import { action, computed, observable, makeObservable } from "mobx";
import { MrbApplicationFormatter } from "../../../common";
import { forEach, map, merge, isFunction, isNull, isUndefined } from "lodash";

const DefaultConfig = {
    actions: {},
    paging: true,
    hidePagerIfUnderLimit: true,
    onDataChange: null,
    mapper: (i) => i,
    generateKey: null,
    formatter: new MrbApplicationFormatter(),
};

class MrbBaseGridViewStore {
    @observable dataInitialized = false;
    @observable initialized = false;

    originalData = [];
    @observable.ref data = [];

    @computed get hasData() {
        return this.data.length > 0;
    }

    @computed get pageNumber() {
        return this.queryUtility.filter.pageNumber;
    }

    @computed get pageSize() {
        return this.queryUtility.filter.pageSize;
    }

    @computed get orderBy() {
        return this.queryUtility.filter.orderBy;
    }

    @computed get orderDirection() {
        return this.queryUtility.filter.orderDirection;
    }

    @computed get recordCount() {
        return this.queryUtility.recordCount;
    }

    @computed get hasItems() {
        return this.data && this.data.length > 0;
    }

    constructor(rootStore, queryUtility, loaderStore, config = {}) {
        makeObservable(this);
        this.rootStore = rootStore;
        this.queryUtility = queryUtility;
        this.loaderStore = loaderStore;
        if (config.actions) {
            // Remove not allowed actions based on user permissions
            config.actions = this._filterActionsWithPermission(config.actions);
        }
        this.config = merge({}, DefaultConfig, config);

        this.formatValue = this.formatValue.bind(this);
        this.generateCellKey = this.generateCellKey.bind(this);
    }

    @action.bound
    setData(data) {
        let items = data.item ? data.item : data;
        items = map(items, this.config.mapper);
        this.originalData = items;
        this.data = items;

        if (data.item) {
            this.queryUtility.handleResponse(data);
        }

        this.dataInitialized = true;
        if (isFunction(this.config.onDataChange)) {
            this.config.onDataChange();
        }
    }

    generateCellKey(item, index) {
        if (isFunction(this.config.generateKey)) {
            return this.config.generateKey(item, index);
        } else if (item.hasOwnProperty("id")) {
            return item.id;
        } else {
            if (process.env.NODE_ENV === "development") {
                console.warn(
                    "MrbBaseGridViewStore: Using an index as the key is not recommended since it can negatively impact performances. Define custom generateKey() function."
                );
            }
            return index;
        }
    }

    @action.bound
    suspend(params) {
        this.loaderStore.suspend(params);
        this.rootStore.mainLoaderStore.suspend();
    }

    @action.bound
    resume() {
        this.loaderStore.resume();
        this.rootStore.mainLoaderStore.resume();
    }

    @action.bound
    setPage(page) {
        this.queryUtility.changePage(page);
    }

    @action.bound
    setPageSize(pageSize) {
        this.queryUtility.changePageSize(pageSize);
    }

    @action.bound
    setSort(sort) {
        this.queryUtility.changeOrder(sort);
    }

    @action.bound
    resetGridItems() {
        this.data = [...this.originalData];
    }

    _filterActionsWithPermission(actions) {
        const result = {};
        forEach(actions, (action, key) => {
            if (!action.authorization || this.rootStore.userStore.hasPermission(action.authorization)) {
                result[key] = action;
            }
        });
        return result;
    }

    formatValue(value, format) {
        if (isNull(value) || isUndefined(value)) {
            return "-";
        }
        if (!this.config.formatter) {
            return value;
        }
        if (format) {
            if (isFunction(format)) {
                return format(value);
            }
            const { type, ...options } = format;
            return this.config.formatter.format(type, value, options);
        }
        return value;
    }

    destroy() {
        this.loaderStore.destroy();
    }
}

export default MrbBaseGridViewStore;
