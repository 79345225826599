import { action, observable, computed, makeObservable, runInAction } from "mobx";
import { extend, isInteger } from "lodash";

const DefaultOptions = {
    delay: 80,
    minDispayTime: 180,
};

class MrbLoaderStore {
    @observable _loaderCount = 0;
    @observable _pastStartDelay = observable.box(false);

    options = null;
    startDelayTimeout = null;
    stopDelayTimeout = null;
    loadingStartTime = null;

    @computed get loading() {
        return this._loaderCount > 0 && this._pastStartDelay.get();
    }

    constructor(options) {
        makeObservable(this);
        this.options = extend({}, DefaultOptions, options);
    }

    @action.bound
    suspend(customDelay = null) {
        if (this.stopDelayTimeout) {
            this._loaderCount--; // silently decrement loader for previous iteration
            this.clearStopDelayTimeout();
        }
        if (this._loaderCount === 0) {
            this._pastStartDelay.set(false);
            const delay = isInteger(customDelay) ? customDelay : this.options.delay;
            if (delay === 0) {
                this._pastStartDelay.set(true);
                this.createLoadingStartTime();
            } else {
                this.startDelayTimeout = setTimeout(() => {
                    runInAction(() => {
                        this._pastStartDelay.set(true);
                        this.createLoadingStartTime();
                    });
                }, delay);
            }
        }
        this._loaderCount++;
    }

    createLoadingStartTime() {
        this.loadingStartTime = new Date().getTime();
    }

    @action.bound
    resume() {
        if (this._loaderCount > 0) {
            if (
                this._loaderCount === 1 &&
                !this.stopDelayTimeout &&
                this.loadingStartTime &&
                isInteger(this.options.minDispayTime)
            ) {
                this._resumeWithDelay();
            } else {
                this._resumeLoaderInternal();
            }
        }
    }

    @action.bound
    _resumeWithDelay() {
        const now = new Date().getTime();
        const diff = now - this.loadingStartTime;
        // 10ms is allowed error constant
        if (diff + 10 < this.options.minDispayTime) {
            this.stopDelayTimeout = setTimeout(() => {
                this._resumeLoaderInternal();
            }, this.options.minDispayTime - diff);
        } else {
            this._resumeLoaderInternal();
        }
    }

    @action.bound
    _resumeLoaderInternal() {
        if (this._loaderCount > 0) {
            this._loaderCount--;
            if (this._loaderCount === 0) {
                this._pastStartDelay.set(false);
                this.clearStopDelayTimeout();
            }
        }
    }

    clearStopDelayTimeout() {
        if (this.stopDelayTimeout) {
            clearTimeout(this.stopDelayTimeout);
            this.stopDelayTimeout = null;
        }
    }

    clearStartDelayTimeout() {
        if (this.startDelayTimeout) {
            clearTimeout(this.startDelayTimeout);
            this.startDelayTimeout = null;
        }
    }

    destroy() {
        this.clearStartDelayTimeout();
        this.clearStopDelayTimeout();
    }
}

export default MrbLoaderStore;
