import React from "react";

const TopTrendingEmptyStateTemplate = () => {
    return (
        <div className="c-empty-state--no-results">
            <i className="u-icon--empty u-icon--empty--lrg u-icon--empty--shuffle u-mar--bottom--sml"></i>
            <h3>No trending list</h3>
            <p>Try adjusting your search to find what you are looking for.</p>
        </div>
    );
};

export default TopTrendingEmptyStateTemplate;
