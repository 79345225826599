import React from "react";
import { PropTypes } from "prop-types";
import { dateFormatter } from "mrb/common/formatting";
import { MrbButton } from "mrb/components/button";
import {
    PremiumTrialTermsInfo,
    SubscriptionPrice,
    InvalidPaymentInfo,
    NakiRadioPremiumBadge,
    NakiNigunPremiumBadge,
    NakiRadioFreeBadge,
} from "application/payment/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { applicationDefaults, subscriptionPlans, subscriptionStatuses } from "common/constants";
import { isSubscriptionBundle } from "common/utils";
import { observer } from "mobx-react";
import { isNil } from "lodash";
import { mergeCss } from "mrb/common/utils";
import moment from "moment";

function UserSubscriptionInfoTemplate({ userSubscriptionsPreviewViewStore, t }) {
    const { activeSubscription, pendingSubscription, isSubscriptionBundleActive } = userSubscriptionsPreviewViewStore;
    return (
        <div>
            <div className="c-payment__list c-payment__list--plan">
                <SubscriptionHeader subscription={activeSubscription} />
                {pendingSubscription && !isSubscriptionBundleActive ? (
                    <React.Fragment>
                        <div className="u-display--flex u-display--flex--ai--center u-mar--bottom--med">
                            <i className="u-icon u-icon--lrg u-icon--arrow-right--secondary"></i>
                        </div>
                        <SubscriptionHeader subscription={pendingSubscription} />
                    </React.Fragment>
                ) : null}
            </div>
            <Discount userSubscriptionsPreviewViewStore={userSubscriptionsPreviewViewStore} t={t} />
            <SubscriptionDetails store={userSubscriptionsPreviewViewStore} t={t} />
        </div>
    );
}

function SubscriptionHeader({ subscription }) {
    let premiumBadge = null;
    let activeClassName = "";
    switch (subscription.product.slug) {
        case subscriptionPlans.nakiRadioPremium:
            premiumBadge = <NakiRadioPremiumBadge iconSize="base" />;
            activeClassName = "primary";
            break;
        case subscriptionPlans.nakiNigun:
            premiumBadge = <NakiNigunPremiumBadge iconSize="base" />;
            activeClassName = "tertiary";
            break;
        default:
            premiumBadge = <NakiRadioFreeBadge iconSize="base" />;
            activeClassName = "secondary";
            break;
    }

    let subscriptionStatus = "";
    let isTrial = false;
    switch (subscription.subscriptionStatus.abrv) {
        case subscriptionStatuses.pastDue:
            subscriptionStatus = "tertiary";
            break;
        case subscriptionStatuses.active:
            subscriptionStatus = "success";
            break;
        case subscriptionStatuses.trial:
            isTrial = true;
            subscriptionStatus = "secondary";
            break;
        default:
            break;
    }

    return (
        <div className={`c-payment__card c-payment__card--full c-payment__card--${activeClassName} u-mar--bottom--med`}>
            <div className="u-display--flex u-display--flex--ai--center">
                {premiumBadge}

                <div className="u-mar--left--med">
                    <p>{subscription.product.name}</p>
                    <p className="u-type--med u-type--wgt--bold u-mar--top--tny">
                        {isTrial || subscription.amount === 0 ? (
                            <span>Free</span>
                        ) : (
                            <SubscriptionPrice price={subscription.amount} />
                        )}
                    </p>
                    <p className={mergeCss("u-type--sml u-type--wgt--bold", `u-type--color--${subscriptionStatus}`)}>
                        {subscription.subscriptionStatus.name}
                    </p>
                </div>
            </div>
        </div>
    );
}

const SubscriptionDetails = observer(function SubscriptionDetails({ store, t }) {
    const {
        activeSubscription,
        pendingSubscription,
        rootStore: { premiumSubscriptionStore },
    } = store;
    const isPremiumSubscriptionTrial = activeSubscription.subscriptionStatus.abrv === subscriptionStatuses.trial;

    return (
        <div className="c-payment__card c-payment__card--full">
            {activeSubscription.product.productCategory &&
            isSubscriptionBundle(activeSubscription.product.productCategory) &&
            activeSubscription.currentBillingCycle < 12 ? (
                <React.Fragment>
                    <DefaultSubscriptionDetails subscription={activeSubscription} />
                    <div className="u-separator--primary"></div>
                    <NakiBundleSubscriptionDetails activeSubscription={activeSubscription} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {isNil(pendingSubscription) ? (
                        <DefaultSubscriptionDetails subscription={activeSubscription} />
                    ) : (
                        <UpgradeSubscriptionDetails
                            activeSubscription={activeSubscription}
                            pendingSubscription={pendingSubscription}
                            isSubscriptionBundleActive={premiumSubscriptionStore.isSubscriptionBundleActive}
                        />
                    )}
                </React.Fragment>
            )}
            {!premiumSubscriptionStore.isSubscriptionBundleActive && <div className="u-separator--primary"></div>}

            {isPremiumSubscriptionTrial && <PremiumTrialTermsInfo subscription={activeSubscription} t={t} />}
            {activeSubscription.latestInvalidPaymentTransaction &&
                activeSubscription.latestInvalidPaymentTransaction.error && (
                    <InvalidPaymentInfo
                        subscription={activeSubscription}
                        paymentError={activeSubscription.latestInvalidPaymentTransaction.error}
                        t={t}
                    />
                )}
            <div className="u-mar--top--tny">
                {activeSubscription.isInternal ? (
                    <InternalSubscriptionControls store={store} t={t} />
                ) : (
                    <SubscriptionControls store={store} t={t} />
                )}
            </div>
        </div>
    );
});

const Discount = observer(function Discount({ userSubscriptionsPreviewViewStore, t }) {
    const { activeSubscription } = userSubscriptionsPreviewViewStore;
    let activeTo;

    if (isNil(activeSubscription.discount)) {
        return null;
    }

    if (activeSubscription.discount.activeTo) {
        activeTo = dateFormatter.format(activeSubscription.discount.activeTo, {
            format: "MM/DD/YYYY",
        });
    }

    return (
        <div className="u-mar--bottom--med">
            <div className="c-card--med c-card--primary">
                <h1 className="u-type--base u-mar--bottom--sml">Discount</h1>
                {activeSubscription.discount.amountOff ? (
                    <p>-${activeSubscription.discount.amountOff} off</p>
                ) : (
                    <p>{activeSubscription.discount.percentOff}% off</p>
                )}
                {activeTo ? <p>Discount is valid until {activeTo}</p> : null}
            </div>
        </div>
    );
});
UserSubscriptionInfoTemplate.propTypes = {
    userSubscriptionsPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function DefaultSubscriptionDetails({ subscription }) {
    const isPremiumSubscriptionTrial = subscription.subscriptionStatus.abrv === subscriptionStatuses.trial;

    return (
        <React.Fragment>
            {subscription.startDate && (
                <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--tny">
                    <span className="u-type--base">Start Date:</span>

                    <p>
                        {dateFormatter.format(subscription.startDate, {
                            format: applicationDefaults.formats.date,
                        })}
                    </p>
                </div>
            )}
            <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-mar--bottom--med">
                {isNil(subscription.endDate) ? (
                    <React.Fragment>
                        {!isNil(subscription.nextBillingDate) && (
                            <React.Fragment>
                                <span className="u-type--base">
                                    {isPremiumSubscriptionTrial ? "Start Billing Date:" : "Next Billing Date:"}
                                </span>
                                <p>
                                    {dateFormatter.format(subscription.nextBillingDate, {
                                        format: applicationDefaults.formats.date,
                                    })}
                                </p>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <span className="u-type--base">End Date:</span>
                        <p>
                            {dateFormatter.format(subscription.endDate, {
                                format: applicationDefaults.formats.date,
                            })}
                        </p>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}

const NakiBundleSubscriptionDetails = observer(function NakiBundleSubscriptionDetails({ activeSubscription }) {
    return (
        <React.Fragment>
            <p className="u-mar--bottom--sml">
                After{" "}
                {dateFormatter.format(moment(activeSubscription.startDate).add(12, "M"), {
                    format: applicationDefaults.formats.date,
                })}
                , your subscription may be cancelled at any time. For technical support and device repair/replacement,
                please contact our support team at <a href="mailto: support@nakiradio.com">support@nakiradio.com</a>.
            </p>
        </React.Fragment>
    );
});

const UpgradeSubscriptionDetails = observer(function UpgradeSubscriptionDetails({
    activeSubscription,
    pendingSubscription,
    isSubscriptionBundleActive,
}) {
    return (
        <React.Fragment>
            <p className="u-mar--bottom--sml">
                Your <strong>{activeSubscription.product.name}</strong> subscription will remain active until{" "}
                <strong>
                    {isSubscriptionBundleActive
                        ? activeSubscription.endDate
                            ? dateFormatter.format(activeSubscription.endDate, {
                                  format: applicationDefaults.formats.date,
                              })
                            : null
                        : activeSubscription.endDate
                        ? dateFormatter.format(activeSubscription.nextBillingDate, {
                              format: applicationDefaults.formats.date,
                          })
                        : null}
                </strong>
                .
            </p>
            <p className="u-mar--bottom--sml">
                Your <strong>{pendingSubscription.product.name}</strong> subscription starts on{" "}
                <strong>
                    {pendingSubscription.startDate
                        ? dateFormatter.format(pendingSubscription.startDate, {
                              format: applicationDefaults.formats.date,
                          })
                        : null}
                </strong>
                , and we will charge you{" "}
                <strong>
                    <SubscriptionPrice price={pendingSubscription.amount} />
                </strong>
                .
            </p>
        </React.Fragment>
    );
});

const InternalSubscriptionControls = observer(function InternalSubscriptionControls({ store, t }) {
    const { cancelSubscription } = store;

    return (
        <div>
            <MrbButton
                classNameOverride="c-btn c-btn--med c-btn--full c-btn--ghost"
                label="Cancel Subscription"
                onClick={cancelSubscription}
            />{" "}
        </div>
    );
});

const SubscriptionControls = observer(function SubscriptionControls({ store, t }) {
    const {
        activeSubscription,
        pendingSubscription,
        cancelSubscription,
        renewSubscription,
        revertSubscription,
        endTrial,
        rootStore: { premiumSubscriptionStore },
    } = store;
    const isPremiumSubscriptionTrial = activeSubscription.subscriptionStatus.abrv === subscriptionStatuses.trial;

    if (premiumSubscriptionStore.isSubscriptionBundleActive) {
        if (activeSubscription.currentBillingCycle >= 12) {
            return (
                <div>
                    <MrbButton
                        classNameOverride="c-btn c-btn--med c-btn--full c-btn--ghost"
                        label="Cancel Subscription"
                        onClick={cancelSubscription}
                    />{" "}
                </div>
            );
        } else {
            return null;
        }
    }

    if (activeSubscription.product.slug === subscriptionPlans.nakiRadioFree) {
        return (
            <div>
                <MrbRouterLink
                    className="c-btn c-btn--med c-btn--full c-btn--ghost"
                    routeName="master.application.premium.subscriptions"
                >
                    Upgrade Plan
                </MrbRouterLink>
            </div>
        );
    }

    if (isPremiumSubscriptionTrial) {
        if (isNil(activeSubscription.endDate)) {
            return (
                <div>
                    <MrbButton
                        classNameOverride="c-btn c-btn--med c-btn--full c-btn--ghost"
                        label="Cancel Subscription"
                        onClick={cancelSubscription}
                    />{" "}
                </div>
            );
        }
        return (
            <div>
                <MrbButton
                    classNameOverride="c-btn c-btn--med c-btn--full c-btn--ghost"
                    label="End Trial"
                    onClick={() => endTrial(activeSubscription.id)}
                />
            </div>
        );
    }

    if (isNil(activeSubscription.endDate)) {
        return (
            <div>
                <MrbRouterLink
                    className="c-btn c-btn--med c-btn--full c-btn--primary u-mar--bottom--sml"
                    routeName="master.application.premium.subscriptions"
                >
                    Change Plan
                </MrbRouterLink>
                <MrbButton
                    classNameOverride="c-btn c-btn--med c-btn--full c-btn--ghost"
                    label="Cancel Subscription"
                    onClick={cancelSubscription}
                />
            </div>
        );
    } else if (!isNil(pendingSubscription)) {
        return (
            <MrbButton
                classNameOverride="c-btn c-btn--med c-btn--full c-btn--ghost"
                label={`Revert to ${activeSubscription.product.name}`}
                onClick={revertSubscription}
            />
        );
    }

    return (
        <MrbButton
            classNameOverride="c-btn c-btn--med c-btn--full c-btn--ghost"
            label="Renew Subscription"
            onClick={renewSubscription}
        />
    );
});

export default observer(UserSubscriptionInfoTemplate);
