class UpdatePodcastStatusOptions {
    podcastId = null;
    title = null;
    isPremium = false;
    isUnavailableInApp = false;

    constructor(podcastId, title, isPremium, isUnavailableInApp) {
        this.podcastId = podcastId;
        this.title = title;
        this.isPremium = isPremium;
        this.isUnavailableInApp = isUnavailableInApp;
    }
}

export default UpdatePodcastStatusOptions;
