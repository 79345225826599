import { MrbBaseRouteStore } from "mrb/core";
import { DeviceAnalyticsService } from "common/services";

class DeviceActivityRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(DeviceAnalyticsService);
    }

    async getDeviceActivity(filter, abortController) {
        const response = await this.service.getDeviceActivity(filter, abortController);
        return response.data;
    }

    async getDeviceActivitySummary(filter, abortController) {
        const response = await this.service.getDeviceActivitySummary(filter, abortController);
        return response.data;
    }
}

export default DeviceActivityRouteStore;
