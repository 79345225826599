import React from "react";
import { PropTypes } from "prop-types";
import NumericInput from "react-numeric-input";

function MrbNumericInput({
    onChange,
    value,
    placeholder,
    t = (i) => i,
    ...rest
}) {
    return (
        <NumericInput
            value={value}
            onChange={onChange}
            placeholder={t(placeholder)}
            noStyle
            {...rest}
        />
    );
}

MrbNumericInput.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    t: PropTypes.func,
};

export default MrbNumericInput;
