import { PropTypes } from "prop-types";
import { producerPlaybackTimeConverter } from "common/utils";

function ProducerPreviewReportPlaybackTimeCellTemplate({ data }) {
    const { reportVersion, totalProducerPlaybacks } = data;
    const value = producerPlaybackTimeConverter(reportVersion, totalProducerPlaybacks);
    if (value) {
        return value;
    }

    return "-";
}

ProducerPreviewReportPlaybackTimeCellTemplate.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ProducerPreviewReportPlaybackTimeCellTemplate;
