class DownloadRequest {
    id = null;
    title = null;
    artworkUrl = null;
    downloadUrl = null;
    duration = null;
    fileSize = null;

    constructor(id, title, artworkUrl, downloadUrl, duration, fileSize) {
        this.id = id;
        this.title = title;
        this.artworkUrl = artworkUrl;
        this.downloadUrl = downloadUrl;
        this.duration = duration;
        this.fileSize = fileSize;
    }
}

export default DownloadRequest;
