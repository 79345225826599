import { BaseService } from "mrb/core";
import { ProducerApiRouteService } from "common/services";

class ProducerService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new ProducerApiRouteService());
    }
}

export default ProducerService;
