import React from "react";
import PropTypes from "prop-types";
import { action, observable, makeObservable, runInAction } from "mobx";
import { observer } from "mobx-react";

class MrbImage extends React.Component {
    @observable errored = false;
    @observable useCssImage = false;
    @observable isLoaded = false;

    constructor() {
        super();
        makeObservable(this);

        this.handleImageLoaded = this.handleImageLoaded.bind(this);
    }

    @action.bound
    onError() {
        if (!this.errored) {
            if (this.props.useCssImageFallback) {
                this.useCssImage = true;
            }
            this.errored = true;
            if (this.props.onError) {
                this.props.onError();
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.src !== this.props.src) {
            runInAction(() => {
                this.setIsLoaded(false);
                this.errored = false;
            });
        }
    }

    @action.bound
    setIsLoaded(value) {
        this.isLoaded = value;
    }

    handleImageLoaded() {
        this.setIsLoaded(true);
    }

    render() {
        const {
            className = "",
            alt = "",
            src,
            loadingPlaceholder,
            fallbackSrc,
            onClick,
            width,
            height,
            fallbackComponent,
        } = this.props;

        const imageStyle = !this.isLoaded ? { display: "none" } : {};

        return (
            <React.Fragment>
                {!this.errored ? (
                    <React.Fragment>
                        {!this.useCssImage ? (
                            <React.Fragment>
                                {!this.isLoaded && loadingPlaceholder && (
                                    <img
                                        src={loadingPlaceholder}
                                        width={width}
                                        height={height}
                                        alt=""
                                        className={className}
                                    />
                                )}
                                <img
                                    src={src}
                                    onError={this.onError}
                                    className={className}
                                    style={imageStyle}
                                    onLoad={this.handleImageLoaded}
                                    alt={alt}
                                    width={width}
                                    height={height}
                                    onClick={onClick}
                                />
                            </React.Fragment>
                        ) : (
                            <div className={className ? `${src} ${className}` : src}></div>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {fallbackComponent ? (
                            <React.Fragment>{fallbackComponent}</React.Fragment>
                        ) : (
                            <React.Fragment>
                                {fallbackSrc ? (
                                    <React.Fragment>
                                        {!this.useCssImage ? (
                                            <img
                                                src={fallbackSrc}
                                                onError={this.onError}
                                                className={className}
                                                alt={alt}
                                            />
                                        ) : (
                                            <div
                                                className={className ? `${fallbackSrc} ${className}` : fallbackSrc}
                                            ></div>
                                        )}
                                    </React.Fragment>
                                ) : null}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

MrbImage.propTypes = {
    src: PropTypes.string.isRequired,
    fallbackSrc: PropTypes.string,
    useCssImage: PropTypes.bool,
    errored: PropTypes.bool,
    onError: PropTypes.func,
    className: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.any,
    height: PropTypes.any,
    onClick: PropTypes.func,
    fallbackComponent: PropTypes.any,
};

export default observer(MrbImage);
