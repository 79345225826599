import { BasePortalDetailedActivityViewStore } from "administration/analytics/stores";

class PremiumUsersActivityViewStore extends BasePortalDetailedActivityViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            chartLabel: "Premium users",
        });
        this.routeStore = routeStore;
    }

    getActivityChartData(params, abortController) {
        return this.routeStore.getPremiumSubscriptionsActivity(params, abortController);
    }

    fetchSummary(params, abortController) {
        return this.routeStore.getPremiumSubscriptionsSummary(params, abortController);
    }
}

export default PremiumUsersActivityViewStore;
