import React from "react";
import { HomeSubPodcastTemplate } from "themes/application/home/components";
import { HomeSubPodcastViewStore } from "application/home/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new HomeSubPodcastViewStore(rootStore, componentProps),
    "homeSubPodcastViewStore"
)
class HomeSubPodcast extends React.Component {
    render() {
        return <HomeSubPodcastTemplate {...this.props} />;
    }
}

export default HomeSubPodcast;
