import React from "react";
import { PropTypes } from "prop-types";
import { MrbImage } from "mrb/components/media";
import defaultCoverPlaceholder from "themes/assets/images/placeholder-template.svg";

function ImageTemplate({ src, placeholder, ...rest }) {
    placeholder = placeholder || defaultCoverPlaceholder;

    return <MrbImage src={src || placeholder} loadingPlaceholder={placeholder} fallbackSrc={placeholder} {...rest} />;
}

ImageTemplate.propTypes = {
    src: PropTypes.string,
    placeholder: PropTypes.string,
};

export default ImageTemplate;
