import * as Sentry from "@sentry/react";

class Logger {
    logError(error) {
        console.log(error);
        Sentry.captureException(error);
    }
}

const logger = new Logger();
export default logger;
