import React from "react";
import { numberFormatter } from "common/utils";
import { mergeCss } from "mrb/common/utils";

function PercentageIncreaseDonutChartTemplate(props) {
    const { value, chartSize } = props;
    const graphValue = Math.abs(value);
    const sign = Math.sign(value);

    return (
        <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--flex-end u-mar--bottom--med">
            <svg
                width="75%"
                height="75%"
                viewBox="0 0 42 42"
                className={mergeCss("c-chart", chartSize ? chartSize : "")}
            >
                <circle
                    rx="6"
                    cx="21"
                    cy="21"
                    r="15.91549430918954"
                    fill="transparent"
                    stroke={`${sign >= 0 ? "#99ceff" : "#cf6679"}`}
                    strokeWidth="1.5"
                ></circle>

                <circle
                    rx="6"
                    ry="6"
                    cx="21"
                    cy="21"
                    r="15.91549430918954"
                    fill="transparent"
                    stroke={`${sign >= 0 ? "#339dff" : "#cf6679"}`}
                    strokeWidth="5"
                    strokeDasharray={`${graphValue} ${100 - graphValue}`}
                    strokeDashoffset="25"
                ></circle>

                <g className="c-chart--text">
                    <text x="50%" y="50%" className="c-chart--number">
                        {numberFormatter.format(value)}%
                    </text>
                    <text x="50%" y="50%" className="c-chart--label">
                        {sign >= 0 ? "increase" : "decrease"}
                    </text>
                </g>
            </svg>
        </div>
    );
}

export default PercentageIncreaseDonutChartTemplate;
