import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "mrb/components/button";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { HomeDeviceSelect, TogglePremium } from "application/home/components";
import { observer } from "mobx-react";
import { isNil } from "lodash";

function HomePreviewHeaderTemplate({ store, hideStatusButton, t }) {
    const {
        deviceSelectStore,
        goBack,
        rootStore: { userDeviceStore },
    } = store;

    return (
        <div className="c-page__header--top">
            <div className="c-home__header__input__wrapper">
                <button onClick={goBack} className="c-btn c-btn--lrg c-btn--anchor">
                    <span className="display--ib u-mar--right--tny align--v--middle">
                        <i className="u-icon u-icon--med u-icon--back" />
                    </span>
                    {t("APPLICATION.HOME.PREVIEW.TITLE")}
                </button>
            </div>
            {userDeviceStore.deviceCount > 0 ? (
                <React.Fragment>
                    <HomeDeviceSelect store={deviceSelectStore} userDeviceStore={userDeviceStore} t={t} />
                    {!hideStatusButton && (
                        <div className="c-header__actions c-header__actions--sml c-header__actions--end">
                            <StatusToggle store={store} t={t} />
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <MrbRouterLink
                    className="c-btn c-btn--base c-btn--primary"
                    routeName="master.application.device.create"
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                >
                    Connect Device
                </MrbRouterLink>
            )}
        </div>
    );
}

HomePreviewHeaderTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    hideStatusButton: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

const StatusToggle = observer(function StatusToggle({ store, t }) {
    const { item, updateTurnedOnStatus, statusToggleLoader, updatingStatus, isTurnedOn, contentAccessResult } = store;
    if (isNil(item)) {
        return null;
    }
    if (!contentAccessResult.canAccessOnSelectedDevice) {
        return (
            <MrbButton
                icon="u-icon u-icon--med u-icon--premium--white u-mar--right--tny"
                classNameOverride="c-btn c-btn--base c-btn--status--enabled"
                label="APPLICATION.HOME.PREVIEW.ACTIONS.ENABLED"
                disabled={true}
                t={t}
            />
        );
    }

    return (
        <TogglePremium isPremium={item.isPremium} canAccessPremiumContent={contentAccessResult.canAccessPremium} t={t}>
            {!isTurnedOn ? (
                <MrbButton
                    icon="u-icon u-icon--med u-icon--premium--white u-mar--right--tny"
                    classNameOverride="c-btn c-btn--base c-btn--status--enabled"
                    label="APPLICATION.HOME.PREVIEW.ACTIONS.ENABLED"
                    disabled={statusToggleLoader.loading}
                    aria-busy={updatingStatus}
                    t={t}
                    onClick={() => updateTurnedOnStatus(true)}
                />
            ) : (
                <MrbButton
                    icon="u-icon u-icon--med u-icon--premium--white u-mar--right--tny"
                    classNameOverride="c-btn c-btn--base c-btn--status--disabled"
                    label="APPLICATION.HOME.PREVIEW.ACTIONS.DISABLED"
                    disabled={statusToggleLoader.loading}
                    aria-busy={updatingStatus}
                    t={t}
                    onClick={() => updateTurnedOnStatus(false)}
                />
            )}
        </TogglePremium>
    );
});

export default HomePreviewHeaderTemplate;
