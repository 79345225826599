import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";

function PasswordFieldInfoTemplate({ passwordFieldInfoViewStore }) {
    const {
        isMiniumLengthValid,
        containsSpecialCharacter,
        containsNumericCharacter,
    } = passwordFieldInfoViewStore;

    return (
        <div className="c-card c-card--message c-card--message--password u-mar--bottom--tny">
            <div>
                <p className="u-type--sml u-mar--bottom--sml">
                    Example: keepMyData$af3
                </p>
                <p className="u-type--sml">
                    {" "}
                    <i
                        className={`u-icon u-icon--med u-mar--right--tny u-icon--${
                            isMiniumLengthValid ? "checked" : "unchecked"
                        }`}
                    ></i>
                    At least 8 characters
                </p>
                <p className="u-type--sml">
                    {" "}
                    <i
                        className={`u-icon u-icon--med u-mar--right--tny u-icon--${
                            containsNumericCharacter ? "checked" : "unchecked"
                        }`}
                    ></i>
                    At least one numeric character
                </p>
                <p className="u-type--sml">
                    {" "}
                    <i
                        className={`u-icon u-icon--med u-mar--right--tny u-icon--${
                            containsSpecialCharacter ? "checked" : "unchecked"
                        }`}
                    ></i>
                    At least one non-alphanumeric character
                </p>
            </div>
        </div>
    );
}

PasswordFieldInfoTemplate.propTypes = {
    passwordFieldInfoViewStore: PropTypes.object.isRequired,
};

export default observer(PasswordFieldInfoTemplate);
