import React from "react";
import ContentLoader from "react-content-loader";

const AddStationsOrPodcastsLoaderTemplate = ({
    props,
    podcast,
    podcastGroup,
}) => {
    const screenwidth = window.innerWidth;
    let loaderHeight;
    let loaderWidth;

    if (screenwidth > 1200) {
        loaderWidth = screenwidth - 690;
    } else if (screenwidth > 988) {
        loaderWidth = screenwidth - 200;
    } else {
        loaderWidth = screenwidth - 64;
    }

    if (screenwidth > 766 && podcast && podcastGroup) {
        loaderHeight = 410;
    } else if (screenwidth > 766 && podcast) {
        loaderHeight = 810;
    } else if (screenwidth > 766) {
        loaderHeight = 875;
    } else {
        loaderHeight = 700;
    }

    return (
        <ContentLoader
            speed={2}
            width={"100%"}
            height={"100%"}
            viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            {screenwidth > 766 ? (
                podcast ? (
                    podcastGroup ? (
                        <PodcastGroupLoader />
                    ) : (
                        <PodcastsLoader />
                    )
                ) : (
                    <StationsLoader />
                )
            ) : (
                <StationsOrPodcastLoaderLrg />
            )}
        </ContentLoader>
    );
};

export default AddStationsOrPodcastsLoaderTemplate;

const StationsOrPodcastLoaderLrg = () => {
    const items = [];
    let y = 0;

    for (let i = 0; i < 7; i++) {
        items.push(
            <React.Fragment key={`${i}_station`}>
                <rect x="0" y={y} rx="3" ry="3" width="10%" height="10" />
                <rect x="0" y={y + 20} rx="6" ry="6" width="100%" height="40" />
            </React.Fragment>
        );

        y += 75;
    }

    return (
        <React.Fragment>
            {items}
            <rect x="0" y={y} rx="3" ry="3" width="10%" height="10" />
            <rect x="0" y={y + 20} rx="6" ry="6" width="100%" height="60" />

            <rect x="0" y={y + 100} rx="3" ry="3" width="4%" height="10" />
            <rect x="0" y={y + 120} rx="6" ry="6" width="6%" height="20" />

            <rect x="8%" y={y + 100} rx="3" ry="3" width="4%" height="10" />
            <rect x="8%" y={y + 120} rx="6" ry="6" width="20%" height="20" />

            <rect x="22%" y={y + 100} rx="3" ry="3" width="4%" height="10" />
            <rect x="22%" y={y + 120} rx="6" ry="6" width="6%" height="20" />

            <rect x="30%" y={y + 100} rx="3" ry="3" width="4%" height="10" />
            <rect x="30%" y={y + 120} rx="6" ry="6" width="25%" height="20" />
        </React.Fragment>
    );
};

const StationsLoader = () => {
    return (
        <React.Fragment>
            <rect x="0" y="10" rx="3" ry="3" width="10%" height="10" />
            <rect x="0" y="30" rx="6" ry="6" width="48%" height="40" />
            <rect x="51%" y="10" rx="3" ry="3" width="10%" height="10" />
            <rect x="51%" y="30" rx="6" ry="6" width="48%" height="40" />

            <rect x="0" y="110" rx="3" ry="3" width="10%" height="10" />
            <rect x="0" y="130" rx="6" ry="6" width="48%" height="40" />
            <rect x="51%" y="110" rx="3" ry="3" width="10%" height="10" />
            <rect x="51%" y="130" rx="6" ry="6" width="48%" height="40" />

            <rect x="0" y="200" rx="3" ry="3" width="10%" height="10" />
            <rect x="0" y="220" rx="6" ry="6" width="48%" height="40" />

            <rect x="0" y="290" rx="3" ry="3" width="10%" height="10" />
            <rect x="0" y="310" rx="6" ry="6" width="100%" height="40" />

            <rect x="0" y="380" rx="3" ry="3" width="10%" height="10" />
            <rect x="0" y="400" rx="6" ry="6" width="100%" height="40" />

            <rect x="0" y="470" rx="3" ry="3" width="10%" height="10" />
            <rect x="0" y="490" rx="6" ry="6" width="100%" height="110" />

            <rect x="0" y="620" rx="3" ry="3" width="10%" height="10" />
            <BoxList y={620} />
            <rect x="0" y="680" rx="6" ry="6" width="100%" height="110" />

            <rect x="0" y="820" rx="3" ry="3" width="4%" height="10" />
            <rect x="0" y="845" rx="6" ry="6" width="6%" height="30" />

            <rect x="8%" y="820" rx="3" ry="3" width="4%" height="10" />
            <rect x="8%" y="845" rx="6" ry="6" width="6%" height="30" />

            <rect x="16%" y="820" rx="3" ry="3" width="4%" height="10" />
            <rect x="16%" y="845" rx="6" ry="6" width="15%" height="30" />

            <rect x="32%" y="820" rx="3" ry="3" width="4%" height="10" />
            <rect x="32%" y="845" rx="6" ry="6" width="6%" height="30" />

            <rect x="40%" y="820" rx="3" ry="3" width="4%" height="10" />
            <rect x="40%" y="845" rx="6" ry="6" width="20%" height="30" />

            <rect x="62%" y="820" rx="3" ry="3" width="4%" height="10" />
            <rect x="62%" y="845" rx="6" ry="6" width="6%" height="30" />

            <rect x="70%" y="820" rx="3" ry="3" width="4%" height="10" />
            <rect x="70%" y="845" rx="6" ry="6" width="25%" height="30" />
        </React.Fragment>
    );
};

const PodcastsLoader = () => {
    const items = [];
    let y = 0;

    for (let i = 0; i < 7; i++) {
        if (i === 1) {
            items.push(
                <React.Fragment key={`${i}_podcast`}>
                    <rect x="0" y={y} rx="3" ry="3" width="10%" height="10" />
                    {/* prettier-ignore */}
                    <rect x="0" y={y + 20} rx="6" ry="6" width="100%" height="40" />
                </React.Fragment>
            );
        } else if (i === 3) {
            items.push(
                <React.Fragment key={`${i}_podcast`}>
                    <rect x="0" y={y} rx="3" ry="3" width="10%" height="10" />
                    {/* prettier-ignore */}
                    <rect x="0" y={y + 20} rx="6" ry="6" width="48%" height="40" />
                </React.Fragment>
            );
        } else if (i === 4) {
            items.push(
                <React.Fragment key={`${i}_podcast`}>
                    <rect x="0" y={y} rx="3" ry="3" width="10%" height="10" />
                    {/* prettier-ignore */}
                    <rect x="0" y={y + 20} rx="6" ry="6" width="100%" height="40" />
                </React.Fragment>
            );
        } else if (i === 5) {
            items.push(
                <React.Fragment key={`${i}_podcast`}>
                    <rect x="0" y={y} rx="3" ry="3" width="10%" height="10" />
                    {/* prettier-ignore */}
                    <rect x="0" y={y + 20} rx="6" ry="6" width="100%" height="70" />
                </React.Fragment>
            );
        } else if (i === 6) {
            items.push(
                <React.Fragment key={`${i}_podcast`}>
                    {/* prettier-ignore */}
                    <rect x="0" y={y + 20} rx="3" ry="3" width="10%" height="10" />
                    <BoxList y={y + 25} />
                    {/* prettier-ignore */}
                    <rect x="0" y={y + 85} rx="6" ry="6" width="100%" height="90" />
                    <ToggleList y={y + 110} />
                </React.Fragment>
            );
        } else {
            items.push(
                <React.Fragment key={`${i}_podcast`}>
                    {/* prettier-ignore */}
                    <rect x="0" y={i > 5 ? y + 30 : y} rx="3" ry="3" width="10%" height="10" />
                    {/* prettier-ignore */}
                    <rect x="0" y={i > 5 ? y + 50 : y + 20} rx="6" ry="6" width="48%" height="40" />
                    {/* prettier-ignore */}
                    <rect x="51%" y={i > 5 ? y + 30 : y} rx="3" ry="3" width="10%" height="10" />
                    {/* prettier-ignore */}
                    <rect x="51%" y={i > 5 ? y + 50 : y + 20} rx="6" ry="6" width="48%" height="40" />
                </React.Fragment>
            );
        }

        y += 96;
    }

    return <React.Fragment>{items}</React.Fragment>;
};

const PodcastGroupLoader = () => {
    const items = [];
    let y = 0;

    for (let i = 0; i < 4; i++) {
        if (i === 2) {
            items.push(
                <React.Fragment key={`${i}_podcastGroup`}>
                    {/* prettier-ignore */}
                    <rect x="0" y={y} rx="3" ry="3" width="10%" height="10" />
                    <BoxList y={y + 5} />
                    {/* prettier-ignore */}
                    <rect x="0" y={y + 65} rx="6" ry="6" width="100%" height="90" />
                    <ToggleList y={y + 90} />
                </React.Fragment>
            );
        } else {
            items.push(
                <React.Fragment key={`${i}_podcastGroup`}>
                    {/* prettier-ignore */}
                    <rect x="0" y={i > 5 ? y + 30 : y} rx="3" ry="3" width="10%" height="10" />
                    {/* prettier-ignore */}
                    <rect x="0" y={i > 5 ? y + 50 : y + 20} rx="6" ry="6" width="100%" height="40" />
                    {/* prettier-ignore */}
                    <rect x="0" y={i > 5 ? y + 30 : y} rx="3" ry="3" width="10%" height="10" />
                    {/* prettier-ignore */}
                    <rect x="0" y={i > 5 ? y + 50 : y + 20} rx="6" ry="6" width="100%" height="40" />
                </React.Fragment>
            );
        }
        y += 96;
    }

    return <React.Fragment>{items}</React.Fragment>;
};

const BoxList = ({ y = 0 }) => {
    const box = [];
    let x = 0;

    for (let j = 0; j < 6; j++) {
        box.push(
            <React.Fragment key={`${j}_boxList`}>
                {/* prettier-ignore */}
                <rect x={x} y={y + 20} rx="6" ry="6" width="30" height="30" />
            </React.Fragment>
        );
        x += 37;
    }
    return <React.Fragment>{box}</React.Fragment>;
};

const ToggleList = ({ y = 0 }) => {
    const box = [];
    let x = 0;

    for (let j = 0; j < 5; j++) {
        box.push(
            <React.Fragment key={`${j}_toggleList`}>
                {/* prettier-ignore */}
                <rect x={x + 8} y={y + 80} rx="6" ry="6" width="30" height="8" />
                {/* prettier-ignore */}
                <rect x={x} y={y + 100} rx="12" ry="12" width="50" height="25" />
            </React.Fragment>
        );
        x += 77;
    }
    return <React.Fragment>{box}</React.Fragment>;
};
