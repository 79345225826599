import React from "react";
import { PropTypes } from "prop-types";
import {
    HomeEmptyState,
    HomeSearchResultGridCell,
    HomeOrderBySelect,
    HomeListSearchLoader,
    HomeListLoader,
} from "application/home/components";
import { MrbGrid } from "mrb/components/grid";
import { PremiumBadge, Image } from "common/components";
import { MrbContentLoader } from "mrb/components/loader";
import { useCanAccessContent, nakiRadioContentType } from "application/common/content-access";
import { MrbPager } from "mrb/components/pager";
import { observer } from "mobx-react";
import { map } from "lodash";

function HomeSearchResultsTemplate({ homeGenericSearchViewStore, t }) {
    const { gridStore, orderBySelectStore, queryUtility } = homeGenericSearchViewStore;

    return (
        <React.Fragment>
            <MrbContentLoader loaderStore={gridStore.loaderStore} loaderRender={() => <HomeListLoader row={1} title />}>
                {() => (
                    <div>
                        <PodcastGroupsList homeGenericSearchViewStore={homeGenericSearchViewStore} t={t} />
                    </div>
                )}
            </MrbContentLoader>

            {gridStore.hasItems && (
                <div className="c-home__filter">
                    <div className="c-home__filter__wrapper">
                        <p className="u-type--base u-type--wgt--bold">Search results</p>

                        <div className="c-home__filter__select">
                            <HomeOrderBySelect store={orderBySelectStore} t={t} />
                        </div>
                    </div>

                    {/* <div className="c-shuffle__search__input c-shuffle__search__input--secondary">
            <MrbSelect store={genreSelectStore} classNameExtend="c-dropdown--dark c-dropdown--base" />
          </div> */}
                </div>
            )}

            <MrbGrid
                store={gridStore}
                t={t}
                classNameOverride="c-home__list--search"
                loaderRender={() => <HomeListSearchLoader row={8} />}
                cellRender={(props) => <HomeSearchResultGridCell {...props} />}
                emptyStateRender={() => <HomeEmptyState classNameExtend="c-empty-state--no-results--lrg" t={t} />}
                pagerRender={(props) => (
                    <MrbPager {...props} hideItemsPerPage={true} hidePagerIfUnderLimit={queryUtility.filter.pageSize} />
                )}
            />
        </React.Fragment>
    );
}

HomeSearchResultsTemplate.propTypes = {
    homeGenericSearchViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const PodcastGroupsList = observer(function PodcastGroupsList({ homeGenericSearchViewStore, t }) {
    const {
        podcastGroups,
        onClickPodcastGroup,
        scrollFeaturedLeft,
        scrollFeaturedRight,
        isAppearsInScrollable,
        setAppearsInContainerRef,
    } = homeGenericSearchViewStore;
    return (
        <React.Fragment>
            {podcastGroups.length > 0 && (
                <div>
                    <div className="c-home__list__header">
                        <div className="u-type--base u-type--wgt--bold u-mar--bottom--tny">
                            {t("APPLICATION.HOME.LIST.GENERIC_SEARCH.APPEARS_IN")}
                        </div>
                        {isAppearsInScrollable && (
                            <div className="u-display--flex">
                                <i
                                    className="u-icon u-icon--base u-icon--arrow-left u-mar--right--tny"
                                    onClick={scrollFeaturedLeft}
                                />
                                <i className="u-icon u-icon--base u-icon--arrow-right" onClick={scrollFeaturedRight} />
                            </div>
                        )}
                    </div>

                    <div className="u-animation--fadeIn">
                        <div className="c-home__list--scroll" ref={setAppearsInContainerRef}>
                            {map(podcastGroups, (podcastGroup) => (
                                <PodcastGroup
                                    item={podcastGroup}
                                    key={podcastGroup.podcastId}
                                    onClickPodcastGroup={onClickPodcastGroup}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
});

function PodcastGroup({ item, onClickPodcastGroup }) {
    const contentAccessResult = useCanAccessContent(nakiRadioContentType.podcast, {
        isPremium: item.isParentPodcastPremium,
    });
    return (
        <div className="c-home__card" onClick={() => onClickPodcastGroup(item)}>
            <div className="c-home__card__media">
                <div className="c-home__card__img-wrapper">
                    <Image src={item.coverImageUrl} />
                    <PremiumBadge
                        isPremium={item.isParentPodcastPremium}
                        isPremiumSubscriptionValid={contentAccessResult.canAccessPremium}
                    />
                </div>
            </div>
            <div className="c-home__card__title">{item.title}</div>
        </div>
    );
}

export default observer(HomeSearchResultsTemplate);
