import React from "react";
import { PropTypes } from "prop-types";
import { SubscriptionPrice } from "application/payment/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { dateFormatter } from "mrb/common/formatting";
import { applicationDefaults, subscriptionStatuses, subscriptionPlans } from "common/constants";
import { isNil, isFunction, map } from "lodash";
import { mergeCss } from "mrb/common/utils";
import { MrbContentLoader } from "mrb/components/loader";
import { PaymentCardLoader } from "application/payment/components";

function SubscriptionPlanCardTemplate({
    loaderStore,
    subscriptionPlan,
    subscription,
    subscriptionExist,
    isPremiumTrialPeriodUsed,
    isInternalSubscriptionActive,
    isSubscriptionBundleActive,
    onClickExploreContent,
    footerRenderer,
}) {
    let className = "c-payment__card--";
    let iconClassName = "u-icon--subscription--";

    if (subscriptionPlan.slug === subscriptionPlans.nakiRadioFree) {
        className += "secondary";
        iconClassName += "tales";
    } else if (subscriptionPlan.slug === subscriptionPlans.nakiNigun) {
        className += "tertiary";
        iconClassName += "nigun";
    } else if (subscriptionPlan.slug === subscriptionPlans.nakiRadioPremium) {
        className += "primary";
        iconClassName += "premium";
    }

    return (
        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <PaymentCardLoader />}>
            {() => (
                <React.Fragment>
                    <div className={mergeCss("c-payment__card c-payment__card--sml", className)}>
                        <div className="c-payment__body">
                            <i className={mergeCss("u-icon u-icon--subscription", iconClassName)}></i>
                        </div>

                        <p className="c-payment__header u-type--med">
                            <span>{subscriptionPlan.name}</span>
                            <span>
                                {subscriptionPlan.price === 0 ? (
                                    "Free"
                                ) : (
                                    <SubscriptionPrice price={subscriptionPlan.price} />
                                )}
                            </span>
                        </p>

                        <div className="c-payment__footer c-payment__footer--base">
                            {map(subscriptionPlan.features, (feature) => {
                                return (
                                    <p className="c-payment__body__item u-type--sml">
                                        <i className="u-icon u-icon--base u-icon--line-arrow u-mar--right--tny"></i>
                                        {feature.isStrong ? (
                                            <strong>{feature.description}</strong>
                                        ) : (
                                            feature.description
                                        )}
                                    </p>
                                );
                            })}
                        </div>

                        {isFunction(footerRenderer) ? (
                            footerRenderer({
                                subscriptionPlan,
                                subscriptionExist,
                                isPremiumTrialPeriodUsed,
                                isInternalSubscriptionActive,
                                onClickExploreContent,
                                isSubscriptionBundleActive,
                            })
                        ) : (
                            <Footer
                                subscription={subscription}
                                subscriptionExist={subscriptionExist}
                                isPremiumTrialPeriodUsed={isPremiumTrialPeriodUsed}
                                isInternalSubscriptionActive={isInternalSubscriptionActive}
                                isSubscriptionBundleActive={isSubscriptionBundleActive}
                                subscriptionPlan={subscriptionPlan}
                                onClickExploreContent={onClickExploreContent}
                            />
                        )}
                    </div>
                </React.Fragment>
            )}
        </MrbContentLoader>
    );
}

SubscriptionPlanCardTemplate.propTypes = {
    subscriptionPlan: PropTypes.object.isRequired,
    isPremiumTrialPeriodUsed: PropTypes.bool,
    isInternalSubscriptionActive: PropTypes.bool,
    isSubscriptionBundleActive: PropTypes.bool,
    subscription: PropTypes.object,
    subscriptionExist: PropTypes.bool.isRequired,
    footerRenderer: PropTypes.func,
    onClickExploreContent: PropTypes.func,
};

function Footer({
    subscription,
    subscriptionExist,
    isPremiumTrialPeriodUsed,
    isInternalSubscriptionActive,
    isSubscriptionBundleActive,
    subscriptionPlan,
    onClickExploreContent,
}) {
    let actionButton;
    let subscriptionStatus = subscription ? subscription.subscriptionStatus.abrv : null;

    if (isSubscriptionBundleActive && subscriptionPlan.slug !== subscriptionPlans.nakiRadioPremium) {
        actionButton = (
            <DefaultActionButton
                subscriptionPlan={subscriptionPlan}
                isPremiumTrialPeriodUsed={isPremiumTrialPeriodUsed}
                subscriptionExist={subscriptionExist}
                isDisabled={true}
            />
        );
    } else {
        if (isSubscriptionBundleActive && subscriptionPlan.slug === subscriptionPlans.nakiRadioPremium) {
            subscriptionStatus = subscriptionStatuses.active;
        }

        switch (subscriptionStatus) {
            case subscriptionStatuses.active:
                actionButton = (
                    <MrbRouterLink
                        className="c-btn c-btn--base c-btn--full c-btn--success u-type--wgt--semibold"
                        routeName="master.application.premium.info"
                    >
                        <i className="u-icon u-icon--med u-icon--check u-mar--right--tny"></i>
                        {!isSubscriptionBundleActive && subscription.endDate
                            ? `Active Plan until ${dateFormatter.format(subscription.endDate, {
                                  format: applicationDefaults.formats.date,
                              })}`
                            : "Active Plan"}
                    </MrbRouterLink>
                );
                break;
            case subscriptionStatuses.pending:
                actionButton = (
                    <MrbRouterLink
                        disabled={isSubscriptionBundleActive}
                        className="c-btn c-btn--base c-btn--full c-btn--success u-type--wgt--semibold"
                        routeName="master.application.premium.info"
                    >
                        <i className="u-icon u-icon--med u-icon--check u-mar--right--tny"></i>
                        Starting from{" "}
                        {dateFormatter.format(subscription.startDate, {
                            format: applicationDefaults.formats.date,
                        })}
                    </MrbRouterLink>
                );
                break;
            case subscriptionStatuses.trial:
                if (isNil(subscription.endDate)) {
                    actionButton = (
                        <MrbRouterLink
                            className="c-btn c-btn--base c-btn--full c-btn--success u-type--wgt--semibold"
                            routeName="master.application.premium.info"
                        >
                            <i className="u-icon u-icon--med u-icon--check u-mar--right--tny"></i>
                            Active plan
                        </MrbRouterLink>
                    );
                } else {
                    actionButton = (
                        <MrbRouterLink
                            className="c-btn c-btn--base c-btn--full c-btn--secondary u-type--wgt--semibold"
                            routeName="master.application.premium.trial.upgrade"
                            params={{ subscriptionId: subscription.id }}
                        >
                            Subscribe now
                        </MrbRouterLink>
                    );
                }
                break;
            default:
                actionButton = (
                    <DefaultActionButton
                        subscriptionPlan={subscriptionPlan}
                        isPremiumTrialPeriodUsed={isPremiumTrialPeriodUsed}
                        subscriptionExist={subscriptionExist}
                        isDisabled={isInternalSubscriptionActive}
                    />
                );
                break;
        }
    }

    return (
        <div>
            {actionButton}
            <div className="u-display--flex u-display--flex--jc--center u-mar--top--sml">
                <p
                    className="u-type--sml u-type--link--secondary u-type--underline"
                    onClick={() => onClickExploreContent(subscriptionPlan)}
                >
                    Explore {subscriptionPlan.name}
                </p>
            </div>
        </div>
    );
}

function DefaultActionButton({ subscriptionPlan, isPremiumTrialPeriodUsed, subscriptionExist, isDisabled }) {
    return (
        <React.Fragment>
            {subscriptionPlan.price === 0 ? (
                <div className="u-type--wgt--semibold u-type--center">Free</div>
            ) : (
                <React.Fragment>
                    {isPremiumTrialPeriodUsed ? (
                        <MrbRouterLink
                            className="c-btn c-btn--base c-btn--full c-btn--primary u-type--wgt--semibold"
                            disabled={isDisabled}
                            routeName={
                                subscriptionExist
                                    ? "master.application.premium.change-plan.edit"
                                    : "master.application.premium.payment"
                            }
                            params={{ planId: subscriptionPlan.id }}
                        >
                            <SubscriptionPrice price={subscriptionPlan.price} />
                        </MrbRouterLink>
                    ) : (
                        <MrbRouterLink
                            className="c-btn c-btn--base c-btn--full c-btn--primary u-type--wgt--semibold"
                            disabled={isDisabled}
                            routeName={
                                subscriptionExist
                                    ? "master.application.premium.change-plan.edit"
                                    : "master.application.premium.trial"
                            }
                            params={{ planId: subscriptionPlan.id }}
                        >
                            Free {applicationDefaults.trialPeriodDays}-day Trial
                        </MrbRouterLink>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default SubscriptionPlanCardTemplate;
