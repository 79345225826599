import React, { useRef, useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

const ExplorePremiumLoaderTemplate = ({ className }) => {
    const ref = useRef(null);
    const [loaderWidth, setLoaderWidth] = useState(0);

    useEffect(() => {
        setLoaderWidth(ref.current.offsetWidth);
    }, []);

    return (
        <div className={className ? className : ""} ref={ref}>
            <ExplorePremiumItem loaderWidth={loaderWidth} />
            <ExplorePremiumItem loaderWidth={loaderWidth} />
            <ExplorePremiumItem loaderWidth={loaderWidth} />
        </div>
    )
}

export default ExplorePremiumLoaderTemplate

const ExplorePremiumItem = (props) => {
    const { loaderWidth } = props
    const screenwidth = window.innerWidth;

    let imgSize = 150;

    let imgX = "38%";
    let titleX = "40%";

    let titleY = 234;
    let loaderHeight = 290;
    let borderLineY = 274;
    let arrowY = 258;

    if (screenwidth > 1199) {
        imgSize = 220;
    } else if (screenwidth > 988) {
        imgX = "20%";
        titleX = "26%";
        imgSize = 220;
    } else if (screenwidth > 550) {
        imgX = "31%";
        titleX = "34%";
        imgSize = 220;
    } else {
        imgX = "30%";
        titleX = "26%";

        loaderHeight = 234;

        arrowY = arrowY - 56;
        titleY = titleY - 70;
    }

    return (
        <div className="c-card--primary u-mar--bottom--sml">
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                {screenwidth > 1199 ?
                    <React.Fragment>
                        <rect x="0" y="0" rx="6" ry="6" width="220" height="220" />
                        <rect x="280" y="144" rx="6" ry="6" width="220" height="16" />
                        <rect x="280" y="184" rx="6" ry="6" width="420" height="8" />
                        <rect x="280" y="204" rx="6" ry="6" width="340" height="8" />

                        <rect x="0" y="274" rx="6" ry="6" width="42%" height="1" />
                        <rect x="58%" y="274" rx="6" ry="6" width="42%" height="1" />
                        <rect x="48%" y="258" rx="50" ry="50" width="32" height="32" />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <rect x={imgX} y="0" rx="6" ry="6" width={imgSize} height={imgSize} />
                        <rect x={titleX} y={titleY} rx="6" ry="6" width="180" height="16" />

                        <rect x="0" y={borderLineY} rx="6" ry="6" width="42%" height="1" />
                        <rect x="58%" y={borderLineY} rx="6" ry="6" width="42%" height="1" />
                        <rect x="48%" y={arrowY} rx="50" ry="50" width="32" height="32" />
                    </React.Fragment>
                }
            </ContentLoader>
        </div>
    )
}