import React from "react";
import { PropTypes } from "prop-types";
import { mergeOrOverrideCss } from "mrb/common/utils";

function NakiRadioFreeBadgeTemplate({ classNameOverride, iconSize }) {
    const className = mergeOrOverrideCss(
        "u-icon u-icon--plan--sml u-icon--plan u-icon--plan--free",
        classNameOverride,
        iconSize ? `u-icon--plan--${iconSize}` : "u-icon--plan--base"
    );
    return <i className={className} />;
}

NakiRadioFreeBadgeTemplate.propTypes = {
    classNameExtend: PropTypes.string,
    classNameOverride: PropTypes.string,
};

export default NakiRadioFreeBadgeTemplate;
