import React from "react";
import PropTypes from "prop-types";
import md5 from "md5";

const baseUrl = "https://www.gravatar.com/avatar/";

function MrbGravatar({ email, size, className }) {
    const hash = md5(email, { encoding: "binary" });
    const src = `${baseUrl}${hash}`;

    return (
        <img
            alt={`Gravatar for ${email}`}
            src={src}
            height={size}
            width={size}
            className={className}
        />
    );
}

MrbGravatar.propTypes = {
    email: PropTypes.string.isRequired,
    size: PropTypes.number,
    className: PropTypes.string,
};

export default React.memo(MrbGravatar);
