import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { MrbModalParams } from "mrb/common/models";
import { dateFormatter, currencyFormatter } from "mrb/common/formatting";
import { applicationDefaults, subscriptionPlans, subscriptionStatuses } from "common/constants";
import { cloneDeep, isNil } from "lodash";

class ChangeSubscriptionPlanViewStore extends MrbBaseViewStore {
    @observable.ref subscriptionChangePreview = null;
    @observable isChangePlanDisabled = false;
    planId = null;
    @observable.ref currentSubscription = null;

    @computed get customerPaymentMethod() {
        return this.rootStore.premiumSubscriptionStore.customerPaymentMethod;
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
        this.planId = rootStore.routerStore.routerState.params.planId;

        this.changePlan = this.changePlan.bind(this);

        this.changePlanSuccessModal = new MrbModalParams(null, {
            onClickExplorePremium: this.goToPremiumContent,
        });
    }

    async onInit() {
        this.currentSubscription = cloneDeep(this.rootStore.premiumSubscriptionStore.subscription);
        await this.fetchSubscriptionChangePreview();
    }

    goToPremiumContent = () => {
        switch (this.subscriptionChangePreview.newSubscriptionPlan.slug) {
            case subscriptionPlans.nakiTales:
            case subscriptionPlans.nakiRadioPremium:
                this.rootStore.routerStore.goTo("master.application.home.list", null, {
                    isPremium: true,
                });
                break;
            case subscriptionPlans.nakiNigun:
                this.rootStore.routerStore.goTo("master.application.naki-nigun.list");
                break;
            default:
                break;
        }
    };

    async fetchSubscriptionChangePreview() {
        try {
            const preview = await this.routeStore.getSubscriptionChangePreview(
                this.currentSubscription.id,
                this.planId
            );
            this.setSubscriptionChangePreview(preview);
        } catch (err) {
            this.rootStore.notificationStore.error("Unexpected error occurred. Please contact the support team.", err);
        }
    }

    async changePlan() {
        const formattedSubscriptionPlanPrice = currencyFormatter.format(
            this.subscriptionChangePreview.newSubscriptionPlan.price,
            {
                currency: "USD",
            }
        );
        let description;
        if (
            this.currentSubscription.subscriptionStatus.abrv === subscriptionStatuses.trial &&
            !isNil(this.currentSubscription.endDate)
        ) {
            description = `Your trial subscription will expire on ${dateFormatter.format(
                this.currentSubscription.endDate,
                {
                    format: applicationDefaults.formats.date,
                }
            )}.`;
        } else {
            description = `Your card will be charged ${formattedSubscriptionPlanPrice} each month, cancel anytime.`;
        }
        this.rootStore.confirmDialogStore.showConfirm({
            message: `Change your subscription plan to ${this.subscriptionChangePreview.newSubscriptionPlan.name}?`,
            description: description,
            yesLabel: "Change Plan",
            onConfirm: async () => {
                this.setIsChangePlanDisabled(true);
                try {
                    await this.routeStore.changeSubscriptionPlan(this.planId);
                    this.changePlanSuccessModal.open({
                        billingStartDate: this.subscriptionChangePreview.dateOfChange, //subscription will be charged at the last day of trial period,
                        subscriptionPlan: this.subscriptionChangePreview.newSubscriptionPlan.name,
                    });
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "Unexpected error occurred. Please contact the support team.",
                        err
                    );
                } finally {
                    this.setIsChangePlanDisabled(false);
                }
            },
        });
    }

    setIsChangePlanDisabled(value) {
        this.isChangePlanDisabled = value;
    }

    @action.bound
    setSubscriptionChangePreview(preview) {
        this.subscriptionChangePreview = preview;
    }
}

export default ChangeSubscriptionPlanViewStore;
