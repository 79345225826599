import { runInAction } from "mobx";
import { MrbBaseSelectStore } from "./";
import { extend, isFunction } from "lodash";

const defaultConfig = {
    virtualized: false,
    createOptionPosition: "last",
    formatCreateLabel: (value) => `Create "${value}"`,
    isOptionDisabled: () => {},
};

class MrbSelectStore extends MrbBaseSelectStore {
    isCreatable = false;

    constructor(config, initialItems) {
        super(extend({}, defaultConfig, config), initialItems);

        this.isCreatable = isFunction(this.actions.createOption);
        this.createOption = this.createOption.bind(this);
    }

    async createOption(value) {
        this.loaderStore.suspend();
        const createdItem = await this.actions.createOption(value);
        runInAction(() => {
            if (this.config.isMulti) {
                this.addSelectedItem(createdItem);
            } else {
                this.setSelectedItem(createdItem);
            }
            this.loaderStore.resume();
        });
    }
}

export default MrbSelectStore;
