import React from "react";
import { PropTypes } from "prop-types";
import { MrbPageContent } from "./";
import { getPageObject } from "../utils";

function MrbPage({
    children,
    contentClassNameExtend,
    contentClassNameOverride,
    t,
}) {
    const { header, footer, content } = getPageObject(children);
    return (
        <React.Fragment>
            {header}
            <MrbPageContent
                classNameExtend={contentClassNameExtend}
                classNameOverride={contentClassNameOverride}
                t={t}
                content={content}
            />
            {footer}
        </React.Fragment>
    );
}

MrbPage.propTypes = {
    children: PropTypes.any,
    contentClassNameExtend: PropTypes.string,
    t: PropTypes.func,
};

export default MrbPage;
