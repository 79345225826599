import { BaseService } from "mrb/core";
import { ShuffleWeightApiRouteService } from "common/services";

class ShuffleWeightService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new ShuffleWeightApiRouteService());
    }
}

export default ShuffleWeightService;
