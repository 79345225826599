import { MrbBaseGridViewStore } from "./";
import { MrbContentLoaderStore } from "../../loader";
import { merge, isNil } from "lodash";

const DefaultConfig = {
    onCellClick: null,
};

class MrbGridViewStore extends MrbBaseGridViewStore {
    constructor(rootStore, queryUtility, config = {}, components = {}) {
        super(
            rootStore,
            queryUtility,
            isNil(components.loaderStore) ? new MrbContentLoaderStore(config.loaderOptions) : components.loaderStore,
            merge({}, DefaultConfig, config)
        );
    }
}

export default MrbGridViewStore;
