import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { subscriptionPlansSortOrder } from "application/payment/utils";
import { isNil, sortBy, indexOf } from "lodash";

class ChangeSubscriptionPlanPreviewViewStore extends MrbBaseViewStore {
    @observable.ref subscriptionPlans = [];

    @computed get subscription() {
        return this.rootStore.premiumSubscriptionStore.subscription;
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
    }

    async onInit() {
        if (!isNil(this.subscription)) {
            await this.fetchSubscriptionPlans();
        }
    }

    async fetchSubscriptionPlans() {
        const items = await this.routeStore.getAllSubscriptionProducts();
        const sortedSubscriptionPlans = sortBy(items, (subscriptionPlan) => {
            return indexOf(subscriptionPlansSortOrder, subscriptionPlan.slug);
        });
        this.setSubscriptionPlans(sortedSubscriptionPlans);
    }

    @action.bound
    setSubscriptionPlans(items) {
        this.subscriptionPlans = items;
    }
}

export default ChangeSubscriptionPlanPreviewViewStore;
