const mrbDefaultValidationErrors = {
    accepted: "ERROR_MESSAGES.ACCEPTED",
    after: "ERROR_MESSAGES.AFTER",
    after_or_equal: "ERROR_MESSAGES.AFTER_OR_EQUAL",
    alpha: "ERROR_MESSAGES.ALPHA",
    alpha_dash: "ERROR_MESSAGES.ALPHA_DASH",
    alpha_num: "ERROR_MESSAGES.ALPHA_NUM",
    before: "ERROR_MESSAGES.BEFORE",
    before_or_equal: "ERROR_MESSAGES.BEFORE_OR_EQUAL",
    between: "ERROR_MESSAGES.BETWEEN",
    confirmed: "ERROR_MESSAGES.CONFIRMED",
    date: "ERROR_MESSAGES.DATE",
    def: "ERROR_MESSAGES.DEF",
    different: "ERROR_MESSAGES.DIFFERENT",
    digits: "ERROR_MESSAGES.DIGITS",
    email: "ERROR_MESSAGES.EMAIL",
    in: "ERROR_MESSAGES.IN",
    integer: "ERROR_MESSAGES.INTEGER",
    max: {
        numeric: "ERROR_MESSAGES.MAX.NUMERIC",
        string: "ERROR_MESSAGES.MAX.STRING",
    },
    min: {
        numeric: "ERROR_MESSAGES.MIN.NUMERIC",
        string: "ERROR_MESSAGES.MIN.STRING",
    },
    not_in: "ERROR_MESSAGES.NOT_IN",
    numeric: "ERROR_MESSAGES.NUMERIC",
    present: "ERROR_MESSAGES.PRESENT",
    regex: "ERROR_MESSAGES.REGEX",
    required: "ERROR_MESSAGES.REQUIRED",
    required_if: "ERROR_MESSAGES.REQUIRED_IF",
    required_unless: "ERROR_MESSAGES.REQUIRED_UNLESS",
    required_with: "ERROR_MESSAGES.REQUIRED_WITH",
    required_with_all: "ERROR_MESSAGES.REQUIIRED_WITH_ALL",
    required_without: "ERROR_MESSAGES.REQUIRED_WITHOUT",
    required_without_all: "ERROR_MESSAGES.REQUIRED_WITHOUT_ALL",
    same: "ERROR_MESSAGES.SAME",
    size: {
        numeric: "ERROR_MESSAGES.SIZE.NUMERIC",
        string: "ERROR_MESSAGES.SIZE.STRING",
    },
    string: "ERROR_MESSAGES.STRING",
    url: "ERROR_MESSAGES.URL",
    after_date: "ERROR_MESSAGES.CUSTOM.AFTER_DATE",
};

export default mrbDefaultValidationErrors;
