import React from "react";
import { PropTypes } from "prop-types";
import { numberFormatter } from "common/utils";
import { timeRangeType } from "administration/analytics/constants";
import { applicationDefaults } from "common/constants";
import { dateFormatter } from "mrb/common/formatting";
import { observer } from "mobx-react";
import { mergeCss } from "mrb/common/utils";

function AnalyticsCounterTemplate({
    filteredBy,
    currentCycleNumber,
    previousCycleNumber = 0,
    classNameExtend,
    timeRangeLabelRenderer,
}) {
    const diff = currentCycleNumber - previousCycleNumber;
    const trend = Math.sign(diff);
    let indicatorClass = null;
    if (trend > 0) {
        indicatorClass = "increase";
    } else if (trend < 0) {
        indicatorClass = "decrease";
    } else {
        indicatorClass = "neutral";
    }

    return (
        <div className={mergeCss("c-dashboard__count", `c-dashboard__count--${indicatorClass}`, classNameExtend)}>
            <p
                className={mergeCss(
                    classNameExtend === "c-dashboard__count--secondary"
                        ? "c-dashboard__count--lrg"
                        : "c-dashboard__count--med"
                )}
            >
                <Sign number={diff} />
                {numberFormatter.format(Math.abs(diff))}
            </p>
            <p className="u-type--sml u-type--color--text">
                {timeRangeLabelRenderer ? (
                    timeRangeLabelRenderer({ filteredBy })
                ) : (
                    <TimeRangeLabel filteredBy={filteredBy} />
                )}
            </p>
        </div>
    );
}

function Sign({ number }) {
    if (number > 0) {
        return "+";
    } else if (number < 0) {
        return "-";
    }

    return null;
}

function TimeRangeLabel({ filteredBy }) {
    if (filteredBy.range === timeRangeType.today) {
        return "Today";
    } else if (filteredBy.range === timeRangeType.yesterday) {
        return "Yesterday";
    } else if (filteredBy.range === timeRangeType.last3Days) {
        return "Last 3 days";
    } else if (filteredBy.range === timeRangeType.thisWeek) {
        return "This Week";
    } else if (filteredBy.range === timeRangeType.lastWeek) {
        return "Last Week";
    } else if (filteredBy.range === timeRangeType.thisMonth) {
        return "This Month";
    } else if (filteredBy.range === timeRangeType.lastMonth) {
        return "Last Month";
    } else {
        const fromDate = dateFormatter.format(filteredBy.from, {
            format: applicationDefaults.formats.date,
            timeZone: filteredBy.timeZone,
        });
        const toDate = dateFormatter.format(filteredBy.to, {
            format: applicationDefaults.formats.date,
            timeZone: filteredBy.timeZone,
        });
        return `${fromDate} - ${toDate}`;
    }
}

AnalyticsCounterTemplate.propTypes = {
    filteredBy: PropTypes.object.isRequired,
    currentCycleNumber: PropTypes.number.isRequired,
    previousCycleNumber: PropTypes.number,
    timeRangeLabelRenderer: PropTypes.func,
};

export default observer(AnalyticsCounterTemplate);
