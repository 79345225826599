import { runInAction } from "mobx";
import { MrbBaseListViewStore } from "./";

class MrbBaseInfiniteScrollListViewStore extends MrbBaseListViewStore {
    async fetchResources(filter) {
        // Display grid (Skeleton loader) only during the first page load, and in other cases, during loading more data on scroll event,
        // display only top bar loader (mainLoaderStore)
        const isFirstPageLoad = filter.pageNumber === 1;
        if (isFirstPageLoad) {
            this.gridStore.suspend();
        } else {
            this.rootStore.mainLoaderStore.suspend();
        }
        try {
            const response = await this.find(filter);
            runInAction(() => {
                this.gridStore.setData(response);
                if (isFirstPageLoad) {
                    this.gridStore.resume();
                } else {
                    this.rootStore.mainLoaderStore.resume();
                }
            });
        } catch (err) {
            if (isFirstPageLoad) {
                this.gridStore.resume();
            } else {
                this.rootStore.mainLoaderStore.resume();
            }
            this.onFetchError(err);
        }
    }
}

export default MrbBaseInfiniteScrollListViewStore;
