import React from "react";
import { PropTypes } from "prop-types";
import { MrbEditFormLayout } from "mrb/components";
import { observer } from "mobx-react";
import { StripeAccountFieldSelect } from "common/components/stripe";

function AdminProducerAccountStripeAccountEditTemplate({ adminProducerAccountStripeAccountEditViewStore, t }) {
    const { form, stripeAccountSelectStore } = adminProducerAccountStripeAccountEditViewStore;

    return (
        <MrbEditFormLayout form={form} submitLabel="Submit" t={t}>
            <StripeAccountFieldSelect
                t={t}
                store={stripeAccountSelectStore}
                fieldClassNameOverride=" "
                classNameExtend="c-dropdown--dark"
            />
        </MrbEditFormLayout>
    );
}

AdminProducerAccountStripeAccountEditTemplate.propTypes = {
    adminProducerAccountStripeAccountViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(AdminProducerAccountStripeAccountEditTemplate);
