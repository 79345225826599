import ContentLoader from "react-content-loader";
import React, { useCallback, useState } from "react";

const AddNewAccountLoaderTemplate = (props) => {
    const [loaderWidth, setLoaderWidth] = useState(0);
    // const screenWidth = window.innerWidth;
    const loaderHeight = 270;

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div ref={ref}>
            <LoaderHeader loaderHeight={loaderHeight} loaderWidth={loaderWidth} props={props} />

            <div className="c-card c-card--med c-card--primary">
                <ContentLoader
                    speed={2}
                    width="100%"
                    height={loaderHeight}
                    viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                    backgroundColor="#262729"
                    foregroundColor="#2b2b2b"
                    {...props}
                >
                    <rect x="0" y="12" rx="6" ry="6" width="60" height="10" />
                    <rect x="0" y="32" rx="6" ry="6" width="30%" height="42" />

                    <rect x={"32%"} y="12" rx="6" ry="6" width="140" height="10" />
                    <rect x={"32%"} y="32" rx="6" ry="6" width="30%" height="42" />

                    <rect x={"64%"} y="12" rx="6" ry="6" width="80" height="10" />
                    <rect x={"64%"} y="32" rx="6" ry="6" width="15%" height="42" />

                    <rect x={loaderWidth - 100} y="32" rx="6" ry="6" width="100" height="42" />

                    <rect x="0" y="95" rx="6" ry="6" width="100%" height="1" />

                    <rect x={0} y="115" rx="6" ry="6" width="100" height="10" />
                    <rect x={0} y="135" rx="6" ry="6" width="25%" height="42" />

                    <rect x={0} y="210" rx="6" ry="6" width="170" height="10" />
                    <rect x={0} y="230" rx="6" ry="6" width="100" height="36" />

                    <rect x={"14%"} y="210" rx="6" ry="6" width="170" height="10" />
                    <rect x={"14%"} y="230" rx="6" ry="6" width="100" height="36" />
                </ContentLoader>
            </div>
        </div>
    );
};

export default AddNewAccountLoaderTemplate;

const LoaderHeader = ({ loaderHeight, loaderWidth, props }) => {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={70}
            viewBox={`0 0 ${loaderWidth} ${70}`}
            backgroundColor="#3C3D3E"
            foregroundColor="#404143"
            {...props}
        >
            <rect x="0" y="6" rx="6" ry="6" width="20%" height="20" />
            <rect x={loaderWidth - 100} y="0" rx="6" ry="6" width={100} height="30" />
            <rect x="0" y="50" rx="6" ry="6" width="100%" height="1" />
        </ContentLoader>
    );
};
