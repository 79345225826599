import React, { useCallback, useState } from "react";
import ContentLoader from "react-content-loader";

const UploadAlbumTrackTemplate = () => {
    const [loaderWidth, setLoaderWidth] = useState(0);
    const [uploadLoaderWidth, setUploadLoaderWidth] = useState(0);

    const ref = useCallback((node) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const uploadRef = useCallback((node) => {
        if (node !== null) {
            setUploadLoaderWidth(node.getBoundingClientRect().width);
        }
    }, []);

    return (
        <div ref={ref}>
            <ContentLoader
                speed={2}
                width={loaderWidth}
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${76}`}
                backgroundColor={"#262729"}
                foregroundColor={"#2b2b2b"}
            >
                <rect x={0} y={4} rx="6" ry="6" width="100" height="10" />
                <rect x={0} y={30} rx="6" ry="6" width="100%" height="40" />
            </ContentLoader>

            <div className="row">
                <div className="col col-sml-12 col-lrg-6" ref={uploadRef}>
                    <ContentLoader
                        speed={2}
                        width={uploadLoaderWidth}
                        height="100%"
                        viewBox={`0 0 ${uploadLoaderWidth} ${460}`}
                        backgroundColor={"#262729"}
                        foregroundColor={"#2b2b2b"}
                    >
                        <rect x={0} y={0} rx="6" ry="6" width={uploadLoaderWidth} height="400" />
                        <rect x={0} y={420} rx="6" ry="6" width={uploadLoaderWidth} height="40" />
                    </ContentLoader>
                </div>

                <div className="col col-sml-12 col-lrg-6">
                    <ContentLoader
                        speed={2}
                        width={uploadLoaderWidth}
                        height="100%"
                        viewBox={`0 0 ${uploadLoaderWidth} ${460}`}
                        backgroundColor={"#262729"}
                        foregroundColor={"#2b2b2b"}
                    >
                        <rect x={0} y={0} rx="6" ry="6" width={uploadLoaderWidth} height="400" />
                        <rect x={0} y={420} rx="6" ry="6" width={uploadLoaderWidth} height="40" />
                    </ContentLoader>
                </div>
            </div>
        </div>
    );
};

export default UploadAlbumTrackTemplate;
