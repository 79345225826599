import React from "react";
import { PropTypes } from "prop-types";
import { MrbEditFormLayout, MrbFieldTextArea } from "mrb/components";
import { CurrencyInputField } from "common/components";
import { currencyFormatter } from "mrb/common/formatting";
import { observer } from "mobx-react";

function AdminProducerAccountBalanceEditTemplate({ adminProducerAccountBalanceEditViewStore, t }) {
    const { form, currentAccountBalance, newAccountBalance } = adminProducerAccountBalanceEditViewStore;
    const diff = newAccountBalance - currentAccountBalance;
    const sign = Math.sign(diff);
    let newBalanceClassName;
    if (sign > 0) {
        newBalanceClassName = "u-type--color--success";
    } else if (sign < 0) {
        newBalanceClassName = "u-type--color--warning";
    }
    return (
        <MrbEditFormLayout form={form} submitLabel="Submit" t={t}>
            <CurrencyInputField
                t={t}
                classNameOverride="c-input c-input--outline c-input--numeric c-input--numeric--med"
                field={form.$("amount")}
            />

            <div className="row">
                <div className="col col-sml-12 col-lrg-6">
                    <div className="c-form__field">
                        <p className="c-form__field__label">
                            {t("ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.CURRENT_ACCOUNT_BALANCE_LABEL")}
                        </p>
                        <div>
                            {currencyFormatter.format(currentAccountBalance, {
                                currency: "USD",
                            })}
                        </div>
                    </div>
                </div>

                <div className="col col-sml-12 col-lrg-6">
                    <div className="c-form__field">
                        <p className="c-form__field__label">
                            {t("ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.NEW_ACCOUNT_BALANCE_LABEL")}
                        </p>
                        <div className={newBalanceClassName}>
                            {currencyFormatter.format(newAccountBalance, {
                                currency: "USD",
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <MrbFieldTextArea t={t} rows={4} field={form.$("notes")} classNameExtend="c-input--outline" />
        </MrbEditFormLayout>
    );
}
AdminProducerAccountBalanceEditTemplate.propTypes = {
    adminProducerAccountBalanceViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(AdminProducerAccountBalanceEditTemplate);
