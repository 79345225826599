import React from "react";
import { PropTypes } from "prop-types";
import { mergeOrOverrideCss } from "../../common/utils";
import { observer } from "mobx-react";
import { isFunction } from "lodash";

function MrbCollapsiblePanelBody({
    isCollapsed,
    classNameOverride,
    classNameExtend,
    children,
}) {
    return (
        <div
            className={mergeOrOverrideCss(
                "c-collapse-panel__body",
                classNameOverride,
                classNameExtend
            )}
        >
            {isCollapsed && renderChildren(children)}
        </div>
    );
}

MrbCollapsiblePanelBody.propTypes = {
    isCollapsed: PropTypes.bool.isRequired,
    classNameOverride: PropTypes.string,
    classNameExtend: PropTypes.string,
    children: PropTypes.any,
};

function renderChildren(children) {
    if (isFunction(children)) {
        return children();
    }
    return children;
}

export default observer(MrbCollapsiblePanelBody);
