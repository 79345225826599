import { MrbBaseFormatter } from "./";
import { mrbDefaultFormats } from "./";
import moment from "moment";

class DateFormatter extends MrbBaseFormatter {
    constructor() {
        super(mrbDefaultFormats.date);
    }

    format(date, { format, timeZone = null }) {
        let momentDate = null;
        if (moment.isMoment(date)) {
            momentDate = date;
        } else if (timeZone && typeof date === "string" && moment(date, moment.ISO_8601, true).isValid()) {
            momentDate = moment.utc(date);
        } else {
            momentDate = moment(date);
        }
        if (timeZone) {
            momentDate = moment.tz(momentDate, timeZone);
        } else {
            momentDate = moment(momentDate);
        }
        if (format) {
            return momentDate.format(format);
        }
        return momentDate.toISOString();
    }
}

const dateFormatter = new DateFormatter();
export default dateFormatter;
