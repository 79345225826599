import React from "react";
import PropTypes from "prop-types";
import { MrbBreadcrumbsOptions } from "./";

function MrbBreadcrumbs({ navigationOptions, t = (i) => i }) {
    return (
        <React.Fragment>
            <MrbBreadcrumbsOptions options={navigationOptions} t={t} />
        </React.Fragment>
    );
}

MrbBreadcrumbs.propTypes = {
    navigationOptions: PropTypes.object,
    breadcrumbs: PropTypes.array,
    t: PropTypes.func,
};

MrbBreadcrumbs.defaultProps = {
    overrideDefaultContent: false,
};

export default MrbBreadcrumbs;
