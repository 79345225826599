import { BaseService } from "mrb/core";
import { OrderApiRouteService } from "common/services";

class OrderService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new OrderApiRouteService());
    }
}

export default OrderService;
