import { action, observable, makeObservable, runInAction } from "mobx";
import { merge, isFunction } from "lodash";
import { MrbContentLoaderStore } from "mrb/components/loader";

const DefaultConfig = {
    label: "Chart",
    fill: true,
    actions: {
        find: () => { },
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            easing: "easeInOutQuad",
            duration: 520,
        },
        scales: {
            y: {
                suggestedMax: 10,
                grid: {
                    color: "rgba(190,190,190,0.4)",
                },
                ticks: {
                    color: "rgba(200,200,200,0.7)",
                    precision: 0,
                },
            },
            x: {
                grid: {
                    color: "rgba(200,200,200,0.4)",
                },
                ticks: {
                    color: "rgba(200,200,200,0.7)",
                },
            },
        },
    },
};

class LineChartStore {
    @observable.ref data = [];
    @observable.ref labels = [];

    config = {};

    constructor(rootStore, config) {
        makeObservable(this);
        this.rootStore = rootStore;
        this.loaderStore = new MrbContentLoaderStore();

        this.config = merge(this.config, DefaultConfig, config);

        this.suspend = this.suspend.bind(this);
        this.resume = this.resume.bind(this);
    }

    suspend() {
        this.loaderStore.suspend();
    }

    resume() {
        this.loaderStore.resume();
    }

    generate(params, abortController = null) {
        return this.getResources(params, abortController);
    }

    async getResources(params, abortController = null) {
        try {
            this.suspend();
            let data = await this.config.actions.find(params, abortController);
            if (isFunction(this.config.actions.preprocessData)) {
                data = this.config.actions.preprocessData(data);
            }
            const dataset = this.config.actions.mapDataset(data);
            const labels = this.config.actions.mapLabels(data);
            runInAction(() => {
                this.setLabels(labels);
                this.setData(dataset);
                this.resume();
            });
        } catch (err) {
            this.resume();
            if (isFunction(this.config.actions.onError)) {
                this.config.actions.onError(err);
            } else {
                throw err;
            }
        }
    }

    @action.bound
    setData(data) {
        this.data = data;
    }

    @action.bound
    setLabels(labels) {
        this.labels = labels;
    }
}

export default LineChartStore;
