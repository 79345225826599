import React from "react";
import { PropTypes } from "prop-types";
import { PremiumBadge, Image } from "common/components";
function PremiumContentSubListItemTemplate({ onCellClick, item }) {
    return (
        <div className="c-home__card" onClick={() => onCellClick(item)}>
            <div className="c-home__card__media">
                <div className="c-home__card__img-wrapper">
                    <Image src={item.coverImageUrl} />
                    <PremiumBadge isPremium={item.isPremium} />
                </div>
            </div>

            <div className={item.podcastType ? "c-home__card__title" : null}>{item.title}</div>
        </div>
    );
}

PremiumContentSubListItemTemplate.propTypes = {
    onCellClick: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
};

export default PremiumContentSubListItemTemplate;
