import { action, computed, override, makeObservable, observable } from "mobx";
import { default as MrbBaseGridViewStore } from "../grid/stores/MrbBaseGridViewStore";
import { MrbContentLoaderStore } from "../loader";
import { concat, merge, map, isFunction, isNil } from "lodash";

const DefaultConfig = {
    onCellClick: null,
};

class MrbInfiniteScrollGridViewStore extends MrbBaseGridViewStore {
    @observable isLoadingMoreData = false;

    @computed get hasMoreData() {
        return this.recordCount > this.data.length;
    }

    constructor(rootStore, queryUtility, config = {}, components = {}) {
        super(
            rootStore,
            queryUtility,
            isNil(components.loaderStore) ? new MrbContentLoaderStore(config.loaderOptions) : components.loaderStore,
            merge({}, DefaultConfig, config)
        );
        makeObservable(this);

        this.loadMore = this.loadMore.bind(this);
    }

    async loadMore() {
        if (!this.isLoadingMoreData && this.hasMoreData) {
            try {
                this.setIsLoadingMoreData(true);
                await this.queryUtility.changePage(this.pageNumber + 1);
            } finally {
                this.setIsLoadingMoreData(false);
            }
        }
    }

    @action.bound
    setIsLoadingMoreData(isLoadingMoreData) {
        this.isLoadingMoreData = isLoadingMoreData;
    }

    @override
    setData(data) {
        let items = data.item ? data.item : data;
        items = map(items, this.config.mapper);
        this.originalData = items;
        if (this.pageNumber === 1) {
            this.data = items;
        } else {
            this.data = concat(this.data, items);
        }

        if (data.item) {
            this.queryUtility.handleResponse(data);
        }

        this.dataInitialized = true;
        if (isFunction(this.config.onDataChange)) {
            this.config.onDataChange();
        }
    }
}

export default MrbInfiniteScrollGridViewStore;
