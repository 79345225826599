import React from "react";
import ContentLoader from "react-content-loader";

const HomeListSearchLoaderTemplate = ({ row = 3, props }) => {
    const screenwidth = window.innerWidth;
    // prettier-ignore
    const loaderWidth = screenwidth > 1199 ? screenwidth - 200 : screenwidth;

    const list = [];
    let y = 60;
    let y2 = 90;
    // prettier-ignore
    const x1 = screenwidth > 988 ? (screenwidth - 200) / 2 : screenwidth < 540 ? screenwidth : screenwidth / 2;
    // prettier-ignore
    const x2 = screenwidth > 988 ? (screenwidth - 50) / 2 : screenwidth < 540 ? screenwidth + 165 : (screenwidth + 165) / 2;

    for (let i = 0; i < row; i++) {
        list.push(
            <React.Fragment key={`${i}`}>
                <rect x="0" y={y} rx="6" ry="6" width="60" height="60" />
                <rect x="75" y={y + 12} rx="6" ry="6" width="170" height="10" />
                <rect x="75" y={y2} rx="6" ry="6" width="70" height="10" />

                {screenwidth > 426 && (
                    <React.Fragment>
                        {/* prettier-ignore */}
                        <rect x={x1} y={y}  rx="6" ry="6" width="60" height="60" />
                        {/* prettier-ignore */}
                        <rect x={x2} y={y + 12} rx="6" ry="6" width="170" height="10" />
                        {/* prettier-ignore */}
                        <rect x={x2} y={y2} rx="6" ry="6" width="70" height="10" />
                    </React.Fragment>
                )}
            </React.Fragment>
        );

        y += 90;
        y2 += 90;
    }

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox={`0 0 ${loaderWidth} ${row * 95}`}
            backgroundColor="#3C3D3E"
            foregroundColor="#404143"
            {...props}
        >
            <rect x="0" y="20" rx="6" ry="6" width="100" height="10" />
            <rect x={loaderWidth - 170} y="20" rx="6" ry="6" width="170" height="10" />

            {list}
        </ContentLoader>
    );
};

export default HomeListSearchLoaderTemplate;
