import { BaseService } from "mrb/core";
import { DeviceAnalyticsApiRouteService } from "common/services";

class DeviceAnalyticsService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new DeviceAnalyticsApiRouteService());
    }

    getUserActivitySummary(userId, abortSignal = null) {
        const url = this.routeService.getUserActivitySummary(userId);
        return this.apiClient.get(url, null, abortSignal);
    }

    getDeviceActivity(filter, abortSignal = null) {
        const url = this.routeService.getDeviceActivity(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getUserDeviceActivity(filter, abortSignal = null) {
        const url = this.routeService.getUserDeviceActivity(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getDeviceActivitySummary(filter, abortSignal = null) {
        const url = this.routeService.getDeviceActivitySummary(filter);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default DeviceAnalyticsService;
