import { fileExtensions } from "../constants";
import { find } from "lodash";

class MrbFileUtility {
    isImage(fileExtension) {
        if (!fileExtension.startsWith(".")) {
            fileExtension = `.${fileExtension}`;
        }
        return find(fileExtensions.image, (type) => type === fileExtension)
            ? true
            : false;
    }

    isDocument(fileExtension) {
        if (!fileExtension.startsWith(".")) {
            fileExtension = `.${fileExtension}`;
        }
        return find(fileExtensions.document, (type) => type === fileExtension)
            ? true
            : false;
    }

    isVideo(fileExtension) {
        if (!fileExtension.startsWith(".")) {
            fileExtension = `.${fileExtension}`;
        }
        return find(fileExtensions.video, (type) => type === fileExtension)
            ? true
            : false;
    }
}

const fileUtility = new MrbFileUtility();
export default fileUtility;
