import React from "react";
import { CoverImageUploadTemplate } from "themes/common/components";

class CoverImageUpload extends React.Component {
    render() {
        return <CoverImageUploadTemplate {...this.props} />;
    }
}

export default CoverImageUpload;
