import { action, computed, observable, makeObservable, runInAction } from "mobx";
import { STATUS, EVENTS, ACTIONS } from "react-joyride";
import { isNil } from "lodash";
import { UserSettingsService } from "common/services";
import { MrbBaseViewStore } from "mrb/core/stores";

class JoyrideViewStore extends MrbBaseViewStore {
    @observable _run = true;
    @observable isTutorialPassed = false;
    @observable stepIndex = 0;
    _userSettingsService = null;

    @computed get run() {
        return this.rootStore.userDeviceStore.deviceCount > 0 && this._run;
    }

    get userSettingsService() {
        if (isNil(this._userSettingsService)) {
            this._userSettingsService = this.rootStore.createApplicationService(UserSettingsService);
        }
        return this._userSettingsService;
    }

    steps = [
        {
            target: ".jq-onboarding-search",
            content:
                "Start typing to search content. You can search by title, genre, location, language, and keywords.",
        },
        {
            target: ".jq-onboarding-sync",
            content: 'Use "Device sync" to enable or disable content on all of your devices.',
        },
        {
            target: ".jq-onboarding-select-device",
            content: "Use the dropdown to switch between your devices.",
        },
        {
            target: ".jq-onboarding-bulk-edit",
            content: "Enable or disable multiple items with the bulk edit option.",
        },
        {
            target: ".jq-onboarding-turn-on-off",
            content:
                'To enable or disable content, click here. If "Device sync" is enabled, it will apply across all of your devices.',
        },
        {
            target: ".jq-onboarding-title",
            content: "To reveal more information about each station or podcast, click here.",
        },
        {
            target: ".c-nav__icon--devices",
            content: "Manage your devices in Devices section.",
        },
        {
            target: ".jq-onboarding-premium",
            content: "Explore our Premium content with a 3-day trial offer!",
        },
    ];

    constructor(rootStore) {
        super(rootStore);
        makeObservable(this);
        this.rootStore = rootStore;

        this.reaction(
            () => this.rootStore.userStore.user,
            (user) => {
                if (user && user.userProfile && user.userProfile.membership) {
                    runInAction(() => {
                        this.reset();
                        if (user) {
                            this.isTutorialPassed = user.userProfile.membership.tutorialPassed;
                        }
                    });
                }
            }
        );
    }

    @action.bound
    handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            this.stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            this.userSettingsService.updateIsTutorialPassed(true);
            this._run = false;
        }
    };

    @action.bound
    reset() {
        this._run = true;
        this.stepIndex = 0;
        this.isTutorialPassed = false;
    }
}

export default JoyrideViewStore;
