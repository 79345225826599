import React from "react";
import { PropTypes } from "prop-types";
import { MrbTabHeaders } from "./";
import { getTabContent } from "./utils";
import { observer } from "mobx-react";

function MrbTabLayout({ store, headersRenderer, children, t }) {
    const { activeTabIndex } = store;
    const tabElements = getTabContent(children);
    return (
        <div>
            <MrbTabHeaders headersRenderer={headersRenderer} store={store} t={t}>
                {tabElements}
            </MrbTabHeaders>
            {renderActiveTab(activeTabIndex, tabElements)}
        </div>
    );
}

function renderActiveTab(activeTabIndex, children) {
    const tabs = React.Children.toArray(children);
    const tab = tabs[activeTabIndex];
    return tab;
}

MrbTabLayout.propTypes = {
    store: PropTypes.object.isRequired,
    headersRenderer: PropTypes.func,
    children: PropTypes.node.isRequired,
    t: PropTypes.func,
};

export default observer(MrbTabLayout);
