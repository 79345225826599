import { override } from "mobx";
import { MrbSelectStore } from "../";
import { merge } from "lodash";
import { arrayDifference } from "../../../../common/utils";

const DefaultConfig = {
    fetchOnChange: true,
};

class MrbQuerySelectStore extends MrbSelectStore {
    queryUtility = null;
    key = null;

    @override get value() {
        return this.queryUtility.filter[this.key];
    }

    constructor(config, queryUtility, key, initialItems) {
        super(merge({}, DefaultConfig, config), initialItems);
        if (!queryUtility) {
            throw new Error("queryUtility must be provided.");
        }
        this.queryUtility = queryUtility;
        if (!key) {
            throw new Error("key must be provided.");
        }
        this.key = key;
    }

    @override
    setValue(value) {
        this.queryUtility.filter.set(this.key, value);
    }

    @override
    onChange(selectedOption) {
        const oldValue = this.value;
        super.onChange(selectedOption);

        if (this.config.fetchOnChange) {
            if (this.config.isMulti) {
                if (arrayDifference(oldValue, this.value).length > 0) {
                    this.queryUtility.fetch();
                }
            } else if (oldValue !== this.value) {
                this.queryUtility.fetch();
            }
        }
    }
}

export default MrbQuerySelectStore;
