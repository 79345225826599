import React, { useRef, useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

const SubscriptionPlanLoaderTemplate = (props) => {
    const ref = useRef(null);
    const [loaderWidth, setLoaderWidth] = useState(0);

    useEffect(() => {
        setLoaderWidth(ref.current.offsetWidth);
    }, []);

    return (
        <React.Fragment>
            <div ref={ref} className="c-payment c-payment__card u-mar--bottom--med">
                <ContentLoader
                    speed={2}
                    width="100%"
                    height="100%"
                    viewBox={`0 0 ${loaderWidth} 84`}
                    backgroundColor="#262729"
                    foregroundColor="#2b2b2b"
                    {...props}
                >
                    <rect x="0" y={84 / 2 - 20} rx="6" ry="6" width="40" height="40" />
                    <rect x="50" y={4} rx="6" ry="6" width="160" height="16" />
                    <rect x="50" y={40} rx="6" ry="6" width="60" height="20" />
                    <rect x="50" y={70} rx="6" ry="6" width="100" height="12" />
                </ContentLoader>
            </div>
            <div className="c-payment c-payment__card">
                <ContentLoader
                    speed={2}
                    width="100%"
                    height="100%"
                    viewBox={`0 0 ${loaderWidth} 185`}
                    backgroundColor="#262729"
                    foregroundColor="#2b2b2b"
                    {...props}
                >
                    <rect x="0" y={4} rx="6" ry="6" width="150" height="16" />
                    <rect x={loaderWidth - 60} y={4} rx="6" ry="6" width="60" height="16" />

                    <rect x="0" y={40} rx="6" ry="6" width="140" height="16" />
                    <rect x={loaderWidth - 60} y={40} rx="6" ry="6" width="60" height="16" />

                    <rect x="0" y={90} rx="6" ry="6" width="170" height="16" />
                    <rect x="0" y={130} rx="6" ry="6" width="100%" height="1" />
                    <rect x="0" y={150} rx="6" ry="6" width="100%" height="34" />
                </ContentLoader>
            </div>
        </React.Fragment>
    );
};

export default SubscriptionPlanLoaderTemplate;
