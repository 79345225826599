import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { AnalyticsFilter } from "administration/analytics/filters";
import { dateFormatter } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";
import { isNil } from "lodash";

class BaseStatsWidgetViewStore extends MrbBaseViewStore {
    @observable.ref item = null;
    @observable.ref filter = new AnalyticsFilter();
    options = null;

    @computed get currentPeriodLabel() {
        return dateFormatter.format(this.filter.from, {
            format: applicationDefaults.formats.monthFull,
            timeZone: this.filter.timeZone,
        });
    }

    @computed get previousPeriodLabel() {
        return dateFormatter.format(this.filter.previousCycleFrom, {
            format: "MMMM, YYYY",
            timeZone: this.filter.timeZone,
        });
    }

    constructor(rootStore, options) {
        super(rootStore);
        makeObservable(this);
        this.options = options;
    }

    onInit() {
        return this.fetchResource(this.filter);
    }

    async fetchResource(filter) {
        try {
            const item = await this.fetch(filter);
            this.setItem(item);
        } catch (err) {
            console.log(err);
            //TODO: error handling
        }
    }

    fetch(filter) {
        throw new Error("Method not implemented");
    }

    @action.bound
    setItem(item) {
        if (!isNil(item)) {
            this.item = item;
        } else {
            this.item = {
                currentCycleScore: 0,
                currentCyclePosition: null,
                previousCyclePosition: null,
            };
        }
    }

    @action.bound
    setTimeRange(timeRange) {
        this.filter.range = timeRange;
    }

    @action.bound
    setParameters(params) {
        this.parameters = params;
    }
}

export default BaseStatsWidgetViewStore;
