import { action, override } from "mobx";
import { MrbSelectStore } from "../";
import { arrayDifference } from "../../../../common/utils";
import { isArray, isEmpty, isString } from "lodash";

class MrbFieldSelectStore extends MrbSelectStore {
    formField = null;

    @override get value() {
        return this.formField.value;
    }

    constructor(config, formField, initialItems = null) {
        super(config, initialItems);
        if (!formField) {
            throw new Error("formField must be provided");
        }
        this.formField = formField;
    }

    @override
    setValue(value) {
        if (isArray(this.value) && isArray(value)) {
            if (arrayDifference(this.value, value).length > 0) {
                this.setFormValue(value);
            }
        } else if (isString(value)) {
            if (this.value !== value && (!isEmpty(this.value) || !isEmpty(value))) {
                this.setFormValue(value);
            }
        } else if (this.value !== value) {
            this.setFormValue(value);
        }
    }

    @action.bound
    setFormValue(value) {
        this.formField.onChange(value);
    }
}

export default MrbFieldSelectStore;
