import React from "react";
import { PropTypes } from "prop-types";

function MrbPageContentHeader({
    classNameExtend,
    classNameOverride,
    children,
}) {
    return (
        <div className={classNameOverride || classNameExtend}>{children}</div>
    );
}

MrbPageContentHeader.propTypes = {
    children: PropTypes.any,
    classNameExtend: PropTypes.string,
    classNameOverride: PropTypes.string,
};

export default MrbPageContentHeader;
