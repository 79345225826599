import React from "react";
import PropTypes from "prop-types";
import { MrbMenuItem, MrbSecondaryMenuLevel } from "./";
import { observer } from "mobx-react";
import { map } from "lodash";

function MrbPrimaryMenuLevel({ menuStore, t = (i) => i }) {
    const { menu } = menuStore;
    return (
        <ul className="c-nav__wrapper">
            {map(menu, (item) => {
                return (
                    <MrbMenuItem
                        key={t(item.title)}
                        menuStore={menuStore}
                        item={item}
                        t={t}
                        subMenuRenderer={() => <MrbSecondaryMenuLevel menuStore={menuStore} t={t} />}
                    />
                );
            })}
        </ul>
    );
}

MrbPrimaryMenuLevel.propTypes = {
    menuStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(MrbPrimaryMenuLevel);
