import React from "react";
import { AgGridColumn } from "@ag-grid-community/react";
import { MrbTableHeader } from "./";
import { mergeCss } from "../../../common/utils";
import { isBoolean, isFunction } from "lodash";

function mrbTableColumnRenderer(column, tableStore, HeaderComponent = MrbTableHeader, t = (i) => i) {
    if (isFunction(column.onCellClicked)) {
        column.cellClass = mergeCss("u-cursor--pointer", column.cellClass);
    }
    return (
        <AgGridColumn
            valueFormatter={(params) => tableStore.formatValue(params.value, params.colDef.format)}
            cellRendererFramework={column.cell}
            checkboxSelection={column.checkboxSelection}
            headerName={t(column.title)}
            key={column.key}
            field={column.key}
            minWidth={column.minWidth}
            width={column.width}
            headerCheckboxSelection={column.headerCheckboxSelection}
            maxWidth={column.maxWidth}
            sortable={isBoolean(column.sortable) ? column.sortable : false}
            format={column.format}
            onCellClicked={column.onCellClicked}
            cellClass={column.cellClass}
            suppressMovable={
                isBoolean(column.suppressMovable)
                    ? column.suppressMovable
                    : tableStore.config.defaultColumnDefinition.suppressMovable
            }
            keyCreator={column.keyCreator}
            headerComponentFramework={(props) =>
                React.createElement(HeaderComponent, {
                    ...props,
                    tableStore: tableStore,
                })
            }
            filter={isBoolean(column.filter) ? column.filter : tableStore.config.defaultColumnDefinition.filter}
            suppressSizeToFit={column.suppressSizeToFit}
        />
    );
}

export default mrbTableColumnRenderer;
