import { MrbBaseForm } from "mrb/core/form";
import { LocalizedField, localizationService } from "common/localization";
import { isFunction, filter } from "lodash";

function createLocalizedValidationMessage(rule) {
    return localizationService.t(rule.message);
}

class LocalizedForm extends MrbBaseForm {
    constructor(hooks, fields, options = {}) {
        super(hooks, fields, {
            mapRuleValidationMessage: createLocalizedValidationMessage,
            ...options,
        });

        if (this.getLocalizeFields().length > 0) {
            this.add({ key: "languageMetadata", fields: [] });
        }
    }

    getLocalizeFields() {
        if (this.setup && isFunction(this.setup)) {
            return filter(this.setup().fields, (s) => s.localize);
        }

        return [];
    }

    makeField(props) {
        return new LocalizedField(props);
    }

    destroy() {
        this.each((field) => {
            field.destroy();
        });
    }
}

export default LocalizedForm;
