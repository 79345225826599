import { action, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "../../core/stores";
import { extend, isFunction } from "lodash";

class MrbTabViewStore extends MrbBaseViewStore {
    @observable activeTabIndex = 0;

    config = {
        actions: {
            onTabChange: null,
        },
    };

    constructor(rootStore, config) {
        super(rootStore);
        makeObservable(this);
        this.config = extend({}, this.config, config);
    }

    @action.bound
    handleTabClick(tabIndex) {
        this.setActiveTabIndex(tabIndex);
        if (isFunction(this.config.actions.onTabChange)) {
            this.config.actions.onTabChange(tabIndex);
        }
    }

    @action.bound
    setActiveTabIndex(tabIndex) {
        this.activeTabIndex = tabIndex;
    }
}

export default MrbTabViewStore;
