import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { isFunction } from "lodash";

function MrbFieldLabel({ field, infoRenderer, infoLabelRenderer, t = (i) => i }) {
    const [isInfoOpen, setIsInfoOpen] = useState(false);

    const requiredMark =
        field.rules && field.rules.indexOf("required") !== -1 ? (
            <span className="u-type--color--warning"> *</span>
        ) : null;

    const info = field.extra && field.extra.info;
    const infoLabel = field.extra && field.extra.infoLabel;

    return (
        <label className="c-form__field__label" htmlFor={field.id}>
            <div className="u-display--flex u-display--flex--jc--space-between">
                <div>
                    {t(field.label)}
                    {requiredMark}
                </div>
                {isFunction(infoLabelRenderer) ? (
                    infoLabelRenderer({
                        info,
                        infoLabel,
                        setIsInfoOpen,
                    })
                ) : (
                    <React.Fragment>
                        {(info || infoRenderer) && (
                            <div className="c-form__field__info">
                                <span
                                    onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setIsInfoOpen(!isInfoOpen);
                                    }}
                                >
                                    <i className="u-icon u-icon--med u-icon--question u-mar--right--tny" />
                                    {infoLabel}
                                </span>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>

            {isInfoOpen && (
                <p className="c-validation c-validation--rules">
                    {infoRenderer
                        ? React.createElement(infoRenderer, {
                              field,
                              info,
                              isInfoOpen,
                              setIsInfoOpen,
                          })
                        : t(info)}
                </p>
            )}
        </label>
    );
}

MrbFieldLabel.propTypes = {
    field: PropTypes.object.isRequired,
    infoRenderer: PropTypes.func,
    t: PropTypes.func,
};

export default observer(MrbFieldLabel);
