import React from "react";
import { MrbFieldSelect } from "mrb/components";
import { ProducerLabel } from "common/components/producer";
import { components } from "react-select";

function ProducerFieldSelectTemplate({ isOptionDisabled, ...props }) {
    return (
        <MrbFieldSelect
            isOptionDisabled={isOptionDisabled}
            components={{ Option: ProducerOption, SingleValue: ProducerSelectedValue }}
            {...props}
        />
    );
}

function ProducerOption(props) {
    const { item } = props.data;
    const isUser = item && item.user !== null;

    return (
        <components.Option {...props}>
            <ProducerLabel name={props.data.label} isUser={isUser} user={item?.user} />
        </components.Option>
    );
}

function ProducerSelectedValue(props) {
    const { item } = props.data;
    const isUser = item && item.user !== null;

    return (
        <components.SingleValue {...props}>
            <ProducerLabel name={props.data.label} isUser={isUser} user={item?.user} />
        </components.SingleValue>
    );
}

export default ProducerFieldSelectTemplate;
