import React from "react";
import { StationListenersCellTemplate } from "themes/administration/analytics/components";

class StationListenersCell extends React.Component {
    render() {
        return <StationListenersCellTemplate {...this.props} />;
    }
}

export default StationListenersCell;
