const subscriptionPlans = {
    nakiTales: "naki-tales-premium",
    nakiNigun: "naki-nigun-premium",
    nakiRadioPremium: "naki-radio-premium",
    nakiRadioFree: "naki-radio-free",
    subscriptionBundleDuo: "subscription-bundle-duo",
    subscriptionBundleSolo: "subscription-bundle-solo",
    subscriptionBundleNaki23Black: "subscription-bundle-naki-23-black",
};

export default subscriptionPlans;
