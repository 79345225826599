import React from "react";
import { observer } from "mobx-react";
import { CustomerPaymentMethodInfoTemplate } from "themes/application/payment/components";
import { CustomerPaymentMethodInfoViewStore } from "application/payment/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new CustomerPaymentMethodInfoViewStore(rootStore, componentProps),
    "customerPaymentMethodInfoViewStore"
)
@observer
class CustomerPaymentMethodInfo extends React.Component {
    render() {
        return <CustomerPaymentMethodInfoTemplate {...this.props} />;
    }
}

export default CustomerPaymentMethodInfo;
