import { override } from "mobx";
import { MrbBaseRouteStore } from "mrb/core";
import { PaymentService, ProductService } from "common/services";

class TakePaymentRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PaymentService);
        this.productService = rootStore.createApplicationService(ProductService);
    }

    @override
    onBeforeEnter(fromState, toState) {
        const subscriptionPlanId = toState.params.planId;
        const subscription =
            this.rootStore.premiumSubscriptionStore.getSubscriptionBySubscriptionPlan(subscriptionPlanId);
        if (subscription) {
            return Promise.reject("master.application.premium.subscriptions");
        }
        return Promise.resolve();
    }

    async getSubscriptionPlan(planId) {
        const response = await this.productService.getSubscription(planId);
        return response.data;
    }

    async setupPaymentMethodRequest() {
        const response = await this.service.setupPaymentMethodRequest();
        return response.data;
    }

    preprocessPayment(planId) {
        return this.rootStore.premiumSubscriptionStore.preprocessPayment(planId);
    }

    postprocessPayment(subscriptionId) {
        return this.rootStore.premiumSubscriptionStore.postprocessPayment(subscriptionId);
    }

    subscribeUsingExistingPaymentMethod(planId) {
        return this.rootStore.premiumSubscriptionStore.subscribeUsingExistingPaymentMethod(planId);
    }
}

export default TakePaymentRouteStore;
