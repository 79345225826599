import { BaseService } from "mrb/core";
import { NakiNigunArtistApiRouteService } from "common/services";

class NakiNigunArtistService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new NakiNigunArtistApiRouteService());
    }

    updateActiveStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateActiveStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updatePremiumStatus(id, value, abortSignal = null) {
        const url = this.routeService.updatePremiumStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateVisibilityStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateVisibilityStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateMobileAppVisibilityStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateMobileAppVisibilityStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateNakiTouchVisibilityStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateNakiTouchVisibilityStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    updateFeaturedStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateFeaturedStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    findArtists(filter, abortSignal = null) {
        const url = this.routeService.findArtists(filter);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default NakiNigunArtistService;
