import { action, observable, makeObservable, runInAction } from "mobx";
import { MrbQueryUtility } from "../../filter";
import { MrbBaseViewStore } from "./";
import { forEach, isString } from "lodash";

class MrbBaseListViewStore extends MrbBaseViewStore {
    @observable isReady = observable.box(false);
    @observable.ref gridStore = null;
    queryUtility = null;
    layoutActions = null;

    constructor(rootStore, { layoutActions, queryConfig, navigateBack }) {
        super(rootStore);
        makeObservable(this);

        this.layoutActions = this._filterActionsWithPermission(layoutActions);
        this.navigateBack = this._createNavigateBackAction(navigateBack);

        const route = this.rootStore.routerStore.getCurrentRoute();
        if (route && route.data && route.data.title) {
            this.title = route.data.title;
        }

        this.queryUtility = new MrbQueryUtility(
            this.rootStore,
            async (filter) => {
                this.fetchResources(filter);
            },
            queryConfig || {}
        );

        this.fetchResources = this.fetchResources.bind(this);
    }

    async fetchResources(filter) {
        this.gridStore.suspend();
        try {
            const response = await this.find(filter);
            runInAction(() => {
                this.gridStore.setData(response);
                this.gridStore.resume();
            });
        } catch (err) {
            this.gridStore.resume();
            this.onFetchError(err);
        }
    }

    find(params) {
        throw new Error("Method not implemented.");
    }

    _filterActionsWithPermission(actions) {
        const result = {};
        forEach(actions, (action, key) => {
            if (!action.authorization || this.rootStore.userStore.hasPermission(action.authorization)) {
                result[key] = action;
            }
        });
        return result;
    }

    _createNavigateBackAction(action) {
        if (action && isString(action)) {
            const route = this.rootStore.routerStore.getRoute(action);
            if (route && (!route.authorization || this.rootStore.userStore.hasPermission(route.authorization))) {
                return {
                    title: route.data ? route.data.title : "Back",
                    routeName: route.name,
                };
            }
        }
    }

    onInit() {
        return this.queryUtility.initialize();
    }

    @action.bound
    setGridStore(gridStore) {
        this.gridStore = gridStore;
    }

    onFetchError(error) {
        throw error;
    }

    onDestroy() {
        this.gridStore.destroy();
    }
}

export default MrbBaseListViewStore;
