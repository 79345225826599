import { action, observable, makeObservable, runInAction } from "mobx";
import { merge, isFunction } from "lodash";
import { MrbContentLoaderStore } from "mrb/components/loader";

const DefaultConfig = {
    label: "Chart",
    actions: {
        find: () => { },
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            easing: "easeInOutQuad",
            duration: 520,
        },
    },
};

class DoughnutChartStore {
    @observable.ref data = [];
    @observable.ref labels = [];

    config = {};

    constructor(rootStore, config) {
        makeObservable(this);
        this.rootStore = rootStore;
        this.loaderStore = new MrbContentLoaderStore();

        this.config = merge(this.config, DefaultConfig, config);

        this.suspend = this.suspend.bind(this);
        this.resume = this.resume.bind(this);
    }

    suspend() {
        this.loaderStore.suspend();
    }

    resume() {
        this.loaderStore.resume();
    }

    generate(params, abortController = null) {
        return this.getResources(params, abortController);
    }

    @action.bound
    setDataSet(data) {
        if (isFunction(this.config.actions.preprocessData)) {
            data = this.config.actions.preprocessData(data);
        }
        const dataset = this.config.actions.mapDataset(data);
        const labels = this.config.actions.mapLabels(data);
        this.setLabels(labels);
        this.setData(dataset);
    }

    async getResources(params, abortController = null) {
        try {
            this.suspend();
            let data = await this.config.actions.find(params, abortController);
            runInAction(() => {
                this.setDataSet(data);
                this.resume();
            });
        } catch (err) {
            this.resume();
            if (isFunction(this.config.actions.onError)) {
                this.config.actions.onError(err);
            } else {
                throw err;
            }
        }
    }

    @action.bound
    setData(data) {
        this.data = data;
    }

    @action.bound
    setLabels(labels) {
        this.labels = labels;
    }
}

export default DoughnutChartStore;
