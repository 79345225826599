import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Image } from "common/components";
import { convertSecondsToHMS } from "common/utils";
import { MrbModal } from "mrb/components/modal";
import { MrbToggle } from "mrb/components/checkbox";
import { MrbButton } from "mrb/components/button";
import { observer } from "mobx-react";

const GenerateAudioFilesModalTemplate = observer(function GenerateAudioFilesModalTemplate({ modalParams, t }) {
    return (
        <MrbModal
            title="Generate Audio files"
            modalParams={modalParams}
            t={t}
            className="modal__content modal__content--lrg"
        >
            <GenerateAudioFiles
                id={modalParams.data.id}
                artworkUrl={modalParams.data.artworkUrl}
                title={modalParams.data.title}
                subtitle={modalParams.data.subtitle}
                duration={modalParams.data.duration}
                hlsStreamUrl={modalParams.data.hlsStreamUrl}
                audioFileBitrate={modalParams.data.audioFileBitrate}
                audioFileSize={modalParams.data.audioFileSize}
                audioStreamUrl={modalParams.data.audioStreamUrl}
                processHlsEncodingForSingleEntity={modalParams.data.processHlsEncodingForSingleEntity}
                processHlsEncodingForAllEntities={modalParams.data.processHlsEncodingForAllEntities}
                t={t}
                cancel={modalParams.close}
            />
            {/* <EditFeaturedSectionContent
                routeStore={modalParams.params.routeStore}
                item={modalParams.data.item}
                onUpdate={modalParams.close}
            /> */}
        </MrbModal>
    );
});

GenerateAudioFilesModalTemplate.propTypes = {
    modalParams: PropTypes.object.isRequired,
    t: PropTypes.func,
};

const GenerateAudioFiles = observer(function GenerateAudioFiles({
    id,
    artworkUrl,
    hlsStreamUrl,
    title,
    subtitle,
    duration,
    audioStreamUrl,
    processHlsEncodingForSingleEntity,
    processHlsEncodingForAllEntities,
    cancel,
}) {
    const [generateHls, setGenerateHls] = useState(false);
    const [generateMp3, setGenerateMp3] = useState(false);

    return (
        <div>
            <div className="u-display--flex u-display--flex--ai--center u-gap--base">
                <Image className="c-home__preview__icon" src={artworkUrl} width="50" height="50" />
                <div>
                    <p className="c-home__preview__title u-mar--bottom--small">{title}</p>
                    {subtitle && <p className="u-type--sml u-type--color--opaque">{subtitle}</p>}
                    {duration && <p className="u-type--sml u-type--color--opaque">{convertSecondsToHMS(duration)}</p>}
                </div>
            </div>

            <div className="u-separator--primary u-separator--primary--sml"></div>

            <div>
                <MrbToggle
                    classNameOverride="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between  u-mar--bottom--sml"
                    id="generate-hls-file"
                    label="Generate HLS"
                    value={generateHls}
                    onChange={(event) => setGenerateHls(event.target.checked)}
                />
                {generateHls && hlsStreamUrl && (
                    <div className="c-card c-card--message c-card--message--password u-mar--bottom--sml">
                        Existing HLS files will be overwritten.
                    </div>
                )}
                <MrbToggle
                    classNameOverride="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between  u-mar--bottom--sml"
                    id="generate-mp3-file"
                    label="Upload mp3"
                    value={generateMp3}
                    onChange={(event) => setGenerateMp3(event.target.checked)}
                />
                {generateMp3 && audioStreamUrl && (
                    <div className="c-card c-card--message c-card--message--password u-mar--bottom--sml">
                        Existing mp3 audio file will be overwritten.
                    </div>
                )}
            </div>

            <div className="u-mar--top--med u-display--flex u-display--flex--jc--flex-end u-gap--sml">
                <MrbButton classNameOverride="c-btn c-btn--base c-btn--ghost" label="Cancel" onClick={cancel} />
                <MrbButton
                    label="Generate"
                    disabled={!generateHls && !generateMp3}
                    onClick={
                        processHlsEncodingForSingleEntity
                            ? () => processHlsEncodingForSingleEntity(id, generateHls, generateMp3)
                            : () => processHlsEncodingForAllEntities(generateHls, generateMp3)
                    }
                />
            </div>
        </div>
    );
});

GenerateAudioFiles.propTypes = {
    artworkUrl: PropTypes.string,
    hlsStreamUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    duration: PropTypes.number,
    cancel: PropTypes.func,
};

export default GenerateAudioFilesModalTemplate;
