import React, { useCallback, useState } from "react";
import ContentLoader from "react-content-loader";

const ContentStatsListLoaderTemplate = (props) => {
    const [loaderWidth, setLoaderWidth] = useState(0);

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    const loaderList = [];
    let y = 0;

    for (let i = 0; i < 10; i++) {
        loaderList.push(
            <React.Fragment>
                <rect x="12" y={y + 12} rx="6" ry="6" width="12" height="24" />
                <rect x="9%" y={y} rx="0" ry="0" width="48" height="48" />
                <rect x="18%" y={y + 18} rx="6" ry="6" width="100" height="12" />
                <rect x={loaderWidth - 70} y={y + 18} rx="6" ry="6" width="70" height="12" />
                <rect x={0} y={y + 60} rx="6" ry="6" width="100%" height="1" />
            </React.Fragment>
        );

        y += 74;
    }

    return (
        <div ref={ref}>
            <ContentLoader
                speed={2}
                width="100%"
                height={loaderList.length * 72}
                viewBox={`0 0 ${loaderWidth} ${loaderList.length * 72}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                {loaderList}
            </ContentLoader>
        </div>
    );
};

export default ContentStatsListLoaderTemplate;
