import { BaseApiRouteService } from "mrb/core";

class DeviceAnalyticsApiRouteService extends BaseApiRouteService {
    constructor() {
        super("analytics/devices");
    }

    getUserActivitySummary(userId) {
        return super.get(`${this.base}/user/{id}/summary`, userId);
    }

    getDeviceActivity(filter) {
        return super.find(`${this.base}/{?from,to,timeZone}`, filter);
    }

    getUserDeviceActivity(filter) {
        return super.find(`${this.base}/user-activity/{userId}{?deviceIds,from,to,page,rpp,sort}`, filter);
    }

    getDeviceActivitySummary(filter) {
        return super.find(`${this.base}/summary{?from,to,previousCycleFrom,previousCycleTo}`, filter);
    }
}

export default DeviceAnalyticsApiRouteService;
