import { BaseService } from "mrb/core";
import { StationFileApiRouteService } from "common/services";

class StationFileService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new StationFileApiRouteService());
    }

    deleteByStation(stationId, abortSignal = null) {
        const url = this.routeService.deleteByStation(stationId);
        return this.apiClient.delete(url, null, null, abortSignal);
    }
}

export default StationFileService;
