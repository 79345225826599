import React from "react";
import ContentLoader from "react-content-loader";

const PreviewContentLoaderTemplate = ({ props, row = 3 }) => {
    const screenwidth = window.innerWidth;
    const loaderHeight = row * 63 + 30;
    let loaderWidth;

    if (screenwidth > 1200) {
        loaderWidth = screenwidth - 690;
    } else if (screenwidth > 988) {
        loaderWidth = screenwidth - 200;
    } else {
        loaderWidth = screenwidth - 64;
    }

    const items = [];
    let y = 0;

    for (let i = 0; i < row; i++) {
        if (i + 1 === row) {
            items.push(
                <React.Fragment key={`_${i}`}>
                    <rect
                        x="0"
                        y={y + 5}
                        rx="6"
                        ry="6"
                        width="10%"
                        height="8"
                    />
                    {/* prettier-ignore */}
                    <rect x="0" y={y + 25} rx="6" ry="6" width="48%" height="50" />
                </React.Fragment>
            );
        } else {
            items.push(
                <React.Fragment key={`${i}_`}>
                    <rect x="0" y={y} rx="6" ry="6" width="10%" height="8" />
                    {/* prettier-ignore */}
                    <rect x="0" y={y + 20} rx="6" ry="6" width="48%" height="14" />
                    <rect x="51%" y={y} rx="6" ry="6" width="10%" height="8" />
                    {/* prettier-ignore */}
                    <rect x="51%" y={y + 20} rx="6" ry="6" width="48%" height="14" />
                </React.Fragment>
            );
        }

        y += 70;
    }

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={loaderHeight}
            viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            {items}
        </ContentLoader>
    );
};

export default PreviewContentLoaderTemplate;
