import { localStorageHandler } from "./";
import { StorageProvider } from "./";

class LocalStorageProvider extends StorageProvider {
    constructor() {
        super(localStorageHandler);
    }
}

const localStorageProvider = new LocalStorageProvider();
export default localStorageProvider;
