import React from "react";
import ContentLoader from "react-content-loader";

const FormInputsLoaderTemplate = ({ containerWidth, col = 2, row = 5, noBackground, button }) => {
    return (
        <div className={`c-container--${containerWidth}`}>
            {noBackground ? (
                <React.Fragment>
                    <InputLoader containerWidth={containerWidth} col={col} row={row} button={button} />
                </React.Fragment>
            ) : (
                <div className="c-card--primary">
                    <InputLoader containerWidth={containerWidth} col={col} row={row} button={button} />
                </div>
            )}
            <Button />
        </div>
    );
};

const InputLoader = ({ containerWidth, col, row }) => {
    const items = [];
    // prettier-ignore
    const viewWidth = containerWidth === "base" ? 1440 : containerWidth === "sml" ? 768 : containerWidth === "tny" ? 550 : 1920;
    const colItems = 100 / col;

    const smlSpace = colItems;
    const lrgSpace = 50;

    let x = 0;
    let x2 = 0;

    let y = 10;
    let y2 = 30;

    for (let i = 0; i < row; i++) {
        for (let j = 0; j < col; j++) {
            items.push(
                <React.Fragment key={`${i}_${j}`}>
                    {/* prettier-ignore */}
                    <rect x={`${x}%`} y={y} rx="6" ry="6" width="100" height="10" />
                    {/* prettier-ignore */}
                    <rect x={`${x2}%`} y={y2} rx="6" ry="6" width="100%" height="38" />
                </React.Fragment>
            );

            x += col > 2 ? smlSpace : lrgSpace;
            x2 += col > 2 ? smlSpace : lrgSpace;
        }

        x = 0;
        x2 = 0;

        y += 75;
        y2 += 75;
    }
    return (
        <ContentLoader
            speed={2}
            width={viewWidth}
            height="100%"
            viewBox={`0 0 ${viewWidth} 150`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
        >
            {items}
        </ContentLoader>
    );
};

export default FormInputsLoaderTemplate;

const Button = () => {
    return (
        <ContentLoader
            speed={2}
            width={100}
            height="100%"
            viewBox={`0 0 ${100} 30`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
        >
            <rect x="0" y="0" rx="3" ry="3" width="100" height="30" />
        </ContentLoader>
    );
};
