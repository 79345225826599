import React from "react";
import { PropTypes } from "prop-types";
import { AnalyticsCounter, AnalyticsStatsLoader } from "administration/analytics/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { numberFormatter } from "common/utils";
import { observer } from "mobx-react";
import { MrbContentLoader } from "mrb/components/loader";

function PortalUserDeviceTemplate({ portalViewStore, t }) {
    const {
        data,
        loaderStore,
        rootStore: { routerStore },
    } = portalViewStore;

    return (
        <div className="col col-sml-12 col-lrg-6 u-mar--bottom--med">
            <h1 className="c-analytics__title">Users & Devices</h1>
            <div className="c-analytics__card">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <AnalyticsStatsLoader />}>
                    {() => (
                        <React.Fragment>
                            {data && (
                                <div className="u-animation--fadeIn">
                                    <div className="u-mar--bottom--sml">
                                        <MrbRouterLink
                                            routeName="master.administration.analytics.premium-users"
                                            queryParams={routerStore.routerState.queryParams}
                                        >
                                            <div className="c-analytics__item">
                                                <div>
                                                    <p className="u-type--sml u-type--color--opaque u-display--flex u-display--flex--ai--center">
                                                        Premium users
                                                        <i className="u-icon u-icon--base u-icon--arrow-right u-mar--left--tny"></i>
                                                    </p>
                                                    <p className="u-type--color--text">
                                                        {numberFormatter.format(data.subscriptions.total)}
                                                    </p>
                                                </div>

                                                <AnalyticsCounter
                                                    arrowRight
                                                    filteredBy={data.filteredBy}
                                                    currentCycleNumber={data.subscriptions.currentCycleTotal}
                                                />
                                            </div>
                                        </MrbRouterLink>
                                    </div>
                                    <div className="u-mar--bottom--sml">
                                        <MrbRouterLink
                                            routeName="master.administration.analytics.registered-devices"
                                            queryParams={routerStore.routerState.queryParams}
                                        >
                                            <div className="c-analytics__item">
                                                <div>
                                                    <p className="u-type--sml u-type--color--opaque u-display--flex u-display--flex--ai--center">
                                                        Registered devices
                                                        <i className="u-icon u-icon--base u-icon--arrow-right u-mar--left--tny"></i>
                                                    </p>
                                                    <p className="u-type--color--text">
                                                        {numberFormatter.format(data.registeredUserDevices.total)}{" "}
                                                    </p>
                                                </div>

                                                <AnalyticsCounter
                                                    arrowRight
                                                    filteredBy={data.filteredBy}
                                                    currentCycleNumber={data.registeredUserDevices.currentCycleTotal}
                                                />
                                            </div>
                                        </MrbRouterLink>
                                    </div>
                                    <MrbRouterLink
                                        routeName="master.administration.analytics.registered-users"
                                        queryParams={routerStore.routerState.queryParams}
                                    >
                                        <div className="c-analytics__item">
                                            <div>
                                                <p className="u-type--sml u-type--color--opaque u-display--flex u-display--flex--ai--center">
                                                    Portal users
                                                    <i className="u-icon u-icon--base u-icon--arrow-right u-mar--left--tny"></i>
                                                </p>
                                                <p className="u-type--color--text">
                                                    {numberFormatter.format(data.registeredUsers.total)}
                                                </p>
                                            </div>
                                            <AnalyticsCounter
                                                arrowRight
                                                filteredBy={data.filteredBy}
                                                currentCycleNumber={data.registeredUsers.currentCycleTotal}
                                            />
                                        </div>
                                    </MrbRouterLink>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
        </div>
    );
}

PortalUserDeviceTemplate.propTypes = {
    portalViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(PortalUserDeviceTemplate);
