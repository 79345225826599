import { find, split, toLower } from "lodash";

class BaasicPermissionService {
    hasPermission = (user, authorization) => {
        let hasPermission = false;

        if (user) {
            if (user.permissions) {
                if (authorization) {
                    const tokens = split(authorization, ".");
                    if (tokens.length > 0) {
                        const section = toLower(tokens[0]);

                        const sectionPermissions = find(
                            user.permissions,
                            (value, key) => toLower(key) === section
                        );
                        if (sectionPermissions) {
                            if (tokens.length > 1) {
                                const action = toLower(tokens[1]);
                                for (
                                    var i = 0;
                                    i < sectionPermissions.length;
                                    i++
                                ) {
                                    if (
                                        toLower(sectionPermissions[i]) ===
                                        action
                                    ) {
                                        hasPermission = true;
                                        break;
                                    }
                                }
                            } else {
                                hasPermission = true;
                            }
                        }
                    }
                } else {
                    hasPermission = true;
                }
            }
        }

        return hasPermission;
    };
}

export default BaasicPermissionService;
