import { override } from "mobx";
import { MrbBaseRouteStore } from "mrb/core";
import { subscriptionStatuses } from "common/constants";
import { PaymentService, ProductService } from "common/services";
import { isNil } from "lodash";

class UpgradeTrialRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PaymentService);
        this.productService = rootStore.createApplicationService(ProductService);
    }

    @override
    onBeforeEnter(fromState, toState) {
        const subscriptionId = toState.params.subscriptionId;
        const subscription = this.rootStore.premiumSubscriptionStore.getSubscription(subscriptionId);
        if (
            subscription &&
            subscription.subscriptionStatus.abrv === subscriptionStatuses.trial &&
            !isNil(subscription.endDate)
        ) {
            return Promise.resolve();
        }
        return Promise.reject("master.application.premium.subscriptions");
    }

    async getSubscriptionPlan(planId) {
        const response = await this.productService.getSubscription(planId);
        return response.data;
    }

    async setupPaymentMethodRequest() {
        const response = await this.service.setupPaymentMethodRequest();
        return response.data;
    }
}

export default UpgradeTrialRouteStore;
