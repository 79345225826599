import { mrbCacheManager } from "./";

class BaseCacheService {
    constructor(cacheKey, region, fetchFn) {
        this.cacheKey = cacheKey;
        this.region = region;
        this.fetchFn = fetchFn;
    }

    getItems() {
        return mrbCacheManager.getOrAdd(
            this.cacheKey,
            this.fetchFn,
            this.region
        );
    }

    removeItems() {
        mrbCacheManager.remove(this.cacheKey, this.region);
    }

    setItems(data) {
        if (!data) {
            return;
        }
        mrbCacheManager.add(this.cacheKey, data, this.region);
    }
}

export default BaseCacheService;
