import { observable, action, makeObservable } from "mobx";
import { errorFormatterService } from "../services";
import { httpStatusCodes } from "../constants";
import { isObject, isUndefined, isFunction } from "lodash";

class MrbErrorStore {
    internalException = null;
    @observable.ref error = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
        // We must initialize the MrbErrorStore early as possible so don't place it in application startup pipeline
        this.initialize();

        this.handleApiError = this.handleApiError.bind(this);
    }

    @action.bound
    setError(error) {
        this.error = error;
    }

    initialize() {
        this.initializeGlobalUnhadledRejectionListener();
        this.initializeGlobalErrorListener();
    }

    initializeGlobalUnhadledRejectionListener() {
        const self = this;
        // Catch all unhandled promise rejections
        window.addEventListener("unhandledrejection", (event) => {
            event.stopPropagation();
            event.preventDefault();

            const { reason } = event;

            if (isObject(reason)) {
                if (!isUndefined(reason.request) && !isUndefined(reason.statusCode)) {
                    return this.handleApiError(self.rootStore, reason);
                }
            }

            return true;
        });
    }

    handleError(error, handlerFn = null) {
        if (isObject(error)) {
            if (!isUndefined(error.request) && !isUndefined(error.statusCode)) {
                if (
                    error.statusCode === httpStatusCodes.Unauthorized ||
                    error.statusCode === httpStatusCodes.Forbidden
                ) {
                    //TODO: check if we need this
                    this.rootStore.authenticationStore.syncToken();
                    if (this.rootStore.authenticationStore.isAuthenticated) {
                        return this.rootStore.routerStore.goToUnauthorized();
                    } else {
                        return this.rootStore.routerStore.goToLogin();
                    }
                }
            }
        }
        if (isFunction(handlerFn)) {
            handlerFn(error);
        }
    }

    initializeGlobalErrorListener() {
        const self = this;
        window.addEventListener("error", (event) => {
            event.stopPropagation();
            event.preventDefault();
            self.setError({
                message: "Something went wrong. Please contact support.",
            });
            self.rootStore.routerStore.goTo(self.rootStore.routerStore.errorState);
        });
    }

    handleApiError(reason) {
        const { data, request, message, statusCode, headers, config } = reason;
        // This request is handled locally in AccountActivationRouteStore so it should never happen. Due to some bug in the
        // Baasic SDK, request promise is rejected twice. Explore what causes that issue and remove this chunk of code.
        if (request.url.includes("/register/activate/")) {
            return;
        }

        if (statusCode === httpStatusCodes.Unauthorized || statusCode === httpStatusCodes.Forbidden) {
            //TODO: check if we need this
            this.rootStore.authenticationStore.syncToken();
            if (this.rootStore.authenticationStore.isAuthenticated) {
                return this.rootStore.routerStore.goToUnauthorized();
            } else {
                return this.rootStore.routerStore.goToLogin();
            }
        }

        const error = errorFormatterService.getErrorObject(data, message, statusCode, headers, config);

        this.rootStore.errorStore.setError(error);
        if (this.rootStore.routerStore.routerState.routeName !== this.rootStore.routerStore.errorState.routeName) {
            this.rootStore.routerStore.goTo(this.rootStore.routerStore.errorState);
        }
    }
}

export default MrbErrorStore;
