import { action, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "./";
import { httpStatusCodes } from "../../constants";
import { forEach, isString } from "lodash";

class MrbBasePreviewViewStore extends MrbBaseViewStore {
    @observable item = null;

    constructor(rootStore, { id, navigateBack, layoutActions = {} }) {
        super(rootStore);
        makeObservable(this);

        this.id = id;
        this.layoutActions = this._filterActionsWithPermission(layoutActions);
        this.navigateBack = this._createNavigateBackAction(navigateBack);
    }

    onInit() {
        return this.initializeResource();
    }

    get(id) {
        throw new Error("Not implemented.");
    }

    async initializeResource() {
        if (this.id) {
            try {
                await this.getResource(this.id);
                return true;
            } catch (err) {
                if (err.statusCode === httpStatusCodes.NotFound) {
                    await this.rootStore.routerStore.goToNotFound();
                    return false;
                } else {
                    throw err;
                }
            }
        }
    }

    async getResource(id) {
        const item = await this.get(id);
        this.setItem(item);
    }

    @action.bound
    setItem(item) {
        this.item = item;
    }

    _filterActionsWithPermission = (actions) => {
        const result = {};
        forEach(actions, (action, key) => {
            if (
                !action.authorization ||
                this.rootStore.userStore.hasPermission(action.authorization)
            ) {
                result[key] = action;
            }
        });
        return result;
    };

    _createNavigateBackAction(action) {
        if (action && isString(action)) {
            const route = this.rootStore.routerStore.getRoute(action);
            if (
                route &&
                (!route.authorization ||
                    this.rootStore.userStore.hasPermission(route.authorization))
            ) {
                return {
                    title: route.data ? route.data.title : "Back",
                    routeName: route.name,
                };
            }
        }
    }
}

export default MrbBasePreviewViewStore;
