import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "./";
import { observer } from "mobx-react";

function MrbFeedbackButton({
    viewStore,
    label,
    type,
    disabled,
    authorization,
    t = (i) => i,
}) {
    const { status } = viewStore;
    return (
        <MrbButton
            disabled={
                disabled ||
                status.get() === viewStore.statusType.inProgress ||
                status.get() === viewStore.statusType.success ||
                status.get() === viewStore.statusType.error
            }
            onClick={viewStore.onClick}
            label={label}
            icon={viewStore.currentIcon}
            type={type}
            authorization={authorization}
            className={viewStore.currentClassName}
            t={t}
        />
    );
}

MrbFeedbackButton.propTypes = {
    viewStore: PropTypes.object.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    authorization: PropTypes.string,
    t: PropTypes.func,
};

export default observer(MrbFeedbackButton);
