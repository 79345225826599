import React from "react";
import { PropTypes } from "prop-types";
import { MrbInfiniteScrollGridEmptyState } from "./components";
import { MrbContentLoader, MrbRectangleContentLoader } from "../loader";
import InfiniteScroll from "react-infinite-scroller";
import { map, isUndefined } from "lodash";
import { observer } from "mobx-react";
import { mergeOrOverrideCss } from "../../common/utils";

function MrbInfiniteScrollGrid({
    store,
    cellRenderer,
    t,
    classNameOverride,
    classNameExtend,
    scrollParent,
    emptyStateRenderer = () => <MrbInfiniteScrollGridEmptyState />,
    loadMoreLoader,
    loaderRenderer = () => <MrbRectangleContentLoader rows={10} columns={10} xRadius={10} yRadius={10} />,
}) {
    const {
        dataInitialized,
        data,
        config: { actions, onCellClick },
        formatValue,
        loaderStore,
        generateCellKey,
        hasMoreData,
        loadMore,
    } = store;
    return (
        <MrbContentLoader loaderStore={loaderStore} loaderRender={loaderRenderer}>
            {() => (
                <React.Fragment>
                    {dataInitialized && (
                        <InfiniteScroll
                            pageStart={0}
                            hasMore={hasMoreData}
                            loadMore={loadMore}
                            loader={loadMoreLoader}
                            getScrollParent={!isUndefined(scrollParent) ? () => scrollParent : null}
                            useWindow={isUndefined(scrollParent)}
                        >
                            {data && data.length > 0 ? (
                                <div className="u-animation--fadeIn">
                                    <div
                                        className={mergeOrOverrideCss(
                                            "u-display--flex u-display--flex--wrap",
                                            classNameOverride,
                                            classNameExtend
                                        )}
                                    >
                                        {map(data, (item, index) => (
                                            <React.Fragment key={generateCellKey(item, index)}>
                                                {cellRenderer({
                                                    item,
                                                    actions,
                                                    onCellClick,
                                                    valueFormatter: formatValue,
                                                    index,
                                                    t,
                                                })}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <React.Fragment>{emptyStateRenderer()}</React.Fragment>
                            )}
                        </InfiniteScroll>
                    )}
                </React.Fragment>
            )}
        </MrbContentLoader>
    );
}

MrbInfiniteScrollGrid.propTypes = {
    store: PropTypes.object.isRequired,
    pagerRenderer: PropTypes.any,
    cellRenderer: PropTypes.func.isRequired,
    emptyStateRenderer: PropTypes.any,
    loaderRenderer: PropTypes.any,
    loadMoreLoader: PropTypes.any,
    scrollParent: PropTypes.any,
    t: PropTypes.func,
};

export default observer(MrbInfiniteScrollGrid);
