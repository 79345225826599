import React from "react";
import { PropTypes } from "prop-types";
import { MrbToggle } from "mrb/components/checkbox";
import { MrbFieldRadioButtons } from "mrb/components/radio";
import { observer } from "mobx-react";
import { head } from "lodash";

function ShuffleWeightPickerTemplate({
    field,
    shuffleDisabled,
    shuffleWeightOptions,
    t,
}) {
    function onChange(event) {
        if (!event.target.checked) {
            field.set(shuffleDisabled.id);
        } else {
            field.set(head(shuffleWeightOptions).value);
        }
    }

    const toggleId = `${field.id}-weight-toggle`;
    return (
        <div className="u-display--flex u-display--flex--wrap">
            <div className="c-shuffle__action__item u-mar--right--lrg">
                {shuffleDisabled && (
                    <div>
                        <label
                            className="c-form__field__label"
                            htmlFor={toggleId}
                        >
                            {t("Shuffle")}
                        </label>
                        <MrbToggle
                            id={toggleId}
                            onChange={onChange}
                            value={field.value !== shuffleDisabled.id}
                        />
                    </div>
                )}
            </div>
            <div className="c-shuffle__action__item ">
                <MrbFieldRadioButtons
                    field={field}
                    t={t}
                    disabled={
                        field.value === shuffleDisabled.id || field.disabled
                    }
                    options={shuffleWeightOptions}
                />
            </div>
        </div>
    );
}

ShuffleWeightPickerTemplate.propTypes = {
    field: PropTypes.object.isRequired,
    shuffleWeightOptions: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(ShuffleWeightPickerTemplate);
