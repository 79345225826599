import React from "react";
import { PasswordFieldInfoTemplate } from "themes/common/components";
import { PasswordFieldInfoViewStore } from "common/components";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PasswordFieldInfoViewStore(rootStore, componentProps),
    "passwordFieldInfoViewStore"
)
class PasswordFieldInfo extends React.Component {
    render() {
        return <PasswordFieldInfoTemplate {...this.props} />;
    }
}

export default PasswordFieldInfo;
