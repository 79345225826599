import React from "react";
import PropTypes from "prop-types";

function MrbEditContent({ children }) {
    return <React.Fragment>{children}</React.Fragment>;
}

MrbEditContent.propTypes = {
    children: PropTypes.any,
};

export default MrbEditContent;
