import React from "react";
import { PropTypes } from "prop-types";
import {
    MrbSimpleSelectPanelContainer,
    MrbSimpleSelectValueContainer,
} from "./components";
import { observer } from "mobx-react";
import { mergeOrOverrideCss } from "../../common/utils";

function MrbSimpleSelect({
    store,
    t = (i) => i,
    optionRender,
    valueRender,
    showDropdownIndicator,
    classNameOverride,
    classNameExtend,
    dropdownClassNameExtend,
}) {
    return (
        <div
            className={mergeOrOverrideCss(
                "c-select",
                classNameOverride,
                classNameExtend
            )}
        >
            <MrbSimpleSelectValueContainer
                store={store}
                t={t}
                valueRender={valueRender}
                showDropdownIndicator={showDropdownIndicator}
            />
            <MrbSimpleSelectPanelContainer
                store={store}
                optionRender={optionRender}
                t={t}
                dropdownClassNameExtend={dropdownClassNameExtend}
            />
        </div>
    );
}

MrbSimpleSelect.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func,
    optionRender: PropTypes.func,
    showDropdownIndicator: PropTypes.bool,
};

export default observer(MrbSimpleSelect);
