import { action, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";

class AdminProducerAccountsListViewStore extends MrbBaseViewStore {
    producerId = null;

    @observable.ref accounts = [];

    constructor(rootStore, { producerId, routeStore }) {
        super(rootStore);
        this.routeStore = routeStore;
        makeObservable(this);

        this.producerId = producerId;
    }

    onInit() {
        return this.getProducerAccounts(this.producerId);
    }

    @action.bound
    async getProducerAccounts(producerId) {
        try {
            const accounts = await this.routeStore.getProducerUserAccountsSummary(producerId);
            this.setAccounts(accounts);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load producer accounts. Please contact the support team."
            );
        }
    }

    @action.bound
    setAccounts(accounts) {
        this.accounts = accounts;
    }
    @action.bound
    selectAccount(account) {
        this.rootStore.routerStore.goTo("master.administration.producer.account", {
            id: account.producerUserAccountId,
        });
    }
}

export default AdminProducerAccountsListViewStore;
