import React from "react";
import { PremiumContentListItemTemplate } from "themes/application/payment/components";
import { PremiumContentListItemViewStore } from "application/payment/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new PremiumContentListItemViewStore(rootStore, componentProps),
    "premiumContentListItemViewStore"
)
class PremiumContentListItem extends React.Component {
    render() {
        return <PremiumContentListItemTemplate {...this.props} />;
    }
}

export default PremiumContentListItem;
