import { MrbBaseRouteStore } from "mrb/core";
import { ProducerUserAccountService } from "common/services";

class AdminProducerAccountPreviewRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
        this.producerUserAccountService = rootStore.createApplicationService(ProducerUserAccountService);
    }

    async getAdminProducerAccountBalance(filter) {
        const response = await this.producerUserAccountService.getAdminProducerAccountBalance(filter);
        return response.data;
    }

    async getAdminProducerAccountRevenueTransaction(filter) {
        const response = await this.producerUserAccountService.getAdminProducerAccountRevenueTransaction(filter);
        return response.data;
    }

    async getAdminProducerAccountSummary(accountId) {
        const response = await this.producerUserAccountService.getAdminProducerAccountSummary(accountId);
        return response.data;
    }

    async getAdminProducerAccountAssignedProducers(accountId) {
        const response = await this.producerUserAccountService.getAdminProducerAccountAssignedProducers(accountId);
        return response.data;
    }

    async addAccountBalance(resource) {
        const response = await this.producerUserAccountService.addAccountBalance(resource);
        return response.data;
    }

    async updateStripeAccount(resource) {
        const response = await this.producerUserAccountService.updateStripeAccount(resource);
        return response.data;
    }
    async getStripeConnectAccounts() {
        const response = await this.producerUserAccountService.getStripeConnectAccounts();
        return response.data;
    }
}

export default AdminProducerAccountPreviewRouteStore;
