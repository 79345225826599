import { action, observable, makeObservable } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { AnalyticsTimeRangeViewStore, UserDeviceActivitySummaryViewStore } from "administration/analytics/stores";
import { DeviceHardwareCell, UserDeviceActivityContentCell } from "administration/analytics/components";
import { UserDeviceActivityFilter } from "administration/analytics/filters";
import { timeRangeType } from "administration/analytics/constants";
import { MrbTableViewStore } from "mrb/components/table";
import { MrbQuerySelectStore } from "mrb/components/select";
import { mrbDefaultFormats } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";

class UserDeviceActivityViewStore extends MrbBaseListViewStore {
    @observable.ref userPortalSummary = null;

    userId = null;

    constructor(rootStore, { routeStore }) {
        const userId = rootStore.routerStore.routerState.params.userId;
        super(rootStore, {
            queryConfig: {
                filter: new UserDeviceActivityFilter(),
                orderBy: "timeStamp",
                orderDirection: "desc",
                paramKeyMap: {
                    _range: "range",
                },
                ignoreQueryParams: ["timeZone", "userId"],
                queryParamMap: {
                    from: {
                        toQuery: (value) => {
                            if (this.queryUtility.filter.range === timeRangeType.custom) {
                                return value;
                            }
                            return undefined;
                        },
                    },
                    to: {
                        toQuery: (value) => {
                            if (this.queryUtility.filter.range === timeRangeType.custom) {
                                return value;
                            }
                            return undefined;
                        },
                    },
                },
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;
        this.userId = userId;

        this.analyticsTimeRangeViewStore = new AnalyticsTimeRangeViewStore(this.rootStore, {
            queryUtility: this.queryUtility,
        });

        this.deviceSelectStore = new MrbQuerySelectStore(
            {
                placeholder: "Select devices",
                isSearchable: false,
                virtualized: false,
                textKey: "deviceName",
                valueKey: "id",
                initFetch: true,
                isMulti: true,
                actions: {
                    find: (searchTerm) => {
                        const params = {
                            searchQuery: searchTerm,
                            userIds: userId,
                            embed: "device",
                            fields: "id,deviceName,device",
                        };
                        return this.routeStore.findUserDevices(params);
                    },
                    mapItem: (item) => {
                        return {
                            value: item.device.deviceIdentifier,
                            label: item.deviceName,
                            item: item,
                        };
                    },
                },
            },
            this.queryUtility,
            "deviceIds"
        );

        this.setGridStore(
            new MrbTableViewStore(this.rootStore, this.queryUtility, {
                columns: [
                    {
                        key: "device.deviceName",
                        title: "Device",
                    },
                    {
                        key: "deviceHardware",
                        title: "Device Hardware (Firmware)",
                        cell: DeviceHardwareCell,
                    },
                    {
                        key: "ipAddress",
                        title: "IP Address",
                    },
                    {
                        key: "",
                        title: "Content",
                        cell: UserDeviceActivityContentCell,
                    },
                    {
                        key: "trackTitle",
                        title: "Track",
                    },
                    {
                        key: "shuffle.name",
                        title: "Shuffle",
                    },
                    {
                        key: "timeStamp",
                        title: "Timestamp",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.date,
                            format: applicationDefaults.formats.dateTimeSeconds,
                        },
                    },
                ],
            })
        );

        this.userDeviceActivitySummaryViewStore = new UserDeviceActivitySummaryViewStore(this.rootStore, {
            userId: this.userId,
            routeStore: this.routeStore,
        });
    }

    async onInit() {
        await Promise.all([
            this.queryUtility.initialize(),
            this.getUserPortalStatsSummary(this.userId),
            this.userDeviceActivitySummaryViewStore.loadData(),
        ]);
    }

    find(params) {
        params.userId = this.userId;
        return this.routeStore.getUserDeviceActivity(params);
    }

    async getUserPortalStatsSummary(userId) {
        try {
            const data = await this.routeStore.getUserPortalStatsSummary(userId);
            this.setUserPortalSummary(data);
        } catch (err) {
            console.log(err);
        }
    }

    @action.bound
    setUserPortalSummary(item) {
        this.userPortalSummary = item;
    }

    onFetchError(err) {
        console.log(err);
        this.rootStore.notificationStore.error(
            "An unexpected error occurred while trying to load data. Please contact the support team."
        );
    }
}

export default UserDeviceActivityViewStore;
