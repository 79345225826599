import { BasePortalDetailedActivityViewStore } from "administration/analytics/stores";

class StationActivityViewStore extends BasePortalDetailedActivityViewStore {
    stationId = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            chartLabel: "Playbacks",
        });
        this.routeStore = routeStore;

        this.stationId = rootStore.routerStore.routerState.params.id;
    }

    getActivityChartData(params, abortController) {
        return this.routeStore.getStationActivity(this.stationId, params, abortController);
    }

    fetchSummary(params, abortController) {
        params.embed = "station";
        return this.routeStore.getStationSummary(this.stationId, params, abortController);
    }
}

export default StationActivityViewStore;
