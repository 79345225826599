import { BaseApiRouteService } from "mrb/core";

class UserContentApiRouteService extends BaseApiRouteService {
    constructor() {
        super("user-content");
    }

    find(filter) {
        return super.find(
            `${this.base}/{?searchQuery,deviceId,genreIds,isPremium,isEnabled,contentType,page,rpp,sort,embed,fields}`,
            filter
        );
    }

    findPremiumContentInfo(filter) {
        return super.find(`${this.base}/premium-info/{?contentType,page,rpp,sort,embed,fields}`, filter);
    }

    getSubPodcasts(id) {
        return super.find(`${this.base}/podcast/{id}/sub-podcasts`, {
            id: id,
        });
    }

    getPodcastVolumes(id) {
        return super.find(`${this.base}/podcast/{id}/volumes`, {
            id: id,
        });
    }

    genericSearch(filter) {
        return super.find(
            `${this.base}/generic-search/{?searchQuery,genreId,deviceId,contentType,page,rpp,sort,embed,fields}`,
            filter
        );
    }

    getUserDeviceStation(deviceId, stationId) {
        return super.get(`${this.base}/{deviceId}/station/{stationId}`, null, {
            deviceId: deviceId,
            stationId: stationId,
        });
    }

    getFeaturedContent(deviceId, contentType) {
        return super.get(`${this.base}/featured{?deviceId,contentType}`, null, {
            deviceId,
            contentType,
        });
    }

    getStationWthDeviceSettings(stationId, deviceId, options) {
        return super.get(`${this.base}/station/{stationId}/device/{deviceId}{?embed,fields}`, null, {
            deviceId: deviceId,
            stationId: stationId,
            ...options,
        });
    }

    getStation(stationId, options) {
        return super.get(`${this.base}/station/{stationId}{?embed,fields}`, null, {
            stationId: stationId,
            ...options,
        });
    }

    getUserDevicePodcast(deviceId, podcastId) {
        return super.get(`${this.base}/{deviceId}/podcast/{podcastId}`, null, {
            deviceId: deviceId,
            podcastId: podcastId,
        });
    }

    getPodcastGroupUserDevicePodcast(deviceId, podcastId) {
        return super.get(`${this.base}/{deviceId}/podcast-group/{podcastId}`, null, {
            deviceId: deviceId,
            podcastId: podcastId,
        });
    }

    getPodcastWthDeviceSettings(podcastId, deviceId, options) {
        return super.get(`${this.base}/podcast/{podcastId}/device/{deviceId}{?embed,fields}`, null, {
            deviceId: deviceId,
            podcastId: podcastId,
            ...options,
        });
    }

    getPodcast(podcastId, options) {
        return super.get(`${this.base}/podcast/{podcastId}{?embed,fields}`, null, {
            podcastId: podcastId,
            ...options,
        });
    }

    getPodcastGroupWthDeviceSettings(podcastId, deviceId, options) {
        return super.get(`${this.base}/podcast-group/{podcastId}/device/{deviceId}{?embed,fields}`, null, {
            deviceId: deviceId,
            podcastId: podcastId,
            ...options,
        });
    }

    getPodcastGroup(podcastId, options) {
        return super.get(`${this.base}/podcast-group/{podcastId}{?embed,fields}`, null, {
            podcastId: podcastId,
            ...options,
        });
    }

    updateStationsAndPodcastsTurnedOnStatus(resources, value) {
        return super.update(`${this.base}/turned-on/bulk{?value}`, {
            resources: resources,
            value: value,
        });
    }
}

export default UserContentApiRouteService;
