import { BaseService } from "mrb/core";
import { PodcastFileStreamApiRouteService } from "common/services";

class PodcastFileStreamService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new PodcastFileStreamApiRouteService());
    }

    get(id, params, abortSignal = null) {
        const url = this.routeService.get(id, params);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastCoverImageByPodcast(podcastId, params, abortSignal = null) {
        const url = this.routeService.getPodcastCoverImageByPodcast(podcastId, params);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastEpisodeAudioFile(id, abortSignal = null) {
        const url = this.routeService.getPodcastEpisodeAudioFile(id);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default PodcastFileStreamService;
