import { BaseApiRouteService } from "mrb/core";

class PaymentApiRouteService extends BaseApiRouteService {
    constructor() {
        super("payment");
    }

    activateTrial(planId) {
        return super.create(`${this.base}/trial/{planId}`, { planId });
    }

    endTrialPeriod(subscriptionId) {
        return super.delete(`${this.base}/subscription/{subscriptionId}/end-trial`, { subscriptionId });
    }

    upgradeTrialSubscription(subscriptionId) {
        return super.update(`${this.base}/subscription/{subscriptionId}/upgrade-trial`, { subscriptionId });
    }

    getUserPremiumInfo(options) {
        return super.get(`${this.base}/premium-info{?embed}`, null, options);
    }

    getLatestSubscriptionPayment(subscriptionId, options) {
        return super.get(`${this.base}/subscription/{id}/latest-payment{?embed}`, subscriptionId, options);
    }

    cancelSubscription(subscriptionId) {
        return super.delete(`${this.base}/subscription/{subscriptionId}/cancel`, { subscriptionId });
    }

    renewSubscription(subscriptionId) {
        return super.update(`${this.base}/subscription/{subscriptionId}/renew`, { subscriptionId });
    }

    changeSubscriptionPlan(planId) {
        return super.update(`${this.base}/subscription/change-plan/{planId}`, {
            planId,
        });
    }

    getSubscriptionChangePreview(subscriptionId, planId) {
        return super.get(`${this.base}/subscription/{subscriptionId}/change-plan/{planId}/preview`, null, {
            subscriptionId,
            planId,
        });
    }

    preprocess(planId) {
        return super.create(`${this.base}/preprocess/{planId}`, { planId });
    }

    postprocess() {
        return super.update(`${this.base}/postprocess`);
    }

    subscribeUsingExistingPaymentMethod(planId) {
        return super.create(`${this.base}/subscribe/{planId}`, { planId });
    }

    postprocessPaymentMethodUpdate() {
        return super.update(`${this.base}/payment-method/update/postprocess`);
    }

    setupPaymentMethodRequest() {
        return super.create(`${this.base}/setup-payment-method`);
    }

    updateDefaultPaymentMethod() {
        return super.update(`${this.base}/payment-method/update`);
    }
}

export default PaymentApiRouteService;
