import { action, observable, makeObservable } from "mobx";
import { default as MrbBaseAppStore } from "../core/stores/base/MrbBaseAppStore";

class BaasicOptions {
    constructor(config) {
        this.useSSL = config.useSSL;
        this.apiRootUrl = config.appUrl;
        this.apiVersion = config.apiVersion;
        this.apiKey = config.apiKey;
    }
}

class BaasicAppStore extends MrbBaseAppStore {
    @observable apps = observable.map();
    @observable applicationIdentifier = observable.box(null); // react to change of this property to detect application changes

    constructor(rootStore, baasicAppFactory) {
        super(rootStore);
        makeObservable(this);
        this.baasicAppFactory = baasicAppFactory;
    }

    setApp(app) {
        super.setApp(app);
        this.applicationIdentifier.set(app ? app.baasic.getApiKey() : null);
    }

    @action.bound
    register(applicationSettings) {
        let app = null;
        const { apiKey } = applicationSettings;
        if (!this.apps.has(apiKey)) {
            app = this.baasicAppFactory.create(
                apiKey,
                new BaasicOptions(applicationSettings)
            );

            this.apps.set(apiKey, app);
        } else {
            app = this.apps.get(apiKey);
        }

        this.setApp({ baasic: app });

        return app;
    }

    _getApiClient(app) {
        return app.baasic.apiClient;
    }
}

export default BaasicAppStore;
