import { useContext } from "react";
import { RootContext } from "mrb/core/contexts";
import { nakiRadioContentType } from "application/common/content-access";
import { isNil } from "lodash";

function useCanAccessContent(contentType, options) {
    const rootStore = useContext(RootContext);
    if (isNil(contentType)) {
        throw new Error("Missing contentType parameter.");
    }
    switch (contentType) {
        case nakiRadioContentType.podcast:
            return rootStore.contentAccessValidator.canAccessPodcast(options);
        case nakiRadioContentType.station:
            return rootStore.contentAccessValidator.canAccessStation(options);
        case nakiRadioContentType.nakiNigun:
            return rootStore.contentAccessValidator.canAccessNakiNigun(options);
        default:
            throw new Error(`Invalid contentType parameter: ${contentType}`);
    }
}

export default useCanAccessContent;
