import { action, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { httpStatusCodes } from "mrb/core/constants";

class PortalViewStore extends MrbBaseViewStore {
    @observable.ref data = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
    }

    onInit() {
        return Promise.resolve();
    }

    async getResources(filter, abortController) {
        try {
            this.loaderStore.suspend();
            const params = this.createParams(filter);
            const data = await this.routeStore.getPortalAnalytics(params, abortController);
            data.filteredBy = {
                range: filter.range,
                timeZone: filter.timeZone,
                ...params,
            };
            runInAction(() => {
                this.setData(data);
                this.loaderStore.resume();
            });
        } catch (error) {
            if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
                return;
            }
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load portal analytics.",
                error
            );
            this.loaderStore.resume();
        }
    }

    createParams(filter) {
        const obj = {
            from: filter.from,
            to: filter.to,
            previousCycleFrom: filter.previousCycleFrom,
            previousCycleTo: filter.previousCycleTo,
        };

        return obj;
    }

    @action.bound
    setData(data) {
        this.data = data;
    }
}

export default PortalViewStore;
