import React from "react";
import { renderComponent } from "./";

function renderParentComponent({ Parent, component }) {
    return (props) => (
        <Parent
            {...props}
            render={(renderProps) => {
                return renderComponent(component, renderProps);
            }}
        />
    );
}

export default renderParentComponent;
