import { BaseApiRouteService } from "mrb/core";

class ContentApiRouteService extends BaseApiRouteService {
    constructor() {
        super("content");
    }

    find(filter) {
        return super.find(`${this.base}/{?searchQuery,isPremium,isActive,page,rpp,sort}`, filter);
    }
}

export default ContentApiRouteService;
