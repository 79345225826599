import { computed, makeObservable } from "mobx";
import { UserContentService, NakiNigunUserContentService, UserTorahAnytimeCollectionService } from "common/services";
import { MrbModalParams } from "mrb/common/models";
import { nakiRadioSystemTypes } from "common/constants";
import { filter, isNil, map } from "lodash";

class UserContentManagerStore {
    @computed get selectedDeviceIds() {
        if (this.userDeviceStore.isDeviceSyncEnabled) {
            return map(this.userDeviceStore.devices, (device) => device.id);
        }
        if (!isNil(this.userDeviceStore.selectedDevice)) {
            return [this.userDeviceStore.selectedDevice.id];
        }
        return [];
    }

    @computed get mobileDevices() {
        return filter(
            this.userDeviceStore.devices,
            (userDevice) => userDevice.device.systemType.abrv === nakiRadioSystemTypes.mobile
        );
    }

    @computed get isContentUnavailableInAppPolicyVisible() {
        return this.rootStore.userStore.isContentUnavailableInAppPolicyVisible;
    }

    _userContentService = null;
    _nakiNigunUserContentService = null;
    _userTorahAnytimeCollectionService = null;

    get userContentService() {
        if (isNil(this._userContentService)) {
            this._userContentService = this.rootStore.createApplicationService(UserContentService);
        }
        return this._userContentService;
    }

    get nakiNigunUserContentService() {
        if (isNil(this._nakiNigunUserContentService)) {
            this._nakiNigunUserContentService = this.rootStore.createApplicationService(NakiNigunUserContentService);
        }
        return this._nakiNigunUserContentService;
    }

    get userTorahAnytimeCollectionService() {
        if (isNil(this._userTorahAnytimeCollectionService)) {
            this._userTorahAnytimeCollectionService = this.rootStore.createApplicationService(
                UserTorahAnytimeCollectionService
            );
        }
        return this._userTorahAnytimeCollectionService;
    }

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
        this.userDeviceStore = this.rootStore.userDeviceStore;

        this._disableContentUnavailableInAppPolicy = this._disableContentUnavailableInAppPolicy.bind(this);
        this.contentUnavailableModal = new MrbModalParams(null, {
            disableContentUnavailableInAppPolicy: this._disableContentUnavailableInAppPolicy,
        });
    }

    updateStationStatus(options, isEnabled) {
        if (
            this.userDeviceStore.isDeviceSyncEnabled &&
            options.isUnavailableInApp &&
            this.mobileDevices.length > 0 &&
            this.isContentUnavailableInAppPolicyVisible
        ) {
            this.contentUnavailableModal.open({
                stations: [options.title],
                devices: this.mobileDevices,
            });
        }
        return this._updateStationsAndPodcastsStatusInternal([options.stationId], [], isEnabled);
    }

    updateNakiNigunAlbumsStatus(options, isEnabled) {
        if (
            this.userDeviceStore.isDeviceSyncEnabled &&
            options.isUnavailableInApp &&
            this.mobileDevices.length > 0 &&
            this.isContentUnavailableInAppPolicyVisible
        ) {
            this.contentUnavailableModal.open({
                nakiNigunAlbums: [options.title],
                devices: this.mobileDevices,
            });
        }
        return this._updateAlbumsTurnedOnStatusInternal([options.albumId], isEnabled);
    }

    updateNakiNigunAlbumsStatusByArtist(options, isEnabled) {
        if (
            this.userDeviceStore.isDeviceSyncEnabled &&
            options.isUnavailableInApp &&
            this.mobileDevices.length > 0 &&
            this.isContentUnavailableInAppPolicyVisible
        ) {
            this.contentUnavailableModal.open({
                nakiNigunArtists: [options.name],
                devices: this.mobileDevices,
            });
        }
        return this._updateAlbumsTurnedOnStatusByArtistInternal([options.artistId], isEnabled);
    }

    updateNakiNigunAlbumsStatusBulk(resource) {
        return this._updateAlbumsTurnedOnStatusBulkInteral(resource);
    }

    updateNakiNigunAlbumsStatusByArtistBulk(resource) {
        return this._updateAlbumsTurnedOnStatusByArtistBulkInternal(resource);
    }

    async _updateAlbumsTurnedOnStatusInternal(albumIds, isEnabled) {
        const response = await this.nakiNigunUserContentService.updateAlbumsTurnedOnStatus(
            { deviceIds: this.selectedDeviceIds, albumIds: albumIds },
            isEnabled
        );
        return response.data;
    }

    async _updateAlbumsTurnedOnStatusBulkInteral(resource) {
        resource.deviceIds = this.selectedDeviceIds;
        const response = await this.nakiNigunUserContentService.updateAlbumsTurnedOnStatusBulk(resource);
        return response.data;
    }

    async _updateAlbumsTurnedOnStatusByArtistInternal(artistIds, isEnabled) {
        const response = await this.nakiNigunUserContentService.updateAlbumsTurnedOnStatusByArtist(
            { deviceIds: this.selectedDeviceIds, artistIds: artistIds },
            isEnabled
        );
        return response.data;
    }

    async _updateAlbumsTurnedOnStatusByArtistBulkInternal(resource) {
        resource.deviceIds = this.selectedDeviceIds;
        const response = await this.nakiNigunUserContentService.updateAlbumsTurnedOnStatusByArtistBulk(resource);
        return response.data;
    }

    async _updateStationsAndPodcastsStatusInternal(stationIds, podcastIds, isEnabled) {
        const response = await this.userContentService.updateStationsAndPodcastsTurnedOnStatus(
            {
                deviceIds: this.selectedDeviceIds,
                stationIds: stationIds,
                podcastIds: podcastIds,
            },
            isEnabled
        );

        return response.data;
    }

    async _updateTorahAnytimeCollectionsStatusInternal(collectionIds, isEnabled) {
        const response = await this.userTorahAnytimeCollectionService.updateCollectionsTurnedOnStatus(
            {
                deviceIds: this.selectedDeviceIds,
                collectionIds: collectionIds,
            },
            isEnabled
        );

        return response.data;
    }

    updateStationsAndPodcastsStatus(stationOptions, podcastOptions, isEnabled) {
        const unavailableStations = filter(stationOptions, (item) => item.isUnavailableInApp);
        const unavailablePodcasts = filter(podcastOptions, (item) => item.isUnavailableInApp);
        if (
            this.userDeviceStore.isDeviceSyncEnabled &&
            (unavailableStations.length > 0 || unavailablePodcasts.length > 0) &&
            this.mobileDevices.length > 0 &&
            this.isContentUnavailableInAppPolicyVisible
        ) {
            this.contentUnavailableModal.open({
                stations: map(unavailableStations, (item) => item.title),
                podcasts: map(unavailablePodcasts, (item) => item.title),
                devices: this.mobileDevices,
            });
        }
        const stationIds = map(stationOptions, (item) => item.stationId);
        const podcastIds = map(podcastOptions, (item) => item.podcastId);
        return this._updateStationsAndPodcastsStatusInternal(stationIds, podcastIds, isEnabled);
    }

    updatePodcastStatus(options, isEnabled) {
        if (
            this.userDeviceStore.isDeviceSyncEnabled &&
            options.isUnavailableInApp &&
            this.mobileDevices.length > 0 &&
            this.isContentUnavailableInAppPolicyVisible
        ) {
            this.contentUnavailableModal.open({
                podcasts: [options.title],
                devices: this.mobileDevices,
            });
        }
        return this._updateStationsAndPodcastsStatusInternal([], [options.podcastId], isEnabled);
    }

    updateTorahAnytimeCollectionsStatus(options, isEnabled) {
        if (
            this.userDeviceStore.isDeviceSyncEnabled &&
            options.isUnavailableInApp &&
            this.mobileDevices.length > 0 &&
            this.isContentUnavailableInAppPolicyVisible
        ) {
            this.contentUnavailableModal.open({
                podcasts: [options.torahAnytimePodcastTitle],
                devices: this.mobileDevices,
            });
        }
        return this._updateTorahAnytimeCollectionsStatusInternal([options.collectionId], isEnabled);
    }

    async _disableContentUnavailableInAppPolicy() {
        try {
            this.contentUnavailableModal.close();
            this.rootStore.mainLoaderStore.suspend();
            await this.rootStore.userStore.disableContentUnavailableInAppPolicy();
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to update user settings.",
                err
            );
        } finally {
            this.rootStore.mainLoaderStore.resume();
        }
    }
}

export default UserContentManagerStore;
