import { action, observable, makeObservable } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbTableViewStore } from "mrb/components";
import { mrbDefaultFormats } from "mrb/common/formatting";
import { ProducerPaymentReportFilter } from "administration/producer/filters";
import { ProducerNameCell } from "administration/producer/components";
import { ProducerPlaybackTimeCell } from "common/components/producer";
import { dateFormatter } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";
import { localizationService } from "common/localization";
import { numberFormatter } from "common/utils";
import { timeRangeType } from "administration/analytics/constants";
import { dateRangeFactory } from "administration/analytics/models";
import moment from "moment";
import "moment-timezone";

class AdminProducerListViewStore extends MrbBaseListViewStore {
    @observable.ref producersSummary;

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            layoutActions: {
                create: {
                    onClick: () => this.rootStore.routerStore.goTo("master.administration.producer.create"),
                    label: "ADMINISTRATION.PRODUCER.LIST.BUTTONS.ADD",
                },
            },
            queryConfig: {
                filter: new ProducerPaymentReportFilter(),
                orderBy: "currentMonthTotalProducerPayment",
                orderDirection: "desc",
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;

        const now = moment().subtract(1, "month").startOf("month");
        this.setTimeRange(now);

        const currentMonthLabel = dateFormatter.format(now, {
            format: applicationDefaults.formats.monthFull,
            timeZone: this.queryUtility.filter.timeZone,
        });
        this.setGridStore(
            new MrbTableViewStore(this.rootStore, this.queryUtility, {
                onRowClick: (node) => {
                    this.rootStore.routerStore.goTo("master.administration.producer.preview", {
                        id: node.data.producerId,
                    });
                },
                columns: [
                    {
                        key: "producerName",
                        title: "ADMINISTRATION.ANALYTICS.PREMIUM_CONTENT.LIST.COLUMNS.PRODUCER",
                        cell: ProducerNameCell,
                        sortable: true,
                    },
                    {
                        key: "currentMonthTotalProducerPlaybacks",
                        title: localizationService.t(
                            "ADMINISTRATION.ANALYTICS.PREMIUM_CONTENT.LIST.COLUMNS.TOTAL_PLAYBACK_TIME",
                            { month: currentMonthLabel }
                        ),
                        sortable: true,
                        cell: ProducerPlaybackTimeCell,
                    },
                    {
                        key: "currentMonthTotalProducerPlaybacks",
                        title: localizationService.t(
                            "ADMINISTRATION.ANALYTICS.PREMIUM_CONTENT.LIST.COLUMNS.TOTAL_PLAYBACK_POINTS",
                            { month: currentMonthLabel }
                        ),
                        format: (value) => numberFormatter.format(value),
                    },
                    {
                        key: "currentMonthTotalProducerPayment",
                        title: localizationService.t(
                            "ADMINISTRATION.ANALYTICS.PREMIUM_CONTENT.LIST.COLUMNS.TOTAL_PAYMENT",
                            { month: currentMonthLabel }
                        ),
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.currency,
                            currency: "USD",
                        },
                    },
                ],
                actions: {
                    edit: {
                        onClick: (item) =>
                            this.rootStore.routerStore.goTo("master.administration.producer.edit", {
                                id: item.producerId,
                            }),
                        label: "ADMINISTRATION.PRODUCER.LIST.ACTIONS.EDIT",
                        buttonProps: {
                            className: "c-btn c-btn--sml c-btn--ghost u-mar--right--tny",
                        },
                    },
                },
            })
        );
    }

    async onInit() {
        await Promise.all([this.queryUtility.initialize(), this.fetchProducersSummary()]);
    }

    find(params) {
        return this.routeStore.findContent(params);
    }

    onFetchError(err) {
        this.rootStore.notificationStore.error(
            "An unexpected error occurred while trying to load data. Please contact the support team."
        );
    }

    @action.bound
    setTimeRange(date) {
        this.queryUtility.filter.monthOfYear = date.format(applicationDefaults.formats.isoDate);
    }

    async fetchProducersSummary() {
        try {
            const timeZone = moment.tz.guess();
            const range = dateRangeFactory.create(timeRangeType.today, timeZone);

            //size - The number of users with the highest balance to retrieve
            let filter = { size: 5, from: range.from, to: range.to };
            const data = await this.routeStore.getProducersSummary(filter);
            this.setProducersSummary(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team.",
                err
            );
        }
    }

    @action.bound
    setProducersSummary(data) {
        this.producersSummary = data;
    }
}

export default AdminProducerListViewStore;
