import React, { useRef, useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

const HomeListLoaderTemplate = ({
    row = 3,
    props,
    title,
    contentWidth,
    backgroundColor = "#3C3D3E",
    foregroundColor = "#404143",
    bulk,
    explorePremium
}) => {
    const ref = useRef(null);
    const [loaderWidth, setLoaderWidth] = useState(0);

    useEffect(() => {
        setLoaderWidth(ref.current.offsetWidth);
    }, []);

    const screenwidth = window.innerWidth;
    const outsideContent = screenwidth - contentWidth;

    const list = [];

    // prettier-ignore
    const cardSpace = screenwidth > 540 ? (title ? 206 : 200) : title ? 180 : 160;
    const cardSize = screenwidth > 540 ? 140 : 100;

    let x = 0;
    let y1 = title ? 36 : 0;
    // prettier-ignore
    let y2 = title ? screenwidth > 540 ? 190 : 150 : screenwidth > 540 ? 150 : 110;

    let col;
    let setX;

    if (screenwidth > 1050 && contentWidth) {
        col = Math.floor((outsideContent - 270) / 150);
        setX = Math.floor((outsideContent - 230) / col);
    } else if (screenwidth > 988) {
        if (contentWidth) {
            col = Math.floor((outsideContent - 270 + 120) / 150);
            setX = Math.floor((outsideContent - 230 + 120) / col);
        } else {
            col = Math.floor((screenwidth - 260) / 150);
            setX = Math.floor((screenwidth - 230) / col);
        }
    } else if (screenwidth > 540) {
        col = Math.floor((screenwidth - 32) / 150);
        setX = Math.floor(screenwidth / col / col) + 150;
    } else {
        col = Math.floor((screenwidth - 32) / 103);
        setX = Math.floor(screenwidth / col / col) + 100;
    }

    for (let i = 0; i < col; i++) {
        for (let j = 0; j < row; j++) {
            list.push(
                <React.Fragment key={`${i}_${j}`}>
                    {/* prettier-ignore */}
                    <rect x={x} y={y1} rx="6" ry="6" width={cardSize} height={bulk ? 195 : cardSize} />
                    <rect x={x} y={y2} rx="6" ry="6" width="70" height="10" />
                </React.Fragment>
            );
            y1 += bulk ? 215 : cardSpace;
            y2 += cardSpace;
        }
        y1 = title ? 36 : 0;
        // prettier-ignore
        y2 = title ? screenwidth > 540 ? 190 : 150 : screenwidth > 540 ? 150 : 110;

        x += setX;
    }

    return (
        <div ref={ref}>
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${bulk ? title ? (row * 205) + 25 : row * 205 : row * cardSpace}`}
                backgroundColor={backgroundColor}
                foregroundColor={foregroundColor}
                {...props}
            >
                {title && <rect x="0" y="5" rx="6" ry="6" width="70" height="10" />}
                {list}
            </ContentLoader>
        </div>
    );
};

export default HomeListLoaderTemplate;
