import React from "react";
import { PropTypes } from "prop-types";
import { MrbModal } from "mrb/components/modal";
import { MrbButton } from "mrb/components/button";
import { capitalize, isNil, map, reduce } from "lodash";
import { observer } from "mobx-react";

function ContentUnavailableModalTemplate({ store, t }) {
    const { contentUnavailableModal } = store;

    return (
        <MrbModal modalParams={contentUnavailableModal} className="modal__content modal__content--med">
            <div>
                <div className="modal__header u-mar--bottom--tny">
                    <h2>Content Availability Information</h2>
                </div>
                <div className="modal__body u-mar--center">
                    <p className="u-type--base">
                        {generateContentName(
                            contentUnavailableModal.data.stations,
                            contentUnavailableModal.data.podcasts,
                            contentUnavailableModal.data.nakiNigunArtists,
                            contentUnavailableModal.data.nakiNigunAlbums
                        )}{" "}
                        {pluralize(
                            contentUnavailableModal.data.stations,
                            contentUnavailableModal.data.podcasts,
                            contentUnavailableModal.data.nakiNigunArtists,
                            contentUnavailableModal.data.nakiNigunAlbums,
                            "is",
                            "are"
                        )}{" "}
                        not yet available in Naki Mobile application, and it won't sync to{" "}
                        {generateDevicesLabel(contentUnavailableModal.data.devices)}{" "}
                        {contentUnavailableModal.data.devices
                            ? contentUnavailableModal.data.devices.length > 0
                                ? "devices"
                                : "device"
                            : null}
                        .
                    </p>
                </div>
                <div className="modal__footer--center u-mar--top--sml">
                    <MrbButton
                        classNameExtend={"c-btn--full u-mar--bottom--tny"}
                        t={t}
                        label="Ok"
                        onClick={contentUnavailableModal.close}
                    />
                    <div>
                        <MrbButton
                            t={t}
                            label="Don't show again"
                            onClick={contentUnavailableModal.params.disableContentUnavailableInAppPolicy}
                            classNameOverride="c-btn c-btn--base c-btn--full c-btn--anchor c-btn--anchor--underline"
                        />
                    </div>
                </div>
            </div>
        </MrbModal>
    );
}

ContentUnavailableModalTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function generateContentName(stations = [], podcasts = [], nakiNigunArtists = [], nakiNigunAlbums = []) {
    let result = [];
    if (
        stations.length === 0 &&
        podcasts.length === 0 &&
        nakiNigunArtists.length === 0 &&
        nakiNigunAlbums.length === 0
    ) {
        return null;
    }

    if (stations.length > 0 && podcasts.length > 0) {
        const stationLabels = generateContentNameLabel(stations, "station");
        const podcastLabels = generateContentNameLabel(podcasts, "podcast");
        result = [...stationLabels, " and ", ...podcastLabels];
    } else if (stations.length > 0) {
        result = generateContentNameLabel(stations, "station");
    } else if (podcasts.length > 0) {
        result = generateContentNameLabel(podcasts, "podcast");
    } else if (nakiNigunArtists.length > 0) {
        result = generateContentNameLabel(nakiNigunArtists, "artist");
    } else if (nakiNigunAlbums.length > 0) {
        result = generateContentNameLabel(nakiNigunAlbums, "album");
    }
    result[0] = capitalize(result[0]);

    return result;
}

function pluralize(
    stations = [],
    podcasts = [],
    nakiNigunArtists = [],
    nakiNigunAlbums = [],
    singularWord,
    pluralWord
) {
    const length = stations.length + podcasts.length + nakiNigunArtists.length + nakiNigunAlbums.length;
    if (length > 1) {
        return pluralWord;
    }
    return singularWord;
}

function generateContentNameLabel(items, contentName) {
    const values = reduce(
        items,
        function (result, value, key) {
            if (key > 0) {
                result.push(", ");
            }
            result.push(<strong>"{value}"</strong>);
            return result;
        },
        []
    );
    const label = items.length > 1 ? `${contentName}s ` : `${contentName} `;

    return [label, values];
}

function generateDevicesLabel(devices) {
    if (isNil(devices)) {
        return null;
    }
    if (devices.length === 1) {
        return <strong>{devices[0].deviceName}</strong>;
    }
    const deviceNames = map(devices, (userDevice) => <strong>"{userDevice.deviceName}"</strong>);
    const lastKey = devices.length - 1;
    const value = reduce(
        deviceNames,
        function (result, value, key) {
            if (key === lastKey) {
                result.push(" and ");
            } else if (key > 0) {
                result.push(", ");
            }
            result.push(value);
            return result;
        },
        []
    );

    return value;
}

export default observer(ContentUnavailableModalTemplate);
