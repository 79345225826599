import React from "react";
import { PropTypes } from "prop-types";
import { mergeCss } from "mrb/common/utils";
import { isNil } from "lodash";
function TrendingIndicatorTemplate({
    showIncreaseNumber = true,
    currentCycleNumber,
    previousCycleNumber,
    classNameExtend,
    indicatorClassNameExtend,
}) {
    let indicatorClass = null;
    if (isNil(previousCycleNumber)) {
        indicatorClass = "up";
    } else {
        const diff = previousCycleNumber - currentCycleNumber;
        const trend = Math.sign(diff);
        if (trend > 0) {
            indicatorClass = "up";
        } else if (trend < 0) {
            indicatorClass = "down";
        } else {
            indicatorClass = "neutral";
        }
    }

    return (
        <React.Fragment>
            {showIncreaseNumber && (
                <TrendingNumber
                    classNameExtend={classNameExtend}
                    currentCycleNumber={currentCycleNumber}
                    previousCycleNumber={previousCycleNumber}
                />
            )}
            <i
                className={mergeCss(
                    `u-icon u-icon--analytics u-icon--analytics--${indicatorClass}`,
                    indicatorClassNameExtend
                )}
            />
        </React.Fragment>
    );
}

function TrendingNumber({ currentCycleNumber, previousCycleNumber, classNameExtend }) {
    if (isNil(previousCycleNumber)) {
        return null;
    }
    const diff = previousCycleNumber - currentCycleNumber;
    const trend = Math.sign(diff);
    let indicatorClass = null;
    if (trend > 0) {
        indicatorClass = "positive";
    } else if (trend < 0) {
        indicatorClass = "negative";
    } else {
        indicatorClass = "neutral";
    }
    return (
        <span className={mergeCss(`c-analytics__stats c-analytics__stats--${indicatorClass}`, classNameExtend)}>
            {trend === 0 ? null : trend > 0 ? "+" : "-"}
            {diff === 0 ? null : Math.abs(diff)}
        </span>
    );
}

TrendingIndicatorTemplate.propTypes = {
    showIncreaseNumber: PropTypes.bool,
    classNameExtend: PropTypes.string,
    indicatorClassNameExtend: PropTypes.string,
    currentCycleNumber: PropTypes.number.isRequired,
    previousCycleNumber: PropTypes.number,
};

export default TrendingIndicatorTemplate;
