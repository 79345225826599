import React from "react";
import { PropTypes } from "prop-types";
import { MrbSimpleSelect } from "mrb/components/select";

function HomeOrderBySelectTemplate({ store, t }) {
    return (
        <MrbSimpleSelect
            classNameExtend={"c-select--lrg"}
            store={store}
            valueRender={(props) => <OrderBySelectValue {...props} />}
            dropdownClassNameExtend={"c-select__dropdown--secondary"}
            t={t}
        />
    );
}

HomeOrderBySelectTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function OrderBySelectValue({ selectedOption, t }) {
    return (
        <React.Fragment>
            <span className="c-home__filter__label">Sort by</span>
            <span>{t(selectedOption.label)}</span>
        </React.Fragment>
    );
}

export default HomeOrderBySelectTemplate;
