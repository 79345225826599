import { action, computed, observable, makeObservable } from "mobx";
import { MrbBasePreviewViewStore } from "mrb/core";
import { MrbSimpleSelectStore } from "mrb/components/select";
import { MrbLoaderStore } from "mrb/components/loader";
import { nakiRadioSystemTypes } from "common/constants";
import { isNil } from "lodash";

class BaseHomePreviewViewStore extends MrbBasePreviewViewStore {
    @observable.ref statusItem = null;
    @observable updatingStatus = false;

    @computed get contentAccessResult() {
        return this.evaluateContentAccess(this.item);
    }

    @computed get isTurnedOn() {
        if (!isNil(this.statusItem)) {
            return this.statusItem.isTurnedOn;
        } else if (!isNil(this.item)) {
            const selectedDevice = this.rootStore.userDeviceStore.selectedDevice;
            if (selectedDevice && selectedDevice.device && selectedDevice.device.systemType) {
                switch (selectedDevice.device.systemType.abrv) {
                    case nakiRadioSystemTypes.venice:
                        return this.item.isTurnedOnByDefault;
                    case nakiRadioSystemTypes.mobile:
                        return this.item.isTurnedOnByDefaultInApp;
                    default:
                        break;
                }
            }
        }
        return false;
    }

    constructor(rootStore, params) {
        super(rootStore, params);
        makeObservable(this);

        this.statusToggleLoader = new MrbLoaderStore();

        this.deviceSelectStore = new MrbSimpleSelectStore(
            {
                textKey: "deviceName",
                valueKey: "id",
                actions: {
                    onChange: (option) => {
                        this.rootStore.userDeviceStore.setSelectedDevice(option.item);
                    },
                },
            },
            this.rootStore.userDeviceStore.devices
        );
        this.deviceSelectStore.setSelectedItem(this.rootStore.userDeviceStore.selectedDevice);

        this.reaction(
            () => this.rootStore.userDeviceStore.selectedDevice,
            (device) => {
                this.getStatusItemResource(device.id, this.id);
            }
        );
    }

    async getStatusItemResource(deviceId, stationId) {
        try {
            this.statusToggleLoader.suspend();
            this.rootStore.mainLoaderStore.suspend();
            const statusItem = await this.getStatusItem(deviceId, stationId);
            this.setStatusItem(statusItem);
        } catch (err) {
        } finally {
            this.statusToggleLoader.resume();
            this.rootStore.mainLoaderStore.resume();
        }
    }

    getStatusItem(deviceId, resourceId) {
        throw new Error("Method not implemented.");
    }

    @action.bound
    setStatusItem(statusItem) {
        this.statusItem = statusItem;
    }

    @action.bound
    setUpdatingStatus(value) {
        this.updatingStatus = value;
    }

    goBack = () => {
        this.rootStore.routerStore.goBack();
    };

    evaluateContentAccess(item) {
        throw new Error("Method not implemented.");
    }
}

export default BaseHomePreviewViewStore;
