import { BaseHomePodcastRouteStore } from "application/home/stores";

class HomePodcastRouteStore extends BaseHomePodcastRouteStore {
    constructor(rootStore) {
        super(rootStore);
    }

    async getPodcastWthDeviceSettings(podcastId, deviceId) {
        const response = await this.userContentService.getPodcastWthDeviceSettings(podcastId, deviceId, {
            embed: "location,language",
        });
        return response.data;
    }

    async getPodcast(podcastId) {
        const response = await this.userContentService.getPodcast(podcastId, {
            embed: "location,language",
        });
        return response.data;
    }
}

export default HomePodcastRouteStore;
