import React from "react";
import { MrbTabContent } from "../";
import { filter } from "lodash";

function getTabContent(children) {
    const elements = React.Children.toArray(children);
    return filter(elements, (element) => element.type === MrbTabContent);
}

export default getTabContent;
