import { action, computed, makeObservable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";

class MainSidebarViewStore extends MrbBaseViewStore {
    isLogoutDisabled = false;

    @computed get user() {
        return this.rootStore.userStore.user;
    }

    constructor(rootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action.bound
    async logout() {
        if (!this.isLogoutDisabled) {
            this.isLogoutDisabled = true;
            this.rootStore.mainLoaderStore.suspend();
            try {
                await this.rootStore.applicationMainSectionStore.logout();
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "An unexpected error occurred while trying to logout. Please contant the support team."
                );
            } finally {
                runInAction(() => {
                    this.isLogoutDisabled = false;
                    this.rootStore.mainLoaderStore.resume();
                });
            }
        }
    }
}

export default MainSidebarViewStore;
