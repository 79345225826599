import React from "react";
import PropTypes from "prop-types";
import { MrbPreviewContent } from "../content";
import { getPageObject } from "../utils";
import { MrbPage, MrbPageHeader, MrbPreviewPageHeader } from "./";
import { observer } from "mobx-react";

function MrbPreviewPage({ store, children, t = (i) => i, ...pageProps }) {
    const { layoutActions, navigationOptions, title, navigateBack } = store;
    const { header, footer, content } = getPageObject(children);

    return (
        <MrbPage {...pageProps}>
            {header ? (
                header
            ) : (
                <MrbPageHeader>
                    <MrbPreviewPageHeader
                        title={title}
                        layoutActions={layoutActions}
                        navigationOptions={navigationOptions}
                        navigateBack={navigateBack}
                        t={t}
                    />
                </MrbPageHeader>
            )}
            {content.header}
            {content.sidebar}
            <MrbPreviewContent>{content.children}</MrbPreviewContent>
            {content.footer}
            {footer}
        </MrbPage>
    );
}

MrbPreviewPage.propTypes = {
    store: PropTypes.object.isRequired,
    children: PropTypes.any,
    layoutFooterVisible: PropTypes.bool,
    t: PropTypes.func,
};

export default observer(MrbPreviewPage);
