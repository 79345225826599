import React from "react";
import ContentLoader from "react-content-loader";

const ChangePasswordLoaderTemplate = (props) => {
    const screewidth = window.innerWidth;
    // prettier-ignore
    const contentWidth = screewidth > 1439 ? 520 : screewidth > 767 ? 320 : screewidth;

    return (
        <React.Fragment>
            <PasswordHeader contentWidth={contentWidth} />
            <PasswordPreview contentWidth={contentWidth} />
        </React.Fragment>
    );
};

export default ChangePasswordLoaderTemplate;

const PasswordHeader = ({ contentWidth }) => {
    const headerHeight = 100;

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={headerHeight}
            viewBox={`0 0 ${contentWidth} ${headerHeight}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
        >
            <rect x="0" y="0" rx="6" ry="6" width="25%" height="12" />
            <rect x="0" y="40" rx="6" ry="6" width="17%" height="10" />
            <rect x="0" y="60" rx="6" ry="6" width="100%" height="38" />
        </ContentLoader>
    );
};

const PasswordPreview = ({ contentWidth }) => {
    const contentHeight = 270;

    const items = [];
    let y = 40;

    for (let i = 0; i < 3; i++) {
        let textWidth = i === 0 ? 120 : i === 1 ? 180 : 240;

        items.push(
            <React.Fragment key={`${i}_passwordItem`}>
                <rect x="0" y={y} rx="22" ry="22" width="22" height="22" />
                {/* prettier-ignore */}
                <rect x="34" y={y + 6} rx="6" ry="6" width={textWidth} height="8" />
            </React.Fragment>
        );

        y += 34;
    }

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={contentHeight}
            viewBox={`0 0 ${contentWidth} ${contentHeight}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
        >
            <rect x="0" y="10" rx="6" ry="6" width="36%" height="10" />
            {items}
            <rect x="0" y="160" rx="6" ry="6" width="17%" height="10" />
            <rect x="0" y="180" rx="6" ry="6" width="100%" height="38" />

            <rect x="0" y="240" rx="6" ry="6" width="100" height="30" />
        </ContentLoader>
    );
};
