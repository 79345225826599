import { action, computed, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { sideScroll } from "common/utils";
import { podcastType, podcastContentType } from "common/constants";
import { map, find } from "lodash";
import { HomeGridItemDecorator } from "application/home/models";
import { UpdatePodcastStatusOptions, UpdateStationStatusOptions } from "application/common/models";

class HomeFeaturedContentViewStore extends MrbBaseViewStore {
    @observable.ref items = [];
    @observable isFeaturedScrollable = false;
    @observable.ref featuredContainerRef = null;

    @computed get selectedDevice() {
        return this.rootStore.userDeviceStore.selectedDevice;
    }

    constructor(rootStore, homeViewStore) {
        super(rootStore);
        makeObservable(this);
        this.homeViewStore = homeViewStore;
        this.routeStore = homeViewStore.routeStore;

        this.reaction(
            () => ({
                groups: this.items,
                container: this.featuredContainerRef,
            }),
            (context) => {
                if (context.container) {
                    setTimeout(() => {
                        runInAction(() => {
                            this.isFeaturedScrollable = context.container.scrollWidth > context.container.clientWidth;
                        });
                    });
                }
            }
        );

        this.reaction(
            () => ({
                deviceId: this.selectedDevice && this.selectedDevice.id,
                contentType: this.homeViewStore.queryUtility.filter.contentType,
            }),
            (context) => {
                this.fetchResources(context);
            }
        );
    }

    onInit() {
        let deviceId;
        if (this.selectedDevice) {
            deviceId = this.selectedDevice.id;
        }
        return this.fetchResources({ deviceId: deviceId });
    }

    async fetchResources({ deviceId, contentType }) {
        this.loaderStore.suspend();
        try {
            const items = await this.routeStore.getFeaturedContent(deviceId, contentType);
            this.setItems(items);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "Unexpected error occurred while trying to load featured content. Please contact the support team.",
                err
            );
        } finally {
            this.loaderStore.resume();
        }
    }

    onClickFeaturedItem = async (item) => {
        if (item.podcastId && item.podcastType === podcastType.podcastGroup) {
            this.rootStore.routerStore.goTo("master.application.home.podcast-group", {
                id: item.podcastId,
            });
        } else if (item.podcastId && item.podcastContentType === podcastContentType.torahAnytime) {
            this.rootStore.routerStore.goTo("master.application.home.torah-anytime", { id: item.podcastId });
        } else {
            if (item.stationId) {
                const contentAccessResult = this.rootStore.contentAccessValidator.canAccessStation({
                    isPremium: item.isPremium,
                    isLegacySubscriptionValid: item.validLegacySubscription,
                    unavailableInMobileApp: item.isVisibleInApp === false,
                    unavailableInNakiTouch: item.isVisibleInEmbeddedApp === false,
                });
                if (contentAccessResult.canAccess) {
                    await this.changeDeviceStationStatus(item);
                }
            } else if (item.podcastId) {
                const contentAccessResult = this.rootStore.contentAccessValidator.canAccessPodcast({
                    isPremium: item.isPremium,
                    isLegacySubscriptionValid: item.validLegacySubscription,
                    unavailableInMobileApp: item.isVisibleInApp === false,
                    unavailableInNakiTouch: item.isVisibleInEmbeddedApp === false,
                });
                if (contentAccessResult.canAccess) {
                    await this.changeDevicePodcastStatus(item);
                }
            }
        }
    };

    goTo = (item) => {
        if (item.stationId) {
            this.rootStore.routerStore.goTo("master.application.home.station", {
                id: item.stationId,
            });
        } else if (item.podcastId) {
            if (item.podcastType === podcastType.podcast) {
                if (item.podcastContentType === podcastContentType.torahAnytime) {
                    this.rootStore.routerStore.goTo("master.application.home.torah-anytime", { id: item.podcastId });
                } else {
                    this.rootStore.routerStore.goTo("master.application.home.podcast", {
                        id: item.podcastId,
                    });
                }
            } else {
                this.rootStore.routerStore.goTo("master.application.home.podcast-group", {
                    id: item.podcastId,
                });
            }
        }
    };

    async changeDeviceStationStatus(item) {
        if (!item.isUpdating) {
            try {
                item.isUpdating = true;
                this.rootStore.mainLoaderStore.suspend();
                const newValue = !item.isTurnedOn;
                const options = new UpdateStationStatusOptions(
                    item.stationId,
                    item.title,
                    item.isPremium,
                    item.isVisibleInApp === false,
                    item.isVisibleInEmbeddedApp === false
                );
                await this.rootStore.userContentManagerStore.updateStationStatus(options, newValue);
                runInAction(() => {
                    item.isTurnedOn = newValue;
                    // Since there is a possibility of having the same item twice in the featured list and main list, we need to sync turned on status
                    this.updateMainViewStationStatus(item.stationId, newValue);
                    this.resetItems();
                });
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Please contact the support team.",
                    err
                );
            } finally {
                delete item.isUpdating;
                this.rootStore.mainLoaderStore.resume();
            }
        }
    }

    async changeDevicePodcastStatus(item) {
        if (!item.isUpdating) {
            try {
                item.isUpdating = true;
                this.rootStore.mainLoaderStore.suspend();
                const newValue = !item.isTurnedOn;
                const options = new UpdatePodcastStatusOptions(
                    item.podcastId,
                    item.title,
                    item.isPremium,
                    item.isVisibleInApp === false,
                    item.isVisibleInEmbeddedApp === false
                );
                await this.rootStore.userContentManagerStore.updatePodcastStatus(options, newValue);
                runInAction(() => {
                    item.isTurnedOn = newValue;
                    // Since there is a possibility of having the same item twice in the featured list and main list, we need to sync turned on status
                    this.updateMainViewPodcastStatus(item.podcastId, newValue);
                    this.resetItems();
                });
            } catch (err) {
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Please contact the support team.",
                    err
                );
            } finally {
                delete item.isUpdating;
                this.rootStore.mainLoaderStore.resume();
            }
        }
    }

    @action.bound
    updateMainViewStationStatus(stationId, isTurnedOn) {
        const item = find(this.homeViewStore.gridStore.data, (x) => x.stationId === stationId);
        if (item) {
            item.isTurnedOn = isTurnedOn;
        }
    }

    @action.bound
    updateMainViewPodcastStatus(podcastId, isTurnedOn) {
        const item = find(this.homeViewStore.gridStore.data, (x) => x.podcastId === podcastId);
        if (item) {
            item.isTurnedOn = isTurnedOn;
        }
    }

    @action.bound
    resetItems() {
        this.items = [...this.items];
    }

    createCoverImageUrl(item) {
        if (item.coverImageId) {
            if (item.stationId) {
                return this.routeStore.createStationCoverImageUrl(item.coverImageId, 200, 200);
            } else if (item.podcastId) {
                return this.routeStore.createPodcastCoverImageUrl(item.coverImageId, 200, 200);
            }
        }
        return null;
    }

    @action.bound
    setItems(items) {
        this.items = map(items, (item) => {
            return new HomeGridItemDecorator({
                ...item,
                coverImageUrl: this.createCoverImageUrl(item),
            });
        });
    }

    @action.bound
    setFeaturedContainerRef(ref) {
        this.featuredContainerRef = ref;
    }

    @action.bound
    scrollFeaturedLeft() {
        sideScroll(this.featuredContainerRef, "left", 20, 400, 20);
    }

    @action.bound
    scrollFeaturedRight() {
        sideScroll(this.featuredContainerRef, "right", 20, 400, 20);
    }
}

export default HomeFeaturedContentViewStore;
