import { MrbBaseRouteStore } from "mrb/core";
import { ProducerAnalyticsService, ProducerUserAccountService } from "common/services";

class AdminProducerPreviewRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(ProducerAnalyticsService);
        this.producerUserAccountService = rootStore.createApplicationService(ProducerUserAccountService);
    }

    async findProducerReports(params) {
        const response = await this.service.findProducerReports(params);
        return response.data;
    }

    async getProducerSummary(producerId) {
        const response = await this.service.getProducerSummary(producerId);
        return response.data;
    }

    async getProducerUserAccountsSummary(producerId) {
        const response = await this.producerUserAccountService.getProducerAccountsSummary(producerId);
        return response.data;
    }

    async getProducerMostPopularContent(producerId, options) {
        const response = await this.service.getProducerMostPopularContent(producerId, options);
        return response.data;
    }
}

export default AdminProducerPreviewRouteStore;
