import { BaseService } from "mrb/core";
import { DeviceApiRouteService } from "common/services";

class DeviceService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new DeviceApiRouteService());
    }

    findUserDeviceForAuthenticatedUser(filter, abortSignal = null) {
        const url = this.routeService.findUserDeviceForAuthenticatedUser(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getUserDeviceInfo(params, abortSignal = null) {
        const url = this.routeService.getUserDeviceInfo(params);
        return this.apiClient.get(url, null, abortSignal);
    }

    findUserDevice(filter, abortSignal = null) {
        const url = this.routeService.findUserDevice(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    updateUserDeviceDisabledStatus(id, value, abortSignal = null) {
        const url = this.routeService.updateUserDeviceDisabledStatus(id, value);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    getUserDevice(id, options = {}, abortSignal = null) {
        const url = this.routeService.getUserDevice(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getMenu(abortSignal = null) {
        const url = this.routeService.getMenu();
        return this.apiClient.get(url, null, abortSignal);
    }

    getMenuLatestItems(abortSignal = null) {
        const url = this.routeService.getMenuLatestItems();
        return this.apiClient.get(url, null, abortSignal);
    }

    updateMenu(resource, abortSignal = null) {
        const url = this.routeService.updateMenu();
        return this.apiClient.put(url, resource, null, abortSignal);
    }

    registerUserDevice(resource, abortSignal = null) {
        const url = this.routeService.registerUserDevice(resource);
        return this.apiClient.post(url, resource, null, abortSignal);
    }

    updateUserDevice(resource, abortSignal = null) {
        const url = this.routeService.updateUserDevice(resource);
        return this.apiClient.put(url, resource, null, abortSignal);
    }

    deleteUserDevice(resource, abortSignal = null) {
        const url = this.routeService.deleteUserDevice(resource);
        return this.apiClient.delete(url, null, resource, abortSignal);
    }
}

export default DeviceService;
