import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

function MrbTableHeader(props) {
    const {
        displayName,
        enableSorting,
        tableStore,
        column: { colId },
    } = props;
    return (
        <div onClick={enableSorting ? () => tableStore.setSort(colId) : null}>
            {displayName}
            {tableStore.orderBy === colId ? (
                <span
                    className={`ag-icon ${
                        tableStore.orderDirection === "asc"
                            ? "ag-icon-asc"
                            : "ag-icon-desc"
                    }`}
                ></span>
            ) : null}
        </div>
    );
}

MrbTableHeader.propTypes = {
    displayName: PropTypes.string,
    enableSorting: PropTypes.bool,
    tableStore: PropTypes.object,
    column: PropTypes.object,
    t: PropTypes.func,
};

export default observer(MrbTableHeader);
