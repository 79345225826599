import { BaseApiRouteService } from "mrb/core";

class FeaturedSectionFileStreamApiRouteService extends BaseApiRouteService {
    constructor() {
        super("featured-section-file-streams");
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?width,height}`, id, options);
    }

    getFeaturedSectionCoverImageByGridItemId(featuredSectionGridItemId, options) {
        return super.get(
            `${this.base}/featured-section-grid-item-cover/{featuredSectionGridItemId}{?width,height}`,
            featuredSectionGridItemId,
            options
        );
    }
}

export default FeaturedSectionFileStreamApiRouteService;
