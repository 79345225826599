import Validator from "validatorjs";
import { mrbDefaultValidationErrors } from "mrb/core/form/validation";
import { each, forOwn, isObject, findIndex } from "lodash";
import { localizationService } from "common/localization";
import { UUID } from "common/utils";

class LocalizedValidatorService {
    callbacks = [];

    constructor() {
        const localizeMessages = (obj) => {
            let messages = {
                attributes: {}, // This should be defined as an empty object at least, or in some cases, the validatorjs will throw an exception
            };
            forOwn(obj, (value, key) => {
                if (isObject(value)) {
                    messages[key] = localizeMessages(value);
                } else {
                    messages[key] = localizationService.t(value);
                }
            });

            return messages;
        };

        localizationService.on("languageChanged", (lng) => {
            if (!Validator.getMessages(lng)) {
                Validator.setMessages(lng, localizeMessages(mrbDefaultValidationErrors));
            }

            Validator.useLang(lng);

            each(this.callbacks, (c) => {
                if (c.eventName === "onMessageSourceChange") c.action();
            });
        });
    }

    registerCallback(eventName, callback) {
        const callbackId = UUID.create();
        this.callbacks.push({ id: callbackId, eventName: eventName, action: callback });
        return callbackId;
    }

    removeCallback(callbackId) {
        const index = findIndex(this.callbacks, (callback) => callback.id === callbackId);
        if (index !== -1) {
            this.callbacks.splice(index, 1);
            return true;
        }
        return false;
    }
}

const service = new LocalizedValidatorService();

export default service;
