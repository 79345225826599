import React from "react";
import { PropTypes } from "prop-types";
import { ProducerPaymentReportCell, ProducersDetailsListLoader } from "common/components/producer";
import { MrbInfiniteScrollGrid } from "mrb/components/infinite-scroll-grid";

function ProducerReportTemplate({ producerReportViewStore, t }) {
    const { gridStore, routeStore, setContainerRef } = producerReportViewStore;
    return (
        <div className="order-1">
            <h1 className="u-type--base u-mar--bottom--sml">{t("COMMON.PRODUCER.REPORT.TITLE")}</h1>

            <div className="c-producers__card" ref={setContainerRef}>
                <div className="c-producers__header">
                    <div className="c-producers__item__col">{t("COMMON.PRODUCER.REPORT.COLUMNS.USER")}</div>
                    <div className="c-producers__item__col">
                        {t("COMMON.PRODUCER.REPORT.COLUMNS.SUBSCRIPTION_CYCLE_START_DATE")}
                    </div>
                    <div className="c-producers__item__col">
                        {t("COMMON.PRODUCER.REPORT.COLUMNS.SUBSCRIPTION_CYCLE_END_DATE")}
                    </div>
                    <div className="c-producers__item__col">
                        {t("COMMON.PRODUCER.REPORT.COLUMNS.PRODUCER_PLAYBACK_TIME")}
                    </div>
                    <div className="c-producers__item__col">
                        {t("COMMON.PRODUCER.REPORT.COLUMNS.PRODUCER_PLAYBACK_POINTS")}
                    </div>
                    <div className="c-producers__item__col">
                        {t("COMMON.PRODUCER.REPORT.COLUMNS.TOTAL_USER_PLAYBACK_TIME")}
                    </div>
                    <div className="c-producers__item__col">{t("COMMON.PRODUCER.REPORT.COLUMNS.PRODUCER_PAYMENT")}</div>
                    <div className="c-producers__item__col">
                        {t("COMMON.PRODUCER.REPORT.COLUMNS.SUBSCRIPTION_PLAN")}
                    </div>
                </div>
                <div className="c-producers__body">
                    <MrbInfiniteScrollGrid
                        loaderRenderer={() => <ProducersDetailsListLoader />}
                        loadMoreLoader={<ProducersDetailsListLoader row={4} />}
                        emptyStateRenderer={() => <EmptyState t={t} />}
                        classNameOverride=""
                        store={gridStore}
                        t={t}
                        cellRenderer={(props) => (
                            <ProducerPaymentReportCell
                                producerPaymentReportPreviewViewStore={producerReportViewStore}
                                routeStore={routeStore}
                                {...props}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
}

ProducerReportTemplate.propTypes = {
    producerReportViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function EmptyState({ t }) {
    return (
        <div className="c-card--primary">
            <div className="u-type--center u-padd--top--lrg u-padd--bottom--lrg">
                <i className="u-icon u-icon--welcome u-icon--welcome--sml u-mar--bottom--sml"></i>
                <h5 className="">{t("COMMON.PRODUCER.REPORT.EMPTY_STATE.MESSAGE")}</h5>
            </div>
        </div>
    );
}

export default ProducerReportTemplate;
