import { MrbBaseRouteStore } from "mrb/core";
import { ProducerService } from "common/services";

class AdminProducerEditRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.producerService = rootStore.createApplicationService(ProducerService);
    }

    async createProducer(resource) {
        const response = await this.producerService.create(resource);
        return response.data;
    }

    async getProducer(resource) {
        const response = await this.producerService.get(resource);
        return response.data;
    }
    async updateProducer(resource) {
        const response = await this.producerService.update(resource);
        return response.data;
    }
}

export default AdminProducerEditRouteStore;
