import { podcastType } from "common/constants";

function getContentActivityStatusIcon(item) {
    if (item.podcastType === podcastType.podcastGroup) {
        if (item.numberOfEnabledSubPodcasts === 0) {
            return "c-home__status--disabled";
        } else if (item.numberOfSubPodcasts === item.numberOfEnabledSubPodcasts) {
            return "c-home__status--enabled";
        } else if (item.numberOfSubPodcasts > item.numberOfEnabledSubPodcasts) {
            return "c-home__status--mix";
        }
    } else {
        if (item.isTurnedOn) {
            return "c-home__status--enabled";
        } else {
            return "c-home__status--disabled";
        }
    }
    return null;
}

export default getContentActivityStatusIcon;
