import React from "react";
import ContentLoader from "react-content-loader";

const ProducerSummaryLoaderTemplate = (props) => {
    const { row = 3, statsPreview, className, isDetails } = props;
    const screenwidth = window.innerWidth;
    const items = [];
    let loaderWidth;

    if (screenwidth > 1199) {
        loaderWidth = 387;
    } else if (screenwidth > 990) {
        loaderWidth = screenwidth / 2 - 40;
    } else if (screenwidth > 766) {
        loaderWidth = screenwidth / 2 + 60;
    } else {
        loaderWidth = screenwidth;
    }

    let y = 0;

    for (let i = 0; i < row; i++) {
        if (i === 0) {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="6" ry="6" width="80" height="8" />
                    <rect x="0" y={y + 24} rx="6" ry="6" width="100" height="14" />

                    {!isDetails && (
                        <React.Fragment>
                            <rect x="60%" y={y} rx="6" ry="6" width="30" height="14" />
                            <rect x="60%" y={y + 24} rx="6" ry="6" width="140" height="8" />
                        </React.Fragment>
                    )}
                </React.Fragment>
            );

            y += 76;
        } else if (i === 1) {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="6" ry="6" width="80" height="8" />
                    <rect x="0" y={y + 24} rx="6" ry="6" width="170" height="34" />
                </React.Fragment>
            );

            y += 86;
        } else {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="6" ry="6" width="80" height="8" />
                    <rect x="0" y={y + 24} rx="6" ry="6" width="100" height="14" />
                </React.Fragment>
            );
            y += 76;
        }
    }

    return (
        <ContentLoader
            speed={2}
            width={loaderWidth}
            height="100%"
            viewBox={`0 0 ${loaderWidth} ${statsPreview ? y - row * 25 : 200}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            className={className}
        >
            {statsPreview ? <StatsPreviewLoader row={row} /> : <React.Fragment>{items}</React.Fragment>}
        </ContentLoader>
    );
};

export default ProducerSummaryLoaderTemplate;

const StatsPreviewLoader = (props) => {
    const { row } = props;
    const items = [];

    let y = 8;

    for (let i = 0; i < row; i++) {
        if (i === 1) {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="6" ry="6" width="80" height="8" />
                    <rect x="0" y={y + 24} rx="6" ry="6" width="60" height="8" />

                    <rect x="60%" y={y} rx="6" ry="6" width="35%" height="8" />
                    <rect x="60%" y={y + 24} rx="6" ry="6" width="8%" height="8" />
                </React.Fragment>
            );
        } else {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="6" ry="6" width="180" height="8" />
                    <rect x="0" y={y + 24} rx="6" ry="6" width="60" height="8" />

                    <rect x="78%" y={y} rx="6" ry="6" width="40" height="20" />
                    <rect x="73%" y={y + 6} rx="6" ry="6" width="16" height="8" />
                    <rect x="90%" y={y + 6} rx="6" ry="6" width="12" height="8" />
                </React.Fragment>
            );
        }

        y += 64;
    }
    return <React.Fragment>{items}</React.Fragment>;
};
