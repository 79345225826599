import { PropTypes } from "prop-types";
import { ProducerLabel } from "common/components/producer";
import { isNil } from "lodash";

function ProducerNameCellTemplate({ data }) {
    const { producerName, userId, userFirstName, userLastName } = data;
    const isUser = !isNil(userId);
    let user;
    if (isUser && userFirstName && userLastName) {
        user = {
            firstName: userFirstName,
            lastName: userLastName,
        };
    }
    return <ProducerLabel name={producerName} isUser={isUser} user={user} />;
}

ProducerNameCellTemplate.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ProducerNameCellTemplate;
