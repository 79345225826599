import { action, computed, observable, makeObservable } from "mobx";
import { applicationDefaults } from "common/constants";
import { BasePaymentViewStore } from "application/payment/stores";
import { dateFormatter, currencyFormatter } from "mrb/common/formatting";

class UpgradeTrialViewStore extends BasePaymentViewStore {
    @observable.ref subscriptionId = null;

    @computed get subscription() {
        return this.rootStore.premiumSubscriptionStore.getSubscription(this.subscriptionId);
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore, { routeStore });
        makeObservable(this);

        this.reaction(
            () => this.subscription,
            (subscription) => {
                this.setSubscriptionPlan(subscription.product);
            }
        );
        this.setSubscriptionId(rootStore.routerStore.routerState.params.subscriptionId);

        this.onClickSubscribe = this.onClickSubscribe.bind(this);
    }

    @action.bound
    upgradeTrialToFullSubscription(cardElements) {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Ready to start your subscription?",
            description: `Starting from ${dateFormatter.format(this.subscription.trialPeriod.trialEndDate, {
                format: applicationDefaults.formats.date,
            })} your card will be charged ${currencyFormatter.format(this.subscription.product.price, {
                currency: "USD",
            })} each month. Cancel anytime.`,
            yesLabel: "Subscribe",
            onConfirm: async () => {
                if (this.paymentMethodExist) {
                    await this.upgradeUsingExistingPaymentMethod();
                } else {
                    await this.upgradeUsingNewCreditCard(cardElements);
                }
            },
        });
    }

    @action.bound
    async upgradeUsingNewCreditCard(cardElements) {
        this.stripeField.resetValidation();
        try {
            const setupPaymentMethodResponse = await this.routeStore.setupPaymentMethodRequest();
            const payload = await this.stripe.confirmCardSetup(setupPaymentMethodResponse.token, {
                payment_method: {
                    card: cardElements,
                },
            });
            if (payload.error) {
                this.stripeField.setError(payload.error);
            } else {
                await this.rootStore.premiumSubscriptionStore.upgradeTrialSubscription(
                    this.subscription.id,
                    payload.setupIntent.payment_method
                );
                this.paymentSuccessModal.open({
                    trialUpgraded: true,
                    billingStartDate: this.subscription.trialPeriod.trialEndDate, //subscription will be charged at the last day of trial period,
                    subscriptionPlan: this.subscriptionPlan.name,
                });
            }
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to process payment. Please contact the support team.",
                err
            );
        }
    }

    @action.bound
    async upgradeUsingExistingPaymentMethod() {
        try {
            await this.rootStore.premiumSubscriptionStore.upgradeTrialSubscription(this.subscription.id, null);
            this.paymentSuccessModal.open({
                trialUpgraded: true,
                billingStartDate: this.subscription.trialPeriod.trialEndDate, //subscription will be charged at the last day of trial period,
                subscriptionPlan: this.subscriptionPlan.name,
            });
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to process payment. Please contact the support team.",
                err
            );
        }
    }

    onClickSubscribe(cardElements) {
        this.upgradeTrialToFullSubscription(cardElements);
    }

    @action.bound
    setSubscriptionId(subscriptionId) {
        this.subscriptionId = subscriptionId;
    }
}

export default UpgradeTrialViewStore;
