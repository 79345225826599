import React from "react";
import { PropTypes } from "prop-types";
import { mergeOrOverrideCss } from "../../../common/utils";

function render(content) {
    return content
        ? typeof content === "function"
            ? content()
            : content
        : null;
}

function MrbPageContent({
    content,
    // loaderRenderer = () => <div></div>, // eslint-disable-line
    emptyRenderer = () => <MrbApplicationEmptyState t={t} />, // eslint-disable-line
    isEmpty = false,
    classNameExtend,
    classNameOverride,
}) {
    // if (loading) return render(loaderRenderer);
    if (emptyRenderer && isEmpty) {
        return render(emptyRenderer);
    }

    return (
        <div
            className={mergeOrOverrideCss(
                "c-page__main",
                classNameOverride,
                classNameExtend
            )}
        >
            {content.header ? content.header : null}

            <div className="c-content__main">{content.children}</div>

            {content.sidebar ? content.sidebar : null}

            {content.footer ? content.footer : null}
        </div>
    );
}

MrbPageContent.propTypes = {
    isEmpty: PropTypes.bool,
    emptyRenderer: PropTypes.any,
    content: PropTypes.any,
    classNameExtend: PropTypes.string,
    classNameOverride: PropTypes.string,
};

export default MrbPageContent;
