import { BaseService } from "mrb/core";
import { NakiNigunUserContentApiRouteService } from "common/services";

class NakiNigunUserContentService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new NakiNigunUserContentApiRouteService());
    }

    findArtists(filter, abortSignal = null) {
        const url = this.routeService.findArtists(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    findAlbums(filter, abortSignal = null) {
        const url = this.routeService.findAlbums(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    genericSearch(filter, abortSignal = null) {
        const url = this.routeService.genericSearch(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getFeaturedArtists(deviceId, abortSignal = null) {
        const url = this.routeService.getFeaturedArtists(deviceId);
        return this.apiClient.get(url, null, abortSignal);
    }

    getFeaturedAlbums(deviceId, abortSignal = null) {
        const url = this.routeService.getFeaturedAlbums(deviceId);
        return this.apiClient.get(url, null, abortSignal);
    }

    getAlbumsByArtist(artistId, abortSignal = null) {
        const url = this.routeService.getAlbumsByArtist(artistId);
        return this.apiClient.get(url, null, abortSignal);
    }

    getNakiNigunArtistWthDeviceSettings(artistId, deviceId, options, abortSignal = null) {
        const url = this.routeService.getNakiNigunArtistWthDeviceSettings(artistId, deviceId, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getNakiNigunArtist(artistId, options, abortSignal = null) {
        const url = this.routeService.getNakiNigunArtist(artistId, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getUserDeviceAlbumsByArtist(deviceId, artistId, abortSignal = null) {
        const url = this.routeService.getUserDeviceAlbumsByArtist(deviceId, artistId);
        return this.apiClient.get(url, null, abortSignal);
    }

    updateAlbumsTurnedOnStatusBulk(resources, abortSignal = null) {
        const url = this.routeService.updateAlbumsTurnedOnStatusBulk(resources);
        return this.apiClient.put(url, resources, null, abortSignal);
    }

    updateAlbumsTurnedOnStatus(resources, value, abortSignal = null) {
        const url = this.routeService.updateAlbumsTurnedOnStatus(resources, value);
        return this.apiClient.put(url, resources, null, abortSignal);
    }

    updateAlbumsTurnedOnStatusByArtist(resources, value, abortSignal = null) {
        const url = this.routeService.updateAlbumsTurnedOnStatusByArtist(resources, value);
        return this.apiClient.put(url, resources, null, abortSignal);
    }

    updateAlbumsTurnedOnStatusByArtistBulk(resources, abortSignal = null) {
        const url = this.routeService.updateAlbumsTurnedOnStatusByArtistBulk(resources);
        return this.apiClient.put(url, resources, null, abortSignal);
    }
}

export default NakiNigunUserContentService;
