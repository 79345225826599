import React from "react";
import { UpdateCustomerPaymentMethodTemplate } from "themes/application/payment/components";
import { stripeProvider } from "common/hoc";

@stripeProvider
class UpdateCustomerPaymentMethod extends React.Component {
    render() {
        return <UpdateCustomerPaymentMethodTemplate {...this.props} />;
    }
}

export default UpdateCustomerPaymentMethod;
