import React, { useRef, useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import { PlanCardLoader } from "application/payment/components";

const ChangeSubscriptionPlanLoaderTemplate = () => {
    return (
        <div>
            <div className="c-payment__list c-payment__list--plan u-mar--bottom--med">
                <PlanCardLoader />
                <div>
                    <i className="u-icon u-icon--lrg u-icon--arrow-right--secondary"></i>
                </div>
                <PlanCardLoader />
            </div>

            <ChangeSubscriptionInfoLoader />
        </div>
    );
};

export default ChangeSubscriptionPlanLoaderTemplate;

const ChangeSubscriptionInfoLoader = (props) => {
    const ref = useRef(null);
    const [loaderWidth, setLoaderWidth] = useState(0);
    const loaderHeight = 495;

    useEffect(() => {
        setLoaderWidth(ref.current.offsetWidth);
    }, []);

    return (
        <div ref={ref} className="c-payment__card c-payment__card--full">
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                <rect x="0" y={0} rx="6" ry="6" width="45%" height="12" />
                <rect x="0" y={32} rx="6" ry="6" width="100%" height="12" />
                <rect x="0" y={60} rx="6" ry="6" width="20%" height="12" />

                <rect x="0" y={125} rx="6" ry="6" width="100%" height="12" />
                <rect x="0" y={155} rx="6" ry="6" width="10%" height="12" />

                <rect x="0" y={192} rx="6" ry="6" width="70%" height="12" />
                <rect x="0" y={222} rx="6" ry="6" width="15%" height="12" />

                <rect x="0" y={262} rx="6" ry="6" width="70%" height="12" />
                <rect x="0" y={292} rx="6" ry="6" width="15%" height="12" />

                <rect x="0" y={362} rx="6" ry="6" width="25%" height="12" />
                <rect x="0" y={392} rx="6" ry="6" width="45%" height="12" />

                <rect x="0" y={loaderHeight - 60} rx="6" ry="6" width="100%" height="1" />
                <rect x="0" y={loaderHeight - 40} rx="6" ry="6" width="100%" height="40" />
            </ContentLoader>
        </div>
    );
};
