import React from "react";
import ContentLoader from "react-content-loader";

const TopTrendingListLoaderTemplate = (props) => {
    const { row = 10, noButton, userActivity = false, ...rest } = props;
    const screenwidth = window.innerWidth;
    const items = [];
    let loaderWidth;

    if (screenwidth > 1199) {
        loaderWidth = 387;
    } else if (userActivity && screenwidth > 990) {
        loaderWidth = screenwidth;
    } else if (screenwidth > 990) {
        loaderWidth = screenwidth / 2 - 200;
    } else if (userActivity && screenwidth > 766) {
        loaderWidth = screenwidth;
    } else if (screenwidth > 766) {
        loaderWidth = screenwidth / 2;
    } else {
        loaderWidth = screenwidth;
    }

    let y = 0;

    for (let i = 0; i < row; i++) {
        let textWidth = Math.random() * (150 - 100) + 100;
        items.push(
            <React.Fragment key={`top-trending_${i}`}>
                {!userActivity &&
                    <rect x="0" y={y + 6} rx="6" ry="6" width="6" height="5" />
                }
                <rect x={!userActivity ? 10 : 0} y={y + 3} rx="4" ry="4" width="10" height="10" />
                <rect x={!userActivity ? 24 : 18} y={y + 3} rx="6" ry="6" width={textWidth} height="10" />
                {noButton && !userActivity && <rect x="79%" y={y} rx="6" ry="6" width="7%" height="16" />}
                <rect x="88%" y={y + 3} rx="6" ry="6" width="12%" height="10" />
            </React.Fragment>
        );

        y += 38;
    }

    return (
        <div>
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${!noButton ? y + 48 : y - 20}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...rest}
            >
                {items}
                {!noButton && (
                    <React.Fragment>
                        <rect x="0" y={y - 6} rx="6" ry="6" width="100%" height="1" />
                        <rect x="0" y={y + 12} rx="6" ry="6" width="100%" height="36" />
                    </React.Fragment>
                )}
            </ContentLoader>
        </div>
    );
};

export default TopTrendingListLoaderTemplate;
