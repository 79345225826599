import { BaseApiRouteService } from "mrb/core";

class UserAccountApiRouteService extends BaseApiRouteService {
    constructor() {
        super("user-accounts");
    }

    activateSystemTypeSubscription() {
        return super.create(`${this.base}/admin/subscription/activate`);
    }

    cancelSubscription(subscriptionId) {
        return super.delete(`${this.base}/admin/subscription/{subscriptionId}/cancel`, { subscriptionId });
    }

    getUserSubscriptionInfo(userId, options) {
        return super.get(`${this.base}/admin/subscription/{id}/info{?embed}`, userId, options);
    }

    update(resource) {
        return super.update(`${this.base}/{id}`, resource);
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?embed,fields}`, id, options);
    }

    find(filter) {
        return super.find(`${this.base}/admin/users/{?searchQuery,page,rpp,sort,embed,fields}`, filter);
    }

    artistRegister() {
        return super.create(`${this.base}/artist`);
    }
}

export default UserAccountApiRouteService;
