import React from "react";
import { PropTypes } from "prop-types";
import { mergeCss } from "mrb/common/utils";
import { MrbCheckbox } from "mrb/components/checkbox";
import { getContentActivityStatusIcon } from "application/home/utils";
import { podcastType, podcastContentType } from "common/constants";
import { PremiumBadge, Image } from "common/components";
import { useCanAccessContent, nakiRadioContentType } from "application/common/content-access";
import { isNil } from "lodash";
import { observer } from "mobx-react";

function HomeGridCellTemplate({ onCellClick, goTo, item, homeManageSelectedItemsViewStore }) {
    const { isBulk, selectItem, selectedItems } = homeManageSelectedItemsViewStore;
    let contentType;
    if (!isNil(item.stationId)) {
        contentType = nakiRadioContentType.station;
    } else if (!isNil(item.podcastId)) {
        contentType = nakiRadioContentType.podcast;
    }
    const contentAccessResult = useCanAccessContent(contentType, {
        isPremium: item.isPremium,
        isLegacySubscriptionValid: item.validLegacySubscription,
        unavailableInMobileApp: item.isVisibleInApp === false,
        unavailableInNakiTouch: item.isVisibleInEmbeddedApp === false,
    });

    return (
        <div
            className={mergeCss(
                "c-home__card",
                !isNil(item.stationId) ? "c-home__card--stations" : null,
                isBulk &&
                    (contentAccessResult.canAccess && item.podcastType !== podcastType.podcastGroup
                        ? "c-home__card--secondary"
                        : "c-home__card--tertiary")
            )}
        >
            {isBulk && contentAccessResult.canAccess && item.podcastType !== podcastType.podcastGroup && (
                <div className="c-home__bulk">
                    <MrbCheckbox
                        classNameExtend="c-input--check--tertiary"
                        onChange={() => {}}
                        value={
                            selectedItems.find(
                                (x) => x.stationId === item.stationId && x.podcastId === item.podcastId
                            ) !== undefined
                        }
                    />
                </div>
            )}
            <div
                className="c-home__card__media"
                onClick={() => {
                    isBulk ? selectItem(item) : onCellClick(item);
                }}
            >
                <div
                    className={
                        contentAccessResult.canAccess && item.podcastContentType !== podcastContentType.torahAnytime
                            ? mergeCss("c-home__status", getContentActivityStatusIcon(item))
                            : null
                    }
                ></div>

                <div
                    className={mergeCss(
                        "c-home__card__img-wrapper jq-onboarding-turn-on-off",
                        contentAccessResult.canAccess &&
                            item.podcastType !== podcastType.podcastGroup &&
                            item.podcastContentType !== podcastContentType.torahAnytime
                            ? item.isTurnedOn
                                ? "status--disabled"
                                : "status--enabled"
                            : item.podcastType !== podcastType.podcastGroup &&
                              item.podcastContentType !== podcastContentType.torahAnytime
                            ? "status--off"
                            : null,
                        !contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null
                    )}
                >
                    <Image src={item.coverImageUrl} />
                    <PremiumBadge
                        isPremium={item.isPremium}
                        isPremiumSubscriptionValid={contentAccessResult.canAccessPremium}
                    />
                </div>
            </div>

            <div className="c-home__card__title jq-onboarding-title" onClick={() => goTo(item)}>
                {item.title}
            </div>
        </div>
    );
}

HomeGridCellTemplate.propTypes = {
    onCellClick: PropTypes.func.isRequired,
    premiumSubscriptionStore: PropTypes.object.isRequired,
    goTo: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
};

export default observer(HomeGridCellTemplate);
