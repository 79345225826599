import { React } from "react";
import PropTypes from "prop-types";
import { MrbButton } from "../../button";
import { observer } from "mobx-react";

function MrbEditFormFooter({
    form,
    footer,
    visible = true,
    onClickCancel,
    submitLabel = "FORM_CONTROLS.SAVE_BUTTON",
    cancelLabel = "FORM_CONTROLS.CANCEL_BUTTON",
    hideCancel = false,
    submitDisabled = false,
    t = (i) => i,
}) {
    const label = t(submitLabel);
    return visible ? (
        footer ? (
            footer
        ) : (
            <div className="c-form__footer">
                <MrbButton
                    type="submit"
                    onClick={form.onSubmit}
                    aria-label={label}
                    aria-busy={form.submitting || form.validating}
                    label={label}
                    className="c-btn c-btn--primary c-btn--base"
                    disabled={submitDisabled}
                />{" "}
                {!hideCancel && onClickCancel && (
                    <MrbButton
                        className="c-btn c-btn--ghost c-btn--base"
                        t={t}
                        label={t(cancelLabel)}
                        onClick={onClickCancel}
                    />
                )}
            </div>
        )
    ) : null;
}

MrbEditFormFooter.propTypes = {
    form: PropTypes.object,
    footer: PropTypes.any,
    visible: PropTypes.bool,
    onClickCancel: PropTypes.func,
    submitLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    t: PropTypes.func,
    hideCancel: PropTypes.bool,
    submitDisabled: PropTypes.bool,
};

export default observer(MrbEditFormFooter);
