import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "mrb/components/button";
import { isNil } from "lodash";

function CreditCardInfoTemplate({ creditCard, onClickUpdateCreditCard, t }) {
    if (isNil(creditCard)) {
        return null;
    }

    return (
        <div className="u-display--flex u-display--flex--ai--center">
            <div className={`u-icon u-icon--xlrg u-icon--${creditCard.brand} u-mar--right--tny`}></div>
            <span className="u-mar--right--tny">#### #### #### {creditCard.last4Numbers} </span>{" "}
            <span className="u-type--base u-type--color--opaque">
                {t(`APPLICATION.PREMIUM.CREDIT_CARD_BRAND.${creditCard.brand.toUpperCase()}`)}
            </span>
            {onClickUpdateCreditCard && (
                <MrbButton
                    label="Update Card"
                    className="c-btn c-btn--sml c-btn--ghost u-mar--left--sml"
                    onClick={onClickUpdateCreditCard}
                />
            )}
        </div>
    );
}

CreditCardInfoTemplate.propTypes = {
    creditCard: PropTypes.object.isRequired,
    onClickUpdateCreditCard: PropTypes.func,
    secondary: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

export default CreditCardInfoTemplate;
