import React, { useCallback, useState } from "react";
import ContentLoader from "react-content-loader";
const ProducerAccountListLoaderTemplate = (props) => {
    const [loaderWidth, setLoaderWidth] = useState(0);
    const loaderHeight = 56;
    const listItemHeight = 16;

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    const ListItem = ({ y }) => {
        return (
            <React.Fragment>
                <rect x="0" y={y} rx="6" ry="6" width="100" height={listItemHeight} />
                <rect x="60%" y={y} rx="6" ry="6" width="10%" height={listItemHeight} />
                <rect x="75%" y={y} rx="6" ry="6" width="10%" height={listItemHeight} />
                <rect x={loaderWidth - 140} y={y} rx="6" ry="6" width="100" height={listItemHeight} />
            </React.Fragment>
        );
    };

    return (
        <div ref={ref}>
            <AccountTitle loaderWidth={loaderWidth} />

            <div className="c-card c-card--med c-card--primary u-mar--top--tny">
                <ContentLoader
                    speed={2}
                    width="100%"
                    height={loaderHeight}
                    viewBox={`0 0 ${props.loaderWidth} ${loaderHeight}`}
                    backgroundColor="#262729"
                    foregroundColor="#2b2b2b"
                    {...props}
                >
                    <ListItem y={0} />
                    <rect x="0" y="28" rx="6" ry="6" width="100%" height="1" />
                    <ListItem y={40} />
                </ContentLoader>
            </div>
        </div>
    );
};

export default ProducerAccountListLoaderTemplate;

const AccountTitle = (props) => {
    const loaderHeight = 20;
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={loaderHeight}
            viewBox={`0 0 ${props.loaderWidth} ${loaderHeight}`}
            backgroundColor="#3C3D3E"
            foregroundColor="#404143"
            {...props}
        >
            <rect x="0" y="0" rx="6" ry="6" width="140" height={loaderHeight} />
            <rect x="150" y="0" rx="6" ry="6" width="20" height={loaderHeight} />
        </ContentLoader>
    );
};
