import React from "react";
import PropTypes from "prop-types";
import { MrbPrimaryMenuLevel } from "./";
import { observer } from "mobx-react";

function MrbMenu({ menuStore, t = (i) => i }) {
    return (
        <nav className="c-nav">
            <MrbPrimaryMenuLevel menuStore={menuStore} t={t} />
        </nav>
    );
}

MrbMenu.propTypes = {
    menuStore: PropTypes.object.isRequired,
};

export default observer(MrbMenu);
