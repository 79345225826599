import { BaseApiRouteService } from "mrb/core";

class PortalAnalyticsApiRouteService extends BaseApiRouteService {
    constructor() {
        super("analytics/portal");
    }

    getUserPortalStatsSummary(userId) {
        return super.get(`${this.base}/user/{id}/summary`, userId);
    }

    getPortalAnalytics(filter) {
        return super.find(`${this.base}/{?from,to,previousCycleFrom,previousCycleTo}`, filter);
    }

    getRegisteredUsersSummary(filter) {
        return super.find(`${this.base}/registered-users/summary/{?from,to,previousCycleFrom,previousCycleTo}`, filter);
    }

    getRegisteredUsersActivity(filter) {
        return super.find(`${this.base}/registered-users/activity/{?from,to,timeZone}`, filter);
    }

    getRegisteredUserDevicesActivity(filter) {
        return super.find(`${this.base}/registered-user-devices/activity/{?from,to,timeZone}`, filter);
    }

    getRegisteredUserDevicesSummary(filter) {
        return super.find(
            `${this.base}/registered-user-devices/summary/{?from,to,previousCycleFrom,previousCycleTo}`,
            filter
        );
    }

    getConnectedDevicesActivity(filter) {
        return super.find(`${this.base}/connected-devices/activity/{?from,to,timeZone}`, filter);
    }

    getConnectedDevicesSummary(filter) {
        return super.find(
            `${this.base}/connected-devices/summary/{?from,to,previousCycleFrom,previousCycleTo}`,
            filter
        );
    }

    getPremiumSubscriptionsActivity(filter) {
        return super.find(`${this.base}/subscriptions/activity/{?from,to,timeZone}`, filter);
    }

    getPremiumSubscriptionsSummary(filter) {
        return super.find(`${this.base}/subscriptions/summary/{?from,to,previousCycleFrom,previousCycleTo}`, filter);
    }
}

export default PortalAnalyticsApiRouteService;
