import React from "react";
import { PropTypes } from "prop-types";

function ProducerLabelTemplate({ name, isUser = false, user }) {
    let userName;
    if (user) {
        userName = `${user.firstName} ${user.lastName}`;
    }

    return (
        <React.Fragment>
            {name}
            {isUser && (
                <React.Fragment>
                    <i className="u-mar--left--tny c-nav__icon u-icon--med u-icon--profile" title="Registered User" />
                    {userName && userName !== name ? `(${userName})` : null}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

ProducerLabelTemplate.propTypes = {
    name: PropTypes.string.isRequired,
    isUser: PropTypes.bool,
    user: PropTypes.object,
};

export default ProducerLabelTemplate;
