import React from "react";
import { PropTypes } from "prop-types";
import { Line } from "react-chartjs-2";
import { observer } from "mobx-react";
import { DeviceActivityEmptyState } from "administration/analytics/components";
import { MrbContentLoader } from "mrb/components/loader";

function createChartjsParams(canvas, data, labels, config) {
    const ctx = canvas.getContext("2d");
    const height = canvas.getAttribute("height");
    const gradient = ctx.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, "rgba(51,157,255, 0.5)");
    gradient.addColorStop(0.5, "rgba(51,157,255, 0.25)");
    gradient.addColorStop(1, "rgba(51,157,255, 0)");
    return {
        labels: labels,
        datasets: [
            {
                label: config.label,
                data: data,
                fill: config.fill,
                backgroundColor: gradient,
                borderColor: "#339dff",
                lineTension: 0.3,
            },
        ],
    };
}

function LineChartTemplate({ store, loaderRenderer }) {
    const { data, labels, config, loaderStore } = store;
    return (
        <MrbContentLoader loaderStore={loaderStore} loaderRender={loaderRenderer}>
            {() => (
                <div className="u-animation--fadeIn c-chart__line--med">
                    {data.length > 0 ? (
                        <Line
                            data={(canvas) => createChartjsParams(canvas, data, labels, config)}
                            options={config.options}
                        />
                    ) : (
                        <DeviceActivityEmptyState />
                    )}
                </div>
            )}
        </MrbContentLoader>
    );
}

LineChartTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    loaderRenderer: PropTypes.func,
};

export default observer(LineChartTemplate);
