import { BaseApiRouteService } from "mrb/core";

class ProductApiRouteService extends BaseApiRouteService {
    constructor() {
        super("application-product");
    }

    getSubscription(id, options) {
        return super.get(`${this.base}/subscription/{id}{?embed,fields}`, id, options);
    }

    getAllSubscriptions() {
        return super.get(`${this.base}/subscription`);
    }

    getSubscriptionBundle(id, options) {
        return super.get(`${this.base}/subscription/bundle/{id}`, id, options);
    }

    getAllSubscriptionBundles() {
        return super.get(`${this.base}/subscription/bundle`);
    }
}

export default ProductApiRouteService;
