import { default as MrbBaseAuthenticationStore } from "../core/stores/base/MrbBaseAuthenticationStore";
import { isNil } from "lodash";

/**
 * Handles whether an user is authenticated and therefore is able to access application main services.
 * @class
 * @memberof Core.Stores
 */
class BaasicAuthenticationStore extends MrbBaseAuthenticationStore {
    /**
     * @function
     * @protected
     * @description Synchronize user authentication token with Baasic SDK instance.
     */
    syncToken() {
        const { application } = this.rootStore;
        const token =
            application && application.baasic
                ? application.baasic.getAccessToken() || null
                : null;
        this.setToken(token);
    }

    /**
     * @function
     * @public
     * @description Initialize authentication store and underlying services.
     */
    initialize() {
        this.initAuthEvents();
        super.initialize();
    }

    /**
     * @function
     * @protected
     * @description Initialize authentication events.
     */
    initAuthEvents() {
        const { application } = this.rootStore;
        document.addEventListener(
            application.baasic.tokenHandler.tokenExpiredEventName,
            () => {
                this.syncToken();
            }
        );

        document.addEventListener(
            application.baasic.tokenHandler.tokenUpdatedEventName,
            () => {
                this.syncToken();
            }
        );
    }

    async login(username, password, options = null) {
        const { application } = this.rootStore;
        await application.baasic.membershipModule.login.login({
            username,
            password,
            options: options,
        });
    }

    async logout() {
        const { application } = this.rootStore;
        const token = application.baasic.getAccessToken();
        await application.baasic.membershipModule.login.logout(
            token.token,
            token.type
        );
        this.resetSignInRedirect();
    }

    isTokenValid(token) {
        const isValid =
            !isNil(token) &&
            (token.expireTime === undefined ||
                token.expireTime === null ||
                token.expireTime - new Date().getTime() > 0);
        return isValid;
    }
}

export default BaasicAuthenticationStore;
