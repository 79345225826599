import React from "react";
import { PropTypes } from "prop-types";
import { MrbRadioButtons } from "mrb/components/radio";
import { HomeDeviceSelect } from "application/home/components";
import { MrbQuerySearchInput } from "mrb/components/input";
import { JoyrideOnboarding, ApplicationWidgetPanel } from "common/components";
import { observer } from "mobx-react";
import { mergeCss } from "mrb/common/utils";

function HomePageHeaderTemplate({ homeViewStore, t }) {
    const {
        deviceSelectStore,
        contentTypeOptions,
        changeContentTypeFilter,
        queryUtility,
        homeGenericSearchViewStore,
        anyDevice,
        inSearchMode,
        rootStore,
    } = homeViewStore;

    return (
        <div className="c-home__header">
            <JoyrideOnboarding joyrideViewStore={rootStore.joyrideViewStore} />
            <div className="c-home__header__input__wrapper">
                <div className="u-animation--fadeIn">
                    <div className="c-home__header__input">
                        <MrbQuerySearchInput
                            className="c-input c-input--search c-input--search--lrg c-input--base jq-onboarding-search"
                            queryUtility={homeGenericSearchViewStore.queryUtility}
                            showSearchButton={false}
                            t={t}
                        />
                        <span className="c-home__header__label">
                            {t("APPLICATION.HOME.LIST.GENERIC_SEARCH.SEARCH_DESCRIPTION")}
                        </span>
                    </div>
                </div>
            </div>

            <div className="c-premium__info--full">
                <ApplicationWidgetPanel rootStore={rootStore} isContentPolicyVisible={false} t={t} />
            </div>

            <div className={mergeCss(
                "c-home__header__actions",
                !rootStore.premiumSubscriptionStore.subscriptionExist ? "c-home__header__actions--secondary" : "")}>
                <div className="u-display--flex u-display--flex--ai--center">
                    <div className="u-animation--fadeIn">
                        {anyDevice && !inSearchMode && (
                            <div className="u-mar--right--sml">
                                <HomeDeviceSelect
                                    store={deviceSelectStore}
                                    userDeviceStore={rootStore.userDeviceStore}
                                    t={t}
                                />
                            </div>
                        )}
                    </div>
                    <div className="u-animation--fadeIn">
                        <MrbRadioButtons
                            id="home-content-type-control"
                            name="home-content-type-control"
                            options={contentTypeOptions}
                            onChange={changeContentTypeFilter}
                            value={queryUtility.filter.contentType}
                            t={t}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

HomePageHeaderTemplate.propTypes = {
    homeViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(HomePageHeaderTemplate);
