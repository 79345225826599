import { MrbBaseRouteStore } from "mrb/core";
import {
    UserContentService,
    StationFileStreamService,
    PodcastFileStreamService,
    GenreLookupService,
} from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";

class HomeRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(UserContentService);
        this.podcastFileStreamService = this.rootStore.createApplicationService(PodcastFileStreamService);
        this.stationFileStreamService = this.rootStore.createApplicationService(StationFileStreamService);
        this.genreLookupService = this.rootStore.createApplicationService(GenreLookupService);
    }

    async getFeaturedContent(deviceId, contentType) {
        const response = await this.service.getFeaturedContent(deviceId, contentType);
        return response.data;
    }

    async genericSearch(params, abortController) {
        const response = await this.service.genericSearch(params, abortController);
        return response.data;
    }

    async findUserContent(params) {
        const response = await this.service.find(params);
        return response.data;
    }

    async findGenres(searchPhrase) {
        const response = await this.genreLookupService.find(searchPhrase);
        return response.data;
    }

    createStationCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.stationFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }

    createPodcastCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.podcastFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }
}

export default HomeRouteStore;
