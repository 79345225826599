import React from "react";
import { components } from "react-select";
import { FixedSizeList } from "react-window";
import { observer } from "mobx-react";
import { findIndex } from "lodash";

class MrbSelectVirtualizedList extends React.Component {
    virtualListRef = React.createRef();
    rowHeight = 47;

    componentDidUpdate(prevProps) {
        const { focusedOption, options } = this.props;
        const previousFocusedIndex = prevProps.focusedOption
            ? findIndex(
                  options,
                  (option) => option.value === prevProps.focusedOption.value
              )
            : -1;
        const focusedOptionIndex = focusedOption
            ? findIndex(
                  options,
                  (option) => option.value === focusedOption.value
              )
            : -1;
        if (
            previousFocusedIndex !== focusedOptionIndex &&
            this.virtualListRef.current
        ) {
            this.virtualListRef.current.scrollToItem(focusedOptionIndex);
        }
    }

    render() {
        const { children, maxHeight, getValue, options } = this.props;
        if (children && !children.length) {
            return <components.NoOptionsMessage {...children.props} />;
        }

        const [value] = getValue();
        let initialOffset = 0;
        if (value) {
            const valueIndex = findIndex(
                options,
                (option) => option.value === value.value
            );
            if (valueIndex > 0) {
                initialOffset = valueIndex * this.rowHeight;
            }
        }
        const potentialTotalHeight = children.length * this.rowHeight;
        return (
            <FixedSizeList
                ref={this.virtualListRef}
                height={
                    potentialTotalHeight >= maxHeight
                        ? maxHeight
                        : potentialTotalHeight
                }
                itemCount={children.length}
                itemSize={this.rowHeight}
                initialScrollOffset={
                    initialOffset >= maxHeight ? initialOffset : null
                }
            >
                {({ index, style }) => {
                    return <div style={style}>{children[index]}</div>;
                }}
            </FixedSizeList>
        );
    }
}

export default observer(MrbSelectVirtualizedList);
