import React from "react";
import { PropTypes } from "prop-types";
import { MrbContentLoader } from "mrb/components/loader";
import { observer } from "mobx-react";
import { AdminProducerSummaryLoader } from "administration/producer/components";
import { map } from "lodash";
import { MrbRouterLink } from "mrb/core/infrastructure/router";

function AdminProducerAccountAssignedProducersPreviewTemplate({ adminProducerAccountPreviewViewStore, t }) {
    const { assignedProducers, loaderStore } = adminProducerAccountPreviewViewStore;

    return (
        <React.Fragment>
            <div className="u-mar--bottom--sml">
                {assignedProducers && (
                    <h1 className="u-type--base u-mar--bottom--sml u-mar--top--sml">
                        {assignedProducers.length > 1 ? "Producers" : "Producer"}
                    </h1>
                )}
                <div className="c-card--primary c-card--med">
                    <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <AdminProducerSummaryLoader />}>
                        {() => (
                            <div className="u-animation--fadeIn">
                                {assignedProducers &&
                                    map(assignedProducers, (item) => (
                                        <div className="u-animation--fadeIn">
                                            <div className="row u-mar--bottom--sml">
                                                <div className="col col-sml-7">
                                                    <p className="u-type--sml u-type--color--opaque">Producer</p>
                                                    <p className="u-display--flex u-display--flex--ai--center">
                                                        <MrbRouterLink
                                                            routeName="master.administration.producer.preview"
                                                            params={{ id: item.producerId }}
                                                        >
                                                            {`${item.producerName} `}
                                                        </MrbRouterLink>
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="u-type--sml u-type--color--opaque">Revenue Share</p>
                                                    <div className="u-display--flex u-display--flex--ai--center">
                                                        <p>{item.revenuePercentage}%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </MrbContentLoader>
                </div>
            </div>
        </React.Fragment>
    );
}

AdminProducerAccountAssignedProducersPreviewTemplate.propTypes = {
    adminProducerAccountPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(AdminProducerAccountAssignedProducersPreviewTemplate);
