import { BaseService } from "mrb/core";
import { LocationApiRouteService } from "common/services";

class LocationService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new LocationApiRouteService());
    }
}

export default LocationService;
