import React from "react";
import { PropTypes } from "prop-types";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { mergeOrOverrideCss } from "../../common/utils";
import { MrbSelectVirtualizedList } from "./";
import { observer } from "mobx-react";

function MrbSelect({
    store,
    disabled,
    placeholder,
    autoFocus,
    classNameExtend,
    classNameOverride,
    onChange,
    value,
    formatOptionLabel,
    ...props
}) {
    const {
        isCreatable,
        config,
        onOpen,
        onClose,
        options,
        setSelectRef,
        setSearchTerm,
        loaderStore,
        createOption,
        selectedOption,
        loadNextPage,
    } = store;

    function onChangeFn(selected) {
        if (onChange) {
            onChange(selected);
        } else {
            store.onChange(selected);
        }
    }

    const className = mergeOrOverrideCss(null, classNameOverride, classNameExtend);

    const components = props.components || {};
    if (!components.MenuList && config.virtualized) {
        components.MenuList = MrbSelectVirtualizedList;
    }

    const SelectComponent = isCreatable ? Creatable : Select;

    return (
        <SelectComponent
            ref={setSelectRef}
            className={className}
            value={selectedOption}
            autoFocus={autoFocus}
            isDisabled={disabled}
            options={options}
            onInputChange={setSearchTerm}
            filterOption={() => true}
            onCreateOption={createOption}
            createOptionPosition={config.createOptionPosition}
            formatCreateLabel={config.formatCreateLabel}
            isSearchable={config.isSearchable}
            formatOptionLabel={formatOptionLabel}
            isClearable={config.isClearable}
            isMulti={config.isMulti}
            isLoading={loaderStore.loading}
            onMenuOpen={onOpen}
            onMenuClose={onClose}
            onChange={onChangeFn}
            onMenuScrollToBottom={loadNextPage}
            placeholder={placeholder || config.placeholder}
            components={components}
            isOptionDisabled={config.isOptionDisabled}
            classNamePrefix="react-select"
            {...props}
        />
    );
}

MrbSelect.propTypes = {
    store: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    classNameExtend: PropTypes.string,
    classNameOverride: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    formatOptionLabel: PropTypes.func,
};

export default observer(MrbSelect);
