import { BaseApiRouteService } from "mrb/core";

class UserStationExpirationApiRouteService extends BaseApiRouteService {
    constructor() {
        super("station-expiration");
    }

    find(filter) {
        return super.find(
            `${this.base}/{?userId,searchQuery,page,rpp,sort}`,
            filter
        );
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?embed,fields}`, id, options);
    }

    create(resource) {
        return super.create(this.base, resource);
    }

    update(resource) {
        return super.update(`${this.base}/{id}`, resource);
    }

    delete(resource) {
        return super.delete(`${this.base}/{id}`, resource);
    }
}

export default UserStationExpirationApiRouteService;
