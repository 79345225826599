import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";

function ContentSyncWidgetTemplate({ contentEncoderSyncStore, t }) {
    if (!contentEncoderSyncStore.isSyncing) {
        return null;
    }

    return (
        <div className="c-content-policy__card">
            <p className="u-type--sml u-type--wgt--bold">
                <i className="u-icon u-icon--med u-icon u-icon--sync u-icon u-mar--right--tny"></i>
                {t("ADMINISTRATION.CONTENT_GENERATE.WIDGET.TITLE")}
            </p>
            <div className="u-display--flex u-mar--top--micro">
                {contentEncoderSyncStore.numberOfActiveTasks === 1 ? (
                    <p className="u-type--sml">{t("ADMINISTRATION.CONTENT_GENERATE.WIDGET.GENERATING_SINGLE")}</p>
                ) : (
                    <p className="u-type--sml">
                        {t("ADMINISTRATION.CONTENT_GENERATE.WIDGET.GENERATING_PLURAL", {
                            number: contentEncoderSyncStore.numberOfActiveTasks,
                        })}
                    </p>
                )}
            </div>
        </div>
    );
}

ContentSyncWidgetTemplate.propTypes = {
    contentEncoderSyncStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(ContentSyncWidgetTemplate);
