import { observable, makeObservable } from "mobx";
import { MrbFilterParameters } from "mrb/core/filter";

class HomeGenericSearchFilter extends MrbFilterParameters {
    @observable genreId;

    constructor() {
        super();
        makeObservable(this);
        this.reset();
    }

    reset() {
        super.reset();
        this.genreId = null;
    }
}

export default HomeGenericSearchFilter;
