import { MrbBaseListViewStore } from "mrb/core";
import { MrbGridViewStore } from "mrb/components/grid";
import { StationAnalyticsService } from "common/services";
import { computed, makeObservable } from "mobx";

class StationListenersListViewStore extends MrbBaseListViewStore {
    @computed get isInitalized() {
        return this.gridStore.dataInitialized;
    }

    constructor(rootStore, stationListenersListViewStore) {
        super(rootStore, {});
        makeObservable(this);
        this.stationListenersListViewStore = stationListenersListViewStore;
        this.routeStore = this.stationListenersListViewStore.routeStore;
        this.service = rootStore.createApplicationService(StationAnalyticsService);

        this.setGridStore(
            new MrbGridViewStore(this.rootStore, this.queryUtility, {
                generateKey: (item) => item.id,
                mapper: (item, i) => {
                    return {
                        ...item,
                        coverImageUrl: this.createCoverImageUrl(item),
                        position: i + 1,
                    };
                },
            })
        );
        this.createCoverImageUrl = this.createCoverImageUrl.bind(this);
    }

    async onInit() {
        this.gridStore.suspend();
        const response = await this.service.getStationListenersStats();
        this.gridStore.setData(response.data);
        this.gridStore.resume();
    }

    createCoverImageUrl(item) {
        if (item && item.coverImageId) {
            return this.rootStore.coverImageFactory.createStationCoverImageUrl(item.coverImageId, 150, 150);
        }
        return null;
    }
}

export default StationListenersListViewStore;
