import { BaseCacheService } from "mrb/core/cache";
import { DeviceMenuTypeService } from "common/services";

class DeviceMenuTypeCacheService extends BaseCacheService {
    constructor(rootStore) {
        super("device-menu-type", "lookup", async () => {
            const response = await this.service.get();
            return response.data;
        });
        this.service = rootStore.createApplicationService(
            DeviceMenuTypeService
        );
    }
}

export default DeviceMenuTypeCacheService;
