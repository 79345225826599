import React from "react";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { applicationDefaults } from "common/constants";

function PaymentInfoTemplate({ userSubscriptionInfoViewStore }) {
    const { isPremiumTrialPeriodUsed } = userSubscriptionInfoViewStore;
    return (
        <React.Fragment>
            <div className="c-payment">
                <div className="c-payment__body">
                    <i className="u-icon u-icon--subscription u-icon--subscription--premium"></i>
                </div>

                <div className="c-payment__footer">
                    <p className="c-payment__title">You will get:</p>
                    <p className="c-payment__body__item">
                        <i className="u-icon u-icon--base u-icon--line-arrow u-mar--right--tny"></i>
                        250+ hours of quality content from world-class producers
                    </p>
                    <p className="c-payment__body__item">
                        <i className="u-icon u-icon--base u-icon--line-arrow u-mar--right--tny"></i>
                        Story Shuffle compatible, exclusive Premium Shuffle included
                    </p>
                    <p className="c-payment__body__item">
                        <i className="u-icon u-icon--base u-icon--line-arrow u-mar--right--tny"></i>
                        Pause / Resume, Fast Forward / Rewind compatible
                    </p>
                    <p className="c-payment__body__item">
                        <i className="u-icon u-icon--base u-icon--line-arrow u-mar--right--tny"></i>
                        Cancel anytime
                    </p>
                    <p className="c-payment__body__item">
                        <i className="u-icon u-icon--base u-icon--line-arrow u-mar--right--tny"></i>
                        Pricing: <span className="u-type--wgt--bold u-mar--left--tny">$10/month</span>
                    </p>
                </div>

                {isPremiumTrialPeriodUsed ? (
                    <MrbRouterLink
                        className="c-btn c-btn--base c-btn--full c-btn--primary u-type--wgt--semibold u-mar--top--sml"
                        routeName="master.application.premium.payment"
                    >
                        $10/month
                    </MrbRouterLink>
                ) : (
                    <MrbRouterLink
                        className="c-btn c-btn--base c-btn--full c-btn--primary u-type--wgt--semibold u-mar--top--sml"
                        routeName="master.application.premium.trial"
                    >
                        Free {applicationDefaults.trialPeriodDays}-day Trial
                    </MrbRouterLink>
                )}
            </div>
        </React.Fragment>
    );
}

export default PaymentInfoTemplate;
