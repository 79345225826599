import { BaseCacheService } from "mrb/core/cache";
import { EpisodeSortTypeService } from "common/services";

class EpisodeSortTypeCacheService extends BaseCacheService {
    constructor(rootStore) {
        super("episode-sort-type", "lookup", async () => {
            const response = await this.service.get();
            return response.data;
        });
        this.service = rootStore.createApplicationService(
            EpisodeSortTypeService
        );
    }
}

export default EpisodeSortTypeCacheService;
