export const fileExtensions = {
    image: {
        png: ".png",
        jpg: ".jpg",
        jpeg: ".jpeg",
        bmp: ".bmp",
        svg: ".svg",
        heic: ".heic",
        tif: ".tif",
        tiff: ".tiff",
    },
    document: {
        pdf: ".pdf",
        docx: ".docx",
        xlsx: ".xlsx",
        xls: ".xls",
        txt: ".txt",
        tif: ".tif",
        tiff: ".tiff",
        csv: ".csv",
    },
    video: {
        avi: ".avi",
        flv: ".flv",
        swf: ".swf",
        wmv: ".wmv",
        mpeg: ".mpeg",
        mpg: ".mpg",
        mp4: ".mp4",
        mp3: ".mp3",
        mov: ".mov",
        m4a: ".m4a",
        "3gp": ".3gp",
        rm: ".rm",
        ogv: ".ogv",
        webm: ".webm",
        mpe: ".mpe",
    },
};

export default fileExtensions;
