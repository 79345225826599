import { MrbBaseViewStore } from "./";
import { action, observable, makeObservable, runInAction } from "mobx";
import { httpStatusCodes } from "../../constants";
import { isBoolean, isFunction, isNil, isUndefined, isString, isEmpty } from "lodash";
import MobxReactFormDevTools from "mobx-react-form-devtools";

class MrbBaseEditViewStore extends MrbBaseViewStore {
    id = null;
    name = null;
    form = null;

    @observable item = null;

    get isEdit() {
        return !isNil(this.id) && !isUndefined(this.id);
    }

    constructor(
        rootStore,
        {
            id,
            name,
            title,
            form,
            FormClass,
            redirectOnCreateSuccess = true,
            redirectOnUpdateSuccess = true,
            successCreateNotification,
            successUpdateNotification,
            autoFocusField = null,
            navigateBack = null,
        }
    ) {
        super(rootStore);
        makeObservable(this);
        this.id = id;
        this.name = name;
        this.title = title;
        this.redirectOnCreateSuccess = redirectOnCreateSuccess;
        this.redirectOnUpdateSuccess = redirectOnUpdateSuccess;
        this.successCreateNotification = successCreateNotification;
        this.successUpdateNotification = successUpdateNotification;
        this.navigateBack = this._createNavigateBackAction(navigateBack);

        this.form =
            form ||
            new FormClass({
                onSuccess: (form) => {
                    const item = form.values();
                    if (this.isEdit) {
                        return this.updateResource(item);
                    } else {
                        return this.createResource(item);
                    }
                },
                onInit: (form) => {
                    if (autoFocusField) {
                        form.$(autoFocusField).focus();
                    }
                },
            });

        if (process.env.NODE_ENV === "development") {
            MobxReactFormDevTools.register({ [this.name]: this.form });
            MobxReactFormDevTools.select(this.name);
        }

        this.onClickCancel = this.onClickCancel.bind(this);
    }

    onInit() {
        return this.initializeResource();
    }

    async initializeResource() {
        if (this.id) {
            try {
                await this.getResource(this.id);
                return true;
            } catch (err) {
                if (err.statusCode === httpStatusCodes.NotFound) {
                    await this.rootStore.routerStore.goToNotFound();
                    return false;
                } else {
                    throw err;
                }
            }
        } else {
            this.resetForm();
        }
    }

    @action.bound
    resetForm() {
        this.form.clear();
    }

    @action.bound
    async createResource(data) {
        this.form.setFieldsDisabled(true);
        try {
            const result = await this.create(data);
            this.form.setFieldsDisabled(false);
            this.onCreateSuccess(result);
        } catch (err) {
            this.form.setFieldsDisabled(false);
            this.onCreateError(err);
        }
    }

    @action.bound
    async updateResource(data) {
        this.form.setFieldsDisabled(true);
        try {
            const result = await this.update({ id: this.id, ...data });
            this.form.setFieldsDisabled(false);
            this.onUpdateSuccess(result);
        } catch (err) {
            this.form.setFieldsDisabled(false);
            this.onUpdateError(err);
        }
    }

    get(id) {
        throw new Error("Method not implemented.");
    }

    create(data) {
        throw new Error("Method not implemented.");
    }

    update(data) {
        throw new Error("Method not implemented.");
    }

    async getResource(id) {
        const item = await this.get(id);
        runInAction(() => {
            this.setItem(item);
            this.updateForm(item);
        });
    }

    @action.bound
    setItem(item) {
        this.item = item;
    }

    @action.bound
    updateForm(values) {
        this.form.set(values);
    }

    @action.bound
    onCreateSuccess(result) {
        this.notifySuccessCreate();

        if (isBoolean(this.redirectOnCreateSuccess) && this.redirectOnCreateSuccess) {
            this.rootStore.routerStore.goBack();
        } else if (isFunction(this.redirectOnCreateSuccess)) {
            this.redirectOnCreateSuccess(result);
        }
    }

    @action.bound
    onUpdateSuccess(result) {
        this.notifySuccessUpdate();

        if (isBoolean(this.redirectOnUpdateSuccess) && this.redirectOnUpdateSuccess) {
            this.rootStore.routerStore.goBack();
        } else if (isFunction(this.redirectOnUpdateSuccess)) {
            this.redirectOnUpdateSuccess(result);
        }
    }

    notifySuccessCreate() {
        if (isString(this.successCreateNotification) && !isEmpty(this.successCreateNotification)) {
            this.rootStore.notificationStore.success(this.successCreateNotification);
        }
    }

    notifySuccessUpdate() {
        if (isString(this.successUpdateNotification) && !isEmpty(this.successUpdateNotification)) {
            this.rootStore.notificationStore.success(this.successUpdateNotification);
        }
    }

    onCreateError(error) {
        throw error;
    }

    onUpdateError(error) {
        throw error;
    }

    onClickCancel() {
        this.rootStore.routerStore.goBack();
    }

    _createNavigateBackAction(action) {
        if (action && isString(action)) {
            const route = this.rootStore.routerStore.getRoute(action);
            if (route && (!route.authorization || this.rootStore.userStore.hasPermission(route.authorization))) {
                return {
                    title: route.data ? route.data.title : "Back",
                    routeName: route.name,
                };
            }
        }
    }

    destroy() {
        super.destroy();
        this.form.destroy();
    }
}

export default MrbBaseEditViewStore;
