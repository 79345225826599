import { BaseApiRouteService } from "mrb/core";

class OrderApiRouteService extends BaseApiRouteService {
    constructor() {
        super("orders");
    }

    find(filter) {
        return super.find(`${this.base}/{?searchQuery,page,rpp,sort,embed,fields}`, filter);
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?embed,fields}`, id, options);
    }
}

export default OrderApiRouteService;
