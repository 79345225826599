import { BaseApiRouteService } from "mrb/core";

class RssSyncApiRouteService extends BaseApiRouteService {
    constructor() {
        super("rss-sync");
    }

    hlsEncodeAlbums(data) {
        return super.create(`${this.base}/hls/naki-nigun`, {
            ids: data.ids,
            processHls: data.processHls,
            overwriteHls: data.overwriteHls,
            processMp3: data.processMp3,
            overwriteMp3: data.overwriteMp3,
        });
    }

    hlsEncodePodcasts(data) {
        return super.create(`${this.base}/hls/podcast`, {
            ids: data.ids,
            processHls: data.processHls,
            overwriteHls: data.overwriteHls,
            processMp3: data.processMp3,
            overwriteMp3: data.overwriteMp3,
        });
    }

    hlsEncodeAlbumTracks(data) {
        return super.create(`${this.base}/hls/album-track`, {
            ids: data.ids,
            processHls: data.processHls,
            overwriteHls: data.overwriteHls,
            processMp3: data.processMp3,
            overwriteMp3: data.overwriteMp3,
        });
    }

    hlsEncodePodcastEpisodes(data) {
        return super.create(`${this.base}/hls/podcast-episode`, {
            ids: data.ids,
            processHls: data.processHls,
            overwriteHls: data.overwriteHls,
            processMp3: data.processMp3,
            overwriteMp3: data.overwriteMp3,
        });
    }

    syncPodcasts(data) {
        return super.create(`${this.base}/podcast`, {
            ids: data.ids,
        });
    }

    syncNakiNigunAlbums(data) {
        return super.create(`${this.base}/naki-nigun`, {
            ids: data.ids,
        });
    }

    getAllRssSyncTasks() {
        return super.get(`${this.base}/sync-task`);
    }

    syncPodcastsBackground(data) {
        return super.create(`${this.base}/podcast/background`, {
            data,
        });
    }

    syncNakiNigunAlbumsBackground(data) {
        return super.create(`${this.base}/naki-nigun/background`, {
            data,
        });
    }
}

export default RssSyncApiRouteService;
