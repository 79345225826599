import React from "react";
import { mergeCss } from "mrb/common/utils";

const PremiumBadgeTemplate = ({ isPremium, isPremiumSubscriptionValid = false, premiumSize, iconSize }) => {
    return (
        <React.Fragment>
            {isPremium && !isPremiumSubscriptionValid && (
                <span
                    className={mergeCss(
                        "c-home__card--premium",
                        premiumSize ? `c-home__card--premium--${premiumSize}` : null
                    )}
                >
                    <i
                        className={mergeCss(
                            "u-icon u-icon--premium u-mar--right--micro",
                            iconSize && window.innerWidth > 545 ? `u-icon--${iconSize}` : "u-icon--base"
                        )}
                    ></i>
                    Premium
                </span>
            )}
        </React.Fragment>
    );
};

export default PremiumBadgeTemplate;
