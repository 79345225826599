import { action, observable, makeObservable, reaction } from "mobx";
import { MrbBaseSelectStore } from "./";
import { findIndex } from "lodash";

class MrbSimpleSelectStore extends MrbBaseSelectStore {
    @observable isOpen = false;
    @observable focusedIndex = 0;
    valueContainerRef = null;

    constructor(config, initialItems) {
        super(config, initialItems);
        makeObservable(this);

        reaction(
            () => this.isOpen,
            (isOpen) => {
                if (isOpen) {
                    this.onOpen();
                } else {
                    this.onClose();
                }
            }
        );

        this.toggleFocusPosition = this.toggleFocusPosition.bind(this);
    }

    onOpen() {
        if (this.selectedOption) {
            const index = findIndex(
                this.options,
                (option) => option.value === this.selectedOption.value
            );
            if (index !== -1) {
                this.setFocusedIndex(index);
            }
        }
        this.registerKeyDownEventListener();
        super.onOpen();
    }

    onClose() {
        this.removeKeyDownEventListener();
        this.resetFocusedIndex();
        super.onClose();
    }

    @action.bound
    setIsOpen(value) {
        this.isOpen = value;
    }

    @action.bound
    setFocusedIndex(value) {
        this.focusedIndex = value;
    }

    resetFocusedIndex() {
        this.setFocusedIndex(0);
    }

    close = () => {
        this.setIsOpen(false);
    };

    toggleIsOpen = () => {
        this.setIsOpen(!this.isOpen);
    };

    setValueContainerRef = (ref) => {
        this.valueContainerRef = ref;
    };

    onChange(selectedOption) {
        super.onChange(selectedOption);
        this.close();
    }

    onFocus = (event) => {
        this.setIsOpen(true);
    };

    onBlur = (event) => {
        this.close();
    };

    registerKeyDownEventListener() {
        document.addEventListener("keydown", this.toggleFocusPosition);
    }

    removeKeyDownEventListener() {
        document.removeEventListener("keydown", this.toggleFocusPosition);
    }

    toggleFocusPosition(event) {
        if (event.key === "ArrowDown") {
            event.preventDefault();
            event.stopPropagation();
            if (this.focusedIndex === this.options.length - 1) {
                this.setFocusedIndex(0);
            } else {
                this.setFocusedIndex(this.focusedIndex + 1);
            }
        } else if (event.key === "ArrowUp") {
            event.preventDefault();
            event.stopPropagation();
            if (this.focusedIndex === 0) {
                this.setFocusedIndex(this.options.length - 1);
            } else {
                this.setFocusedIndex(this.focusedIndex - 1);
            }
        } else if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            if (this.focusedIndex <= this.options.length - 1) {
                this.onChange(this.options[this.focusedIndex]);
            }
        }
    }
}

export default MrbSimpleSelectStore;
