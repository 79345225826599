import { MrbBaseFormatter } from "./";
import { mrbDefaultFormats } from "./";
import { isNil } from "lodash";

class CurrencyFormatter extends MrbBaseFormatter {
    _formatters = {};

    constructor() {
        super(mrbDefaultFormats.currency);
    }

    format(value, { currency, locale = "en-US" }) {
        const formatter = this._getOrAddFormatter(currency, locale);

        return formatter.format(value);
    }

    _getOrAddFormatter(currency, locale) {
        const key = `${locale}_${currency}`;
        let formatter = this._formatters[key];
        if (isNil(formatter)) {
            formatter = new Intl.NumberFormat(locale, { style: "currency", currency: currency });
            this._formatters[key] = formatter;
        }

        return formatter;
    }
}

const currencyFormatter = new CurrencyFormatter();
export default currencyFormatter;
