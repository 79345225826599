import { BaseApiRouteService } from "mrb/core";

class ProducerUserAccountApiRouteService extends BaseApiRouteService {
    constructor() {
        super("producer-accounts");
    }

    find(filter) {
        return super.find(
            `${this.base}/admin/producer-user-accounts/{?producerIds,userIds,page,rpp,sort,embed,fields}`,
            filter
        );
    }

    getProducerAccountsSummary(id) {
        return super.get(`${this.base}/admin/{id}/accounts-summary`, id);
    }

    getAdminProducerAccountSummary(accountId) {
        return super.get(`${this.base}/admin/account/${accountId}/summary`);
    }

    getAdminProducerAccountAssignedProducers(accountId) {
        return super.get(`${this.base}/admin/account/${accountId}/assigned-producers`);
    }

    getProducerAccountSummary() {
        return super.get(`${this.base}/account-summary`);
    }

    getAccountTotalBalance(id) {
        return super.get(`${this.base}/admin/account/{id}/total-balance`, id);
    }

    getProducerTotalBalance(id) {
        return super.get(`${this.base}/admin/{id}/total-balance`, id);
    }

    getStripeConnectAccounts() {
        return super.get(`${this.base}/admin/stripe-connect`);
    }

    getAdminProducerAccountBalance(filter) {
        return super.find(
            `${this.base}/admin/producer-user-account-balance/{?producerUserAccountIds,page,rpp,sort}`,
            filter
        );
    }

    getAdminProducerAccountRevenueTransaction(filter) {
        return super.find(
            `${this.base}/admin/producer-revenue-transaction/{?producerUserAccountIds,page,rpp,sort}`,
            filter
        );
    }

    getProducerAccountBalance(filter) {
        return super.find(`${this.base}/producer-user-account-balance/{?page,rpp,sort}`, filter);
    }

    getProducerAccountRevenueTransaction(filter) {
        return super.find(`${this.base}/producer-revenue-transaction/{?page,rpp,sort}`, filter);
    }

    updateAccounts(producerId) {
        return super.update(`${this.base}/admin/account/{id}`, { id: producerId });
    }

    addAccountBalance() {
        return super.create(`${this.base}/admin/account/add-balance`);
    }

    processPayoutToBankAccount(accountId) {
        return super.update(`${this.base}/{id}/payout`, { id: accountId });
    }

    updateStripeAccount() {
        return super.update(`${this.base}/admin/stripe-account-update`);
    }

    getProducers() {
        return super.get(`${this.base}/producers`);
    }

    getProducersSummary(filter) {
        return super.find(`${this.base}/admin/producers-summary/{?size,from,to}`, filter);
    }

    getStripeConnectOnboardingStatus() {
        return super.get(`${this.base}/stripe-connect-onboarding-status`);
    }

    createStripeConnectLink() {
        return super.create(`${this.base}/stripe-connect-link`);
    }

    getProducerUserAccountProducerType() {
        return super.get(`${this.base}/producer-type`);
    }
}

export default ProducerUserAccountApiRouteService;
