import React from "react";

function AppStartupLoaderTemplate() {
    return (
        <div className="init-loader--overlay">
            <div className="init-spin">
                <div className="init-dbl-bounce1"></div>
                <div className="init-dbl-bounce2"></div>
            </div>
        </div>
    );
}

export default AppStartupLoaderTemplate;
