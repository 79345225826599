import { MrbBaseGridViewStore } from "../../grid/stores";
import { MrbLoaderStore } from "../../loader";
import { merge, isNil } from "lodash";

const DefaultConfig = {
    onRowClick: null,
    columns: [],
};

class MrbBaseTableViewStore extends MrbBaseGridViewStore {
    constructor(rootStore, queryUtility, config = {}, components = {}) {
        super(
            rootStore,
            queryUtility,
            isNil(components.loaderStore) ? new MrbLoaderStore(config.loaderOptions) : components.loaderStore,
            merge({}, DefaultConfig, config)
        );

        if (!this.config.columns || this.config.columns.length === 0) {
            throw new Error("Columns are not defined.");
        }
    }
}

export default MrbBaseTableViewStore;
