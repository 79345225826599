import React from "react";
import PropTypes from "prop-types";
import { mergeOrOverrideCss } from "../../common/utils";
import { observer } from "mobx-react";

function MrbToggle({
    id,
    label,
    value,
    onChange,
    t = (i) => i,
    disabled,
    labelRender = null,
    classNameExtend,
    classNameOverride,
    labelClassNameExtend,
    labelClassNameOverride,
}) {
    return (
        <React.Fragment>
            <div className={mergeOrOverrideCss(
                    classNameOverride,
                    classNameExtend
                    )}
                >
                <label className="u-type--sml">{t(label)}</label>
                <input
                    id={id}
                    checked={value}
                    onChange={onChange}
                    disabled={disabled}
                    tabIndex={0}
                    type="checkbox"
                    className="c-toggle"
                />
                {labelRender ? (
                    labelRender({ id, t })
                ) : (
                    <label
                    htmlFor={id}
                    className={mergeOrOverrideCss(
                        "toggle-button toggle-button--regular",
                        labelClassNameOverride,
                        labelClassNameExtend
                        )}
                        >
                    </label>
                )}
            </div>
        </React.Fragment>
    );
}

MrbToggle.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    t: PropTypes.func,
    labelRender: PropTypes.func,
    classNameExtend: PropTypes.string,
    classNameOverride: PropTypes.string,
    labelClassNameExtend: PropTypes.string,
    labelClassNameOverride: PropTypes.string,
};

MrbToggle.defaultProps = {
    disabled: false,
};

export default observer(MrbToggle);
