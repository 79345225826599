import React from "react";
import { PropTypes } from "prop-types";
import { MrbModal } from "mrb/components/modal";
import { MrbButton } from "mrb/components/button";
import { applicationDefaults } from "common/constants";
import { dateFormatter } from "mrb/common/formatting";
import { defaultTemplate } from "common/hoc";

function TrialActivatedModalModalTemplate({ modalParams, t }) {
    const { params, data } = modalParams;
    const trialEndDate = dateFormatter.format(data.trialEndDate, {
        format: applicationDefaults.formats.longDate,
    });
    const buttonLabel = t("APPLICATION.PREMIUM.TRIAL.ACTIVATE_SUCCESS.CONFIRM_BUTTON", {
        subscriptionPlan: data.subscriptionPlan,
    });
    return (
        <MrbModal modalParams={modalParams} shouldCloseOnOverlayClick={false} displayCloseIcon={false}>
            <div>
                <div className="modal__icon">
                    <i className="u-icon u-icon--radio-lrg"></i>
                </div>
                <div className="modal__header">
                    <h2>{t("APPLICATION.PREMIUM.TRIAL.ACTIVATE_SUCCESS.TITLE")}</h2>
                </div>
                <div className="modal__body">
                    <p>
                        {t("APPLICATION.PREMIUM.TRIAL.ACTIVATE_SUCCESS.DESCRIPTION", {
                            endDate: trialEndDate,
                            subscriptionPlan: data.subscriptionPlan,
                        })}
                    </p>
                </div>
                <div className="modal__footer">
                    <MrbButton label={buttonLabel} onClick={params.onClickExplorePremium} />
                </div>
            </div>
        </MrbModal>
    );
}

TrialActivatedModalModalTemplate.propTypes = {
    modalParams: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(TrialActivatedModalModalTemplate);
