import { split, join, filter, findIndex } from "lodash";

class MrbValidationRules {
    _rules = [];
    _separator = "|";

    get rules() {
        return [...this._rules];
    }

    get isRequired() {
        return findIndex(this._rules, (rule) => rule === "required") !== -1;
    }

    constructor(rulesExpression) {
        if (rulesExpression) {
            this._rules = split(rulesExpression, this._separator);
        }
    }

    addIsRequiredRule() {
        if (!this.isRequired) {
            this.rules.push("required");
        }
        this.addRule("required");
    }

    removeIsRequiredRule() {
        this.removeRule("required");
    }

    addRule(ruleName) {
        if (!this.ruleExists(ruleName)) {
            this._rules.push(ruleName);
        }
    }

    removeRule(ruleName) {
        if (this.ruleExists(ruleName)) {
            this._rules = filter(this._rules, (rule) => rule !== ruleName);
        }
    }

    ruleExists(ruleName) {
        return findIndex(this._rules, (rule) => rule === ruleName) !== -1;
    }

    createExpression() {
        if (this._rules && this._rules.length > 0) {
            return join(this._rules, this._separator);
        }

        return "";
    }
}

export default MrbValidationRules;
