class UpdateTorahAnytimeCollectionStatusOptions {
    collectionId = null;
    collectionName = null;
    torahAnytimePodcastId = null;
    torahAnytimePodcastTitle = null;
    isPremium = false;
    isUnavailableInApp = false;

    constructor(
        collectionId,
        collectionName,
        torahAnytimePodcastId,
        torahAnytimePodcastTitle,
        isPremium,
        isUnavailableInApp
    ) {
        this.collectionId = collectionId;
        this.collectionName = collectionName;
        this.torahAnytimePodcastId = torahAnytimePodcastId;
        this.torahAnytimePodcastTitle = torahAnytimePodcastTitle;
        this.isPremium = isPremium;
        this.isUnavailableInApp = isUnavailableInApp;
    }
}

export default UpdateTorahAnytimeCollectionStatusOptions;
