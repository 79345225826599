import React from "react";
import { PropTypes } from "prop-types";
import { MrbGridEmptyState } from "./components";
import { MrbPager } from "../pager";
import { MrbContentLoader, MrbRectangleContentLoader } from "../loader";
import { map } from "lodash";
import { observer } from "mobx-react";
import { mergeOrOverrideCss } from "../../common/utils";

function MrbGrid({
    store,
    cellRender,
    pagerRender,
    t,
    classNameOverride,
    classNameExtend,
    emptyStateRender = () => <MrbGridEmptyState />,
    loaderRender = () => (
        <MrbRectangleContentLoader
            rows={10}
            columns={10}
            xRadius={10}
            yRadius={10}
        />
    ),
}) {
    const {
        dataInitialized,
        data,
        config: { actions, onCellClick },
        formatValue,
        loaderStore,
        generateCellKey,
    } = store;

    return (
        <React.Fragment>
            <MrbContentLoader
                loaderStore={loaderStore}
                loaderRender={loaderRender}
            >
                {() => (
                    <React.Fragment>
                        {dataInitialized && (
                            <React.Fragment>
                                {data && data.length > 0 ? (
                                    <div className="u-animation--fadeIn">
                                        <div
                                            className={mergeOrOverrideCss(
                                                "u-display--flex u-display--flex--wrap",
                                                classNameOverride,
                                                classNameExtend
                                            )}
                                        >
                                            {map(data, (item, index) => (
                                                <React.Fragment
                                                    key={generateCellKey(
                                                        item,
                                                        index
                                                    )}
                                                >
                                                    {cellRender({
                                                        item,
                                                        actions,
                                                        onCellClick,
                                                        valueFormatter:
                                                            formatValue,
                                                        t,
                                                    })}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        {emptyStateRender()}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </MrbContentLoader>
            <PagerWrapper store={store} pagerRender={pagerRender} t={t} />
        </React.Fragment>
    );
}

MrbGrid.propTypes = {
    store: PropTypes.object.isRequired,
    pagerRender: PropTypes.any,
    cellRender: PropTypes.func.isRequired,
    emptyStateRender: PropTypes.any,
    loaderRender: PropTypes.any,
    t: PropTypes.func,
};

const PagerWrapper = observer(function PagerWrapper({ store, t, pagerRender }) {
    const renderer = pagerRender
        ? pagerRender
        : (props) => <MrbPager {...props} />;
    return (
        store.dataInitialized &&
        store.config.paging &&
        renderer({
            tableStore: store,
            hidePagerIfUnderLimit: store.config.hidePagerIfUnderLimit,
            t,
        })
    );
});

export default observer(MrbGrid);
