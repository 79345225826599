import { BaseService } from "mrb/core";
import { NakiNigunAlbumFileStreamApiRouteService } from "common/services";

class NakiNigunAlbumFileStreamService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new NakiNigunAlbumFileStreamApiRouteService());
    }

    get(id, params, abortSignal = null) {
        const url = this.routeService.get(id, params);
        return this.apiClient.get(url, null, abortSignal);
    }

    getAlbumCoverImageByAlbum(albumId, params, abortSignal = null) {
        const url = this.routeService.getAlbumCoverImageByAlbum(albumId, params);
        return this.apiClient.get(url, null, abortSignal);
    }

    getAlbumTrackAudioFile(id, abortSignal = null) {
        const url = this.routeService.getAlbumTrackAudioFile(id);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default NakiNigunAlbumFileStreamService;
