import React from "react";
import ContentLoader from "react-content-loader";

const AnalyticsStatsLoaderTemplate = (props) => {
    const { newDevices, isPremium, ...rest } = props;
    const screenWidth = window.innerWidth;

    let loaderWidth;
    let circleLoaderHeight;

    const items = [];
    let y = 0;

    if (screenWidth > 1440) {
        circleLoaderHeight = 220;
        loaderWidth = Math.floor((screenWidth - 760) / 2);
    } else if (screenWidth > 1199) {
        loaderWidth = Math.floor((screenWidth - 760) / 2);
    } else if (screenWidth > 991) {
        loaderWidth = Math.floor((screenWidth - 200) / 2);
    } else if (screenWidth > 767) {
        loaderWidth = Math.floor(screenWidth / 2);
        circleLoaderHeight = 120;
    } else {
        loaderWidth = screenWidth;
        circleLoaderHeight = 200;
    }

    for (let i = 0; i < 3; i++) {
        items.push(
            <React.Fragment key={i}>
                <rect x="0" y={y} rx="4" ry="4" width="120" height="10" />
                <rect x="0" y={y + 26} rx="4" ry="4" width={i === 1 || i === 2 ? "70" : "20"} height="8" />

                <rect x="96%" y={y} rx="4" ry="4" width="4%" height="24" />
                <rect x={"85%"} y={y + 30} rx="4" ry="4" width="15%" height="8" />
            </React.Fragment>
        );

        y += 86;
    }

    return (
        <div className="card--primary">
            <ContentLoader
                speed={2}
                width={loaderWidth}
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${newDevices ? circleLoaderHeight : isPremium ? 290 : y - 28}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...rest}
            >
                {newDevices ? <NewDevices /> : isPremium ? <PremiumAnalyticsLoader /> : items}
            </ContentLoader>
        </div>
    );
};

export default AnalyticsStatsLoaderTemplate;

const NewDevices = () => {
    const screenWidth = window.innerWidth;
    let circleWidth = null;
    let y1 = 10;
    let y2 = 55;
    let y3 = 85;
    let circleY = 60;

    if (screenWidth > 1440) {
        circleWidth = 80;
        y1 = 65;
        y2 = 110;
        y3 = 140;
        circleY = 100;
    } else if (screenWidth > 1199) {
        circleWidth = 60;
    } else {
        circleWidth = 60;
    }

    return (
        <React.Fragment>
            <rect x="0" y={y1} rx="4" ry="4" width="100" height="35" />
            <rect x="0" y={y2} rx="4" ry="4" width="190" height="8" />
            <rect x="0" y={y3} rx="4" ry="4" width="140" height="8" />

            <circle cx="82%" cy={circleY} r={circleWidth} />
        </React.Fragment>
    );
};

const PremiumAnalyticsLoader = () => {
    const items = [];
    let y = 6;

    for (let i = 0; i < 3; i++) {
        if (i === 1) {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="4" ry="4" width="120" height="10" />
                    <rect x="0" y={y + 28} rx="4" ry="4" width={200} height="56" />
                </React.Fragment>
            );
        } else {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="4" ry="4" width="120" height="10" />
                    <rect x="0" y={y + 28} rx="4" ry="4" width={80} height="20" />
                </React.Fragment>
            );
        }

        y += i === 1 ? 126 : 96;
    }

    return (
        <React.Fragment>
            {items}
        </React.Fragment>
    )
}