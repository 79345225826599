import { each, isFunction } from "lodash";

class ModuleProvider {
    name = null;
    configurations = [];

    constructor(name) {
        if (!name) {
            throw new Error("name parameter must be defined.");
        }
        this.name = name;
    }

    register = (config) => {
        this.configurations.push(config);
    };

    getRoutes = () => {
        const items = [];
        each(this.configurations, (config) => {
            if (config.routes) {
                const routes = isFunction(config.routes)
                    ? (ctx) => config.routes(ctx)
                    : config.routes;
                items.push(...routes);
            }
        });
        return items;
    };

    getMenus = () => {
        const items = [];
        each(this.configurations, (config) => {
            if (config.menu) {
                items.push(
                    isFunction(config.menu)
                        ? (ctx) => config.menu(ctx)
                        : config.menu
                );
            }
        });
        return items;
    };

    getStores = () => {
        const items = [];
        each(this.configurations, (config) => {
            if (config.moduleStore) {
                items.push(
                    isFunction(config.moduleStore)
                        ? (ctx) => config.moduleStore(ctx)
                        : config.moduleStore
                );
            }
        });
        return items;
    };
}

export default ModuleProvider;
