import { each, find, orderBy, isArray, isString } from "lodash";

function buildAuthorizedMenu(menuItems, routes, permissionFunc) {
    let menuTree = [];
    each(menuItems, function (moduleItems) {
        each(moduleItems, (moduleItem) => {
            if (hasPermission(moduleItem, permissionFunc)) {
                let root = find(menuTree, (t) => t.title === moduleItem.title);
                if (!root) {
                    root = {
                        title: moduleItem.title,
                        subMenu: [],
                    };
                    menuTree.push(root);
                }
                if (!root.icon && moduleItem.icon) {
                    root.icon = moduleItem.icon;
                }
                if (!root.route && moduleItem.route) {
                    root.route = moduleItem.route;
                }
                if (!root.order && moduleItem.order) {
                    root.order = moduleItem.order;
                }
                each(moduleItem.subMenu, function (secondaryMenuItem, idx) {
                    // eslint-disable-line
                    var route = getRouteByMenu(secondaryMenuItem, routes);
                    if (hasPermission(route, permissionFunc)) {
                        let subRoot = find(
                            root.subMenu,
                            (t) => t.title === secondaryMenuItem.title
                        );
                        if (secondaryMenuItem.subMenu) {
                            evaluateSubMenuItems(
                                secondaryMenuItem,
                                routes,
                                permissionFunc
                            );
                        }
                        if (!subRoot) {
                            root.subMenu = [...root.subMenu, secondaryMenuItem];
                        } else {
                            each(
                                secondaryMenuItem.subMenu,
                                function (terniaryMenuItem, idx) {
                                    // eslint-disable-line
                                    var route = getRouteByMenu(
                                        terniaryMenuItem,
                                        routes
                                    );
                                    if (hasPermission(route, permissionFunc)) {
                                        subRoot.subMenu = [
                                            ...subRoot.subMenu,
                                            terniaryMenuItem,
                                        ];
                                    }
                                }
                            );
                            subRoot.subMenu = orderBy(
                                subRoot.subMenu,
                                "order",
                                "asc"
                            );
                        }
                    }
                });
                root.subMenu = orderBy(root.subMenu, "order", "asc");
            }
        });
    });

    return menuTree;
}
function evaluateSubMenuItems(menuItem, routes, permissionFunc) {
    var keys = [];
    each(menuItem.subMenu, function (subMenuItem, key) {
        var childRoute = getRouteByMenu(subMenuItem, routes);
        if (!hasPermission(childRoute, permissionFunc)) {
            keys.push(key);
        } else {
            if (subMenuItem.subMenu) {
                evaluateSubMenuItems(subMenuItem);
            }
        }
    });
    each(keys.reverse(), function (key) {
        if (isArray(menuItem.subMenu)) {
            menuItem.subMenu.splice(key, 1);
        } else {
            delete menuItem.subMenu[key];
        }
    });

    menuItem.subMenu = orderBy(menuItem.subMenu, "order", "asc");
}

function getRouteByMenu(menuItem, routes) {
    var route = "";
    if (menuItem.route === "function") {
        const menuRoute = menuItem.route();
        if (menuRoute === null) return null;

        route = menuRoute.routeName;
    } else {
        route = menuItem.route;
    }

    return find(routes, (r) => r.name === route);
}

function hasPermission(item, permissionFunc) {
    let authorized = true;
    if (item && item.authorization) {
        if (isString(item.authorization) || isArray(item.authorization)) {
            authorized = permissionFunc(item.authorization);
        } else {
            authorized = item.authorization();
        }
    }
    return authorized;
}

export default buildAuthorizedMenu;
