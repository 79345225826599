import { action, reaction } from "mobx";
import { MrbBaseTableViewStore } from "./";
import { MrbApplicationFormatter } from "../../../common";
import { map, merge } from "lodash";

const DefaultConfig = {
    gridIdentifier: null,
    actionsColumn: {
        title: "Actions",
        columnId: "gridActionsCol",
        pinned: false,
    },
    onRowClick: null,
    rows: {
        rowClass: null,
        getRowClass: null,
    },
    columns: [],
    export: {
        suppressCsvExport: false,
        csvExportParams: {
            fileName: null,
            allColumns: false,
            processCellCallback: null,
        },
    },
    selection: {
        rowSelection: "single",
        checkboxSelection: false,
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        suppressRowClickSelection: true,
        groupSelectsChildren: true,
        groupSelectsFiltered: true,
        //range selection
        suppressMultiRangeSelection: true,
        onRowSelected: () => {},
        onSelectionChanged: () => {},
        onRowDataChanged: () => {},
    },
    actions: {},
    paging: true,
    hidePagerIfUnderLimit: true,
    defaultColumnDefinition: {
        editable: false,
        resizable: true,
        suppressMovable: false,
        filter: false,
        filterParams: {
            buttons: ["apply", "clear", "reset"],
        },
        floatingFilter: false,
    },
    // eslint-disable-next-line
    onDataChange: () => {},
    mapper: (i) => i,
    formatter: new MrbApplicationFormatter(),
    autoSizeColumns: true,
    multiSortKey: "ctrl",
};

class MrbTableViewStore extends MrbBaseTableViewStore {
    loaderDisposer = null;

    constructor(rootStore, queryUtility, config = {}, components = {}) {
        super(rootStore, queryUtility, merge({}, DefaultConfig, config), components);

        this.loaderDisposer = reaction(
            () => this.loaderStore.loading,
            (isLoading) => {
                if (isLoading) {
                    this.showLoadingOverlay();
                } else {
                    this.hideLoadingOverlay();
                }
            }
        );
    }

    setData(data) {
        let items = data.item ? data.item : data;
        items = map(items, this.config.mapper);
        this.originalData = items;
        this.data = items;

        if (data.item) {
            this.queryUtility.handleResponse(data);
        }
        this.gridApi.setRowData(this.data);

        this.dataInitialized = true;
        this.config.onDataChange();
    }

    showLoadingOverlay() {
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay();
        }
    }

    hideLoadingOverlay() {
        if (this.gridApi) {
            if (this.data && this.data.length > 0) {
                this.gridApi.hideOverlay();
            } else {
                this.gridApi.showNoRowsOverlay();
            }
        }
    }

    @action.bound
    onSelectionChanged({ api }) {
        if (this.config.selection.onSelectionChanged) {
            this.config.selection.onSelectionChanged(api.getSelectedNodes());
        }
    }
    @action.bound
    onRowSelected({ node }) {
        if (this.config.selection.onRowSelected) {
            this.config.selection.onRowSelected(node);
        }
    }

    @action.bound
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if (this.initialized) {
            this.resetGridItems();
        } else {
            this.initialized = true;
        }
        this.gridApi.hideOverlay();

        if (this.config.autoSizeColumns) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    @action.bound
    isRowSelectable(params) {
        if (this.config.selection.isRowSelectable) {
            return this.config.selection.isRowSelectable(params.data);
        }
    }

    suspend() {
        super.suspend();
        this.rootStore.mainLoaderStore.suspend();
    }

    resume() {
        super.resume();
        this.rootStore.mainLoaderStore.resume();
    }

    resetGridItems() {
        super.resetGridItems();
        this.gridApi.setRowData(this.data);
    }

    destroy() {
        super.destroy();
        if (this.loaderDisposer) {
            this.loaderDisposer();
        }
    }
}

export default MrbTableViewStore;
