import { MrbBaseViewStore } from "mrb/core";
import { action, makeObservable, observable } from "mobx";

class ContentStatsViewStore extends MrbBaseViewStore {
    @observable.ref selectedContentType = 0;
    contentTypeOptions = [
        {
            label: "Podcasts",
            value: 0,
        },
        {
            label: "Stations",
            value: 1,
        },
        {
            label: "Naki Nigun",
            value: 2,
        },
    ];

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        this.routeStore = routeStore;
        makeObservable(this);
    }

    @action.bound
    setSelectedContentType = (content) => {
        this.selectedContentType = content;
    };
}

export default ContentStatsViewStore;
