import { computed, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { AdminProducerAccountBalanceEditForm } from "administration/producer/forms";
import { isFunction, isString } from "lodash";

class AdminProducerAccountBalanceEditViewStore extends MrbBaseViewStore {
    @computed get currentAccountBalance() {
        return this.producerUserAccount.accountBalance;
    }

    @computed get newAccountBalance() {
        let accountBalance = this.form.$("amount").value || 0;
        if (isString(accountBalance)) {
            accountBalance = parseFloat(accountBalance);
        }

        return this.currentAccountBalance + accountBalance;
    }

    producerUserAccount = null;
    onSubmit = null;

    constructor(rootStore, { producerUserAccount, onSubmit }) {
        super(rootStore);
        makeObservable(this);
        this.producerUserAccount = producerUserAccount;
        this.onSubmit = onSubmit;

        this.form = new AdminProducerAccountBalanceEditForm({
            onSuccess: (form) => {
                const item = form.values();
                if (isString(item.amount)) {
                    item.amount = parseFloat(item.amount);
                }

                if (this.currentAccountBalance + item.amount < 0) {
                    this.form.invalidate("Account balance cannot be less than 0.");
                    return;
                }
                return this.createResource(item);
            },
        });

        this.createResource = this.createResource.bind(this);
    }

    createResource(resource) {
        if (isFunction(this.onSubmit)) {
            this.onSubmit(resource);
        }
    }
}

export default AdminProducerAccountBalanceEditViewStore;
