import React from "react";
import PropTypes from "prop-types";
import { MrbButton } from "../button";
import { observer } from "mobx-react";

function MrbConfirmDialogPanel({
    message,
    onConfirm,
    onCancel,
    yesLabel = "Yes",
    noLabel = "No",
    description,
    t = (i) => i,
    loading,
}) {
    return (
        <div className="modal__wrapper">
            <div className="modal__header">
                <h2>{t(message)}</h2>
            </div>
            <div className="modal__body">
                {description && <p className="u-type--sml">{description}</p>}
            </div>
            <div className="modal__footer">
                <MrbButton
                    className="c-btn c-btn--base c-btn--ghost u-mar--right--tny"
                    onClick={onCancel}
                    disabled={loading}
                    label={t(noLabel)}
                    t={t}
                />
                <MrbButton
                    className="c-btn c-btn--base c-btn--primary"
                    onClick={onConfirm}
                    disabled={loading}
                    aria-busy={loading}
                    label={t(yesLabel)}
                    t={t}
                />
            </div>
        </div>
    );
}

MrbConfirmDialogPanel.propTypes = {
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    yesLabel: PropTypes.string,
    noLabel: PropTypes.string,
    t: PropTypes.func,
    loading: PropTypes.bool,
};

export default observer(MrbConfirmDialogPanel);
