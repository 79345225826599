import React from "react";
import { UserDeviceActivitySummaryLoaderTemplate } from "themes/administration/analytics/components";

class UserDeviceActivitySummaryLoader extends React.Component {
    render() {
        return <UserDeviceActivitySummaryLoaderTemplate {...this.props} />;
    }
}

export default UserDeviceActivitySummaryLoader;
