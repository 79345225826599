import React from "react";
import PropTypes from "prop-types";
import { MrbRouterLink } from "../../core/infrastructure/router";
import { observer } from "mobx-react";

function MrbRouteLinkMenuItem({ item, className, tabIndex = 0, t = (i) => i }) {
    const title = t(item.title);
    return (
        <MrbRouterLink
            aria-label={title}
            className={className}
            isActive={() => item.isActive}
            onClick={item.onClick}
            activeClassName="is-active"
            routeName={item.route}
            tabIndex={tabIndex}
        >
            <i className={item.icon ? `c-nav__icon c-nav__icon--${item.icon}` : null}></i>
            <span className="u-type--break">{title}</span>
            {!item.shouldHideChildren && item.hasChildren ? <i className="nav--secondary__link__icon" /> : null}
        </MrbRouterLink>
    );
}

MrbRouteLinkMenuItem.propTypes = {
    item: PropTypes.object.isRequired,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    t: PropTypes.func.isRequired,
};

export default observer(MrbRouteLinkMenuItem);
