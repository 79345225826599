import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldLayout } from "../layout/content/form";
import { MrbSelect } from "./";
import { isNil } from "lodash";
import { observer } from "mobx-react";

function MrbFieldSelect({
    store,
    showLabel = true,
    disabled,
    labelRender,
    validationRender,
    fieldClassNameExtend,
    fieldClassNameOverride,
    t = (i) => i,
    ...props
}) {
    const { formField } = store;

    if (isNil(formField)) {
        throw new Error("Form field must be provided.");
    }

    return (
        <MrbFieldLayout
            field={formField}
            t={t}
            labelRender={labelRender}
            validationRender={validationRender}
            showLabel={showLabel}
            classNameExtend={fieldClassNameExtend}
            classNameOverride={fieldClassNameOverride}
        >
            <MrbSelect
                store={store}
                placeholder={t(formField.placeholder)}
                disabled={isNil(disabled) ? formField.disabled : disabled}
                autoFocus={formField.autoFocus}
                {...props}
            />
        </MrbFieldLayout>
    );
}

MrbFieldSelect.propTypes = {
    store: PropTypes.object.isRequired,
    classNameOverride: PropTypes.string,
    classNameExtend: PropTypes.string,
    labelRender: PropTypes.any,
    validationRender: PropTypes.any,
    fieldClassNameExtend: PropTypes.string,
    fieldClassNameOverride: PropTypes.string,
    t: PropTypes.func,
};

export default observer(MrbFieldSelect);
