import { action, computed, observable, makeObservable, reaction, runInAction } from "mobx";
import { MrbLoaderStore } from "../../loader";
import { DropdownItemsResult } from "../models";
import { extend, isNil, isString, map, isObject, isFunction, find, filter, isArray, head } from "lodash";

class MrbBaseSelectStore {
    @observable.ref selectedOption = null;
    @observable.ref _value = null;
    @observable.ref originalItems = null;
    @observable.ref filteredItems = null;
    @observable isInitialized = false;
    @observable searchTerm = "";
    loaderStore = null;

    @computed get value() {
        return this._value;
    }

    @computed get selectedItem() {
        if (this.selectedOption) {
            if (this.config.isMulti && isArray(this.selectedOption)) {
                return map(this.selectedOption, (option) => option.item);
            } else if (!this.config.isMulti) {
                return this.selectedOption.item;
            }
        }

        return null;
    }

    @computed get options() {
        if (this.filteredItems) {
            return this.mapDropdownItems(this.filteredItems.items);
        } else if (this.originalItems) {
            return this.mapDropdownItems(this.originalItems.items);
        }
        return [];
    }

    config = {
        placeholder: "Search",
        isSearchable: false,
        isClearable: true,
        isMulti: false,
        textKey: "name",
        valueKey: "id",
        isDisabledKey: "isDisabled",
        filterDebounce: 500,
        initFetch: true,
        pageable: true,
        pageSize: 15,
        preselectFirstItemAsDefault: false,
    };

    actions = {
        find: null,
        mapItem: null,
        onOpen: () => {},
        onClose: () => {},
        onChange: (selected) => selected,
        fetchOriginalItem: null,
    };

    constructor({ actions, ...config }, initialItems = null) {
        makeObservable(this);

        this.config = extend({}, this.config, config);
        this.actions = extend({}, this.actions, actions);

        this.loaderStore = new MrbLoaderStore();

        if (initialItems) {
            this.originalItems = new DropdownItemsResult(initialItems, 1, initialItems.length, initialItems.length);
        }

        reaction(
            () => this.searchTerm,
            (searchTerm) => {
                runInAction(() => {
                    if (!searchTerm) {
                        this.filteredItems = null;
                        return;
                    }
                    this.filter(searchTerm);
                });
            },
            {
                delay: this.config.filterDebounce,
            }
        );

        this.initialize();
    }

    async initialize() {
        const tasks = [];
        if (this.config.initFetch) {
            tasks.push(this.initializeOriginalItems());
        }
        tasks.push(this.fetchOriginalItem());
        await Promise.all(tasks);
    }

    async initializeOriginalItems() {
        if (!this.isInitialized) {
            await this.filter();
            runInAction(() => {
                this.onPostInitializeOriginalItems();
                this.setIsInitialized(true);
            });
        }
    }

    @action.bound
    onPostInitializeOriginalItems() {
        if (this.value) {
            const selectedOption = find(this.options, (option) => option.value === this.value);
            if (selectedOption) {
                this.setSelectedOption(selectedOption);
            }
        } else if (this.config.preselectFirstItemAsDefault) {
            this.setSelectedOption(head(this.options));
        }
    }

    @action.bound
    async fetchOriginalItem() {
        if (isFunction(this.actions.fetchOriginalItem)) {
            this.loaderStore.suspend();
            const item = await this.actions.fetchOriginalItem();
            runInAction(() => {
                if (item) {
                    const option = this.mapDropdownItem(item);
                    this.setValue(option.value);
                }
                this.loaderStore.resume();
            });
        }
    }

    @action.bound
    setPageNumber(pageNumber) {
        this.pageNumber = pageNumber;
    }

    mapDropdownItems(items) {
        if (items && items.length > 0) {
            return map(items, (item) => this.mapDropdownItem(item));
        }

        return [];
    }

    mapDropdownItem(item) {
        if (isFunction(this.actions.mapItem)) {
            return this.actions.mapItem(item);
        }
        if (isString(item)) {
            return {
                label: item,
                value: item,
                isDisabled: false,
                item: item,
            };
        } else if (isObject(item)) {
            return {
                label: item[this.config.textKey],
                value: item[this.config.valueKey],
                isDisabled: item[this.config.isDisabledKey],
                item: item,
            };
        }

        return item;
    }

    @action.bound
    setValue(value) {
        this._value = value;
    }

    addSelectedItem(item) {
        if (isArray(item) && this.config.isMulti) {
            const options = this.mapDropdownItems(item);
            if (this.selectedOption && isArray(this.selectedOption)) {
                this.setSelectedOption([...this.selectedOption, ...options]);
            } else {
                this.setSelectedOption(options);
            }
        } else {
            const option = this.mapDropdownItem(item);
            if (this.config.isMulti) {
                if (this.selectedOption && isArray(this.selectedOption)) {
                    this.setSelectedOption([...this.selectedOption, option]);
                } else {
                    this.setSelectedOption([option]);
                }
            } else {
                this.setSelectedOption(option);
            }
        }
    }

    setSelectedItem(item) {
        if (isArray(item)) {
            this.setSelectedOption(this.mapDropdownItems(item));
        } else {
            this.setSelectedOption(this.mapDropdownItem(item));
        }
    }

    @action.bound
    setSelectedOption(option) {
        this.selectedOption = option;
        let value = null;
        if (this.config.isMulti) {
            value = map(this.selectedOption, (option) => option.value);
        } else {
            value = !isNil(this.selectedOption) ? this.selectedOption.value : null;
        }
        this.setValue(value);
    }

    @action.bound
    setSearchTerm(searchTerm) {
        this.searchTerm = searchTerm;
    }

    @action.bound
    onOpen(event) {
        this.actions.onOpen(event);
        if (!this.initFetch) {
            this.initializeOriginalItems();
        }
    }

    @action.bound
    onClose(event) {
        this.actions.onClose(event);
    }

    @action.bound
    async filter(searchTerm) {
        if (isFunction(this.actions.find)) {
            await this.filterDataSource(searchTerm);
        } else {
            await this.filterInMemory(searchTerm);
        }
    }

    @action.bound
    async loadNextPage() {
        if (this.loaderStore.loading) {
            return;
        }
        const dropdownItemsResult = this.filteredItems || this.originalItems;
        let searchPhrase = dropdownItemsResult.searchPhrase;
        if (dropdownItemsResult.pageNumber < dropdownItemsResult.totalPages) {
            if (searchPhrase === "") {
                searchPhrase = undefined;
            }
            this.loaderStore.suspend();

            const data = await this.actions.find(
                searchPhrase,
                dropdownItemsResult.pageNumber + 1,
                this.config.pageSize
            );
            runInAction(() => {
                dropdownItemsResult.appendNextPage(data.item, data.page);
                this.loaderStore.resume();
            });
        }
    }

    @action.bound
    async filterDataSource(searchTerm) {
        if (searchTerm === "") {
            searchTerm = undefined;
        }
        this.loaderStore.suspend();

        const data = await this.actions.find(searchTerm, 1, this.config.pageSize);
        runInAction(() => {
            if (searchTerm) {
                if (data.item && data.page && data.recordsPerPage && data.totalRecords) {
                    this.filteredItems = new DropdownItemsResult(
                        data.item,
                        data.page,
                        data.recordsPerPage,
                        data.totalRecords,
                        searchTerm
                    );
                } else {
                    this.filteredItems = new DropdownItemsResult(data, 1, data.length, data.length, searchTerm);
                }
            } else {
                if (data.item && data.page && data.recordsPerPage && data.totalRecords) {
                    this.originalItems = new DropdownItemsResult(
                        data.item,
                        data.page,
                        data.recordsPerPage,
                        data.totalRecords
                    );
                } else {
                    this.originalItems = new DropdownItemsResult(data, 1, data.length, data.length, searchTerm);
                }
            }
            this.loaderStore.resume();
        });
    }

    filterInMemory(searchTerm) {
        let items = [];
        if (!searchTerm) {
            items = this.originalItems.items;
        } else {
            items = filter(this.originalItems.items, (item) => {
                return item[this.config.textKey].toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
            });
        }
        runInAction(() => {
            this.filteredItems = new DropdownItemsResult(items, 1, items.length, items.length, searchTerm);
        });
    }

    @action.bound
    onChange(selectedOption) {
        if (selectedOption?.isDisabled) {
            return;
        }

        this.setSelectedOption(selectedOption);
        this.actions.onChange(selectedOption);
    }

    @action.bound
    setIsInitialized(isInitialized) {
        this.isInitialized = isInitialized;
    }

    clear() {
        this.setSelectedOption(null);
    }

    clearOptions() {
        this.originalItems = null;
    }
}

export default MrbBaseSelectStore;
