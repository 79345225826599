import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";

function PaymentValidationTemplate({ store }) {
    const { error } = store;
    if (!error) {
        return null;
    }

    return (
        <p className="c-validation--input">
            <span className="c-validation--input__label">{error.message}</span>
        </p>
    );
}

PaymentValidationTemplate.propTypes = {
    store: PropTypes.object.isRequired,
};

export default observer(PaymentValidationTemplate);
