import { isNil } from "lodash";
import { nakiRadioProductCategoryAbbreviations } from "common/constants";

export default function isSubscriptionBundle(subscriptionCategory) {
    if (isNil(subscriptionCategory)) {
        throw new Error("subscription category can't be null.");
    }

    return subscriptionCategory.abrv === nakiRadioProductCategoryAbbreviations.subscriptionBundle;
}
