import { BaseService } from "mrb/core";
import { NakiNigunArtistFileStreamApiRouteService } from "common/services";

class NakiNigunArtistFileStreamService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new NakiNigunArtistFileStreamApiRouteService());
    }

    get(id, params, abortSignal = null) {
        const url = this.routeService.get(id, params);
        return this.apiClient.get(url, null, abortSignal);
    }

    getArtistCoverImageByArtist(artistId, params, abortSignal = null) {
        const url = this.routeService.getArtistCoverImageByArtist(artistId, params);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default NakiNigunArtistFileStreamService;
