import { baasicSystemRoles } from "mrb/baasic";

const nakiRadioRoles = {
    accountOwnerRoleName: baasicSystemRoles.accountOwnerRoleName,
    administratorRoleName: baasicSystemRoles.administratorRoleName,
    producerRoleName: "Producers",
    userRoleName: baasicSystemRoles.userRoleName,
    guestRoleName: "Guests",
    anonymousRoleName: baasicSystemRoles.anonymousRoleName,
};

export default nakiRadioRoles;
