import { MrbBaseRouteStore } from "mrb/core";
import { PaymentService, ProductService, UserContentService, NakiNigunUserContentService } from "common/services";

class PremiumSubscriptionsRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PaymentService);
        this.productService = rootStore.createApplicationService(ProductService);
        this.userContentService = this.rootStore.createApplicationService(UserContentService);
        this.nakiNigunUserContentService = this.rootStore.createApplicationService(NakiNigunUserContentService);
    }

    async getAllSubscriptionProducts() {
        const response = await this.productService.getAllSubscriptions();
        return response.data;
    }

    cancelSubscription(subscriptionId) {
        return this.rootStore.premiumSubscriptionStore.cancelSubscription(subscriptionId);
    }

    renewSubscription(subscriptionId) {
        return this.rootStore.premiumSubscriptionStore.renewSubscription(subscriptionId);
    }

    async setupPaymentMethodRequest() {
        const response = await this.service.setupPaymentMethodRequest();
        return response.data;
    }

    async findUserContent(params) {
        const response = await this.userContentService.findPremiumContentInfo(params);
        return response.data;
    }

    async getSubPodcasts(params) {
        const response = await this.userContentService.getSubPodcasts(params);
        return response.data;
    }

    async getPodcastVolumes(params) {
        const response = await this.userContentService.getPodcastVolumes(params);
        return response.data;
    }

    async getAlbumsByArtist(params) {
        const response = await this.nakiNigunUserContentService.getAlbumsByArtist(params);
        return response.data;
    }
}

export default PremiumSubscriptionsRouteStore;
