import React from "react";
import ContentLoader from "react-content-loader";

const HomePreviewSubPodcastListLoaderTemplate = (props) => {
    return (
        <div className="c-container--sidebar">
            <HomePreviewCollapsePanelLoaderTemplate {...props} />
            <HomePreviewCollapsePanelLoaderTemplate {...props} />
            <HomePreviewCollapsePanelLoaderTemplate {...props} />
            <HomePreviewCollapsePanelLoaderTemplate {...props} />
            <HomePreviewCollapsePanelLoaderTemplate {...props} />
            <HomePreviewCollapsePanelLoaderTemplate {...props} />
            <HomePreviewCollapsePanelLoaderTemplate {...props} />
            <HomePreviewCollapsePanelLoaderTemplate {...props} />
            <HomePreviewCollapsePanelLoaderTemplate {...props} />
        </div>
    );
};

const HomePreviewCollapsePanelLoaderTemplate = (props) => {
    const screenwidth = window.innerWidth;
    let loaderWidth;

    if (screenwidth > 1200) {
        loaderWidth = screenwidth - 690;
    } else if (screenwidth > 988) {
        loaderWidth = screenwidth - 200;
    } else {
        loaderWidth = screenwidth - 64;
    }

    return (
        <React.Fragment>
            <div className="c-card--primary c-card--med u-height--75--px">
                <ContentLoader
                    speed={2}
                    width="100%"
                    height="100%"
                    viewBox={`0 0 ${loaderWidth} 40`}
                    backgroundColor="#262729"
                    foregroundColor="#2b2b2b"
                    {...props}
                >
                    <rect x="16" y="12" rx="6" ry="6" width="16" height="16" />
                    <rect x="46" y="0" rx="6" ry="6" width="40" height="40" />
                    <rect x="116" y="12" rx="6" ry="6" width="200" height="16" />
                    <rect x="326" y="12" rx="6" ry="6" width="16" height="16" />
                    <rect x="96%" y="12" rx="6" ry="6" width="32" height="16" />
                </ContentLoader>
            </div>
            {screenwidth > 1198 && <div></div>}
        </React.Fragment>
    );
};

export default HomePreviewSubPodcastListLoaderTemplate;
