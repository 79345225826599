import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

function MrbBreadcrumbsOptions({ options, t = (i) => i }) {
    if (!options) {
        return null;
    }

    if (options.loading) {
        return (
            <span className="display--ib spc--left--tny align--v--text-top">
                <i className="u-icon u-icon--med u-icon--truck" />
            </span>
        );
    }

    if (options.title && options.title !== "") {
        return <span className="type--wgt--regular">: {t(options.title)}</span>;
    }

    return null;
}

MrbBreadcrumbsOptions.propTypes = {
    options: PropTypes.object,
    t: PropTypes.func,
};

export default observer(MrbBreadcrumbsOptions);
