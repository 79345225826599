import { observable, makeObservable } from "mobx";
import { MrbFilterParameters } from "mrb/core/filter";

class PremiumContentListFilter extends MrbFilterParameters {
    @observable contentType;

    constructor() {
        super();
        makeObservable(this);
        this.reset();
    }

    reset() {
        super.reset();
        this.contentType = 1;
    }
}

export default PremiumContentListFilter;
