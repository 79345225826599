import React from "react";
import { UserDeviceActivitySummaryTemplate } from "themes/administration/analytics/components";

class UserDeviceActivitySummary extends React.Component {
    render() {
        return <UserDeviceActivitySummaryTemplate {...this.props} />;
    }
}

export default UserDeviceActivitySummary;
