import { action, computed, observable, makeObservable } from "mobx";
import { PaymentService, ProductService } from "common/services";
import { subscriptionStatuses } from "common/constants";
import { isSubscriptionBundle } from "common/utils";
import { isNil, find, findIndex, orderBy, some, filter } from "lodash";
import moment from "moment";

class PremiumSubscriptionStore {
    @observable.ref _premiumInfo = null;

    _activeSubscriptionStatuses = [
        subscriptionStatuses.active,
        subscriptionStatuses.pastDue,
        subscriptionStatuses.trial,
    ];
    _paymentService = null;
    _productService = null;

    get paymentService() {
        if (isNil(this._paymentService)) {
            this._paymentService = this.rootStore.createApplicationService(PaymentService);
        }
        return this._paymentService;
    }

    get productService() {
        if (isNil(this._productService)) {
            this._productService = this.rootStore.createApplicationService(ProductService);
        }
        return this._productService;
    }

    @computed get customerPaymentMethod() {
        if (this._premiumInfo) {
            return this._premiumInfo.customerPaymentMethod;
        }
        return null;
    }

    @computed get subscriptions() {
        if (isNil(this._premiumInfo)) {
            return [];
        }
        return this._premiumInfo.subscriptions;
    }

    @computed get subscription() {
        if (this._premiumInfo) {
            return find(
                orderBy(this._premiumInfo.subscriptions, (s) => s.startDate, "desc"),
                (s) =>
                    findIndex(
                        this._activeSubscriptionStatuses,
                        (statusAbrv) => s.subscriptionStatus.abrv === statusAbrv
                    ) !== -1
            );
        }
        return null;
    }

    @computed get isSubscriptionBundleActive() {
        return this.subscription && isSubscriptionBundle(this.subscription.product.productCategory);
    }

    @computed get pendingSubscription() {
        if (this._premiumInfo) {
            return find(
                this._premiumInfo.subscriptions,
                (s) => s.subscriptionStatus.abrv === subscriptionStatuses.pending
            );
        }
        return null;
    }

    @computed get isPremiumTrialPeriodUsed() {
        if (this._premiumInfo) {
            return this._premiumInfo.premiumTrialPeriodUsed;
        }
        return true;
    }

    @computed get invalidPaymentExist() {
        return this.invalidPaymentSubscriptions && this.invalidPaymentSubscriptions.length > 0;
    }

    @computed get invalidPaymentSubscriptions() {
        return filter(this.subscriptions, (subscription) => !isNil(subscription.latestInvalidPaymentTransaction));
    }

    @computed get subscriptionExist() {
        return this.subscriptions.length > 0;
    }

    @computed get premiumTrialSubscriptionExist() {
        return some(
            this.subscriptions,
            (subscription) => subscription.subscriptionStatus.abrv === subscriptionStatuses.trial
        );
    }

    @computed get premiumTrialSubscriptions() {
        return filter(
            this.subscriptions,
            (subscription) => subscription.subscriptionStatus.abrv === subscriptionStatuses.trial
        );
    }

    @computed get premiumPastDueSubscriptionExist() {
        return some(
            this.subscriptions,
            (subscription) => subscription.subscriptionStatus.abrv === subscriptionStatuses.pastDue
        );
    }

    @computed get premiumPastDueSubscriptions() {
        return filter(
            this.subscriptions,
            (subscription) => subscription.subscriptionStatus.abrv === subscriptionStatuses.pastDue
        );
    }

    @computed get isInternalSubscriptionActive() {
        return !isNil(this.subscription) && this.subscription.isInternal;
    }

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeObservable(this);

        this.isLegacySubscriptionActive = this.isLegacySubscriptionActive.bind(this);
    }

    async preprocessPayment(planId) {
        const response = await this.paymentService.preprocess(planId);
        return response.data;
    }

    async postprocessPayment(subscriptionId) {
        const response = await this.paymentService.postprocess(subscriptionId);
        await this.refreshUserPremiumSubscription();
        return response.data;
    }

    async postprocessPaymentMethodUpdate(paymentMethodId) {
        const response = await this.paymentService.postprocessPaymentMethodUpdate({
            paymentProviderPaymentMethodId: paymentMethodId,
        });
        await this.refreshUserPremiumSubscription();
        return response.data;
    }

    async updateDefaultPaymentMethod(paymentMethodId) {
        const response = await this.paymentService.updateDefaultPaymentMethod({
            paymentProviderPaymentMethodId: paymentMethodId,
        });
        await this.refreshUserPremiumSubscription();
        return response.data;
    }

    async cancelSubscription(subscriptionId) {
        const response = await this.paymentService.cancelSubscription(subscriptionId);
        await this.refreshUserPremiumSubscription();
        return response.data;
    }

    async changeSubscriptionPlan(planId) {
        const response = await this.paymentService.changeSubscriptionPlan(planId);
        await this.refreshUserPremiumSubscription();
        return response.data;
    }

    async renewSubscription(subscriptionId) {
        const response = await this.paymentService.renewSubscription(subscriptionId);
        await this.refreshUserPremiumSubscription();
        return response.data;
    }

    async upgradeTrialSubscription(subscriptionId, paymentMethodId) {
        const response = await this.paymentService.upgradeTrialSubscription(subscriptionId, {
            paymentProviderPaymentMethodId: paymentMethodId,
        });
        await this.refreshUserPremiumSubscription();
        return response.data;
    }

    async activateTrial(planId) {
        const response = await this.paymentService.activateTrial(planId);
        await this.refreshUserPremiumSubscription();
        return response.data;
    }

    async endTrialPeriod(subscriptionId) {
        const response = await this.paymentService.endTrialPeriod(subscriptionId);
        await this.refreshUserPremiumSubscription();
        return response.data;
    }

    async subscribeUsingExistingPaymentMethod(planId) {
        const response = await this.paymentService.subscribeUsingExistingPaymentMethod(planId);
        await this.refreshUserPremiumSubscription();
        return response.data;
    }

    async setupPaymentMethodRequest() {
        const response = await this.paymentService.setupPaymentMethodRequest();
        return response.data;
    }

    async getUserPremiumInfo() {
        try {
            const response = await this.paymentService.getUserPremiumInfo({
                embed: "subscriptionStatus,trialPeriod",
            });
            this.setPremiumInfo(response.data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "Something went wrong while trying to reload premium subscription. Please contact the support team."
            );
        }
    }

    refreshUserPremiumSubscription() {
        return this.getUserPremiumInfo();
    }

    getSubscription(subscriptionId) {
        if (this._premiumInfo && this._premiumInfo.subscriptions) {
            return find(this._premiumInfo.subscriptions, (subscription) => subscription.id === subscriptionId);
        }
        return null;
    }

    getSubscriptionBySubscriptionPlan(subscriptionPlanId) {
        if (this._premiumInfo && this._premiumInfo.subscriptions) {
            return find(
                this._premiumInfo.subscriptions,
                (subscription) => subscription.product.id === subscriptionPlanId
            );
        }
        return null;
    }

    @action.bound
    setPremiumInfo(premiumInfo) {
        this._premiumInfo = premiumInfo;
    }

    isLegacySubscriptionActive(legacySubscriptionExpirationDate) {
        if (isNil(legacySubscriptionExpirationDate)) {
            return false;
        }
        return moment.utc().isBefore(legacySubscriptionExpirationDate);
    }

    isSubscriptionPlanActive(subscriptionPlan) {
        let isActive = false;
        if (this.subscriptions && this.subscriptions.length > 0) {
            const subscription = find(
                this.subscriptions,
                (subscription) => subscription.product.id === subscriptionPlan.id
            );
            if (subscription) {
                isActive = some(
                    this._activeSubscriptionStatuses,
                    (status) => subscription.subscriptionStatus.abrv === status
                );
            }
        }

        return isActive;
    }
}

export default PremiumSubscriptionStore;
