import { action, observable, observe, reaction } from "mobx";
import { each, isFunction } from "lodash";

class MrbBaseRouteStore {
    observeDisposers = [];
    reactDisposers = [];

    @observable isRouteExitBlocked = observable.box(false);

    constructor(rootStore, parentRouteStore) {
        this.rootStore = rootStore;
        this.parentRouteStore = parentRouteStore;
    }

    @action.bound
    onBeforeEnter() {
        return Promise.resolve();
    }

    @action.bound
    onEnter() {
        return Promise.resolve();
    }

    @action.bound
    onBeforeExit() {
        return this._resolveRouteExitBlocking();
    }

    @action.bound
    onExit() {
        each(
            this.observeDisposers,
            (disposer) => isFunction(disposer) && disposer()
        );
        each(
            this.reactDisposers,
            (disposer) => isFunction(disposer) && disposer()
        );
        return Promise.resolve();
    }

    @action.bound
    setIsRouteExitBlocked(value) {
        this.isRouteExitBlocked.set(value);
    }

    _resolveRouteExitBlocking() {
        if (this.isRouteExitBlocked.get()) {
            return new Promise((resolve, reject) => {
                this.rootStore.confirmDialogStore.showConfirm({
                    message: "Do you want to leave page?",
                    description: "Any unsaved change will be lost.",
                    yesLabel: "Leave page",
                    onConfirm: () => {
                        resolve();
                        return Promise.resolve();
                    },
                    onCancel: () => {
                        reject();
                        return Promise.resolve();
                    },
                    noLabel: "Cancel",
                });
            });
        }
    }

    observe(target, propertyName = null, listener) {
        const disposer = observe(target, propertyName, listener);
        this.observeDisposers.push(disposer);
    }

    react(target, listener, options = {}) {
        const disposer = reaction(target, listener, options);
        this.reactDisposers.push(disposer);
        return disposer;
    }
}

export default MrbBaseRouteStore;
