class LocalStorageHandler {
    constructor() {
        if (!localStorage) {
            throw new Error("Local storage not found.");
        }
    }

    clear() {
        return localStorage.clear();
    }

    remove(key) {
        return localStorage.removeItem(key);
    }

    get(key) {
        const item = localStorage.getItem(key);
        try {
            return JSON.parse(item);
        } catch (err) {
            return item;
        }
    }

    set(key, data) {
        if (typeof data === "string") {
            localStorage.setItem(key, data);
        } else {
            localStorage.setItem(key, JSON.stringify(data));
        }
    }

    getKeys() {
        let keys = [];

        for (let i = 0, len = localStorage.length; i < len; ++i) {
            keys.push(localStorage.key(i));
        }

        return keys;
    }
}

const localStorageHandler = new LocalStorageHandler();
export default localStorageHandler;
