import React from 'react'
import ContentLoader from "react-content-loader";

const AnalyticsPremiumListLoaderTemplate = (props) => {
    const { podcastGruop } = props;

    return (
        <React.Fragment>
            {podcastGruop &&
                <React.Fragment>
                    <AnalyticsGruopLoaderCard {...props} podcastGruop={podcastGruop} />
                    <AnalyticsGruopLoaderCard {...props} podcastGruop={podcastGruop} />
                </React.Fragment>
            }
            <AnalyticsGruopLoaderCard props={props} podcastGruop={podcastGruop} />
        </React.Fragment>
    )
}

export default AnalyticsPremiumListLoaderTemplate

const AnalyticsGruopLoaderCard = (props) => {
    const { col = 5, row = 6, podcastGruop, ...rest } = props;

    const screenwidth = window.innerWidth;

    let loaderWidth = null;

    if (screenwidth > 1199) {
        loaderWidth = screenwidth - 690;
    } else if (screenwidth > 990) {
        loaderWidth = screenwidth - 250;
    } else {
        loaderWidth = screenwidth;
    }

    const listItems = [];
    const headerItems = [];
    let y = 0;
    let x = 0;

    for (let i = 0; i < row; i++) {
        for (let j = 0; j < col; j++) {
            headerItems.push(
                <React.Fragment key={`${i}_header_${j}`}>
                    <rect x={`${x}%`} y={0} rx="4" ry="4" width="100" height="10" />
                </React.Fragment>
            )
            listItems.push(
                <React.Fragment key={`${j}_${i}`}>
                    <rect x={`${x}%`} y={podcastGruop ? y + 38 : y} rx="4" ry="4" width="100" height="10" />
                </React.Fragment>
            )

            x += j === 0 ? 30 : j === 3 ? 21.7 : 20;
        }

        x = 0;
        y += 35;
    }

    return (
        <div className={`${podcastGruop ? "u-mar--bottom--med" : ""}`}>
            {podcastGruop &&
                <div>
                    <ContentLoader
                        speed={2}
                        width={150}
                        height="100%"
                        viewBox={`0 0 ${150} 32`}
                        backgroundColor="#3C3D3E"
                        foregroundColor="#404143"
                        {...rest}
                    >
                        <rect x={0} y={8} rx="4" ry="4" width="150" height="14" />
                    </ContentLoader>
                </div>
            }
            <div className="c-card--primary">
                <ContentLoader
                    speed={2}
                    width={loaderWidth}
                    height="100%"
                    viewBox={`0 0 ${loaderWidth} ${(podcastGruop ? row * 49 : (row * 35) - 20)}`}
                    backgroundColor="#262729"
                    foregroundColor="#2b2b2b"
                    {...rest}
                >
                    {podcastGruop &&
                        <React.Fragment>
                            {headerItems}
                            < rect x={`0`} y={22} rx="4" ry="4" width="100%" height="1" />
                        </React.Fragment>
                    }
                    {listItems}
                    {podcastGruop &&
                        <React.Fragment>
                            <rect x={`0`} y={y + 28} rx="4" ry="4" width="100%" height="1" />

                            {/* TOTAL */}
                            <rect x={`85%`} y={y + 45} rx="4" ry="4" width="80" height="10" />
                            <rect x={`94%`} y={y + 45} rx="4" ry="4" width="5.8%" height="10" />
                            <rect x={`83%`} y={y + 70} rx="4" ry="4" width="106" height="10" />
                            <rect x={`96%`} y={y + 70} rx="4" ry="4" width="3.8%" height="10" />
                        </React.Fragment>
                    }
                </ContentLoader>
            </div>
        </div>
    )
}