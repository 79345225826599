import { BaseApiRouteService } from "mrb/core";

class StationFileStreamApiRouteService extends BaseApiRouteService {
    constructor() {
        super("station-file-streams");
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?width,height}`, id, options);
    }

    getStationCoverImageByStation(id, options) {
        return super.get(
            `${this.base}/station-cover/{id}{?width,height}`,
            id,
            options
        );
    }

    createStationCoverImage(stationId, fileName) {
        return super.create(`${this.base}/{stationId}/cover/{fileName}`, {
            stationId: stationId,
            fileName: fileName,
        });
    }
}

export default StationFileStreamApiRouteService;
