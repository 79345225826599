import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { NakiRadioFreePlan } from "application/payment/models";
import { subscriptionPlansSortOrder } from "application/payment/utils";
import { subscriptionStatuses } from "common/constants";
import { sortBy, indexOf, head } from "lodash";

class PremiumSubscriptionsViewStore extends MrbBaseViewStore {
    @observable.ref subscriptionPlans = [];
    @observable.ref selectedSubscriptionPlan = null;

    @computed get subscriptionExist() {
        return this.rootStore.premiumSubscriptionStore.subscriptionExist;
    }

    @computed get existingSubscriptions() {
        return this.rootStore.premiumSubscriptionStore.subscriptions;
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
        const route = this.rootStore.routerStore.getCurrentRoute();
        if (route && route.data && route.data.title) {
            this.title = route.data.title;
        }
    }

    async onInit() {
        await this.fetchSubscriptionPlans();
        this.setSelectedSubscriptionPlan(head(this.subscriptionPlans));
    }

    async fetchSubscriptionPlans() {
        const items = await this.routeStore.getAllSubscriptionProducts();
        this.setSubscriptionPlans(items);
    }

    @action.bound
    setSubscriptionPlans(items) {
        let hasActivePlan = false;
        for (let subscriptionPlan of items) {
            const subscription = this.rootStore.premiumSubscriptionStore.getSubscriptionBySubscriptionPlan(
                subscriptionPlan.id
            );
            if (subscription) {
                subscriptionPlan.subscription = subscription;
                hasActivePlan = true;
            }
        }
        const freePlan = new NakiRadioFreePlan();
        if (!hasActivePlan && !this.rootStore.premiumSubscriptionStore.subscriptionExist) {
            freePlan.subscription = {
                id: -1,
                subscriptionStatus: {
                    abrv: subscriptionStatuses.active,
                },
            };
        }
        items.push(freePlan);
        const sortedSubscriptionPlans = sortBy(items, (subscriptionPlan) => {
            return indexOf(subscriptionPlansSortOrder, subscriptionPlan.slug);
        });
        this.subscriptionPlans = sortedSubscriptionPlans;
    }

    @action.bound
    setSelectedSubscriptionPlan(subscriptionPlan) {
        this.selectedSubscriptionPlan = subscriptionPlan;
    }
}

export default PremiumSubscriptionsViewStore;
