import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { applicationDefaults } from "common/constants";
import { dateFormatter } from "mrb/common/formatting";
import { PremiumTrialExpiration, SubscriptionPrice } from "application/payment/components";
import { isNil } from "lodash";
import { mergeCss } from "mrb/common/utils";
import { observer } from "mobx-react";

function PremiumTrialTermsInfoTemplate({ subscription, t }) {
    return (
        <div>
            <div
                className={mergeCss(
                    isNil(subscription.nextBillingDate)
                        ? "c-validation--info c-validation--info--tertiary"
                        : "c-validation--trial u-mar--bottom--sml"
                )}
            >
                <p className="u-type--med u-type--wgt--bold">
                    <PremiumTrialExpiration subscription={subscription} t={t} />
                </p>
                {isNil(subscription.nextBillingDate) ? (
                    <div>
                        <p>
                            To continue using all {subscription.product.name} features after the trial period, activate
                            subscription. You won't be charged until{" "}
                            {dateFormatter.format(subscription.trialPeriod.trialEndDate, {
                                format: applicationDefaults.formats.date,
                            })}
                            , cancel anytime.
                        </p>
                    </div>
                ) : (
                    <div>
                        <p>
                            {subscription.product.name} subscription is activated and starting from{" "}
                            {dateFormatter.format(subscription.trialPeriod.trialEndDate, {
                                format: applicationDefaults.formats.date,
                            })}{" "}
                            we will charge your credit card <SubscriptionPrice price={subscription.product.price} />.
                            You can cancel your subscription to avoid payment.
                        </p>
                    </div>
                )}
            </div>
            {isNil(subscription.nextBillingDate) && (
                <MrbRouterLink
                    className="c-btn c-btn--base c-btn--full c-btn--primary u-type--wgt--semibold u-mar--top--sml"
                    routeName="master.application.premium.trial.upgrade"
                    params={{ subscriptionId: subscription.id }}
                >
                    <SubscriptionPrice price={subscription.product.price} />
                </MrbRouterLink>
            )}
        </div>
    );
}

PremiumTrialTermsInfoTemplate.propTypes = {
    subscription: PropTypes.object.isRequired,
};

export default observer(PremiumTrialTermsInfoTemplate);
