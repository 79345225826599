import { action, runInAction } from "mobx";
import { default as MrbBaseUserStore } from "../core/stores/base/MrbBaseUserStore";
import { BaasicPermissionService } from "./";
import { isNil, get, has, isArray, split, first } from "lodash";

/**
 * Handles application authenticated user instance and provides authorization API for Baasic permissions.
 * @memberof Core.Stores
 */
class BaasicUserStore extends MrbBaseUserStore {
    /**
     * @constructor
     * @param {Core.Stores.RootStore} rootStore - The instance of the RootStore object.
     * @description Initializes a new instance of the BaasicUserStore.
     */
    constructor(rootStore) {
        super(rootStore);
        this.permissionService = new BaasicPermissionService();
    }

    resolveUser() {
        if (this.applicationUser) {
            return Promise.resolve();
        }
        return this.refreshUserAccount();
    }

    async refreshUserAccount() {
        if (this._resolveUserPromise) {
            return this._resolveUserPromise;
        }
        this._resolveUserPromise = this.resolveBaasicApplicationUser();
        const applicationUser = await this._resolveUserPromise;

        this._resolveUserPromise = null;
        runInAction(() => {
            this.applicationUser = applicationUser;
        });
    }

    /**
     * @function
     * @public
     * @description Remove stored authenticated user instance. Method will trigger application sign out event.
     */
    @action.bound
    removeUser() {
        const { application } = this.rootStore;

        this.applicationUser = null;
        if (application && application.baasic) {
            application.baasic.setUser(null);
        }
    }

    /**
     * @function
     * @async
     * @protected
     * @description Resolves authenticated user provided by Baasic SDK instance.
     */
    async resolveBaasicApplicationUser() {
        const {
            authenticationStore,
            application: { baasic: baasicApplication },
        } = this.rootStore;

        let user = null;
        if (authenticationStore.isAuthenticated) {
            const response =
                await baasicApplication.membershipModule.login.loadUserData({
                    embed: "permissions",
                });

            user = {
                ...response.data,
                isApplicationUser: true,
                apiKey: baasicApplication.getApiKey(),
            };

            await this.getUserProfile(user, baasicApplication);
        }

        baasicApplication.setUser(user);
        return user;
    }

    /**
     * @function
     * @async
     * @protected
     * @param {Object} user - Instance of the authenticated user.
     * @param {Object} baasicApplication - Instance of Baasic SDK application.
     * @description Resolves authenticated user Baasic profile provided by the UserProfileModule.
     */
    async getUserProfile(user, baasicApplication) {
        try {
            const userProfile =
                await baasicApplication.userProfileModule.profile.get(user.id);
            if (userProfile && userProfile.data) {
                user.userProfile = userProfile.data;
            }
        } catch (ex) {
            // eslint-disable-line
        }
    }

    /**
     * @function
     * @public
     * @param {string} authorization - The Baasic authorization policy.
     * Baasic authorization policy must be in the following format: sectionAbrv.actionAbrv where sectionAbrv specifies Baasic authorization section abbreviation
     * and actionAbrv specifies Baasic authorization Action abbreviation.
     * @description Determines whether an authenticated user has valid permissions constrained by the provided authorization parameter.
     */
    hasPermission(authorization) {
        const permissionFunc = (policy) => {
            if (authorizationValid(policy) && has(this.permissions, policy)) {
                return get(this.permissions, policy);
            }

            const { user } = this.rootStore.userStore;
            const hasPermission = this.permissionService.hasPermission(
                user,
                policy
            );

            if (authorizationValid(policy)) {
                this.permissions[policy] = hasPermission;
            }

            return hasPermission;
        };

        let authorized = true;
        if (authorization) {
            if (typeof authorization === "function") {
                return authorization();
            }

            let requestedAuthorization;
            if (isArray(authorization)) {
                requestedAuthorization = authorization;
            } else if (typeof authorization === "string") {
                requestedAuthorization = [authorization];
            }

            let l = requestedAuthorization.length;
            let i = 0;
            while (authorized && i < l) {
                const accessPolicy = requestedAuthorization[i++];
                authorized = permissionFunc(accessPolicy);
                if (!authorized) {
                    const section = first(split(accessPolicy, "."));
                    if (section && section !== "") {
                        authorized = permissionFunc(section + ".full");
                    }
                }
            }
        } else {
            authorized = permissionFunc(null);
        }

        return authorized;
    }
}

function authorizationValid(authorization) {
    return !isNil(authorization) && authorization;
}

export default BaasicUserStore;
