import {
    MrbApplicationMainSectionStore,
    MrbApplicationStartupStore,
    MrbErrorStore,
    MrbMainViewStore,
    MrbMenuStore,
    MrbNotificationStore,
    MrbConfirmDialogStore,
    MrbRouterStore,
} from "./";
import { MrbLoaderStore } from "../../components/loader";
import { merge, isFunction } from "lodash";

const defaultConfig = {
    applicationSettings: {},
    providers: {
        notificationProvider: null,
    },
    stores: {
        applicationStoreFactory: null,
        authenticationStoreFactory: null,
        applicationMainSectionStoreFactory: (context) => new MrbApplicationMainSectionStore(context.rootStore),
        errorStoreFactory: (context) => new MrbErrorStore(context.rootStore),
        applicationStartupStoreFactory: (context) => new MrbApplicationStartupStore(context.rootStore),
        routerStoreFactory: (context) => new MrbRouterStore(context.rootStore),
        userStoreFactory: null,
        mainViewStoreFactory: (context) => new MrbMainViewStore(context.rootStore),
        menuStoreFactory: (context) => new MrbMenuStore(context.rootStore),
        notificationStoreFactory: ({ rootStore, notificationProvider }) =>
            new MrbNotificationStore(rootStore, notificationProvider),
        mainLoaderStoreFactory: () => new MrbLoaderStore({ delay: 1000 }),
        confirmDialogStoreFactory: (context) => new MrbConfirmDialogStore(context.rootStore),
    },
};

/**
 * RootStore is main store used in the application.
 * @class
 * @memberof Core.Stores
 */
class MrbRootStore {
    currentRouteStores = new Map();

    get application() {
        return this.applicationStore.app;
    }

    get apiClient() {
        return this.applicationStore.apiClient;
    }

    get applicationSettings() {
        return this._config.applicationSettings;
    }

    /**
     * @constructor
     * @description Initializes a new instance of the RootStore class.
     * @param {object} modulesConfiguration - modules configuration object.
     */
    constructor(modulesConfiguration, config) {
        this.configuration = modulesConfiguration;
        this._config = merge({}, defaultConfig, config);

        const context = { rootStore: this };
        this.createStores(context, this._config.stores);
        this.createServices(context);
    }

    createStores(context, storesConfig) {
        if (!storesConfig) {
            throw new Error("Stores configuration is required in order to create core stores.");
        }
        if (!storesConfig.applicationStoreFactory || !isFunction(storesConfig.applicationStoreFactory)) {
            throw new Error("ApplicationStoreFactory not provided.");
        }
        this.applicationStore = storesConfig.applicationStoreFactory(context);

        if (!storesConfig.authenticationStoreFactory || !isFunction(storesConfig.authenticationStoreFactory)) {
            throw new Error("AuthenticationStoreFactory not provided.");
        }
        this.authenticationStore = storesConfig.authenticationStoreFactory(context);

        if (
            !storesConfig.applicationMainSectionStoreFactory ||
            !isFunction(storesConfig.applicationMainSectionStoreFactory)
        ) {
            throw new Error("ApplicationMainSectionStoreFactory not provided.");
        }
        this.applicationMainSectionStore = storesConfig.applicationMainSectionStoreFactory(context);

        if (!storesConfig.errorStoreFactory || !isFunction(storesConfig.errorStoreFactory)) {
            throw new Error("ErrorStoreFactory not provided.");
        }
        this.errorStore = storesConfig.errorStoreFactory(context);

        if (!storesConfig.applicationStartupStoreFactory || !isFunction(storesConfig.applicationStartupStoreFactory)) {
            throw new Error("ApplicationStartupStoreFactory not provided.");
        }
        this.applicationStartupStore = storesConfig.applicationStartupStoreFactory({
            ...context,
            notificationProvider: this._config.providers.notificationProvider,
        });

        if (!storesConfig.routerStoreFactory || !isFunction(storesConfig.routerStoreFactory)) {
            throw new Error("RouterStoreFactory not provided.");
        }
        this.routerStore = storesConfig.routerStoreFactory(context);

        if (!storesConfig.userStoreFactory || !isFunction(storesConfig.userStoreFactory)) {
            throw new Error("UserStoreFactory not provided.");
        }
        this.userStore = storesConfig.userStoreFactory(context);

        if (!storesConfig.mainViewStoreFactory || !isFunction(storesConfig.mainViewStoreFactory)) {
            throw new Error("MainViewStoreFactory not provided.");
        }
        this.mainViewStore = storesConfig.mainViewStoreFactory(context);

        if (!storesConfig.menuStoreFactory || !isFunction(storesConfig.menuStoreFactory)) {
            throw new Error("MenuStoreFactory not provided.");
        }
        this.menuStore = storesConfig.menuStoreFactory(context);

        if (!storesConfig.notificationStoreFactory || !isFunction(storesConfig.notificationStoreFactory)) {
            throw new Error("NotificationStoreFactory not provided.");
        }
        this.notificationStore = storesConfig.notificationStoreFactory({
            ...context,
            notificationProvider: this._config.providers.notificationProvider,
        });

        if (!storesConfig.mainLoaderStoreFactory || !isFunction(storesConfig.mainLoaderStoreFactory)) {
            throw new Error("mainLoaderStoreFactory not provided.");
        }
        this.mainLoaderStore = storesConfig.mainLoaderStoreFactory(context);

        if (!storesConfig.confirmDialogStoreFactory || !isFunction(storesConfig.confirmDialogStoreFactory)) {
            throw new Error("ConfirmDialogStoreFactory not provided.");
        }
        this.confirmDialogStore = storesConfig.confirmDialogStoreFactory(context);
    }

    createServices(context) {
        return;
    }

    createCurrentRouteStore = (name, Store, parentName = null) => {
        let parentStore = null;
        if (parentName && this.currentRouteStores.has(parentName)) {
            parentStore = this.currentRouteStores.get(parentName);
        }
        const store = new Store(this, parentStore);
        this.currentRouteStores.set(name, store);
        return store;
    };

    getCurrentRouteStore = (name) => {
        return this.currentRouteStores.get(name);
    };

    removeCurrentRouteStore = (name) => {
        if (this.currentRouteStores.has(name)) {
            this.currentRouteStores.delete(name);
        }
    };

    createApplicationService = (Type) => {
        return this.applicationStore.createApplicationService(Type);
    };
}

export default MrbRootStore;
