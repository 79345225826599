import { BaseApiRouteService } from "mrb/core";

class EpisodeSortTypeApiRouteService extends BaseApiRouteService {
    constructor() {
        super("lookups/episode-sort-types");
    }

    get() {
        return super.get(this.base);
    }
}

export default EpisodeSortTypeApiRouteService;
