import React from "react";
import PropTypes from "prop-types";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import { AgGridReact } from "@ag-grid-community/react";
import { observer } from "mobx-react";
import { MrbPager } from "../pager";
import { mrbTableColumnRenderer, mrbTableActionColumnRenderer } from "./columns";
import { MrbTableLoadingOverlay, MrbTableNoDataOverlay } from "./overlays";
import { mergeCss } from "../../common/utils";
import { map, isFunction } from "lodash";

// Note: this will register modules globally. See: https://www.ag-grid.com/documentation/javascript/modules/#providing-modules-globally
ModuleRegistry.registerModules([ClientSideRowModelModule, InfiniteRowModelModule]);

function MrbTable({
    tableStore,
    ActionsComponent,
    HeaderComponent,
    className,
    pagerRender,
    t = (i) => i,
    loadingRender,
    emptyStateRender,
    ...props
}) {
    const {
        onGridReady,
        onRowSelected,
        onSelectionChanged,
        rowDataChanged,
        processCellForClipboard,
        isRowSelectable,
        config: {
            selection,
            rows: { rowClass, getRowClass, getRowStyle },
            actions,
            columns,
            defaultColumnDefinition,
            onRowClick,
            multiSortKey,
        },
        getContextMenuItems,
    } = tableStore;

    return (
        <div className={className} style={{ height: "100%", width: "100%" }}>
            <AgGridReact
                rowClass={isFunction(onRowClick) ? mergeCss("u-cursor--pointer", rowClass) : rowClass}
                getRowClass={getRowClass}
                getRowStyle={getRowStyle}
                suppressPropertyNamesCheck={true}
                onGridReady={onGridReady}
                defaultColDef={defaultColumnDefinition}
                suppressPaginationPanel={true}
                onRowClicked={onRowClick}
                rowSelection={selection.rowSelection}
                suppressRowClickSelection={selection.suppressRowClickSelection}
                processCellForClipboard={processCellForClipboard}
                suppressMultiRangeSelection={selection.suppressMultiRangeSelection}
                onRowSelected={onRowSelected}
                isRowSelectable={isRowSelectable}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                allowContextMenuWithControlKey={true}
                getContextMenuItems={getContextMenuItems}
                onRowDataChanged={rowDataChanged}
                loadingOverlayComponentFramework={loadingRender || MrbTableLoadingOverlay}
                noRowsOverlayComponentFramework={emptyStateRender || MrbTableNoDataOverlay}
                multiSortKey={multiSortKey}
                {...props}
            >
                {renderColumns(columns, tableStore, HeaderComponent, t)}
                {mrbTableActionColumnRenderer(tableStore, actions, ActionsComponent, t)}
            </AgGridReact>
            <PagerWrapper tableStore={tableStore} pagerRender={pagerRender} t={t} />
        </div>
    );
}

function renderColumns(columns, tableStore, HeaderComponent, t) {
    return map(columns, (column) => mrbTableColumnRenderer(column, tableStore, HeaderComponent, t));
}

const PagerWrapper = observer(function PagerWrapper({ tableStore, t, pagerRender }) {
    const renderer = pagerRender ? pagerRender : (props) => <MrbPager {...props} />;
    return (
        tableStore.dataInitialized &&
        tableStore.config.paging &&
        renderer({
            tableStore,
            hidePagerIfUnderLimit: tableStore.config.hidePagerIfUnderLimit,
            t,
        })
    );
});

MrbTable.propTypes = {
    tableStore: PropTypes.object.isRequired,
    ActionsComponent: PropTypes.elementType,
    HeaderComponent: PropTypes.elementType,
    className: PropTypes.string,
    pagerRender: PropTypes.func,
    t: PropTypes.func,
    loadingRender: PropTypes.func,
    emptyStateRender: PropTypes.func,
};

export default observer(MrbTable);
