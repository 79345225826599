import React from "react";
import { isUndefined, isNull, isFunction } from "lodash";
import { withRootStore } from "./";

function setCurrentView(getView, name = "currentView") {
    return function (Component) {
        class WrapComponent extends React.Component {
            constructor(props) {
                super(props);
                this.initializeView(props);
            }

            view = null;

            initializeView({ rootStore, parentView, storeName, ...componentProps }) {
                const customStoreName =
                    !isUndefined(storeName) && !isNull(storeName) && storeName !== "" ? storeName : name;
                const parent = parentView || rootStore;
                let propertyName = getPropertyName(parent, customStoreName);
                let currentView = typeof getView === "function" ? getView(rootStore, componentProps) : getView;
                currentView.store_path =
                    parent && parent !== rootStore ? parent.store_path + "." + customStoreName : customStoreName;

                parent[propertyName] = currentView;

                this.view = {
                    [name]: currentView, // this is used for passing in props so child always gets view under defined name
                    propertyName: propertyName,
                    parentView: parent,
                };
            }

            componentDidMount() {
                const currentView = this.view[name];
                if (currentView && currentView.initialize && isFunction(currentView.initialize)) {
                    currentView.initialize();
                }
            }

            componentWillUnmount() {
                const currentView = this.view[name];
                if (currentView && currentView.destroy && isFunction(currentView.destroy)) {
                    currentView.destroy();
                }

                if (this.view.parentView[this.view.propertyName]) {
                    delete this.view.parentView[this.view.propertyName];
                }
            }

            componentDidUpdate(prevProps) {
                const currentView = this.view[name];
                if (currentView && currentView.onViewUpdate && typeof currentView.onViewUpdate === "function") {
                    currentView.onViewUpdate(prevProps, this.props);
                }
            }

            render() {
                const { propertyName, parentView, ...other } = this.view; // eslint-disable-line
                const { rootStore, ...rest } = this.props; // eslint-disable-line
                const props = {
                    parentView: parentView,
                    [name]: this.view[name],
                    ...rest,
                };

                return <Component {...props} />;
            }
        }

        return withRootStore(WrapComponent);
    };
}

function getPropertyName(obj, name, idx = -1) {
    const propertyName = idx !== -1 ? name + "_" + idx : name;
    const property = obj[propertyName];
    if (property !== undefined && property !== null) {
        return getPropertyName(obj, name, idx + 1);
    }

    return propertyName;
}

export default setCurrentView;
