import React from "react";
import { StationListenersListTemplate } from "themes/administration/analytics/components";
import { StationListenersListViewStore } from "administration/analytics/stores";
import { setCurrentView } from "mrb/core";
import { observer } from "mobx-react";

@setCurrentView(
    (rootStore, componentProps) => new StationListenersListViewStore(rootStore, componentProps),
    "stationListenersListViewStore"
)
@observer
class StationListenersList extends React.Component {
    render() {
        return <StationListenersListTemplate {...this.props} />;
    }
}

export default StationListenersList;
