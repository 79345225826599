import { action, observable, makeObservable } from "mobx";
import { assignWith, omitBy, isUndefined, isNull, some, forOwn, forEach } from "lodash";
import { MrbFilterParameters, sortDirection } from "./";

class MrbQueryUtility {
    @observable filter;
    @observable recordCount;
    @observable totalPages;

    constructor(rootStore, fetchFunc, options = {}) {
        this.rootStore = rootStore;
        this.fetchFunc = fetchFunc;
        makeObservable(this);

        if (options.filter) {
            this.filter = options.filter;
        } else {
            this.filter = new MrbFilterParameters();
        }

        this.recordCount = 0;
        this.totalPages = 0;

        this._init(options);
    }

    _init = (options) => {
        if (options.onChangeOrder) {
            this.onChangeOrder = options.onChangeOrder;
        }

        if (options.onChangePage) {
            this.onChangePage = options.onChangePage;
        }

        if (options.onChangePageSize) {
            this.onChangePageSize = options.onChangePageSize;
        }

        if (options.embed) {
            this.filter.embed = options.embed;
        }

        if (options.fields) {
            this.filter.fields = options.fields;
        }

        if (options.onTransformQueryParams) {
            this.onTransformQueryParams = options.onTransformQueryParams;
        }

        if (options.queryParamMap) {
            this.queryParamMap = options.queryParamMap;
        }
        if (options.paramKeyMap) {
            this.paramKeyMap = options.paramKeyMap || {};
        }

        if (options.ignoreQueryParams) {
            this.ignoreQueryParams = options.ignoreQueryParams;
        }

        if (options.disableUpdateQueryParams) {
            this.disableUpdateQueryParams = options.disableUpdateQueryParams;
        }

        if (options.onResetFilter) {
            this.onResetFilter = options.onResetFilter;
        }
        if (options.pageSize) {
            this.filter.pageSize = options.pageSize;
        }
        if (options.orderBy) {
            this.filter.orderBy = options.orderBy;
        }
        if (options.orderDirection) {
            this.filter.orderDirection = options.orderDirection;
        }
    };

    @action initialize = (updateUrlParams = true, useDefaultFilter = false) => {
        this.updateFilter(useDefaultFilter);
        return this._reloadCollection(updateUrlParams);
    };

    fetch = (updateUrlParams = true) => {
        this.changePage(1, updateUrlParams);
    };

    @action changePage = (page, updateUrlParams = true) => {
        this.filter.pageNumber = page;

        if (this.onChangePage) {
            this.onChangePage(page);
        }

        return this._reloadCollection(updateUrlParams);
    };

    @action changePageSize = (pageSize, updateUrlParams = true) => {
        this.filter.pageSize = pageSize;
        this.filter.pageNumber = 1;

        if (this.onChangePageSize) {
            this.onChangePageSize(pageSize);
        }

        return this._reloadCollection(updateUrlParams);
    };

    @action changeOrder = (sort, updateUrlParams = true) => {
        let direction = sortDirection.asc;
        if (this.filter.orderBy === sort && this.filter.orderDirection === sortDirection.asc) {
            direction = sortDirection.desc;
        }
        if (this.filter.orderBy === sort && this.filter.orderDirection === sortDirection.desc) {
            sort = null;
            direction = null;
        }
        this.filter.orderBy = sort;
        this.filter.orderDirection = this.filter.orderBy === null ? null : direction;
        if (this.onChangeOrder) {
            this.onChangeOrder({ sort, direction });
        }

        return this._reloadCollection(updateUrlParams);
    };

    @action handleResponse = (response, updateUrlParams = false) => {
        this.filter.pageNumber = response.page;
        this.filter.pageSize = response.recordsPerPage;
        this.recordCount = response.totalRecords;
        this.totalPages = Math.ceil(this.recordCount / this.filter.pageSize);

        if (response.sort) {
            let params = response.sort.split("|");

            this.filter.orderBy = params[0];
            this.filter.orderDirection = params[1];
        }

        if (updateUrlParams) {
            this.updateUrlParams();
        }
    };

    getFilterUrlParams = () => {
        const {
            pageNumber,
            pageSize,
            search,
            orderBy,
            orderDirection,
            /* eslint-disable */
            embed,
            fields,
            /* eslint-enable */
            ...others
        } = this.filter;

        let sortSlug;
        if (orderBy) {
            sortSlug = orderBy + "|" + orderDirection;
        }

        let queryParams = {
            page: pageNumber,
            rpp: pageSize,
            orderBy: sortSlug,
            search: search || undefined,
            ...others,
        };
        if (this.paramKeyMap) {
            forEach(this.paramKeyMap, (key, realKey) => {
                queryParams[key] = queryParams[realKey];
                delete queryParams[realKey];
            });
        }

        return this.getValidQueryParams(queryParams);
    };

    getValidQueryParams = (queryParams) => {
        return omitBy(queryParams, (param, key) => {
            const isEmpty = isUndefined(param) || isNull(param) || param === "";
            if (isEmpty) {
                return true;
            }

            return this.ignoreQueryParams ? some(this.ignoreQueryParams, (ignore) => ignore === key) : false;
        });
    };

    updateUrlParams = () => {
        if (this.disableUpdateQueryParams) return;

        const { routerStore } = this.rootStore;
        let queryParams = this.getFilterUrlParams();

        if (this.queryParamMap) {
            forOwn(queryParams, (value, key) => {
                const realKey = this.paramKeyMap && this.paramKeyMap[key] ? this.paramKeyMap[key] : key;
                const map = this.queryParamMap[realKey];
                if (map && map.toQuery) {
                    queryParams[realKey] = map.toQuery(value);
                }
            });
        }

        routerStore.setQueryParams(queryParams);
    };

    removeUrlParams = (paramsToRemove = []) => {
        const { routerStore } = this.rootStore;
        let queryParams = this.getFilterUrlParams();

        if (paramsToRemove.length === 0) routerStore.setQueryParams();
        else {
            paramsToRemove.forEach((param) => {
                if (hasOwnProperty(queryParams, param)) {
                    queryParams[param] = undefined;
                }
            });

            routerStore.setQueryParams(queryParams);
        }

        function hasOwnProperty(obj, prop) {
            var proto = obj.__proto__ || obj.constructor.prototype;
            return prop in obj && (!(prop in proto) || proto[prop] !== obj[prop]);
        }
    };

    updateFilter = (useDefault = false) => {
        if (useDefault) {
            this.filter.reset();
            return;
        }

        const { page, rpp, orderBy, search, ...others } = this.rootStore.routerStore.routerState.queryParams;

        this.filter.pageNumber = parseInt(page < 0 ? 1 : page) || this.filter.pageNumber;
        this.filter.pageSize = parseInt(rpp) || this.filter.pageSize;

        if (orderBy) {
            const params = orderBy.split("|");
            this.filter.orderBy = params[0];
            if (params.length > 1) {
                this.filter.orderDirection = params[1];
            }
        }

        if (search) {
            this.filter.search = search;
        }

        if (others) {
            const mapParams = withoutProperties(others, this.ignoreQueryParams);

            const evaluateQueryParam = (queryParamKey, queryParamValue, filterValue) => {
                if (this.queryParamMap) {
                    // custom mapping function for specified properties
                    const map = this.queryParamMap[queryParamKey];
                    if (map && map.fromQuery) {
                        return map.fromQuery(queryParamValue);
                    }
                }

                try {
                    if (Array.isArray(filterValue) && typeof queryParamValue === "string") {
                        return [queryParamValue];
                    }
                    /* eslint-disable */
                    return eval(queryParamValue);
                } catch (ex) {
                    return queryParamValue;
                }
            };

            assignWith(this.filter, mapParams, (objValue, srcValue, key) => {
                return isUndefined(srcValue) ? objValue : evaluateQueryParam(key, srcValue, objValue);
            });
        }
    };

    @action resetFilter(fetch = true) {
        this.filter.reset();

        if (this.onResetFilter) {
            this.onResetFilter(this.filter);
        }

        if (fetch) this.fetch();
    }

    _reloadCollection = (updateUrlParams = true) => {
        if (updateUrlParams) {
            this.updateUrlParams();
        }

        return this.fetchFunc(this.filter, { updateUrlParams });
    };
}

function withoutProperties(obj, properties) {
    return omitBy(obj, (param, key) => {
        return !isUndefined(properties) && !isNull(obj) ? some(properties, (ignore) => ignore === key) : false;
    });
}

export default MrbQueryUtility;
