import React from "react";
import PropTypes from "prop-types";
import { renderActions } from "../utils";
import { MrbBreadcrumbs } from "../../breadcrumbs";
import { MrbButton } from "../../button";
import { isFunction } from "lodash";
import { default as MrbRouterLink } from "../../../core/infrastructure/router/MrbRouterLink";
import { MrbEditFormValidation } from "../content";
import { observer } from "mobx-react";

function MrbEditPageHeader({
    form,
    onClickCancel,
    title,
    layoutActions,
    navigationOptions,
    navigateBack,
    t = (i) => i,
    submitLabel,
    cancelLabel,
}) {
    return (
        <React.Fragment>
            <div className="c-page__header--top">
                {navigateBack ? (
                    <MrbRouterLink routeName={navigateBack.routeName} params={navigateBack.routeParams}>
                        <span className="display--ib u-mar--right--tny align--v--middle">
                            <i className="u-icon u-icon--med u-icon--back" />
                        </span>
                        {t(navigateBack.title)}
                    </MrbRouterLink>
                ) : (
                    <div>{t(title)}</div>
                )}
                <div className="c-header__actions">
                    <MrbEditFormValidation form={form} t={t} />

                    <EditPageActions
                        form={form}
                        onClickCancel={onClickCancel}
                        t={t}
                        submitLabel={submitLabel}
                        cancelLabel={cancelLabel}
                    />
                    {renderActions(layoutActions)}
                </div>
            </div>
            {navigationOptions && (
                <div className="c-page__header--bottom">
                    <MrbBreadcrumbs options={navigationOptions} t={t} />
                </div>
            )}
        </React.Fragment>
    );
}

MrbEditPageHeader.propTypes = {
    form: PropTypes.object.isRequired,
    onClickCancel: PropTypes.func,
    title: PropTypes.string,
    layoutActions: PropTypes.object,
    navigationOptions: PropTypes.object,
    navigateBack: PropTypes.object,
    t: PropTypes.func,
    submitLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
};

const EditPageActions = observer(function EditPageActions({
    form,
    onClickCancel,
    submitLabel = "Save",
    cancelLabel = "Cancel",
    t,
}) {
    return (
        <React.Fragment>
            {onClickCancel && isFunction(onClickCancel) && (
                <MrbButton
                    t={t}
                    className="c-btn c-btn--base c-btn--ghost"
                    label={cancelLabel}
                    onClick={onClickCancel}
                />
            )}{" "}
            <MrbButton
                t={t}
                type="submit"
                className="c-btn c-btn--base c-btn--primary"
                onClick={form.onSubmit}
                aria-label={submitLabel}
                aria-busy={form.submitting || form.validating}
                label={submitLabel}
                disabled={form.submitting || form.validating}
            />
        </React.Fragment>
    );
});

EditPageActions.propTypes = {
    form: PropTypes.object.isRequired,
    onClickCancel: PropTypes.func,
    submitLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    t: PropTypes.func,
};

export default observer(MrbEditPageHeader);
