class UpdateStationStatusOptions {
    stationId = null;
    title = null;
    isPremium = false;
    isUnavailableInApp = false;

    constructor(stationId, title, isPremium, isUnavailableInApp) {
        this.stationId = stationId;
        this.title = title;
        this.isPremium = isPremium;
        this.isUnavailableInApp = isUnavailableInApp;
    }
}

export default UpdateStationStatusOptions;
