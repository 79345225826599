import React from "react";
import { PropTypes } from "prop-types";
import { DoughnutChart } from "common/components";
import { observer } from "mobx-react";
import { UserDeviceActivitySummaryLoader } from "administration/analytics/components";

function UserContentListSummaryTemplate({ userDeviceActivityViewStore, t }) {
    const { userDeviceActivitySummaryViewStore } = userDeviceActivityViewStore;
    const { chartStore } = userDeviceActivitySummaryViewStore;
    return (
        <div>
            <h1 className="c-analytics__title">Device Activity (Overall)</h1>
            <div className="c-card--primary c-card--med">
                <DoughnutChart
                    store={chartStore}
                    loaderRenderer={() => <UserDeviceActivitySummaryLoader />}
                    emptyStateRenderer={() => <EmptyState />}
                />
            </div>
        </div>
    );
}

UserContentListSummaryTemplate.propTypes = {
    userDeviceActivityViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function EmptyState() {
    return (
        <div className="c-empty-state--no-results c-empty-state--no-results--sml">
            <i className="u-icon--empty u-icon--empty--sml u-icon--empty--analytics u-mar--bottom--sml"></i>
            <h3>No activity data</h3>
            <p>The user has no active devices.</p>
        </div>
    );
}

export default observer(UserContentListSummaryTemplate);
