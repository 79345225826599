import { BaseService } from "mrb/core";
import { ProductApiRouteService } from "common/services";

class ProductService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new ProductApiRouteService());
    }

    getSubscription(id, options, abortSignal = null) {
        const url = this.routeService.getSubscription(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getAllSubscriptions(abortSignal = null) {
        const url = this.routeService.getAllSubscriptions();
        return this.apiClient.get(url, null, abortSignal);
    }

    getSubscriptionBundle(id, options, abortSignal = null) {
        const url = this.routeService.getSubscriptionBundle(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getAllSubscriptionBundles(abortSignal = null) {
        const url = this.routeService.getAllSubscriptionBundles();
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default ProductService;
