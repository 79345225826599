import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from "prop-types";

function MrbRecaptcha({ store, className, tabindex }) {
    const { setCaptchaRef, config, onChange, onExpired, onError } = store;
    return (
        <div className={className || "form__group"}>
            <ReCAPTCHA
                tabindex={tabindex}
                sitekey={config.siteKey}
                badge={config.badgePosition}
                stoken={config.secureToken}
                size={config.type}
                ref={(ref) => setCaptchaRef(ref)}
                onChange={onChange}
                onExpired={onExpired}
                onErrored={onError}
            />
        </div>
    );
}

MrbRecaptcha.propTypes = {
    store: PropTypes.object.isRequired,
    className: PropTypes.string,
    onResolved: PropTypes.func,
    tabindex: PropTypes.number,
};

export default MrbRecaptcha;
