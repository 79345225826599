import { action, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "../../core/stores";
import { extend, isFunction } from "lodash";

class MrbFileUploadViewStore extends MrbBaseViewStore {
    @observable.ref files = [];
    @observable.ref rejectedFiles = [];
    @observable disabled = false;

    uploadInputRef = null;

    actions = {
        onChange: () => {},
    };

    config = {
        multiple: false,
        showValidation: true,
        errorsMap: null,
    };

    constructor(rootStore, { actions, ...config }) {
        super(rootStore);
        makeObservable(this);
        this.config = extend({}, this.config, config);
        this.actions = extend({}, this.actions, actions);
    }

    @action.bound
    onChange(files) {
        this.files = files;
        this.actions.onChange(files);
        this.setRejectedFiles([]);
    }

    @action.bound
    setRejectedFiles(files) {
        this.rejectedFiles = files;
    }

    @action.bound
    onDropRejected(files) {
        this.setRejectedFiles(files);
        if (isFunction(this.actions.onFileRejected)) {
            this.actions.onFileRejected(files);
        }
    }

    @action.bound
    setDisabled(isDisabled) {
        this.disabled = isDisabled;
    }

    @action.bound
    setUploadInputRef(ref) {
        this.uploadInputRef = ref;
    }

    raiseBrowse() {
        if (this.uploadInputRef) {
            this.uploadInputRef.open();
        }
    }

    @action.bound
    clear() {
        this.files = [];
    }
}

export default MrbFileUploadViewStore;
