import React from "react";
import { PropTypes } from "prop-types";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { defaultTemplate } from "common/hoc";
import { StripeCreditCardField } from "common/components";
import { MrbButton } from "mrb/components/button";

function UpdateCustomerPaymentMethodTemplate({ store, t }) {
    const stripe = useStripe();
    const elements = useElements();

    const { stripeField, isPaymentDisabled } = store;

    if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return <div>loading</div>;
    }
    store.setStripe(stripe);

    const onSubmit = () => {
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
        store.onFormSubmit(cardElement);
    };

    return (
        <React.Fragment>
            <div className="c-payment">
                <form
                    onSubmit={(event) => {
                        onSubmit();
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                >
                    <div className="c-payment__payment-card">
                        <StripeCreditCardField field={stripeField} label="Credit Card" t={t} />
                    </div>

                    <div className="u-separator--primary"></div>

                    <div className="u-mar--top--sml">
                        <MrbButton
                            t={t}
                            type="submit"
                            disabled={isPaymentDisabled}
                            className="c-btn c-btn--med c-btn--full c-btn--primary"
                            label="Update Credit Card"
                        />
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}

UpdateCustomerPaymentMethodTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(UpdateCustomerPaymentMethodTemplate);
