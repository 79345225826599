import { BaseService } from "mrb/core";
import { PodcastAnalyticsApiRouteService } from "common/services";

class PodcastAnalyticsService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new PodcastAnalyticsApiRouteService());
    }

    getPodcastPremiumStatistic(id, options, abortSignal = null) {
        const url = this.routeService.getPodcastPremiumStatistic(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastGroupPremiumStatistic(id, options, abortSignal = null) {
        const url = this.routeService.getPodcastGroupPremiumStatistic(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getTrendingPodcasts(filter, abortSignal = null) {
        const url = this.routeService.getTrendingPodcasts(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastActivity(id, options, abortSignal = null) {
        const url = this.routeService.getPodcastActivity(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastStats(id, options, abortSignal = null) {
        const url = this.routeService.getPodcastStats(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastSummary(id, options, abortSignal = null) {
        const url = this.routeService.getPodcastSummary(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastListenersStats(abortSignal = null) {
        const url = this.routeService.getPodcastListenersStats();
        return this.apiClient.get(url, null, abortSignal);
    }

    getTrendingPodcastsForProducer(filter, abortSignal = null) {
        const url = this.routeService.getTrendingPodcastsForProducer(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getTrendingPodcastsTotalStreamsForProducer(filter, abortSignal = null) {
        const url = this.routeService.getTrendingPodcastsTotalStreamsForProducer(filter);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default PodcastAnalyticsService;
