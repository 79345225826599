import { LocalizedForm } from "common/localization";

export default class AdminProducerAccountBalanceEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "amount",
                    label: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.ACCOUNT_BALANCE_AMOUNT_LABEL",
                    placeholder: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.ACCOUNT_BALANCE_PLACEHOLDER",
                    rules: "required|numeric",
                },
                {
                    name: "notes",
                    label: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.ACCOUNT_BALANCE_NOTES_LABEL",
                    placeholder: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.ACCOUNT_BALANCE_NOTES_PLACEHOLDER",
                    rules: "string",
                },
            ],
        };
    }
}
