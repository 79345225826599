import { runInAction, observable, reaction } from "mobx";
import { MrbSimpleSelectStore, DropdownItemsResult } from "mrb/components/select";
import { isFunction, findIndex, filter, some } from "lodash";

export default class PlaylistSelectStore extends MrbSimpleSelectStore {
    isCreatable = false;

    @observable showCreateOption = false;

    constructor(config, initialItems) {
        super(config, initialItems);

        this.isCreatable = isFunction(this.actions.createOption);
        this.createOption = this.createOption.bind(this);

        reaction(
            () => this.searchTerm,
            () => {
                runInAction(() => {
                    this.showCreateOption = false;
                });
            }
        );

        reaction(
            () => this.filteredItems,
            () => {
                runInAction(() => {
                    if (
                        this.searchTerm == null ||
                        this.searchTerm.trim() === "" ||
                        some(this.filteredItems?.items, (item) => {
                            return item?.title?.trim() === this.searchTerm.trim();
                        })
                    ) {
                        this.showCreateOption = false;
                    } else {
                        this.showCreateOption = true;
                    }
                });
            }
        );
    }

    async createOption(value) {
        this.loaderStore.suspend();
        const createdItem = await this.actions.createOption(value);
        runInAction(() => {
            this.filteredItems = new DropdownItemsResult(
                [...(this.filteredItems.items?.item || []), createdItem],
                this.filteredItems.page,
                this.filteredItems.recordsPerPage,
                this.filteredItems.totalRecords + 1,
                value
            );
            this.loaderStore.resume();
        });
        await this.filter();
    }

    onChange(option) {
        const index = findIndex(this.selectedOption, (o) => o.value === option.value);
        if (index === -1) {
            const selectedOptions = [...this.selectedOption, option];
            super.onChange(selectedOptions);
            if (this.actions.onSelected) {
                this.actions.onSelected(option.item);
            }
        } else {
            const selectedOptions = filter(this.selectedOption, (o) => o.value !== option.value);
            super.onChange(selectedOptions);
            if (this.actions.onRemoved) {
                this.actions.onRemoved(option.item);
            }
        }
    }
}
