import React from "react";
import ContentLoader from "react-content-loader";

const PortalDetailedActivityLoaderTemplate = (props) => {
    const { cardWidth = 425, row = 4 } = props;

    const items = [];
    let y = 0;

    for (let i = 0; i < row; i++) {
        if (i === 3) {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="6" ry="6" width="80" height="8" />
                    <rect x="0" y={y + 24} rx="6" ry="6" width="60" height="14" />

                    <rect x="88%" y={y} rx="6" ry="6" width="12%" height="38" />
                    <rect x="84%" y={y + 48} rx="6" ry="6" width="16%" height="8" />
                </React.Fragment>
            );
        } else {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="6" ry="6" width="80" height="8" />
                    <rect x="0" y={y + 24} rx="6" ry="6" width="140" height="14" />
                </React.Fragment>
            );
        }

        y += 70;
    }

    return (
        <div className="c-card--primary">
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${cardWidth} ${y - 14}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                {items}
                <CircleLoader />
            </ContentLoader>
        </div>
    );
};

export default PortalDetailedActivityLoaderTemplate;

const CircleLoader = () => {
    return (
        <React.Fragment>
            <circle cx="83%" cy={"30%"} r={70} />
        </React.Fragment>
    );
};
