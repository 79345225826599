import { MrbRootStore } from "mrb/core";
import { baasicAppFactory, BaasicAppStore, BaasicAuthenticationStore } from "mrb/baasic";
import { MrbNotificationProvider } from "mrb/notifications";
import {
    NakiRadioApplicationSectionStore,
    NakiRadioUserStore,
    UserDeviceStore,
    PremiumSubscriptionStore,
    JoyrideViewStore,
    DownloadManagerStore,
} from "common/stores";
import { ContentAccessValidator } from "application/common/content-access";
import { UserContentManagerStore } from "application/common/stores";
import { CoverImageFactory } from "common/services";
import { ContentEncoderSyncStore } from "common/stores";

class RootStore extends MrbRootStore {
    constructor(modulesConfiguration) {
        super(modulesConfiguration, {
            applicationSettings: ApplicationSettings,
            stores: {
                applicationStoreFactory: ({ rootStore }) => new BaasicAppStore(rootStore, baasicAppFactory),
                authenticationStoreFactory: ({ rootStore }) => new BaasicAuthenticationStore(rootStore),
                userStoreFactory: ({ rootStore }) => new NakiRadioUserStore(rootStore),
                applicationMainSectionStoreFactory: ({ rootStore }) => new NakiRadioApplicationSectionStore(rootStore),
                downloadManagerStoreFactory: ({ rootStore }) => new DownloadManagerStore(rootStore),
            },
            providers: {
                notificationProvider: new MrbNotificationProvider(),
            },
        });
    }

    createStores(context, storesConfig) {
        super.createStores(context, storesConfig);

        this.userDeviceStore = new UserDeviceStore(context.rootStore);
        this.premiumSubscriptionStore = new PremiumSubscriptionStore(context.rootStore);
        this.joyrideViewStore = new JoyrideViewStore(context.rootStore);
        this.userContentManagerStore = new UserContentManagerStore(context.rootStore);
        this.contentEncoderSyncStore = new ContentEncoderSyncStore(context.rootStore);
        this.downloadManagerStore = new DownloadManagerStore(context.rootStore);
    }

    createServices(context) {
        this.coverImageFactory = new CoverImageFactory(context.rootStore);
        this.contentAccessValidator = new ContentAccessValidator(context.rootStore);
    }
}

export default RootStore;
