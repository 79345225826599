import { computed, observable, makeObservable, override, runInAction } from "mobx";
import { timeRangeType } from "administration/analytics/constants";
import { dateRangeFactory } from "administration/analytics/models";
import { MrbFilterParameters } from "mrb/core/filter";
import moment from "moment";
import "moment-timezone";

class AnalyticsFilter extends MrbFilterParameters {
    @observable _range;
    @observable from;
    @observable to;
    @observable timeZone;

    @computed get previousCycleFrom() {
        return dateRangeFactory.createPreviousCycleRangeFrom(this._range, this.from, this.to, this.timeZone);
    }

    @computed get previousCycleTo() {
        return dateRangeFactory.createPreviousCycleRangeTo(this._range, this.from, this.to, this.timeZone);
    }

    set range(value) {
        runInAction(() => {
            this._range = value;
            if (this._range !== timeRangeType.custom) {
                const range = dateRangeFactory.create(this._range, this.timeZone);
                this.from = range.from;
                this.to = range.to;
            }
        });
    }

    get range() {
        return this._range;
    }

    constructor() {
        super();
        makeObservable(this);
        this.reset();
    }

    @override
    reset() {
        super.reset();
        this.timeZone = moment.tz.guess();
        this.range = timeRangeType.thisWeek;
    }
}

export default AnalyticsFilter;
