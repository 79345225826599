import { MrbBaseRouteStore } from "mrb/core";
import { PodcastFileStreamService, UserContentService } from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";

class BaseHomePodcastRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.podcastFileStreamService = this.rootStore.createApplicationService(PodcastFileStreamService);
        this.userContentService = this.rootStore.createApplicationService(UserContentService);
    }

    createPodcastCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.podcastFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }

    async getUserDevicePodcast(deviceId, podcastId) {
        const response = await this.userContentService.getUserDevicePodcast(deviceId, podcastId);
        return response.data;
    }

    async getPodcastGroupUserDevicePodcast(deviceId, podcastId) {
        const response = await this.userContentService.getPodcastGroupUserDevicePodcast(deviceId, podcastId);
        return response.data;
    }
}

export default BaseHomePodcastRouteStore;
