import React from 'react'
import ContentLoader from "react-content-loader";

const UserDeviceActivitySummaryLoaderTemplate = (props) => {

    const screenWidth = window.innerWidth;
    let loaderWidth;

    if (screenWidth > 1440) {
        loaderWidth = Math.floor((screenWidth - 760) / 2);
    } else if (screenWidth > 1199) {
        loaderWidth = Math.floor((screenWidth - 760) / 2);
    } else if (screenWidth > 991) {
        loaderWidth = Math.floor((screenWidth - 200) / 2);
    } else if (screenWidth > 767) {
        loaderWidth = Math.floor(screenWidth / 2);
    } else {
        loaderWidth = screenWidth;
    }

    return (
        <ContentLoader
            speed={2}
            width={loaderWidth}
            height={"100%"}
            viewBox={`0 0 ${loaderWidth} ${156}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            <rect x="77%" y={"50"} rx="0" ry="0" width="30" height="16" />
            <rect x="88%" y={"55"} rx="3" ry="3" width="40" height="6" />
            <rect x="77%" y={"80"} rx="0" ry="0" width="30" height="16" />
            <rect x="88%" y={"85"} rx="3" ry="3" width="40" height="6" />
            <circle cx={"38%"} cy="76" r="76" />
        </ContentLoader>
    )
}

export default UserDeviceActivitySummaryLoaderTemplate
