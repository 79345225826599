import React from "react";
import { PropTypes } from "prop-types";

function MrbFieldValidation({ field }) {
    return (
        <p className="c-validation--input">
            {" "}
            <svg className="c-validation--input__icon" fill="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
                <defs />
                <path d="M11.865 10.14L7.15.71a1.286 1.286 0 00-2.3 0L.135 10.14A1.286 1.286 0 001.285 12h9.43a1.286 1.286 0 001.15-1.86zM5.357 4.285a.643.643 0 011.286 0v2.571a.643.643 0 01-1.286 0zm.643 6a.857.857 0 11.857-.857.857.857 0 01-.857.857z" />
            </svg>
            <span className="c-validation--input__label">{field.errorMessage}</span>
        </p>
    );
}

MrbFieldValidation.propTypes = {
    field: PropTypes.object.isRequired,
};

export default MrbFieldValidation;
