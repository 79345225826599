import { action, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { MrbTableViewStore } from "mrb/components";
import { mrbDefaultFormats } from "mrb/common/formatting";
import { dateFormatter } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";
import { MrbModalParams } from "mrb/common/models";
import { MrbQueryUtility } from "mrb/core/filter";
import { isNil } from "lodash";

class AdminProducerAccountPreviewViewStore extends MrbBaseViewStore {
    _id = null;
    _producerId = null;
    @observable revenueGridStore = null;
    @observable balanceGridStore = null;
    @observable.ref summaryData = null;
    @observable.ref assignedProducers = null;
    @observable.ref navigateBack;
    revenueQueryUtility = null;
    balanceQueryUtility = null;
    @observable title = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
        this._id = this.rootStore.routerStore.routerState.params.id;
        this._producerId = this.rootStore.routerStore.routerState.params.producerId;

        this.revenueQueryUtility = new MrbQueryUtility(
            this.rootStore,
            async (filter) => {
                this.revenueFetchResources(filter);
            },
            {
                orderBy: "dateCreated",
                orderDirection: "desc",
                ignoreQueryParams: true,
                disableUpdateQueryParams: true,
            }
        );

        this.balanceQueryUtility = new MrbQueryUtility(
            this.rootStore,
            async (filter) => {
                this.balanceFetchResources(filter);
            },
            {
                orderBy: "dateCreated",
                orderDirection: "desc",
                ignoreQueryParams: true,
                disableUpdateQueryParams: true,
            }
        );
        this.revenueFetchResources = this.revenueFetchResources.bind(this);
        this.balanceFetchResources = this.balanceFetchResources.bind(this);

        this.setRevenueGridStore(
            new MrbTableViewStore(this.rootStore, this.revenueQueryUtility, {
                columns: [
                    {
                        key: "dateCreated",
                        title: "Date",
                        sortable: true,
                        format: (value) =>
                            dateFormatter.format(value, {
                                format: applicationDefaults.formats.dateTime,
                            }),
                    },
                    {
                        key: "stripeTransactionId",
                        title: "Stripe Transaction Id",
                    },
                    {
                        key: "amount",
                        title: "Amount",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.currency,
                            currency: "USD",
                        },
                    },
                ],
            })
        );

        this.setBalanceGridStore(
            new MrbTableViewStore(this.rootStore, this.balanceQueryUtility, {
                columns: [
                    {
                        key: "dateCreated",
                        title: "Date",
                        sortable: true,
                        format: (value) =>
                            dateFormatter.format(value, {
                                format: applicationDefaults.formats.dateTime,
                            }),
                    },
                    {
                        key: "notes",
                        title: "Notes",
                    },
                    {
                        key: "amount",
                        title: "Amount",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.currency,
                            currency: "USD",
                        },
                        cellClass: (item) => {
                            if (item.value > 0) {
                                return "u-type--color--success";
                            } else if (item.value < 0) {
                                return "u-type--color--warning";
                            }
                        },
                    },
                    {
                        key: "producer.name",
                        title: "Producer",
                        cell: (params) => {
                            if (!isNil(params.data.producerId)) {
                                return (
                                    <span
                                        className="u-type--link u-type--link--secondary"
                                        onClick={() => this._producerNavigation(params.data.producerId)}
                                    >
                                        {params.value}
                                    </span>
                                );
                            } else {
                                return null;
                            }
                        },
                    },
                ],
            })
        );

        this.addToAccountBalanceModal = new MrbModalParams(null, {
            addToAccountBalance: this.addToAccountBalance,
        });

        this.updateStripeAccountModal = new MrbModalParams(null, {
            updateStripeAccount: this.updateStripeAccount,
        });
    }

    async revenueFetchResources(filter) {
        this.revenueGridStore.suspend();
        try {
            filter.producerUserAccountIds = this._id;
            const response = await this.fetchRevenueData(filter);
            runInAction(() => {
                this.revenueGridStore.setData(response);
                this.revenueGridStore.resume();
            });
        } catch (err) {
            this.revenueGridStore.resume();
            this.onFetchError(err);
        }
    }

    async balanceFetchResources(filter) {
        this.balanceGridStore.suspend();
        try {
            filter.producerUserAccountIds = this._id;
            const response = await this.fetchBalanceData(filter);
            runInAction(() => {
                this.balanceGridStore.setData(response);
                this.balanceGridStore.resume();
            });
        } catch (err) {
            this.balanceGridStore.resume();
            this.onFetchError(err);
        }
    }

    async onInit() {
        await Promise.all([
            this.revenueQueryUtility.initialize(),
            this.balanceQueryUtility.initialize(),
            this.fetchAccountSummaryData(this._id),
            this.fetchAssignedProducers(this._id),
        ]);
    }

    onFetchError(err) {
        this.rootStore.notificationStore.error(
            "An unexpected error occurred while trying to load data. Please contact the support team.",
            err
        );
    }

    async fetchRevenueData(filter) {
        try {
            return await this.routeStore.getAdminProducerAccountRevenueTransaction(filter);
        } catch (error) {
            this.onFetchError(error);
        }
    }

    async fetchBalanceData(filter) {
        try {
            return await this.routeStore.getAdminProducerAccountBalance(filter);
        } catch (error) {
            this.onFetchError(error);
        }
    }

    async get() {
        try {
            this._producer = await this.routeStore.get(this._producerId);
        } catch (error) {
            this.onFetchError(error);
        }
    }

    @action.bound
    setRevenueGridStore(revenueGridStore) {
        this.revenueGridStore = revenueGridStore;
    }

    @action.bound
    setBalanceGridStore(balanceGridStore) {
        this.balanceGridStore = balanceGridStore;
    }

    @action.bound
    setSummaryData(data) {
        this.summaryData = data;
        this.setTitle(this.summaryData);
    }

    @action.bound
    setAssignedProducers(data) {
        this.assignedProducers = data;
    }

    onDestroy() {
        this.revenueGridStore.destroy();
        this.balanceGridStore.destroy();
    }

    setTitle(data) {
        this.title = data.firstName + " " + data.lastName;
    }

    async fetchAccountSummaryData(accountId) {
        try {
            const data = await this.routeStore.getAdminProducerAccountSummary(accountId);
            this.setSummaryData(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team.",
                err
            );
        }
    }

    async fetchAssignedProducers(accountId) {
        try {
            const data = await this.routeStore.getAdminProducerAccountAssignedProducers(accountId);
            this.setAssignedProducers(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team.",
                err
            );
        }
    }

    @action.bound
    openAddToAccountBalanceModal() {
        this.addToAccountBalanceModal.open({
            producerUserAccount: {
                id: this.summaryData.producerUserAccountId,
                user: {
                    id: this.summaryData.userId,
                    firstName: this.summaryData.firstName,
                    lastName: this.summaryData.lastName,
                },
                accountBalance: this.summaryData.accountBalance,
            },
        });
    }

    @action.bound
    openUpdateStripeAccountModal() {
        this.updateStripeAccountModal.open({
            producerUserAccount: {
                id: this.summaryData.producerUserAccountId,
                stripeAccountId: this.summaryData.stripeAccountId,
            },
            routeStore: this.routeStore,
        });
    }

    @action.bound
    async addToAccountBalance(resource) {
        try {
            resource.producerUserAccountId = this.summaryData.producerUserAccountId;
            await this.routeStore.addAccountBalance(resource);
            this.addToAccountBalanceModal.close();
            await this.refreshAccountBalance();
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to manually add balance. Please contact the support team.",
                err
            );
        }
    }

    @action.bound
    async updateStripeAccount(resource) {
        try {
            resource.id = this.summaryData.producerUserAccountId;
            await this.routeStore.updateStripeAccount(resource);
            this.updateStripeAccountModal.close();
            await this.refreshStripeAccount();
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to update stripe account. Please contact the support team.",
                err
            );
        }
    }

    @action.bound
    async refreshAccountBalance() {
        await Promise.all([this.balanceQueryUtility.fetch(), this.fetchAccountSummaryData(this._id)]);
    }

    @action.bound
    async refreshStripeAccount() {
        await Promise.all([this.fetchAccountSummaryData(this._id)]);
    }
}

export default AdminProducerAccountPreviewViewStore;
