import { BaseService } from "mrb/core";
import { AppHomeSectionApiRouteService } from "common/services";

class AppHomeSectionService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new AppHomeSectionApiRouteService());
    }

    getAll(options, abortSignal = null) {
        const url = this.routeService.getAll(options);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getFeaturedGrid(options, abortSignal = null) {
        const url = this.routeService.getFeaturedGrid(options);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getContent(options, abortSignal = null) {
        const url = this.routeService.getContent(options);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    update(resource, abortSignal = null) {
        const url = this.routeService.update(resource);
        return this.apiClient.put(url, resource, null, abortSignal);
    }

    updateFeaturedGrid(systemType, resource, abortSignal = null) {
        const url = this.routeService.updateFeaturedGrid(systemType, resource);
        return this.apiClient.put(url, resource, null, abortSignal);
    }
}

export default AppHomeSectionService;
