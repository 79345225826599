import { BaseService } from "mrb/core";
import { RssSyncApiRouteService } from "common/services";

class RssSyncService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new RssSyncApiRouteService());
    }

    hlsEncodeAlbums(data, abortSignal = null) {
        const url = this.routeService.hlsEncodeAlbums(data);
        return this.apiClient.post(url, data, null, abortSignal);
    }

    hlsEncodePodcasts(data, abortSignal = null) {
        const url = this.routeService.hlsEncodePodcasts(data);
        return this.apiClient.post(url, data, null, abortSignal);
    }

    hlsEncodeAlbumTracks(data, abortSignal = null) {
        const url = this.routeService.hlsEncodeAlbumTracks(data);
        return this.apiClient.post(url, data, null, abortSignal);
    }

    hlsEncodePodcastEpisodes(data, abortSignal = null) {
        const url = this.routeService.hlsEncodePodcastEpisodes(data);
        return this.apiClient.post(url, data, null, abortSignal);
    }

    getAllRssSyncTasks(abortSignal = null) {
        const url = this.routeService.getAllRssSyncTasks();
        return this.apiClient.get(url, null, abortSignal);
    }

    syncPodcasts(data, abortSignal = null) {
        const url = this.routeService.syncPodcasts(data);
        return this.apiClient.post(url, data, null, abortSignal);
    }

    syncNakiNigunAlbums(data, abortSignal = null) {
        const url = this.routeService.syncNakiNigunAlbums(data);
        return this.apiClient.post(url, data, null, abortSignal);
    }

    syncPodcastsBackground(data, abortSignal = null) {
        const url = this.routeService.syncPodcastsBackground(data);
        return this.apiClient.post(url, data, null, abortSignal);
    }

    syncNakiNigunAlbumsBackground(data, abortSignal = null) {
        const url = this.routeService.syncNakiNigunAlbumsBackground(data);
        return this.apiClient.post(url, data, null, abortSignal);
    }
}

export default RssSyncService;
