import { action, observable, makeObservable, reaction } from "mobx";
import { moduleBuilder } from "../../infrastructure";

class MrbApplicationMainSectionStore {
    @observable isInitialized = false;

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

        reaction(
            () => this.rootStore.authenticationStore.isAuthenticated,
            (isAuthenticated) => {
                if (!isAuthenticated) {
                    this.setIsInitialized();
                    this.rootStore.menuStore.setMenu([]);
                }
            }
        );

        this.initialize = this.initialize.bind(this);
    }

    async initialize() {
        if (!this.isInitialized) {
            this.rootStore.authenticationStore.resetSignInRedirect();
            this.buildMenu();
            this.setIsInitialized(true);
            return Promise.resolve();
        }
    }

    buildMenu(modules) {
        const menu = moduleBuilder.buildMenus(
            this.rootStore.configuration.menus,
            { rootStore: this.rootStore },
            modules
        );
        this.rootStore.menuStore.setMenu(menu);
    }

    @action.bound
    setIsInitialized(isInitialized) {
        this.isInitialized = isInitialized;
    }

    async logout() {
        await this.rootStore.authenticationStore.logout();
    }
}

export default MrbApplicationMainSectionStore;
