import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

function MrbTableActionColumn({
    tableStore,
    ActionsComponent,
    actions,
    data,
    t = (i) => i,
}) {
    // Don't display actions for group data
    if (!data) {
        return null;
    }
    if (ActionsComponent) {
        return React.createElement(ActionsComponent, {
            tableStore,
            actions,
            item: data,
            t,
        });
    }

    return (
        <span>
            {map(actions, (action, idx) => {
                const label = t(action.label);
                return (
                    <button
                        key={idx}
                        type="button"
                        onClick={(event) => {
                            //prevent row click event
                            event.preventDefault();
                            event.stopPropagation();
                            action.onClick(data);
                        }}
                        aria-label={action.label}
                        {...action.buttonProps}
                    >
                        {action.icon ? (
                            <i
                                className={`${action.icon} align--v--middle ${
                                    idx !== 0 ? " spc--left--sml " : ""
                                }`}
                                title={label}
                            />
                        ) : (
                            label
                        )}
                    </button>
                );
            })}
        </span>
    );
}

MrbTableActionColumn.propTypes = {
    tableStore: PropTypes.object.isRequired,
    ActionsComponent: PropTypes.any,
    actions: PropTypes.object,
    data: PropTypes.object,
    t: PropTypes.func,
};

export default MrbTableActionColumn;
