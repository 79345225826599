import { observable, makeObservable, override } from "mobx";
import { MrbFilterParameters } from "mrb/core/filter";

class ProducerPayoutUserEventFilter extends MrbFilterParameters {
    @observable userId;
    @observable deviceIds;
    @observable from;
    @observable to;

    constructor() {
        super();
        makeObservable(this);
        this.reset();
    }

    @override
    reset() {
        super.reset();
        this.userId = null;
        this.deviceIds = null;
        this.from = null;
        this.to = null;
    }
}

export default ProducerPayoutUserEventFilter;
