import { action, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { MrbModalParams } from "mrb/common/models";

class ActivateTrialViewStore extends MrbBaseViewStore {
    @observable isSubmitDisabled = false;
    @observable.ref subscriptionPlan = null;
    planId = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
        this.planId = rootStore.routerStore.routerState.params.planId;

        this.trialActivatedModal = new MrbModalParams(null, {
            onClickExplorePremium: this.goToPremiumContent,
        });
    }

    goToPremiumContent = () => {
        this.rootStore.routerStore.goTo("master.application.home.list", null, {
            isPremium: true,
        });
    };

    onInit() {
        return this.fetchSubscriptionPlan();
    }

    async fetchSubscriptionPlan() {
        try {
            const subscriptionPlan = await this.routeStore.getSubscriptionPlan(this.planId);
            this.setSubscriptionPlan(subscriptionPlan);
        } catch (err) {
            this.rootStore.notificationStore.error("Unexpected error occurred. Please contact the support team.", err);
        }
    }

    @action.bound
    setSubscriptionPlan(plan) {
        this.subscriptionPlan = plan;
    }

    @action.bound
    activateTrial() {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Start trial?",
            description: "You won't be charged for the evaluation period.",
            yesLabel: "Start",
            onConfirm: async () => {
                try {
                    this.setIsSubmitDisabled(true);
                    await this.rootStore.premiumSubscriptionStore.activateTrial(this.planId);
                    this.trialActivatedModal.open({
                        trialEndDate: this.rootStore.premiumSubscriptionStore.subscription.trialPeriod.trialEndDate,
                        subscriptionPlan: this.subscriptionPlan.name,
                    });
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "Unexpected error occurred while trying to activate trial period. Please contact the support team.",
                        err
                    );
                } finally {
                    this.setIsSubmitDisabled(false);
                }
            },
        });
    }

    @action.bound
    setIsSubmitDisabled(value) {
        this.isSubmitDisabled = value;
    }
}

export default ActivateTrialViewStore;
