import { makeObservable, observable, action, computed, reaction } from "mobx";
import { forEach, filter, find } from "lodash";
import JSZip from "jszip";
import { localizationService } from "common/localization";

class DownloadManagerStore {
    downloadStatus = {
        new: "new",
        downloading: "downloading",
        finished: "finished",
        canceled: "canceled",
        failed: "failed",
    };

    @observable selectedViewValue = 1;

    @observable.ref items = [];

    zip = new JSZip();
    nameCounter = 0;

    @computed
    get currentlyDownloadingItem() {
        return find(this.items, (item) => item.status === this.downloadStatus.downloading);
    }

    @computed
    get queuedItems() {
        return filter(this.items, (item) => item.status === this.downloadStatus.new);
    }

    @computed
    get finishedItems() {
        return filter(this.items, (item) => item.status === this.downloadStatus.finished);
    }

    @computed
    get canceledItems() {
        return filter(this.items, (item) => item.status === this.downloadStatus.canceled);
    }

    @computed
    get failedItems() {
        return filter(this.items, (item) => item.status === this.downloadStatus.failed);
    }

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
        this.downloadItem = this.downloadItem.bind(this);
        this.initializeListeners = this.initializeListeners.bind(this);

        reaction(
            () => this.items,
            () => {
                this.onItemsChanged();
            }
        );

        reaction(
            () => this.rootStore.authenticationStore.isAuthenticated,
            (isAuthenticated) => {
                if (!isAuthenticated) {
                    try {
                        if (this.queuedItems && this.queuedItems.length > 0) {
                            forEach(this.queuedItems, (item) => {
                                item.status = this.downloadStatus.canceled;
                            });
                        }
                        if (this.currentlyDownloadingItem && this.currentlyDownloadingItem.xhr) {
                            this.currentlyDownloadingItem.xhr.abort();
                        }
                        this.items = [];
                    } catch (err) {
                        this.rootStore.notificationStore.error(
                            "An unexpected error occurred. Unable to close the window.",
                            err
                        );
                    }
                }
            }
        );
    }

    @action.bound
    addItemToQueue(item) {
        item.status = this.downloadStatus.new;
        item.index = this.items.length;
        this.items.push(item);
        this.items = [...this.items];
    }

    @action.bound
    addItemsToQueue(items) {
        forEach(items, (item, index) => {
            item.status = this.downloadStatus.new;
            item.index = this.items.length + index;
        });
        this.items.push(...items);
        this.items = [...this.items];
    }

    downloadItem(item) {
        item.status = this.downloadStatus.downloading;
        this.items = [...this.items];
        const xhr = new XMLHttpRequest();
        item.xhr = xhr;
        this.initializeListeners(item.xhr);
        item.xhr.open("GET", item.downloadUrl);
        item.xhr.responseType = "blob";
        item.xhr.send();
    }

    initializeListeners(xhr) {
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                const blob = new Blob([xhr.response], { type: "audio/mp3" });
                const sanitizedTitle = this.currentlyDownloadingItem.title?.replace(/[\\/:*?"<>|]/g, " ")?.trim();
                var defaultName;
                if (!sanitizedTitle) {
                    if (this.nameCounter === 0) {
                        defaultName = "download";
                    } else {
                        defaultName = `download (${this.nameCounter})`;
                    }
                    this.nameCounter += 1;
                }

                this.zip.file(`${sanitizedTitle || defaultName}.mp3`, blob);
                this.currentlyDownloadingItem.status = this.downloadStatus.finished;
            } else {
                this.currentlyDownloadingItem.status = this.downloadStatus.failed;
            }
            this.items = [...this.items];
        };

        xhr.onerror = () => {
            this.currentlyDownloadingItem.status = this.downloadStatus.failed;
            this.items = [...this.items];
        };

        xhr.onabort = () => {
            this.currentlyDownloadingItem.status = this.downloadStatus.canceled;
            this.items = [...this.items];
        };

        xhr.onprogress = (event) => {
            this.setDownloadProgress((event.loaded / event.total) * 100);
            // event.loaded returns how many bytes are downloaded
            // event.total returns the total number of bytes
            // event.total is only available if server sends `Content-Length` header
        };
    }

    onItemsChanged() {
        if (this.currentlyDownloadingItem != null) {
            return;
        }
        if (this.queuedItems != null && this.queuedItems.length > 0) {
            this.downloadItem(this.queuedItems[0]);
        } else {
            if (Object.keys(this.zip.files).length === 1 && this.queuedItems.length === 0) {
                const fileName = Object.keys(this.zip.files)[0];
                this.zip.generateAsync({ type: "blob" }).then((blob) => {
                    this.downloadSingleItem(blob, fileName.split(".mp3")[0]);
                });
                this.zip = new JSZip();
                return;
            } else {
                this.downloadZip();
            }
        }
    }

    downloadSingleItem(blob, fileName) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileName}.mp3`;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    downloadZip() {
        if (Object.keys(this.zip.files).length === 0) {
            return;
        }
        const localZip = this.zip.clone();
        this.zip = new JSZip();
        this.nameCounter = 0;

        localZip.generateAsync({ type: "blob" }).then((blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "downloads.zip";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(url);
            document.body.removeChild(a);
        });
    }

    @action.bound
    cancelDownload(index) {
        var item = find(this.items, (item) => item.index === index);
        if (item.status === this.downloadStatus.downloading && item.xhr) {
            item.xhr.abort();
        } else {
            item.status = this.downloadStatus.canceled;
        }
        this.items = [...this.items];
    }

    @action.bound
    retryDownload(index) {
        var item = find(this.items, (item) => item.index === index);
        item.status = this.downloadStatus.new;
        this.items = [...this.items];
    }

    @action.bound
    setDownloadProgress(value) {
        this.currentlyDownloadingItem.downloadProgress = value;
        this.items = [...this.items];
    }

    @action.bound
    setSelectedViewValue(value) {
        this.selectedViewValue = value;
    }

    @action.bound
    closeWindow() {
        if (this.queuedItems.length === 0 && this.currentlyDownloadingItem == null) {
            this.items = [];
        } else {
            this.rootStore.confirmDialogStore.showConfirm({
                message: localizationService.t("ADMINISTRATION.DOWNLOAD_MANAGER.CLOSE_MODAL.TITLE"),
                onConfirm: () => {
                    try {
                        forEach(this.queuedItems, (item) => {
                            item.status = this.downloadStatus.canceled;
                        });

                        if (this.currentlyDownloadingItem.xhr) {
                            this.currentlyDownloadingItem.xhr.abort();
                        }
                        this.items = [];
                    } catch (err) {
                        this.rootStore.notificationStore.error(
                            "An unexpected error occurred. Unable to close the window.",
                            err
                        );
                    }
                },
                yesLabel: localizationService.t("ADMINISTRATION.DOWNLOAD_MANAGER.CLOSE_MODAL.YES_LABEL"),
                noLabel: localizationService.t("ADMINISTRATION.DOWNLOAD_MANAGER.CLOSE_MODAL.NO_LABEL"),
                description: localizationService.t("ADMINISTRATION.DOWNLOAD_MANAGER.CLOSE_MODAL.DESCRIPTION"),
            });
        }
    }
}

export default DownloadManagerStore;
