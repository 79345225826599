import { MrbBaseRouteStore } from "mrb/core";
import { PodcastAnalyticsService } from "common/services";

class PodcastActivityRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PodcastAnalyticsService);
    }

    async getPodcastActivity(stationId, filter, abortController) {
        const response = await this.service.getPodcastActivity(stationId, filter, abortController);
        return response.data;
    }

    async getPodcastSummary(stationId, filter, abortController) {
        const response = await this.service.getPodcastSummary(stationId, filter, abortController);
        return response.data;
    }
}

export default PodcastActivityRouteStore;
