import { BaseApiRouteService } from "mrb/core";

class UserTorahAnytimeCollectionApiRouteService extends BaseApiRouteService {
    constructor() {
        super("user-torah-anytime-collection");
    }

    find(filter) {
        return super.find(`${this.base}/{?podcastId,searchQuery,page,rpp,sort,embed,fields}`, filter);
    }

    findWthDeviceSettings(deviceId, filter) {
        return super.find(`${this.base}/device/{deviceId}/{?podcastId,searchQuery,page,rpp,sort,embed,fields}`, {
            deviceId: deviceId,
            ...filter,
        });
    }

    updateCollectionsTurnedOnStatus(resources, value) {
        return super.update(`${this.base}/turned-on{?value}`, {
            resources: resources,
            value: value,
        });
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?embed,fields}`, id, options);
    }

    create(resource) {
        return super.create(this.base, resource);
    }

    update(resource) {
        return super.update(`${this.base}/{id}`, resource);
    }

    delete(resource) {
        return super.delete(`${this.base}/{id}`, resource);
    }
}

export default UserTorahAnytimeCollectionApiRouteService;
