import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { PremiumTrialExpiration } from "application/payment/components";
import { isNil } from "lodash";

function PremiumInfoTrialWidgetTemplate({ subscription, t }) {
    return (
        <div className="c-premium__info">
            <div className="c-premium__header">
                <i className="u-icon u-icon--med u-icon--premium u-mar--right--micro"></i>
                <p className="u-type--base u-type--wgt--bold">
                    {isNil(subscription.nextBillingDate)
                        ? t("APPLICATION.PREMIUM.TRIAL_WIDGET.TITLE")
                        : t("APPLICATION.PREMIUM.PREMIUM_STARTS.TITLE")}
                </p>
            </div>

            <p className="u-type--sml u-mar--bottom--sml">
                <PremiumTrialExpiration subscription={subscription} t={t} />{" "}
                {isNil(subscription.nextBillingDate)
                    ? t("APPLICATION.PREMIUM.TRIAL_WIDGET.DESCRIPTION_TAKE_ACTION")
                    : t("APPLICATION.PREMIUM.PREMIUM_STARTS.DESCRIPTION_START_OF_BILLING")}
            </p>
            <MrbRouterLink
                className="c-btn c-btn--sml c-btn--full c-btn--primary"
                routeName="master.application.premium.info"
            >
                {t("APPLICATION.PREMIUM.TRIAL_WIDGET.MORE_INFO")}
            </MrbRouterLink>
        </div>
    );
}

PremiumInfoTrialWidgetTemplate.propTypes = {
    subscription: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default PremiumInfoTrialWidgetTemplate;
