import { BaseApiRouteService } from "mrb/core";

class WeekDayApiRouteService extends BaseApiRouteService {
    constructor() {
        super("lookups/week-days");
    }

    get() {
        return super.get(this.base);
    }
}

export default WeekDayApiRouteService;
