import { BasePortalDetailedActivityViewStore } from "administration/analytics/stores";

class DeviceActivityViewStore extends BasePortalDetailedActivityViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            chartLabel: "Active devices",
        });
        this.routeStore = routeStore;
    }

    getActivityChartData(filter, abortController) {
        return this.routeStore.getDeviceActivity(filter, abortController);
    }

    fetchSummary(params, abortController) {
        return this.routeStore.getDeviceActivitySummary(params, abortController);
    }
}

export default DeviceActivityViewStore;
