import { BaseApiRouteService } from "mrb/core";

class ProducerAnalyticsApiRouteService extends BaseApiRouteService {
    constructor() {
        super("analytics/producers");
    }

    findProducerReportPayoutUserEvents(filter) {
        return super.find(
            `${this.base}/reports/payout-user-events/{?userId,deviceIds,from,to,page,rpp,sort,embed,fields}`,
            filter
        );
    }

    findProducerReports(filter) {
        return super.find(`${this.base}/reports/{?producerIds,from,to,page,rpp,sort,embed,fields}`, filter);
    }

    findProducerPaymentSummary(filter) {
        return super.find(`${this.base}/summary{?searchQuery,monthOfYear,page,rpp,sort}`, filter);
    }

    getProducerPaymentSummary(producerId, options) {
        return super.get(`${this.base}/{id}/reports/summary{?monthOfYear}`, producerId, options);
    }

    getProducerSummary(producerId) {
        return super.get(`${this.base}/{id}/summary`, producerId);
    }

    getProducerMostPopularContent(producerId, options) {
        return super.get(`${this.base}/{id}/top-content{?page,rpp}`, producerId, options);
    }

    findProducerReportItems(filter) {
        return super.find(
            `${this.base}/{producerId}/reports/items{?searchQuery,monthOfYear,page,rpp,sort,embed,fields}`,
            filter
        );
    }
}

export default ProducerAnalyticsApiRouteService;
