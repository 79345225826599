import React from "react";

function MrbTableLoadingOverlay() {
    return (
        <div className="loader--overlay">
            <div className="lds-ripple center">
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default MrbTableLoadingOverlay;
