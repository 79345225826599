import { createRouterState } from "mobx-state-router";
import { joinPaths, getSkipRoutePrefix, getBreadcrumbTitle } from "./";
import { filter, forEach, isString, isArray, isEqual, isFunction, isNil, map, reduce, startsWith } from "lodash";

const eventHooks = {
    beforeEnter: "beforeEnter",
    onEnter: "onEnter",
    beforeExit: "beforeExit",
    onExit: "onExit",
};

class StateRoute {
    constructor(route) {
        this.name = route.name;
        this.parent = route.parent;
        this.isPublic = route.isPublic || false;
        // Flat all parent routes in tree order
        const parentOptions = flatParentOptions(route);
        const routeTree = [...parentOptions, route];

        this.pattern = reduce(routeTree, (prev, current) => joinPaths(prev, current.pattern), "");
        this.authorization = filter(
            map(routeTree, (p) => p.authorization),
            (f) => !isNil(f) && f !== ""
        );

        const optionLength = routeTree.length;
        this.data = reduce(
            routeTree,
            (prev, current, idx) => {
                if (current.data) {
                    if (current.data.title) {
                        prev.title = current.data.title;
                    }

                    if (current.data.back) {
                        prev.back = current.data.back;
                    }

                    if (current.data.crumbs) {
                        const crumbs = isArray(current.data.crumbs) ? current.data.crumbs : [current.data.crumbs];
                        prev.crumbs = [...prev.crumbs, ...crumbs];
                    } else if (optionLength - 1 === idx) {
                        prev.crumbs = [
                            ...prev.crumbs,
                            {
                                title: getBreadcrumbTitle(route.name),
                                route: route.name,
                            },
                        ];
                    }

                    // NOTICE: add more properties here if you want to support them in data property
                    if (current.data.type) {
                        prev.type = current.data.type;
                    }
                }

                return prev;
            },
            {
                crumbs: [],
            }
        );

        this.component = this.createRouteTree(route, parentOptions);
        this.onExit = (fromState, toState, router) => {
            const {
                rootStore: { routerStore },
            } = router.options;
            return this.fireEvent(eventHooks.onExit, fromState, toState, routerStore, routeTree);
        };
        this.onEnter = (fromState, toState, router) => {
            const {
                rootStore: { routerStore },
            } = router.options;
            return this.fireEvent(eventHooks.onEnter, fromState, toState, routerStore, routeTree);
        };
        this.beforeExit = (fromState, toState, router) => {
            const {
                rootStore: { routerStore },
            } = router.options;
            return this.fireEvent(eventHooks.beforeExit, fromState, toState, routerStore, routeTree);
        };
        this.beforeEnter = async (fromState, toState, router) => {
            const { rootStore } = router.options;
            if (fromState.routeName !== toState.routeName) {
                rootStore.mainViewStore.setNavigationOptions(null);
            } else if (isEqual(fromState.params, toState.params)) {
                // if same state and same params don't initiaze any beforeEnter actions
                return Promise.resolve();
            }

            try {
                await this.fireEvent(eventHooks.beforeEnter, fromState, toState, rootStore.routerStore, routeTree);

                await rootStore.routerStore.routeChange({
                    fromState,
                    toState,
                    options: {
                        authorization: this.authorization,
                        pattern: this.pattern,
                        data: this.data,
                        isPublic: this.isPublic,
                    },
                });
            } catch (ex) {
                return ex;
            }

            return Promise.resolve();
        };
    }

    createRouteTree = (route, parentOptions) => {
        let node = null;
        let root = null;
        if (parentOptions && parentOptions.length > 0) {
            //parentOptions = parentOptions.reverse();
            forEach(parentOptions, (option) => {
                if (option.layouts && option.layouts.length > 0) {
                    forEach(option.layouts, (layout) => {
                        if (!node) {
                            node = {
                                routeName: option.name,
                                component: layout,
                            };
                            root = node;
                        } else {
                            node.childRoute = {
                                routeName: option.name,
                                component: layout,
                            };
                            node = node.childRoute;
                        }
                    });
                }
            });
        }
        if (!node) {
            node = {
                routeName: route.name,
                component: route.component,
            };
            root = node;
        } else {
            node.childRoute = {
                routeName: route.name,
                component: route.component,
            };
        }
        return root;
    };

    // executes all parents and own event in order starting from initial parent
    // NOTE: this method is initiated when:
    // 1. route name is changed - in this case parts that changed are compared and only those
    //         parts are fired. e.g. master.user.edit -> master.user.list
    //         means that only master.user.list event will be fired (master and master.user will be skipped since they are same as on previous route)
    // 2. custom condition has been defined for the route - condition is always inherited from
    //        parent route and it overrides rule 1. if defined.
    //        It can be defiend on route as hookCondition: (fromState, toState, routerState) => { return bool; }
    //        If hookCondition returns false on master but true on master.user everything beneath master.user (including master.user) will be initiated
    fireEvent = async (name, fromState, toState, routerStore, parentOptions) => {
        const routeChanged = fromState.routeName !== toState.routeName;

        let hookCondition = false;
        for (let i = 0; i < parentOptions.length; i++) {
            const routeOptions = parentOptions[i];
            const hook = routeOptions[name];

            if (routeOptions.hookCondition) {
                hookCondition = routeOptions.hookCondition(fromState, toState, routerStore);
            }

            if (routeChanged || hookCondition) {
                const skipRoutePrefix = getSkipRoutePrefix(fromState, toState);
                if (hookCondition || !skipRoutePrefix || !startsWith(skipRoutePrefix, routeOptions.name)) {
                    try {
                        await this.triggerRouteStoreLifecycle(
                            name,
                            routerStore.rootStore,
                            routeOptions,
                            fromState,
                            toState
                        );
                    } catch (ex) {
                        if (process.env.NODE_ENV === "development") {
                            console.log(ex); // eslint-disable-line
                        }
                        if (isString(ex)) {
                            return Promise.reject(createRouterState(ex));
                        }
                        return Promise.reject(ex);
                    }

                    // if there is no hook defined for route options, continue
                    if (isNil(hook)) continue;

                    try {
                        await hook(fromState, toState, routerStore);
                    } catch (ex) {
                        if (process.env.NODE_ENV === "development") {
                            console.log(ex); // eslint-disable-line
                        }
                        return Promise.reject(ex);
                    }
                }
            }
        }

        return Promise.resolve();
    };

    triggerRouteStoreLifecycle = async (eventName, rootStore, routeOptions, fromState, toState) => {
        if (eventName === eventHooks.beforeEnter) {
            if (routeOptions.store) {
                const routeStore = rootStore.createCurrentRouteStore(
                    routeOptions.name,
                    routeOptions.store,
                    routeOptions.parent.name
                );
                if (routeStore.onBeforeEnter && isFunction(routeStore.onBeforeEnter)) {
                    await routeStore.onBeforeEnter(fromState, toState);
                }
            }
        } else if (eventName === eventHooks.onEnter) {
            const routeStore = rootStore.getCurrentRouteStore(routeOptions.name);
            if (routeStore && routeStore.onEnter && isFunction(routeStore.onEnter)) {
                await routeStore.onEnter(fromState, toState);
            }
        } else if (eventName === eventHooks.beforeExit) {
            const routeStore = rootStore.getCurrentRouteStore(routeOptions.name);
            if (routeStore && routeStore.onBeforeExit && isFunction(routeStore.onBeforeExit)) {
                await routeStore.onBeforeExit(fromState, toState);
            }
        } else if (eventName === eventHooks.onExit) {
            const routeStore = rootStore.getCurrentRouteStore(routeOptions.name);
            if (routeStore && routeStore.onExit && isFunction(routeStore.onExit)) {
                await routeStore.onExit(fromState, toState);
            }
            rootStore.removeCurrentRouteStore(routeOptions.name);
        }
    };
}

function flatParentOptions(route) {
    const parentOptions = [];
    flatParentRoute(route, parentOptions);
    return parentOptions;
}

function flatParentRoute(route, parentOptions) {
    if (route.parent) {
        flatParentRoute(route.parent, parentOptions);
        parentOptions.push(route.parent);
    }
}

export default StateRoute;
