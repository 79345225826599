import { BasePortalDetailedActivityViewStore } from "administration/analytics/stores";

class PodcastActivityViewStore extends BasePortalDetailedActivityViewStore {
    podcastId = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            chartLabel: "Playbacks",
        });
        this.routeStore = routeStore;

        this.podcastId = rootStore.routerStore.routerState.params.id;
    }

    getActivityChartData(filter, abortController) {
        return this.routeStore.getPodcastActivity(this.podcastId, filter, abortController);
    }

    fetchSummary(params, abortController) {
        params.embed = "podcast";
        return this.routeStore.getPodcastSummary(this.podcastId, params, abortController);
    }
}

export default PodcastActivityViewStore;
