import * as uritemplate from "uritemplate";

function createBaasicAuthenticatedMediaVaultUrl(
    rootStore,
    imageUrl,
    options = {}
) {
    const baseUrl = rootStore.application.baasic.getApiUrl();
    const token = rootStore.authenticationStore.token;
    const params = {
        ...options,
        token: token ? token.tokenUrl : null,
        ttype: token ? token.type : null,
    };
    const url = `${baseUrl}${imageUrl}{?token,ttype,height,width}`;
    return uritemplate.parse(url).expand(params);
}

export default createBaasicAuthenticatedMediaVaultUrl;
