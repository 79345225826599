import { BaseService } from "mrb/core";
import { PortalAnalyticsApiRouteService } from "common/services";

class PortalAnalyticsService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new PortalAnalyticsApiRouteService());
    }

    getUserPortalStatsSummary(userId, abortSignal = null) {
        const url = this.routeService.getUserPortalStatsSummary(userId);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPortalAnalytics(filter, abortSignal = null) {
        const url = this.routeService.getPortalAnalytics(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getRegisteredUsersActivity(filter, abortSignal = null) {
        const url = this.routeService.getRegisteredUsersActivity(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getRegisteredUsersSummary(filter, abortSignal = null) {
        const url = this.routeService.getRegisteredUsersSummary(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getRegisteredUserDevicesActivity(filter, abortSignal = null) {
        const url = this.routeService.getRegisteredUserDevicesActivity(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getRegisteredUserDevicesSummary(filter, abortSignal = null) {
        const url = this.routeService.getRegisteredUserDevicesSummary(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getConnectedDevicesActivity(filter, abortSignal = null) {
        const url = this.routeService.getConnectedDevicesActivity(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getConnectedDevicesSummary(filter, abortSignal = null) {
        const url = this.routeService.getConnectedDevicesSummary(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPremiumSubscriptionsActivity(filter, abortSignal = null) {
        const url = this.routeService.getPremiumSubscriptionsActivity(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPremiumSubscriptionsSummary(filter, abortSignal = null) {
        const url = this.routeService.getPremiumSubscriptionsSummary(filter);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default PortalAnalyticsService;
