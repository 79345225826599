import {
    action,
    computed,
    observable,
    makeObservable,
    runInAction,
} from "mobx";

class DropdownItemsResult {
    @observable.ref items = [];
    @observable pageNumber = 1;
    totalRecords = 0;
    searchPhrase = null;
    pageSize = 15;

    @computed get isEmpty() {
        return this.items.length === 0;
    }

    @computed get totalPages() {
        return Math.ceil(this.totalRecords / this.pageSize);
    }

    constructor(
        items,
        pageNumber,
        pageSize,
        totalRecords,
        searchPhrase = null
    ) {
        makeObservable(this);
        runInAction(() => {
            this.items = items;
            this.pageNumber = pageNumber;
            this.pageSize = pageSize;
            this.totalRecords = totalRecords;
            this.searchPhrase = searchPhrase;
        });
    }

    @action.bound
    appendNextPage(items, pageNumber) {
        this.items = [...this.items, ...items];
        this.pageNumber = pageNumber;
    }
}

export default DropdownItemsResult;
