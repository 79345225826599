import React from "react";
import { PropTypes } from "prop-types";
import { mergeOrOverrideCss } from "baasic-react-bootstrap/src/common";

function HomeEmptyStateTemplate({ t, classNameOverride, classNameExtend }) {
    return (
        <div
            className={mergeOrOverrideCss(
                "c-empty-state--no-results",
                classNameOverride,
                classNameExtend
            )}
        >
            <i className="u-icon--welcome u-icon--welcome--sml u-mar--bottom--sml"></i>
            <h3>No results found</h3>
            <p>Try adjusting your search to find what you are looking for.</p>
        </div>
    );
}

HomeEmptyStateTemplate.propTypes = {
    t: PropTypes.func.isRequired,
    classNameExtend: PropTypes.string,
    classNameOverride: PropTypes.string,
};

export default HomeEmptyStateTemplate;
