import React from "react";
import { ProducerPaymentReportCellTemplate } from "themes/common/components/producer";
import { ProducerPayoutUserEventListViewStore } from "administration/producer/stores";
import { setCurrentView } from "mrb/core";
import { observer } from "mobx-react";

@setCurrentView(
    (rootStore, componentProps) => new ProducerPayoutUserEventListViewStore(rootStore, componentProps),
    "producerPayoutUserEventListViewStore"
)
@observer
class ProducerPaymentReportCell extends React.Component {
    render() {
        return <ProducerPaymentReportCellTemplate {...this.props} />;
    }
}

export default ProducerPaymentReportCell;
