import React, { useState, useCallback } from "react";
import ContentLoader from "react-content-loader";

const UploadedImageLoaderTemplate = (props) => {
    const [loaderWidth, setLoaderWidth] = useState(0);

    let cardHeight;
    switch (props.size) {
        case "c-upload__upload--sml":
            cardHeight = 235;
            break;
        case "c-upload--tny":
            cardHeight = 300;
            break;
        case "c-upload--base":
            cardHeight = 450;
            break;
        default:
            cardHeight = 360;
            break;
    }

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div className="u-display--flex u-display--flex--jc--center u-mar--bottom--tny" ref={ref}>
            <ContentLoader
                speed={2}
                width={loaderWidth}
                height={cardHeight}
                viewBox={`0 0 ${loaderWidth} ${cardHeight}`}
                backgroundColor="#2d2f34"
                foregroundColor="#2b2b2b"
                {...props}
            >
                <rect x="0" y="0" rx="6" ry="6" width="100%" height="100%" />
            </ContentLoader>
        </div>
    );
};

export default UploadedImageLoaderTemplate;
