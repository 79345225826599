import React from "react";
import { MrbButton } from "../../button";
import { map, isEmpty, isFunction, flatMap } from "lodash";

function renderActions(actions, t) {
    if (!actions || isEmpty(actions)) {
        return null;
    }

    const components = map(actions, (action, actionName) => {
        if (actionName === "edit") {
            action.className = action.className || "c-btn c-btn--base c-btn--primary";
            action.label = action.label || "Edit";
        } else if (actionName === "cancel") {
            action.className = action.className || "c-btn c-btn--base c-btn--ghost";
            action.label = action.label || "Cancel";
        }
        return (
            <MrbButton
                type="button"
                key={actionName}
                classNameOverride={action.className}
                onClick={action.onClick}
                icon={action.icon}
                label={action.label}
                disabled={isFunction(action.disabled) && action.disabled()}
                t={t}
                authorization={action.authorization}
            />
        );
    });

    const result = flatMap(components, (value, index, array) => (array.length - 1 !== index ? [value, " "] : value));
    return result;
}

export default renderActions;
