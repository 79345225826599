import React from "react";
import { PropTypes } from "prop-types";
import { MrbContentLoader } from "mrb/components/loader";
import { currencyFormatter } from "mrb/common/formatting";
import { observer } from "mobx-react";
import { AdminProducerSummaryLoader } from "administration/producer/components";
import { isNil } from "lodash";
import { StripeAccountLink } from "common/components/stripe";

function AdminProducerAccountSummaryPreviewTemplate({ adminProducerAccountPreviewViewStore, t }) {
    const { summaryData, loaderStore } = adminProducerAccountPreviewViewStore;

    return (
        <div className="u-mar--bottom--sml">
            <h1 className="u-type--base u-mar--bottom--sml">Summary</h1>
            <div className="c-card--primary c-card--med">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <AdminProducerSummaryLoader />}>
                    {() => (
                        <React.Fragment>
                            <div className="u-animation--fadeIn">
                                {summaryData && (
                                    <React.Fragment>
                                        <div className="u-animation--fadeIn">
                                            <div className="row u-mar--bottom--sml">
                                                <div className="col col-sml-7">
                                                    <p className="u-type--sml u-type--color--opaque">User Email</p>
                                                    <p className="u-display--flex u-display--flex--ai--center">
                                                        {summaryData.email}
                                                    </p>
                                                </div>
                                            </div>

                                            {!isNil(summaryData.stripeAccountId) && (
                                                <div className="row u-mar--bottom--sml">
                                                    <div className="col col-sml-7">
                                                        <p className="u-type--sml u-type--color--opaque">
                                                            Stripe Account
                                                        </p>
                                                        <p className="u-display--flex u-display--flex--ai--center">
                                                            <StripeAccountLink
                                                                accountId={summaryData.stripeAccountId}
                                                                t={t}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col col-sml-7">
                                                    <p className="u-type--sml u-type--color--opaque">Account Balance</p>
                                                    <div className="c-dashboard__count--increase">
                                                        <p className="c-dashboard__count--lrg">
                                                            {currencyFormatter.format(
                                                                summaryData.accountBalance
                                                                    ? summaryData.accountBalance
                                                                    : 0,
                                                                {
                                                                    currency: "USD",
                                                                }
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
        </div>
    );
}

AdminProducerAccountSummaryPreviewTemplate.propTypes = {
    adminProducerAccountPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(AdminProducerAccountSummaryPreviewTemplate);
