import { MrbBaseField } from "mrb/core/form";
import { localizedValidatorService, localizationService } from "common/localization";
import { trim } from "lodash";

class LocalizedField extends MrbBaseField {
    initialSetup = null;
    _callbackId = null;

    constructor(props) {
        super(props);

        this._callbackId = localizedValidatorService.registerCallback("onMessageSourceChange", async () => {
            if (this.showError) {
                await this.validate();
                this.showErrors(!this.isValid);
            }
        });
    }

    preprocessErrorMessage(error) {
        return error
            ? error.replace(
                  /\[[^\]]+\]/g,
                  function (
                      // eslint-disable-next-line
                      match,
                      value,
                      a,
                      b,
                      c,
                      str
                  ) {
                      return localizationService.t(trim(match, "[]"));
                  }
              )
            : null;
    }

    destroy() {
        localizedValidatorService.removeCallback(this._callbackId);
    }
}

export default LocalizedField;
