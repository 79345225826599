import { action, observable, makeObservable } from "mobx";

class StripeField {
    @observable isCompleted = false;
    @observable.ref error = null;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setError(error) {
        this.error = error;
    }

    @action.bound
    onStripeFieldChange(event) {
        this.setIsCompleted(event.complete);
        this.setError(event.error);
    }

    @action.bound
    setIsCompleted(isCompleted) {
        this.isCompleted = isCompleted;
    }

    resetValidation() {
        this.setError(null);
    }

    @action.bound
    reset() {
        this.resetValidation();
        this.setIsCompleted(false);
    }
}

export default StripeField;
