import { action, makeObservable, observable, runInAction } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { httpStatusCodes } from "mrb/core/constants";
import { MrbGridViewStore } from "mrb/components/grid";
import { MrbSimpleSelectStore, MrbQuerySelectStore } from "mrb/components/select";
import { HomeGenericSearchFilter } from "application/home/filters";
import { sideScroll } from "common/utils";
import { podcastType, podcastContentType } from "common/constants";
import { find, filter, map, isNil, uniqBy } from "lodash";

class HomeGenericSearchViewStore extends MrbBaseListViewStore {
    @observable showSearchView = false;
    @observable.ref podcastGroups = [];
    @observable isAppearsInScrollable = false;
    @observable.ref appearsInContainerRef = null;
    _isInitialLoad = true;

    _abortController = null;

    orderOptions = [
        {
            label: "APPLICATION.HOME.LIST.GENERIC_SEARCH.ORDER.RELEVANCE_OPTION",
            value: "-1",
        },
        {
            label: "APPLICATION.HOME.LIST.GENERIC_SEARCH.ORDER.RECENTLY_ADDED_OPTION",
            value: "dateCreated",
        },
        {
            label: "APPLICATION.HOME.LIST.GENERIC_SEARCH.ORDER.ALPHABETICALY_OPTION",
            value: "title",
        },
    ];

    constructor(rootStore, homeViewStore) {
        super(rootStore, {
            queryConfig: {
                filter: new HomeGenericSearchFilter(),
                disableUpdateQueryParams: true,
                pageSize: 30,
            },
        });
        makeObservable(this);
        this.homeViewStore = homeViewStore;
        this.routeStore = this.homeViewStore.routeStore;

        this.setGridStore(
            new MrbGridViewStore(this.rootStore, this.queryUtility, {
                onCellClick: (item) => this.onClickCellItem(item),
                generateKey: (item) => item.stationId || item.podcastId,
                mapper: (item) => {
                    return {
                        ...item,
                        coverImageUrl: this.createCoverImageUrl(item),
                    };
                },
            })
        );

        this.orderBySelectStore = new MrbSimpleSelectStore(
            {
                textKey: "label",
                valueKey: "value",
                actions: {
                    onChange: (selectedOrder) => {
                        if (this.queryUtility.filter.orderBy !== selectedOrder.value) {
                            if (selectedOrder.value === "-1") {
                                this.queryUtility.filter.orderBy = null;
                                this.queryUtility.filter.orderDirection = null;
                            } else {
                                this.queryUtility.filter.orderBy = selectedOrder.value;
                                if (selectedOrder.value === "title") {
                                    this.queryUtility.filter.orderDirection = "asc";
                                } else {
                                    this.queryUtility.filter.orderDirection = "desc";
                                }
                            }
                            this.queryUtility.fetch();
                        }
                    },
                },
            },
            this.orderOptions
        );

        this.genreSelectStore = new MrbQuerySelectStore(
            {
                isSearchable: true,
                textKey: "name",
                valueKey: "id",
                initFetch: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findGenres(searchTerm);
                    },
                },
            },
            this.queryUtility,
            "genreId"
        );

        this.initializeSortingDefault();

        this.reaction(
            () => (this.queryUtility.filter.search || "").trim(),
            (searchPhrase) => {
                if (searchPhrase) {
                    this.queryUtility.fetch();
                } else {
                    this.reset();
                }
            },
            {
                delay: 300,
            }
        );

        this.reaction(
            () => ({
                groups: this.podcastGroups,
                container: this.appearsInContainerRef,
            }),
            (context) => {
                if (context.container) {
                    setTimeout(() => {
                        runInAction(() => {
                            this.isAppearsInScrollable = context.container.scrollWidth > context.container.clientWidth;
                        });
                    });
                }
            }
        );
    }

    async fetchResources(filter) {
        if (filter.search) {
            this.abortExistingRequest();
            this.setShowSearchView(true);
            let delay = null;
            if (this._isInitialLoad) {
                delay = 0;
            }
            this.gridStore.suspend(delay);
            filter.contentType = this.homeViewStore.queryUtility.filter.contentType;
            try {
                const response = await this.find(filter);
                runInAction(() => {
                    this.gridStore.setData(response);
                    this.setPodcastGroups(response);
                    this.gridStore.resume();
                });
            } catch (err) {
                runInAction(() => {
                    this.gridStore.resume();
                });
                this.onFetchError(err);
            } finally {
                this._isInitialLoad = false;
            }
        } else {
            this.reset();
        }
    }

    @action.bound
    setPodcastGroups(response) {
        const data = response.item ? response.item : response;
        const podcastGroups = map(
            uniqBy(
                filter(data, (item) => !isNil(item.parentPodcastId)),
                "parentPodcastId"
            ),
            (item) => {
                return {
                    podcastId: item.parentPodcastId,
                    title: item.parentPodcastTitle,
                    isParentPodcastPremium: item.isParentPodcastPremium,
                    coverImageUrl: this.createPodcastGroupCoverImageUrl(item.parentPodcastCoverImageId),
                };
            }
        );
        this.podcastGroups = podcastGroups;
    }

    initializeSortingDefault() {
        if (this.queryUtility.filter.orderBy) {
            this.orderBySelectStore.setSelectedItem(
                find(this.orderOptions, (orderOption) => orderOption.value === this.queryUtility.filter.orderBy)
            );
        } else {
            this.orderBySelectStore.setSelectedItem(this.orderOptions[0]);
        }
    }

    find(params) {
        this._abortController = new AbortController();
        return this.routeStore.genericSearch(params, this._abortController);
    }

    onClickCellItem = (item) => {
        if (item.stationId) {
            this.rootStore.routerStore.goTo("master.application.home.station", {
                id: item.stationId,
            });
        } else if (item.podcastId) {
            if (item.podcastType === podcastType.podcast) {
                if (item.podcastContentType === podcastContentType.torahAnytime) {
                    this.rootStore.routerStore.goTo("master.application.home.torah-anytime", { id: item.podcastId });
                } else if (!isNil(item.parentPodcastId)) {
                    this.rootStore.routerStore.goTo(
                        "master.application.home.podcast-group",
                        {
                            id: item.parentPodcastId,
                        },
                        { subPodcastId: item.podcastId }
                    );
                } else {
                    this.rootStore.routerStore.goTo("master.application.home.podcast", {
                        id: item.podcastId,
                    });
                }
            } else {
                this.rootStore.routerStore.goTo("master.application.home.podcast-group", {
                    id: item.podcastId,
                });
            }
        }
    };

    createPodcastGroupCoverImageUrl(coverImageId) {
        if (coverImageId) {
            return this.routeStore.createPodcastCoverImageUrl(coverImageId, 200, 200);
        }
        return null;
    }

    createCoverImageUrl(item) {
        if (item.coverImageId) {
            if (item.stationId) {
                return this.routeStore.createStationCoverImageUrl(item.coverImageId, 200, 200);
            } else if (item.podcastId) {
                return this.routeStore.createPodcastCoverImageUrl(item.coverImageId, 200, 200);
            }
        }
        return null;
    }

    onFetchError(error) {
        if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
            return;
        }
        this.rootStore.notificationStore.error(
            "Unexpected error occurred while trying to load content. Please contact the support team.",
            error
        );
    }

    @action.bound
    setShowSearchView(value) {
        this.showSearchView = value;
    }

    @action.bound
    reset() {
        this.setShowSearchView(false);
        this.abortExistingRequest();
        this.genreSelectStore.setValue(null);
        this.gridStore.setData([]);
        this.setPodcastGroups([]);
        this._isInitialLoad = true;
    }

    @action.bound
    scrollFeaturedLeft() {
        sideScroll(this.appearsInContainerRef, "left", 20, 400, 20);
    }

    @action.bound
    scrollFeaturedRight() {
        sideScroll(this.appearsInContainerRef, "right", 20, 400, 20);
    }

    abortExistingRequest() {
        if (this._abortController) {
            this._abortController.abort();
        }
    }

    onDestroy() {
        this.abortExistingRequest();
    }

    onClickPodcastGroup = (podcastGroup) => {
        this.rootStore.routerStore.goTo("master.application.home.podcast-group", {
            id: podcastGroup.podcastId,
        });
    };

    @action.bound
    setAppearsInContainerRef(ref) {
        this.appearsInContainerRef = ref;
    }
}

export default HomeGenericSearchViewStore;
