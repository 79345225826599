import React from "react";
import { PropTypes } from "prop-types";
import { isNil } from "lodash";

function UserDeviceActivityContentCellTemplate({ data }) {
    let contentTitle = null;
    let isPremium = false;
    if (!isNil(data.station)) {
        contentTitle = data.station.title;
        isPremium = data.station.isPremium;
    } else if (!isNil(data.podcast)) {
        contentTitle = data.podcast.title;
        isPremium = data.podcast.isPremium;
    } else if (!isNil(data.nakiNigunAlbum)) {
        contentTitle = data.nakiNigunAlbum.title;
        isPremium = data.nakiNigunAlbum.isPremium;
    } else {
        return "-";
    }

    return (
        <div className="u-display--flex u-display--flex--ai--center">
            <ContentTypeIcon data={data} />
            <span className="c-analytics__list__title c-analytics__list__title--sml">{contentTitle}</span>
            {isPremium && <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny" />}
        </div>
    );
}

UserDeviceActivityContentCellTemplate.propTypes = {
    data: PropTypes.object.isRequired,
};

function ContentTypeIcon({ data }) {
    let icon = null;
    if (!isNil(data.station)) {
        icon = "stations";
    } else if (!isNil(data.podcast)) {
        icon = "podcasts";
    } else if (!isNil(data.nakiNigunAlbum)) {
        icon = "nigun";
    } else {
        return null;
    }

    return <i className={`u-icon u-icon--med u-icon--${icon} u-mar--right--tny`} />;
}

export default UserDeviceActivityContentCellTemplate;
