import React from "react";
import { mergeCss } from "mrb/common/utils";

const StatsWidgetEmptyState = (props) => {
    const { period, classNameExtend } = props;

    return (
        <div className={mergeCss("u-type--center", classNameExtend ? classNameExtend : "")}>
            <i className="u-icon u-icon--welcome u-icon--welcome--sml u-mar--bottom--sml"></i>
            <h5 className="">{`No statistic available for the current ${period ? period : "period"}`}</h5>
        </div>
    );
};

export default StatsWidgetEmptyState;
