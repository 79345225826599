import React from "react";
import ContentLoader from "react-content-loader";

const MrbLineContentLoader = ({
    smallWidth,
    setWidth,
    rows = 1,
    lineheight = 34,
    setHeight,
    setY = 50,
    backgroundColor = "#262729",
    foregroundColor = "#2b2b2b",
    ...props
}) => {
    const screenwidth = window.innerWidth;
    let loaderWidth;

    if (setWidth && screenwidth > 1270) {
        loaderWidth = setWidth;
    } else if (smallWidth && screenwidth > 1190) {
        loaderWidth = 380;
    } else if (screenwidth > 1190) {
        loaderWidth = screenwidth - 660;
    } else if (screenwidth > 990) {
        loaderWidth = screenwidth - 250;
    } else {
        loaderWidth = screenwidth;
    }

    const items = [];
    let y = 0;

    for (let i = 0; i < rows; i++) {
        items.push(
            <rect
                key={i}
                x="0"
                y={y}
                rx="6"
                ry="6"
                width={loaderWidth}
                height={lineheight}
            />
        );

        y += setY;
    }

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox={`0 0 ${loaderWidth} ${
                setHeight ? setHeight : rows * setY
            }`}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
            {...props}
        >
            {items}
        </ContentLoader>
    );
};

export default MrbLineContentLoader;
