import React from "react";
import { observer } from "mobx-react";
import { PropTypes } from "prop-types";
import { MrbPageContentHeader } from "mrb/components/layout";
import { MrbGrid } from "mrb/components/grid";
import { TableNoDataOverlay } from "common/components";
import { ContentStatsListLoader, PodcastListenersCell } from "administration/analytics/components";

function PodcastListenersListTemplate({ podcastListenersListViewStore, t }) {
    const { gridStore } = podcastListenersListViewStore;
    return (
        <div store={podcastListenersListViewStore} t={t}>
            <MrbPageContentHeader classNameOverride="c-container--base u-mar--bottom--sml"></MrbPageContentHeader>
            <div className="c-container--base">
                <div className="c-card--primary">
                    <div className="c-analytics__header">
                        <span className="c-analytics__header__item c-analytics__item--position">Position</span>
                        <span className="c-analytics__header__item c-analytics__item--name c-analytics__item--name--full">
                            Title
                        </span>
                        <span className="c-analytics__header__item c-analytics__item--score"># of Devices</span>
                    </div>
                    <MrbGrid
                        t={t}
                        store={gridStore}
                        classNameOverride="test"
                        emptyStateRender={TableNoDataOverlay}
                        loaderRender={() => <ContentStatsListLoader />}
                        cellRender={(props) => <PodcastListenersCell podcastId={props.item.id} {...props} />}
                    />
                </div>
            </div>
        </div>
    );
}

PodcastListenersListTemplate.propTypes = {
    podcastListenersListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(PodcastListenersListTemplate);
