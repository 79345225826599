import { BaseApiRouteService } from "mrb/core";

class DeviceApiRouteService extends BaseApiRouteService {
    constructor() {
        super("devices");
    }

    findUserDeviceForAuthenticatedUser(filter) {
        return super.find(`${this.base}/user/{?searchQuery,page,rpp,sort,embed,fields}`, filter);
    }

    getUserDeviceInfo(params) {
        return super.find(`${this.base}/user/info/{?sort,embed,fields}`, params);
    }

    findUserDevice(filter) {
        return super.find(`${this.base}/{?searchQuery,userIds,page,rpp,sort,embed,fields}`, filter);
    }

    updateUserDeviceDisabledStatus(id, value) {
        return super.update(`${this.base}/{id}/disabled{?value}`, {
            id: id,
            value: value,
        });
    }

    getMenu() {
        return super.get(`${this.base}/menu`);
    }

    getMenuLatestItems() {
        return super.get(`${this.base}/menu/latest-items`);
    }

    updateMenu() {
        return super.update(`${this.base}/menu`);
    }

    getUserDevice(id, options) {
        return super.get(`${this.base}/{id}/user{?embed,fields}`, id, options);
    }

    registerUserDevice(resource) {
        return super.create(`${this.base}/user/register`, resource);
    }

    updateUserDevice(resource) {
        return super.update(`${this.base}/{id}/user`, resource);
    }

    deleteUserDevice(resource) {
        return super.delete(`${this.base}/{id}/user`, resource);
    }
}

export default DeviceApiRouteService;
