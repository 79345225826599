import { MrbBaseRouteStore } from "mrb/core";
import { StationAnalyticsService } from "common/services";

class StationActivityRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(StationAnalyticsService);
    }

    async getStationActivity(stationId, filter, abortController) {
        const response = await this.service.getStationActivity(stationId, filter, abortController);
        return response.data;
    }

    async getStationSummary(stationId, filter, abortController) {
        const response = await this.service.getStationSummary(stationId, filter, abortController);
        return response.data;
    }
}

export default StationActivityRouteStore;
