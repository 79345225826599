import { BaseService } from "mrb/core";
import { LanguageApiRouteService } from "common/services";

class LanguageService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new LanguageApiRouteService());
    }
}

export default LanguageService;
