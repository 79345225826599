import { override } from "mobx";
import { MrbBaseRouteStore } from "mrb/core";
import { PaymentService, ProductService } from "common/services";

class ActivateTrialRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PaymentService);
        this.productService = rootStore.createApplicationService(ProductService);
    }

    @override
    onBeforeEnter() {
        if (
            this.rootStore.premiumSubscriptionStore.subscriptionExist ||
            this.rootStore.premiumSubscriptionStore.isPremiumTrialPeriodUsed
        ) {
            return Promise.reject("master.application.premium.subscriptions");
        }
    }

    async getSubscriptionPlan(planId) {
        const response = await this.productService.getSubscription(planId);
        return response.data;
    }
}

export default ActivateTrialRouteStore;
