import React from "react";
import ContentLoader from "react-content-loader";

const MrbRectangleContentLoader = ({
    rows = 1,
    rectangleHeight = 80,
    rectangleWidth = 280,
    spacing = 10,
    xRadius = 6,
    yRadius = 6,
    xBase = 10,
    yBase = 10,
    ...props
}) => {
    const screenwidth = window.innerWidth;
    let columns;

    if (screenwidth > 1494) {
        columns = 5;
    } else if (screenwidth > 1180) {
        columns = 3;
    } else if (screenwidth > 760) {
        columns = 2;
    } else {
        columns = 1;
    }
    
    const items = [];

    for (let i = 0; i < rows; i++) {
        for (let j = 0; j < columns; j++) {
            items.push(
                <rect
                    key={`${i}_${j}`}
                    x={xBase + j * (rectangleWidth + spacing)}
                    y={yBase + i * (rectangleHeight + spacing)}
                    rx={xRadius}
                    ry={yRadius}
                    width={screenwidth < 766 ? "100%" : rectangleWidth}
                    height={rectangleHeight}
                />
            );
        }
    }

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox={`0 0 ${
                screenwidth > 988 ? screenwidth - 248 : screenwidth
            } 80`}
            backgroundColor="#393c42"
            foregroundColor="#404143"
            {...props}
        >
            {items}
        </ContentLoader>
    );
};

export default MrbRectangleContentLoader;
