import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldLayout } from "mrb/components/layout";
import { mergeOrOverrideCss } from "mrb/common/utils";
import CurrencyInput from "react-currency-input-field";
import { isNil } from "lodash";
import { observer } from "mobx-react";

function CurrencyInputFieldTemplate({
    field,
    t = (i) => i,
    showLabel = true,
    disabled,
    labelRender = null,
    validationRender = null,
    classNameExtend,
    classNameOverride,
    fieldClassNameExtend,
    fieldClassNameOverride,
    ...restOfProps
}) {
    const { placeholder, onChange, ...otherProps } = field.bind();

    if (otherProps.value && typeof otherProps.value === "string")
        otherProps.value = otherProps.value.replace(/\s+/g, " ");
    if (otherProps.value == null) otherProps.value = "";

    return (
        <MrbFieldLayout
            field={field}
            t={t}
            labelRender={labelRender}
            validationRender={validationRender}
            showLabel={showLabel}
            classNameExtend={fieldClassNameExtend}
            classNameOverride={fieldClassNameOverride}
        >
            <CurrencyInput
                className={mergeOrOverrideCss(
                    "c-input c-input--outline c-input--numeric c-input--numeric--sml",
                    classNameOverride,
                    classNameExtend
                )}
                decimalsLimit={2}
                prefix="$"
                onValueChange={(value) => onChange(value)}
                {...otherProps}
                {...restOfProps}
                disabled={isNil(disabled) ? otherProps.disabled : disabled}
                placeholder={t(placeholder)}
            />
        </MrbFieldLayout>
    );
}

CurrencyInputFieldTemplate.propTypes = {
    field: PropTypes.object.isRequired,
    showLabel: PropTypes.bool,
    t: PropTypes.func,
    labelRender: PropTypes.func,
    validationRender: PropTypes.func,
    classNameExtend: PropTypes.string,
    classNameOverride: PropTypes.string,
    fieldClassNameExtend: PropTypes.string,
    fieldClassNameOverride: PropTypes.string,
};

export default observer(CurrencyInputFieldTemplate);
