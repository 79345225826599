import { BaseApiRouteService } from "mrb/core";

class AppHomeSectionApiRouteService extends BaseApiRouteService {
    constructor() {
        super("app-home-sections");
    }

    getAll(options) {
        return super.find(`${this.base}{?embed}`, options);
    }

    getFeaturedGrid(options) {
        return super.find(`${this.base}/featured-grid{?systemType,embed}`, options);
    }

    getContent(options) {
        return super.find(`${this.base}/content{?systemType,searchQuery,page,rpp}`, options);
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?embed,fields}`, id, options);
    }

    create(resource) {
        return super.create(this.base, resource);
    }

    update(resource) {
        return super.update(`${this.base}`, resource);
    }

    updateFeaturedGrid(systemType, resource) {
        return super.update(`${this.base}/featured-grid{?systemType}`, { systemType: systemType }, resource);
    }

    delete(resource) {
        return super.delete(`${this.base}/{id}`, resource);
    }
}

export default AppHomeSectionApiRouteService;
