import React from "react";
import { NakiNigunListenersCellTemplate } from "themes/administration/analytics/components";

class NakiNigunListenersCell extends React.Component {
    render() {
        return <NakiNigunListenersCellTemplate {...this.props} />;
    }
}

export default NakiNigunListenersCell;
