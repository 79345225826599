import React from "react";
import { observer } from "mobx-react";
import { RouterContext } from "mobx-state-router";
import { ApplicationRouterView } from "./infrastructure/router";
import { RootContext } from "./contexts";
import { isNil, isBoolean, isFunction } from "lodash";

@observer
class MrbApp extends React.Component {
    async componentDidMount() {
        await this.props.rootStore.applicationStartupStore.initialize();
    }

    render() {
        const { applicationStartupStore, routerStore } = this.props.rootStore;
        if (
            !applicationStartupStore.isInitialized ||
            (isBoolean(this.props.suspendRendering) && this.props.suspendRendering)
        ) {
            if (!isNil(this.props.loaderRenderer) && isFunction(this.props.loaderRenderer)) {
                return this.props.loaderRenderer();
            }
            return <div>Application is loading</div>;
        }

        return (
            <React.Fragment>
                <RootContext.Provider value={this.props.rootStore}>
                    <RouterContext.Provider value={routerStore.router}>
                        <ApplicationRouterView
                            routerStore={routerStore}
                            viewMap={routerStore.routerMaps}
                        ></ApplicationRouterView>
                    </RouterContext.Provider>
                </RootContext.Provider>
            </React.Fragment>
        );
    }
}

export default MrbApp;
