import { BaseService } from "mrb/core";
import { WeekDayApiRouteService } from "common/services";

class WeekDayService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new WeekDayApiRouteService());
    }

    get(abortSignal = null) {
        const url = this.routeService.get();
        return this.apiClient.get(url, null, null, abortSignal);
    }
}

export default WeekDayService;
