import { action, computed, observable, makeObservable } from "mobx";
import { moduleBuilder } from "../infrastructure";

/**
 * MrbApplicationStartupStore is responsible for initializing application infrastructure services (apiClient, application, routing and authentication).
 * @class
 * @memberof Core.Stores
 */
class MrbApplicationStartupStore {
    @observable _isInitialized = observable.box(false);

    /**
     * @computed
     * @readonly
     * @public
     * @description Determines whether an application services are initialized.
     */
    @computed get isInitialized() {
        return this._isInitialized.get();
    }

    /**
     * @constructor
     * @param {Core.Stores.RootStore} rootStore - The instance of the RootStore.
     * @description Initializes a new instance of the MrbApplicationStartupStore.
     */
    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    /**
     * @function
     * @async
     * @public
     * @action
     * @description Initializes current application services. Function is called in App component within componentDidMount function.
     * Once when an application services are initialized, application is rendered.
     */
    @action initialize = async () => {
        if (!this.isInitialized) {
            // ApplicationStore must be initialized first since it is responsible for creating API client.
            this.rootStore.applicationStore.register(
                this.rootStore.applicationSettings
            );
            this.createModuleStores();
            // AuthenticationStore must be initialized before the RouterStore and the UserStore
            this.rootStore.authenticationStore.initialize();
            // UserStore must be initialized before the UserStore
            await this.rootStore.userStore.initialize();
            // RouterStore must be initialized before the MenuStore because the current route is tracked
            // inside of it
            this.rootStore.routerStore.initialize(
                this.rootStore.configuration.routes
            );
            this.rootStore.menuStore.initialize();
            await this.postInitialize();
            this.setIsInitialized();
        }
        return Promise.resolve();
    };

    postInitialize = () => {
        const currentPath = this.rootStore.routerStore.historyAdapter.history
            .location.pathname;
        //TODO check if neccessary isAuthenticated
        if (currentPath === "" || currentPath === "/") {
            const routerStore = this.rootStore.routerStore;
            if (this.rootStore.authenticationStore.isAuthenticated) {
                routerStore.goTo(routerStore.initialState);
            } else {
                routerStore.goTo(routerStore.loginState);
            }
        }
        return Promise.resolve();
    };

    createModuleStores = () => {
        const moduleContext = { rootStore: this };
        const moduleStores = moduleBuilder.buildStores(
            this.rootStore.configuration.stores,
            moduleContext
        );
        if (moduleStores) {
            this.rootStore.module = moduleStores;
        }
    };

    /**
     * @function
     * @private
     * @description Sets IsInitialized flag.
     */
    @action setIsInitialized = () => {
        this._isInitialized.set(true);
    };
}

export default MrbApplicationStartupStore;
