import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "baasic-react-bootstrap/src";
import { observer } from "mobx-react";

function HomeManageSelectedItemsTemplate({ homeManageSelectedItemsViewStore, t }) {
    const { isBulk, changeIsBulk, changeTurnOn, isUpdating } = homeManageSelectedItemsViewStore;

    return (
        <div>
            {isBulk ? (
                <div className="c-home__header__options--secondary">
                    <div className="u-display--flex">
                        <MrbButton
                            classNameExtend="u-mar--right--tny"
                            label="Turn on"
                            onClick={() => changeTurnOn(true)}
                            disabled={isUpdating || !homeManageSelectedItemsViewStore.isSelectedItemsAny}
                        />
                        <MrbButton
                            label="Turn off"
                            onClick={() => changeTurnOn(false)}
                            disabled={isUpdating || !homeManageSelectedItemsViewStore.isSelectedItemsAny}
                        />
                    </div>
                    <MrbButton
                        classNameOverride="c-btn c-btn--base c-btn--ghost"
                        label="Cancel"
                        onClick={changeIsBulk}
                        disabled={isUpdating}
                    />
                </div>
            ) : (
                <div className="c-home__header__options">
                    <MrbButton label="Edit" classNameExtend="jq-onboarding-bulk-edit" onClick={changeIsBulk} />
                </div>
            )}
        </div>
    );
}

HomeManageSelectedItemsTemplate.propTypes = {
    homeManageSelectedItemsViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(HomeManageSelectedItemsTemplate);
