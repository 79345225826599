import React from "react";
import { ProducerReportsTableTemplate } from "themes/common/components";

class ProducerReportsTable extends React.Component {
    render() {
        return <ProducerReportsTableTemplate {...this.props} />;
    }
}

export default ProducerReportsTable;
