import { action, makeObservable } from "mobx";
import { BasePaymentViewStore } from "application/payment/stores";
import { currencyFormatter } from "mrb/common/formatting";

class TakePaymentViewStore extends BasePaymentViewStore {
    planId = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore, { routeStore });
        makeObservable(this);
        this.planId = rootStore.routerStore.routerState.params.planId;

        this.onClickSubscribe = this.onClickSubscribe.bind(this);
    }

    async onInit() {
        return this.fetchSubscriptionPlan();
    }

    async fetchSubscriptionPlan() {
        try {
            const subscriptionPlan = await this.routeStore.getSubscriptionPlan(this.planId);
            this.setSubscriptionPlan(subscriptionPlan);
        } catch (err) {
            this.rootStore.notificationStore.error("Unexpected error occurred. Please contact the support team.", err);
        }
    }

    onClickSubscribe(cardElements) {
        if (this.paymentMethodExist) {
            this.subscribeUsingExistingPaymentMethod();
        } else {
            this.submitPayment(cardElements);
        }
    }

    @action.bound
    subscribeUsingExistingPaymentMethod() {
        const formattedPrice = currencyFormatter.format(this.subscriptionPlan.price, {
            currency: "USD",
        });
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Ready to start your subscription?",
            description: `Your card will be charged ${formattedPrice} each month, cancel anytime.`,
            yesLabel: `Pay ${formattedPrice}`,
            onConfirm: async () => {
                try {
                    await this.routeStore.subscribeUsingExistingPaymentMethod(this.planId);
                    this.paymentSuccessModal.open({
                        subscriptionPlan: this.subscriptionPlan.name,
                    });
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "Unexpected error occurred while trying to process payment. Please contact the support team.",
                        err
                    );
                }
            },
        });
    }

    @action.bound
    submitPayment(cardElements) {
        const formattedPrice = currencyFormatter.format(this.subscriptionPlan.price, {
            currency: "USD",
        });
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Ready to start your subscription?",
            description: `Your card will be charged ${formattedPrice} each month, cancel anytime.`,
            yesLabel: `Pay ${formattedPrice}`,
            onConfirm: async () => {
                try {
                    const { user } = this.rootStore.userStore;
                    this.stripeField.resetValidation();
                    const result = await this.routeStore.preprocessPayment(this.planId);
                    let payload = null;
                    let paymentMethod = null;
                    if (result.clientSecret) {
                        payload = await this.stripe.confirmCardPayment(result.clientSecret, {
                            payment_method: {
                                card: cardElements,
                            },
                            receipt_email: user.email,
                        });
                    } else {
                        const setupPaymentMethodResponse = await this.routeStore.setupPaymentMethodRequest();
                        payload = await this.stripe.confirmCardSetup(setupPaymentMethodResponse.token, {
                            payment_method: {
                                card: cardElements,
                            },
                        });
                        if (!payload.error) {
                            paymentMethod = payload.setupIntent.payment_method;
                        }
                    }

                    if (payload.error) {
                        this.stripeField.setError(payload.error);
                        const data = {
                            undoChanges: true,
                            paymentProviderSubscriptionId: result.paymentProviderSubscriptionId,
                        };
                        await this.routeStore.postprocessPayment(data);
                    } else {
                        const data = {
                            paymentProviderPaymentMethodId: paymentMethod,
                            paymentProviderSubscriptionId: result.paymentProviderSubscriptionId,
                        };
                        await this.routeStore.postprocessPayment(data);
                        this.paymentSuccessModal.open({
                            subscriptionPlan: this.subscriptionPlan.name,
                        });
                    }
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "Unexpected error occurred while trying to process payment. Please contact the support team.",
                        err
                    );
                }
            },
        });
    }
}

export default TakePaymentViewStore;
