import React, { useState } from "react";
import { mergeCss } from "mrb/common/utils";
import { MrbNotifyOutsideClick } from "mrb/components/notify-outside-click";

export const PopoverTemplate = ({ trigger, isDisabled, contentList, contentClassName, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="c-popover">
            <MrbNotifyOutsideClick action={() => setIsOpen(false)}>
                <div className="c-popover__trigger" onClick={!isDisabled ? handleIsOpen : null}>
                    {trigger}
                </div>

                {isOpen && (
                    <div className={mergeCss("c-popover__content", contentClassName)}>
                        {children
                            ? children
                            : contentList.length > 0 &&
                              contentList.map((item) => {
                                  return (
                                      <div
                                          onClick={(event) => {
                                              item.action(event);
                                              setIsOpen(false);
                                          }}
                                          key={Math.random()}
                                          className={mergeCss(
                                              "c-popover__item",
                                              item.className,
                                              item.disabled && "isDisabled"
                                          )}
                                      >
                                          {item.icon && <i className={`u-icon u-icon--med u-icon--${item.icon}`} />}
                                          {item.title}
                                      </div>
                                  );
                              })}
                    </div>
                )}
            </MrbNotifyOutsideClick>
        </div>
    );
};

export default PopoverTemplate;
