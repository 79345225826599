import { BaseApiRouteService } from "mrb/core";

class NakiNigunAnalyticsApiRouteService extends BaseApiRouteService {
    constructor() {
        super("analytics/naki-nigun");
    }

    getNakiNigunListenersStats() {
        return super.get(`${this.base}/listeners`);
    }

    getTrendingAlbumsForProducer(filter) {
        return super.find(
            `${this.base}/producer/{?from,to,previousCycleFrom,previousCycleTo,timeZone,page,rpp,uniqueListeners}`,
            filter
        );
    }

    getTrendingAlbumsTotalStreamsForProducer(filter) {
        return super.find(`${this.base}/producer-total/{?from,to,timeZone,uniqueListeners}`, filter);
    }
}

export default NakiNigunAnalyticsApiRouteService;
