import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import {
    AnalyticsCounter,
    AnalyticsStatsLoader,
    PercentageIncreaseDonutChart,
} from "administration/analytics/components";
import { calculatePercantageIncrease, numberFormatter } from "common/utils";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { MrbContentLoader } from "mrb/components/loader";
import { timeRangeType } from "administration/analytics/constants";
import { applicationDefaults } from "common/constants";
import { dateFormatter } from "mrb/common/formatting";

function PortalNewDevicesTemplate({ portalViewStore, t }) {
    const {
        data,
        loaderStore,
        rootStore: { routerStore },
    } = portalViewStore;

    return (
        <div className="col col-sml-12 col-lrg-6 u-mar--bottom--med">
            <MrbRouterLink
                className="c-analytics__title"
                routeName="master.administration.analytics.connected-devices"
                queryParams={routerStore.routerState.queryParams}
            >
                Connected devices
                <i className="u-icon u-icon--base u-icon--arrow-right u-mar--left--tny" />
            </MrbRouterLink>
            <div className="c-analytics__card c-analytics__card--secondary">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <AnalyticsStatsLoader newDevices />}>
                    {() => (
                        <React.Fragment>
                            {data && (
                                <React.Fragment>
                                    <div className="u-animation--fadeIn c-analytics__card__info">
                                        <AnalyticsCounter
                                            filteredBy={data.filteredBy}
                                            currentCycleNumber={data.connectedDevices.currentCycleTotal}
                                            classNameExtend="c-dashboard__count--secondary"
                                            timeRangeLabelRenderer={(props) => <TimeRangeLabel {...props} />}
                                        />
                                        <p>
                                            Total of{" "}
                                            <span className="u-type--wgt--bold">
                                                {numberFormatter.format(data.connectedDevices.total)}
                                            </span>{" "}
                                            devices
                                        </p>
                                    </div>

                                    <div className="u-animation--fadeIn c-analytics__card__chart">
                                        <PercentageIncreaseDonutChart
                                            value={calculatePercantageIncrease(
                                                data.connectedDevices.currentCycleTotal,
                                                data.connectedDevices.previousCycleTotal
                                            )}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
        </div>
    );
}

PortalNewDevicesTemplate.propTypes = {
    portalViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function TimeRangeLabel({ filteredBy }) {
    if (filteredBy.range === timeRangeType.today) {
        return "new devics today";
    } else if (filteredBy.range === timeRangeType.yesterday) {
        return "new devices yesterday";
    } else if (filteredBy.range === timeRangeType.last3Days) {
        return "new devices in the last 3 days";
    } else if (filteredBy.range === timeRangeType.thisWeek) {
        return "new devices this week";
    } else if (filteredBy.range === timeRangeType.lastWeek) {
        return "new devices in the last week";
    } else if (filteredBy.range === timeRangeType.thisMonth) {
        return "new devices this month";
    } else if (filteredBy.range === timeRangeType.lastMonth) {
        return "new devices in the last month";
    } else {
        const fromDate = dateFormatter.format(filteredBy.from, {
            format: applicationDefaults.formats.date,
            timeZone: filteredBy.timeZone,
        });
        const toDate = dateFormatter.format(filteredBy.to, {
            format: applicationDefaults.formats.date,
            timeZone: filteredBy.timeZone,
        });
        return `new devices ${fromDate} - ${toDate}`;
    }
}

export default observer(PortalNewDevicesTemplate);
