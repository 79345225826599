import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { isFunction } from "lodash";

Modal.setAppElement("#root");
function MrbModal({
    modalParams,
    children,
    className = "modal__content",
    overlayClassName = "modal__overlay",
    shouldCloseOnOverlayClick = true,
    displayCloseIcon = true,
    ...other
}) {
    return (
        <Modal
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            onRequestClose={modalParams.close}
            className={className}
            overlayClassName={overlayClassName}
            isOpen={modalParams.isOpen}
            {...other}
        >
            {displayCloseIcon && (
                <span className="u-icon u-icon--sml u-icon--close modal__icon--close" onClick={modalParams.close} />
            )}
            {isFunction(children)
                ? children({ modalParams: modalParams.params, modalData: modalParams.data })
                : children}
        </Modal>
    );
}

MrbModal.propTypes = {
    modalParams: PropTypes.object,
    children: PropTypes.any,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    shouldCloseOnOverlayClick: PropTypes.bool,
    displayCloseIcon: PropTypes.bool,
};

export default observer(MrbModal);
