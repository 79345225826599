import { subscriptionPlans } from "common/constants";

class NakiRadioFreePlan {
    constructor() {
        this.id = "-1";
        this.name = "NakiRadio Free";
        this.slug = subscriptionPlans.nakiRadioFree;
        this.price = 0;
        this.features = [
            {
                description: "The classic NakiRadio experience",
                sortOrder: 1,
            },
            {
                description: "More than 50 stations of music, stories and entertainment",
                sortOrder: 2,
            },
            {
                description: "Thousands of stories, inspirational speeches, and shiurim for all ages",
                sortOrder: 3,
            },
            {
                description: "Customize your NakiRadio with additional stations and podcasts",
                sortOrder: 4,
            },
        ];
    }
}

export default NakiRadioFreePlan;
