import { BaseHomePodcastViewStore } from "application/home/stores";

class HomePodcastViewStore extends BaseHomePodcastViewStore {
    async get(id) {
        if (this.rootStore.userDeviceStore.selectedDevice) {
            return this.routeStore.getPodcastWthDeviceSettings(id, this.rootStore.userDeviceStore.selectedDevice.id);
        }
        return this.routeStore.getPodcast(id);
    }

    getStatusItem(deviceId, resourceId) {
        return this.routeStore.getUserDevicePodcast(deviceId, resourceId);
    }

    setStatusItem(statusItem) {
        this.statusItem = statusItem;
    }
}

export default HomePodcastViewStore;
