import { BaseService } from "mrb/core";
import { ContentApiRouteService } from "common/services";

class ContentService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new ContentApiRouteService());
    }
}

export default ContentService;
