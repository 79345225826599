import React from "react";
import { PropTypes } from "prop-types";
import { PremiumBadge, HTMLRender, Image } from "common/components";
import { MrbCollapsiblePanel } from "mrb/components/collapsible-panel";
import { PremiumContentSubListItem } from "application/payment/components";
import { HomeListLoader } from "application/home/components";
import { map } from "lodash";
import { observer } from "mobx-react";
import { MrbContentLoader } from "mrb/components/loader";

function PremiumContentListItemTemplate({ premiumContentListItemViewStore, item, premiumContentListViewStore }) {
    const { goTo } = premiumContentListViewStore;
    const { isCollapsed, toggleCollapse, setPanelRef, hasSubContent } = premiumContentListItemViewStore;

    return (
        <MrbCollapsiblePanel
            title={item.title}
            isCollapsed={isCollapsed}
            innerRef={setPanelRef}
            onChangeCollapse={hasSubContent ? toggleCollapse : null}
            headerClassNameExtend={`${hasSubContent ? "c-collapse-panel__header--secondary" : ""} u-cursor--pointer`}
            bodyClassNameOverride={"c-collapse-panel__body--secondary"}
            headerRender={(props) => (
                <ContentItemPanelHeader {...props} item={item} onCellClick={goTo} hasSubContent={hasSubContent} />
            )}
        >
            {() => (
                <React.Fragment>
                    <HTMLRender className="c-collapse-panel__body__description u-mar--bottom--med">
                        {item.premiumInfo}
                    </HTMLRender>
                    <SubItems premiumContentViewStore={premiumContentListItemViewStore} />
                </React.Fragment>
            )}
        </MrbCollapsiblePanel>
    );
}

PremiumContentListItemTemplate.propTypes = {
    item: PropTypes.object.isRequired,
    premiumContentListItemViewStore: PropTypes.object.isRequired,
};

const SubItems = observer(function SubItems({ premiumContentViewStore }) {
    const { items, loaderStore, onClickSubItem } = premiumContentViewStore;
    return (
        <MrbContentLoader
            loaderStore={loaderStore}
            loaderRender={() => (
                <HomeListLoader explorePremium backgroundColor={"#262729"} foregroundColor={"#2b2b2b"} row={1} />
            )}
        >
            {() => (
                <div className="c-home__list">
                    {map(items, (item) => (
                        <PremiumContentSubListItem onCellClick={onClickSubItem} item={item} key={item.id} />
                    ))}
                </div>
            )}
        </MrbContentLoader>
    );
});

const ContentItemPanelHeader = observer(function ContentItemPanelHeader({
    item,
    isCollapsed,
    onCellClick,
    hasSubContent,
}) {
    return (
        <React.Fragment>
            <div className="c-home__card__wrapper">
                {hasSubContent && (
                    <i
                        className={`c-collapse-panel__icon u-icon u-icon--med u-icon--arrow-down ${
                            isCollapsed ? "u-icon--arrow-up" : ""
                        } u-cursor--pointer`}
                    ></i>
                )}
                <div className="c-home__card__media c-home__card__media--lrg">
                    <div className="c-home__card__img-wrapper">
                        <Image src={item.coverImageUrl} />
                        <PremiumBadge isPremium={item.isPremium} premiumSize={"lrg"} iconSize={"med"} />
                    </div>
                </div>
                <div>
                    <div
                        className="c-home__card__title--lrg u-type--link--secondary"
                        title={item.title}
                        onClick={() => onCellClick(item)}
                    >
                        {item.title}
                    </div>
                    <HTMLRender className="c-home__card__description">{item.alternativeDescription}</HTMLRender>
                </div>
            </div>
        </React.Fragment>
    );
});

export default observer(PremiumContentListItemTemplate);
