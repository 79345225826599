import { computed, makeObservable } from "mobx";
import { Field } from "mobx-react-form";
import { MrbValidationRules } from "./validation";
import moment from "moment";

class MrbBaseField extends Field {
    initialSetup = null;

    @computed get errorMessage() {
        return this.preprocessErrorMessage(this.error);
    }

    constructor(props) {
        const setup = props.data;
        if (setup && setup.type === "date") {
            setup.output =
                setup.output ||
                ((value) => {
                    return moment.utc(value).format();
                });
        }

        super({
            ...props,
            data: { ...setup },
        });
        makeObservable(this);

        this.initialSetup = props ? props.data : null;
    }

    preprocessErrorMessage(error) {
        return error;
    }

    addIsRequiredRule() {
        const validationRules = new MrbValidationRules(this.rules);
        if (!validationRules.isRequired) {
            validationRules.addIsRequiredRule();
            this.set("rules", validationRules.createExpression());
        }
    }

    removeIsRequiredRule() {
        const validationRules = new MrbValidationRules(this.rules);
        if (validationRules.isRequired) {
            validationRules.removeIsRequiredRule();
            this.set("rules", validationRules.createExpression());
        }
    }

    addRule(ruleName) {
        const validationRules = new MrbValidationRules(this.rules);
        if (!validationRules.ruleExists(ruleName)) {
            validationRules.addRule(ruleName);
            this.set("rules", validationRules.createExpression());
        }
    }

    removeRule(ruleName) {
        const validationRules = new MrbValidationRules(this.rules);
        if (validationRules.ruleExists(ruleName)) {
            validationRules.removeRule(ruleName);
            this.set("rules", validationRules.createExpression());
        }
    }

    disable() {
        this.set("disabled", true);
    }

    enable() {
        this.set("disabled", false);
    }
}

export default MrbBaseField;
