const applicationErrorCodes = {
    device: {
        notExists: 51002,
        alreadyRegistered: 51003,
    },
    payment: {
        existingActiveSubscription: 53001,
        paymentFailedAuthenticationRequired: 53003,
        paymentFailedExpiredCreditCard: 53005,
        paymentFailedInvalidCreditCard: 53004,
        paymentFailedUnknownReason: 53006,
    },
    rssSync: {
        rssSyncFailed: 55001,
    },
    producer: {
        administratorConflict: 58001,
        producerNameEmpty: 58002,
        userNotFound: 58003,
        emailAlreadyExists: 58005,
        insufficientAccountBalance: 59006,
        stripeConnectAccountNotExists: 59005,
        producerUserAccountNotExists: 59004,
        stripeConnectAccountNotEnabled: 59007,
        userIsAdministrator: 59008,
        duplicateUsersSelected: 59002,
    },
};

export default applicationErrorCodes;
