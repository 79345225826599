import { MrbBaseRouteStore } from "mrb/core";
import { PodcastAnalyticsService } from "common/services";

class ContentStatsRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PodcastAnalyticsService);
    }

    async getPodcastListenersStats() {
        const response = await this.service.getPodcastListenersStats();
        return response.data;
    }
}

export default ContentStatsRouteStore;
