import { MrbBaseRouteStore } from "mrb/core";
import { PodcastAnalyticsService, PodcastFileStreamService } from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";

class TrendingPodcastListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PodcastAnalyticsService);
        this.PodcastFileStreamService = this.rootStore.createApplicationService(PodcastFileStreamService);
    }

    async getTrendingPodcasts(filter) {
        const response = await this.service.getTrendingPodcasts(filter);
        return response.data;
    }

    createPodcastCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.PodcastFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }
}

export default TrendingPodcastListRouteStore;
