import { BaseApiRouteService } from "mrb/core";

class UserSettingsApiRouteService extends BaseApiRouteService {
    constructor() {
        super("user-settings");
    }

    updateIsTutorialPassed(isTutorialPassed) {
        return super.update(`${this.base}/tutorial-passed{?value}`, {
            value: isTutorialPassed,
        });
    }

    disableContentPolicy() {
        return super.update(`${this.base}/content-policy/disable`);
    }

    disableTorahAnytimeAvailability() {
        return super.update(`${this.base}/torah-anytime-availability/disable`);
    }

    disableConnectDevice() {
        return super.update(`${this.base}/connect-device/disable`);
    }

    disableContentUnavailableInAppPolicy() {
        return super.update(`${this.base}/content-unavailable-policy/disable`);
    }
}

export default UserSettingsApiRouteService;
