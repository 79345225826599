import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { AnalyticsTimeRange } from "administration/analytics/components";
import { observer } from "mobx-react";

function AnalyticsLayoutTemplate({ analyticsTimeRangeViewStore, children, t }) {
    return (
        <MrbPage>
            <MrbPageHeader>
                <Header analyticsTimeRangeViewStore={analyticsTimeRangeViewStore} t={t} />
            </MrbPageHeader>

            {children}
        </MrbPage>
    );
}

AnalyticsLayoutTemplate.propTypes = {
    analyticsTimeRangeViewStore: PropTypes.object.isRequired,
    children: PropTypes.any,
};

const Header = observer(function Header({ analyticsTimeRangeViewStore, t }) {
    const {
        rootStore: { routerStore },
    } = analyticsTimeRangeViewStore;
    return (
        <React.Fragment>
            <MrbRouterLink
                routeName="master.administration.analytics.dashboard"
                queryParams={routerStore.routerState.queryParams}
            >
                <span className="display--ib u-mar--right--tny align--v--middle">
                    <i className="u-icon u-icon--med u-icon--back" />
                </span>
                Analytics
            </MrbRouterLink>
            <AnalyticsTimeRange analyticsTimeRangeViewStore={analyticsTimeRangeViewStore} t={t} />
        </React.Fragment>
    );
});

export default observer(AnalyticsLayoutTemplate);
