import React, { useState } from "react";
import { mergeCss } from "mrb/common/utils";
import { MrbButton } from "mrb/components/button";

const AccordionTemplate = (props) => {
    const { title, children, className, customStyle, handleRemoveCard, showClose, additionalContent } = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleIsOpen = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    return (
        <div className={mergeCss("c-accordion", isOpen && "is-open", className)} style={customStyle}>
            <div className="c-accordion__header">
                {title}

                <div className="c-accordion__actions">
                    <MrbButton
                        onClick={handleIsOpen}
                        classNameOverride="c-btn c-btn--icon"
                        onlyIcon
                        icon={mergeCss("u-icon u-icon--base u-icon--arrow-up", isOpen && "u-rotate--180")}
                    />
                    {showClose && (
                        <MrbButton
                            onClick={handleRemoveCard}
                            classNameOverride="c-btn c-btn--icon"
                            onlyIcon
                            icon={mergeCss("u-icon u-icon--base u-icon--close")}
                        />
                    )}
                </div>
            </div>

            {isOpen && (
                <div className="c-accordion__wrapper">
                    {additionalContent}

                    <div className="c-accordion__content">{children}</div>
                </div>
            )}
        </div>
    );
};

export default AccordionTemplate;
