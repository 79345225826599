import React from "react";
import { PropTypes } from "prop-types";

function MrbSimpleSelectOption({ option, t }) {
    return <div>{t(option.label)}</div>;
}

MrbSimpleSelectOption.propTypes = {
    option: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default MrbSimpleSelectOption;
