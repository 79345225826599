import { action, observable, makeObservable } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbTableViewStore } from "mrb/components";
import { mrbDefaultFormats } from "mrb/common/formatting";
import { ProducerPayoutUserEventFilter } from "administration/producer/filters";
import { UserEventPayoutCell } from "common/components/producer";
import { UserPayoutEventListContentCell } from "administration/analytics/components";
import { applicationDefaults } from "common/constants";
import { convertSecondsToHMS } from "common/utils";

class ProducerPayoutUserEventListViewStore extends MrbBaseListViewStore {
    @observable.ref user = null;
    @observable isCollapsed = false;
    producerId = null;

    constructor(rootStore, { item, producerId, routeStore }) {
        super(rootStore, {
            queryConfig: {
                ignoreQueryParams: true,
                disableUpdateQueryParams: true,
                filter: new ProducerPayoutUserEventFilter(),
                embed: "producer,device,station,podcast,nakiNigunAlbum,shuffle",
                orderBy: "timeStamp",
                orderDirection: "desc",
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;
        this.item = item;
        this.producerId = producerId;

        this.setGridStore(
            new MrbTableViewStore(this.rootStore, this.queryUtility, {
                columns: [
                    {
                        key: "device.deviceName",
                        title: "ADMINISTRATION.PRODUCER.PAYOUT_USER_EVENT.LIST.COLUMNS.DEVICE",
                    },
                    {
                        key: "producer.name",
                        title: "ADMINISTRATION.PRODUCER.PAYOUT_USER_EVENT.LIST.COLUMNS.PRODUCER",
                    },
                    {
                        key: "",
                        title: "ADMINISTRATION.PRODUCER.PAYOUT_USER_EVENT.LIST.COLUMNS.CONTENT_NAME",
                        cell: UserPayoutEventListContentCell,
                    },
                    {
                        key: "trackTitle",
                        title: "ADMINISTRATION.PRODUCER.PAYOUT_USER_EVENT.LIST.COLUMNS.TRACK",
                    },
                    {
                        key: "trackDuration",
                        title: "ADMINISTRATION.PRODUCER.PAYOUT_USER_EVENT.LIST.COLUMNS.TRACK_DURATION",
                        format: (value) => convertSecondsToHMS(value),
                    },
                    {
                        key: "isAllowedForPayout",
                        title: "ADMINISTRATION.PRODUCER.PAYOUT_USER_EVENT.LIST.COLUMNS.COLLECTED",
                        cell: UserEventPayoutCell,
                    },
                    {
                        key: "durationForPayout",
                        title: "ADMINISTRATION.PRODUCER.PAYOUT_USER_EVENT.LIST.COLUMNS.DURATION_FOR_PAYOUT",
                        format: (value) => convertSecondsToHMS(value),
                    },
                    {
                        key: "durationForPayout",
                        title: "ADMINISTRATION.PRODUCER.PAYOUT_USER_EVENT.LIST.COLUMNS.POINTS_FOR_PAYOUT",
                    },
                    {
                        key: "timeStamp",
                        title: "ADMINISTRATION.PRODUCER.PAYOUT_USER_EVENT.LIST.COLUMNS.TIMESTAMP",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.date,
                            format: applicationDefaults.formats.dateTimeSeconds,
                        },
                    },
                ],
                rows: {
                    getRowClass: (params) => {
                        const item = params.node.data;

                        if (item && this.producerId === item.producerId && item.isAllowedForPayout) {
                            return "highlight";
                        }
                    },
                },
            })
        );

        this.reaction(
            () => this.isCollapsed,
            (isCollapsed) => {
                if (isCollapsed) {
                    this.queryUtility.initialize();
                }
            }
        );
    }

    onInit() {
        return Promise.resolve();
    }

    find(params) {
        params.userId = this.item.userId;
        params.from = this.item.subscriptionCycleStartDate;
        params.to = this.item.subscriptionCycleEndDate;
        params.producerId = this.rootStore.routerStore.routerState.params.id;
        return this.routeStore.findProducerReportPayoutUserEvents(params);
    }

    onFetchError(err) {
        this.rootStore.notificationStore.error(
            "An unexpected error occurred while trying to load data. Please contact the support team.",
            err
        );
    }

    @action.bound
    setUser(user) {
        this.user = user;
    }

    @action.bound
    setIsCollapsed(value) {
        this.isCollapsed = value;
    }
}

export default ProducerPayoutUserEventListViewStore;
