import React from "react";
import { PropTypes } from "prop-types";
import { dateFormatter } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";
import { isProducerReportComplete } from "common/utils";

function ProducerPreviewReportPeriodCellTemplate({ data }) {
    const isCompleted = isProducerReportComplete(data.monthOfYear, data.periodEndDate);

    return (
        <React.Fragment>
            {dateFormatter.format(data.monthOfYear, {
                format: applicationDefaults.formats.monthShort,
                timeZone: "utc",
            })}
            {!isCompleted ? <span className="u-mar--left--tny u-type--color--opaque">(In Progress)</span> : null}
        </React.Fragment>
    );
}

ProducerPreviewReportPeriodCellTemplate.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ProducerPreviewReportPeriodCellTemplate;
