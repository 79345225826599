import React from "react";
import { PropTypes } from "prop-types";
import { action, observable, makeObservable } from "mobx";
import { observer } from "mobx-react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { isFunction } from "lodash";
import "../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

@observer
class RichTextEditorTemplate extends React.Component {
    @observable.ref editorState = EditorState.createEmpty();

    constructor(props) {
        super();
        makeObservable(this);
        this.onChange = props.onChange;
        if (props.value) {
            const content = htmlToDraft(props.value);
            if (content) {
                const contentState = ContentState.createFromBlockArray(
                    content.contentBlocks
                );
                const editorState = EditorState.createWithContent(contentState);
                this.setEditorState(editorState);
            }
        }
    }

    @action.bound
    setEditorState(editorState) {
        this.editorState = editorState;
    }

    @action.bound
    onEditorStateChange(editorState) {
        this.setEditorState(editorState);
        if (isFunction(this.onChange)) {
            this.onChange(
                draftToHtml(convertToRaw(editorState.getCurrentContent()))
            );
        }
    }

    render() {
        return (
            <Editor
                editorState={this.editorState}
                toolbar={{
                    options: ["inline", "link"],
                    inline: {
                        options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                        ],
                    },
                }}
                placeholder={this.props.placeholder}
                onEditorStateChange={this.onEditorStateChange}
            />
        );
    }
}

RichTextEditorTemplate.propTypes = {
    field: PropTypes.object,
    t: PropTypes.func,
};

export default RichTextEditorTemplate;
