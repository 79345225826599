import {
    action,
    computed,
    observable,
    reaction,
    runInAction,
    makeObservable,
} from "mobx";

class MrbBaseUserStore {
    @observable.ref applicationUser = null;
    @observable.ref permissions = {};
    _resolveUserPromise = null;

    @observable isInitialized = false;

    @computed get user() {
        return this.applicationUser;
    }

    /**
     * @constructor
     * @param {Core.Stores.RootStore} rootStore - The instance of the RootStore object.
     * @description Initializes a new instance of the MrbUserStore.
     */
    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

        reaction(
            () => this.applicationUser,
            () => {
                this.resetPermissions();
            }
        );

        this.hasPermission = this.hasPermission.bind(this);
    }

    async initialize() {
        if (!this.isInitialized) {
            if (this.rootStore.authenticationStore.isAuthenticated) {
                await this.resolveUser();
            }
            runInAction(() => {
                this.isInitialized = true;
            });
        }
    }

    resolveUser() {
        throw new Error("Method not implemented.");
    }

    removeUser() {
        throw new Error("Method not implemented.");
    }

    hasPermission(authorization) {
        throw new Error("Method not implemented.");
    }

    /**
     * @function
     * @protected
     * @description Reset authenticated user permissions.
     */
    @action.bound
    resetPermissions() {
        this.permissions = {};
    }
}

export default MrbBaseUserStore;
