import { BaseApiRouteService } from "mrb/core";

class ProducerPreviewReportPeriodCellApiRouteService extends BaseApiRouteService {
    constructor() {
        super("producer/reports");
    }

    findProducerReportPayoutUserEvents(filter) {
        return super.find(
            `${this.base}/payout-user-events/{?producerId,userId,deviceIds,from,to,page,rpp,sort,embed,fields}`,
            filter
        );
    }

    findProducerReports(filter) {
        return super.find(`${this.base}/{?producerId,from,to,page,rpp,sort,embed,fields}`, filter);
    }

    getProducerPaymentSummary(producerId, options) {
        return super.get(`${this.base}/summary{?producerId,monthOfYear}`, producerId, options);
    }

    findProducerReportItems(filter) {
        return super.find(`${this.base}/items{?producerId,searchQuery,monthOfYear,page,rpp,sort,embed,fields}`, filter);
    }
}

export default ProducerPreviewReportPeriodCellApiRouteService;
