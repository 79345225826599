import React from "react";
import { PropTypes } from "prop-types";
import { MrbInfiniteScrollGrid } from "mrb/components/infinite-scroll-grid";
import { AnalyticsPremiumListLoader } from "administration/analytics/components";
import { numberFormatter } from "common/utils";
import { currencyFormatter } from "mrb/common/formatting";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { observer } from "mobx-react";
import { mergeCss } from "mrb/common/utils";
import { StatsWidgetEmptyState } from "common/components/empty-state";

function ProducerPaymentReportTemplate({ producerPaymentReportPreviewViewStore, scrollParent, t, listClassName }) {
    const { gridStore } = producerPaymentReportPreviewViewStore;
    return (
        <React.Fragment>
            <div className="c-analytics__header">
                <span className="c-analytics__header__item c-analytics__item--30">User</span>
                <span className="c-analytics__header__item c-analytics__item--20 u-type--center">
                    # of Browse Playbacks
                </span>
                <span className="c-analytics__header__item c-analytics__item--30 u-type--center">
                    Total # of Premium Browse Playbacks
                </span>
                <span className="c-analytics__header__item c-analytics__item--20 u-type--center">
                    Browse Payment Share
                </span>
                <span className="c-analytics__header__item c-analytics__item--20 u-type--center">
                    Producer's Browse Payment
                </span>
                <span className="c-analytics__header__item c-analytics__item--20 u-type--center">
                    # of Shuffle Playbacks
                </span>
                <span className="c-analytics__header__item c-analytics__item--30 u-type--center">
                    Total # of Premium Shuffle Playbacks
                </span>
                <span className="c-analytics__header__item c-analytics__item--20 u-type--center">
                    Browse Shuffle Share
                </span>
                <span className="c-analytics__header__item c-analytics__item--20 u-type--center">
                    Producer's Shuffle Payment
                </span>
                <span className="c-analytics__header__item c-analytics__item--20 u-type--right">Total Payment</span>
            </div>
            <div>
                <MrbInfiniteScrollGrid
                    scrollParent={scrollParent}
                    loaderRenderer={() => <AnalyticsPremiumListLoader podcastGruop={false} row={8} />}
                    loadMoreLoader={<AnalyticsPremiumListLoader row={3} podcastGruop={false} />}
                    emptyStateRenderer={() => (
                        <StatsWidgetEmptyState classNameExtend="u-padd--top--lrg u-padd--bottom--lrg" />
                    )}
                    classNameOverride={mergeCss("c-analytics__list", listClassName ? listClassName : null)}
                    store={gridStore}
                    cellRenderer={(props) => <GridItem {...props} />}
                />
            </div>
        </React.Fragment>
    );
}

ProducerPaymentReportTemplate.propTypes = {
    producerPaymentReportPreviewViewStore: PropTypes.object.isRequired,
    scrollParent: PropTypes.any,
    t: PropTypes.func,
};

function GridItem({ item }) {
    return (
        <div className="c-analytics__list__item">
            <div className="c-analytics__item--30 u-type--base">
                <MrbRouterLink
                    routeName="master.administration.analytics.user-device-activity"
                    params={{ userId: item.userId }}
                >
                    {item.firstName} {item.lastName}
                </MrbRouterLink>
            </div>
            <div className="c-analytics__item--20 u-type--center">{numberFormatter.format(item.browsePlaybacks)}</div>
            <div className="c-analytics__item--30 u-type--center u-type--color--opaque">
                {numberFormatter.format(item.totalBrowsePlaybacks)}
            </div>
            <div className="c-analytics__item--20 u-type--center">
                {currencyFormatter.format(item.browsePaymentShare, { currency: "USD" })}
            </div>
            <div className="c-analytics__item--20 u-type--right">
                {currencyFormatter.format(item.browseProducerPayment, { currency: "USD" })}
            </div>
            <div className="c-analytics__item--20 u-type--center">{numberFormatter.format(item.shufflePlaybacks)}</div>
            <div className="c-analytics__item--30 u-type--center u-type--color--opaque">
                {numberFormatter.format(item.totalShufflePlaybacks)}
            </div>
            <div className="c-analytics__item--20 u-type--center">
                {currencyFormatter.format(item.shufflePaymentShare, { currency: "USD" })}
            </div>
            <div className="c-analytics__item--20 u-type--right">
                {currencyFormatter.format(item.shuffleProducerPayment, { currency: "USD" })}
            </div>
            <div className="c-analytics__item--20 u-type--right">
                {currencyFormatter.format(item.totalProducerPayment, { currency: "USD" })}
            </div>
        </div>
    );
}

export default observer(ProducerPaymentReportTemplate);
