import { toast } from "react-toastify";
import { merge } from "lodash";

const DefaultOptions = {
    t: (i) => i,
};
class MrbNotificationProvider {
    constructor(options) {
        const { t } = merge(DefaultOptions, options);
        this.t = t;
    }

    success(message, options = null) {
        return toast.success(this.t(message), options);
    }

    warning(message, options = null) {
        return toast.warning(this.t(message), options);
    }

    error(message, options = null) {
        return toast.error(this.t(message), options);
    }

    info(message, options = null) {
        return toast.info(this.t(message), options);
    }
}

export default MrbNotificationProvider;
