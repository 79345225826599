import React from "react";
import { PropTypes } from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { timeZoneConverter } from "../../common/utils";
import moment from "moment";
import { mergeOrOverrideCss, mergeCss } from "../../common/utils";
import { observer } from "mobx-react";

function MrbDatePicker({
    value,
    onChange,
    placeholder = "--/--/----",
    isClearable = true,
    todayButtonLabel = "Today",
    disabled,
    minDate,
    maxDate,
    timeZone,
    selectsStart,
    selectsEnd,
    startDate,
    endDate,
    classNameExtend,
    classNameOverride,
    monthsShown,
    showMonthYearPicker,
    dateFormat,
    t = (i) => i,
}) {
    let internalValue = null,
        internalStartDate = null,
        internalEndDate = null;
    if (value) {
        internalValue = createInternalValue(value, timeZone);
    }
    if (startDate) {
        internalStartDate = createInternalValue(startDate, timeZone);
    }
    if (endDate) {
        internalEndDate = createInternalValue(endDate, timeZone);
    }

    function internalOnChange(value) {
        let parsedValue;
        if (value) {
            if (timeZone) {
                parsedValue = timeZoneConverter.toISOString(value, timeZone);
            } else {
                parsedValue = value.toISOString();
            }
        }
        onChange(parsedValue);
    }

    return (
        <DatePicker
            selected={internalValue}
            onChange={internalOnChange}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            startDate={internalStartDate}
            endDate={internalEndDate}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText={placeholder}
            disabled={disabled}
            isClearable={isClearable}
            todayButton={t(todayButtonLabel)}
            monthsShown={monthsShown}
            showMonthYearPicker={showMonthYearPicker}
            popperClassName={mergeCss(todayButtonLabel ? "react-datepicker--has-btn" : null)}
            dateFormat={dateFormat}
            className={mergeOrOverrideCss("c-input c-input--text c-input--base", classNameOverride, classNameExtend)}
        />
    );
}

MrbDatePicker.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    todayButtonLabel: PropTypes.string,
    classNameExtend: PropTypes.string,
    classNameOverride: PropTypes.string,
    monthsShown: PropTypes.number,
    showMonthYearPicker: PropTypes.bool,
    dateFormat: PropTypes.string,
    t: PropTypes.func,
};

function createInternalValue(value, timeZone) {
    if (timeZone) {
        return timeZoneConverter.toJsDate(value, timeZone);
    } else {
        return moment.utc(value).toDate();
    }
}

export default observer(MrbDatePicker);
