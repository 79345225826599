import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(ApplicationSettings.stripePublishableKey);

function stripeProvider(Component) {
    return class extends React.Component {
        render() {
            return (
                <Elements stripe={stripePromise}>
                    <Component {...this.props} />
                </Elements>
            );
        }
    };
}

export default stripeProvider;
