import { action } from "mobx";
import { Form } from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import { merge, isFunction, isEmpty, forEach, isObject, isArray } from "lodash";
import { MrbBaseField } from "./";
import { mrbDefaultFormRules } from "./validation";

const DefaultOptions = {
    validationDebounceWait: 50,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnChangeAfterSubmit: true,
};

class MrbBaseForm extends Form {
    constructor(
        hooks,
        fields,
        options = {
            customRules: mrbDefaultFormRules,
            mapRuleValidationMessage: null,
        }
    ) {
        super(
            { fields },
            {
                plugins: {
                    dvr: dvr({
                        package: validatorjs,
                        extend: ({ form, validator }) => {
                            // add custom rules
                            if (!isEmpty(options.customRules)) {
                                forEach(options.customRules, (customRule, key) => {
                                    validator.register(
                                        key,
                                        (value, req, attr) => customRule.rule(value, req, attr, form),
                                        isFunction(options.mapRuleValidationMessage)
                                            ? options.mapRuleValidationMessage(customRule)
                                            : customRule.message
                                    );
                                });
                            }
                        },
                    }),
                },
                hooks,
                options: merge({}, DefaultOptions, options),
            }
        );
    }

    // eslint-disable-next-line
    @action.bound
    update(obj, convertNullToEmptyString = false) {
        this.set("value", obj);
        this.set("initial", obj);
    }

    @action.bound
    submit(obj) {
        // trim all inputs on submit
        const vals = this.values();
        if (isObject(vals) && !isArray(vals)) {
            const valKeys = Object.keys(vals);

            valKeys.forEach((vk) => {
                if (typeof vals[vk] === "string") {
                    vals[vk] = vals[vk].trim();
                    if (vals[vk] === "true") vals[vk] = true;
                    else if (vals[vk] === "false") vals[vk] = false;
                }
            });
        }
        this.set("value", vals);

        super.submit(obj);
    }

    @action.bound
    setFieldsDisabled(disabled) {
        this.each((field) => field.set("disabled", disabled));
    }

    makeField(props) {
        return new MrbBaseField(props);
    }
}

export default MrbBaseForm;
