import React from "react";
import { PropTypes } from "prop-types";
import { mergeOrOverrideCss } from "../../common/utils";

function MrbCollapsiblePanelHeader({
    title,
    t = (i) => i,
    classNameOverride,
    classNameExtend,
    headerRender,
    isCollapsed,
    onChangeCollapse,
}) {
    return (
        <div
            onClick={onChangeCollapse}
            className={mergeOrOverrideCss(
                "c-collapse-panel__header",
                classNameOverride,
                classNameExtend
            )}
        >
            {headerRender ? (
                headerRender({
                    title,
                    t,
                    isCollapsed,
                })
            ) : (
                <React.Fragment>
                    <div className="u-display--flex u-display--flex--ai--center">
                        {t(title)}
                    </div>
                    <i
                        className={`u-icon u-icon--med u-icon--arrow-down ${
                            isCollapsed ? "u-rotate--180" : ""
                        } u-cursor--pointer`}
                    ></i>
                </React.Fragment>
            )}
        </div>
    );
}

MrbCollapsiblePanelHeader.propTypes = {
    title: PropTypes.string,
    t: PropTypes.func,
    classNameOverride: PropTypes.string,
    classNameExtend: PropTypes.string,
    onChangeCollapse: PropTypes.func.isRequired,
};

export default MrbCollapsiblePanelHeader;
