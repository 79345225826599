import { Error } from "../models";
import { trim, isUndefined, isNull } from "lodash";

class ErrorFormatterService {
    mergeMessages = (message, dataMessage) => {
        message = message || "";
        dataMessage = dataMessage || "";

        return trim(message + " " + dataMessage);
    };

    getErrorObject = (data, message, status, headers, config) => {
        let result = new Error();
        if (!isUndefined(data) && !isNull(data)) {
            if (!isUndefined(data.message)) {
                result.message = message = this.mergeMessages(
                    message,
                    data.message
                );
            } else if (!isUndefined(message)) {
                result.message = this.mergeMessages(
                    result.message,
                    this.mergeMessages(message, "HTTP Status: " + status)
                );
            } else if (!isUndefined(data)) {
                result.message = data;
            }

            if (!isUndefined(data.error) && !isNull(data.error)) {
                result.message = this.mergeMessages(
                    message,
                    data.error.Message
                );
                result.error = data.error;
            } else if (!isUndefined(data.exceptionMessage)) {
                let exception = data;
                while (!isUndefined(exception.innerException)) {
                    exception = exception.innerException;
                }
                result.message = this.mergeMessages(
                    message,
                    exception.exceptionMessage
                );
                result.error = data;
            } else if (!isUndefined(data.message)) {
                result.error = data;
            } else {
                result.error = {
                    message: message,
                    data: data,
                    status: status,
                    headers: headers !== undefined ? headers : null,
                    config: config,
                };
            }

            if (!isUndefined(data.modelState)) {
                result.message = this.mergeMessages(
                    result.message,
                    " Model state is invalid."
                );
            }
        }
        return result;
    };
}

const errorFormatterService = new ErrorFormatterService();

export default errorFormatterService;
