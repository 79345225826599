import { BaseService } from "mrb/core";
import { FeaturedSectionFileStreamApiRouteService } from "common/services";

class FeaturedSectionFileStreamService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new FeaturedSectionFileStreamApiRouteService());
    }

    get(id, params, abortSignal = null) {
        const url = this.routeService.get(id, params);
        return this.apiClient.get(url, null, abortSignal);
    }

    getFeaturedSectionCoverImageByGridItemId(featuredGridItemId, params, abortSignal = null) {
        const url = this.routeService.getFeaturedSectionCoverImageByGridItemId(featuredGridItemId, params);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default FeaturedSectionFileStreamService;
