import { action, observable, makeObservable, runInAction } from "mobx";
import { BaseAnalyticsViewStore } from "administration/analytics/stores";
import { httpStatusCodes } from "mrb/core/constants";
import { LineChartStore } from "common/components/charts";
import { spanDataSetToFullDateRange, createTimeRangeChartLabel } from "administration/analytics/utils";
import { map } from "lodash";

class BasePortalDetailedActivityViewStore extends BaseAnalyticsViewStore {
    @observable.ref summaryData = null;

    constructor(rootStore, { chartLabel }) {
        super(rootStore);
        makeObservable(this);

        this.chartStore = new LineChartStore(this.rootStore, {
            label: chartLabel,
            actions: {
                preprocessData: (data) => {
                    const spannedDataset = spanDataSetToFullDateRange(data, (periodStart, periodEnd) => {
                        return {
                            periodStartDate: periodStart,
                            periodEndDate: periodEnd,
                            count: 0,
                        };
                    });
                    data.data = spannedDataset;
                    return data;
                },
                mapLabels: (data) => createTimeRangeChartLabel(data),
                mapDataset: (data) => {
                    return map(data.data, (item) => item.count);
                },
                find: async (params, abortController) => {
                    const filter = this.createParams(params);
                    return this.getActivityChartData(filter, abortController);
                },
                onError: (error) => {
                    if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
                        return;
                    }
                    this.rootStore.notificationStore.error(
                        "An unexpected error occurred while trying to load chart data.",
                        error
                    );
                },
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        });
    }

    createParams(filter) {
        const obj = {
            from: filter.from,
            to: filter.to,
            previousCycleFrom: filter.previousCycleFrom,
            previousCycleTo: filter.previousCycleTo,
            timeZone: filter.timeZone,
        };

        return obj;
    }

    getActivityChartData(filter, abortController) {
        throw new Error("Method not implemented");
    }

    fetchSummary(filter, abortController) {
        throw new Error("Method not implemented");
    }

    @action.bound
    setSummaryData(data) {
        this.summaryData = data;
    }

    fetch(filter, abortController) {
        return Promise.all([
            this._fetchSummary(filter, abortController),
            this.chartStore.generate(filter, abortController),
        ]);
    }

    async _fetchSummary(filter, abortController) {
        try {
            this.loaderStore.suspend();
            const params = {
                from: filter.from,
                to: filter.to,
                previousCycleFrom: filter.previousCycleFrom,
                previousCycleTo: filter.previousCycleTo,
            };
            const data = await this.fetchSummary(params, abortController);
            data.filteredBy = {
                range: filter.range,
                timeZone: filter.timeZone,
                ...params,
            };
            runInAction(() => {
                this.setSummaryData(data);
                this.loaderStore.resume();
            });
        } catch (error) {
            this.loaderStore.resume();
            if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
                return;
            }
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load summary data.",
                error
            );
        }
    }
}

export default BasePortalDetailedActivityViewStore;
