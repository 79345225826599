import { BaseService } from "mrb/core";
import { PremiumPaymentAnalyticsApiRouteService } from "common/services";

class PremiumPaymentAnalyticsService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new PremiumPaymentAnalyticsApiRouteService());
    }

    getPodcastGroupPaymentStatistic(id, options, abortSignal = null) {
        const url = this.routeService.getPodcastGroupPaymentStatistic(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastPaymentStatistic(id, options, abortSignal = null) {
        const url = this.routeService.getPodcastPaymentStatistic(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getStationPaymentStatistic(id, options, abortSignal = null) {
        const url = this.routeService.getStationPaymentStatistic(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    findPremiumPaymentSummary(filter, abortSignal = null) {
        const url = this.routeService.findPremiumPaymentSummary(filter);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default PremiumPaymentAnalyticsService;
