import React from "react";
import { PropTypes } from "prop-types";
import { applicationDefaults } from "common/constants";
import { MrbContentLoader } from "mrb/components/loader";
import { currencyFormatter } from "mrb/common/formatting";
import { ProducerLabel, ProducerSummaryLoader } from "common/components/producer";
import { observer } from "mobx-react";
import { dateFormatter } from "baasic-react-bootstrap/src/common";
import { isNil } from "lodash";

function ProducerPreviewSummaryTemplate({ producerPreviewViewStore, t }) {
    const { summaryData, loaderStore } = producerPreviewViewStore;

    return (
        <div className="u-mar--bottom--sml">
            <h1 className="u-type--base u-mar--bottom--sml">Summary</h1>
            <div className="c-card--primary c-card--med">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <ProducerSummaryLoader />}>
                    {() => (
                        <React.Fragment>
                            <div className="u-animation--fadeIn">
                                {summaryData && (
                                    <React.Fragment>
                                        <div className="u-animation--fadeIn">
                                            <div className="row u-mar--bottom--sml">
                                                <div className="col col-sml-7">
                                                    <p className="u-type--sml u-type--color--opaque">Producer</p>
                                                    <p className="u-display--flex u-display--flex--ai--center">
                                                        <ProducerLabel
                                                            name={summaryData.producer.name}
                                                            isUser={!isNil(summaryData.producer.user)}
                                                        />
                                                    </p>
                                                </div>
                                                <div className="col col-sml-5">
                                                    <ProducerPositionIndicator
                                                        topPaidProducerStatus={summaryData.topPaidProducerStatus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="u-mar--bottom--sml">
                                                <div>
                                                    <p className="u-type--sml u-type--color--opaque">Total Revenue</p>
                                                    <div className="c-dashboard__count--increase">
                                                        <p className="c-dashboard__count--lrg">
                                                            {currencyFormatter.format(
                                                                summaryData.topPaidProducerStatus
                                                                    ? summaryData.topPaidProducerStatus.totalPayment
                                                                    : 0,
                                                                {
                                                                    currency: "USD",
                                                                }
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            {summaryData.lastProducerReport && (
                                                <div>
                                                    <p className="u-type--sml u-type--color--opaque">
                                                        Last Revenue (
                                                        {dateFormatter.format(
                                                            summaryData.lastProducerReport.monthOfYear,
                                                            {
                                                                format: applicationDefaults.formats.monthShort,
                                                                timeZone: "utc",
                                                            }
                                                        )}
                                                        )
                                                    </p>
                                                    <p className="u-type--med u-type--wgt--bold u-type--color--tertiary">
                                                        {currencyFormatter.format(
                                                            summaryData.lastProducerReport.totalProducerPayment,
                                                            {
                                                                currency: "USD",
                                                            }
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
        </div>
    );
}

ProducerPreviewSummaryTemplate.propTypes = {
    producerPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function ProducerPositionIndicator({ topPaidProducerStatus }) {
    if (!topPaidProducerStatus || topPaidProducerStatus.totalPayment === 0) {
        return null;
    }

    return (
        <div>
            <span className="u-type--wgt--semibold">{getNumberWithOrdinal(topPaidProducerStatus.position)}</span>
            <div className="u-type--sml u-type--color--opaque">most paid producer</div>
        </div>
    );
}

function getNumberWithOrdinal(n) {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export default observer(ProducerPreviewSummaryTemplate);
