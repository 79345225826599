import { BaseService } from "mrb/core";
import { GenreLookupApiRouteService } from "common/services";

class GenreLookupService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new GenreLookupApiRouteService());
    }
}

export default GenreLookupService;
