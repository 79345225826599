import { BaseApiRouteService } from "mrb/core";

class TorahAnytimeApiRouteService extends BaseApiRouteService {
    constructor() {
        super("torah-anytime");
    }

    findSpeakers() {
        return super.create(`${this.base}/speakers`);
    }

    findTopics(filter) {
        return super.find(`${this.base}/topics/{?searchQuery,page,rpp,sort,embed,fields}`, filter);
    }

    getSetting() {
        return super.get(`${this.base}/setting`);
    }

    findSpeakersByGender() {
        return super.create(`${this.base}/speakers/gender`);
    }

    findSpeakerIdsByTopicIds() {
        return super.create(`${this.base}/speakers/topics`);
    }
}

export default TorahAnytimeApiRouteService;
