import React from "react";
import { PropTypes } from "prop-types";
import { join, map } from "lodash";

function GenresTemplate({ genres }) {
    if (genres && genres.length > 0) {
        return (
            <p className="u-type--base">
                {join(
                    map(genres, (genre) => genre.name),
                    ", "
                )}
            </p>
        );
    }
    return null;
}

GenresTemplate.propTypes = {
    genres: PropTypes.array,
};

export default GenresTemplate;
