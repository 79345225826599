import React from "react";
import { PropTypes } from "prop-types";
import { dateFormatter } from "mrb/common/formatting";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { applicationDefaults } from "common/constants";
import { observer } from "mobx-react";
import { MrbContentLoader } from "mrb/components/loader";
import { UserAccountSummaryLoader } from "administration/analytics/components";

function UserAccountSummaryTemplate({ userDeviceActivityViewStore, t }) {
    const { userPortalSummary, loaderStore } = userDeviceActivityViewStore;
    return (
        <div>
            <h1 className="c-analytics__title">Summary</h1>
            <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <UserAccountSummaryLoader />}>
                {() => (
                    <div className="c-card--primary c-card--med">
                        {userPortalSummary && (
                            <div className="row">
                                <div className="col col-sml-12 col-lrg-6">
                                    <div className="u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">User</p>
                                        <p>
                                            <MrbRouterLink
                                                routeName="master.administration.user.edit"
                                                params={{ id: userPortalSummary.userId }}
                                            >
                                                {userPortalSummary.firstName} {userPortalSummary.lastName}
                                            </MrbRouterLink>{" "}
                                        </p>
                                    </div>
                                    <div className="u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">Registration Date</p>
                                        <p>
                                            {dateFormatter.format(userPortalSummary.dateOfRegistration, {
                                                format: applicationDefaults.formats.date,
                                            })}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="u-type--sml u-type--color--opaque">Registered Devices</p>
                                        <p>{userPortalSummary.numberOfDevices}</p>
                                    </div>
                                </div>

                                <div className="col col-sml-12 col-lrg-6">
                                    <SubscriptionInfo subscription={userPortalSummary.subscription} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </MrbContentLoader>
        </div>
    );
}

UserAccountSummaryTemplate.propTypes = {
    userDeviceActivityViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function SubscriptionInfo({ subscription }) {
    return (
        <React.Fragment>
            <div className="u-mar--bottom--sml">
                <p className="u-type--sml u-type--color--opaque">Premium Subscription</p>
                {subscription ? (
                    <p
                        className={`c-payment__subscription__status c-payment__subscription__status--${subscription.subscriptionStatus.abrv.toLowerCase()}`}
                    >
                        {subscription.subscriptionStatus.name}
                    </p>
                ) : (
                    <p>-</p>
                )}
            </div>
            {subscription && (
                <React.Fragment>
                    <div className="u-mar--bottom--sml">
                        <p className="u-type--sml u-type--color--opaque">Subscription Start Date</p>
                        <p>
                            {subscription && subscription.startDate
                                ? dateFormatter.format(subscription.startDate, {
                                      format: applicationDefaults.formats.date,
                                  })
                                : "-"}
                        </p>
                    </div>
                    <div>
                        <p className="u-type--sml u-type--color--opaque">Subscription End Date</p>
                        <p>
                            {subscription && subscription.endDate
                                ? dateFormatter.format(subscription.endDate, {
                                      format: applicationDefaults.formats.date,
                                  })
                                : "-"}
                        </p>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default observer(UserAccountSummaryTemplate);
