import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "mrb/components/button";
import { ContentPolicyModal } from "application/common/components/content-policy";
import { observer } from "mobx-react";

function ContentPolicyWidgetTemplate({ contentPolicyWidgetViewStore, t }) {
    const { contentPolicyModal, displayContentPolicy, isContentPolicyVisible } = contentPolicyWidgetViewStore;
    if (!isContentPolicyVisible) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="c-content-policy__card">
                <p className="u-type--sml u-type--wgt--bold">
                    <i className="u-icon u-icon--med u-icon--info u-mar--right--tny"></i>
                    Content Policy
                </p>
                <div className="u-display--flex u-mar--top--micro">
                    <MrbButton onClick={displayContentPolicy} label="Learn More" classNameOverride="c-btn c-btn--sml c-btn--anchor c-btn--anchor--underline" />
                </div>
            </div>
            <ContentPolicyModal modalParams={contentPolicyModal} />
        </React.Fragment>
    );
}

ContentPolicyWidgetTemplate.propTypes = {
    contentPolicyWidgetViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(ContentPolicyWidgetTemplate);
