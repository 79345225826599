import React from "react";
import PropTypes from "prop-types";
import {
    MrbEditFormContent,
    MrbEditFormFooter,
    MrbEditFormValidation,
} from "./content";
import { observer } from "mobx-react";

function MrbEditFormLayout({
    form,
    children,
    t,
    layoutFooterVisible,
    footer,
    onClickCancel,
    onSubmit,
    submitLabel,
    cancelLabel,
    hideCancel = false,
    submitDisabled = false,
}) {
    return (
        <React.Fragment>
            <form onSubmit={onSubmit || form.onSubmit} className="c-form">
                <MrbEditFormContent t={t}>{children}</MrbEditFormContent>
                <MrbEditFormValidation
                    form={form}
                    t={t}
                ></MrbEditFormValidation>
                <MrbEditFormFooter
                    form={form}
                    footer={footer}
                    visible={layoutFooterVisible}
                    t={t}
                    onSubmit={onSubmit}
                    onClickCancel={onClickCancel}
                    submitLabel={submitLabel}
                    cancelLabel={cancelLabel}
                    hideCancel={hideCancel}
                    submitDisabled={submitDisabled}
                ></MrbEditFormFooter>
            </form>
        </React.Fragment>
    );
}

MrbEditFormLayout.propTypes = {
    form: PropTypes.object,
    children: PropTypes.any,
    t: PropTypes.func,
    layoutFooterVisible: PropTypes.bool,
    footer: PropTypes.any,
    onClickCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    submitLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    hideCancel: PropTypes.bool,
    submitDisabled: PropTypes.bool,
};

export default observer(MrbEditFormLayout);
