import React from "react";
import { PropTypes } from "prop-types";
import { PremiumContentListItem, ExplorePremiumLoader } from "application/payment/components";
import { MrbInfiniteScrollGrid } from "mrb/components/infinite-scroll-grid";
import { MrbRadioButtons } from "mrb/components/radio";
import { isNil } from "lodash";
import { observer } from "mobx-react";

function PremiumContentListTemplate({ premiumContentListViewStore }) {
    const {
        gridStore,
        routeStore,
        premiumSubscriptionsViewStore: { selectedSubscriptionPlan },
    } = premiumContentListViewStore;
    if (isNil(selectedSubscriptionPlan)) {
        return null;
    }
    return (
        <div className="order-1">
            <p className="u-type--lrg u-mar--bottom--sml">Explore {selectedSubscriptionPlan.name} Content</p>
            <ContentFilter premiumContentListViewStore={premiumContentListViewStore} />

            <MrbInfiniteScrollGrid
                loaderRenderer={() => <ExplorePremiumLoader className={"c-container--base"} noButton />}
                // loadMoreLoader={<TopTrendingListViewMoreLoader loadMore row={3} noButton />}
                classNameOverride="c-container--base"
                store={gridStore}
                cellRenderer={(props) => (
                    <PremiumContentListItem
                        {...props}
                        premiumContentListViewStore={premiumContentListViewStore}
                        routeStore={routeStore}
                    />
                )}
            />
        </div>
    );
}

const ContentFilter = observer(function ContentFilter({ premiumContentListViewStore, t }) {
    const { contentTypeOptions, queryUtility, changeContentFilter } = premiumContentListViewStore;
    if (isNil(contentTypeOptions)) {
        return null;
    }
    if (contentTypeOptions.length <= 1) {
        return null;
    }
    return (
        <div className="u-mar--bottom--sml">
            <MrbRadioButtons
                inputClassNameExtend="c-segmented--secondary c-segmented--2"
                id="premium-content-type-control"
                name="premium-content-type-control"
                options={contentTypeOptions}
                value={queryUtility.filter.contentType}
                onChange={changeContentFilter}
                t={t}
            />
        </div>
    );
});

PremiumContentListTemplate.propTypes = {
    premiumContentListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(PremiumContentListTemplate);
