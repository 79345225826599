import React, { useRef, useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

const PaymentCardLoaderTemplate = (props) => {
    const ref = useRef(null);
    const [loaderWidth, setLoaderWidth] = useState(0);
    const loaderHeight = 580;

    useEffect(() => {
        setLoaderWidth(ref.current.offsetWidth);
    }, []);

    return (
        <div ref={ref} className="c-payment__card c-payment__card--sml">
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                {/* Img */}
                <rect x="0" y="0" rx="6" ry="6" width="100%" height="200" />

                {/* Price */}
                <rect x="0" y="223" rx="6" ry="6" width="150" height="22" />
                <rect x={loaderWidth - 80} y="223" rx="6" ry="6" width="80" height="22" />

                {/* Separator */}
                <rect x="0" y="263" rx="6" ry="6" width="100%" height="1" />

                {/* info */}
                <rect x="0" y="288" rx="6" ry="6" width="160" height="22" />

                <InfoList start={332} />

                {/* Separator */}
                <rect x="0" y="494" rx="6" ry="6" width="100%" height="1" />

                {/* Button */}
                <rect x="0" y="513" rx="6" ry="6" width="100%" height="42" />

                <rect x={loaderWidth / 2 - 75} y="566" rx="6" ry="6" width="150" height="12" />
            </ContentLoader>
        </div>
    );
};

const InfoList = ({ start, list = 4 }) => {
    const infoList = [];
    let y = start;

    for (let i = 0; i < list; i++) {
        infoList.push(
            <React.Fragment>
                {/* Arrow */}
                <rect x="0" y={y} rx="6" ry="6" width="20" height="12" />
                {/* Text */}
                <rect x="30" y={y} rx="6" ry="6" width="80%" height="12" />
            </React.Fragment>
        );

        y += 39;
    }

    return <React.Fragment>{infoList}</React.Fragment>;
};

export default PaymentCardLoaderTemplate;
