import React from "react";
import { PropTypes } from "prop-types";
import { applicationDefaults } from "common/constants";
import { dateFormatter, currencyFormatter } from "mrb/common/formatting";
import { MrbTable } from "mrb/components/table";
import { TableNoDataOverlay } from "common/components";
import { observer } from "mobx-react";
import { mergeCss } from "mrb/common/utils";
import { producerPlaybackTimeConverter, numberFormatter } from "common/utils";

function ProducerPaymentReportCellTemplate({
    item,
    producerPaymentReportPreviewViewStore,
    producerPayoutUserEventListViewStore,
    index,
    t,
}) {
    const { gridStore, isCollapsed, setIsCollapsed } = producerPayoutUserEventListViewStore;
    const { summaryData, maskUserNames } = producerPaymentReportPreviewViewStore;
    return (
        <div className={mergeCss(`c-producers__wrapper`, isCollapsed ? "isCollapsed" : "")}>
            <div className="c-producers__item u-cursor--pointer" onClick={() => setIsCollapsed(!isCollapsed)}>
                <div className="c-producers__item__icon">
                    <i
                        className={mergeCss(
                            "u-icon u-icon--base u-icon--arrow-down",
                            !isCollapsed ? "" : "u-rotate--180"
                        )}
                    ></i>
                </div>
                <div className="c-producers__item__col">
                    {maskUserNames
                        ? t("COMMON.PRODUCER.REPORT.PLACEHOLDERS.USER", { number: index + 1 })
                        : `${item.user.firstName} ${item.user.lastName}`}
                </div>
                <div className="c-producers__item__col">
                    {dateFormatter.format(item.subscriptionCycleStartDate, {
                        format: applicationDefaults.formats.dateTime,
                    })}
                </div>
                <div className="c-producers__item__col">
                    {dateFormatter.format(item.subscriptionCycleEndDate, {
                        format: applicationDefaults.formats.dateTime,
                    })}
                </div>
                <div className="c-producers__item__col">
                    <PlaybackTime
                        reportVersion={summaryData.currentMonthReportVersion}
                        value={item.producerPlaybacks}
                    />
                </div>
                <div className="c-producers__item__col">{numberFormatter.format(item.producerPlaybacks)}</div>
                <div className="c-producers__item__col">
                    <PlaybackTime
                        reportVersion={summaryData.currentMonthReportVersion}
                        value={item.totalUserPlaybacks}
                    />
                </div>
                <div className="c-producers__item__col c-producers__item__col--payout">
                    {currencyFormatter.format(item.producerPayment, {
                        currency: "USD",
                    })}
                </div>
                <div className="c-producers__item__col">{item.subscriptionPlan.name}</div>
            </div>

            {isCollapsed && (
                <MrbTable
                    t={t}
                    tableStore={gridStore}
                    className={mergeCss(
                        `ag-theme-alpine`,
                        gridStore.data.length < 5 && gridStore.data.length !== 0 ? "ag-theme-alpine--sml" : "",
                        gridStore.data.length === 0 && "ag-theme-alpine--empty"
                    )}
                    emptyStateRender={TableNoDataOverlay}
                />
            )}
        </div>
    );
}

ProducerPaymentReportCellTemplate.propTypes = {
    item: PropTypes.object.isRequired,
};

function PlaybackTime({ reportVersion, value }) {
    const totalPlaybackTime = producerPlaybackTimeConverter(reportVersion, value);
    if (totalPlaybackTime) {
        return totalPlaybackTime;
    }

    return "-";
}

export default observer(ProducerPaymentReportCellTemplate);
