import React from "react";
import Joyride from "react-joyride";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";

const customStyles = {
    options: {
        arrowColor: "#393c42",
        backgroundColor: "#393c42",
        textColor: "#f5f5f5",
        zIndex: 1000,
    },
    tooltip: {
        width: "310px",
    },
    tooltipFooter: {
        marginTop: "0px",
    },
    buttonClose: {
        height: "8px",
        width: "14px",
        padding: "0px",
        position: "absolute",
        right: "4px",
        top: "4px",
        borderRadius: "100%",
    },
    tooltipContent: {
        padding: "10px 0px 14px",
        textAlign: "left",
        fontSize: "14px",
    },
    buttonSkip: {
        padding: 0,
        color: "#ececec",
        opacity: 0.7,
        fontSize: "14px",
    },
    buttonBack: {
        padding: "8px 12px",
        fontSize: "14px",
        color: "#339dff",
    },
    buttonNext: {
        padding: "8px 12px",
        fontSize: "14px",
        backgroundColor: "#339dff",
    },
};

function JoyrideOnboardingTemplate({ joyrideViewStore }) {
    const { steps, run, handleJoyrideCallback, stepIndex } = joyrideViewStore;

    return (
        <React.Fragment>
            {!joyrideViewStore.isTutorialPassed && (
                <Joyride
                    steps={steps}
                    continuous={true}
                    showSkipButton={true}
                    run={run}
                    callback={handleJoyrideCallback}
                    stepIndex={stepIndex}
                    styles={customStyles}
                    locale={{ last: "Finish" }}
                />
            )}
        </React.Fragment>
    );
}

JoyrideOnboardingTemplate.propTypes = {
    joyrideViewStore: PropTypes.object.isRequired,
};

export default observer(JoyrideOnboardingTemplate);
