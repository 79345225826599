import React from "react";
import { MainSidebarTemplate } from "themes/common/components";
import { MainSidebarViewStore } from "common/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new MainSidebarViewStore(rootStore, componentProps),
    "mainSidebarViewStore"
)
class MainSidebar extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MainSidebarTemplate {...this.props} />
            </React.Fragment>
        );
    }
}

export default MainSidebar;
