import React from "react";
import { PropTypes } from "prop-types";
import { MrbCollapsiblePanel } from "mrb/components/collapsible-panel";
import { TogglePremium } from "application/home/components";
import { HTMLRender, Image } from "common/components";
import { PodcastUnavailableInApp } from "application/common/components";
import { observer } from "mobx-react";
import { MrbButton } from "mrb/components/button";
import { mergeCss } from "mrb/common/utils";
import { dateFormatter } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";

function HomeSubPodcastTemplate({ homeSubPodcastViewStore, t }) {
    const { subPodcast, isCollapsed, toggleCollapse, setPanelRef } = homeSubPodcastViewStore;

    return (
        <MrbCollapsiblePanel
            title={subPodcast.title}
            innerRef={setPanelRef}
            t={t}
            isCollapsed={isCollapsed}
            onChangeCollapse={(event) => {
                // Since our control is inside a collapse panel, we must prevent toggle button from triggering the collapse change event
                if (
                    event.target.className &&
                    (event.target.className.includes("c-toggle") || event.target.className.includes("toggle-button"))
                ) {
                    return;
                }
                toggleCollapse();
            }}
            headerClassNameExtend="u-cursor--pointer"
            headerRender={(props) => (
                <SubPodcastPanelHeader {...props} homeSubPodcastViewStore={homeSubPodcastViewStore} />
            )}
        >
            {() => (
                <div className="row">
                    <div className="col col-sml-12 col-xlrg-6 u-mar--bottom--sml">
                        <div className="c-collapse-panel__body__link">
                            <p className="c-collapse-panel__body__label">
                                {t("APPLICATION.HOME.PODCAST.FIELDS.DESCRIPTION_LINK")}
                            </p>
                            {subPodcast.descriptionLink && (
                                <a href={subPodcast.descriptionLink} target="_blank" rel="noreferrer">
                                    {subPodcast.descriptionLink}
                                </a>
                            )}
                        </div>
                    </div>

                    <div className="col col-sml-12 col-xlrg-6 u-mar--bottom--sml">
                        <p className="c-collapse-panel__body__label">{t("APPLICATION.HOME.PODCAST.FIELDS.LOCATION")}</p>
                        <p className="u-type--base">{subPodcast.location && subPodcast.location.name}</p>
                    </div>

                    <div className="col col-sml-12 col-xlrg-6 u-mar--bottom--sml">
                        <p className="c-collapse-panel__body__label">{t("APPLICATION.HOME.PODCAST.FIELDS.LANGUAGE")}</p>
                        <p className="u-type--base">{subPodcast.language && subPodcast.language.name}</p>
                    </div>

                    <div className="col col-sml-12 col-xlrg-6 u-mar--bottom--sml">
                        <p className="c-collapse-panel__body__label">{t("APPLICATION.HOME.PODCAST.FIELDS.GENRES")}</p>
                        {subPodcast.genres}
                    </div>

                    <div className="col col-sml-12 col-xlrg-6">
                        <p className="c-collapse-panel__body__label">
                            {t("APPLICATION.HOME.PODCAST.FIELDS.DESCRIPTION")}
                        </p>
                        <HTMLRender className="c-collapse-panel__body__description">
                            {subPodcast.alternativeDescription}
                        </HTMLRender>
                    </div>
                </div>
            )}
        </MrbCollapsiblePanel>
    );
}

HomeSubPodcastTemplate.propTypes = {
    homeSubPodcastViewStore: PropTypes.object.isRequired,
};

const SubPodcastPanelHeader = observer(function SubPodcastPanelHeader({ homeSubPodcastViewStore, isCollapsed, t }) {
    const {
        subPodcast,
        coverImageUrl,
        isTurnedOn,
        isDisabled,
        toggleSubPodcastStatus,
        contentAccessResult,
        rootStore: { premiumSubscriptionStore, userDeviceStore },
    } = homeSubPodcastViewStore;
    const isLegacySubscriptionValid = premiumSubscriptionStore.isLegacySubscriptionActive(
        subPodcast.legacySubscriptionExpirationDate
    );
    return (
        <React.Fragment>
            <div className="u-display--flex u-display--flex--ai--center">
                <i
                    className={`u-icon u-icon--med u-icon--arrow-down ${
                        isCollapsed ? "u-rotate--180" : ""
                    } u-cursor--pointer u-mar--right--sml`}
                ></i>
                <div
                    className={mergeCss(
                        "c-home__preview__icon u-mar--right--sml",
                        !contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null
                    )}
                >
                    <Image src={coverImageUrl} />
                </div>
                <div>
                    <div className="c-home__preview__title u-mar--bottom--none">
                        <p className={!contentAccessResult.canAccessOnSelectedDevice ? "u-opacity--4" : null}>
                            {subPodcast.title}
                        </p>
                        {!contentAccessResult.canAccessPremium && (
                            <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
                        )}
                    </div>
                    {!contentAccessResult.canAccessOnSelectedDevice && <PodcastUnavailableInApp />}
                    {isLegacySubscriptionValid && (
                        <p className="u-type--sml u-type--color--text">
                            Old subscription until:{" "}
                            <span className="u-type--sml u-type--color--primary">
                                {dateFormatter.format(subPodcast.legacySubscriptionExpirationDate, {
                                    format: applicationDefaults.formats.date,
                                })}
                            </span>
                        </p>
                    )}
                </div>
            </div>

            {userDeviceStore.deviceCount > 0 && contentAccessResult.canAccessOnSelectedDevice && (
                <div>
                    <TogglePremium
                        isPremium={subPodcast.isPremium}
                        canAccessPremiumContent={contentAccessResult.canAccessPremium}
                        t={t}
                    >
                        <MrbButton
                            id={`sub-podcast-status-${subPodcast.id}`}
                            value={isTurnedOn}
                            disabled={isDisabled}
                            onAction={() => {
                                toggleSubPodcastStatus(!isTurnedOn);
                            }}
                            icon="u-icon u-icon--lrg u-icon--premium--white"
                            classNameOverride={mergeCss(
                                "c-btn c-btn--xsml c-btn--status c-btn--rounded",
                                isTurnedOn ? "c-btn--status--enabled" : "c-btn--status--disabled"
                            )}
                        />
                    </TogglePremium>
                </div>
            )}
        </React.Fragment>
    );
});

export default observer(HomeSubPodcastTemplate);
