import { MrbBaseViewStore } from "mrb/core";
import { MrbQueryUtility } from "mrb/core/filter";
import { AnalyticsTimeRangeViewStore } from "administration/analytics/stores";
import { AnalyticsFilter } from "administration/analytics/filters";
import { timeRangeType } from "administration/analytics/constants";
import { merge } from "lodash";

class BaseAnalyticsViewStore extends MrbBaseViewStore {
    _abortController = null;
    _options = {
        filter: null,
        timeRangeOptions: {},
    };

    constructor(rootStore, options) {
        super(rootStore);
        this._options = merge(this._options, options);

        this.queryUtility = new MrbQueryUtility(
            this.rootStore,
            async (filter) => {
                this.fetchResources(filter);
            },
            {
                filter: this._options.filter || new AnalyticsFilter(),
                paramKeyMap: {
                    _range: "range",
                },
                ignoreQueryParams: ["page", "timeZone", "rpp"],
                queryParamMap: {
                    from: {
                        toQuery: (value) => {
                            if (this.queryUtility.filter.range === timeRangeType.custom) {
                                return value;
                            }
                            return undefined;
                        },
                    },
                    to: {
                        toQuery: (value) => {
                            if (this.queryUtility.filter.range === timeRangeType.custom) {
                                return value;
                            }
                            return undefined;
                        },
                    },
                },
            }
        );

        this.createAnalyticsTimeRangeViewStore(this.rootStore, {
            queryUtility: this.queryUtility,
            ...this._options.timeRangeOptions,
        });
    }

    createAnalyticsTimeRangeViewStore(rootStore, params) {
        this.analyticsTimeRangeViewStore = new AnalyticsTimeRangeViewStore(rootStore, params);
    }

    onInit() {
        return this.queryUtility.initialize();
    }

    fetchResources(params) {
        this.abortExistingRequest();
        this._abortController = new AbortController();
        return this.fetch(params);
    }

    async fetch(params, abortController) {
        throw new Error("Method not implemented.");
    }

    abortExistingRequest() {
        if (this._abortController) {
            this._abortController.abort();
        }
    }

    onDestroy() {
        this.analyticsTimeRangeViewStore.destroy();
        this.abortExistingRequest();
    }
}

export default BaseAnalyticsViewStore;
