import { PropTypes } from "prop-types";
import { isNil } from "lodash";
import moment from "moment";

function PremiumTrialExpirationTemplate({ subscription, t }) {
    const todayDate = moment().startOf("day");
    const diff = moment(subscription.trialPeriod.trialEndDate).startOf("day").diff(todayDate, "days");

    if (isNil(subscription.nextBillingDate)) {
        return getTrialExpiresDescription(diff, subscription.product, t);
    }
    return getPremiumStartsDescription(diff, subscription.product, t);
}

PremiumTrialExpirationTemplate.propTypes = {
    subscription: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function getPremiumStartsDescription(daysDiff, product, t) {
    if (daysDiff === 1) {
        return t("APPLICATION.PREMIUM.PREMIUM_STARTS.DESCRIPTION_TOMORROW", { subscriptionPlan: product.name });
    } else if (daysDiff === 0) {
        return t("APPLICATION.PREMIUM.PREMIUM_STARTS.DESCRIPTION_TODAY", { subscriptionPlan: product.name });
    }
    return t("APPLICATION.PREMIUM.PREMIUM_STARTS.DESCRIPTION", { daysNum: daysDiff, subscriptionPlan: product.name });
}

function getTrialExpiresDescription(daysDiff, product, t) {
    if (daysDiff === 1) {
        return t("APPLICATION.PREMIUM.TRIAL_WIDGET.DESCRIPTION_TOMORROW", { subscriptionPlan: product.name });
    } else if (daysDiff === 0) {
        return t("APPLICATION.PREMIUM.TRIAL_WIDGET.DESCRIPTION_TODAY", { subscriptionPlan: product.name });
    }
    return t("APPLICATION.PREMIUM.TRIAL_WIDGET.DESCRIPTION", { daysNum: daysDiff, subscriptionPlan: product.name });
}

export default PremiumTrialExpirationTemplate;
