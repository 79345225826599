import { BaseService } from "mrb/core";
import { NakiNigunAnalyticsApiRouteService } from "common/services";

class NakiNigunAnalyticsService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new NakiNigunAnalyticsApiRouteService());
    }

    getNakiNigunListenersStats(abortSignal = null) {
        const url = this.routeService.getNakiNigunListenersStats();
        return this.apiClient.get(url, null, abortSignal);
    }

    getTrendingAlbumsForProducer(filter, abortSignal = null) {
        const url = this.routeService.getTrendingAlbumsForProducer(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getTrendingAlbumsTotalStreamsForProducer(filter, abortSignal = null) {
        const url = this.routeService.getTrendingAlbumsTotalStreamsForProducer(filter);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default NakiNigunAnalyticsService;
