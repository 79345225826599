import React from "react";
import { PropTypes } from "prop-types";
import { numberFormatter } from "common/utils";
import { TrendingIndicator } from "administration/analytics/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { mergeCss } from "mrb/common/utils";
import { Image } from "common/components";
import { isNil } from "lodash";

function TrendingListItemTemplate({ item }) {
    return (
        <div className="u-animation--fadeIn c-analytics__list__item c-analytics__list__item--secondary">
            <div className="c-analytics__list__item--position">
                <span className="u-type--med">{item.currentCyclePosition}.</span>
                <TrendingIndicator
                    currentCycleNumber={item.currentCyclePosition}
                    previousCycleNumber={item.previousCyclePosition}
                />
            </div>

            <div className="c-analytics__list__img">
                <Image src={item.coverImageUrl} />
            </div>
            <div className="c-analytics__list__information">
                <div className="u-display--flex u-display--flex--ai--center">
                    {item.routeParams ? (
                        <MrbRouterLink
                            className={"c-analytics__list__title c-analytics__list__title--sml"}
                            routeName={item.routeParams.route}
                            params={item.routeParams.params}
                            queryParams={item.routeParams.queryParams}
                            title={item.title}
                        >
                            {item.title}
                        </MrbRouterLink>
                    ) : (
                        <span className="u-type--base">{item.title || "Unknown"}</span>
                    )}
                    <i
                        className={mergeCss(
                            "u-icon u-icon--med u-mar--left--tny",
                            item.isPremium ? "u-icon--premium" : null
                        )}
                    />
                </div>

                <div className="c-analytics__list__information__stats">
                    <div className="c-analytics__list__item--prev-position">
                        <span className="u-type--base u-type--wgt--bold u-type--color--opaque u-mar--right--xxxlrg">
                            {isNil(item.previousCyclePosition) ? "Unlisted" : item.previousCyclePosition}
                        </span>
                        <span className="c-analytics__list__label">PP</span>
                    </div>

                    <div className="c-analytics__list__item--score">
                        <p className="c-analytics__list__count u-type--med">{numberFormatter.format(item.score)}</p>
                        <span className="c-analytics__list__label">P</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

TrendingListItemTemplate.propTypes = {
    item: PropTypes.object.isRequired,
};

export default TrendingListItemTemplate;
