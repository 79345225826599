import { MrbBaseRouteStore } from "mrb/core";
import { ProducerAnalyticsService, ProducerUserAccountService } from "common/services";

class AdminProducerListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(ProducerAnalyticsService);
        this.producerUserAccountService = rootStore.createApplicationService(ProducerUserAccountService);
    }

    async findContent(params) {
        const response = await this.service.findProducerPaymentSummary(params);
        return response.data;
    }

    async getProducersSummary(filter) {
        const response = await this.producerUserAccountService.getProducersSummary(filter);
        return response.data;
    }
}

export default AdminProducerListRouteStore;
