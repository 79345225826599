import { BasePortalDetailedActivityViewStore } from "administration/analytics/stores";

class ConnectedDevicesActivityViewStore extends BasePortalDetailedActivityViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            chartLabel: "New devices",
        });
        this.routeStore = routeStore;
    }

    getActivityChartData(params, abortController) {
        return this.routeStore.getConnectedDevicesActivity(params, abortController);
    }

    fetchSummary(params, abortController) {
        return this.routeStore.getConnectedDevicesSummary(params, abortController);
    }
}

export default ConnectedDevicesActivityViewStore;
