import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

function MrbBasicSelect({ items, onSelect, value, panelRender = null }) {
    return (
        <select value={value} onChange={(e) => onSelect(e.target.value)}>
            {panelRender ? (
                panelRender(items)
            ) : (
                <React.Fragment>
                    {map(items, (item) => {
                        return (
                            <option key={item.value} value={item.value}>
                                {item.label}
                            </option>
                        );
                    })}
                </React.Fragment>
            )}
        </select>
    );
}

MrbBasicSelect.propTypes = {
    items: PropTypes.array.isRequired,
    value: PropTypes.any,
    onSelect: PropTypes.func,
    panelRender: PropTypes.func,
};

export default MrbBasicSelect;
