import { MrbBaseViewStore } from "mrb/core";
import { LineChartStore } from "common/components/charts";
import { httpStatusCodes } from "mrb/core/constants";
import { spanDataSetToFullDateRange, createTimeRangeChartLabel } from "administration/analytics/utils";
import { map } from "lodash";

class DeviceActivitySummaryViewStore extends MrbBaseViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore);
        this.routeStore = routeStore;

        this.deviceActivityChartStore = new LineChartStore(this.rootStore, {
            label: "Active devices",
            actions: {
                preprocessData: (data) => {
                    const spannedDataset = spanDataSetToFullDateRange(data, (periodStart, periodEnd) => {
                        return {
                            periodStartDate: periodStart,
                            periodEndDate: periodEnd,
                            count: 0,
                        };
                    });
                    data.data = spannedDataset;
                    return data;
                },
                mapLabels: (data) => createTimeRangeChartLabel(data),
                mapDataset: (data) => {
                    return map(data.data, (item) => item.count);
                },
                find: async (params, abortController) => {
                    const filter = this.createParams(params);
                    return this.routeStore.getDeviceActivity(filter, abortController);
                },
                onError: (error) => {
                    if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
                        return;
                    }
                    this.rootStore.notificationStore.error(
                        "An unexpected error occurred while trying to load device activity graph.",
                        error
                    );
                },
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        });
    }

    onInit() {
        return Promise.resolve();
    }

    createParams(filter) {
        const obj = {
            from: filter.from,
            to: filter.to,
            timeZone: filter.timeZone,
        };

        return obj;
    }

    async getResources(filter, abortController) {
        await this.deviceActivityChartStore.generate(filter, abortController);
    }
}

export default DeviceActivitySummaryViewStore;
