import { MrbBaseViewStore } from "../../core/stores";
import { merge, isFunction } from "lodash";

const DefaultConfig = {
    siteKey: null,
    secureToken: null,
    type: "invisible",
    badgePosition: "bottomright",
    actions: {
        onError: () => {},
        onExpired: () => {},
        onChange: () => {},
    },
};

class MrbRecaptchaViewStore extends MrbBaseViewStore {
    initialUpdate = false;
    captchaRef = null;
    siteKey = null;

    constructor(rootStore, config) {
        super(rootStore);
        this.config = merge({}, DefaultConfig, config);

        if (!this.config.siteKey) {
            throw new Error("Public site key must be provided.");
        }
    }

    setCaptchaRef = (ref) => {
        this.captchaRef = ref;
    };

    execute = () => {
        const existingToken = this.getVerificationResult();
        if (existingToken) {
            this.reset();
        }
        return this.captchaRef.executeAsync();
    };

    onChange = (response) => {
        if (
            this.config.actions.onChange &&
            isFunction(this.config.actions.onChange)
        ) {
            this.config.actions.onChange(response);
        }
    };

    onError = (response) => {
        if (
            this.config.actions.onError &&
            isFunction(this.config.actions.onError)
        ) {
            this.config.actions.onError(response);
        }
    };

    onExpired = (response) => {
        if (
            this.config.actions.onExpired &&
            isFunction(this.config.actions.onExpired)
        ) {
            this.config.actions.onExpired(response);
        }
    };

    reset = () => {
        return this.captchaRef.reset();
    };

    getVerificationResult = () => {
        return this.captchaRef.getValue();
    };
}

export default MrbRecaptchaViewStore;
