import { errorFormatterService } from "../services";

class MrbNotificationStore {
    constructor(rootStore, notificationProvider) {
        this.rootStore = rootStore;
        this.notificationProvider = notificationProvider;
    }

    success(message, options = null) {
        if (this.notificationProvider) {
            return this.notificationProvider.success(message, options);
        }
    }

    warning(message, options = null) {
        if (this.notificationProvider) {
            return this.notificationProvider.warning(message, options);
        }
    }

    error(message, data = null, options = null) {
        if (data) {
            const { headers, config, status } = data;
            errorFormatterService.getErrorObject(
                data.data,
                data.message,
                status,
                headers,
                config
            );
        }
        if (this.notificationProvider) {
            return this.notificationProvider.error(message, options);
        }
    }

    info(message, options = null) {
        if (this.notificationProvider) {
            return this.notificationProvider.info(message, options);
        }
    }
}

export default MrbNotificationStore;
