import { action, computed, runInAction, makeObservable } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbInfiniteScrollGridViewStore } from "mrb/components/infinite-scroll-grid";
import { PremiumContentListFilter } from "application/payment/filters";
import { podcastType } from "common/constants";
import { head, findIndex } from "lodash";

class PremiumContentListViewStore extends MrbBaseListViewStore {
    _contentTypeOptions = {
        "naki-radio-premium": [
            {
                label: "Naki Tales",
                value: 1,
            },
            {
                label: "Naki Nigun",
                value: 2,
            },
        ],
        "naki-nigun-premium": [
            {
                label: "Naki Nigun",
                value: 2,
            },
        ],
        "naki-radio-free": [
            {
                label: "NakiRadio Free",
                value: 3,
            },
        ],
    };

    @computed get contentTypeOptions() {
        if (this.premiumSubscriptionsViewStore.selectedSubscriptionPlan) {
            return this._contentTypeOptions[this.premiumSubscriptionsViewStore.selectedSubscriptionPlan.slug];
        }
        return [];
    }

    constructor(rootStore, { routeStore, premiumSubscriptionsViewStore }) {
        super(rootStore, {
            queryConfig: {
                orderBy: "title",
                orderDirection: "asc",
                disableUpdateQueryParams: true,
                filter: new PremiumContentListFilter(),
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;
        this.premiumSubscriptionsViewStore = premiumSubscriptionsViewStore;

        this.setGridStore(
            new MrbInfiniteScrollGridViewStore(this.rootStore, this.queryUtility, {
                onCellClick: (item) => this.goTo(item),
                generateKey: (item) => item.stationId || item.podcastId || item.nakiNigunArtistId,
                mapper: (item) => {
                    return {
                        ...item,
                        coverImageUrl: this.createCoverImageUrl(item),
                    };
                },
            })
        );

        this.reaction(
            () => this.contentTypeOptions,
            (contentTypeOptions) => {
                const index = findIndex(
                    contentTypeOptions,
                    (option) => option.value === this.queryUtility.filter.contentType
                );
                if (index === -1) {
                    this.changeContentFilter(head(contentTypeOptions).value);
                }
            }
        );

        this.createCoverImageUrl = this.createCoverImageUrl.bind(this);
    }

    async fetchResources(filter) {
        // Display grid (Skeleton loader) only during the first page load, and in other cases, during loading more data on scroll event,
        // display only top bar loader (mainLoaderStore)
        const isFirstPageLoad = filter.pageNumber === 1;
        if (isFirstPageLoad) {
            this.gridStore.suspend();
        } else {
            this.rootStore.mainLoaderStore.suspend();
        }
        try {
            const response = await this.find(filter);
            runInAction(() => {
                this.gridStore.setData(response);
                if (isFirstPageLoad) {
                    this.gridStore.resume();
                } else {
                    this.rootStore.mainLoaderStore.resume();
                }
            });
        } catch (err) {
            if (isFirstPageLoad) {
                this.gridStore.resume();
            } else {
                this.rootStore.mainLoaderStore.resume();
            }
            this.onFetchError(err);
        }
    }

    find(params) {
        params.isPremium = true;
        return this.routeStore.findUserContent(params);
    }

    goTo = (item) => {
        if (item.stationId) {
            this.rootStore.routerStore.goTo("master.application.home.station", {
                id: item.stationId,
            });
        } else if (item.podcastId) {
            if (item.podcastType === podcastType.podcast) {
                this.rootStore.routerStore.goTo("master.application.home.podcast", {
                    id: item.podcastId,
                });
            } else {
                this.rootStore.routerStore.goTo("master.application.home.podcast-group", {
                    id: item.podcastId,
                });
            }
        } else if (item.nakiNigunArtistId) {
            this.rootStore.routerStore.goTo("master.application.naki-nigun.artist", {
                id: item.nakiNigunArtistId,
            });
        }
    };

    createCoverImageUrl(item) {
        if (item.coverImageId) {
            if (item.stationId) {
                return this.rootStore.coverImageFactory.createStationCoverImageUrl(item.coverImageId, 200, 200);
            } else if (item.podcastId) {
                return this.rootStore.coverImageFactory.createPodcastCoverImageUrl(item.coverImageId, 200, 200);
            } else if (item.nakiNigunArtistId) {
                return this.rootStore.coverImageFactory.createNakiNigunArtistCoverImageUrl(item.coverImageId, 200, 200);
            }
        }
        return null;
    }

    @action.bound
    changeContentFilter(value) {
        this.queryUtility.filter.set("contentType", value);
        this.queryUtility.fetch();
    }
}

export default PremiumContentListViewStore;
