import React from "react";
import { PropTypes } from "prop-types";
import { MrbSimpleSelect } from "mrb/components/select";
import { MrbDatePicker } from "mrb/components/date-picker";
import { MrbButton } from "mrb/components/button";
import { observer } from "mobx-react";

function AnalyticsTimeRangeTemplate({ analyticsTimeRangeViewStore, t }) {
    const {
        timeRangeSelectStore,
        maxDate,
        isDateRangeDisabled,
        queryUtility,
        setFromDate,
        setToDate,
        applyFilter,
        datePickerOptions,
    } = analyticsTimeRangeViewStore;
    return (
        <div className="c-header__actions ">
            <div className="u-display--flex u-display--flex--ai--center">
                <div className="u-mar--right--sml">
                    <MrbSimpleSelect
                        store={timeRangeSelectStore}
                        classNameExtend="c-select--secondary c-select--date"
                    />
                </div>
                <MrbDatePicker
                    value={queryUtility.filter.from}
                    selectsStart={true}
                    isClearable={false}
                    disabled={isDateRangeDisabled}
                    maxDate={maxDate}
                    timeZone={queryUtility.filter.timeZone}
                    startDate={queryUtility.filter.from}
                    endDate={queryUtility.filter.to}
                    onChange={setFromDate}
                    {...datePickerOptions}
                />
                <span className="u-mar--right--tny u-mar--left--tny">-</span>
                <MrbDatePicker
                    value={queryUtility.filter.to}
                    selectsEnd={true}
                    isClearable={false}
                    disabled={isDateRangeDisabled}
                    maxDate={maxDate}
                    timeZone={queryUtility.filter.timeZone}
                    startDate={queryUtility.filter.from}
                    endDate={queryUtility.filter.to}
                    onChange={setToDate}
                    {...datePickerOptions}
                />

                <MrbButton
                    t={t}
                    className="c-btn c-btn--base c-btn--primary u-mar--left--sml"
                    onClick={applyFilter}
                    label="Apply"
                    disabled={isDateRangeDisabled}
                />
            </div>
        </div>
    );
}

AnalyticsTimeRangeTemplate.propTypes = {
    analyticsTimeRangeViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(AnalyticsTimeRangeTemplate);
