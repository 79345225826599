class StorageProvider {
    constructor(storage) {
        this.storage = storage;
    }

    add(storageKey, item) {
        return this.storage.set(storageKey, item);
    }

    get(storageKey) {
        return this.storage.get(storageKey);
    }

    remove(storageKey) {
        this.storage.remove(storageKey);
    }

    clear() {
        this.storage.clear();
    }

    getKeys() {
        return this.storage.getKeys();
    }
}

export default StorageProvider;
