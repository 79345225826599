import { isNil } from "lodash";
import moment from "moment";
import "moment-timezone";

class TimeZoneConverter {
    toISOString(value, timeZone) {
        if (isNil(value)) {
            throw new Error("value must be provided");
        }
        if (isNil(timeZone)) {
            throw new Error("timeZone must be provided");
        }

        let momentDate = null;
        if (moment.isMoment(value)) {
            momentDate = value;
        } else if (typeof value === "string" && moment(value, moment.ISO_8601, true).isValid()) {
            momentDate = moment.utc(value);
        } else {
            momentDate = moment(value);
        }

        const convertedDate = momentDate.tz(timeZone, true);

        return convertedDate.toISOString();
    }

    toJsDate(value, timeZone) {
        if (isNil(value)) {
            throw new Error("value must be provided");
        }
        if (isNil(timeZone)) {
            throw new Error("timeZone must be provided");
        }

        let momentDate = null;
        if (moment.isMoment(value)) {
            momentDate = value;
        } else if (typeof value === "string" && moment(value, moment.ISO_8601, true).isValid()) {
            momentDate = moment.utc(value);
        } else {
            momentDate = moment(value);
        }

        const localTimeZone = moment.tz.guess();
        const dateInLocalTimeZone = momentDate.tz(timeZone).tz(localTimeZone, true);

        const date = dateInLocalTimeZone.toDate();
        return date;
    }
}

const timeZoneConverter = new TimeZoneConverter();
export default timeZoneConverter;
