import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldLayout } from "../layout/content/form";
import { MrbDatePicker } from "./";
import { isNil } from "lodash";
import { observer } from "mobx-react";

function MrbFieldDatePicker({
    field,
    labelRender,
    showLabel,
    validationRender,
    infoRenderer,
    fieldClassNameExtend,
    fieldClassNameOverride,
    t = (i) => i,
    ...props
}) {
    if (isNil(field)) {
        throw new Error("Form field must be provided.");
    }

    const { value, onChange, placeholder } = field.bind();

    return (
        <MrbFieldLayout
            field={field}
            t={t}
            labelRender={labelRender}
            validationRender={validationRender}
            infoRenderer={infoRenderer}
            showLabel={showLabel}
            classNameExtend={fieldClassNameExtend}
            classNameOverride={fieldClassNameOverride}
        >
            <MrbDatePicker {...props} value={value} onChange={onChange} placeholder={placeholder} />
        </MrbFieldLayout>
    );
}

MrbFieldDatePicker.propTypes = {
    field: PropTypes.object.isRequired,
    labelRender: PropTypes.func,
    showLabel: PropTypes.bool,
    validationRender: PropTypes.func,
    infoRenderer: PropTypes.func,
    fieldClassNameExtend: PropTypes.string,
    fieldClassNameOverride: PropTypes.string,
    t: PropTypes.func,
};

export default observer(MrbFieldDatePicker);
