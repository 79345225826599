const AdminProducerAccountEditFields = [
    {
        name: "userId",
        label: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.USER_LABEL",
        placeholder: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.USER_PLACEHOLDER",
        rules: "required|string",
    },
    {
        name: "stripeAccountId",
        label: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.STRIPE_ACCOUNT_LABEL",
        placeholder: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.STRIPE_ACCOUNT_PLACEHOLDER",
        rules: "string",
    },
    {
        name: "revenuePercentage",
        label: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.REVENUE_PERCENTAGE_LABEL",
        placeholder: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.REVENUE_PERCENTAGE_PLACEHOLDER",
        rules: "required|numeric|between:1,100",
    },
];

export default AdminProducerAccountEditFields;
