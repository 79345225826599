import React from "react";
import PropTypes from "prop-types";
import { MrbToggle } from "./";
import { isSome, renderIf } from "../../common/utils";
import { observer } from "mobx-react";

function MrbFieldToggle({ field, t = (i) => i, ...props }) {
    return (
        <React.Fragment>
            {renderIf(isSome(field.errorMessage))(
                <div className="u-position--rel">
                    <p className="c-validation--input">
                        {" "}
                        <svg
                            className="c-validation--input__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 12 12"
                        >
                            <defs />
                            <path d="M11.625 11.617a.375.375 0 00.33-.552L6.332.565a.391.391 0 00-.661 0l-5.625 10.5a.375.375 0 00.33.553zM6 10.242a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-6.125a.5.5 0 01.5.5V7.35a.5.5 0 01-1 0V4.618a.5.5 0 01.5-.5z" />
                        </svg>
                        <span className="c-validation--input__label">
                            {field.errorMessage}
                        </span>
                    </p>
                </div>
            )}
            <MrbToggle {...field.bind()} t={t} {...props} />
        </React.Fragment>
    );
}

MrbFieldToggle.propTypes = {
    field: PropTypes.any,
    labelRender: PropTypes.func,
};

export default observer(MrbFieldToggle);
