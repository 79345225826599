import { BaseCacheService } from "mrb/core/cache";
import { WeekDayService } from "common/services";

class WeekDayCacheService extends BaseCacheService {
    constructor(rootStore) {
        super("week-day", "lookup", async () => {
            const response = await this.service.get();
            return response.data;
        });
        this.service = rootStore.createApplicationService(WeekDayService);
    }
}

export default WeekDayCacheService;
