import React from "react";
import { PodcastListenersCellTemplate } from "themes/administration/analytics/components";

class PodcastListenersCell extends React.Component {
    render() {
        return <PodcastListenersCellTemplate {...this.props} />;
    }
}

export default PodcastListenersCell;
