import { convertSecondsToHMS } from "common/utils";
import { reportVersions } from "common/constants";

function producerPlaybackTimeConverter(reportVersion, value) {
    if (value) {
        if (reportVersion === reportVersions["1_0"]) {
            return null;
        } else {
            return convertSecondsToHMS(value);
        }
    }

    return null;
}

export default producerPlaybackTimeConverter;
