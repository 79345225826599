import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { observer } from "mobx-react";

function TogglePremiumTemplate({ isPremium, canAccessPremiumContent, children, t }) {
    if (!isPremium) {
        return children;
    }

    return (
        <React.Fragment>
            {canAccessPremiumContent ? (
                <React.Fragment>{children}</React.Fragment>
            ) : (
                <MrbRouterLink
                    className="c-btn c-btn--base c-btn--primary"
                    routeName="master.application.premium.subscriptions"
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                >
                    {t("APPLICATION.PREMIUM.GO_PREMIUM")}
                </MrbRouterLink>
            )}
        </React.Fragment>
    );
}

TogglePremiumTemplate.propTypes = {
    isPremium: PropTypes.bool.isRequired,
    canAccessPremiumContent: PropTypes.bool,
    t: PropTypes.func.isRequired,
    children: PropTypes.any,
};

export default observer(TogglePremiumTemplate);
