import React from "react";
import ContentLoader from "react-content-loader";

const AnalyticsGraphLoaderTemplate = (props) => {
    const screenwidth = window.innerWidth;
    let loaderWidth;
    let col;

    if (screenwidth > 1199) {
        loaderWidth = screenwidth - 690;
        col = Math.floor(loaderWidth / 10);
    } else if (screenwidth > 990) {
        loaderWidth = screenwidth - 250;
        col = Math.floor(loaderWidth / 6);
    } else {
        loaderWidth = screenwidth;
        col = Math.floor(loaderWidth / 4);
    }

    let itemX = [];
    let itemY = [];

    let labelY = [];
    let labelX = [];

    let y = 0;
    let y2 = 24;
    let x = -70;
    let x2 = 70;

    for (let i = 0; i < 7; i++) {
        itemY.push(
            <React.Fragment key={i}>
                <rect x="70" y={y} rx="0" ry="0" width="100%" height="1" />
            </React.Fragment>
        );

        y += 63;
    }

    for (let i = 0; i < 10; i++) {
        itemX.push(
            <React.Fragment key={i}>
                <rect x={0} y={x} rx="0" ry="0" width="379" height="1" style={{ transform: "rotate(90deg)" }} />
            </React.Fragment>
        );
        x -= col;
    }

    for (let i = 0; i < 8; i++) {
        labelY.push(
            <React.Fragment key={i}>
                <rect x={0} y={y2} rx="0" ry="0" width="50" height="8" />
            </React.Fragment>
        );
        y2 += 50;
    }

    for (let i = 0; i < 16; i++) {
        labelX.push(
            <React.Fragment key={i}>
                <rect x={x2} y={"98%"} rx="0" ry="0" width="60" height="8" />
            </React.Fragment>
        );
        x2 += col;
    }

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox={`0 0 ${loaderWidth} 400`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            {itemY}
            {itemX}
            {labelY}
            {labelX}
        </ContentLoader>
    );
};

export default AnalyticsGraphLoaderTemplate;
