import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { join, map } from "lodash";

function PastDueSubscriptionWidgetTemplate({ subscriptions, t }) {
    const productNames = map(subscriptions, (subscription) => subscription.product.name);
    return (
        <div className="c-premium__info">
            <div className="c-premium__header">
                <i className="u-icon u-icon--med u-icon--cc-sync-outline u-mar--right--micro"></i>
                <p className="u-type--base u-type--wgt--bold">{t("APPLICATION.PREMIUM.PAST_DUE_WIDGET.TITLE")}</p>
            </div>

            <p className="u-type--sml u-mar--bottom--sml">
                {t(
                    productNames.length > 1
                        ? "APPLICATION.PREMIUM.PAST_DUE_WIDGET.DESCRIPTION_PLURAL"
                        : "APPLICATION.PREMIUM.PAST_DUE_WIDGET.DESCRIPTION",
                    {
                        subscriptionPlan: join(productNames, " and "),
                    }
                )}
            </p>

            <MrbRouterLink
                className="c-btn c-btn--sml c-btn--full c-btn--primary"
                routeName="master.application.premium.info"
            >
                {t("APPLICATION.PREMIUM.PAST_DUE_WIDGET.ACTION")}
            </MrbRouterLink>
        </div>
    );
}

PastDueSubscriptionWidgetTemplate.propTypes = {
    subscriptions: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default PastDueSubscriptionWidgetTemplate;
