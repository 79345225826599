import { findIndex, orderBy, isFunction } from "lodash";
import moment from "moment";

function spanDataSetToFullDateRange(dataset, createItemFn) {
    if (!isFunction(createItemFn)) {
        throw new Error("createItemFn is not defined.");
    }
    const min = moment(dataset.timeRange.min);
    const max = moment(dataset.timeRange.max);
    const groupedBy = dataset.groupedBy.toLowerCase();
    const periods = max.diff(min, groupedBy) + 1;
    let currentPeriodStart = moment.tz(dataset.timeRange.min, dataset.timeZone).startOf(groupedBy).toISOString();
    let currentPeriodEnd = moment.tz(dataset.timeRange.min, dataset.timeZone).endOf(groupedBy).toISOString();
    let missingPeriods = [];
    for (let period = 1; period <= periods; period++) {
        /* eslint-disable no-loop-func */
        const index = findIndex(dataset.data, (x) => moment(x.periodStartDate).isSame(currentPeriodStart));
        if (index === -1) {
            missingPeriods.push(createItemFn(currentPeriodStart, currentPeriodEnd));
        }
        currentPeriodStart = moment
            .tz(currentPeriodStart, dataset.timeZone)
            .add(1, groupedBy)
            .startOf(groupedBy)
            .toISOString();
        currentPeriodEnd = moment
            .tz(currentPeriodEnd, dataset.timeZone)
            .add(1, groupedBy)
            .endOf(groupedBy)
            .toISOString();
    }
    const result = orderBy([...dataset.data, ...missingPeriods], (x) => x.periodStartDate);
    return result;
}

export default spanDataSetToFullDateRange;
