import React from "react";
import { PropTypes } from "prop-types";

function MrbSimpleSelectValue({ selectedOption, t }) {
    return <span>{t(selectedOption.label)}</span>;
}

MrbSimpleSelectValue.propTypes = {
    selectedOption: PropTypes.object,
    t: PropTypes.func,
};

export default MrbSimpleSelectValue;
