import React from "react";
import PropTypes from "prop-types";

function MrbEditFormContent({ children }) {
    return (
        <React.Fragment>
            {children ? <React.Fragment>{children}</React.Fragment> : null}
        </React.Fragment>
    );
}

MrbEditFormContent.propTypes = {
    children: PropTypes.any,
};

export default MrbEditFormContent;
