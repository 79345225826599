import { observable, makeObservable, override } from "mobx";
import { MrbFilterParameters } from "mrb/core/filter";
import { applicationDefaults } from "common/constants";
import moment from "moment";

class ProducerPaymentReportFilter extends MrbFilterParameters {
    @observable monthOfYear;
    @observable timeZone;

    constructor() {
        super();
        makeObservable(this);
        this.reset();
    }

    @override
    reset() {
        super.reset();
        this.monthOfYear = moment().subtract(1, "month").startOf("month").format(applicationDefaults.formats.isoDate);
        this.timeZone = moment.tz.guess();
    }
}

export default ProducerPaymentReportFilter;
