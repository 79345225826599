import { action, computed, observable, makeObservable } from "mobx";

class MrbBaseAppStore {
    @observable.ref app = null;

    @computed get apiClient() {
        return this._getApiClient(this.app);
    }

    get applicationExists() {
        return this.app !== undefined && this.app !== null;
    }

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action.bound
    setApp(app) {
        this.app = app;
    }

    createApplicationService(Type) {
        return new Type(this.apiClient);
    }

    register(params) {
        throw new Error("Method not implemented.");
    }

    _getApiClient() {
        throw new Error("Method not implemented.");
    }
}

export default MrbBaseAppStore;
