import { BaseService } from "mrb/core";
import { StationAnalyticsApiRouteService } from "common/services";

class StationAnalyticsService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new StationAnalyticsApiRouteService());
    }

    getStationPremiumStatistic(id, options, abortSignal = null) {
        const url = this.routeService.getStationPremiumStatistic(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getTrendingStations(filter, abortSignal = null) {
        const url = this.routeService.getTrendingStations(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getStationActivity(id, options, abortSignal = null) {
        const url = this.routeService.getStationActivity(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getStationStats(id, options, abortSignal = null) {
        const url = this.routeService.getStationStats(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getStationSummary(id, options, abortSignal = null) {
        const url = this.routeService.getStationSummary(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getStationListenersStats(abortSignal = null) {
        const url = this.routeService.getStationListenersStats();
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default StationAnalyticsService;
