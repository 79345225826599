import { action, computed, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { applicationDefaults, subscriptionStatuses } from "common/constants";
import { dateFormatter } from "mrb/common/formatting";
import { NakiRadioFreePlan } from "application/payment/models";

class UserSubscriptionsPreviewViewStore extends MrbBaseViewStore {
    @computed get activeSubscription() {
        return this.preprocessSubscription(this.rootStore.premiumSubscriptionStore.subscription);
    }

    @computed get pendingSubscription() {
        return this.rootStore.premiumSubscriptionStore.pendingSubscription;
    }

    @computed get isSubscriptionBundleActive() {
        return this.rootStore.premiumSubscriptionStore.isSubscriptionBundleActive;
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;

        const route = this.rootStore.routerStore.getCurrentRoute();
        if (route && route.data && route.data.title) {
            this.title = route.data.title;
        }
    }

    @action.bound
    setIsCanceling(isCanceling) {
        this.isCanceling = isCanceling;
    }

    @action.bound
    endTrial(subscriptionId) {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "End Premium trial?",
            onConfirm: async () => {
                try {
                    await this.rootStore.premiumSubscriptionStore.endTrialPeriod(subscriptionId);
                } catch (err) {
                    this.rootStore.notificationStore.error("Unable to end trial. Please contact the support team.");
                }
            },
            yesLabel: "End Trial",
            noLabel: "Cancel",
            description:
                "Your trial will end immediately, and you won't be able to access Premium features or activate trial anymore.",
        });
    }

    @action.bound
    cancelSubscription() {
        let description;
        if (this.activeSubscription.isInternal) {
            description =
                "Your subscription will end immediately, and you won't be able to access Premium features anymore.";
        } else {
            description = `You'll still be able to access Premium content until ${dateFormatter.format(
                this.activeSubscription.nextBillingDate,
                {
                    format: applicationDefaults.formats.date,
                }
            )}.`;
        }
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Cancel Subscription?",
            onConfirm: async () => {
                try {
                    await this.rootStore.premiumSubscriptionStore.cancelSubscription(this.activeSubscription.id);
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "Unable to cancel subscription. Please contact the support team."
                    );
                }
            },
            yesLabel: "Cancel Subscription",
            noLabel: "Cancel",
            description: description,
        });
    }

    @action.bound
    renewSubscription() {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Renew Subscription?",
            onConfirm: async () => {
                try {
                    await this.rootStore.premiumSubscriptionStore.renewSubscription(this.activeSubscription.id);
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "Unable to renew subscription. Please contact the support team."
                    );
                }
            },
            yesLabel: `Renew ${this.activeSubscription.product.name}`,
            noLabel: "Cancel",
            description: `Your ${this.activeSubscription.product.name} subscription won't be canceled and you can continue enjoying Premium content.`,
        });
    }

    @action.bound
    revertSubscription() {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Revert Subscription?",
            onConfirm: async () => {
                try {
                    await this.rootStore.premiumSubscriptionStore.renewSubscription(this.activeSubscription.id);
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "Unable to revert subscription. Please contact the support team."
                    );
                }
            },
            yesLabel: `Revert to ${this.activeSubscription.product.name}`,
            noLabel: "Cancel",
            description: `Your ${this.activeSubscription.product.name} subscription won't be canceled and you can continue using Premium content.`,
        });
    }

    preprocessSubscription(subscription) {
        if (subscription) {
            return subscription;
        }

        return {
            id: "-1",
            amount: 0,
            product: new NakiRadioFreePlan(),
            subscriptionStatus: {
                name: "Active",
                abrv: subscriptionStatuses.active,
            },
        };
    }
}

export default UserSubscriptionsPreviewViewStore;
