import { action, computed, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { MrbQuerySimpleSelectStore } from "mrb/components/select";
import { timeRangeType } from "administration/analytics/constants";
import moment from "moment";

const defaultDropdownOptions = [
    { label: "Custom", value: timeRangeType.custom },
    { label: "Today", value: timeRangeType.today },
    { label: "Yesterday", value: timeRangeType.yesterday },
    { label: "Last 3 days", value: timeRangeType.last3Days },
    { label: "This Week", value: timeRangeType.thisWeek },
    { label: "Last Week", value: timeRangeType.lastWeek },
    { label: "This Month", value: timeRangeType.thisMonth },
    { label: "Last Month", value: timeRangeType.lastMonth },
];

class AnalyticsTimeRangeViewStore extends MrbBaseViewStore {
    maxDate = null;

    @computed get isDateRangeDisabled() {
        return this.timeRangeSelectStore.value !== timeRangeType.custom;
    }

    constructor(rootStore, { queryUtility, selectableOptions, datePickerOptions = {} }) {
        super(rootStore);
        makeObservable(this);
        this.queryUtility = queryUtility;
        this.datePickerOptions = datePickerOptions;
        this.maxDate = this.datePickerOptions.maxDate || moment().startOf("day").toDate();

        this.timeRangeSelectStore = new MrbQuerySimpleSelectStore(
            {
                textKey: "label",
                valueKey: "value",
                fetchOnChange: (selectedOption) => selectedOption.value !== timeRangeType.custom,
            },
            this.queryUtility,
            "range",
            selectableOptions ? selectableOptions : defaultDropdownOptions
        );

        this.applyFilter = this.applyFilter.bind(this);
    }

    @action.bound
    setFromDate(value) {
        this.queryUtility.filter.set(
            "from",
            moment.utc(value).tz(this.queryUtility.filter.timeZone).startOf("day").toISOString()
        );
    }

    @action.bound
    setToDate(value) {
        this.queryUtility.filter.set(
            "to",
            moment.utc(value).tz(this.queryUtility.filter.timeZone).endOf("day").toISOString()
        );
    }

    applyFilter() {
        return this.queryUtility.fetch();
    }
}

export default AnalyticsTimeRangeViewStore;
