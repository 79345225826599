import React from "react";
import { PropTypes } from "prop-types";
import { MrbRadioButtons } from "./";
import { MrbFieldLayout } from "../layout/content/form";
import { observer } from "mobx-react";

function MrbFieldRadioButtons({
    field,
    t = (i) => i,
    showLabel = true,
    labelRender = null,
    validationRender = null,
    rowView = true,
    ...props
}) {
    return (
        <MrbFieldLayout
            field={field}
            t={t}
            labelRender={labelRender}
            validationRender={validationRender}
            showLabel={showLabel}
        >
            <MrbRadioButtons t={t} {...field.bind()} rowView={rowView} {...props} />
        </MrbFieldLayout>
    );
}

MrbFieldRadioButtons.propTypes = {
    field: PropTypes.object.isRequired,
    showLabel: PropTypes.bool,
    t: PropTypes.func,
    labelRender: PropTypes.func,
    validationRender: PropTypes.func,
    classNameExtend: PropTypes.string,
};

export default observer(MrbFieldRadioButtons);
