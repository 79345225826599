import React from "react";
import PropTypes from "prop-types";
import { mergeOrOverrideCss } from "../../common/utils";
import { observer } from "mobx-react";

function MrbCheckbox({
    id,
    label,
    value,
    onChange,
    t = (i) => i,
    disabled,
    labelRender = null,
    classNameExtend,
    classNameOverride,
}) {
    return (
        <React.Fragment>
            <input
                id={id}
                checked={value}
                onChange={onChange}
                disabled={disabled}
                type="checkbox"
                className={mergeOrOverrideCss(
                    "c-input--check",
                    classNameOverride,
                    classNameExtend
                )}
            />
            {labelRender ? (
                labelRender({ id, t })
            ) : (
                <label htmlFor={id}>{t(label)}</label>
            )}
        </React.Fragment>
    );
}

MrbCheckbox.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    t: PropTypes.func,
    labelRender: PropTypes.func,
};

MrbCheckbox.defaultProps = {
    disabled: false,
};

export default observer(MrbCheckbox);
