import { BaseApiRouteService } from "mrb/core";

class GenreLookupApiRouteService extends BaseApiRouteService {
    constructor() {
        super("lookups/genres");
    }

    find(filter) {
        return super.find(`${this.base}/{?searchQuery}`, filter);
    }

    get(id) {
        return super.get(`${this.base}/{id}`, id);
    }

    create(resource) {
        return super.create(this.base, resource);
    }
}

export default GenreLookupApiRouteService;
