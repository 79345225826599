import { BaseApiRouteService } from "mrb/core";

class NakiNigunArtistFileStreamApiRouteService extends BaseApiRouteService {
    constructor() {
        super("naki-nigun/artist-file-streams");
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?width,height}`, id, options);
    }

    getArtistCoverImageByArtist(id, options) {
        return super.get(`${this.base}/artist-cover/{id}{?width,height}`, id, options);
    }
}

export default NakiNigunArtistFileStreamApiRouteService;
