import { BaseService } from "mrb/core";
import { PlaylistApiRouteService } from "common/services";

class PlaylistService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new PlaylistApiRouteService());
    }

    findAdminGeneratedPlaylists(filter, abortSignal = null) {
        const url = this.routeService.findAdminGeneratedPlaylists(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getAdminPlaylist(playlistId, abortSignal = null) {
        const url = this.routeService.getAdminPlaylist(playlistId);
        return this.apiClient.get(url, null, abortSignal);
    }

    createAdminGeneratedPlaylist(resource, abortSignal = null) {
        const url = this.routeService.createAdminGeneratedPlaylist(resource);
        return this.apiClient.post(url, resource, null, abortSignal);
    }

    addItemToAdminGeneratedPlaylist(playlistId, resource, abortSignal = null) {
        const url = this.routeService.addItemToAdminGeneratedPlaylist(playlistId, resource);
        return this.apiClient.put(url, resource, null, abortSignal);
    }

    deleteAlbumTrackFromAdminGeneratedPlaylist(playlistId, albumTrackId, abortSignal = null) {
        const url = this.routeService.deleteAlbumTrackFromAdminGeneratedPlaylist(playlistId, albumTrackId);
        return this.apiClient.delete(url, null, abortSignal);
    }

    getAllAdminGeneratedPlaylists(abortSignal = null) {
        const url = this.routeService.getAllAdminGeneratedPlaylists();
        return this.apiClient.get(url, null, abortSignal);
    }

    updateAdminGeneratedPlaylists(playlists, abortSignal = null) {
        const url = this.routeService.updateAdminGeneratedPlaylists();
        return this.apiClient.put(url, playlists, null, abortSignal);
    }

    deleteAlbumTrackFromAdminPlaylist(playlistId, albumTrackId, abortSignal = null) {
        const url = this.routeService.deleteAlbumTrackFromAdminPlaylist(playlistId, albumTrackId);
        return this.apiClient.delete(url, null, abortSignal);
    }
}

export default PlaylistService;
