import { MrbBaseRouteStore } from "../base";
import { makeObservable, computed } from "mobx";

class MainRouteStore extends MrbBaseRouteStore {
    @computed get isInitialized() {
        return this.rootStore.applicationMainSectionStore.isInitialized;
    }

    constructor(rootStore) {
        super(rootStore);
        makeObservable(this);
    }

    onBeforeEnter = async () => {
        await this.rootStore.applicationMainSectionStore.initialize();
    };
}

export default MainRouteStore;
