import React from "react";
import { PropTypes } from "prop-types";
import { MrbModal } from "mrb/components/modal";
import { MrbButton } from "mrb/components/button";
import { applicationDefaults } from "common/constants";
import { dateFormatter } from "mrb/common/formatting";
import { defaultTemplate } from "common/hoc";

function PaymentSuccessModalTemplate({ modalParams, t }) {
    const { params, data = {} } = modalParams;
    let title, description;
    if (data.trialUpgraded) {
        const billingStartDate = dateFormatter.format(data.billingStartDate, {
            format: applicationDefaults.formats.longDate,
        });
        title = t("APPLICATION.PREMIUM.TRIAL.UPGRADE_SUCCESS.TITLE");
        description = t("APPLICATION.PREMIUM.TRIAL.UPGRADE_SUCCESS.DESCRIPTION", {
            startDate: billingStartDate,
            subscriptionPlan: data.subscriptionPlan,
        });
    } else {
        title = t("APPLICATION.PAYMENT.PAYMENT_SUCCESS.TITLE");
        description = t("APPLICATION.PAYMENT.PAYMENT_SUCCESS.DESCRIPTION", { subscriptionPlan: data.subscriptionPlan });
    }
    const buttonLabel = t("APPLICATION.PAYMENT.PAYMENT_SUCCESS.CONFIRM_BUTTON", {
        subscriptionPlan: data.subscriptionPlan,
    });
    return (
        <MrbModal modalParams={modalParams} shouldCloseOnOverlayClick={false} displayCloseIcon={false}>
            <div>
                <div className="modal__icon">
                    <i className="u-icon u-icon--radio-lrg"></i>
                </div>
                <div className="modal__header">
                    <h2>{title}</h2>
                </div>
                <div className="modal__body">
                    <p>{description}</p>
                </div>
                <div className="modal__footer">
                    <MrbButton label={buttonLabel} onClick={params.onClickExplorePremium} />
                </div>
            </div>
        </MrbModal>
    );
}

PaymentSuccessModalTemplate.propTypes = {
    modalParams: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(PaymentSuccessModalTemplate);
