import { PropTypes } from "prop-types";
import { observer } from "mobx-react";

function MrbContentLoader({ loaderStore, loaderRender, children }) {
    const { loading, suspendRendering } = loaderStore;
    if (suspendRendering && !loading) {
        return null;
    } else if (loading) {
        return loaderRender();
    }
    return children();
}

MrbContentLoader.propTypes = {
    loaderStore: PropTypes.object.isRequired,
    loaderRender: PropTypes.func.isRequired,
    children: PropTypes.any,
};

export default observer(MrbContentLoader);
