import React from "react";
import { observer } from "mobx-react";

// NOTE: This is a direct implementation of the mobx-state-router RouterView component. We need to support factory pattern for view components.
// https://github.com/nareshbhatia/mobx-state-router/blob/master/src/components/RouterView.tsx
function ApplicationRouterView({ routerStore, viewMap }) {
    const { routerState } = routerStore.router;
    const route = viewMap[routerState.routeName];
    return route ? renderRouteTree(route, routerStore) : null;
}

function renderRouteTree(route, routerStore, componentProps = {}) {
    componentProps.routeStore = routerStore.rootStore.getCurrentRouteStore(
        route.routeName
    );
    const renderChildRoute = route.childRoute
        ? (props) => renderRouteTree(route.childRoute, routerStore, props)
        : null;
    if (renderChildRoute) {
        componentProps.renderChildRoute = renderChildRoute;
    }
    return <route.component {...componentProps}></route.component>;
}

export default observer(ApplicationRouterView);
