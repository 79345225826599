import { observable } from "mobx";
import { each, endsWith, split, join, take, some, merge } from "lodash";

function getDefaultRouteDataMap(routes) {
    const routeDataMap = observable.map();

    each(routes, (route) => {
        // set default back for common pages
        // create -> list
        // edit -> list
        // settings -> list
        // language -> list
        if (!route.data) {
            route.data = {};
        }
        let goBack = route.data.back;
        if (!goBack) {
            const routeName = route.name;
            if (
                endsWith(routeName, ".create") ||
                endsWith(routeName, ".edit") ||
                endsWith(routeName, ".settings") ||
                endsWith(routeName, ".language") ||
                endsWith(routeName, ".localization")
            ) {
                const parts = split(routeName, ".");
                const listRoute =
                    join(take(parts, parts.length - 1), ".") + ".list";
                if (some(routes, (r) => r.name === listRoute)) {
                    goBack = listRoute;
                }
            }
        }

        const { data } = route;
        if (data && data.title && (!data.crumbs || data.crumbs.length === 0)) {
            // set page breadcrumb as page title if not breadcrumb defined
            data.crumbs = [{ title: data.title }];
        }

        const routeData = merge({}, route.data, {
            back: goBack,
            crumbs: data.crumbs,
        });

        routeDataMap.set(route.name, routeData);
    });

    return routeDataMap;
}

export default getDefaultRouteDataMap;
