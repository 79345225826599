import React from "react";
import PropTypes from "prop-types";
import { MrbRouteLinkMenuItem } from "./";
import { mergeOrOverrideCss, mergeCss } from "../../common/utils";
import { observer } from "mobx-react";

function MrbMenuItem({ menuStore, item, t, classNameExtend, classNameOverride, tabIndex = 0, subMenuRenderer = null }) {
    const title = t(item.title);
    const baseCss = mergeOrOverrideCss("c-nav__link", classNameOverride, classNameExtend);
    return (
        <li className="c-nav__item">
            {item.canNavigate ? (
                <MrbRouteLinkMenuItem className={baseCss} item={item} t={t} />
            ) : (
                // expand collapse icon
                <a
                    href="void(0)"
                    tabIndex={tabIndex}
                    onClick={(event) => menuStore.selectMenuItem(event, item)}
                    aria-label={title}
                    className={mergeCss(baseCss, "c-nav__link--expanded", item.isActive ? " is-active" : null)}
                >
                    <span className="c-nav__link__content">
                        {item.icon && <i className={item.icon ? `c-nav__icon c-nav__icon--${item.icon}` : null}></i>}
                        {title}
                    </span>
                    <i
                        className={mergeCss(
                            "u-icon u-icon--med u-icon--arrow-down",
                            item.isSelected ? "u-rotate--180" : null
                        )}
                    />
                </a>
            )}
            {subMenuRenderer && item.isSelected && subMenuRenderer()}
        </li>
    );
}

MrbMenuItem.propTypes = {
    menuStore: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    classNameExtend: PropTypes.string,
    classNameOverride: PropTypes.string,
    t: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
    className: PropTypes.string,
    subMenuRenderer: PropTypes.func,
};

export default observer(MrbMenuItem);
