import { BaseApiRouteService } from "mrb/core";

class NakiNigunUserContentApiRouteService extends BaseApiRouteService {
    constructor() {
        super("naki-nigun/user-content");
    }

    findArtists(filter) {
        return super.find(`${this.base}/artist/{?searchQuery,deviceId,page,rpp,sort,embed,fields}`, filter);
    }

    findAlbums(filter) {
        return super.find(`${this.base}/album/{?searchQuery,deviceId,page,rpp,sort,embed,fields}`, filter);
    }

    getAlbumsByArtist(id) {
        return super.find(`${this.base}/artist/{id}/albums`, {
            id: id,
        });
    }

    genericSearch(filter) {
        return super.find(`${this.base}/generic-search/{?searchQuery,page,rpp,sort,embed,fields}`, filter);
    }

    getNakiNigunArtistWthDeviceSettings(artistId, deviceId, options) {
        return super.get(`${this.base}/artist/{artistId}/device/{deviceId}{?embed,fields}`, null, {
            deviceId: deviceId,
            artistId: artistId,
            ...options,
        });
    }

    getNakiNigunArtist(artistId, options) {
        return super.get(`${this.base}/artist/{artistId}{?embed,fields}`, null, {
            artistId: artistId,
            ...options,
        });
    }

    getUserDeviceAlbumsByArtist(deviceId, artistId) {
        return super.get(`${this.base}/{deviceId}/artist/{artistId}`, null, {
            deviceId: deviceId,
            artistId: artistId,
        });
    }

    updateAlbumsTurnedOnStatusBulk(resources) {
        return super.update(`${this.base}/album/turned-on/bulk`, {
            resources: resources,
        });
    }

    updateAlbumsTurnedOnStatus(resources, value) {
        return super.update(`${this.base}/album/turned-on{?value}`, {
            resources: resources,
            value: value,
        });
    }

    updateAlbumsTurnedOnStatusByArtist(resources, value) {
        return super.update(`${this.base}/artist/turned-on{?value}`, {
            resources: resources,
            value: value,
        });
    }

    updateAlbumsTurnedOnStatusByArtistBulk(resources) {
        return super.update(`${this.base}/artist/turned-on/bulk`, {
            resources: resources,
        });
    }

    getFeaturedArtists(deviceId) {
        return super.get(`${this.base}/artist/featured{?deviceId}`, null, {
            deviceId,
        });
    }

    getFeaturedAlbums(deviceId) {
        return super.get(`${this.base}/album/featured{?deviceId}`, null, {
            deviceId,
        });
    }
}

export default NakiNigunUserContentApiRouteService;
