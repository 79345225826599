import React from "react";
import ContentLoader from "react-content-loader";

const MrbHeaderLoader = (props) => {
    const screenwidth =
        window.innerWidth > 992 ? window.innerWidth - 200 : window.innerWidth;
    const headerheight = screenwidth > 766 ? 135 : 272;

    return (
        <ContentLoader
            speed={2}
            width={screenwidth}
            height={"100%"}
            viewBox={`0 0 ${screenwidth} ${headerheight}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            {screenwidth > 766 ? (
                <React.Fragment>
                    {/* prettier-ignore */}
                    <rect x="155" y="10" rx="3" ry="3" width="50" height="8" />
                    {/* prettier-ignore */}
                    <rect x="155" y="30" rx="3" ry="3" width="170" height="30" />
                    {/* prettier-ignore */}
                    <rect x="155" y="90" rx="3" ry="3" width="50" height="8" />
                    {/* prettier-ignore */}
                    <rect x="155" y="110" rx="3" ry="3" width="265" height="8" />
                    {/* prettier-ignore */}
                    <rect x="155" y="125" rx="3" ry="3" width="135" height="8" />

                    <circle cx="68" cy="68" r="68" />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <circle cx="73" cy="73" r="73" />

                    {/* prettier-ignore */}
                    <rect x="0" y="165" rx="3" ry="3" width="50" height="8" />
                    {/* prettier-ignore */}
                    <rect x="0" y="180" rx="3" ry="3" width="150" height="15" />
                    {/* prettier-ignore */}
                    <rect x="0" y="225" rx="3" ry="3" width="50" height="8" />
                    {/* prettier-ignore */}
                    <rect x="0" y="245" rx="3" ry="3" width="225" height="10" />
                </React.Fragment>
            )}
        </ContentLoader>
    );
};

export default MrbHeaderLoader;
