import { uriFormat } from "../utils";

const mrbDefaultFormRules = {
    array_required: {
        // eslint-disable-next-line
        rule: (value, req, attr, form) => {
            return Array.isArray(value) && value.length > 0;
        },
        message: "The :attribute must have at least 1 value",
    },
    // before_or_equal_date: beforeOrEqualDate,
    // before_date: beforeDate,
    // after_date: afterDate,
    // min_date: minDate,
    // max_date: maxDate,
    // greater_than: greaterThan,
    // less_than: lessThan,
    url: {
        // eslint-disable-next-line
        rule: (value, req, attr, form) => {
            if (value.indexOf(" ") !== -1) {
                return false;
            }

            return uriFormat.test(value);
        },
        message: "Invalid URL.",
    },
};

export default mrbDefaultFormRules;
