import { BaseService } from "mrb/core";
import { UserContentApiRouteService } from "common/services";

class UserContentService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new UserContentApiRouteService());
    }

    getFeaturedContent(deviceId, contentType, abortSignal = null) {
        const url = this.routeService.getFeaturedContent(deviceId, contentType);
        return this.apiClient.get(url, null, abortSignal);
    }

    genericSearch(filter, abortSignal = null) {
        const url = this.routeService.genericSearch(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    findPremiumContentInfo(filter, abortSignal = null) {
        const url = this.routeService.findPremiumContentInfo(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getSubPodcasts(id, abortSignal = null) {
        const url = this.routeService.getSubPodcasts(id);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastVolumes(id, abortSignal = null) {
        const url = this.routeService.getPodcastVolumes(id);
        return this.apiClient.get(url, null, abortSignal);
    }

    updateStationsAndPodcastsTurnedOnStatus(resources, value, abortSignal = null) {
        const url = this.routeService.updateStationsAndPodcastsTurnedOnStatus(resources, value);
        return this.apiClient.put(url, resources, null, abortSignal);
    }

    getUserDeviceStation(deviceId, stationId, abortSignal = null) {
        const url = this.routeService.getUserDeviceStation(deviceId, stationId);
        return this.apiClient.get(url, null, abortSignal);
    }

    getUserDevicePodcast(deviceId, podcastId, abortSignal = null) {
        const url = this.routeService.getUserDevicePodcast(deviceId, podcastId);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastGroupUserDevicePodcast(deviceId, podcastId, abortSignal = null) {
        const url = this.routeService.getPodcastGroupUserDevicePodcast(deviceId, podcastId);
        return this.apiClient.get(url, null, abortSignal);
    }

    getStationWthDeviceSettings(stationId, deviceId, options, abortSignal = null) {
        const url = this.routeService.getStationWthDeviceSettings(stationId, deviceId, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getStation(stationId, options, abortSignal = null) {
        const url = this.routeService.getStation(stationId, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastWthDeviceSettings(podcastId, deviceId, options, abortSignal = null) {
        const url = this.routeService.getPodcastWthDeviceSettings(podcastId, deviceId, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcast(podcastId, options, abortSignal = null) {
        const url = this.routeService.getPodcast(podcastId, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastGroupWthDeviceSettings(podcastId, deviceId, options, abortSignal = null) {
        const url = this.routeService.getPodcastGroupWthDeviceSettings(podcastId, deviceId, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getPodcastGroup(podcastId, deviceId, options, abortSignal = null) {
        const url = this.routeService.getPodcastGroup(podcastId, deviceId, options);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default UserContentService;
