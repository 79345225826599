import React from "react";
import { PropTypes } from "prop-types";
import { mergeOrOverrideCss } from "../../../common/utils";

function MrbPageHeader({ children, classNameOverride, classNameExtend }) {
    return (
        <div
            className={mergeOrOverrideCss(
                "c-page__header",
                classNameOverride,
                classNameExtend
            )}
        >
            {children}
        </div>
    );
}

MrbPageHeader.propTypes = {
    children: PropTypes.any,
    classNameOverride: PropTypes.string,
    classNameExtend: PropTypes.string,
};

export default MrbPageHeader;
