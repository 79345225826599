import { BasePortalDetailedActivityViewStore } from "administration/analytics/stores";

class RegisteredUsersActivityViewStore extends BasePortalDetailedActivityViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            chartLabel: "Portal users",
        });
        this.routeStore = routeStore;
    }

    getActivityChartData(params, abortController) {
        return this.routeStore.getRegisteredUsersActivity(params, abortController);
    }

    fetchSummary(params, abortController) {
        return this.routeStore.getRegisteredUsersSummary(params, abortController);
    }
}

export default RegisteredUsersActivityViewStore;
