import React from "react";
import PropTypes from "prop-types";
import { MrbPage } from "mrb/components/layout";
import { getPageObject } from "mrb/components/layout/utils";

function HomePageLayoutTemplate({ store, children, t, ...pageProps }) {
    const { header, content } = getPageObject(children);

    return (
        <MrbPage {...pageProps} contentClassNameExtend="c-page__main--secondary">
            {header}
            {content.header}
            {content.sidebar}
            <div>{content.children}</div>
            {content.footer}
        </MrbPage>
    );
}

HomePageLayoutTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    t: PropTypes.func.isRequired,
};

export default HomePageLayoutTemplate;
