class ContentAccessResult {
    canAccessOnSelectedDevice = true;
    canAccessPremium = true;

    get canAccess() {
        return this.canAccessOnSelectedDevice === true && this.canAccessPremium === true;
    }
}

export default ContentAccessResult;
