import React from "react";
import { observable, action, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { podcastType } from "common/constants";
import { map, isNil } from "lodash";

const SUB_ITEM_TYPE = {
    SUB_PODCAST: "sub-podcast",
    PODCAST_VOLUME: "podcast-volume",
    NAKI_NIGUN_ALBUM: "naki-nigun-album",
};

class PremiumContentListItemViewStore extends MrbBaseViewStore {
    @observable isCollapsed = false;
    panelRef = React.createRef();
    item = null;
    @observable.ref items = [];
    @observable isPopulated = false;

    get hasSubItems() {
        return (
            this.item.numberOfSubPodcasts > 0 || this.item.numberOfPodcastVolumes > 0 || this.item.numberOfAlbums > 0
        );
    }

    get hasSubContent() {
        return this.hasSubItems || this.item.premiumInfo;
    }

    constructor(rootStore, { item, routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.item = item;
        this.routeStore = routeStore;

        this.reaction(
            () => this.isCollapsed,
            (isCollapsed) => {
                if (isCollapsed && this.panelRef) {
                    // We need to execute scroll function asynchronously in order to take effect.
                    setTimeout(() => {
                        if (this.panelRef) {
                            this.panelRef.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                    });
                }
            }
        );

        this.onClickSubItem = this.onClickSubItem.bind(this);
        this.createCoverImageUrl = this.createCoverImageUrl.bind(this);
    }

    @action.bound
    async toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
        if (this.isCollapsed && !this.isPopulated && this.hasSubContent) {
            await this.getItems();
        }
        this.setIsPopulated(true);
    }

    @action.bound
    setIsPopulated(isPopulated) {
        this.isPopulated = isPopulated;
    }

    setPanelRef = (ref) => {
        this.panelRef = ref;
    };

    @action.bound
    setItems(items) {
        this.items = items;
    }

    async getNakiNigunArtistSubContent() {
        let items = await this.routeStore.getAlbumsByArtist(this.item.nakiNigunArtistId);
        items = map(items, (item) => {
            return {
                ...item,
                type: SUB_ITEM_TYPE.NAKI_NIGUN_ALBUM,
                coverImageUrl: this.createNakiNigunAlbumCoverImageUrl(item),
            };
        });
        this.setItems(items);
    }

    async getPodcastSubContent() {
        if (this.item.podcastType === podcastType.podcastGroup) {
            let items = await this.routeStore.getSubPodcasts(this.item.podcastId);
            items = map(items, (item) => {
                return {
                    ...item,
                    type: SUB_ITEM_TYPE.SUB_PODCAST,
                    coverImageUrl: this.createCoverImageUrl(item),
                };
            });
            this.setItems(items);
        } else if (this.item.podcastType === podcastType.podcast) {
            let items = await this.routeStore.getPodcastVolumes(this.item.podcastId);
            items = map(items, (item) => {
                return {
                    ...item,
                    type: SUB_ITEM_TYPE.PODCAST_VOLUME,
                    coverImageUrl: this.createCoverImageUrl(item),
                };
            });
            this.setItems(items);
        }
    }

    async getItems() {
        try {
            this.loaderStore.suspend();
            if (!isNil(this.item.nakiNigunArtistId)) {
                await this.getNakiNigunArtistSubContent();
            } else if (!isNil(this.item.podcastId)) {
                this.getPodcastSubContent();
            }
            await this.getPodcastSubContent();
        } catch (err) {
            console.log(err);
            this.rootStore.notificationStore.error("An unexpected error occurred while trying to fetch content.", err);
        } finally {
            this.loaderStore.resume();
        }
    }

    onClickSubItem(subItem) {
        if (subItem.type === SUB_ITEM_TYPE.PODCAST_VOLUME) {
            this.rootStore.routerStore.goTo("master.application.home.podcast", {
                id: this.item.podcastId,
            });
        } else if (subItem.type === SUB_ITEM_TYPE.SUB_PODCAST) {
            this.rootStore.routerStore.goTo("master.application.home.podcast-group", {
                id: this.item.podcastId,
            });
        } else if (subItem.type === SUB_ITEM_TYPE.NAKI_NIGUN_ALBUM) {
            this.rootStore.routerStore.goTo("master.application.naki-nigun.artist", {
                id: this.item.nakiNigunArtistId,
            });
        }
    }

    createNakiNigunAlbumCoverImageUrl(item) {
        if (item.coverImageId) {
            return this.rootStore.coverImageFactory.createNakiNigunAlbumCoverImageUrl(item.coverImageId, 200, 200);
        }
        return null;
    }

    createCoverImageUrl(item) {
        if (item.coverImageId) {
            return this.rootStore.coverImageFactory.createPodcastCoverImageUrl(item.coverImageId, 200, 200);
        } else if (item.podcastFileEntryId) {
            return this.rootStore.coverImageFactory.createPodcastCoverImageUrl(item.podcastFileEntryId, 200, 200);
        }
        return null;
    }
}

export default PremiumContentListItemViewStore;
