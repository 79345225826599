import React from "react";
import { mergeCss } from "mrb/common/utils";

const ProgressbarTemplate = (props) => {
    const { value, total, displayValue, status } = props;

    const percentage = (value / total).toFixed(2);
    const totalWidthValue = percentage * 100;
    const widthValue = `calc(${totalWidthValue}%)`;

    const downloadStatus = {
        new: "new",
        downloading: "downloading",
        finished: "finished",
        canceled: "canceled",
        failed: "failed",
    };

    const statusClass = () => {
        switch (status) {
            case downloadStatus.downloading:
                return "in-progress";
            case downloadStatus.finished:
                return "is-finished";
            case downloadStatus.failed:
                return "is-failed";
            default:
                return "";
        }
    };

    return (
        <div className="c-progressbar">
            <p className="c-progressbar__value">{displayValue}</p>

            <div className="c-progressbar__wrapper">
                <div
                    className={mergeCss("c-progressbar__range", statusClass())}
                    style={{ width: status === downloadStatus.failed ? "100%" : widthValue }}
                ></div>
            </div>
        </div>
    );
};

export default ProgressbarTemplate;
