import { ModuleProvider } from "./";

class ModuleProviderFactory {
    modules = {};

    module(name) {
        if (this.modules[name] === undefined) {
            this.modules[name] = new ModuleProvider(name);
        }

        return this.modules[name];
    }

    find(names) {
        if (!names || names.length === 0) {
            return Object.values(this.modules);
        }
    }
}

const moduleProviderFactory = new ModuleProviderFactory();
export default moduleProviderFactory;
