const deviceMenuTypes = {
    station: "station",
    podcast: "podcast",
    shuffle: "shuffle",
    search: "search",
    separator: "separator",
    directory: "directory",
    nakiNigunArtist: "naki-nigun-artist",
    nakiNigunAlbum: "naki-nigun-album",
    nakiTalesCollection: "naki-tales-collection",
    nakiTalesTitle: "naki-tales-title",
    torahAnytime: "torahanytime",
    myRecentlyUpdated: "recently-updated-my",
    allRecentlyUpdated: "recently-updated-all",
    playlists: "playlists",
};

export default deviceMenuTypes;
