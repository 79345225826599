import React from "react";
import { PropTypes } from "prop-types";
import Dropzone from "react-dropzone";
import { MrbFileUploadValidation } from "./";
import { observer } from "mobx-react";

function MrbFileUpload({ store, children, t }) {
    const { onChange, disabled, setUploadInputRef, clear, config, onDropRejected } = store;
    return (
        <Dropzone
            ref={setUploadInputRef}
            accept={config.acceptedFiles}
            onDrop={onChange}
            onDropRejected={onDropRejected}
            disabled={disabled}
            multiple={config.multiple}
            maxSize={config.maxSize}
            maxFiles={config.maxFiles}
            removeFiles={clear}
        >
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {children}
                        {config.showValidation && <MrbFileUploadValidation store={store} t={t} />}
                    </div>
                </section>
            )}
        </Dropzone>
    );
}

MrbFileUpload.propTypes = {
    store: PropTypes.object.isRequired,
};

export default observer(MrbFileUpload);
