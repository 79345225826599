import { BaseService } from "mrb/core";
import { UserAccountApiRouteService } from "common/services";

class UserAccountService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new UserAccountApiRouteService());
    }

    activateSystemTypeSubscription(data, abortSignal = null) {
        const url = this.routeService.activateSystemTypeSubscription();
        return this.apiClient.post(url, data, null, abortSignal);
    }

    cancelSubscription(subscriptionId, abortSignal = null) {
        const url = this.routeService.cancelSubscription(subscriptionId);
        return this.apiClient.delete(url, null, null, abortSignal);
    }

    getUserSubscriptionInfo(userId, options, abortSignal = null) {
        const url = this.routeService.getUserSubscriptionInfo(userId, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    artistRegister(data, abortSignal = null) {
        const url = this.routeService.artistRegister();
        return this.apiClient.post(url, data, null, abortSignal);
    }
}

export default UserAccountService;
