const timeRangeType = {
    today: "today",
    yesterday: "yesterday",
    last3Days: "last-3-days",
    thisWeek: "this-week",
    lastWeek: "last-week",
    thisMonth: "this-month",
    lastMonth: "last-month",
    custom: "custom",
};

export default timeRangeType;
