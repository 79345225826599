import { MrbBaseEditViewStore } from "mrb/core";
import { AdminProducerEditForm } from "administration/producer/forms";
import { makeObservable } from "mobx";

class AdminProducerEditViewStore extends MrbBaseEditViewStore {
    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            name: "producer",
            FormClass: AdminProducerEditForm,
            autoFocusField: "name",
            navigateBack: "master.administration.producer.list",
            successCreateNotification: null,
            successUpdateNotification: null,
            redirectOnCreateSuccess: ({ id }) =>
                this.rootStore.routerStore.goTo("master.administration.producer.preview", { id: id }),
            redirectOnUpdateSuccess: () =>
                this.rootStore.routerStore.goTo("master.administration.producer.preview", { id: this.id }),
        });
        makeObservable(this);
        this.routeStore = routeStore;
    }

    get(id) {
        return this.routeStore.getProducer(id);
    }

    create(data) {
        return this.routeStore.createProducer(data);
    }

    async update(data) {
        await this.routeStore.updateProducer(data);
    }

    onCreateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to create resource.", err);
    }

    onUpdateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to update resource.", err);
    }
}

export default AdminProducerEditViewStore;
