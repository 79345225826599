import { StateRoute, StateRouteOptions } from "./";
import { flattenArray } from "../../utils";
import { forEach, map } from "lodash";

export default function flattenRoutes(routes) {
    const flatRoutes = getFlatRoutes(routes);
    return {
        routes: flatRoutes,
        routerMaps: getRouterMaps(flatRoutes),
    };
}

function getRouterMaps(flatRoutes) {
    let maps = {};
    forEach(flatRoutes, (item) => {
        maps[item.name] = item.component;
    });
    return maps;
}

function getFlatRoutes(routes, parent, parentOptions = []) {
    return flattenArray(
        map(routes, (r) => {
            return flatten(r, parent, parentOptions);
        })
    );
}

function flatten(route, parent, parentOptions = []) {
    let options = new StateRouteOptions(route, parent);

    if (route.children && route.children.length > 0) {
        const children = getFlatRoutes(route.children, options, parentOptions);
        return [...children];
    }
    const stateRoute = new StateRoute(options);

    return [stateRoute];
}
