const subscriptionStatuses = {
    canceled: "Cancelled",
    pending: "Pending",
    pastDue: "PastDue",
    active: "Active",
    unrecognized: "Unrecognized",
    expired: "Expired",
    trial: "Trial",
};

export default subscriptionStatuses;
