import React from "react";
import { AgGridColumn } from "@ag-grid-community/react";
import { MrbTableActionColumn } from "./";
import { size, some, values, isFunction } from "lodash";

function mrbTableActionColumnRenderer(
    tableStore,
    actions,
    ActionsComponent,
    t
) {
    const actionCount = size(actions);
    return (
        <AgGridColumn
            type="rightAligned"
            cellClass="u-display--flex u-display--flex--jc--flex-end"
            headerName={t(tableStore.config.actionsColumn.title)}
            colId={tableStore.config.actionsColumn.actionsColumnId}
            checkboxSelection={tableStore.config.selection.checkboxSelection}
            headerCheckboxSelection={
                tableStore.config.selection.headerCheckboxSelection
            }
            headerCheckboxSelectionFilteredOnly={
                tableStore.config.selection.headerCheckboxSelectionFilteredOnly
            }
            hide={!hasAction(actions) && !ActionsComponent}
            sortable={false}
            filter={false}
            resizable={false}
            suppressMovable={true}
            lockPosition={false}
            pinned={tableStore.config.actionsColumn.pinned}
            suppressMenu={true}
            suppressSizeToFit={
                tableStore.config.actionsColumn.suppressActionsSizeToFit ||
                (actionCount > 0 ? false : true)
            }
            cellRendererFramework={(cellProps) => (
                <MrbTableActionColumn
                    {...cellProps}
                    tableStore={tableStore}
                    actions={actions}
                    ActionsComponent={ActionsComponent}
                    t={t}
                />
            )}
        />
    );
}

function hasAction(actions) {
    return (
        actions && some(values(actions), (action) => isFunction(action.onClick))
    );
}

export default mrbTableActionColumnRenderer;
