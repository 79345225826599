const CACHE_PREFIX = "CS";

class BaseCacheManager {
    promiseRegistry = {};

    constructor(storageProvider) {
        if (!storageProvider) {
            throw new Error("Storage provider not specified.");
        }
        this.storageProvider = storageProvider;
    }

    composeKey(key, region = null) {
        if (region) {
            return `${CACHE_PREFIX}-${region}-${key}`;
        }
        return `${CACHE_PREFIX}-${key}`;
    }

    async getOrAdd(key, fetchFn, region = null) {
        const composedKey = this.composeKey(key, region);
        let item = this.storageProvider.get(composedKey);
        if (!item) {
            const existingPromise = this.promiseRegistry[composedKey];
            if (existingPromise) {
                return existingPromise;
            }
            const resultPromise = fetchFn(key);
            this.promiseRegistry[composedKey] = resultPromise;
            item = await resultPromise;
            this.storageProvider.add(composedKey, item);
            delete this.promiseRegistry[composedKey];
        }
        return item;
    }

    remove(key, region = null) {
        const composedKey = this.composeKey(key, region);

        this.storageProvider.remove(composedKey);
    }

    clearRegion(region) {
        const keys = this.storageProvider.getKeys();
        const regionKeys = keys.filter((k) =>
            k.startsWith(`${CACHE_PREFIX}-${region}`)
        );

        regionKeys.forEach((key) => {
            this.storageProvider.remove(key);
        });
    }

    clear() {
        const keys = this.storageProvider.getKeys();
        const cacheKeys = keys.filter((k) => k.startsWith(CACHE_PREFIX));
        cacheKeys.forEach((key) => {
            this.storageProvider.remove(key);
        });
    }
}

export default BaseCacheManager;
