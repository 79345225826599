import React from "react";
import PropTypes from "prop-types";
import { MrbPage, MrbPageHeader } from "./";
import { MrbEditContent } from "../content";
import { getPageObject } from "../utils";
import { MrbEditPageHeader } from "./";
import { observer } from "mobx-react";

function MrbEditPage({
    store,
    children,
    onSubmit,
    t,
    submitLabel,
    cancelLabel,
    ...pageProps
}) {
    const {
        form,
        onClickCancel,
        title,
        layoutActions,
        navigationOptions,
        navigateBack,
    } = store;
    const { header, footer, content } = getPageObject(children);
    return (
        <form onSubmit={onSubmit || form.onSubmit}>
            <MrbPage t={t} {...pageProps}>
                {header ? (
                    header
                ) : (
                    <MrbPageHeader>
                        <MrbEditPageHeader
                            form={form}
                            onClickCancel={onClickCancel}
                            title={title}
                            layoutActions={layoutActions}
                            navigateBack={navigateBack}
                            navigationOptions={navigationOptions}
                            submitLabel={submitLabel}
                            cancelLabel={cancelLabel}
                            t={t}
                        />
                    </MrbPageHeader>
                )}
                {content.header}
                {content.sidebar}
                <MrbEditContent>{content.children}</MrbEditContent>
                {content.footer}
                {footer}
            </MrbPage>
        </form>
    );
}

MrbEditPage.propTypes = {
    store: PropTypes.object.isRequired,
    children: PropTypes.any,
    onSubmit: PropTypes.func,
    t: PropTypes.func,
    layoutFooterVisible: PropTypes.bool,
    submitLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
};

export default observer(MrbEditPage);
