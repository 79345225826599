import React, { useCallback, useState } from "react";
import ContentLoader from "react-content-loader";

const AdminProducerSummaryLoaderTemplate = (props) => {
    const [loaderWidth, setLoaderWidth] = useState(0);
    const loaderHeight = 124;

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div ref={ref}>
            <ContentLoader
                speed={2}
                width="100%"
                height={loaderHeight}
                viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                <rect x="0" y="6" rx="6" ry="6" width="33%" height="8" />
                <rect x="0" y="28" rx="6" ry="6" width="24%" height="8" />

                {!props.isUser && (
                    <>
                        <rect x={loaderWidth - 150} y="6" rx="6" ry="6" width="25%" height="8" />
                        <rect x={loaderWidth - 150} y="28" rx="6" ry="6" width="50" height="8" />
                    </>
                )}

                <rect x="0" y="66" rx="6" ry="6" width="33%" height="8" />
                <rect x="0" y="80" rx="6" ry="6" width="24%" height="42" />
            </ContentLoader>
        </div>
    );
};

export default AdminProducerSummaryLoaderTemplate;
