import React from "react";
import { PropTypes } from "prop-types";
import { LineChart } from "common/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { AnalyticsGraphLoader } from "administration/analytics/components";
import { observer } from "mobx-react";

function DeviceActivitySummaryTemplate({ deviceActivitySummaryViewStore, t }) {
    const {
        deviceActivityChartStore,
        rootStore: { routerStore },
    } = deviceActivitySummaryViewStore;

    return (
        <div>
            <MrbRouterLink
                className="c-analytics__title"
                routeName="master.administration.analytics.device-activity"
                queryParams={routerStore.routerState.queryParams}
            >
                Active devices
                <i className="u-icon u-icon--base u-icon--arrow-right u-mar--left--tny" />
            </MrbRouterLink>
            <div className="c-card c-card--primary">
                <LineChart store={deviceActivityChartStore} loaderRenderer={() => <AnalyticsGraphLoader />} />
            </div>
        </div>
    );
}

DeviceActivitySummaryTemplate.propTypes = {
    deviceActivitySummaryViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(DeviceActivitySummaryTemplate);
