import React from "react";
import { PropTypes } from "prop-types";
import { MrbSimpleSelect } from "mrb/components/select";
import { observer } from "mobx-react";
import { mergeCss } from "mrb/common/utils";

function HomeDeviceSelectTemplate({ store, userDeviceStore, t }) {
    return (
        <MrbSimpleSelect
            store={store}
            valueRender={(props) => <DeviceSelectValue userDeviceStore={userDeviceStore} {...props} />}
            optionRender={(props) => <DeviceOption userDeviceStore={userDeviceStore} {...props} />}
            t={t}
        />
    );
}

HomeDeviceSelectTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    userDeviceStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const DeviceSelectValue = observer(function DeviceSelectValue({ selectedOption, userDeviceStore }) {
    const { isDeviceSyncEnabled, setIsDeviceSyncEnabled } = userDeviceStore;
    return (
        <div className="u-display--flex u-display--flex--ai--center">
            <i
                className={mergeCss(
                    "u-icon u-icon--sync u-mar--right--tny jq-onboarding-sync",
                    isDeviceSyncEnabled ? "u-icon--sync--active" : "u-icon--sync--inactive"
                )}
                onMouseDown={(event) => {
                    setIsDeviceSyncEnabled(!isDeviceSyncEnabled);
                    event.preventDefault();
                    event.stopPropagation();
                }}
            />
            <div className="c-select__label jq-onboarding-select-device">{selectedOption.label}</div>
        </div>
    );
});

const DeviceOption = observer(function DeviceOption({ option, userDeviceStore }) {
    const { isDeviceSyncEnabled } = userDeviceStore;
    const isMobile = option.item.device.systemType.abrv;
    const isDisabled = option.item.isDisabled;

    return (
        <div className="u-display--flex">
            <div
                className={mergeCss(
                    "u-mar--right--tny",
                    isDeviceSyncEnabled ? "c-select__item--active" : "",
                    isDisabled && "c-select__item--disabled"
                )}
            >
                <i
                    className={mergeCss(
                        "u-icon u-icon--med--alt",
                        isMobile === "mobile" ? "u-icon--mobile--sml" : "u-icon--radio--sml"
                    )}
                ></i>
            </div>

            {option.label}
        </div>
    );
});

export default HomeDeviceSelectTemplate;
