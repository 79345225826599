import { MrbBaseRouteStore } from "mrb/core";
import { ProductService } from "common/services";

class ChangeSubscriptionPlanPreviewRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.productService = rootStore.createApplicationService(ProductService);
    }

    async getAllSubscriptionProducts() {
        const response = await this.productService.getAllSubscriptions();
        return response.data;
    }
}

export default ChangeSubscriptionPlanPreviewRouteStore;
