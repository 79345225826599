import React from "react";
import PropTypes from "prop-types";
import { default as MrbBasicSelect } from "../select/MrbBasicSelect";
import { observer } from "mobx-react";
import { range, map, min, filter, isBoolean, isInteger } from "lodash";

function MrbPager({
    tableStore,
    pageNeighbours = window.innerWidth >= 425 ? 2 : 1,
    pageSizes = [10, 30, 50, 100],
    onPageChange = null,
    nextPageLabel = "Next",
    previousPageLabel = "Previous",
    itemsPerPageLabel = "Items per page",
    totalRecordsLabel = "Total Records",
    hidePagerIfUnderLimit = false,
    hideItemsPerPage = false,
    t = (i) => i,
}) {
    const { pageNumber, pageSize, recordCount, setPage, setPageSize } =
        tableStore;

    let hideControls = false;
    if (
        isBoolean(hidePagerIfUnderLimit) &&
        hidePagerIfUnderLimit &&
        recordCount <= min(pageSizes)
    ) {
        hideControls = true;
    } else if (
        isInteger(hidePagerIfUnderLimit) &&
        recordCount <= hidePagerIfUnderLimit
    ) {
        hideControls = true;
    }

    const totalPages = Math.ceil(recordCount / pageSize);
    const pages = createPageNumbers(totalPages, pageNumber, pageNeighbours);

    const pageSizeItems = map(pageSizes, (item) => {
        return {
            value: item,
            label: item,
        };
    });

    const goToPage = (page) => {
        const currentPage = Math.max(0, Math.min(page, recordCount));

        if (onPageChange && typeof onPageChange === "function") {
            onPageChange(page);
        } else {
            setPage(currentPage);
        }
    };

    const onClickPage = (page) => (event) => {
        event.preventDefault();
        goToPage(page);
    };

    const onClickPreviousPage = (event) => {
        event.preventDefault();
        goToPage(pageNumber - 1);
    };

    const onClickNextPage = (event) => {
        event.preventDefault();
        goToPage(pageNumber + 1);
    };

    return (
        <React.Fragment>
            <div className="c-pagination">
                {recordCount > 0 && (
                    <React.Fragment>
                        <div>
                            {!hideControls && (
                                <div className="c-pagination__pagination">
                                    <nav aria-label="Pagination">
                                        <ul className="c-pagination__list">
                                            {pageNumber > 1 && (
                                                <li className="c-pagination__item c-pagination__item--prev">
                                                    <button
                                                        className="c-pagination__item--link"
                                                        aria-label={
                                                            previousPageLabel
                                                        }
                                                        onClick={
                                                            onClickPreviousPage
                                                        }
                                                    >
                                                        <span aria-hidden="true">
                                                            &laquo;
                                                        </span>
                                                        <span className="sr-only">
                                                            {previousPageLabel}
                                                        </span>
                                                    </button>
                                                </li>
                                            )}
                                            {pageNumber - pageNeighbours >
                                                1 && (
                                                <React.Fragment>
                                                    <li className="c-pagination__item">
                                                        <button
                                                            className="c-pagination__item--link"
                                                            onClick={onClickPage(
                                                                1
                                                            )}
                                                        >
                                                            1
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <span>...</span>
                                                    </li>
                                                </React.Fragment>
                                            )}
                                            {pages.map((page, index) => {
                                                return (
                                                    <li
                                                        key={index}
                                                        className={`c-pagination__item${
                                                            pageNumber === page
                                                                ? " c-pagination__item--active"
                                                                : ""
                                                        }`}
                                                    >
                                                        <button
                                                            className="c-pagination__item--link"
                                                            onClick={onClickPage(
                                                                page
                                                            )}
                                                        >
                                                            {page}
                                                        </button>
                                                    </li>
                                                );
                                            })}
                                            {pageNumber + pageNeighbours <
                                                totalPages && (
                                                <React.Fragment>
                                                    <li>
                                                        <span>...</span>
                                                    </li>
                                                    <li className="c-pagination__item">
                                                        <button
                                                            className="c-pagination__item--link"
                                                            onClick={onClickPage(
                                                                totalPages
                                                            )}
                                                        >
                                                            {totalPages}
                                                        </button>
                                                    </li>
                                                </React.Fragment>
                                            )}
                                            {pageNumber < totalPages ? (
                                                <li className="c-pagination__item c-pagination__item--next">
                                                    <button
                                                        className="c-pagination__item--link"
                                                        aria-label={
                                                            nextPageLabel
                                                        }
                                                        onClick={
                                                            onClickNextPage
                                                        }
                                                    >
                                                        <span className="sr-only">
                                                            {nextPageLabel}
                                                        </span>{" "}
                                                        <span aria-hidden="true">
                                                            &raquo;
                                                        </span>
                                                    </button>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </nav>
                                </div>
                            )}
                        </div>
                        <div className="c-pagination__total">
                            {!hideControls && !hideItemsPerPage && (
                                <React.Fragment>
                                    <div className="c-pagination__total__label">
                                        {t(itemsPerPageLabel)}
                                    </div>
                                    <MrbBasicSelect
                                        items={pageSizeItems}
                                        value={pageSize}
                                        onSelect={setPageSize}
                                    />{" "}
                                </React.Fragment>
                            )}
                            <p className="u-type--base u-type--color--opaque">
                                {t(totalRecordsLabel)}:{" "}
                                <span className="u-type--color--text">
                                    {recordCount}
                                </span>
                            </p>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}

MrbPager.propTypes = {
    tableStore: PropTypes.object.isRequired,
    pageNeighbours: PropTypes.number,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    onPageChange: PropTypes.func,
    nextPageLabel: PropTypes.string,
    previousPageLabel: PropTypes.string,
    itemsPerPageLabel: PropTypes.string,
    totalRecordsLabel: PropTypes.string,
    hidePagerIfUnderLimit: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
    ]),
    hideItemsPerPage: PropTypes.bool,
    t: PropTypes.func,
};

const createPageNumbers = (totalPages, currentPage, pageNeighbours) => {
    const ranges = range(-pageNeighbours, pageNeighbours + 1);
    return filter(
        map(ranges, (rangeNum) => currentPage + rangeNum),
        (page) => page > 0 && page <= totalPages
    );
};

export default observer(MrbPager);
