import { timeGroup } from "administration/analytics/constants";
import { applicationDefaults } from "common/constants";
import { dateFormatter } from "mrb/common/formatting";
import { map } from "lodash";
import moment from "moment";

function createTimeRangeChartLabel(result) {
    if (result.groupedBy === timeGroup.hour) {
        return map(result.data, (item) => {
            const startOfDay = moment.tz(item.periodStartDate, result.timeZone).startOf("day").toISOString();
            if (moment(item.periodStartDate).isSame(startOfDay, "hour")) {
                return dateFormatter.format(item.periodStartDate, {
                    format: "MMM D",
                    timeZone: result.timeZone,
                });
            }
            return dateFormatter.format(item.periodStartDate, {
                format: "HH A",
                timeZone: result.timeZone,
            });
        });
    } else if (result.groupedBy === timeGroup.day) {
        return map(result.data, (item) =>
            dateFormatter.format(item.periodStartDate, {
                format: "MMM D",
                timeZone: result.timeZone,
            })
        );
    } else if (result.groupedBy === timeGroup.week) {
        return map(result.data, (item) => {
            const startOfWeek = dateFormatter.format(item.periodStartDate, {
                format: "MMM D",
            });
            const endOfWeek = dateFormatter.format(item.periodEndDate, {
                format: "MMM D",
                timeZone: result.timeZone,
            });

            return `${startOfWeek} - ${endOfWeek}`;
        });
    } else if (result.groupedBy === timeGroup.month) {
        return map(result.data, (item) =>
            dateFormatter.format(item.periodStartDate, {
                format: applicationDefaults.formats.month,
                timeZone: result.timeZone,
            })
        );
    }
}

export default createTimeRangeChartLabel;
