import { action, computed, makeObservable } from "mobx";
import { BaseHomePreviewViewStore } from "application/home/stores";
import { UpdateStationStatusOptions } from "application/common/models";
import { isNil } from "lodash";

class HomeStationViewStore extends BaseHomePreviewViewStore {
    @computed get coverImageUrl() {
        if (this.item && this.item.coverImageId) {
            return this.routeStore.createStationCoverImageUrl(this.item.coverImageId, 200, 200);
        }

        return null;
    }

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            navigateBack: "master.application.home.list",
        });
        makeObservable(this);
        this.routeStore = routeStore;
    }

    async get(id) {
        if (this.rootStore.userDeviceStore.selectedDevice) {
            return this.routeStore.getStationWthDeviceSettings(id, this.rootStore.userDeviceStore.selectedDevice.id);
        } else {
            return this.routeStore.getStation(id);
        }
    }

    setItem(item) {
        super.setItem(item);
        this.setStatusItem(item.userDeviceStation);
    }

    @action.bound
    async updateTurnedOnStatus(isTurnedOn) {
        try {
            this.setUpdatingStatus(true);
            const options = new UpdateStationStatusOptions(
                this.item.id,
                this.item.title,
                this.item.isPremium,
                this.item.isVisibleInApp === false,
                this.item.isVisibleInEmbeddedApp === false
            );
            await this.rootStore.userContentManagerStore.updateStationStatus(options, isTurnedOn);
            this.setStatusItem({ isTurnedOn: isTurnedOn });
        } catch (err) {
            this.rootStore.notificationStore.error("Unexpected error occurred. Please contact the support team.", err);
        } finally {
            this.setUpdatingStatus(false);
        }
    }

    getStatusItem(deviceId, resourceId) {
        return this.routeStore.getUserDeviceStation(deviceId, resourceId);
    }

    evaluateContentAccess(item) {
        if (isNil(item)) {
            return false;
        }
        return this.rootStore.contentAccessValidator.canAccessStation({
            isPremium: item.isPremium,
            isLegacySubscriptionValid: item.validLegacySubscription,
            unavailableInMobileApp: item.isVisibleInApp === false,
            unavailableInNakiTouch: item.isVisibleInEmbeddedApp === false,
        });
    }
}

export default HomeStationViewStore;
