import { BaseApiRouteService } from "mrb/core";

class PremiumPaymentAnalyticsApiRouteService extends BaseApiRouteService {
    constructor() {
        super("analytics/premium-payment");
    }

    getPodcastGroupPaymentStatistic(id, options) {
        return super.get(`${this.base}/podcast-group/{id}{?from,to,page,rpp}`, id, options);
    }

    getPodcastPaymentStatistic(id, options) {
        return super.get(`${this.base}/podcast/{id}{?from,to,page,rpp}`, id, options);
    }

    getStationPaymentStatistic(id, options) {
        return super.get(`${this.base}/station/{id}{?from,to,page,rpp}`, id, options);
    }

    findPremiumPaymentSummary(filter) {
        return super.find(`${this.base}/summary{?searchQuery,from,to,page,rpp,sort}`, filter);
    }
}

export default PremiumPaymentAnalyticsApiRouteService;
