import React from "react";
import PropTypes from "prop-types";
import { renderActions } from "../utils";
import { MrbBreadcrumbs } from "../../breadcrumbs";
import { isNil } from "lodash";
import { default as MrbRouterLink } from "../../../core/infrastructure/router/MrbRouterLink";
import { observer } from "mobx-react";

function MrbPreviewPageHeader({ title, layoutActions, navigationOptions, navigateBack, t = (i) => i }) {
    const layoutActionsLength = !isNil(layoutActions) ? Object.keys(layoutActions).length : 0;

    return (
        <React.Fragment>
            <div className="c-page__header--top">
                {navigateBack ? (
                    <MrbRouterLink routeName={navigateBack.routeName} params={navigateBack.routeParams}>
                        <span className="display--ib u-mar--right--tny align--v--middle">
                            <i className="u-icon u-icon--med u-icon--back" />
                        </span>
                        {t(navigateBack.title)}
                    </MrbRouterLink>
                ) : (
                    <div>{t(title)}</div>
                )}
                <div
                    className={`${layoutActionsLength <= 0 ? "" : "c-header__actions"} ${
                        layoutActionsLength < 2 ? "c-header__actions--end" : ""
                    }`}
                >
                    {renderActions(layoutActions, t)}
                </div>
            </div>
            {navigationOptions && (
                <div className="c-page__header--bottom">
                    <MrbBreadcrumbs options={navigationOptions} t={t} />
                </div>
            )}
        </React.Fragment>
    );
}

MrbPreviewPageHeader.propTypes = {
    title: PropTypes.string,
    layoutActions: PropTypes.object,
    navigationOptions: PropTypes.object,
    navigateBack: PropTypes.object,
    t: PropTypes.func,
};

export default observer(MrbPreviewPageHeader);
