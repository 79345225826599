import React from "react";
import { PropTypes } from "prop-types";
import { MrbNotifyOutsideClick } from "../../notify-outside-click";
import { MrbSimpleSelectOption } from "./";
import { mergeCss } from "../../../common/utils";
import { map } from "lodash";
import { observer } from "mobx-react";

function MrbSimpleSelectPanel({ store, optionRender = (props) => <MrbSimpleSelectOption {...props} />, t }) {
    const { close, options, onChange, valueContainerRef, focusedIndex, setFocusedIndex } = store;
    return (
        <MrbNotifyOutsideClick action={close} ignoreElementsRef={[valueContainerRef]}>
            {options && options.length > 0 ? (
                map(options, (option, index) => (
                    <div
                        className={mergeCss(
                            "c-select__item",
                            focusedIndex === index ? "focused" : null,
                            option.isDisabled && "isDisabled"
                        )}
                        key={option.value}
                        onMouseOver={() => setFocusedIndex(index)}
                        onMouseDown={() => onChange(option)}
                    >
                        {optionRender({ option, index, t })}
                    </div>
                ))
            ) : (
                <div className="c-card--tny">
                    <p className="u-type--base u-type--center">No Results</p>
                </div>
            )}
        </MrbNotifyOutsideClick>
    );
}

MrbSimpleSelectPanel.propTypes = {
    store: PropTypes.object.isRequired,
    optionRender: PropTypes.func,
    t: PropTypes.func,
};

export default observer(MrbSimpleSelectPanel);
