import React from "react";
import PropTypes from "prop-types";

function MrbListContent({ children }) {
    return <React.Fragment>{children}</React.Fragment>;
}

MrbListContent.propTypes = {
    children: PropTypes.any,
};

export default MrbListContent;
