import React from "react";
import { PropTypes } from "prop-types";
import { MrbSimpleSelectPanel } from "./";
import { observer } from "mobx-react";
import { mergeCss } from "../../../common/utils";

function MrbSimpleSelectPanelContainer({
    dropdownClassNameExtend,
    store,
    optionRender,
    t,
}) {
    const { isOpen } = store;
    return (
        <div
            className={mergeCss(
                "c-select__dropdown",
                dropdownClassNameExtend ? dropdownClassNameExtend : null
            )}
        >
            {isOpen && (
                <MrbSimpleSelectPanel
                    store={store}
                    t={t}
                    optionRender={optionRender}
                />
            )}
        </div>
    );
}

MrbSimpleSelectPanelContainer.propTypes = {
    store: PropTypes.object.isRequired,
    optionRender: PropTypes.func,
    t: PropTypes.func,
};

export default observer(MrbSimpleSelectPanelContainer);
