import React from "react";

function ImageUploadNoteTemplate({ showInfo }) {
    return (
        <React.Fragment>
            <i className="u-icon u-icon--huge u-icon--upload"></i>
            <p className="u-type--sml u-type--center u-mar--top--sml">
                Click here to upload or <br /> drag and drop image
            </p>
            {showInfo && (
                <ul className="u-type--sml u-type--center u-mar--top--sml">
                    <li className="u-type--sml u-type--color--opaque">• Upload jpg, png, or gif files</li>
                    <li className="u-type--sml u-type--color--opaque">• Max. file size is 20MB</li>
                    <li className="u-type--sml u-type--color--opaque">• 400px x 400px file size is recommended</li>
                </ul>
            )}
        </React.Fragment>
    );
}

export default ImageUploadNoteTemplate;
