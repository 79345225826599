import { cloneDeep } from "lodash";
import { buildAuthorizedMenu } from "../utils";
import { isSome } from "../../common/utils";

class MenuProvider {
    menus = [];

    initialize = (menus, context) => {
        this.menus = [...menus];
        this.context = context;

        const rootStore = this.context.rootStore;

        if (menus.length > 0 && !isSome(rootStore.routerStore.routes)) {
            throw new Error(
                "Menu provider requires that root store routes are initialized - initialize RouteProvider"
            );
        }

        return this.buildMenu(
            rootStore,
            cloneDeep(menus),
            rootStore.routerStore.routes
        );
    };

    buildMenu = (rootStore, menu, routes) => {
        return buildAuthorizedMenu(
            menu,
            routes,
            rootStore.userStore.hasPermission
        );
    };
}

const menuProvider = new MenuProvider();
export default menuProvider;
