import React from "react";
import ContentLoader from "react-content-loader";

const HomePreviewStationsLoaderTemplate = (props) => {
    const screenwidth = window.innerWidth;
    let loaderWidth;

    if (screenwidth > 1200) {
        loaderWidth = 380;
    } else if (screenwidth > 990) {
        loaderWidth = screenwidth - 260;
    } else {
        loaderWidth = screenwidth;
    }

    const items = [];
    let row = 3;
    let y = 0;

    for (let i = 0; i < row; i++) {
        items.push(
            <React.Fragment key={`_${i}`}>
                <rect x="0" y={y} rx="6" ry="6" width="65" height="7" />
                <rect x="0" y={y + 20} rx="6" ry="6" width="154" height="12" />
            </React.Fragment>
        );

        y += 60;
    }

    return (
        <ContentLoader
            speed={2}
            width={loaderWidth}
            height="100%"
            viewBox={`0 0 ${loaderWidth} ${160}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            {items}
        </ContentLoader>
    );
};

export default HomePreviewStationsLoaderTemplate;
