import { subscriptionPlans } from "common/constants";

const subscriptionPlansSortOrder = [
    subscriptionPlans.nakiRadioPremium,
    subscriptionPlans.nakiTales,
    subscriptionPlans.nakiNigun,
    subscriptionPlans.nakiRadioFree,
];

export default subscriptionPlansSortOrder;
