import { observable, action, makeObservable, computed, runInAction } from "mobx";
import { AdminProducerAccountsEditForm, AdminProducerAccountEditFields } from "administration/producer/forms";
import MobxReactFormDevTools from "mobx-react-form-devtools";
import { MrbBaseViewStore } from "mrb/core";
import { forEach, sumBy, map, filter, isNil } from "lodash";
import { applicationErrorCodes } from "common/constants";
import { UUID } from "common/utils";
import { localizationService } from "common/localization";

class AdminProducerAccountsViewStore extends MrbBaseViewStore {
    @observable.ref producerTotalBalance;
    @observable.ref producer;
    @observable.ref navigateBack;
    @observable.ref stripeConnectAccounts = [];
    @observable.ref accounts = [];

    producerId;

    @computed get selectedUsers() {
        const values = this.form.values();
        const usersIds = map(values, (value) => value.userId);
        return filter(usersIds, (userId) => userId !== "");
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);

        this.routeStore = routeStore;
        this.producerId = this.rootStore.routerStore.routerState.params.id;

        this.form = new AdminProducerAccountsEditForm({
            onSuccess: (form) => {
                const item = form.values();
                if (isNil(item)) {
                    return this.updateResource([]);
                }
                const values = Object.values(item);
                if (values && values.length > 0) {
                    const revenueShare = sumBy(values, (account) => account.revenuePercentage);
                    if (revenueShare !== 100) {
                        runInAction(() => {
                            form.invalidate("Revenue share accross all accounts must be 100%.");
                        });
                        return;
                    }
                }
                return this.updateResource(values);
            },
        });

        if (process.env.NODE_ENV === "development") {
            MobxReactFormDevTools.register({ accounts: this.form });
            MobxReactFormDevTools.select("accounts");
        }
    }

    async onInit() {
        await Promise.all([
            this.getStripeConnectAccounts(),
            this.getProducer(this.producerId),
            this.getProducerAccounts(this.producerId),
        ]);
    }

    @action.bound
    async getProducer(producerId) {
        try {
            const producer = await this.routeStore.getProducer(producerId);
            this.setProducer(producer);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load producer. Please contact the support team."
            );
        }
    }

    @action.bound
    async getProducerAccounts(producerId) {
        try {
            const accounts = await this.routeStore.getProducerAccountsSummary(producerId);
            this.setAccounts(accounts);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load producer accounts. Please contact the support team."
            );
        }
    }

    @action.bound
    async getProducerTotalBalance(producerId) {
        try {
            const totalBalance = await this.routeStore.getProducerTotalBalance(producerId);
            this.setProducerTotalBalance(totalBalance);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load producer total balance. Please contact the support team."
            );
        }
    }

    _createNavigateBackAction(producer) {
        return {
            title: producer.name,
            routeName: "master.administration.producer.preview",
            routeParams: { id: producer.id },
        };
    }

    @action.bound
    async getStripeConnectAccounts() {
        try {
            const accounts = await this.routeStore.getStripeConnectAccounts();
            this.setStripeConnectAccounts(accounts);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load Stripe Connect accounts. Please contact the support team."
            );
        }
    }

    @action.bound
    setProducer(producer) {
        this.producer = producer;
        this.navigateBack = this._createNavigateBackAction(this.producer);
    }

    @action.bound
    setAccounts(accounts) {
        if (accounts && accounts.length > 0) {
            forEach(accounts, (account) => {
                const fields = [];
                forEach(AdminProducerAccountEditFields, (fieldDefinition) => {
                    let field = {
                        ...fieldDefinition,
                    };

                    field.value = account[fieldDefinition.name];

                    fields.push(field);
                });
                fields.push({
                    name: "accountId",
                    rules: "string",
                    value: account.producerUserAccountId,
                });
                this.form.add({
                    key: UUID.create(),
                    fields: fields,
                });
            });
            this.accounts = accounts;
        }
    }

    @action.bound
    setStripeConnectAccounts(accounts) {
        this.stripeConnectAccounts = accounts;
    }

    @action.bound
    addNewAccount() {
        this.form.add({
            fields: [...AdminProducerAccountEditFields],
        });
    }

    @action.bound
    setProducerTotalBalance(totalBalance) {
        this.producerTotalBalance = totalBalance;
    }

    @action.bound
    async updateResource(accounts) {
        try {
            await this.routeStore.updateAccounts(this.producerId, accounts);

            this.rootStore.routerStore.goTo("master.administration.producer.preview", { id: this.producer.id });
        } catch (error) {
            if (error.data.errorCode === applicationErrorCodes.producer.userIsAdministrator) {
                this.rootStore.notificationStore.error(
                    localizationService.t("ADMINISTRATION.USER.EDIT.ERROR.ADMIN_SELECTED_AS_PRODUCER"),
                    error
                );
            } else if (error.data.errorCode === applicationErrorCodes.producer.duplicateUsersSelected) {
                this.rootStore.notificationStore.error(
                    localizationService.t("ADMINISTRATION.USER.EDIT.ERROR.USER_SELECTED_MULTIPLE_TIMES"),
                    error
                );
            } else {
                this.rootStore.notificationStore.error(
                    "An unexpected error occurred while trying to update accounts. Please contact the support team."
                );
            }
        }
    }
}

export default AdminProducerAccountsViewStore;
