import { override } from "mobx";
import { MrbBaseRouteStore } from "mrb/core";
import { PaymentService, ProductService } from "common/services";

class ChangeSubscriptionPlanRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PaymentService);
        this.productService = rootStore.createApplicationService(ProductService);
    }

    @override
    onBeforeEnter(fromState, toState) {
        const subscriptionPlanId = toState.params.planId;
        const subscription =
            this.rootStore.premiumSubscriptionStore.getSubscriptionBySubscriptionPlan(subscriptionPlanId);
        if (subscription) {
            return Promise.reject("master.application.premium.subscriptions");
        }
        return Promise.resolve();
    }

    async getSubscriptionPlan(planId) {
        const response = await this.productService.getSubscription(planId);
        return response.data;
    }

    changeSubscriptionPlan(planId) {
        return this.rootStore.premiumSubscriptionStore.changeSubscriptionPlan(planId);
    }

    async getSubscriptionChangePreview(subscriptionId, planId) {
        const response = await this.service.getSubscriptionChangePreview(subscriptionId, planId);
        return response.data;
    }
}

export default ChangeSubscriptionPlanRouteStore;
