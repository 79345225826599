import { BaseApiRouteService } from "mrb/core";

class PlaylistApiRouteService extends BaseApiRouteService {
    constructor() {
        super("playlist");
    }

    findAdminGeneratedPlaylists(filter) {
        return super.find(`${this.base}/admin/{?searchQuery,page,rpp,sort,embed,fields}`, filter);
    }

    createAdminGeneratedPlaylist(resource) {
        return super.create(`${this.base}/admin`, resource);
    }

    addItemToAdminGeneratedPlaylist(playlistId, resource) {
        return super.update(`${this.base}/admin/{id}/item`, { id: playlistId, resource: resource });
    }

    deleteAlbumTrackFromAdminGeneratedPlaylist(playlistId, albumTrackId) {
        return super.delete(`${this.base}/admin/{id}/item/album-track/{albumTrackId}`, {
            id: playlistId,
            albumTrackId: albumTrackId,
        });
    }

    getAllAdminGeneratedPlaylists() {
        return super.get(`${this.base}/admin/all`);
    }

    updateAdminGeneratedPlaylists() {
        return super.update(`${this.base}/admin`);
    }

    getAdminPlaylist(id) {
        return super.get(`${this.base}/admin/{id}`, id);
    }

    deleteAlbumTrackFromAdminPlaylist(playlistId, albumTrackId) {
        return super.delete(`${this.base}/admin/{id}/item/album-track/{albumTrackId}`, {
            id: playlistId,
            albumTrackId: albumTrackId,
        });
    }
}

export default PlaylistApiRouteService;
