import { timeRangeType } from "administration/analytics/constants";
import { applicationDefaults } from "common/constants";
import { dateFormatter } from "mrb/common/formatting";

class TimeRangeLabelParser {
    createCurrentCycleLabel(range, from, to, timeZone) {
        switch (range) {
            case timeRangeType.today:
                return "Today";
            case timeRangeType.yesterday:
                return "Yesterday";
            case timeRangeType.last3Days:
                return "Last 3 Days";
            case timeRangeType.thisWeek:
                return "This Week";
            case timeRangeType.lastWeek:
                return "Last Week";
            case timeRangeType.thisMonth:
                return "This Month";
            case timeRangeType.lastMonth:
                return "Last Month";
            default:
                const fromDate = dateFormatter.format(from, {
                    format: applicationDefaults.formats.date,
                    timeZone: timeZone,
                });
                const toDate = dateFormatter.format(to, {
                    format: applicationDefaults.formats.date,
                    timeZone: timeZone,
                });
                return `${fromDate} - ${toDate}`;
        }
    }

    createPreviousCycleLabel(range, from, to, timeZone) {
        switch (range) {
            case timeRangeType.today:
                return "Yesterday";
            case timeRangeType.yesterday:
                return dateFormatter.format(from, {
                    format: applicationDefaults.formats.date,
                    timeZone: timeZone,
                });
            case timeRangeType.thisWeek:
                return "Last Week";
            case timeRangeType.thisMonth:
                return "Last Month";
            case timeRangeType.lastMonth:
                return dateFormatter.format(from, {
                    format: applicationDefaults.formats.monthFull,
                    timeZone: timeZone,
                });
            case timeRangeType.last3Days:
            case timeRangeType.lastWeek:
            default:
                const fromDate = dateFormatter.format(from, {
                    format: applicationDefaults.formats.date,
                    timeZone: timeZone,
                });
                const toDate = dateFormatter.format(to, {
                    format: applicationDefaults.formats.date,
                    timeZone: timeZone,
                });
                return `${fromDate} - ${toDate}`;
        }
    }
}

const timeRangeLabelParser = new TimeRangeLabelParser();
export default timeRangeLabelParser;
