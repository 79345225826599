import { MrbApplicationMainSectionStore } from "mrb/core/stores";
import { moduleBuilder } from "mrb/core/infrastructure";
import { find } from "lodash";
import { producerTypes } from "common/constants";
import { localizationService } from "common/localization";

class NakiRadioApplicationSectionStore extends MrbApplicationMainSectionStore {
    constructor(rootStore) {
        super(rootStore);
    }

    async initialize() {
        if (!this.isInitialized) {
            this.rootStore.authenticationStore.resetSignInRedirect();
            this.buildMenu();
            this.setIsInitialized(true);
        }
        if (this.rootStore.authenticationStore.isAuthenticated && !this.rootStore.userStore.isAdministrator) {
            await Promise.all([
                this.rootStore.userDeviceStore.initialize(),
                this.rootStore.premiumSubscriptionStore.refreshUserPremiumSubscription(),
            ]);
        }
    }

    buildMenu() {
        if (this.rootStore.userStore.isAdministrator) {
            super.buildMenu(["administration"]);
        } else {
            this._buildApplicationMenu(["application"]);
        }
    }

    _buildApplicationMenu(modules) {
        var menu = moduleBuilder.buildMenus(this.rootStore.configuration.menus, { rootStore: this.rootStore }, modules);

        var producerMenu = find(menu, (item) => item.route === "master.application.producer.preview.dashboard");
        var producerVerification = producerMenu && this.rootStore.userStore.isProducer;
        if (producerVerification && this.rootStore.userStore.producerType?.slug === producerTypes.artistSlug) {
            producerMenu.title = localizationService.t("APPLICATION.PRODUCER.MENU.ARTIST_TITLE");
        } else if (
            producerVerification &&
            this.rootStore.userStore.producerType?.slug === producerTypes.podcasterSlug
        ) {
            producerMenu.title = localizationService.t("APPLICATION.PRODUCER.MENU.PODCASTER_TITLE");
        }

        this.rootStore.menuStore.setMenu(menu);
    }
}

export default NakiRadioApplicationSectionStore;
