import React from "react";

const StatusTagTemplate = ({ isActive }) => {
    return (
        <div className={`c-tag c-tag--sml c-tag--${isActive ? "active" : "inactive"}--outline`}>
            <i
                className={`u-icon u-icon--med u-icon--circle--${isActive ? "active" : "inactive"} u-mar--right--micro`}
            ></i>
            {isActive ? "Active" : "Inactive"}
        </div>
    );
};

export default StatusTagTemplate;
