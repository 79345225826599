import { some, reverse, every, map, orderBy } from "lodash";
import { action, observable, computed, makeObservable } from "mobx";
import { generateUrl } from "mobx-state-router";

class MenuItem {
    title = null;
    icon = null;
    route = null;
    params = null;
    parent = null;
    isExternal = false;
    order = null;

    @observable isSelected = false;

    @observable isActive = false;

    @observable.ref subMenu = [];

    @computed get hasChildren() {
        return this.subMenu && this.subMenu.length > 0;
    }

    get canNavigate() {
        return this.route && this.route !== "";
    }

    get isClickable() {
        return (this.hasChildren && some(this.subMenu, (m) => m.isClickable)) || this.canNavigate;
    }

    constructor(menuStore, json) {
        this.menuStore = menuStore;
        makeObservable(this);
        this.title = json.title;
        this.icon = json.icon;
        this.parent = json.parent || null;
        this.route = json.route;
        this.order = json.order || null; // if order is not defined, push item to the bottom
        this.path = reverse([this, ...(this.parent ? reverse([...this.parent.path]) : [])]);
        this.params = json.params;

        this.depth = this.path.length;

        if (json.subMenu) {
            this.subMenu = orderBy(
                map(json.subMenu, (item) => {
                    return new MenuItem(this.menuStore, {
                        ...item,
                        subMenu: item.subMenu,
                        parent: this,
                    });
                }),
                (item) => item.order,
                "asc"
            );
        }

        this.equals = this.equals.bind(this);
        this.isActiveByPath = this.isActiveByPath.bind(this);
        this.shouldHideChildren = json.shouldHideChildren || false;
    }

    getRoute(forceLeaf = false) {
        if (!this.canNavigate) {
            if (forceLeaf && this.hasChildren) {
                const subItem = this.subMenu[0]; // immediatelly navigate to first child
                return subItem.getRoute();
            }
        } else {
            const route = typeof this.route === "function" ? this.route() : this.route;
            if (route !== null) {
                return route;
            }
        }

        return null;
    }

    equals(other) {
        return this.depth === other.depth && this.title === other.title && this.route === other.route;
    }

    isActiveByPath(path) {
        if (path.length < this.path.length) return false;
        return every(this.path, (p, idx) => p.equals(path[idx]));
    }

    getPattern() {
        const routeName = this.getRoute();
        if (routeName) {
            const routerStore = this.menuStore.rootStore.routerStore;
            const pattern = routerStore.findRoute(routeName) ? routerStore.findRoute(routeName).pattern : "/";
            return generateUrl(pattern);
        }

        return "";
    }

    @action.bound
    setIsSelected(isSelected) {
        this.isSelected = isSelected;
    }

    @action.bound
    setIsActive(isActive) {
        this.isActive = isActive;
    }

    @action.bound
    onClick() {}
}

export default MenuItem;
