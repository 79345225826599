import { computed, makeObservable, observable, runInAction } from "mobx";
import { BaasicUserStore } from "mrb/baasic";
import { ProducerUserAccountService, UserSettingsService } from "common/services";
import { isNil, some } from "lodash";

class NakiRadioUserStore extends BaasicUserStore {
    @observable producerType = null;

    @computed get isAdministrator() {
        if (this.user && this.user.roles) {
            return some(this.user.roles, (roleName) => roleName === "Administrators");
        }
        return false;
    }

    @computed get isProducer() {
        if (this.user && this.user.roles) {
            return some(this.user.roles, (roleName) => roleName === "Producers");
        }
        return false;
    }

    @computed get isContentPolicyVisible() {
        return this.user && this.user.userProfile.membership.contentPolicyVisible !== false;
    }

    @computed get isTorahAnytimeAvailabilityVisible() {
        return this.user && this.user.userProfile.membership.torahAnytimeAvailabilityVisible !== false;
    }

    @computed get isConnectDeviceMessageVisible() {
        return this.user && this.user.userProfile.membership.connectDeviceVisible !== false;
    }

    @computed get isContentUnavailableInAppPolicyVisible() {
        return this.user && this.user.userProfile.membership.contentUnavailableInAppVisible !== false;
    }

    _userSettingsService = null;
    _producerUserAccountService = null;

    get userSettingsService() {
        if (isNil(this._userSettingsService)) {
            this._userSettingsService = this.rootStore.createApplicationService(UserSettingsService);
        }
        return this._userSettingsService;
    }

    get producerUserAccountService() {
        if (isNil(this._producerUserAccountService)) {
            this._producerUserAccountService = this.rootStore.createApplicationService(ProducerUserAccountService);
        }
        return this._producerUserAccountService;
    }

    constructor(rootStore) {
        super(rootStore);
        makeObservable(this);
    }

    async resolveUser() {
        await super.resolveUser();
        if (this.isAdministrator) {
            this.rootStore.routerStore.setSystemRoute("initialState", "master.administration.dashboard");
        } else {
            this.rootStore.routerStore.setSystemRoute("initialState", "master.application.home.list");
        }

        if (this.isProducer) {
            var result = await this.producerUserAccountService.getProducerUserAccountProducerType();
            var producerType = result.data;
            runInAction(() => {
                this.producerType = producerType;
            });
        }
    }

    async disableContentPolicy() {
        await this.userSettingsService.disableContentPolicy();
        await this.rootStore.userStore.refreshUserAccount();
    }

    async disableTorahAnytimeAvailability() {
        await this.userSettingsService.disableTorahAnytimeAvailability();
        await this.rootStore.userStore.refreshUserAccount();
    }

    async disableConnectDeviceMessage() {
        await this.userSettingsService.disableConnectDevice();
        await this.rootStore.userStore.refreshUserAccount();
    }

    async disableContentUnavailableInAppPolicy() {
        await this.userSettingsService.disableContentUnavailableInAppPolicy();
        await this.rootStore.userStore.refreshUserAccount();
    }

    async updateAccountInfo(data) {
        await this.rootStore.application.baasic.membershipModule.user.update(data);
        await this.refreshUserAccount();
    }
}

export default NakiRadioUserStore;
