import React from "react";
import { PropTypes } from "prop-types";
import { isNil } from "lodash";

function DeviceHardwareCellTemplate({ data }) {
    if (isNil(data.deviceHardware)) {
        return "-";
    }

    return <React.Fragment>{deviceHardwareFormatter(data.deviceHardware, data.deviceFirmware)}</React.Fragment>;
}

DeviceHardwareCellTemplate.propTypes = {
    data: PropTypes.object.isRequired,
};

function deviceHardwareFormatter(deviceHardware, deviceFirmware) {
    let hardware = null;
    switch (deviceHardware) {
        case "venice6":
            hardware = "Venice 6.5";
            break;
        case "venicex":
            hardware = "Venice X";
            break;
        default:
            hardware = deviceHardware;
            break;
    }

    if (deviceFirmware) {
        return `${hardware} (${deviceFirmware})`;
    }

    return deviceHardware;
}

export default DeviceHardwareCellTemplate;
