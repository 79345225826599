import React from "react";

function MrbTabContent({ children }) {
    return <React.Fragment>{render(children)}</React.Fragment>;
}

function render(content) {
    return content
        ? typeof content === "function"
            ? content()
            : content
        : null;
}

export default MrbTabContent;
