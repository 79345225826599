import { action, computed, observable, makeObservable, reaction } from "mobx";
import { MenuItem } from "../models";
import { each, orderBy, find, last, split, difference } from "lodash";

class MrbMenuStore {
    rawMenu = [];

    @observable isInitialized = false;

    @observable.ref menu = [];
    @observable.ref activePath = [];
    @observable.ref selectedPath = [];

    @observable isOpen = false;

    @computed get secondaryMenuVisible() {
        return this.selectedPath && this.selectedPath.length > 0;
    }

    @computed get secondaryMenu() {
        const path = this.selectedPath.length === 0 ? this.activePath : this.selectedPath;

        const parent = find(this.menu, (item) => item.isActiveByPath(path));
        return parent ? parent.subMenu : [];
    }

    @computed get activeMenuItem() {
        return last(this.activePath);
    }

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    initialize() {
        if (!this.isInitialized) {
            reaction(
                () => this.rootStore.routerStore.routerState,
                (activeRoute) => {
                    this.syncMenuRoute(activeRoute);
                    if (this.isOpen) {
                        this.closeMenu();
                    }
                }
            );
            this.setIsInitialized(true);
        }
    }

    @action.bound
    setIsInitialized(value) {
        this.isInitialized = value;
    }

    @action.bound
    setMenu(menu) {
        this.rawMenu = menu;
        this.menu = menu;

        const menuItems = [];
        each(menu, (item) => {
            const menuItem = new MenuItem(this, item);
            menuItems.push(menuItem);
        });
        this.menu = orderBy(menuItems, (item) => item.order, "asc");
    }

    //toggles menu open/close is-open, for mobile
    @action.bound
    toggleMenuOpen() {
        this.isOpen = !this.isOpen;
    }

    @action.bound
    setIsOpen(isOpen) {
        this.isOpen = isOpen;
    }

    @action.bound
    closeMenu() {
        this.setIsOpen(false);
    }

    @action.bound
    setActivePath(path) {
        this.activePath = path;
    }

    @action.bound
    setSelectedPath(path) {
        this.selectedPath = path;
    }

    @action.bound
    selectMenuItem(event, item) {
        if (event) {
            event.preventDefault();
        }
        let newPath = item.path;
        if (
            item.path.length < this.selectedPath.length ||
            (item.path.length === this.selectedPath.length && !last(item.path).canNavigate)
        ) {
            const subPath = this._getCommonSubPath(item.path, this.selectedPath);
            if (subPath && subPath.length > 0) {
                subPath.pop();
                newPath = subPath;
            }
        }

        if (this.selectedPath && this.selectedPath.length > 0) {
            each(this.selectedPath, (selectedMenuItem) => {
                selectedMenuItem.setIsSelected(false);
            });
        }
        each(newPath, (menuItem) => {
            menuItem.setIsSelected(true);
        });
        this.setSelectedPath(newPath);
    }

    @action.bound
    setActiveMenuItem(event, item) {
        if (event) {
            event.preventDefault();
        }
        if (this.activePath && this.activePath.length > 0) {
            each(this.activePath, (menuItem) => {
                menuItem.setIsActive(false);
            });
        }
        each(item.path, (menuItem) => {
            menuItem.setIsActive(true);
        });
        this.setActivePath(item.path);
        this.selectMenuItem(event, item);
    }

    @action.bound
    syncMenuRoute(activeRoute) {
        if (!this.menu || this.menu.length === 0) {
            return;
        }
        const activeItem = findActiveMenuItem(this.menu, activeRoute.routeName);
        if (activeItem) {
            this.setActiveMenuItem(null, activeItem);
        }
    }

    _getCommonSubPath(lhs, rhs) {
        const subPath = [];
        const minLength = Math.min(lhs.length, rhs.length);
        for (let index = 0; index < minLength; index++) {
            if (lhs[index].equals(rhs[index])) {
                subPath.push(lhs[index]);
            }
        }

        return subPath;
    }
}

function findActiveMenuItem(menu, route) {
    let bestMatch = {
        difference: 0,
        item: null,
    };

    for (let i = 0; i < menu.length; i++) {
        const item = menu[i];
        if (item.route) {
            const menuRoute = typeof item.route === "function" ? item.route() : item.route;
            if (menuRoute === route) {
                // return menu item that has exact route as specified route
                return item;
            } else {
                // find menu item that has 'closest' route match to specified route
                // e.g. master.platform.main.user.create doesn't have menu definition
                // so its closest match would be master.platform.main.user.list
                const difference = getDifference(menuRoute, route);
                if (difference >= 0 && difference <= bestMatch.difference) {
                    bestMatch.item = item;
                    bestMatch.difference = difference;
                }
            }
        }

        if (item.hasChildren) {
            const child = findActiveMenuItem(item.subMenu, route);
            if (child) {
                return child;
            }
        }
    }

    return bestMatch.item;
}

function getDifference(menuRoute, route) {
    let menuParts = split(menuRoute, ".");
    let routeParts = split(route, ".");

    const diff = routeParts.length - menuParts.length;
    if (diff < 0) return -1;

    return difference(menuParts.slice(0, -1), routeParts.slice(0, -(diff + 1))).length;
}

export default MrbMenuStore;
