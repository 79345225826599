const applicationDefaults = {
    rpp: 20,
    formats: {
        dateTime: "MM/DD/YYYY HH:mm A",
        dateTimeSeconds: "MM/DD/YYYY HH:mm:ss A",
        date: "MM/DD/YYYY",
        longDate: "MMMM DD, YYYY",
        month: "MMM YYYY",
        monthFull: "MMMM, YYYY",
        monthShort: "MM/YYYY",
        isoDate: "YYYY-MM-DD",
    },
    maximumNumberOfDevices: 5,
    trialPeriodDays: 7,
    defaultProductReportTimeZone: "America/New_York",
};

export default applicationDefaults;
