import React from "react";
import { PropTypes } from "prop-types";
import { MrbSimpleSelectValue } from "./";
import { mergeCss } from "../../../common/utils";
import { observer } from "mobx-react";

function MrbSimpleSelectValueContainer({
    store,
    valueRender = (props) => <MrbSimpleSelectValue {...props} />,
    showDropdownIndicator = true,
    t,
}) {
    const {
        toggleIsOpen,
        setValueContainerRef,
        selectedOption,
        config: { placeholder },
        onFocus,
        onBlur,
    } = store;

    return (
        <div
            onMouseDown={toggleIsOpen}
            ref={setValueContainerRef}
            className="c-select__input"
            onFocus={onFocus}
            onBlur={onBlur}
            tabIndex="0"
        >
            {selectedOption ? (
                valueRender({ selectedOption, t })
            ) : (
                <span>{placeholder}</span>
            )}
            {showDropdownIndicator && <DropdownIndicator store={store} />}
        </div>
    );
}

MrbSimpleSelectValueContainer.propTypes = {
    store: PropTypes.object.isRequired,
    showDropdownIndicator: PropTypes.bool,
    t: PropTypes.func,
};

const DropdownIndicator = observer(function ({ store }) {
    return (
        <i
            className={mergeCss(
                "u-icon u-icon--med u-mar--left--tny u-icon--arrow-up",
                store.isOpen ? "" : "u-rotate--180"
            )}
        ></i>
    );
});

export default observer(MrbSimpleSelectValueContainer);
