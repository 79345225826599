import { BaseHomePodcastRouteStore } from "application/home/stores";

class HomePodcastGroupRouteStore extends BaseHomePodcastRouteStore {
    constructor(rootStore) {
        super(rootStore);
    }

    async getPodcastGroupWthDeviceSettings(podcastId, deviceId) {
        const response = await this.userContentService.getPodcastGroupWthDeviceSettings(podcastId, deviceId, {
            embed: "subPodcasts.location,subPodcasts.language",
        });
        return response.data;
    }

    async getPodcastGroup(podcastId) {
        const response = await this.userContentService.getPodcastGroup(podcastId, {
            embed: "subPodcasts.location,subPodcasts.language",
        });
        return response.data;
    }
}

export default HomePodcastGroupRouteStore;
