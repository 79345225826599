import React from "react";
import { withRootStore } from "./";

function withAuthorization(Component) {
    class AuthComponent extends React.Component {
        render() {
            const { rootStore, authorization, ...otherProps } = this.props;
            return authorization ? (
                rootStore.userStore.hasPermission(authorization) ? (
                    <Component {...otherProps} />
                ) : null
            ) : (
                <Component {...otherProps} />
            );
        }
    }

    return withRootStore(AuthComponent);
}

export default withAuthorization;
