import React from "react";
import { mergeOrOverrideCss } from "baasic-react-bootstrap/src/common";

const EmptyState = ({ title, description, classNameOverride, classNameExtend }) => {
    return (
        <div className={mergeOrOverrideCss("c-empty-state--no-results", classNameOverride, classNameExtend)}>
            <i className="u-icon--welcome u-icon--welcome--sml u-mar--bottom--sml"></i>
            <h3>{title ? title : "No results found"}</h3>
            <p>{description ? description : "Try adjusting your search to find what you are looking for."}</p>
        </div>
    );
};

export default EmptyState;
