import React, { useCallback, useState } from "react";
import ContentLoader from "react-content-loader";

const ProducerBalanceInfoLoaderTemplate = (props) => {
    const [loaderWidth, setLoaderWidth] = useState(0);
    const loaderHeight = 230;

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div ref={ref} className="c-card--primary c-card--med">
            <ContentLoader
                speed={2}
                width="100%"
                height={loaderHeight}
                viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                <rect x="0" y="0" rx="6" ry="6" width="55%" height="8" />
                <rect x="0" y="20" rx="6" ry="6" width="35%" height="32" />

                <rect x="0" y="100" rx="6" ry="6" width="55%" height="8" />
                <rect x="0" y="120" rx="6" ry="6" width="35%" height="16" />
                <rect x={loaderWidth - 60} y="120" rx="6" ry="6" width={60} height="16" />

                <rect x="0" y="170" rx="6" ry="6" width="55%" height="8" />
                <rect x="0" y="190" rx="6" ry="6" width="35%" height="32" />
            </ContentLoader>
        </div>
    );
};

export default ProducerBalanceInfoLoaderTemplate;
