import React from "react";
import { PropTypes } from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { map } from "lodash";
import { observer } from "mobx-react";

function DragDropListTemplate({
    droppableId = "droppable",
    itemRender,
    items,
    generateItemKey = (item) => item.id,
    onDragEnd,
}) {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={droppableId}>
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {map(items, (item, index) => (
                            <Draggable key={generateItemKey(item)} draggableId={generateItemKey(item)} index={index}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {itemRender(item)}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

DragDropListTemplate.propTypes = {
    droppableId: PropTypes.string,
    items: PropTypes.array.isRequired,
    itemRender: PropTypes.any.isRequired,
    generateItemKey: PropTypes.func,
    onDragEnd: PropTypes.func.isRequired,
};

export default observer(DragDropListTemplate);
