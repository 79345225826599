import React from "react";
import ContentLoader from "react-content-loader";

const ProducersDetailsListLoaderTemplate = (props) => {
    const { className, row = 11 } = props;
    const screenwidth = window.innerWidth;
    const items = [];

    let loaderWidth = null;
    let y = 0;

    if (screenwidth > 1199) {
        loaderWidth = screenwidth - 690;
    } else if (screenwidth > 990) {
        loaderWidth = screenwidth - 250;
    } else {
        loaderWidth = screenwidth;
    }

    for (let i = 0; i < row; i++) {
        items.push(
            <React.Fragment key={i}>
                <rect x="0" y={y} rx="6" ry="6" width="100%" height="52" />
            </React.Fragment>
        );

        y += 67;
    }

    return (
        <div>
            <ContentLoader
                speed={2}
                width={"100%"}
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${67 * row}`}
                backgroundColor="#3C3D3E"
                foregroundColor="#404143"
                className={className}
                {...props}
            >
                {items}
            </ContentLoader>
        </div>
    );
};

export default ProducersDetailsListLoaderTemplate;
