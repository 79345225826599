import { BaseService } from "mrb/core";
import { UserSettingsApiRouteService } from "common/services";

class UserSettingsService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new UserSettingsApiRouteService());
    }

    updateIsTutorialPassed(isTutorialPassed, abortSignal = null) {
        const url = this.routeService.updateIsTutorialPassed(isTutorialPassed);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    disableContentPolicy(abortSignal = null) {
        const url = this.routeService.disableContentPolicy();
        return this.apiClient.put(url, null, null, abortSignal);
    }

    disableConnectDevice(abortSignal = null) {
        const url = this.routeService.disableConnectDevice();
        return this.apiClient.put(url, null, null, abortSignal);
    }

    disableTorahAnytimeAvailability(abortSignal = null) {
        const url = this.routeService.disableTorahAnytimeAvailability();
        return this.apiClient.put(url, null, null, abortSignal);
    }

    disableContentUnavailableInAppPolicy(abortSignal = null) {
        const url = this.routeService.disableContentUnavailableInAppPolicy();
        return this.apiClient.put(url, null, null, abortSignal);
    }
}

export default UserSettingsService;
