import { BaasicApp } from "baasic-sdk-reactjs";
import { localStorageHandler } from "../common";

class BaasicAppFactory {
    create = (apiKey, options = {}) => {
        return new BaasicApp(apiKey, {
            storageHandler: () => localStorageHandler,
            ...options,
        });
    };
}

const baasicAppFactory = new BaasicAppFactory();
export default baasicAppFactory;
