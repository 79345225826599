import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { mergeCss } from "../../common/utils";

function MrbTabHeader({ onClick, label, isActive, icon, t = (i) => i }) {
    return (
        <li onClick={onClick}>
            <span
                className={mergeCss(
                    "c-tabs__item",
                    isActive ? "c-tabs__item--active" : null
                )}
            >
                {t(label)}
            </span>
            {icon && <span className={icon}></span>}
        </li>
    );
}

MrbTabHeader.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    t: PropTypes.func,
};

export default observer(MrbTabHeader);
