import { observable, makeObservable } from "mobx";
import { MrbFilterParameters } from "mrb/core/filter";

class HomeContentFilter extends MrbFilterParameters {
    @observable contentType;
    @observable isEnabled;
    @observable isPremium;

    constructor() {
        super();
        makeObservable(this);
        this.reset();
    }

    reset() {
        super.reset();
        this.contentType = 0;
        this.isEnabled = null;
        this.isPremium = null;
    }
}

export default HomeContentFilter;
