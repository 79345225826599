import React from "react";
import { PropTypes } from "prop-types";
import { renderTabs } from "./utils";
import { observer } from "mobx-react";

function MrbTabHeaders({ store, headersRenderer, children, t }) {
    if (headersRenderer) {
        return headersRenderer({ store, children, t });
    }
    return (
        <nav className="c-tabs__navigation">
            <ul className="c-tabs">{renderTabs(store, children, t)}</ul>
        </nav>
    );
}

MrbTabHeaders.propTypes = {
    store: PropTypes.object.isRequired,
    headersRenderer: PropTypes.func,
    children: PropTypes.node.isRequired,
    t: PropTypes.func,
};

export default observer(MrbTabHeaders);
