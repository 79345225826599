import React from "react";
import { PropTypes } from "prop-types";
import { MrbTable } from "mrb/components/table";
import { mergeCss } from "mrb/common/utils";
import { observer } from "mobx-react";

function ProducerAccountPreviewRevenueTransactionTemplate({ title, gridStore, emptyStateRenderer, t }) {
    return (
        <div>
            {title && <h1 className="u-type--base u-mar--bottom--sml">{t(title)}</h1>}
            <MrbTable
                t={t}
                tableStore={gridStore}
                className={mergeCss(
                    `ag-theme-alpine`,
                    gridStore.data.length <= 5 && gridStore.data.length !== 0 ? "ag-theme-alpine--sml" : "",
                    gridStore.data.length === 0 && "ag-theme-alpine--empty"
                )}
                emptyStateRender={emptyStateRenderer}
            />
        </div>
    );
}

ProducerAccountPreviewRevenueTransactionTemplate.propTypes = {
    title: PropTypes.string,
    gridStore: PropTypes.object.isRequired,
    emptyStateRenderer: PropTypes.any,
    t: PropTypes.func,
};

export default observer(ProducerAccountPreviewRevenueTransactionTemplate);
