import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldLayout } from "../layout/content/form";
import { mergeOrOverrideCss } from "../../common/utils";
import { isNil } from "lodash";
import { observer } from "mobx-react";

function MrbFieldInput({
    field,
    t = (i) => i,
    showLabel = true,
    disabled,
    labelRender,
    validationRender,
    infoRenderer,
    infoLabelRenderer,
    classNameExtend,
    classNameOverride,
    ...restOfProps
}) {
    const { placeholder, ...otherProps } = field.bind();

    if (otherProps.value && typeof otherProps.value === "string")
        otherProps.value = otherProps.value.replace(/\s+/g, " ");
    if (otherProps.value == null) otherProps.value = "";

    return (
        <MrbFieldLayout
            field={field}
            t={t}
            labelRender={labelRender}
            validationRender={validationRender}
            infoRenderer={infoRenderer}
            infoLabelRenderer={infoLabelRenderer}
            showLabel={showLabel}
        >
            <input
                className={mergeOrOverrideCss("c-input c-input--text c-input--med", classNameOverride, classNameExtend)}
                {...otherProps}
                {...restOfProps}
                disabled={isNil(disabled) ? otherProps.disabled : disabled}
                placeholder={t(placeholder)}
            />
        </MrbFieldLayout>
    );
}

MrbFieldInput.propTypes = {
    field: PropTypes.object.isRequired,
    showLabel: PropTypes.bool,
    t: PropTypes.func,
    labelRender: PropTypes.func,
    validationRender: PropTypes.func,
    infoRenderer: PropTypes.func,
    infoLabelRenderer: PropTypes.func,
    classNameExtend: PropTypes.string,
};

export default observer(MrbFieldInput);
