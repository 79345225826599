import React from "react";
import { MrbTabHeader } from "../";

function renderTabs(store, children, t) {
    return React.Children.map(children, (tab, idx) => {
        if (!tab) {
            return null;
        }
        const { label, icon } = tab.props;
        const { key } = tab;
        return (
            <MrbTabHeader
                icon={icon}
                label={label}
                key={key ? key : idx}
                onClick={() => store.handleTabClick(idx)}
                isActive={idx === store.activeTabIndex}
                t={t}
            />
        );
    });
}

export default renderTabs;
