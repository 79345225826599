import React from "react";
import { MrbFieldSelect } from "mrb/components";
import { components } from "react-select";

function StripeAccountFieldSelectTemplate({ isOptionDisabled, ...props }) {
    return (
        <MrbFieldSelect
            isOptionDisabled={isOptionDisabled}
            components={{ Option: AccountOption, SingleValue: AccountSelectedValue }}
            {...props}
        />
    );
}

function AccountOption(props) {
    const { item } = props.data;

    return (
        <components.Option {...props}>
            <AccountLabel account={item} />
        </components.Option>
    );
}

function AccountSelectedValue(props) {
    const { item } = props.data;

    return (
        <components.SingleValue {...props}>
            <AccountLabel account={item} />
        </components.SingleValue>
    );
}

function AccountLabel({ account }) {
    let label;
    if (account.email) {
        label = `${account.id} (${account.email})`;
    } else {
        label = account.id;
    }
    return label;
}

export default StripeAccountFieldSelectTemplate;
