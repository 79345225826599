import { BaseApiRouteService } from "mrb/core";

class ShuffleApiRouteService extends BaseApiRouteService {
    constructor() {
        super("shuffles");
    }

    find(filter) {
        return super.find(`${this.base}/{?searchQuery,page,rpp,sort,embed,fields}`, filter);
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?embed,fields}`, id, options);
    }

    createStation(resource) {
        return super.create(`${this.base}/station`, resource);
    }

    createNakiNigun(resource) {
        return super.create(`${this.base}/naki-nigun`, resource);
    }

    createPodcast(resource) {
        return super.create(`${this.base}/podcast`, resource);
    }

    update(resource) {
        return super.update(`${this.base}/{id}`, resource);
    }

    delete(resource) {
        return super.delete(`${this.base}/{id}`, resource);
    }
}

export default ShuffleApiRouteService;
