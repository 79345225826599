import { MrbConfirmModalParams } from "../../common/models";
import { action, observable, makeObservable } from "mobx";

class MrbConfirmDialogStore {
    @observable.ref confirmModalParams = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @action.bound
    showConfirm(options) {
        this.confirmModalParams = new MrbConfirmModalParams(options);
        this.confirmModalParams.open();
    }
}

export default MrbConfirmDialogStore;
