import { PropTypes } from "prop-types";
import { roundedCurrencyFormatter } from "common/utils";
import { currencyFormatter } from "mrb/common/formatting";

function SubscriptionPriceTemplate({ price, isRounded = false }) {
    const value = isRounded
        ? roundedCurrencyFormatter.format(price)
        : currencyFormatter.format(price, { currency: "USD" });

    return `${value}/month`;
}

SubscriptionPriceTemplate.propTypes = {
    price: PropTypes.number.isRequired,
    isRounded: PropTypes.bool,
};

export default SubscriptionPriceTemplate;
