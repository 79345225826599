import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { map, isNil, isFunction } from "lodash";

function MrbFileUploadValidation({ store, t = (i) => i }) {
    const {
        rejectedFiles,
        config: { errorsMap },
    } = store;
    return map(rejectedFiles, (rejectedFile) => {
        return (
            <div key={rejectedFile.file.name} className="c-validation--error--secondary u-type--center u-mar--top--sml">
                <p className="c-validation--input__label">Unable to upload file: {rejectedFile.file.name}</p>
                {map(rejectedFile.errors, (error) => {
                    return (
                        <p className="c-validation--input__label" key={error.code}>
                            {getErrorMessage(error, errorsMap, t)}
                        </p>
                    );
                })}
            </div>
        );
    });
}

function getErrorMessage(error, errorsMap, t) {
    if (!isNil(errorsMap) && isFunction(errorsMap[error.code])) {
        return errorsMap[error.code](error, t);
    }
    return error.message;
}

MrbFileUploadValidation.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(MrbFileUploadValidation);
