import React from "react";
import { PodcastListenersListTemplate } from "themes/administration/analytics/components";
import { PodcastListenersListViewStore } from "administration/analytics/stores";
import { setCurrentView } from "mrb/core";
import { observer } from "mobx-react";

@setCurrentView(
    (rootStore, componentProps) => new PodcastListenersListViewStore(rootStore, componentProps),
    "podcastListenersListViewStore"
)
@observer
class PodcastListenersList extends React.Component {
    render() {
        return <PodcastListenersListTemplate {...this.props} />;
    }
}

export default PodcastListenersList;
