import React, { useCallback, useState } from "react";
import ContentLoader from "react-content-loader";

const ToggleActionsLoaderTemplate = ({
    smallWidth,
    sidebarLrg,
    rows = 1,
    setHeight,
    setY = 40,
    backgroundColor = "#262729",
    foregroundColor = "#2b2b2b",
    ...props
}) => {
    const [loaderWidth, setLoaderWidth] = useState(0);

    const ref = useCallback((node) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const items = [];
    let y = 0;

    for (let i = 0; i < rows; i++) {
        let textWidth = Math.random() * (150 - 100) + 100;
        if (i + 1 === rows) {
            items.push(
                <React.Fragment key={i}>
                    {/* prettier-ignore */}
                    <rect x="0" y={y + 6} rx="6" ry="6" width={textWidth} height="10" />
                    {/* prettier-ignore */}
                    <rect x="85%" y={y} rx="6" ry="6" width="15%" height="24" />
                </React.Fragment>
            );
        } else {
            items.push(
                <React.Fragment key={i}>
                    {/* prettier-ignore */}
                    <rect x="0" y={y + 6} rx="6" ry="6" width={textWidth} height="10" />
                    {/* prettier-ignore */}
                    <rect x="90%" y={y} rx="10" ry="10" width="10%" height="20" />
                </React.Fragment>
            );
        }

        y += setY;
    }

    return (
        <div ref={ref}>
            <ContentLoader
                speed={2}
                width={loaderWidth}
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${setHeight ? setHeight : y - 15}`}
                backgroundColor={backgroundColor}
                foregroundColor={foregroundColor}
                {...props}
            >
                {items}
            </ContentLoader>
        </div>
    );
};

export default ToggleActionsLoaderTemplate;
