import { action, override } from "mobx";
import { MrbSimpleSelectStore } from "../";
import { arrayDifference } from "../../../../common/utils";
import { isArray, isEmpty } from "lodash";

class MrbFieldSimpleSelectStore extends MrbSimpleSelectStore {
    formField = null;

    @override get value() {
        return this.formField.value;
    }

    constructor(config, formField, initialItems = null) {
        super(config, initialItems);
        if (!formField) {
            throw new Error("formField must be provided");
        }
        this.formField = formField;
    }

    @override
    setValue(value) {
        if (isArray(this.formField.value) && isArray(value)) {
            if (arrayDifference(this.formField.value, value).length > 0) {
                this.setFormValue(value);
            }
        } else if (
            this.formField.value !== value &&
            (!isEmpty(this.formField.value) || !isEmpty(value))
        ) {
            this.setFormValue(value);
        }
    }

    @action.bound
    setFormValue(value) {
        this.formField.onChange(value);
    }
}

export default MrbFieldSimpleSelectStore;
