class MediaVaultStreamService {
    constructor(apiClient, baasic) {
        this.apiClient = apiClient;
        this.mediaVaultModule = baasic.mediaVaultModule;
    }

    create(data, file, abortSignal = null) {
        const url = this.mediaVaultModule.streams.routeDefinition.create(data);
        let formData = new FormData();
        formData.append("file", file);
        return this.apiClient.post(url, formData, null, abortSignal);
    }

    get(id) {
        return this.mediaVaultModule.streams.getBlog(id);
    }
}

export default MediaVaultStreamService;
