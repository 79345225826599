import React, { useRef, useState, useEffect } from "react";
import ContentLoader from "react-content-loader";

const PlanCardLoaderTemplate = (props) => {
    const ref = useRef(null);
    const [loaderWidth, setLoaderWidth] = useState(0);

    useEffect(() => {
        setLoaderWidth(ref.current.offsetWidth);
    }, []);

    return (
        <div ref={ref} className="c-payment__card c-payment__card--plan">
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${loaderWidth} 90`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                <rect x="0" y={84 / 2 - 20} rx="6" ry="6" width="40" height="40" />
                <rect x="50" y={4} rx="6" ry="6" width="160" height="16" />
                <rect x="50" y={40} rx="6" ry="6" width="60" height="20" />
                <rect x="50" y={70} rx="6" ry="6" width="100" height="12" />
            </ContentLoader>
        </div>
    );
};

export default PlanCardLoaderTemplate;
