import React from "react";
import { AdminProducerAccountsListTemplate } from "themes/administration/producer/components";
import { AdminProducerAccountsListViewStore } from "administration/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new AdminProducerAccountsListViewStore(rootStore, componentProps),
    "adminProducerAccountsListViewStore"
)
class AdminProducerAccountsList extends React.Component {
    render() {
        return <AdminProducerAccountsListTemplate {...this.props} />;
    }
}

export default AdminProducerAccountsList;
