import { endsWith } from "lodash";

function joinPaths(a, b) {
    a = a || "";
    b = b || "";
    if (b === "") return a;
    return removeEndingSlash(a) + "/" + removeStartingSlash(b);
}

function removeStartingSlash(str) {
    return str.replace(/^[\/]+/, ""); // eslint-disable-line
}

function removeEndingSlash(str) {
    return str.replace(/[\/]+$/, ""); // eslint-disable-line
}

function getSkipRoutePrefix(fromState, toState) {
    const fromParts = getRouteParts(fromState.routeName);
    const toParts = getRouteParts(toState.routeName);

    let skipRoutePrefix = undefined;
    const length = fromParts.length;
    for (let j = 0; j < toParts.length; j++) {
        if (length < j + 1) {
            break;
        }

        const fromElement = fromParts[j];
        const toElement = toParts[j];
        if (fromElement === toElement) {
            skipRoutePrefix =
                skipRoutePrefix === undefined
                    ? toElement
                    : skipRoutePrefix + "." + toElement;
        }
    }
    return skipRoutePrefix;
}

function getBreadcrumbTitle(routeName) {
    const predicate = (suffix) => endsWith(routeName, suffix);

    let breadcrumb;
    if (predicate(".list")) {
        breadcrumb = "";
    } else if (predicate(".edit")) {
        breadcrumb = "BREADCRUMBS.EDIT";
    } else if (predicate(".create")) {
        breadcrumb = "BREADCRUMBS.CREATE";
    } else if (predicate(".language") || predicate(".localization")) {
        breadcrumb = "BREADCRUMBS.LANGUAGE";
    } else if (predicate(".settings")) {
        breadcrumb = "BREADCRUMBS.SETTINGS";
    } else if (predicate(".preview")) {
        breadcrumb = "BREADCRUMBS.PREVIEW";
    }
    return breadcrumb;
}

function getRouteParts(route) {
    return route.split(".");
}

export { joinPaths, getSkipRoutePrefix, getBreadcrumbTitle };
