import React from "react";
import ContentLoader from "react-content-loader";

const StatsWidgetLoaderTemplate = (props) => {
    const { row = 3, statsPreview, className } = props;
    const screenwidth = window.innerWidth;
    const items = [];
    let loaderWidth;

    if (screenwidth > 1199) {
        loaderWidth = 387;
    } else if (screenwidth > 990) {
        loaderWidth = screenwidth / 2 - 40;
    } else if (screenwidth > 766) {
        loaderWidth = screenwidth / 2 + 60;
    } else {
        loaderWidth = screenwidth;
    }

    let y = 0;

    for (let i = 0; i < row; i++) {
        if (i === 1) {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="6" ry="6" width="80" height="8" />
                    <rect x="0" y={y + 24} rx="6" ry="6" width="60" height="14" />

                    <rect x="88%" y={y} rx="6" ry="6" width="12%" height="38" />
                    <rect x="84%" y={y + 48} rx="6" ry="6" width="16%" height="8" />
                </React.Fragment>
            );
        } else {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="6" ry="6" width="80" height="8" />
                    <rect x="0" y={y + 24} rx="6" ry="6" width="140" height="14" />
                </React.Fragment>
            );
        }

        y += 76;
    }

    return (
        <ContentLoader
            speed={2}
            width={loaderWidth}
            height="100%"
            viewBox={`0 0 ${loaderWidth} ${statsPreview ? y - (row * 20) : 265}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            className={className}
            {...props}
        >
            {statsPreview ? <StatsPreviewLoader row={row} /> :
                <React.Fragment>
                    {items}
                    <React.Fragment>
                        <rect x="0" y={y - 20} rx="6" ry="6" width="100%" height="1" />
                        <rect x="0" y={y - 5} rx="6" ry="6" width="100%" height="42" />
                    </React.Fragment>
                </React.Fragment>
            }
        </ContentLoader >
    );
};

export default StatsWidgetLoaderTemplate;


const StatsPreviewLoader = (props) => {
    const { row } = props;
    const items = [];

    let y = 8;

    for (let i = 0; i < row; i++) {
        if (i === 1) {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="6" ry="6" width="80" height="8" />
                    <rect x="0" y={y + 24} rx="6" ry="6" width="60" height="8" />

                    <rect x="60%" y={y} rx="6" ry="6" width="35%" height="8" />
                    <rect x="60%" y={y + 24} rx="6" ry="6" width="8%" height="8" />
                </React.Fragment>
            );
        } else {
            items.push(
                <React.Fragment key={i}>
                    <rect x="0" y={y} rx="6" ry="6" width="180" height="8" />
                    <rect x="0" y={y + 24} rx="6" ry="6" width="60" height="8" />

                    <rect x="78%" y={y} rx="6" ry="6" width="40" height="20" />
                    <rect x="73%" y={y + 6} rx="6" ry="6" width="16" height="8" />
                    <rect x="90%" y={y + 6} rx="6" ry="6" width="12" height="8" />
                </React.Fragment>
            );
        }

        y += 64;
    }
    return (
        <React.Fragment>
            {items}
        </React.Fragment>
    )
}