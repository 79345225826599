import { BaseService } from "mrb/core";
import { ProducerReportApiRouteService } from "common/services";

class ProducerReportService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new ProducerReportApiRouteService());
    }

    findProducerReportPayoutUserEvents(filter, abortSignal = null) {
        const url = this.routeService.findProducerReportPayoutUserEvents(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    findProducerReports(filter, abortSignal = null) {
        const url = this.routeService.findProducerReports(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getProducerPaymentSummary(producerId, options, abortSignal = null) {
        const url = this.routeService.getProducerPaymentSummary(producerId, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    findProducerReportItems(filter, abortSignal = null) {
        const url = this.routeService.findProducerReportItems(filter);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default ProducerReportService;
