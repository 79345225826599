import React, { useState } from "react";
import { mergeOrOverrideCss } from "mrb/common/utils";
import { Popover } from "react-tiny-popover";

const CustomDropdownTemplate = ({
    trigger,
    isDisabled,
    children,
    contentClassNameOverride,
    contentClassNameExtend,
    triggerClassNameOverride,
    triggerClassNameExtend,
    onOpen,
    onClose,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleIsOpen = () => {
        const value = !isOpen;
        setIsOpen(value);
        if (value && onOpen) {
            onOpen();
        } else if (!value && onClose) {
            onClose();
        }
    };

    const triggerClassName = mergeOrOverrideCss(
        "c-dropdown__trigger",
        triggerClassNameOverride,
        triggerClassNameExtend
    );
    const contentClassName = mergeOrOverrideCss(
        "c-dropdown__content",
        contentClassNameOverride,
        contentClassNameExtend
    );

    return (
        <Popover
            isOpen={isOpen}
            positions={["bottom", "top"]}
            align="start"
            padding={5}
            onClickOutside={() => handleIsOpen()}
            boundaryInset={15}
            containerClassName={contentClassName}
            content={children}
        >
            <div className={triggerClassName} onClick={!isDisabled ? handleIsOpen : null}>
                {trigger}
            </div>
        </Popover>
    );
};

export default CustomDropdownTemplate;
