import { BaseService } from "mrb/core";
import { UserTorahAnytimeCollectionApiRouteService } from "common/services";

class UserTorahAnytimeCollectionService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new UserTorahAnytimeCollectionApiRouteService());
    }

    findWthDeviceSettings(deviceId, filter, abortSignal = null) {
        const url = this.routeService.findWthDeviceSettings(deviceId, filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    updateCollectionsTurnedOnStatus(resources, value, abortSignal = null) {
        const url = this.routeService.updateCollectionsTurnedOnStatus(resources, value);
        return this.apiClient.put(url, resources, null, abortSignal);
    }
}

export default UserTorahAnytimeCollectionService;
