import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { StripeField } from "common/models";
import { logger } from "common/utils";
import { isNil, map, join } from "lodash";

class CustomerPaymentMethodInfoViewStore extends MrbBaseViewStore {
    @observable.ref stripe = null;
    @observable isCreditCardFormVisible = false;
    stripeField = null;

    @computed get isPaymentDisabled() {
        return isNil(this.stripe) || !this.stripeField.isCompleted;
    }

    @computed get creditCardExist() {
        return !isNil(this.rootStore.premiumSubscriptionStore.customerPaymentMethod);
    }

    @computed get customerPaymentMethod() {
        return this.rootStore.premiumSubscriptionStore.customerPaymentMethod;
    }

    constructor(rootStore) {
        super(rootStore);
        makeObservable(this);
        this.stripeField = new StripeField();

        this.reaction(
            () => this.isCreditCardFormVisible,
            (isVisible) => {
                if (!isVisible) {
                    this.stripeField.reset();
                }
            }
        );
    }

    @action.bound
    setStripe(stripe) {
        this.stripe = stripe;
    }

    @action.bound
    onFormSubmit(cardElements) {
        if (this.rootStore.premiumSubscriptionStore.invalidPaymentExist) {
            return this.submitPaymentMethod(cardElements);
        } else if (!this.creditCardExist) {
            this.addPaymentMethod(cardElements);
        } else {
            return this.createNewPaymentMethod(cardElements);
        }
    }

    @action.bound
    addPaymentMethod(cardElements) {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Add your credit card information?",
            description: "New credit card will be used for subscription payments.",
            yesLabel: "Add Card",
            onConfirm: async () => {
                this.stripeField.resetValidation();
                try {
                    const setupPaymentMethodResponse =
                        await this.rootStore.premiumSubscriptionStore.setupPaymentMethodRequest();
                    const payload = await this.stripe.confirmCardSetup(setupPaymentMethodResponse.token, {
                        payment_method: {
                            card: cardElements,
                        },
                    });
                    if (payload.error) {
                        this.stripeField.setError(payload.error);
                    } else {
                        await this.rootStore.premiumSubscriptionStore.updateDefaultPaymentMethod(
                            payload.setupIntent.payment_method
                        );
                        this.setIsCreditCardFormVisible(false); // close update payment method form
                    }
                } catch (err) {
                    logger.logError(err);
                    this.rootStore.notificationStore.error(
                        "An unexpected error occurred while trying to add credit card. Please contact the support team.",
                        err
                    );
                }
            },
        });
    }

    @action.bound
    createNewPaymentMethod(cardElements) {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Update your credit card information?",
            description: "New credit card will be used for subscription payments.",
            yesLabel: "Update Card",
            onConfirm: async () => {
                this.stripeField.resetValidation();
                try {
                    const setupPaymentMethodResponse =
                        await this.rootStore.premiumSubscriptionStore.setupPaymentMethodRequest();
                    const payload = await this.stripe.confirmCardSetup(setupPaymentMethodResponse.token, {
                        payment_method: {
                            card: cardElements,
                        },
                    });
                    if (payload.error) {
                        this.stripeField.setError(payload.error);
                    } else {
                        await this.rootStore.premiumSubscriptionStore.updateDefaultPaymentMethod(
                            payload.setupIntent.payment_method
                        );
                        this.setIsCreditCardFormVisible(false); // close update payment method form
                    }
                } catch (err) {
                    logger.logError(err);
                    this.rootStore.notificationStore.error(
                        "An unexpected error occurred while trying to update credit card information. Please contact the support team.",
                        err
                    );
                }
            },
        });
    }

    @action.bound
    submitPaymentMethod(cardElements) {
        const invalidPaymentSubscriptions = this.rootStore.premiumSubscriptionStore.invalidPaymentSubscriptions;
        const productNames = map(invalidPaymentSubscriptions, (subscription) => subscription.product.name);
        const description = `New credit card will be charged immediately to cover ${join(
            productNames,
            " and "
        )} past due ${productNames.length === 1 ? "subscription" : "subscriptions"}.`;
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Update your credit card information?",
            description: description,
            yesLabel: "Update Card",
            onConfirm: async () => {
                this.stripeField.resetValidation();
                try {
                    let paymentMethod = null;
                    for (let subscription of invalidPaymentSubscriptions) {
                        const payload = await this.stripe.confirmCardPayment(
                            subscription.latestInvalidPaymentTransaction.processorAuthorizationCode,
                            {
                                setup_future_usage: "off_session",
                                payment_method: !isNil(paymentMethod)
                                    ? paymentMethod
                                    : {
                                          card: cardElements,
                                      },
                            }
                        );
                        if (payload.error) {
                            this.stripeField.setError(payload.error);
                            break;
                        } else if (isNil(paymentMethod)) {
                            // setup new payment method only once, and use it afterward
                            paymentMethod = payload.paymentIntent.payment_method;
                        }
                    }
                    await this.rootStore.premiumSubscriptionStore.postprocessPaymentMethodUpdate(paymentMethod);
                    this.setIsCreditCardFormVisible(false); // close update payment method form
                } catch (err) {
                    logger.logError(err);
                    this.rootStore.notificationStore.error(
                        "An unexpected error occurred while trying to update credit card information. Please contact the support team.",
                        err
                    );
                }
            },
        });
    }

    @action.bound
    setIsCreditCardFormVisible(isCreditCardFormVisible) {
        this.isCreditCardFormVisible = isCreditCardFormVisible;
    }
}

export default CustomerPaymentMethodInfoViewStore;
