import React from "react";
import { PropTypes } from "prop-types";
import { MrbMenu } from "mrb/components/menu";
import { MrbNotifyOutsideClick } from "mrb/components/notify-outside-click";
import { defaultTemplate } from "common/hoc";
import logo from "themes/assets/images/logo-spa.svg";
import { observer } from "mobx-react";
import { ApplicationWidgetPanel, AdministrationWidgetPanel } from "common/components";
import { version } from "../../../../../package.json";

const APP_VERSION = `version ${version}`;

function MainSidebarTemplate({ mainSidebarViewStore, t }) {
    const { rootStore, logout, user } = mainSidebarViewStore;
    const { menuStore } = rootStore;

    return (
        <MrbNotifyOutsideClick action={menuStore.closeMenu}>
            <div
                className="c-nav__btn c-nav__btn--secondary"
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    menuStore.toggleMenuOpen();
                }}
            >
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div className="l__admin--aside">
                <div className="c-logo__wrapper">
                    <img src={logo} className="c-logo__image" alt="Naki Radio Logo" />
                </div>
                <div className="u-position--rel">
                    <MrbMenu menuStore={menuStore} t={t} />

                    <div className="c-nav__wrapper">
                        <div className="c-nav__item">
                            <a href="#javascript" className="c-nav__link" onClick={logout} label="Log Out">
                                <i className="c-nav__icon c-nav__icon--logout" />
                                Log Out
                            </a>
                        </div>

                        <UserInfo user={user} />

                        <div>
                            <ApplicationWidgetPanel rootStore={rootStore} t={t} />
                            <AdministrationWidgetPanel rootStore={rootStore} t={t} />
                        </div>

                        <p className="c-nav__version">{APP_VERSION}</p>

                        {/* Uncomment this to test session expired */}
                        {/* <button
                        onClick={() =>
                            rootStore.authenticationStore.setToken(null)
                        }
                        >
                        Clear Token
                    </button> */}
                    </div>
                </div>
            </div>
        </MrbNotifyOutsideClick>
    );
}

const UserInfo = observer(function UserInfo({ user }) {
    if (!user) {
        return null;
    }
    let displayName = null;
    if (user.userProfile) {
        displayName = `${user.userProfile.firstName}`;
    } else {
        displayName = user.userName;
    }

    return (
        <div className="c-avatar">
            <i className="c-nav__icon u-icon--profile"></i>
            <span className="c-avatar__title u-type--base">Hi, {displayName}</span>
        </div>
    );
});

MainSidebarTemplate.propTypes = {
    mainSidebarViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default defaultTemplate(MainSidebarTemplate);
