import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldLabel, MrbFieldValidation } from "./";
import { isSome, renderIf, mergeOrOverrideCss } from "../../../../common/utils";
import { observer } from "mobx-react";

function MrbFieldLayout({
    field,
    t = (i) => i,
    labelRender,
    validationRender,
    infoRenderer,
    infoLabelRenderer,
    showLabel = true,
    children,
    classNameOverride,
    classNameExtend,
}) {
    return (
        <div className={mergeOrOverrideCss("c-form__field", classNameOverride, classNameExtend)}>
            {showLabel && (
                <React.Fragment>
                    {labelRender ? (
                        labelRender(field, t)
                    ) : (
                        <MrbFieldLabel
                            field={field}
                            t={t}
                            infoRenderer={infoRenderer}
                            infoLabelRenderer={infoLabelRenderer}
                        />
                    )}
                </React.Fragment>
            )}
            {renderIf(isSome(field.errorMessage))(
                <React.Fragment>
                    {validationRender ? validationRender(field) : <MrbFieldValidation field={field} />}
                </React.Fragment>
            )}
            {children}
        </div>
    );
}

MrbFieldLayout.propTypes = {
    field: PropTypes.object.isRequired,
    t: PropTypes.func,
    labelRender: PropTypes.func,
    validationRender: PropTypes.func,
    infoRenderer: PropTypes.func,
    infoLabelRenderer: PropTypes.func,
    showLabel: PropTypes.bool,
    children: PropTypes.any,
    classNameOverride: PropTypes.string,
};

export default observer(MrbFieldLayout);
