import { action, observable, makeObservable } from "mobx";
import { localStorageProvider } from "mrb/common/storage";
import { isBoolean } from "lodash";

class UserDeviceSyncStore {
    @observable isDeviceSyncEnabled = false;
    _cacheKey = null;

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action.bound
    setIsDeviceSyncEnabled(value) {
        this.isDeviceSyncEnabled = value;
        localStorageProvider.add(this._cacheKey, value);
    }

    initialize() {
        this._cacheKey = `device-sync-u-${this.rootStore.userStore.user.id}`;
        this.syncDeviceSyncStatus();
    }

    @action.bound
    syncDeviceSyncStatus() {
        const isEnabled = localStorageProvider.get(this._cacheKey);
        if (isBoolean(isEnabled)) {
            this.isDeviceSyncEnabled = isEnabled;
        } else {
            this.isDeviceSyncEnabled = false;
        }
    }
}

export default UserDeviceSyncStore;
