import React from "react";
import { PropTypes } from "prop-types";
import { MrbCollapsiblePanelBody, MrbCollapsiblePanelHeader } from "./";
import { mergeOrOverrideCss } from "../../common/utils";
import { observer } from "mobx-react";

function MrbCollapsiblePanel({
    title,
    isCollapsed,
    t = (i) => i,
    headerRender,
    headerClassNameExtend,
    headerClassNameOverride,
    bodyClassNameExtend,
    bodyClassNameOverride,
    containerClassNameExtend,
    containerClassNameOverride,
    children,
    onChangeCollapse,
    innerRef,
}) {
    return (
        <div
            className={mergeOrOverrideCss("c-collapse-panel", containerClassNameOverride, containerClassNameExtend)}
            ref={innerRef}
        >
            <div className="animateFadeIn">
                <MrbCollapsiblePanelHeader
                    title={title}
                    headerRender={headerRender}
                    classNameOverride={headerClassNameOverride}
                    classNameExtend={headerClassNameExtend}
                    t={t}
                    isCollapsed={isCollapsed}
                    onChangeCollapse={onChangeCollapse}
                />
                {isCollapsed && (
                    <MrbCollapsiblePanelBody
                        isCollapsed={isCollapsed}
                        classNameOverride={bodyClassNameOverride}
                        classNameExtend={bodyClassNameExtend}
                    >
                        {children}
                    </MrbCollapsiblePanelBody>
                )}
            </div>
        </div>
    );
}

MrbCollapsiblePanel.propTypes = {
    title: PropTypes.string,
    isCollapsed: PropTypes.bool.isRequired,
    headerRender: PropTypes.func,
    headerClassNameExtend: PropTypes.string,
    headerClassNameOverride: PropTypes.string,
    bodyClassNameExtend: PropTypes.string,
    bodyClassNameOverride: PropTypes.string,
    containerClassNameExtend: PropTypes.string,
    containerClassNameOverride: PropTypes.string,
    children: PropTypes.any,
    onChangeCollapse: PropTypes.func.isRequired,
    innerRef: PropTypes.func,
};

export default observer(MrbCollapsiblePanel);
