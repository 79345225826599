import React from "react";
import { AdminProducerAccountEditTemplate } from "themes/administration/producer/components";
import { AdminProducerAccountEditViewStore } from "administration/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new AdminProducerAccountEditViewStore(rootStore, componentProps),
    "adminProducerAccountEditViewStore"
)
class AdminProducerAccountEdit extends React.Component {
    render() {
        return <AdminProducerAccountEditTemplate {...this.props} />;
    }
}

export default AdminProducerAccountEdit;
