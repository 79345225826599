import { RouterStore } from "mobx-state-router";
import { valueEqual } from "@react-force/utils";

class MrbRouter extends RouterStore {
    goToState(toState) {
        if (valueEqual(this.routerState, toState)) {
            return this.routerState;
        }
        return super.goToState(toState);
    }
}

export default MrbRouter;
