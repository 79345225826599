import React from "react";
import { UserAccountSummaryLoaderTemplate } from "themes/administration/analytics/components";

class UserAccountSummaryLoader extends React.Component {
    render() {
        return <UserAccountSummaryLoaderTemplate {...this.props} />;
    }
}

export default UserAccountSummaryLoader;
