import { makeObservable, observable, action } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { AdminProducerAccountStripeAccountEditForm } from "administration/producer/forms";
import { isFunction } from "lodash";
import { MrbFieldSelectStore } from "mrb/components/select";
import { isNil } from "lodash";

class AdminProducerAccountStripeAccountEditViewStore extends MrbBaseViewStore {
    onSubmit = null;
    @observable.ref producerUserAccount = null;
    @observable.ref stripeAccountSelectStore;

    constructor(rootStore, { producerUserAccount, onSubmit, routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.onSubmit = onSubmit;
        this.routeStore = routeStore;

        this.setForm(
            new AdminProducerAccountStripeAccountEditForm({
                onSuccess: (form) => {
                    const item = form.values();
                    return this.createResource(item);
                },
            })
        );

        this.setProducerUserAccount(producerUserAccount);

        this.createResource = this.createResource.bind(this);
    }

    createResource(resource) {
        if (isFunction(this.onSubmit)) {
            this.onSubmit(resource);
        }
    }

    @action.bound
    setProducerUserAccount(producerUserAccount) {
        this.producerUserAccount = producerUserAccount;
        if (!isNil(producerUserAccount.stripeAccountId)) {
            this.form.$("stripeAccountId").set(producerUserAccount.stripeAccountId);
        }
    }

    @action.bound
    setForm(form) {
        this.form = form;

        this.stripeAccountSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: false,
                virtualized: false,
                textKey: "email",
                actions: {
                    find: () => {
                        return this.routeStore.getStripeConnectAccounts();
                    },
                },
            },
            this.form.$("stripeAccountId")
        );
    }
}

export default AdminProducerAccountStripeAccountEditViewStore;
