import React from "react";
import { Image } from "common/components";
import { numberFormatter } from "common/utils";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { isNil } from "lodash";

function PodcastListenersCellTemplate({ item }) {
    return (
        <div className="u-animation--fadeIn c-analytics__list__item c-analytics__list__item--secondary">
            <div className="c-analytics__list__item--position">
                <span className="u-type--med">{item.position}.</span>
            </div>
            <div className="c-analytics__list__img">
                <Image src={item.coverImageUrl} />
            </div>
            <div className="c-analytics__list__information">
                {isNil(item.podcastGroupId) ? (
                    <div className="u-display--flex u-display--flex--ai--center">
                        <MrbRouterLink
                            className="c-analytics__list__title c-analytics__list__title--sml"
                            routeName="master.administration.podcast.preview"
                            params={{ id: item.id }}
                            title={item.title}
                        >
                            {item.title}
                        </MrbRouterLink>
                        {item.isPremium ? <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny" /> : null}
                    </div>
                ) : (
                    <div>
                        <div className="u-display--flex u-display--flex--ai--center">
                            <MrbRouterLink
                                className="c-analytics__list__title c-analytics__list__title--sml"
                                routeName="master.administration.podcast.preview-group"
                                params={{ id: item.podcastGroupId }}
                                queryParams={{ subPodcastId: item.id }}
                                title={item.title}
                            >
                                {item.title}
                            </MrbRouterLink>
                            {item.isPremium ? (
                                <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny" />
                            ) : null}
                        </div>
                        <div className="u-type--sml u-type--color--opaque">{item.podcastGroupTitle}</div>
                    </div>
                )}
            </div>

            <div className="c-analytics__list__item--score">{numberFormatter.format(item.total)}</div>
        </div>
    );
}

export default PodcastListenersCellTemplate;
