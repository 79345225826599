import { BaseApiRouteService } from "mrb/core";

class ProducerApiRouteService extends BaseApiRouteService {
    constructor() {
        super("lookups/producers");
    }

    find(filter) {
        return super.find(`${this.base}/{?searchQuery}`, filter);
    }

    get(id) {
        return super.get(`${this.base}/{id}`, id);
    }

    create(resource) {
        return super.create(this.base, resource);
    }

    update(resource) {
        return super.update(`${this.base}/{id}`, resource);
    }
}

export default ProducerApiRouteService;
