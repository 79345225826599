import { observe, reaction } from "mobx";
import { each, isFunction } from "lodash";
import { MrbContentLoaderStore } from "../../../components/loader";

class MrbBaseViewStore {
    observeDisposers = [];
    eventDisposers = [];
    reactDisposers = [];

    loaderStore = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.loaderStore = new MrbContentLoaderStore();
    }

    onInit() {
        return Promise.resolve();
    }

    async initialize() {
        this.loaderStore.suspend();
        await this.onInit();
        this.loaderStore.resume();
    }

    observe(target, propertyName = null, listener) {
        const disposer = observe(target, propertyName, listener);
        this.observeDisposers.push(disposer);
    }

    reaction(target, listener, options = {}) {
        const disposer = reaction(target, listener, options);
        this.reactDisposers.push(disposer);
        return disposer;
    }

    addEvent(eventName, func) {
        const disposer = this.rootStore.eventHandler.addEvent(eventName, func);
        this.eventDisposers.push(disposer);
        return disposer;
    }

    onDestroy() {}

    destroy() {
        this.loaderStore.destroy();
        this.onDestroy();
        each(
            this.observeDisposers,
            (disposer) => isFunction(disposer) && disposer()
        );
        each(
            this.eventDisposers,
            (disposer) => isFunction(disposer) && disposer()
        );
        each(
            this.reactDisposers,
            (disposer) => isFunction(disposer) && disposer()
        );
    }
}

export default MrbBaseViewStore;
