import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";

function MrbProgressBarLoader({ loaderStore }) {
    const isLoading = loaderStore.loading;
    return (
        <React.Fragment>
            {isLoading && (
                <div className="c-bar-loader">
                    <div className="c-bar-loader__progress"></div>
                </div>
            )}
        </React.Fragment>
    );
}

MrbProgressBarLoader.propTypes = {
    loaderStore: PropTypes.object.isRequired,
};

export default observer(MrbProgressBarLoader);
