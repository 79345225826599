import { MrbBaseRouteStore } from "mrb/core";
import { StationFileStreamService, UserContentService } from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";

class HomeStationRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.stationFileStreamService = this.rootStore.createApplicationService(StationFileStreamService);
        this.userContentService = this.rootStore.createApplicationService(UserContentService);
    }

    async getStationWthDeviceSettings(stationId, deviceId) {
        const response = await this.userContentService.getStationWthDeviceSettings(stationId, deviceId, {
            embed: "location,language",
        });
        return response.data;
    }

    async getStation(stationId) {
        const response = await this.userContentService.getStation(stationId, {
            embed: "location,language",
        });
        return response.data;
    }

    createStationCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.stationFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }

    async getUserDeviceStation(deviceId, stationId) {
        const response = await this.userContentService.getUserDeviceStation(deviceId, stationId);
        return response.data;
    }
}

export default HomeStationRouteStore;
