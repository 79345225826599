import { menuProvider, routeProvider, storeProvider } from "../providers";
import { filter, isFunction, map, forEach, some } from "lodash";

class ModuleBuilder {
    buildRoutes = (modulesRouteConfig, context = {}, modules = null) => {
        if (modules && modules.length > 0) {
            modulesRouteConfig = filter(modulesRouteConfig, (moduleRoute) =>
                some(modules, (moduleName) => moduleName === moduleRoute.module)
            );
        }
        let routes = [];
        forEach(modulesRouteConfig, (moduleRoute) => {
            routes = [...routes, ...moduleRoute.items];
        });
        const contextRoutes = buildConfiguration(routes, context);
        return routeProvider.initialize(contextRoutes, context);
    };

    buildMenus = (modulesMenuConfig, context = {}, modules = null) => {
        if (modules && modules.length > 0) {
            modulesMenuConfig = filter(modulesMenuConfig, (moduleMenu) =>
                some(modules, (moduleName) => moduleName === moduleMenu.module)
            );
        }
        let menus = [];
        forEach(modulesMenuConfig, (moduleMenu) => {
            menus = [...menus, ...moduleMenu.items];
        });
        const contextMenus = buildConfiguration(menus, context);
        return menuProvider.initialize(contextMenus, context);
    };

    buildStores = (modulesStoreConfig, context = {}, modules = null) => {
        if (modules && modules.length > 0) {
            modulesStoreConfig = filter(modulesStoreConfig, (moduleStore) =>
                some(modules, (moduleName) => moduleName === moduleStore.module)
            );
        }
        let stores = [];
        forEach(modulesStoreConfig, (moduleStore) => {
            stores = [...stores, ...moduleStore.items];
        });
        const contextStores = buildConfiguration(stores, context);
        return storeProvider.initialize(contextStores, context);
    };
}

function buildConfiguration(items, ctx) {
    return map(items, (item) => (isFunction(item) ? item(ctx) : item));
}

const moduleBuilder = new ModuleBuilder();
export default moduleBuilder;
