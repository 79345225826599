import React from "react";
import { UserSubscriptionInfoTemplate } from "themes/application/payment/components";

class UserSubscriptionInfo extends React.Component {
    render() {
        return <UserSubscriptionInfoTemplate {...this.props} />;
    }
}

export default UserSubscriptionInfo;
