import { observable, makeObservable } from "mobx";
import { AnalyticsFilter } from "administration/analytics/filters";

class UserDeviceActivityFilter extends AnalyticsFilter {
    @observable deviceIds = null;

    constructor() {
        super();
        makeObservable(this);
        this.reset();
    }
}

export default UserDeviceActivityFilter;
