import React from "react";
import { PropTypes } from "prop-types";

function UserEventPayoutCellTemplate({ data }) {
    if (data.isAllowedForPayout) {
        return <i className="u-icon u-icon--med u-icon--payout" />;
    }
    return "-";
}

UserEventPayoutCellTemplate.propTypes = {
    data: PropTypes.object.isRequired,
};

export default UserEventPayoutCellTemplate;
