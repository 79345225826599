import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";

function PremiumInfoWidgetTemplate({ t }) {
    return (
        <div className="c-premium__info jq-onboarding-premium">
            <div className="c-premium__header">
                <i className="u-icon u-icon--med u-icon--premium u-mar--right--micro"></i>
                <p className="u-type--base u-type--wgt--bold">{t("APPLICATION.PREMIUM.INFO_WIDGET.TITLE")}</p>
            </div>

            <p className="u-type--sml u-mar--bottom--sml">{t("APPLICATION.PREMIUM.INFO_WIDGET.DESCRIPTION")}</p>

            <MrbRouterLink
                className="c-btn c-btn--sml c-btn--full c-btn--primary"
                routeName="master.application.premium.subscriptions"
            >
                {t("APPLICATION.PREMIUM.INFO_WIDGET.ACTION")}
            </MrbRouterLink>
        </div>
    );
}

PremiumInfoWidgetTemplate.propTypes = {
    t: PropTypes.func.isRequired,
};

export default PremiumInfoWidgetTemplate;
