import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldNumericInput, MrbFieldSelect, MrbButton } from "mrb/components";
import { currencyFormatter } from "mrb/common/formatting";
import { StripeAccountFieldSelect } from "common/components/stripe";
import { components } from "react-select";
import { observer } from "mobx-react";

function AdminProducerAccountEditTemplate({ adminProducerAccountEditViewStore, t }) {
    const { userAccountSelectStore, stripeAccountSelectStore, removeAccount, form } = adminProducerAccountEditViewStore;
    return (
        <div className="c-card--primary u-mar--bottom--med">
            <div className="row row--align-end">
                <div className="col col-sml-12 col-lrg-6 col-xlrg-4 u-mar--bottom--sml">
                    <MrbFieldSelect
                        t={t}
                        store={userAccountSelectStore}
                        classNameExtend="c-dropdown--dark"
                        fieldClassNameOverride=" "
                        components={{ Option: UserOption, SingleValue: UserSelectedValue }}
                    />
                </div>
                <div className="col col-sml-12 col-lrg-6 col-xlrg-4 u-mar--bottom--sml">
                    <StripeAccountFieldSelect
                        t={t}
                        store={stripeAccountSelectStore}
                        fieldClassNameOverride=" "
                        classNameExtend="c-dropdown--dark"
                    />
                </div>
                <div className="col col-sml-12 col-lrg-6 col-xlrg-4 u-mar--bottom--sml">
                    <div className="u-display--flex u-display--flex--ai--flex-end u-display--flex--jc--space-between u-gap--lrg">
                        <MrbFieldNumericInput
                            classNameOverride="c-input c-input--outline c-input--numeric c-input--numeric--base"
                            fieldClassNameOverride=" "
                            precision={0}
                            t={t}
                            field={form.$("revenuePercentage")}
                        />

                        <div>
                            <MrbButton label="Remove" onClick={removeAccount} classNameExtend="c-btn--med" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="u-separator--primary u-separator--primary--base u-mar--top--sml"></div>

            <AccountBalance adminProducerAccountEditViewStore={adminProducerAccountEditViewStore} t={t} />
        </div>
    );
}

AdminProducerAccountEditTemplate.propTypes = {
    adminProducerAccountEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function UserOption(props) {
    const { item } = props.data;

    return (
        <components.Option {...props}>
            <UserLabel item={item} />
        </components.Option>
    );
}

function UserLabel({ item }) {
    return (
        <React.Fragment>
            {item.firstName} {item.lastName} - {item.email}
        </React.Fragment>
    );
}

function UserSelectedValue(props) {
    const { item } = props.data;

    return (
        <components.SingleValue {...props}>
            <UserLabel item={item} />
        </components.SingleValue>
    );
}

const AccountBalance = observer(function AccountBalance({ adminProducerAccountEditViewStore, t }) {
    const { currentAccountBalance } = adminProducerAccountEditViewStore;
    return (
        <div className="row">
            <div className="col col-sml-12 col-lrg-4 col-xlrg-2">
                <div className="c-form__field">
                    <p className="c-form__field__label">
                        {t("ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.ACCOUNT_BALANCE_LABEL")}
                    </p>
                    <div className="u-type--color--success">
                        {currencyFormatter.format(currentAccountBalance, {
                            currency: "USD",
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default observer(AdminProducerAccountEditTemplate);
