import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldLayout } from "../layout/content/form";
import { observer } from "mobx-react";

function MrbFieldTextArea({
    field,
    rows = 3,
    t = (i) => i,
    showLabel = true,
    labelRender = null,
    validationRender = null,
    classNameExtend = ""
}) {
    const { placeholder, ...otherProps } = field.bind();

    if (otherProps.value) {
        otherProps.value = otherProps.value.replace(/\s+/g, " ");
    } else {
        otherProps.value = "";
    }

    return (
        <MrbFieldLayout
            field={field}
            t={t}
            labelRender={labelRender}
            validationRender={validationRender}
            showLabel={showLabel}
        >
            <textarea
                className={`c-input c-input--med c-input--textarea ${classNameExtend}`}
                {...otherProps}
                placeholder={t(placeholder)}
                rows={rows}
            />
        </MrbFieldLayout>
    );
}

MrbFieldTextArea.propTypes = {
    field: PropTypes.object.isRequired,
    rows: PropTypes.number,
    showLabel: PropTypes.bool,
    labelRender: PropTypes.func,
    validationRender: PropTypes.func,
    t: PropTypes.func,
};

export default observer(MrbFieldTextArea);
