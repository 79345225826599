import { BaseApiRouteService } from "mrb/core";

class DayHourApiRouteService extends BaseApiRouteService {
    constructor() {
        super("lookups/day-hours");
    }

    get() {
        return super.get(this.base);
    }
}

export default DayHourApiRouteService;
