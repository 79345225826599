import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";

function StripeFieldValidationTemplate({ field }) {
    const { error } = field;
    if (!error) {
        return null;
    }

    return (
        <p className="c-validation--input">
            <span className="c-validation--input__label">{error.message}</span>
        </p>
    );
}

StripeFieldValidationTemplate.propTypes = {
    field: PropTypes.object.isRequired,
};

export default observer(StripeFieldValidationTemplate);
