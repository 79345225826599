import { PropTypes } from "prop-types";
import { producerPlaybackTimeConverter } from "common/utils";

function ProducerPlaybackTimeCellTemplate({ data }) {
    const { currentMonthReportVersion, currentMonthTotalProducerPlaybacks } = data;
    const value = producerPlaybackTimeConverter(currentMonthReportVersion, currentMonthTotalProducerPlaybacks);
    if (value) {
        return value;
    }

    return "-";
}

ProducerPlaybackTimeCellTemplate.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ProducerPlaybackTimeCellTemplate;
