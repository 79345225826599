import React from "react";
import { RootContext } from "../contexts";

const withRootStore = (Component) =>
    class WithRootStore extends React.Component {
        render() {
            return (
                <RootContext.Consumer>
                    {(rootStore) => (
                        <Component rootStore={rootStore} {...this.props} />
                    )}
                </RootContext.Consumer>
            );
        }
    };

export default withRootStore;
