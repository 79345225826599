import React from "react";
import { PropTypes } from "prop-types";
import { MrbModal } from "mrb/components/modal";
import { MrbButton } from "mrb/components/button";

function ConnectDeviceModalTemplate({
    title = "APPLICATION.HOME.CONNECT_DEVICE.TITLE",
    description = "APPLICATION.HOME.CONNECT_DEVICE.DESCRIPTION",
    modalParams,
    t,
}) {
    const { params } = modalParams;
    return (
        <MrbModal modalParams={modalParams} t={t} shouldCloseOnOverlayClick={false}>
            <div>
                <div className="modal__icon">
                    <i className="u-icon u-icon--radio-lrg"></i>
                </div>
                <div className="modal__header">
                    <h2>{t(title)}</h2>
                </div>
                <div className="modal__body">
                    <p>{t(description)}</p>
                </div>
                <div className="modal__footer modal__footer--column">
                    <MrbButton
                        t={t}
                        label="APPLICATION.HOME.CONNECT_DEVICE.CONNECT_BUTTON"
                        onClick={params.goToConnectDevice}
                    />
                    <p className="modal__splitter">OR</p>
                    <MrbButton
                        t={t}
                        classNameOverride="c-btn c-btn--base c-btn--anchor c-btn--anchor--underline"
                        label="Explore Content"
                        onClick={modalParams.close}
                    />
                </div>
            </div>
        </MrbModal>
    );
}

ConnectDeviceModalTemplate.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    modalParams: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default ConnectDeviceModalTemplate;
