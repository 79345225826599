import { observable, override, computed, makeObservable } from "mobx";
import { MrbLoaderStore } from "./";
import { extend } from "lodash";

const DefaultOptions = {
    delay: 10,
    suspendRenderOnInit: true,
};

class MrbContentLoaderStore extends MrbLoaderStore {
    @observable _initial = true;

    @computed get suspendRendering() {
        return this._initial && this.options.suspendRenderOnInit;
    }

    constructor(options) {
        super(extend({}, DefaultOptions, options));
        makeObservable(this);
    }

    @override
    resume() {
        this._initial = false;
        super.resume();
    }
}

export default MrbContentLoaderStore;
