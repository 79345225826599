class UpdateNakiNigunArtistStatusOptions {
    artistId = null;
    name = null;
    isPremium = false;
    isUnavailableInApp = false;

    constructor(artistId, name, isPremium, isUnavailableInApp) {
        this.artistId = artistId;
        this.name = name;
        this.isPremium = isPremium;
        this.isUnavailableInApp = isUnavailableInApp;
    }
}

export default UpdateNakiNigunArtistStatusOptions;
