import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { MrbDatePicker } from "mrb/components/date-picker";
import { MrbButton } from "mrb/components/button";
import { observer } from "mobx-react";

function ProducerReportLayoutTemplate({ producerReportViewStore, backButtonRenderer, children, t }) {
    return (
        <MrbPage>
            <MrbPageHeader>
                <Header
                    producerReportViewStore={producerReportViewStore}
                    backButtonRenderer={backButtonRenderer}
                    t={t}
                />
            </MrbPageHeader>

            {children}
        </MrbPage>
    );
}

ProducerReportLayoutTemplate.propTypes = {
    producerReportViewStore: PropTypes.object.isRequired,
    backButtonRenderer: PropTypes.any,
    children: PropTypes.any,
};

const Header = observer(function Header({ producerReportViewStore, backButtonRenderer, t }) {
    const { summaryData, isDateRangeDisabled, queryUtility, setFromDate, applyFilter, selectedDate } =
        producerReportViewStore;
    return (
        <React.Fragment>
            {backButtonRenderer && (
                <React.Fragment>{summaryData ? backButtonRenderer({ summaryData }) : <div></div>}</React.Fragment>
            )}
            <div className="c-header__actions">
                <div className="u-display--flex u-display--flex--ai--center">
                    <MrbDatePicker
                        value={selectedDate}
                        isClearable={false}
                        disabled={isDateRangeDisabled}
                        timeZone={queryUtility.filter.timeZone}
                        dateFormat="MMM, yyyy"
                        onChange={setFromDate}
                        showMonthYearPicker={true}
                        todayButtonLabel={null}
                    />

                    <MrbButton
                        t={t}
                        className="c-btn c-btn--base c-btn--primary u-mar--left--sml"
                        onClick={applyFilter}
                        label="Apply"
                        disabled={isDateRangeDisabled}
                    />
                </div>
            </div>
        </React.Fragment>
    );
});

export default observer(ProducerReportLayoutTemplate);
