import { computed, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { MrbModalParams } from "mrb/common/models";

class ContentPolicyWidgetViewStore extends MrbBaseViewStore {
    @computed get isContentPolicyVisible() {
        return this.rootStore.userStore.isContentPolicyVisible;
    }

    constructor(rootStore) {
        super(rootStore);
        makeObservable(this);

        this.disableContentPolicy = this.disableContentPolicy.bind(this);
        this.displayContentPolicy = this.displayContentPolicy.bind(this);

        this.contentPolicyModal = new MrbModalParams(null, {
            disableContentPolicy: this.disableContentPolicy,
        });
    }

    displayContentPolicy() {
        this.contentPolicyModal.open();
        this.rootStore.menuStore.closeMenu();
    }

    async disableContentPolicy() {
        try {
            this.contentPolicyModal.close();
            this.rootStore.mainLoaderStore.suspend();
            await this.rootStore.userStore.disableContentPolicy();
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to update user settings.",
                err
            );
        } finally {
            this.rootStore.mainLoaderStore.resume();
        }
    }
}

export default ContentPolicyWidgetViewStore;
