import React from "react";
import { ContentPolicyWidgetTemplate } from "themes/application/common/components";
import { ContentPolicyWidgetViewStore } from "application/common/components/content-policy";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new ContentPolicyWidgetViewStore(rootStore, componentProps),
    "contentPolicyWidgetViewStore"
)
class ContentPolicyWidget extends React.Component {
    render() {
        return <ContentPolicyWidgetTemplate {...this.props} />;
    }
}

export default ContentPolicyWidget;
