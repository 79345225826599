import { BaseApiRouteService } from "mrb/core";

class StationFileApiRouteService extends BaseApiRouteService {
    constructor() {
        super("station-files");
    }

    deleteByStation(stationId) {
        return super.delete(`${this.base}/unlink-by-station/{id}`, {
            id: stationId,
        });
    }
}

export default StationFileApiRouteService;
