import React from "react";
import { PropTypes } from "prop-types";
import { PremiumInfoWidget, PastDueSubscriptionWidget, PremiumInfoTrialWidget } from "application/payment/components";
import { ContentPolicyWidget } from "application/common/components";
import { head } from "lodash";
import { observer } from "mobx-react";

function ApplicationWidgetPanelTemplate({ rootStore, isContentPolicyVisible = true, t }) {
    const { userStore, premiumSubscriptionStore } = rootStore;
    if (userStore.isAdministrator) {
        return null;
    }

    return (
        <React.Fragment>
            {!premiumSubscriptionStore.subscriptionExist && <PremiumInfoWidget t={t} />}
            <SubscriptionInfoWidgets premiumSubscriptionStore={premiumSubscriptionStore} t={t} />

            {isContentPolicyVisible && <ContentPolicyWidget t={t} />}
        </React.Fragment>
    );
}

ApplicationWidgetPanelTemplate.propTypes = {
    rootStore: PropTypes.object.isRequired,
    isContentPolicyVisible: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

const SubscriptionInfoWidgets = observer(function SubscriptionInfoWidgets({ premiumSubscriptionStore, t }) {
    const pastDueSubscriptions = premiumSubscriptionStore.premiumPastDueSubscriptions;
    if (pastDueSubscriptions && pastDueSubscriptions.length > 0) {
        return <PastDueSubscriptionWidget subscriptions={pastDueSubscriptions} t={t} />;
    }
    const trialSubscriptions = premiumSubscriptionStore.premiumTrialSubscriptions;
    if (trialSubscriptions && trialSubscriptions.length > 0) {
        return <PremiumInfoTrialWidget subscription={head(trialSubscriptions)} t={t} />;
    }

    return null;
});

export default observer(ApplicationWidgetPanelTemplate);
