import { MrbBaseFormatter } from "./";
import { mrbDefaultFormats } from "./";

class BoolFormatter extends MrbBaseFormatter {
    constructor() {
        super(mrbDefaultFormats.bool);
    }

    format(isTrue, { trueLabel, falseLabel }) {
        return isTrue ? trueLabel : falseLabel;
    }
}

const boolFormatter = new BoolFormatter();
export default boolFormatter;
