import React from "react";
import { PropTypes } from "prop-types";
import { applicationErrorCodes } from "common/constants";

function InvalidPaymentInfoTemplate({ subscription, paymentError, t }) {
    let message = null;
    if (paymentError) {
        if (paymentError.errorCode === applicationErrorCodes.payment.paymentFailedExpiredCreditCard) {
            message = (
                <span>
                    Your credit card has expired. In order to continue using {subscription.product.name} subscription,
                    provide new credit card information.
                </span>
            );
        } else if (paymentError.errorCode === applicationErrorCodes.payment.paymentFailedInvalidCreditCard) {
            message = (
                <span>
                    Your credit card is invalid. In order to continue using {subscription.product.name} subscription,
                    provide new credit card information.
                </span>
            );
        } else {
            message = (
                <span>
                    We are unable to process the credit card payment. In order to continue using{" "}
                    <strong>{subscription.product.name}</strong> subscription, provide new credit card, or contact the
                    support team.
                </span>
            );
        }
    }

    if (!message) {
        return null;
    }

    return <div className="c-card c-card--message c-card--message--cc-sync u-mar--bottom--med">{message}</div>;
}

InvalidPaymentInfoTemplate.propTypes = {
    subscription: PropTypes.object.isRequired,
    paymentError: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default InvalidPaymentInfoTemplate;
