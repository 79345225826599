import React from "react";
import PropTypes from "prop-types";
import { MrbPage, MrbPageHeader } from "./";
import { MrbListContent } from "../content";
import { MrbBreadcrumbs } from "../../breadcrumbs";
import { getPageObject, renderActions } from "../utils";

function MrbListPage({ store, children, t = (i) => i, ...pageProps }) {
    const { layoutActions, navigationOptions, title } = store;
    const { header, footer, content } = getPageObject(children);

    let layoutActionsLength = Object.keys(layoutActions).length;

    return (
        <MrbPage title={title} t={t} loaderStore={null} {...pageProps}>
            {header ? (
                header
            ) : (
                <MrbPageHeader>
                    <div className="c-page__header--top">
                        <div>{t(title)}</div>
                        <div
                            className={`${
                                layoutActionsLength <= 0
                                    ? ""
                                    : "c-header__actions"
                            } ${
                                layoutActionsLength < 2 &&
                                layoutActionsLength > 0
                                    ? "c-header__actions--end"
                                    : ""
                            }`}
                        >
                            {renderActions(layoutActions, t)}
                        </div>
                    </div>
                    <div className="c-page__header--bottom">
                        <MrbBreadcrumbs options={navigationOptions} t={t} />
                    </div>
                </MrbPageHeader>
            )}
            {content.header}
            {content.sidebar}
            <MrbListContent>{content.children}</MrbListContent>
            {content.footer}
            {footer}
        </MrbPage>
    );
}
MrbListPage.propTypes = {
    title: PropTypes.string,
    store: PropTypes.object.isRequired,
    children: PropTypes.any,
    t: PropTypes.func,
};

export default MrbListPage;
