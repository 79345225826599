import React from "react";
import { PropTypes } from "prop-types";
import { withAuthorization } from "../../core/hoc";
import { mergeOrOverrideCss } from "../../common/utils";
import { observer } from "mobx-react";

const iconName = (name) => (name ? "" + name : "");

const MrbButton = function ({ onClick, authorization, ...otherProps }) {
    return (
        <AuthContent
            authorization={authorization}
            onAction={onClick}
            {...otherProps}
        />
    );
};

MrbButton.propTypes = {
    onClick: PropTypes.func,
    authorization: PropTypes.any,
};

const AuthContent = withAuthorization(DefaultContent);

function DefaultContent({
    onlyIcon = false,
    disabled = false,
    content = null,
    type = "button",
    classNameOverride,
    classNameExtend,
    onAction,
    label,
    icon,
    t = (i) => i,
    ...other
}) {
    const displayLabel = t(label);
    return (
        <button
            type={type}
            disabled={disabled}
            onClick={onAction}
            className={mergeOrOverrideCss(
                "c-btn c-btn--base c-btn--primary",
                classNameOverride,
                classNameExtend
            )}
            title={displayLabel}
            aria-label={displayLabel}
            {...other}
        >
            {content || (
                <span className="u-display--flex u-display--flex--ai--center">
                    <span className={`${iconName(icon)}`} />
                    {!onlyIcon && displayLabel}
                </span>
            )}
        </button>
    );
}

DefaultContent.propTypes = {
    onlyIcon: PropTypes.bool,
    disabled: PropTypes.bool,
    content: PropTypes.any,
    type: PropTypes.string,
    classNameOverride: PropTypes.string,
    classNameExtend: PropTypes.string,
    onAction: PropTypes.func,
    label: PropTypes.string,
    icon: PropTypes.string,
    other: PropTypes.any,
};

export default observer(MrbButton);
