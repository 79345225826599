import React from "react";
import { PropTypes } from "prop-types";
import { Doughnut } from "react-chartjs-2";
import { MrbContentLoader } from "mrb/components/loader";
import { observer } from "mobx-react";

function createChartjsParams(data, labels, config) {
    return {
        labels: labels,
        datasets: [
            {
                label: config.label,
                data: data,
                fill: config.fill,
                backgroundColor: config.backgroundColor,
                borderColor: config.borderColor,
                borderWidth: config.borderWidth,
            },
        ],
    };
}

function DoughnutChartTemplate({ store, loaderRenderer = null, emptyStateRenderer = null }) {
    const { data, labels, config, loaderStore } = store;
    return (
        <MrbContentLoader loaderStore={loaderStore} loaderRender={loaderRenderer}>
            {() => (
                <div className="u-animation--fadeIn">
                    {data.length > 0 ? (
                        <div className="c-chart--doughnut">
                            <Doughnut data={() => createChartjsParams(data, labels, config)} options={config.options} />
                        </div>
                    ) : (
                        emptyStateRenderer()
                    )}
                </div>
            )}
        </MrbContentLoader>
    );
}

DoughnutChartTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    loaderRenderer: PropTypes.func,
    emptyStateRenderer: PropTypes.func,
};

export default observer(DoughnutChartTemplate);
