import React from "react";
import PropTypes from "prop-types";
import { MrbButton } from "mrb/components/button";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { isFunction } from "lodash";
import { observer } from "mobx-react";

function TakePaymentHeaderTemplate({ onClickCancel, t = (i) => i, cancelLabel }) {
    return (
        <div className="c-page__header--top">
            <MrbRouterLink routeName="master.application.premium.subscriptions">
                <span className="display--ib u-mar--right--tny align--v--middle">
                    <i className="u-icon u-icon--med u-icon--back" />
                </span>
                Subscriptions
            </MrbRouterLink>
            <div className="c-header__actions">
                <EditPageActions onClickCancel={onClickCancel} t={t} cancelLabel={cancelLabel} />
            </div>
        </div>
    );
}

TakePaymentHeaderTemplate.propTypes = {
    onClickCancel: PropTypes.func,
    t: PropTypes.func,
    cancelLabel: PropTypes.string,
};

const EditPageActions = observer(function EditPageActions({ onClickCancel, cancelLabel = "Cancel", t }) {
    return (
        <React.Fragment>
            {onClickCancel && isFunction(onClickCancel) && (
                <MrbButton
                    t={t}
                    className="c-btn c-btn--base c-btn--ghost"
                    label={cancelLabel}
                    onClick={onClickCancel}
                />
            )}{" "}
        </React.Fragment>
    );
});

EditPageActions.propTypes = {
    onClickCancel: PropTypes.func,
    cancelLabel: PropTypes.string,
    t: PropTypes.func,
};

export default observer(TakePaymentHeaderTemplate);
