import { MrbBaseRouteStore } from "mrb/core";
import { DeviceAnalyticsService, DeviceService, PortalAnalyticsService } from "common/services";

class UserDeviceActivityRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(DeviceAnalyticsService);
        this.deviceService = this.rootStore.createApplicationService(DeviceService);
        this.portalAnalyticsService = this.rootStore.createApplicationService(PortalAnalyticsService);
    }

    async getUserDeviceActivitySummary(userId) {
        const response = await this.service.getUserActivitySummary(userId);
        return response.data;
    }

    async getUserPortalStatsSummary(userId) {
        const response = await this.portalAnalyticsService.getUserPortalStatsSummary(userId);
        return response.data;
    }

    async getUserDeviceActivity(filter) {
        const response = await this.service.getUserDeviceActivity(filter);
        return response.data;
    }

    async findUserDevices(filter) {
        const response = await this.deviceService.findUserDevice(filter);
        return response.data;
    }
}

export default UserDeviceActivityRouteStore;
