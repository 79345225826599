import { join, filter, isEmpty } from "lodash";

function mergeCss(...args) {
    return join(
        filter(args, (x) => !isEmpty(x)),
        " "
    );
}

export default mergeCss;
