import { BaseApiRouteService } from "mrb/core";

class GenreApiRouteService extends BaseApiRouteService {
    constructor() {
        super("genre");
    }

    getGenreContent(id, filter) {
        return super.get(`${this.base}/content/{id}{?page,rpp,sort}`, id, filter);
    }
}

export default GenreApiRouteService;
