import React from "react";
import { observer } from "mobx-react";
import { PremiumContentListTemplate } from "themes/application/payment/components";
import { PremiumContentListViewStore } from "application/payment/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new PremiumContentListViewStore(rootStore, componentProps),
    "premiumContentListViewStore"
)
@observer
class PremiumContentList extends React.Component {
    render() {
        return <PremiumContentListTemplate {...this.props} />;
    }
}

export default PremiumContentList;
