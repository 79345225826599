import React from "react";
import PropTypes from "prop-types";

function HTMLRenderTemplate({ children, className = null }) {
    if (!children) {
        return null;
    }

    return (
        <div
            className={className}
            dangerouslySetInnerHTML={{ __html: children }}
        ></div>
    );
}

HTMLRenderTemplate.propTypes = {
    children: PropTypes.string,
    className: PropTypes.string,
};

export default HTMLRenderTemplate;
