import { BaseService } from "mrb/core";
import { UserPodcastExpirationApiRouteService } from "common/services";

class UserPodcastExpirationService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new UserPodcastExpirationApiRouteService());
    }
}

export default UserPodcastExpirationService;
