import React from "react";
import ContentLoader from "react-content-loader";

const CoverImageUploadLoaderTemplate = (props) => (
    <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        viewBox="0 0 380 440"
        backgroundColor="#262729"
        foregroundColor="#2b2b2b"
        {...props}
    >
        <rect x="0" y="0" rx="6" ry="6" width="380" height="85%" />
        <rect x="0" y="89%" rx="6" ry="6" width="380" height="8%" />
    </ContentLoader>
);

export default CoverImageUploadLoaderTemplate;
