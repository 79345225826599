import { BaseApiRouteService } from "mrb/core";

class LocationApiRouteService extends BaseApiRouteService {
    constructor() {
        super("lookups/locations");
    }

    find(filter) {
        return super.find(`${this.base}/{?searchQuery}`, filter);
    }

    create(resource) {
        return super.create(this.base, resource);
    }
}

export default LocationApiRouteService;
