import React from "react";
import { PropTypes } from "prop-types";
import { mergeOrOverrideCss } from "../../../common/utils";
import { observer } from "mobx-react";

function MrbPageFooter({ children, classNameOverride, classNameExtend }) {
    return (
        <div
            className={mergeOrOverrideCss(
                "content__footer",
                classNameOverride,
                classNameExtend
            )}
        >
            <div>{children}</div>
        </div>
    );
}

MrbPageFooter.propTypes = {
    children: PropTypes.any,
    classNameOverride: PropTypes.string,
    classNameExtend: PropTypes.string,
};

export default observer(MrbPageFooter);
