import React from 'react'
import ContentLoader from "react-content-loader";

const UserAccountSummaryLoaderTemplate = (props) => {
    const { row = 3 } = props;
    const screenWidth = window.innerWidth;
    let loaderWidth;

    const items = [];
    let y = 8;

    if (screenWidth > 1440) {
        loaderWidth = Math.floor((screenWidth - 760) / 2);
    } else if (screenWidth > 1199) {
        loaderWidth = Math.floor((screenWidth - 760) / 2);
    } else if (screenWidth > 991) {
        loaderWidth = Math.floor((screenWidth - 200) / 2);
    } else if (screenWidth > 767) {
        loaderWidth = Math.floor(screenWidth / 2);
    } else {
        loaderWidth = screenWidth;
    }

    for (let i = 0; i < row; i++) {
        let textWidth = Math.random() * (170 - 100) + 100;
        let secondTextWidth = Math.random() * (170 - 100) + 100;

        items.push(
            <React.Fragment key={i}>
                <rect x="0" y={y} rx="6" ry="6" width="80" height="8" />
                <rect x="0" y={y + 20} rx="6" ry="6" width={textWidth} height="14" />

                <rect x="55%" y={y} rx="6" ry="6" width="80" height="8" />
                <rect x="55%" y={y + 20} rx="6" ry="6" width={secondTextWidth} height="14" />
            </React.Fragment>
        );

        y += 57;
    }

    return (
        <div className="c-card--primary">
            <ContentLoader
                speed={2}
                width={loaderWidth}
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${y - 22}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                {items}
            </ContentLoader>
        </div>
    );
}

export default UserAccountSummaryLoaderTemplate
