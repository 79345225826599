import React from "react";
import PropTypes from "prop-types";
import { isString } from "lodash";
import { observer } from "mobx-react";

function MrbEditFormValidation({ form, t = (i) => i }) {
    return (
        <React.Fragment>
            {isString(form.error) && (
                <div className="c-validation--error">
                    <p className="c-validation__label">{t(form.error)}</p>
                </div>
            )}
        </React.Fragment>
    );
}

MrbEditFormValidation.propTypes = {
    form: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(MrbEditFormValidation);
