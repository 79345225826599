import React from "react";
import ContentLoader from "react-content-loader";

const MrbHeaderWithoutImageLoader = (props) => {
    const screenwidth =
        window.innerWidth > 992 ? window.innerWidth - 200 : window.innerWidth;
    const headerheight = 130;

    return (
        <ContentLoader
            speed={2}
            width={screenwidth}
            height={"100%"}
            viewBox={`0 0 ${screenwidth} ${headerheight}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            {/* prettier-ignore */}
            <rect x="0" y="20" rx="3" ry="3" width="50" height="8" />
            {/* prettier-ignore */}
            <rect x="0" y="40" rx="3" ry="3" width="170" height="30" />
            {/* prettier-ignore */}
            <rect x="0" y="100" rx="3" ry="3" width="50" height="8" />
            {/* prettier-ignore */}
            <rect x="0" y="120" rx="3" ry="3" width="265" height="8" />
            {/* prettier-ignore */}
            <rect x="0" y="135" rx="3" ry="3" width="135" height="8" />
        </ContentLoader>
    );
};

export default MrbHeaderWithoutImageLoader;
