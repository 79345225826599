import React from "react";
import ContentLoader from "react-content-loader";

const TopTrendingListViewMoreLoaderTemplate = (props) => {
    const { row = 12, loadMore, noButton, ...rest } = props;
    const screenwidth = window.innerWidth;
    const items = [];
    let loaderWidth;
    let loaderSml = false;

    if (screenwidth > 766) {
        loaderWidth = 768;
    } else {
        loaderWidth = screenwidth;
    }

    if (screenwidth < 543) {
        loaderSml = true;
    }

    let y = loadMore ? 24 : 24;
    let imgY = loadMore ? 12 : 12;

    for (let i = 0; i < row; i++) {
        let textWidth = Math.random() * (120 - 100) + loaderSml ? 100 : 50;
        let textWidth2 = Math.random() * (150 - 100) + loaderSml ? 200 : 50;
        items.push(
            <React.Fragment key={`top-trending_${i}`}>
                <rect x="20" y={y} rx="4" ry="4" width="10" height="16" />
                <rect x="20" y={y + 20} rx="2" ry="2" width="10" height="7" />

                <rect x="130" y={loaderSml ? y - 6 : y + 8} rx="4" ry="4" width={textWidth} height="10" />
                <rect x={textWidth + 140} y={loaderSml ? y - 6 : y + 8} rx="4" ry="4" width={textWidth2} height="10" />

                <rect x="65" y={imgY} rx="3" ry="3" width="50" height="50" />

                {noButton &&
                    <React.Fragment>
                        <rect x={loaderSml ? "35%" : "79%"} y={loaderSml ? y + 16 : y + 6} rx="4" ry="4" width={loaderSml ? "8%" : "3%"} height="16" />
                        {loaderSml &&
                            <rect x={loaderSml ? "35%" : "79%"} y={loaderSml ? y + 36 : y + 6} rx="4" ry="4" width={"12"} height="6" />
                        }
                    </React.Fragment>
                }
                <rect x="92%" y={loaderSml ? y + 16 : y + 8} rx="4" ry="4" width="8%" height="10" />
                {loaderSml &&
                    <rect x={"94.5%"} y={loaderSml ? y + 34 : y + 6} rx="4" ry="4" width={"12"} height="6" />
                }
                <rect x="0" y={imgY + 75} rx="6" ry="6" width="100%" height="1" />
            </React.Fragment>
        );

        y += 95;
        imgY += 95;
    }

    return (
        <div>
            <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${!noButton ? y + 56 : y - 20}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...rest}
            >
                {items}
                {!noButton && (
                    <React.Fragment>
                        <rect x="0" y={y - 6} rx="4" ry="4" width="100%" height="1" />
                        <rect x="0" y={y + 12} rx="4" ry="4" width="100%" height="36" />
                    </React.Fragment>
                )}
            </ContentLoader>
        </div>
    );
}

export default TopTrendingListViewMoreLoaderTemplate
