import { action, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { DoughnutChartStore } from "common/components";
import { map, sumBy } from "lodash";

const numberFormatter = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 });

class UserDeviceActivitySummaryViewStore extends MrbBaseViewStore {
    @observable.ref contentPlaybacks = [];

    userId = null;

    constructor(rootStore, { userId, routeStore }) {
        super(rootStore);
        this.routeStore = routeStore;
        this.userId = userId;
        makeObservable(this);

        this.chartStore = new DoughnutChartStore(this.rootStore, {
            actions: {
                mapLabels: (data) => {
                    return map(data, (item) => (item.device ? item.device.deviceName : "Unregistered device"));
                },
                mapDataset: (data) => {
                    const total = sumBy(data, (item) => item.playbacks);
                    return map(data, (item) => numberFormatter.format((item.playbacks / total) * 100));
                },
            },
            backgroundColor: ["#456990", "#1b96c6", "#41e9b6", "#eeb868", "#ef767a"],
            borderColor: ["#456990", "#1b96c6", "#41e9b6", "#eeb868", "#ef767a"],
            borderWidth: 1,
            options: {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                return `${context.label}: ${context.parsed}%`;
                            },
                        },
                    },
                    legend: {
                        position: "right",
                        onClick: null,
                        labels: {
                            color: "#f5f5f5",
                        },
                    },
                },
            },
        });

        this.loadData = this.loadData.bind(this);
    }

    loadData() {
        return this.getUserDeviceActivitySummary(this.userId);
    }

    async getUserDeviceActivitySummary(userId) {
        try {
            this.chartStore.suspend();
            this.loaderStore.suspend();
            this.rootStore.mainLoaderStore.suspend();
            const data = await this.routeStore.getUserDeviceActivitySummary(userId);
            runInAction(() => {
                this.chartStore.setDataSet(data.devicePlaybacks);
                this.setContentPlaybacks(data.contentPlaybacks);
            });
        } catch (err) {
            console.log(err);
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team."
            );
        } finally {
            this.chartStore.resume();
            this.loaderStore.resume();
            this.rootStore.mainLoaderStore.resume();
        }
    }

    @action.bound
    setContentPlaybacks(data) {
        this.contentPlaybacks = data;
    }
}

export default UserDeviceActivitySummaryViewStore;
