import React from "react";
import {
    MrbPageHeader,
    MrbPageFooter,
    MrbPageContentHeader,
    MrbPageContentFooter,
    MrbPageContentSidebar,
} from "../page";

function getPageObject(children) {
    const page = {
        header: null,
        footer: null,
        content: {
            header: null,
            footer: null,
            sidebar: null,
            children: null,
        },
    };

    React.Children.forEach(children, (child) => {
        if (!React.isValidElement(child)) return;
        switch (child.type) {
            case MrbPageHeader:
                if (!page.header) page.header = [];
                page.header.push(child);
                break;
            case MrbPageFooter:
                if (!page.footer) page.footer = [];
                page.footer.push(child);
                break;
            case MrbPageContentHeader:
                if (!page.content.header) page.content.header = [];
                page.content.header.push(child);
                break;
            case MrbPageContentFooter:
                if (!page.content.footer) page.content.footer = [];
                page.content.footer.push(child);
                break;
            case MrbPageContentSidebar:
                if (!page.content.sidebar) page.content.sidebar = [];
                page.content.sidebar.push(child);
                break;
            default:
                if (!page.content.children) page.content.children = [];
                page.content.children.push(child);
                break;
        }
    });

    return page;
}
export default getPageObject;
