import React from "react";
import PropTypes from "prop-types";

function MrbEmptyState({ title, message, children, t = (i) => i }) {
    return (
        <div className="card card--primary card--med display--ib">
            <div className="type--color--primary spc--bottom--sml">
                <span className="icomoon icon-alert-circle spc--right--tny align--v--text-top" />
                <h5 className="display--ib">{t(title)}</h5>
            </div>
            <div className="padd--left--med">
                {children ? children : <p>{t(message)}</p>}
            </div>
        </div>
    );
}

MrbEmptyState.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    children: PropTypes.any,
};

MrbEmptyState.defaultProps = {
    title: "EMPTY_STATE.NO_DATA",
    message: "",
};

export default MrbEmptyState;
