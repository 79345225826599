import React from "react";
import { PropTypes } from "prop-types";
import { MrbContentLoader } from "mrb/components/loader";
import { observer } from "mobx-react";
import { currencyFormatter } from "mrb/common/formatting";
import { map } from "lodash";
import { ProducerBalanceInfoLoader } from "administration/producer/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";

function AdminProducerListSummaryPreviewTemplate({ adminProducerListViewStore, t }) {
    const { producersSummary, loaderStore } = adminProducerListViewStore;
    return (
        <div className="u-mar--bottom--sml">
            <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <ProducerBalanceInfoLoader />}>
                {() => (
                    <div className="u-animation--fadeIn">
                        {producersSummary && (
                            <div className="c-card--primary c-card--med">
                                <div className="u-mar--left--xsml">
                                    <div className="row u-mar--bottom--sml">
                                        <div className="col col-sml-12">
                                            <p className="u-type--sml u-type--color--opaque u-mar--bottom--xsml">
                                                Total balance on all accounts
                                            </p>

                                            <div className="c-dashboard__count--increase">
                                                <p className="c-dashboard__count--lrg">
                                                    {currencyFormatter.format(
                                                        producersSummary.totalBalance
                                                            ? producersSummary.totalBalance
                                                            : 0,
                                                        {
                                                            currency: "USD",
                                                        }
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque u-mar--bottom--xsml">
                                            Top users by balance
                                        </p>
                                        {map(producersSummary.producerAccountSummaries, (summary, index) => (
                                            <div className="u-display--flex u-display--flex--jc--space-between">
                                                <MrbRouterLink
                                                    routeName="master.administration.producer.account"
                                                    params={{ id: summary.producerUserAccountId }}
                                                    className="u-type--base"
                                                >
                                                    {`${summary.firstName} ${summary.lastName} `}
                                                </MrbRouterLink>

                                                <div className="u-type--base u-type--color--success">
                                                    {currencyFormatter.format(
                                                        summary.accountBalance ? summary.accountBalance : 0,
                                                        {
                                                            currency: "USD",
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <p className="u-type--sml u-type--color--opaque u-mar--bottom--xsml">
                                        Today's payouts to bank accounts
                                    </p>
                                    <div className="c-dashboard__count--increase">
                                        <p className="c-dashboard__count--lrg">
                                            {currencyFormatter.format(
                                                producersSummary.payoutAmount ? producersSummary.payoutAmount : 0,
                                                {
                                                    currency: "USD",
                                                }
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </MrbContentLoader>
        </div>
    );
}

AdminProducerListSummaryPreviewTemplate.propTypes = {
    adminProducerListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(AdminProducerListSummaryPreviewTemplate);
