import React from "react";
import PropTypes from "prop-types";

function MrbRecaptchaPrivacyPolicy({ className = null }) {
    return (
        <p className={className}>
            This page is protected by reCAPTCHA and the Google{" "}
            <a href="https://www.google.com/policies/privacy/">
                Privacy Policy
            </a>{" "}
            and{" "}
            <a href="https://www.google.com/policies/terms/">
                Terms of Service
            </a>{" "}
            apply.
        </p>
    );
}

MrbRecaptchaPrivacyPolicy.propTypes = {
    className: PropTypes.string,
};

export default MrbRecaptchaPrivacyPolicy;
