import { timeRangeType } from "administration/analytics/constants";
import moment from "moment";
import "moment-timezone";

class DateRangeFactory {
    create(type, timeZone) {
        const range = {
            from: null,
            to: null,
        };
        switch (type) {
            case timeRangeType.today:
                range.from = moment().tz(timeZone).startOf("day").toISOString();
                range.to = moment().tz(timeZone).endOf("day").toISOString();
                break;
            case timeRangeType.yesterday:
                range.from = moment().tz(timeZone).subtract(1, "day").startOf("day").toISOString();
                range.to = moment().tz(timeZone).subtract(1, "day").endOf("day").toISOString();
                break;
            case timeRangeType.last3Days:
                range.from = moment().tz(timeZone).subtract(2, "day").startOf("day").toISOString();
                range.to = moment().tz(timeZone).endOf("day").toISOString();
                break;
            case timeRangeType.thisWeek:
                range.from = moment().tz(timeZone).startOf("week").toISOString();
                range.to = moment().tz(timeZone).endOf("week").toISOString();
                break;
            case timeRangeType.lastWeek:
                range.from = moment().tz(timeZone).subtract(1, "week").startOf("week").toISOString();
                range.to = moment().tz(timeZone).subtract(1, "week").endOf("week").toISOString();
                break;
            case timeRangeType.thisMonth:
                range.from = moment().tz(timeZone).startOf("month").toISOString();
                range.to = moment().tz(timeZone).endOf("month").toISOString();
                break;
            case timeRangeType.lastMonth:
                range.from = moment().tz(timeZone).subtract(1, "month").startOf("month").toISOString();
                range.to = moment().tz(timeZone).subtract(1, "month").endOf("month").toISOString();
                break;
            default:
                break;
        }

        return range;
    }

    createPreviousCycleRangeFrom(type, from, to, timeZone) {
        switch (type) {
            case timeRangeType.today:
            case timeRangeType.yesterday:
                return moment.tz(from, timeZone).subtract(1, "day").startOf("day").toISOString();
            case timeRangeType.last3Days:
                return moment.tz(from, timeZone).subtract(2, "day").startOf("day").toISOString();
            case timeRangeType.lastWeek:
            case timeRangeType.thisWeek:
                return moment.tz(from, timeZone).subtract(1, "week").startOf("week").toISOString();
            case timeRangeType.thisMonth:
            case timeRangeType.lastMonth:
                return moment.tz(from, timeZone).subtract(1, "month").startOf("month").toISOString();
            default:
                const daysDiff =
                    moment.tz(to, timeZone).startOf("day").diff(moment.tz(from, timeZone).startOf("day"), "days") + 1;
                return moment.tz(from, timeZone).subtract(daysDiff, "days").toISOString();
        }
    }

    createPreviousCycleRangeTo(type, from, to, timeZone) {
        switch (type) {
            case timeRangeType.today:
            case timeRangeType.yesterday:
                return moment.tz(to, timeZone).subtract(1, "day").endOf("day").toISOString();
            case timeRangeType.last3Days:
                return moment.tz(from, timeZone).subtract(1, "day").endOf("day").toISOString();
            case timeRangeType.lastWeek:
            case timeRangeType.thisWeek:
                return moment.tz(to, timeZone).subtract(1, "week").endOf("week").toISOString();
            case timeRangeType.thisMonth:
            case timeRangeType.lastMonth:
                return moment.tz(to, timeZone).subtract(1, "month").endOf("month").toISOString();
            default:
                const daysDiff =
                    moment.tz(to, timeZone).startOf("day").diff(moment.tz(from, timeZone).startOf("day"), "days") + 1;
                return moment.tz(to, timeZone).subtract(daysDiff, "days").toISOString();
        }
    }

    createPreviousCycleRange(type, from, to, timeZone) {
        const range = {
            from: this.createPreviousCycleRangeFrom(type, from, to, timeZone),
            to: this.createPreviousCycleRangeTo(type, from, to, timeZone),
        };
        return range;
    }
}

const dateRangeFactory = new DateRangeFactory();
export default dateRangeFactory;
