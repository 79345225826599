import React from "react";
import PropTypes from "prop-types";

function MrbPreviewContent({ children }) {
    return <React.Fragment>{children}</React.Fragment>;
}

MrbPreviewContent.propTypes = {
    children: PropTypes.any,
};

export default MrbPreviewContent;
