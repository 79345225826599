import { BaseService } from "mrb/core";
import { PaymentApiRouteService } from "common/services";

class PaymentService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new PaymentApiRouteService());
    }

    preprocess(planId, abortSignal = null) {
        const url = this.routeService.preprocess(planId);
        return this.apiClient.post(url, null, null, abortSignal);
    }

    activateTrial(planId, abortSignal = null) {
        const url = this.routeService.activateTrial(planId);
        return this.apiClient.post(url, null, null, abortSignal);
    }

    endTrialPeriod(subscriptionId, abortSignal = null) {
        const url = this.routeService.endTrialPeriod(subscriptionId);
        return this.apiClient.delete(url, null, null, abortSignal);
    }

    upgradeTrialSubscription(subscriptionId, data, abortSignal = null) {
        const url = this.routeService.upgradeTrialSubscription(subscriptionId);
        return this.apiClient.put(url, data, null, abortSignal);
    }

    setupPaymentMethodRequest(abortSignal = null) {
        const url = this.routeService.setupPaymentMethodRequest();
        return this.apiClient.post(url, null, null, abortSignal);
    }

    getUserPremiumInfo(options, abortSignal = null) {
        const url = this.routeService.getUserPremiumInfo(options);
        return this.apiClient.get(url, null, abortSignal);
    }

    getLatestSubscriptionPayment(subscriptionId, options, abortSignal = null) {
        const url = this.routeService.getLatestSubscriptionPayment(subscriptionId, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    postprocess(data, abortSignal = null) {
        const url = this.routeService.postprocess();
        return this.apiClient.put(url, data, null, abortSignal);
    }

    subscribeUsingExistingPaymentMethod(planId, abortSignal = null) {
        const url = this.routeService.subscribeUsingExistingPaymentMethod(planId);
        return this.apiClient.post(url, null, null, abortSignal);
    }

    updateDefaultPaymentMethod(data, abortSignal = null) {
        const url = this.routeService.updateDefaultPaymentMethod();
        return this.apiClient.put(url, data, null, abortSignal);
    }

    postprocessPaymentMethodUpdate(data, abortSignal = null) {
        const url = this.routeService.postprocessPaymentMethodUpdate();
        return this.apiClient.put(url, data, null, abortSignal);
    }

    cancelSubscription(subscriptionId, abortSignal = null) {
        const url = this.routeService.cancelSubscription(subscriptionId);
        return this.apiClient.delete(url, null, null, abortSignal);
    }

    renewSubscription(subscriptionId, abortSignal = null) {
        const url = this.routeService.renewSubscription(subscriptionId);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    changeSubscriptionPlan(planId, abortSignal = null) {
        const url = this.routeService.changeSubscriptionPlan(planId);
        return this.apiClient.put(url, null, null, abortSignal);
    }

    getSubscriptionChangePreview(subscriptionId, planId, abortSignal = null) {
        const url = this.routeService.getSubscriptionChangePreview(subscriptionId, planId);
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default PaymentService;
