import { BaseService } from "mrb/core";
import { GenreApiRouteService } from "common/services";
import { findParams } from "mrb/core/utils";

class GenreService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new GenreApiRouteService());
    }

    getGenreContent(id, filter, abortSignal = null) {
        const params = findParams(filter);
        const data = {
            page: params.page,
            rpp: params.rpp,
            sort: params.sort,
        };
        const url = this.routeService.getGenreContent(id, data);
        return this.apiClient.get(url, data, null, abortSignal);
    }
}

export default GenreService;
