import React from "react";
import ContentLoader from "react-content-loader";

const MrbFormFieldContentLoader = ({ props, col = 1 }) => {
    const screenwidth = window.innerWidth;
    let loaderWidth;

    if (screenwidth > 1440) {
        loaderWidth = Math.floor((screenwidth - 300) / 3);
    } else if (screenwidth > 992) {
        loaderWidth = Math.floor((screenwidth - 250) / 2);
    } else {
        loaderWidth = screenwidth - 40;
    }

    const items = [];
    const colWidth = 110;
    let x = 0;

    for (let i = 1; i <= col; i++) {
        if (i === col) {
            items.push(
                <React.Fragment key={`${i}_${i}`}>
                    {/* prettier-ignore */}
                    <rect x={loaderWidth - colWidth} y="35" rx="6" ry="6" width={colWidth} height="36" />
                </React.Fragment>
            );
        } else {
            items.push(
                <React.Fragment key={`${i}_${i}`}>
                    {/* prettier-ignore */}
                    <rect x={x} y="35" rx="6" ry="6" width={colWidth} height="36" />
                </React.Fragment>
            );
        }
        x += colWidth + 15;
    }

    return (
        <ContentLoader
            speed={2}
            width={loaderWidth}
            height="100%"
            viewBox={`0 0 ${loaderWidth} 80`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            <rect x="0" y="0" rx="6" ry="6" width="10%" height="16" />
            {col > 1 ? (
                items
            ) : (
                <rect x="0" y="30" rx="6" ry="6" width="100%" height="48" />
            )}
        </ContentLoader>
    );
};

export default MrbFormFieldContentLoader;
