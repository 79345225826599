import { BaseApiRouteService } from "mrb/core";

class DeviceMenuTypeApiRouteService extends BaseApiRouteService {
    constructor() {
        super("lookups/device-menu-types");
    }

    get() {
        return super.get(this.base);
    }
}

export default DeviceMenuTypeApiRouteService;
