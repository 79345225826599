import React from "react";
import { PropTypes } from "prop-types";
import { useCanAccessContent, nakiRadioContentType } from "application/common/content-access";
import { Image } from "common/components";
import { isNil } from "lodash";

function HomeSearchResultGridCellTemplate({ item, onCellClick }) {
    let contentType;
    if (!isNil(item.stationId)) {
        contentType = nakiRadioContentType.station;
    } else if (!isNil(item.podcastId)) {
        contentType = nakiRadioContentType.podcast;
    }
    const contentAccessResult = useCanAccessContent(contentType, {
        isPremium: item.isPremium,
        isLegacySubscriptionValid: item.validLegacySubscription,
        unavailableInMobileApp: item.isVisibleInApp === false,
    });
    return (
        <div onClick={() => onCellClick(item)} className="c-home__preview__item c-home__preview__item--search">
            <div className="c-home__preview__icon u-mar--right--sml">
                <Image src={item.coverImageUrl} />
            </div>
            <div>
                <div className="c-home__preview__title">
                    {item.title}{" "}
                    {item.isPremium && !contentAccessResult.canAccessPremium && (
                        <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
                    )}
                </div>
                {item.parentPodcastTitle && (
                    <div className="c-home__preview__desc c-home__preview__desc--primary">
                        {item.parentPodcastTitle}
                    </div>
                )}
            </div>
        </div>
    );
}

HomeSearchResultGridCellTemplate.propTypes = {
    item: PropTypes.object.isRequired,
};

export default HomeSearchResultGridCellTemplate;
