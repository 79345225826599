import { map } from "lodash";

class BaseService {
    apiClient = null;

    constructor(apiClient, routeService) {
        this.apiClient = apiClient;
        this.routeService = routeService;

        this.find = this.find.bind(this);
        this.get = this.get.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.batchDelete = this.batchDelete.bind(this);
        this.batchUpdate = this.batchUpdate.bind(this);
    }

    find(filter, abortSignal = null) {
        const url = this.routeService.find(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    get(id, options = {}, abortSignal = null) {
        const url = this.routeService.get(id, options);
        return this.apiClient.get(url, null, abortSignal);
    }

    create(resource, abortSignal = null) {
        const url = this.routeService.create(resource);
        return this.apiClient.post(url, resource, null, abortSignal);
    }

    update(resource, abortSignal = null) {
        const url = this.routeService.update(resource);
        return this.apiClient.put(url, resource, null, abortSignal);
    }

    delete(resource, abortSignal = null) {
        const url = this.routeService.delete(resource);
        return this.apiClient.delete(url, null, resource, abortSignal);
    }

    batchDelete(resources, abortSignal = null) {
        const url = this.routeService.batchDelete(resources);
        const ids = resources ? map(resources, (r) => r.id) : [];
        return this.apiClient.delete(url, null, ids, abortSignal);
    }

    batchUpdate(resources, abortSignal = null) {
        const url = this.routeService.batchUpdate(resources);
        return this.apiClient.put(url, resources, abortSignal);
    }

    batchCreate(resources, abortSignal = null) {
        const url = this.routeService.batchCreate(resources);
        return this.apiClient.post(url, resources, abortSignal);
    }
}

export default BaseService;
