import { BaseService } from "mrb/core";
import { ProducerUserAccountApiRouteService } from "common/services";

class ProducerUserAccountService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new ProducerUserAccountApiRouteService());
    }

    getProducerAccountsSummary(producerId, abortSignal = null) {
        const url = this.routeService.getProducerAccountsSummary(producerId);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getAdminProducerAccountSummary(accountId, abortSignal = null) {
        const url = this.routeService.getAdminProducerAccountSummary(accountId);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getAdminProducerAccountAssignedProducers(accountId, abortSignal = null) {
        const url = this.routeService.getAdminProducerAccountAssignedProducers(accountId);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getProducerAccountSummary(abortSignal = null) {
        const url = this.routeService.getProducerAccountSummary();
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getAccountTotalBalance(accountId, abortSignal = null) {
        const url = this.routeService.getAccountTotalBalance(accountId);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getProducerTotalBalance(producerId, abortSignal = null) {
        const url = this.routeService.getProducerTotalBalance(producerId);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getStripeConnectAccounts(abortSignal = null) {
        const url = this.routeService.getStripeConnectAccounts();
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getAdminProducerAccountBalance(filter, abortSignal = null) {
        const url = this.routeService.getAdminProducerAccountBalance(filter);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getAdminProducerAccountRevenueTransaction(filter, abortSignal = null) {
        const url = this.routeService.getAdminProducerAccountRevenueTransaction(filter);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getProducerAccountBalance(filter, abortSignal = null) {
        const url = this.routeService.getProducerAccountBalance(filter);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    getProducerAccountRevenueTransaction(filter, abortSignal = null) {
        const url = this.routeService.getProducerAccountRevenueTransaction(filter);
        return this.apiClient.get(url, null, null, abortSignal);
    }

    updateAccounts(producerId, resource, abortSignal = null) {
        const url = this.routeService.updateAccounts(producerId);
        return this.apiClient.put(url, resource, null, abortSignal);
    }

    addAccountBalance(resource, abortSignal = null) {
        const url = this.routeService.addAccountBalance();
        return this.apiClient.post(url, resource, null, abortSignal);
    }

    processPayoutToBankAccount(accountId, resource, abortSignal = null) {
        const url = this.routeService.processPayoutToBankAccount(accountId);
        return this.apiClient.post(url, resource, null, abortSignal);
    }

    updateStripeAccount(resource, abortSignal = null) {
        const url = this.routeService.updateStripeAccount();
        return this.apiClient.put(url, resource, null, abortSignal);
    }

    getProducers(abortSignal = null) {
        const url = this.routeService.getProducers();
        return this.apiClient.get(url, null, abortSignal);
    }

    getProducersSummary(filter, abortSignal = null) {
        const url = this.routeService.getProducersSummary(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getStripeConnectOnboardingStatus(abortSignal = null) {
        const url = this.routeService.getStripeConnectOnboardingStatus();
        return this.apiClient.get(url, null, abortSignal);
    }

    createStripeConnectLink(resource, abortSignal = null) {
        const url = this.routeService.createStripeConnectLink();
        return this.apiClient.post(url, resource, null, abortSignal);
    }

    getProducerUserAccountProducerType(abortSignal = null) {
        const url = this.routeService.getProducerUserAccountProducerType();
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default ProducerUserAccountService;
