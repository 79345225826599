import { action, computed, observable, makeObservable, reaction, runInAction } from "mobx";
import { DeviceService } from "common/services";
import { UserDeviceSyncStore } from "common/stores";
import { find, isNil, head, map } from "lodash";

class UserDeviceStore {
    @observable.ref devices = [];
    @observable.ref deviceLimit = null;
    @observable.ref selectedDevice = null;
    _deviceService = null;

    @computed get isDeviceSyncEnabled() {
        return this.userDeviceSyncStore.isDeviceSyncEnabled;
    }

    @computed get isDeviceSlotAvailable() {
        if (isNil(this.deviceLimit)) {
            return true;
        }
        return this.devices.length < this.deviceLimit;
    }

    @computed get deviceCount() {
        if (this.devices) {
            return this.devices.length;
        }
        return 0;
    }

    @computed get deviceIds() {
        return map(this.devices, (device) => device.id);
    }

    get deviceService() {
        if (isNil(this._deviceService)) {
            this._deviceService = this.rootStore.createApplicationService(DeviceService);
        }
        return this._deviceService;
    }

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
        this.userDeviceSyncStore = new UserDeviceSyncStore(rootStore);

        reaction(
            () => this.devices,
            (newDevices) => {
                if (isNil(this.selectedDevice)) {
                    if (newDevices.length > 0) {
                        this.setSelectedDevice(head(newDevices));
                    }
                } else {
                    const existingDevice = find(newDevices, (device) => device.id === this.selectedDevice.id);
                    if (existingDevice) {
                        this.setSelectedDevice(existingDevice);
                    } else if (newDevices.length > 0) {
                        this.setSelectedDevice(head(newDevices));
                    } else {
                        this.setSelectedDevice(null);
                    }
                }
            }
        );
    }

    @action.bound
    async initialize() {
        await this.loadUserDevices();
        this.userDeviceSyncStore.initialize();
    }

    async loadUserDevices() {
        try {
            const response = await this.deviceService.getUserDeviceInfo({
                embed: "device,device.systemType",
                orderBy: "dateCreated",
                orderDirection: "desc",
            });
            runInAction(() => {
                this.setDeviceLimit(response.data.deviceLimit);
                this.setDevices(response.data.devices);
            });
        } catch (err) {}
    }

    async getUserDevice(id, options = null) {
        const response = await this.deviceService.getUserDevice(id, options);
        return response.data;
    }

    async updateUserDevice(resource) {
        const response = await this.deviceService.updateUserDevice(resource);
        await this.loadUserDevices();
        return response.data;
    }

    async deleteUserDevice(resource) {
        const response = await this.deviceService.deleteUserDevice(resource);
        await this.loadUserDevices();
        return response.data;
    }

    @action.bound
    setDeviceLimit(limit) {
        this.deviceLimit = limit;
    }

    @action.bound
    setDevices(devices) {
        this.devices = devices;
    }

    @action.bound
    setSelectedDevice(device) {
        if (!device.isDisabled) {
            this.selectedDevice = device;
        }
    }

    @action.bound
    setIsDeviceSyncEnabled(value) {
        this.userDeviceSyncStore.setIsDeviceSyncEnabled(value);
    }
}

export default UserDeviceStore;
