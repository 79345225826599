import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { MrbModalParams } from "mrb/common/models";
import { StripeField } from "common/models";
import { subscriptionPlans } from "common/constants";
import { isNil } from "lodash";

class BasePaymentViewStore extends MrbBaseViewStore {
    @observable.ref stripe = null;
    @observable.ref subscriptionPlan = null;
    stripeField = null;

    @computed get isPaymentDisabled() {
        if (this.paymentMethodExist) {
            return false;
        }
        return isNil(this.stripe) || isNil(this.stripeField) || !this.stripeField.isCompleted;
    }

    get paymentMethodExist() {
        return !isNil(this.rootStore.premiumSubscriptionStore.customerPaymentMethod);
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
        this.stripeField = new StripeField();

        this.paymentSuccessModal = new MrbModalParams(null, {
            onClickExplorePremium: this.goToPremiumContent,
        });
    }

    @action.bound
    setSubscriptionPlan(plan) {
        this.subscriptionPlan = plan;
    }

    goToPremiumContent = () => {
        switch (this.subscriptionPlan.slug) {
            case subscriptionPlans.nakiTales:
            case subscriptionPlans.nakiRadioPremium:
                this.rootStore.routerStore.goTo("master.application.home.list", null, {
                    isPremium: true,
                });
                break;
            case subscriptionPlans.nakiNigun:
                this.rootStore.routerStore.goTo("master.application.naki-nigun.list");
                break;
            default:
                break;
        }
    };

    @action.bound
    async authorizePaymentRequest(clientSecret) {
        try {
            const result = await this.stripe.handleCardAction(clientSecret);
            if (result.error) {
            } else {
                await this.routeStore.confirmPayment({
                    providerPaymentRequestId: result.paymentIntent.id,
                });
            }
        } catch (err) {
            this.rootStore.notificationStore.error(
                "Unexpected error occurred while trying to complete payment. Please contact the support team.",
                err
            );
        }
    }

    @action.bound
    setStripe(stripe) {
        this.stripe = stripe;
    }
}

export default BasePaymentViewStore;
