import { computed, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";

const numericRegex = /\d/;

class PasswordFieldInfoViewStore extends MrbBaseViewStore {
    passwordField = null;

    @computed get isMiniumLengthValid() {
        return this.passwordField.value && this.passwordField.value.length >= 8;
    }

    @computed get containsSpecialCharacter() {
        return containsSpecialCharacter(this.passwordField.value);
    }

    @computed get containsNumericCharacter() {
        return numericRegex.test(this.passwordField.value);
    }

    constructor(rootStore, { field }) {
        super(rootStore);
        makeObservable(this);
        this.passwordField = field;
    }
}

function containsSpecialCharacter(value) {
    if (value) {
        for (let i = 0; i < value.length; i++) {
            const character = value[i];
            if (
                !(character >= "0" && character <= "9") &&
                character.toUpperCase() === character.toLowerCase()
            ) {
                return true;
            }
        }
    }

    return false;
}

export default PasswordFieldInfoViewStore;
