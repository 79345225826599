import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldLayout } from "mrb/components/layout/content/form";
import { RichTextEditor } from "common/components";
import { observer } from "mobx-react";
import { isNil } from "lodash";

function FieldRichTextEditorTemplate({
    field,
    t,
    showLabel = true,
    disabled,
    labelRender = null,
    validationRender = null,
    classNameExtend,
    classNameOverride,
    ...restOfProps
}) {
    const { placeholder, onChange, ...otherProps } = field.bind();
    if (otherProps.value && typeof otherProps.value === "string")
        otherProps.value = otherProps.value.replace(/\s+/g, " ");
    if (otherProps.value == null) otherProps.value = "";

    const onChangeFn = function (value) {
        value = value.replace(/\n$/, "");
        if (value === "<p></p>") {
            value = null;
        }
        if (otherProps.value !== value) {
            onChange(value);
        }
    };

    return (
        <MrbFieldLayout
            field={field}
            t={t}
            labelRender={labelRender}
            validationRender={validationRender}
            showLabel={showLabel}
        >
            <RichTextEditor
                {...otherProps}
                {...restOfProps}
                onChange={onChangeFn}
                disabled={isNil(disabled) ? otherProps.disabled : disabled}
                placeholder={t(placeholder)}
            />
        </MrbFieldLayout>
    );
}

FieldRichTextEditorTemplate.propTypes = {
    field: PropTypes.object.isRequired,
    showLabel: PropTypes.bool,
    t: PropTypes.func,
    labelRender: PropTypes.func,
    validationRender: PropTypes.func,
    classNameExtend: PropTypes.string,
};

export default observer(FieldRichTextEditorTemplate);
