import React from "react";
import PropTypes from "prop-types";
import { MrbModal, MrbConfirmDialogPanel } from "./";
import { observer } from "mobx-react";

function MrbConfirmDialog({ store, customStyles, t }) {
    const { confirmModalParams } = store;
    return (
        <React.Fragment>
            {confirmModalParams && (
                <MrbModal
                    modalParams={confirmModalParams}
                    style={customStyles}
                    t={t}
                >
                    <MrbConfirmDialogPanel
                        message={confirmModalParams.message}
                        description={confirmModalParams.description}
                        onConfirm={confirmModalParams.confirm}
                        yesLabel={confirmModalParams.yesLabel}
                        noLabel={confirmModalParams.noLabel}
                        onCancel={confirmModalParams.cancel}
                        loading={confirmModalParams.loading}
                        t={t}
                    />
                </MrbModal>
            )}
        </React.Fragment>
    );
}

MrbConfirmDialog.propTypes = {
    store: PropTypes.object.isRequired,
    customStyles: PropTypes.object,
    t: PropTypes.func,
};

export default observer(MrbConfirmDialog);
