import { computed, action, makeObservable, observable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { MrbFieldSelectStore } from "mrb/components/select";
import { find, findIndex, isNil } from "lodash";

class AdminProducerAccountEditViewStore extends MrbBaseViewStore {
    @computed get currentAccountBalance() {
        if (this.selectedAccountExistingBalance != null) {
            return this.selectedAccountExistingBalance;
        }
        if (this.existingAccount) {
            return this.existingAccount.accountBalance;
        }

        return 0;
    }

    @computed get existingAccount() {
        if (this.form.has("accountId")) {
            const accountId = this.form.$("accountId").value;
            if (accountId) {
                return find(
                    this.adminProducerAccountsViewStore.accounts,
                    (account) => account.producerUserAccountId === accountId
                );
            }
        }

        return null;
    }

    @computed get producer() {
        return this.adminProducerAccountsViewStore.producer;
    }

    @observable.ref form;
    @observable.ref stripeAccountSelectStore;

    @observable selectedAccountExistingBalance;

    constructor(rootStore, { adminProducerAccountsViewStore, routeStore, form }) {
        super(rootStore);
        makeObservable(this);
        this.adminProducerAccountsViewStore = adminProducerAccountsViewStore;
        this.routeStore = routeStore;
        this.setForm(form);
    }

    @action.bound
    setForm(form) {
        this.form = form;

        this.stripeAccountSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: false,
                virtualized: false,
                textKey: "email",
                actions: {
                    find: () => {
                        return this.adminProducerAccountsViewStore.stripeConnectAccounts;
                    },
                },
            },
            this.form.$("stripeAccountId")
        );

        this.userAccountSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                initFetch: false,
                textKey: "email",
                isOptionDisabled: (option) => {
                    const isUserAlreadySelected =
                        findIndex(
                            this.adminProducerAccountsViewStore.selectedUsers,
                            (userId) => userId === option.value
                        ) !== -1;
                    if (isUserAlreadySelected) {
                        return true;
                    } else {
                        return false;
                    }
                },
                actions: {
                    find: (searchPhrase, pageNumber, pageSize) => {
                        return this.routeStore.findUser({
                            search: searchPhrase,
                            pageNumber: pageNumber,
                            pageSize: pageSize,
                            embed: "producerUserAccount,producerUserAccount.producer,membership",
                            orderBy: "firstName,lastName",
                            orderDirection: "asc,asc",
                        });
                    },
                    onChange: async (selected) => {
                        if (!isNil(selected)) {
                            const user = await this.routeStore.getUser(selected.value);
                            if (user && user.producerUserAccount) {
                                const balance = await this.routeStore.getAccountTotalBalance(
                                    user.producerUserAccount.id
                                );
                                this.selectedAccountExistingBalance = balance;
                            } else {
                                this.selectedAccountExistingBalance = 0;
                            }
                            if (user && user.producerUserAccount && user.producerUserAccount.stripeAccountId) {
                                this.stripeAccountSelectStore.setFormValue(user.producerUserAccount.stripeAccountId);
                                this.stripeAccountSelectStore.setSelectedItem({
                                    id: user.producerUserAccount.stripeAccountId,
                                });
                            } else {
                                this.stripeAccountSelectStore.setFormValue("");
                                this.stripeAccountSelectStore.setSelectedItem();
                            }
                        } else {
                            this.stripeAccountSelectStore.setFormValue("");
                            this.stripeAccountSelectStore.setSelectedItem();
                            this.selectedAccountExistingBalance = 0;
                        }
                    },
                },
            },
            this.form.$("userId")
        );

        if (this.existingAccount) {
            this.userAccountSelectStore.setSelectedItem({
                firstName: this.existingAccount.firstName,
                lastName: this.existingAccount.lastName,
                email: this.existingAccount.email,
                id: this.existingAccount.userId,
                producerUserAccount: {
                    id: this.existingAccount.producerUserAccountId,
                    producer: {
                        id: this.producer.id,
                        name: this.producer.name,
                    },
                },
            });
        }
    }

    onViewUpdate(_, newProps) {
        /* eslint-disable */
        // return true only if both operands reference the same object.
        if (this.form == newProps.form) {
            return;
        }
        /* eslint-enable */
        this.setForm(newProps.form);
    }

    @action.bound
    removeAccount(event) {
        this.form.onDel(event);
    }
}

export default AdminProducerAccountEditViewStore;
