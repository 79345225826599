import { BasePortalDetailedActivityViewStore } from "administration/analytics/stores";
class RegisteredUserDevicesActivityViewStore extends BasePortalDetailedActivityViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            chartLabel: "Registered devices",
        });
        this.routeStore = routeStore;
    }

    getActivityChartData(params, abortController) {
        return this.routeStore.getRegisteredUserDevicesActivity(params, abortController);
    }

    fetchSummary(params, abortController) {
        return this.routeStore.getRegisteredUserDevicesSummary(params, abortController);
    }
}

export default RegisteredUserDevicesActivityViewStore;
