import React from "react";
import PropTypes from "prop-types";
import { MrbMenuItem } from "./";
import { observer } from "mobx-react";
import { map } from "lodash";

function MrbSecondaryMenuLevel({ menuStore, t }) {
    const { secondaryMenu } = menuStore;
    return (
        <React.Fragment>
            {secondaryMenu && secondaryMenu.length > 0 && (
                <ul className="nav__menu">
                    {map(secondaryMenu, (item) => {
                        return (
                            <MrbMenuItem
                                classNameExtend="c-nav__menu__link c-nav__link"
                                key={t(item.title)}
                                menuStore={menuStore}
                                item={item}
                                t={t}
                            />
                        );
                    })}
                </ul>
            )}
        </React.Fragment>
    );
}

MrbSecondaryMenuLevel.propTypes = {
    menuStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(MrbSecondaryMenuLevel);
