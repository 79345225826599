import { BaseService } from "mrb/core";
import { StationFileStreamApiRouteService } from "common/services";

class StationFileStreamService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new StationFileStreamApiRouteService());
    }

    createStationCoverImage(stationId, file, abortSignal = null) {
        let formData = new FormData();
        formData.append("file", file, file.name);
        const url = this.routeService.createStationCoverImage(
            stationId,
            file.name
        );
        return this.apiClient.post(url, formData, null, abortSignal);
    }

    updateStationCoverImage(stationId, file, abortSignal = null) {
        let formData = new FormData();
        formData.append("file", file, file.name);
        const url = this.routeService.update(stationId, file.name);
        return this.apiClient.put(url, formData, null, abortSignal);
    }

    get(id, params, abortSignal = null) {
        const url = this.routeService.get(id, params);
        return this.apiClient.get(url, null, abortSignal);
    }

    getStationCoverImageByStation(stationId, params, abortSignal = null) {
        const url = this.routeService.getStationCoverImageByStation(
            stationId,
            params
        );
        return this.apiClient.get(url, null, abortSignal);
    }
}

export default StationFileStreamService;
