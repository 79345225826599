import React from "react";
import { NakiNigunListenersListTemplate } from "themes/administration/analytics/components";
import { NakiNigunListenersListViewStore } from "administration/analytics/stores";
import { setCurrentView } from "mrb/core";
import { observer } from "mobx-react";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunListenersListViewStore(rootStore, componentProps),
    "nakiNigunListenersListViewStore"
)
@observer
class NakiNigunListenersList extends React.Component {
    render() {
        return <NakiNigunListenersListTemplate {...this.props} />;
    }
}

export default NakiNigunListenersList;
