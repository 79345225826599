import { observable, action, makeObservable } from "mobx";
import { isFunction } from "lodash";

class MrbModalParams {
    @observable isOpen = false;
    @observable loading = false;
    @observable data = {};
    params = {};

    constructor(options = {}, params = {}) {
        makeObservable(this);
        this.params = params;
        if (options && isFunction(options.onClose)) {
            this.onClose = options.onClose;
        }
    }

    @action.bound
    open(data) {
        if (this.isOpen) {
            return;
        }
        this.data = data;

        this.setOpen(true);
    }

    @action.bound
    close() {
        if (!this.isOpen) {
            return;
        }

        this.setOpen(false);

        if (this.onClose) {
            this.onClose();
        }
    }

    @action.bound
    setOpen(value) {
        this.isOpen = value;
    }

    @action.bound
    setLoading(loading) {
        this.loading = loading;
    }
}

export default MrbModalParams;
