import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

function MrbMenuToggle({ menuStore }) {
    return (
        <div className="nav--primary__toggle">
            <button
                className={
                    "nav--primary__toggle__c-btn" +
                    (menuStore.isMenuExpanded ? " is-expanded" : "")
                }
                type="button"
                onClick={menuStore.toggleMenu}
            >
                <span className="nav--primary__toggle__icon">
                    <span />
                    <span />
                    <span />
                </span>
            </button>
        </div>
    );
}

MrbMenuToggle.propTypes = {
    menuStore: PropTypes.object.isRequired,
};

export default observer(MrbMenuToggle);
