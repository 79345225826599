import React from "react";
import PropTypes from "prop-types";
import { I18nextProvider, useTranslation } from "react-i18next";

function ApplicationLocalization({ children, localizationService, ...props }) {
    const { ready } = useTranslation();
    return (
        <I18nextProvider i18n={localizationService}>
            {React.createElement(children, {
                ...props,
                suspendRendering: !ready,
            })}
        </I18nextProvider>
    );
}

ApplicationLocalization.propTypes = {
    App: PropTypes.any,
    props: PropTypes.object,
};

export default ApplicationLocalization;
