import React from "react";
import ContentLoader from "react-content-loader";

const DescriptionLoaderTemplate = (props) => {
    const screenwidth = window.innerWidth;
    let loaderWidth;

    if (screenwidth > 1200) {
        loaderWidth = screenwidth - 690;
    } else if (screenwidth > 988) {
        loaderWidth = screenwidth - 200;
    } else {
        loaderWidth = screenwidth - 64;
    }

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={75}
            viewBox={`0 0 ${loaderWidth} 75`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            <rect x="0" y="0" rx="6" ry="6" width="7%" height="8" />
            <rect x="0" y="25" rx="6" ry="6" width="50%" height="14" />
            <rect x="0" y="55" rx="6" ry="6" width="40%" height="14" />
        </ContentLoader>
    );
};

export default DescriptionLoaderTemplate;
