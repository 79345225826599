import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { MrbContentLoaderStore } from "mrb/components/loader";
import { MrbFileUploadViewStore } from "mrb/components/media";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";
import { head } from "lodash";

class CoverImageUploadViewStore extends MrbBaseViewStore {
    @computed get file() {
        return head(this.fileUploadStore.files);
    }

    @observable uploadedFileId = null;

    @observable.ref imageUrl = null;

    @observable hasChanged = false;

    constructor(
        rootStore,
        {
            width = 400,
            height = 400,
            acceptedFiles = "image/jpeg, image/png, image/gif",
            maxSize = 20000000,
            onFileRejected,
            errorsMap = {
                "file-invalid-type": () => {
                    return "File type must be .jpg, .png or .gif";
                },
                "file-too-large": () => {
                    return "File is larger than 20MB";
                },
            },
        } = {}
    ) {
        super(rootStore);
        makeObservable(this);
        this.fileUploadStore = new MrbFileUploadViewStore(this.rootStore, {
            acceptedFiles: acceptedFiles,
            maxSize: maxSize,
            showValidation: false,
            actions: {
                onFileRejected: onFileRejected,
            },
            errorsMap: errorsMap,
        });
        this.width = width;
        this.height = height;
        this.loaderStore = new MrbContentLoaderStore({
            suspendRenderOnInit: false,
        });

        this.reaction(
            () => this.file,
            (file) => {
                if (file) {
                    this.uploadFile(file);
                } else {
                    this.changeUploadedFileId(null);
                }
            }
        );

        this.reaction(
            () => this.uploadedFileId,
            (id) => {
                if (id) {
                    this.setImageUrl(this.getMediaVaultFileUrl(id));
                } else {
                    this.setImageUrl(null);
                }
            }
        );
    }

    @action.bound
    async uploadFile(file) {
        try {
            this.loaderStore.suspend();
            const mediaVaultFile = await this.uploadImageToMediaVault(file);
            runInAction(() => {
                this.changeUploadedFileId(mediaVaultFile.id);
                this.loaderStore.resume();
            });
        } catch (err) {
            this.rootStore.notificationStore.error(
                "Unexpected error occurred while trying to upload image. Please contact the support team.",
                err
            );
            this.loaderStore.resume();
        }
    }

    uploadImageToMediaVault(file) {
        throw new Error("Method not implemented");
    }

    getMediaVaultFileUrl(id) {
        throw new Error("Method not implemented");
    }

    @action.bound
    changeUploadedFileId(fileId) {
        this.setUploadedFileId(fileId);
        this.hasChanged = true;
    }

    @action.bound
    setUploadedFileId(fileId) {
        this.uploadedFileId = fileId;
    }

    @action.bound
    setImageUrl(url) {
        if (url) {
            this.imageUrl = this.createAuthenticatedImageUrl(url);
        } else {
            this.imageUrl = null;
        }
    }

    uploadImage = (event) => {
        this.fileUploadStore.raiseBrowse(event);
    };

    remove = () => {
        this.setImageUrl(null);
        this.changeUploadedFileId(null);
        this.fileUploadStore.clear();
    };

    createAuthenticatedImageUrl = (baseUrl) => {
        return createBaasicAuthenticatedMediaVaultUrl(this.rootStore, baseUrl, {
            width: this.width,
            height: this.height,
        });
    };
}

export default CoverImageUploadViewStore;
