import { observable, action, makeObservable } from "mobx";
import { isNil } from "lodash";

class MrbFilterParameters {
    @observable pageNumber;
    @observable pageSize;
    @observable orderBy;
    @observable orderDirection;
    @observable embed;
    @observable search;
    @observable fields;

    constructor() {
        makeObservable(this);
        this.reset();
    }

    @action.bound
    reset() {
        this.pageNumber = 1;
        this.pageSize = 10;
        this.orderBy = null;
        this.orderDirection = null;
        this.embed = null;
        this.search = null;
        this.fields = null;
    }

    @action.bound
    set(key, value) {
        this[key] = value;
    }

    @action.bound
    toggle(key) {
        const property = this[key];
        const evalValue = !isNil(property) ? property : false;
        if (typeof evalValue === "boolean") {
            this[key] = !property;
        }
    }
}

export default MrbFilterParameters;
