import React from "react";

function MrbTableNoDataOverlay() {
    return (
        <div className="c-empty-state c-empty-state--table">
            <h3 className="c-empty-state__title">
                Ooops! Someone cut the cable.
            </h3>
            <p className="c-empty-state__desc">There is no data.</p>
        </div>
    );
}

export default MrbTableNoDataOverlay;
