import { BaseService } from "mrb/core";
import { UserStationExpirationApiRouteService } from "common/services";

class UserStationExpirationService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new UserStationExpirationApiRouteService());
    }
}

export default UserStationExpirationService;
