import { BaseApiRouteService } from "mrb/core";

class StationAnalyticsApiRouteService extends BaseApiRouteService {
    constructor() {
        super("analytics/stations");
    }

    getStationPremiumStatistic(id, options) {
        return super.get(`${this.base}/{id}/premium-statistic{?from,to,page,rpp}`, id, options);
    }

    getTrendingStations(filter) {
        return super.find(`${this.base}/{?from,to,previousCycleFrom,previousCycleTo,timeZone,page,rpp}`, filter);
    }

    getStationActivity(id, options) {
        return super.get(`${this.base}/{id}{?from,to,previousCycleFrom,previousCycleTo,timeZone}`, id, options);
    }

    getStationStats(id, options) {
        return super.get(`${this.base}/{id}/stats{?from,to,previousCycleFrom,previousCycleTo,timeZone}`, id, options);
    }

    getStationSummary(id, options) {
        return super.get(
            `${this.base}/{id}/summary{?from,to,previousCycleFrom,previousCycleTo,embed,fields}`,
            id,
            options
        );
    }

    getStationListenersStats() {
        return super.get(`${this.base}/listeners`);
    }
}

export default StationAnalyticsApiRouteService;
