import React from "react";
import { PropTypes } from "prop-types";
import { currencyFormatter } from "mrb/common/formatting";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { StripeAccountLink } from "common/components/stripe";
import { map } from "lodash";
import { defaultTemplate } from "common/hoc";
import { MrbContentLoader } from "mrb/components/loader";
import { ProducerAccountListLoader } from "administration/producer/components";

function AdminProducerAccountsListTemplate({ adminProducerAccountsListViewStore, t }) {
    const { accounts, producerId, selectAccount, loaderStore } = adminProducerAccountsListViewStore;

    return (
        <div className="u-mar--bottom--sml">
            <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <ProducerAccountListLoader />}>
                {() => (
                    <React.Fragment>
                        <div>
                            <MrbRouterLink
                                className="c-analytics__title"
                                routeName="master.administration.producer.accounts"
                                params={{ id: producerId }}
                            >
                                {t("ADMINISTRATION.PRODUCER.PAYMENT.PREVIEW.ACCOUNTS.TITLE")}
                                <i className="u-icon u-icon--base u-icon--arrow-right u-mar--left--tny" />
                            </MrbRouterLink>
                        </div>
                        <div className="c-card--table">
                            <table className="c-table c-table--primary">
                                <thead className="c-table--primary__thead">
                                    <tr className="c-table--primary__tr">
                                        <th className="c-table--primary__th u-type--base" colSpan="2">
                                            {t("ADMINISTRATION.PRODUCER.PAYMENT.PREVIEW.ACCOUNTS.COLUMNS.USER")}
                                        </th>
                                        <th className="c-table--primary__th u-type--base" colSpan="2">
                                            {t(
                                                "ADMINISTRATION.PRODUCER.PAYMENT.PREVIEW.ACCOUNTS.COLUMNS.STRIPE_CONNECT"
                                            )}
                                        </th>
                                        <th className="c-table--primary__th u-type--base">
                                            {t(
                                                "ADMINISTRATION.PRODUCER.PAYMENT.PREVIEW.ACCOUNTS.COLUMNS.REVENUE_PERCENTAGE"
                                            )}
                                        </th>
                                        <th className="c-table--primary__th u-type--base">
                                            {t(
                                                "ADMINISTRATION.PRODUCER.PAYMENT.PREVIEW.ACCOUNTS.COLUMNS.ACCOUNT_BALANCE"
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="c-table--primary__tbody c-table--primary__tbody">
                                    {accounts.length === 0 ? (
                                        <tr className="c-table--primary__tr">
                                            <td colSpan="6" className="c-table--primary__td">
                                                <NoDataEmptyState producerId={producerId} t={t} />
                                            </td>
                                        </tr>
                                    ) : (
                                        map(accounts, (account) => (
                                            <tr
                                                key={Math.random()}
                                                className="c-table--primary__tr u-cursor--pointer"
                                                onClick={() => selectAccount(account)}
                                            >
                                                <td className="c-table--primary__td u-type--sml" colSpan="2">
                                                    {account.firstName} {account.lastName} ({account.email})
                                                </td>
                                                <td className="c-table--primary__td u-type--sml" colSpan="2">
                                                    {account.stripeAccountId && (
                                                        <StripeAccountLink
                                                            accountId={account.stripeAccountId}
                                                            classNameExtend="u-type--sml"
                                                            t={t}
                                                        />
                                                    )}
                                                </td>
                                                <td className="c-table--primary__td u-type--sml">
                                                    {account.revenuePercentage} %
                                                </td>
                                                <td className="c-table--primary__td u-type--sml">
                                                    {currencyFormatter.format(account.accountBalance, {
                                                        currency: "USD",
                                                    })}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </MrbContentLoader>
        </div>
    );
}

AdminProducerAccountsListTemplate.propTypes = {
    adminProducerAccountsListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function NoDataEmptyState({ producerId, t }) {
    return (
        <div className="c-card c-card--med c-card--primary">
            <div className="c-empty-state--no-results c-empty-state--center">
                <div className="u-mar--bottom--med">
                    <h3>{t("Add new account")}</h3>
                    <p>{t("In order to add a new account, please click the button below.")}</p>
                </div>
                <MrbRouterLink
                    className="c-btn c-btn--base c-btn--primary"
                    routeName="master.administration.producer.accounts"
                    params={{ id: producerId }}
                >
                    Add New
                </MrbRouterLink>
            </div>
        </div>
    );
}

export default defaultTemplate(AdminProducerAccountsListTemplate);
