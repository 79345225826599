import { action, observable, makeObservable } from "mobx";
import { MrbModalParams } from "./";
import { isFunction } from "lodash";

class MrbConfirmModalParams extends MrbModalParams {
    @observable message = null;

    constructor({
        message,
        description,
        onConfirm,
        onCancel,
        yesLabel,
        noLabel,
        ...rest
    }) {
        super(rest);
        makeObservable(this);
        this.message = message;
        this.description = description;
        this.onConfirm = onConfirm;
        this.onCancel = onCancel;
        this.yesLabel = yesLabel;
        this.noLabel = noLabel;
    }

    @action.bound
    async confirm() {
        this.setLoading(true);
        await this.onConfirm(this.data);
        this.setLoading(false);
        this.close();
    }

    @action.bound
    cancel() {
        if (isFunction(this.onCancel)) {
            this.onCancel();
        }
        this.close();
    }
}

export default MrbConfirmModalParams;
