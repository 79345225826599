import { BaseApiRouteService } from "mrb/core";

class NakiNigunArtistApiRouteService extends BaseApiRouteService {
    constructor() {
        super("naki-nigun/artists");
    }

    find(filter) {
        return super.find(`${this.base}/{?searchQuery,page,rpp,sort,embed,fields}`, filter);
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?embed,fields}`, id, options);
    }

    create(resource) {
        return super.create(this.base, resource);
    }

    update(resource) {
        return super.update(`${this.base}/{id}`, resource);
    }

    delete(resource) {
        return super.delete(`${this.base}/{id}`, resource);
    }

    updateActiveStatus(id, value) {
        return super.update(`${this.base}/{id}/active{?value}`, {
            id: id,
            value: value,
        });
    }

    updatePremiumStatus(id, value) {
        return super.update(`${this.base}/{id}/premium{?value}`, {
            id: id,
            value: value,
        });
    }

    updateVisibilityStatus(id, value) {
        return super.update(`${this.base}/{id}/visibility{?value}`, {
            id: id,
            value: value,
        });
    }

    updateMobileAppVisibilityStatus(id, value) {
        return super.update(`${this.base}/{id}/mobile-app-visibility{?value}`, {
            id: id,
            value: value,
        });
    }

    updateNakiTouchVisibilityStatus(id, value) {
        return super.update(`${this.base}/{id}/embedded-app-visibility{?value}`, {
            id: id,
            value: value,
        });
    }

    updateFeaturedStatus(id, value) {
        return super.update(`${this.base}/{id}/featured{?value}`, {
            id: id,
            value: value,
        });
    }

    findArtists(filter) {
        return super.find(`${this.base}{?page,searchQuery,rpp,sort,embed,fields}`, filter);
    }
}

export default NakiNigunArtistApiRouteService;
