import { RssSyncService } from "common/services";
import { filter, isNil } from "lodash";
import { action, computed, observable, makeObservable, reaction } from "mobx";

class ContentEncoderSyncStore {
    @observable podcastEpisodeTasks = [];
    @observable albumTrackTasks = [];
    _rssSyncService = null;

    @computed get numberOfActiveTasks() {
        return this.podcastEpisodeTasks.length + this.albumTrackTasks.length;
    }

    @computed get isSyncing() {
        return this.numberOfActiveTasks > 0;
    }

    get rssSyncService() {
        if (isNil(this._rssSyncService)) {
            this._rssSyncService = this.rootStore.createApplicationService(RssSyncService);
        }
        return this._rssSyncService;
    }

    _tickDelay = 15000;
    _tickIntervalInstance = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeObservable(this);

        reaction(
            () => this.rootStore.authenticationStore.isAuthenticated,
            (isAuthenticated) => {
                if (isAuthenticated) {
                    this._sync();
                    this.startSync();
                } else {
                    this.stopSync();
                    this._clear();
                }
            }
        );
    }

    startSync() {
        this._tickIntervalInstance = setInterval(() => this._sync(), this._tickDelay);
    }

    resetSync() {
        this.stopSync();
        this._sync();
        this.startSync();
    }

    stopSync() {
        if (this._tickIntervalInstance != null) {
            clearInterval(this._tickIntervalInstance);
            this._tickIntervalInstance = null;
        }
    }

    async _sync() {
        try {
            const response = await this.rssSyncService.getAllRssSyncTasks();
            const tasks = response.data;

            const podcastEpisodeTasks = filter(tasks, (task) => task.podcastEpisodeId);
            const albumTrackTasks = filter(tasks, (task) => task.trackId);

            this.setPodcastEpisodeTasks(podcastEpisodeTasks);
            this.setAlbumTrackTasks(albumTrackTasks);
        } catch (err) {}
    }

    @action.bound
    setPodcastEpisodeTasks(items) {
        this.podcastEpisodeTasks = items;
    }

    @action.bound
    setAlbumTrackTasks(items) {
        this.albumTrackTasks = items;
    }

    @action.bound
    _clear() {
        this.setAlbumTrackTasks([]);
        this.setPodcastEpisodeTasks([]);
    }
}

export default ContentEncoderSyncStore;
