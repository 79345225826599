import React from "react";
import { PropTypes } from "prop-types";
import { mergeOrOverrideCss } from "../../../common/utils";

function MrbPageContentFooter({
    classNameOverride,
    classNameExtend,
    children,
}) {
    return (
        <div
            className={mergeOrOverrideCss(
                "c-content__footer",
                classNameOverride,
                classNameExtend
            )}
        >
            {children}
        </div>
    );
}

MrbPageContentFooter.propTypes = {
    classNameOverride: PropTypes.string,
    classNameExtend: PropTypes.string,
    children: PropTypes.any,
};

export default MrbPageContentFooter;
