import React from "react";
import { PropTypes } from "prop-types";
import { AnalyticsLayout } from "administration/analytics/layouts";
import {
    AnalyticsCounter,
    AnalyticsGraphLoader,
    PercentageIncreaseDonutChart,
    PortalDetailedActivityLoader,
} from "administration/analytics/components";
import { MrbContentLoader } from "mrb/components/loader";
import { calculatePercantageIncrease, numberFormatter } from "common/utils";
import { LineChart } from "common/components";
import { timeRangeLabelParser } from "administration/analytics/utils";
import { observer } from "mobx-react";

function PortalDetailedActivityTemplate({ store, activityLabel, totalLabel, nameFieldRenderer, t }) {
    const { analyticsTimeRangeViewStore, chartStore } = store;
    return (
        <AnalyticsLayout analyticsTimeRangeViewStore={analyticsTimeRangeViewStore} t={t}>
            <div className="c-container--sidebar u-padd--none">
                <div className="order-1">
                    <h1 className="u-type--base u-mar--bottom--sml">{activityLabel || "Activity"}</h1>
                    <div className="c-card c-card--primary">
                        <LineChart store={chartStore} loaderRenderer={() => <AnalyticsGraphLoader />} />
                    </div>
                </div>

                <div className="c-container--sml order-2">
                    <Summary store={store} totalLabel={totalLabel} nameFieldRenderer={nameFieldRenderer} />
                </div>
            </div>
        </AnalyticsLayout>
    );
}

PortalDetailedActivityTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    nameFieldRenderer: PropTypes.func,
    t: PropTypes.func.isRequired,
};

const Summary = observer(function Summary({ store, totalLabel, nameFieldRenderer }) {
    const { summaryData, loaderStore } = store;

    return (
        <div>
            <h1 className="u-type--base u-mar--bottom--sml">Summary</h1>
            <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <PortalDetailedActivityLoader />}>
                {() => (
                    <div className="u-animation--fadeIn">
                        {summaryData && (
                            <div className="c-card--primary c-card--med">
                                {nameFieldRenderer && (
                                    <div className="u-mar--bottom--sml">{nameFieldRenderer({ summaryData })}</div>
                                )}
                                <div className="u-display--flex u-display--flex--jc--space-between">
                                    <div>
                                        <div className="u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">{totalLabel || "Total"}</p>
                                            <p>{numberFormatter.format(summaryData.total)}</p>
                                        </div>
                                        <div className="u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {timeRangeLabelParser.createCurrentCycleLabel(
                                                    summaryData.filteredBy.range,
                                                    summaryData.filteredBy.from,
                                                    summaryData.filteredBy.to,
                                                    summaryData.filteredBy.timeZone
                                                )}
                                            </p>
                                            <p>{numberFormatter.format(summaryData.currentCycleTotal)}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <PercentageIncreaseDonutChart
                                            value={calculatePercantageIncrease(
                                                summaryData.currentCycleTotal,
                                                summaryData.previousCycleTotal
                                            )}
                                            chartSize="c-chart--sml"
                                        />
                                    </div>
                                </div>
                                <div className="u-display--flex u-display--flex--jc--space-between">
                                    <div>
                                        <p className="u-type--sml u-type--color--opaque">
                                            {timeRangeLabelParser.createPreviousCycleLabel(
                                                summaryData.filteredBy.range,
                                                summaryData.filteredBy.previousCycleFrom,
                                                summaryData.filteredBy.previousCycleTo,
                                                summaryData.filteredBy.timeZone
                                            )}{" "}
                                            (Previous period)
                                        </p>
                                        <p>{numberFormatter.format(summaryData.previousCycleTotal)}</p>
                                    </div>
                                    <AnalyticsCounter
                                        filteredBy={summaryData.filteredBy}
                                        currentCycleNumber={summaryData.currentCycleTotal}
                                        previousCycleNumber={summaryData.previousCycleTotal}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </MrbContentLoader>
        </div>
    );
});

export default observer(PortalDetailedActivityTemplate);
