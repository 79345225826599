function arrayDifference(lhsArray, rhsArray) {
    if (!lhsArray && !rhsArray) {
        return [];
    } else if (!lhsArray && rhsArray && rhsArray.length > 0) {
        return [...rhsArray];
    } else if (lhsArray && lhsArray.length > 0 && !rhsArray) {
        return [...lhsArray];
    }

    const difference = lhsArray
        .filter((x) => !rhsArray.includes(x))
        .concat(rhsArray.filter((x) => !lhsArray.includes(x)));

    return difference;
}

export default arrayDifference;
