import { BaseApiRouteService } from "mrb/core";

class ShuffleWeightApiRouteService extends BaseApiRouteService {
    constructor() {
        super("lookups/shuffle-weights");
    }

    get() {
        return super.get(this.base);
    }
}

export default ShuffleWeightApiRouteService;
