import React from "react";
import { PropTypes } from "prop-types";
import { numberFormatter, producerPlaybackTimeConverter } from "common/utils";
import { dateFormatter, currencyFormatter } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";
import { MrbContentLoader } from "mrb/components/loader";
import { isProducerReportComplete } from "common/utils";
import { ProducerLabel, ProducerSummaryLoader } from "common/components/producer";
import { observer } from "mobx-react";
import { isNil } from "lodash";

function ProducerPaymentReportSummaryTemplate({ producerPaymentReportPreviewViewStore, t }) {
    const { summaryData, loaderStore } = producerPaymentReportPreviewViewStore;

    return (
        <React.Fragment>
            <h1 className="u-type--base u-mar--bottom--sml">Summary</h1>
            <div className="c-card--primary c-card--med">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <ProducerSummaryLoader isDetails />}>
                    {() => (
                        <React.Fragment>
                            {summaryData && (
                                <React.Fragment>
                                    <div className="u-animation--fadeIn">
                                        <div className="u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">Producer</p>
                                            <p className="u-display--flex u-display--flex--ai--center">
                                                <ProducerLabel
                                                    name={summaryData.producerName}
                                                    isUser={!isNil(summaryData.userId)}
                                                />
                                            </p>
                                        </div>
                                        {summaryData.currentMonthTotalProducerPlaybacks !== null &&
                                            summaryData.currentMonthTotalProducerPlaybacks !== undefined && (
                                                <div className="u-mar--bottom--sml">
                                                    <p className="u-type--sml u-type--color--opaque">Period</p>
                                                    <p className="u-display--flex u-display--flex--ai--center">
                                                        <ReportPeriodRange summaryData={summaryData} />
                                                    </p>
                                                </div>
                                            )}
                                        <div className="u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                <MonthOfReportLabel summaryData={summaryData} />
                                            </p>
                                            <div className="c-dashboard__count--increase">
                                                <p className="c-dashboard__count--lrg">
                                                    {currencyFormatter.format(
                                                        summaryData.currentMonthTotalProducerPayment,
                                                        {
                                                            currency: "USD",
                                                        }
                                                    )}
                                                </p>
                                            </div>
                                        </div>

                                        <TotalPlaybackTime summaryData={summaryData} />

                                        <div>
                                            <p className="u-type--sml u-type--color--opaque">Total Playback Points</p>
                                            <p className="u-type--med u-type--wgt--bold u-type--color--tertiary">
                                                {numberFormatter.format(summaryData.currentMonthTotalProducerPlaybacks)}
                                            </p>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
        </React.Fragment>
    );
}

ProducerPaymentReportSummaryTemplate.propTypes = {
    producerPaymentReportPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function TotalPlaybackTime({ summaryData }) {
    const totalPlaybackTime = producerPlaybackTimeConverter(
        summaryData.currentMonthReportVersion,
        summaryData.currentMonthTotalProducerPlaybacks
    );
    if (totalPlaybackTime) {
        return (
            <div className="u-mar--bottom--sml">
                <p className="u-type--sml u-type--color--opaque">Total Playback Time</p>
                <p className="u-type--med u-type--wgt--bold">{totalPlaybackTime}</p>
            </div>
        );
    }

    return null;
}

function MonthOfReportLabel({ summaryData }) {
    const monthLabel = dateFormatter.format(summaryData.filteredBy.date, {
        format: applicationDefaults.formats.monthFull,
    });
    return monthLabel;
}

function ReportPeriodRange({ summaryData }) {
    const startDate = dateFormatter.format(summaryData.currentMonthPeriodStartDate, {
        format: applicationDefaults.formats.date,
        timeZone: applicationDefaults.defaultProductReportTimeZone,
    });
    const endDate = dateFormatter.format(summaryData.currentMonthPeriodEndDate, {
        format: applicationDefaults.formats.date,
        timeZone: applicationDefaults.defaultProductReportTimeZone,
    });
    const isComplete = isProducerReportComplete(summaryData.filteredBy.date, summaryData.currentMonthPeriodEndDate);
    return (
        <React.Fragment>
            {startDate} - {endDate}{" "}
            {!isComplete ? <span className="u-mar--left--tny u-type--color--opaque">(In Progress)</span> : null}
        </React.Fragment>
    );
}

export default observer(ProducerPaymentReportSummaryTemplate);
