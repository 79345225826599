import { BaseApiRouteService } from "mrb/core";

class NakiNigunAlbumFileStreamApiRouteService extends BaseApiRouteService {
    constructor() {
        super("naki-nigun/album-file-streams");
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?width,height}`, id, options);
    }

    getAlbumCoverImageByAlbum(id, options) {
        return super.get(`${this.base}/album-cover/{id}{?width,height}`, id, options);
    }

    getAlbumTrackAudioFile(id) {
        return super.get(`${this.base}/track/{id}`, id);
    }
}

export default NakiNigunAlbumFileStreamApiRouteService;
