import { LocalizedForm } from "common/localization";

export default class AdminProducerAccountsEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {};
    }
}
