import { BaseApiRouteService } from "mrb/core";

class StationApiRouteService extends BaseApiRouteService {
    constructor() {
        super("stations");
    }

    find(filter) {
        return super.find(`${this.base}/{?searchQuery,shuffleWeightIds,page,rpp,sort,embed,fields}`, filter);
    }

    findShufflableStation(filter) {
        return super.create(`${this.base}/shufflable-station`, filter);
    }

    findDeviceStations(filter) {
        return super.find(`${this.base}/device-stations/{?deviceId,searchQuery,page,rpp,sort}`, filter);
    }

    updateExcludedFromRevenueStatus(id, excludedFromRevenue) {
        return super.update(`${this.base}/{id}/excluded-from-revenue{?value}`, { id: id, value: excludedFromRevenue });
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?embed,fields}`, id, options);
    }

    create(resource) {
        return super.create(this.base, resource);
    }

    update(resource) {
        return super.update(`${this.base}/{id}`, resource);
    }

    delete(resource) {
        return super.delete(`${this.base}/{id}`, resource);
    }

    updateActiveStatus(id, value) {
        return super.update(`${this.base}/{id}/active{?value}`, {
            id: id,
            value: value,
        });
    }

    updateOnByDefaultStatus(id, value) {
        return super.update(`${this.base}/{id}/on-by-default{?value}`, {
            id: id,
            value: value,
        });
    }

    updateOnByDefaultInAppStatus(id, value) {
        return super.update(`${this.base}/{id}/on-by-default-in-app{?value}`, {
            id: id,
            value: value,
        });
    }

    updateOnByDefaultInNakiTouchStatus(id, value) {
        return super.update(`${this.base}/{id}/on-by-default-in-embedded-app{?value}`, {
            id: id,
            value: value,
        });
    }

    updatePremiumStatus(id, value) {
        return super.update(`${this.base}/{id}/premium{?value}`, {
            id: id,
            value: value,
        });
    }

    updateVisibilityStatus(id, value) {
        return super.update(`${this.base}/{id}/visibility{?value}`, {
            id: id,
            value: value,
        });
    }

    updateAppVisibilityStatus(id, value) {
        return super.update(`${this.base}/{id}/app-visibility{?value}`, {
            id: id,
            value: value,
        });
    }

    updateNakiTouchVisibilityStatus(id, value) {
        return super.update(`${this.base}/{id}/embedded-app-visibility{?value}`, {
            id: id,
            value: value,
        });
    }

    updateStationTurnedOnStatus(deviceId, stationId, value) {
        return super.update(`${this.base}/{stationId}/device/{deviceId}/turned-on{?value}`, {
            deviceId: deviceId,
            stationId: stationId,
            value: value,
        });
    }

    updateFeaturedStatus(id, value) {
        return super.update(`${this.base}/{id}/featured{?value}`, {
            id: id,
            value: value,
        });
    }
}

export default StationApiRouteService;
