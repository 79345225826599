import { BaseApiRouteService } from "mrb/core";

class PodcastFileStreamApiRouteService extends BaseApiRouteService {
    constructor() {
        super("podcast-file-streams");
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?width,height}`, id, options);
    }

    getPodcastCoverImageByPodcast(id, options) {
        return super.get(`${this.base}/podcast-cover/{id}{?width,height}`, id, options);
    }

    getPodcastEpisodeAudioFile(id) {
        return super.get(`${this.base}/episode/{id}`, id);
    }
}

export default PodcastFileStreamApiRouteService;
