import { moduleProviderFactory } from "./";
import { each } from "lodash";

class ApplicationBootstrapper {
    run = () => {
        return this.getConfigurations();
    };

    getConfigurations = (moduleNames) => {
        const modules = moduleProviderFactory.find(moduleNames);
        let routes = [];
        let menus = [];
        let stores = [];

        each(modules, (module) => {
            routes.push({
                module: module.name,
                items: module.getRoutes(),
            });
            menus.push({
                module: module.name,
                items: module.getMenus(),
            });
            stores.push({
                module: module.name,
                items: module.getStores(),
            });
        });

        return {
            routes,
            menus,
            stores,
        };
    };
}

const applicationBootstrapper = new ApplicationBootstrapper();
export default applicationBootstrapper;
