import { BaseService } from "mrb/core";
import { ShuffleApiRouteService } from "common/services";

class ShuffleService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new ShuffleApiRouteService());
    }

    createStation(resource, abortSignal = null) {
        const url = this.routeService.createStation(resource);
        return this.apiClient.post(url, resource, null, abortSignal);
    }

    createPodcast(resource, abortSignal = null) {
        const url = this.routeService.createPodcast(resource);
        return this.apiClient.post(url, resource, null, abortSignal);
    }

    createNakiNigun(resource, abortSignal = null) {
        const url = this.routeService.createNakiNigun(resource);
        return this.apiClient.post(url, resource, null, abortSignal);
    }
}

export default ShuffleService;
