import { LocalizedForm } from "common/localization";

export default class AdminProducerAccountStripeAccountEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "stripeAccountId",
                    label: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.STRIPE_ACCOUNT_LABEL",
                    placeholder: "ADMINISTRATION.PRODUCER.ACCOUNT.EDIT.FIELDS.STRIPE_ACCOUNT_PLACEHOLDER",
                    rules: "string",
                },
            ],
        };
    }
}
