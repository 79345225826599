import { BaseCacheService } from "mrb/core/cache";
import { ShuffleWeightService } from "common/services";

class ShuffleWeightCacheService extends BaseCacheService {
    constructor(rootStore) {
        super("shuffle-weight", "lookup", async () => {
            const response = await this.service.get();
            return response.data;
        });
        this.service = rootStore.createApplicationService(ShuffleWeightService);
    }
}

export default ShuffleWeightCacheService;
