import React from "react";
import { PropTypes } from "prop-types";
import { MrbModal } from "mrb/components/modal";
import { MrbButton } from "mrb/components/button";

function ContentPolicyModalTemplate({ modalParams, t }) {
    const description =
        "While all items available on the NakiRadio Portal have been determined by our guidance to have a place within appropriate public discourse, some shows may not be intended for all ages or conform to all ideological inclinations. In its default state, only items whose intended audience includes children are available on the NakiRadio. We encourage the user to use discretion when installing additional items on the NakiRadio, along with visiting their respective websites for details on content, programming, and intended audience. For more information on a given show, please click the show’s title in the Portal.";
    return (
        <MrbModal modalParams={modalParams} className="modal__content modal__content--secondary">
            <div>
                <div className="modal__header">
                    <h2>Content Policy</h2>
                </div>
                <div className="modal__body">
                    <p className="u-type--base">{description}</p>
                </div>
                <div className="modal__footer--secondary">
                    <MrbButton t={t} label="Don't show again" onClick={modalParams.params.disableContentPolicy} classNameOverride="c-btn c-btn--base c-btn--anchor c-btn--anchor--underline" />
                    <MrbButton t={t} label="Close" onClick={modalParams.close} />
                </div>
            </div>
        </MrbModal>
    );
}

ContentPolicyModalTemplate.propTypes = {
    modalParams: PropTypes.object,
    t: PropTypes.func,
};

export default ContentPolicyModalTemplate;
