import { isArray, last, initial } from "lodash";
import { isSome } from "../../../common/utils";

class StateRouteOptions {
    constructor(route, parent) {
        this.layouts = [];
        this.component = [];
        this.name = route.name;
        this.pattern = route.pattern;
        this.authorization = route.authorization;
        this.data = route.data;
        this.parent = parent;
        this.isPublic = route.isPublic;
        this.appendComponents(route);
        this.store = route.store;

        this.hookCondition = route.hookCondition;
        this.beforeExit = route.beforeExit;
        this.beforeEnter = route.beforeEnter;
        this.onExit = route.onExit;
        this.onEnter = route.onEnter;
    }

    appendComponents = (route) => {
        if (isSome(route.component)) {
            if (route.children && route.children.length > 0) {
                if (isArray(route.component)) {
                    this.layouts = route.component;
                } else {
                    this.layouts = [route.component];
                }
            } else {
                if (isArray(route.component)) {
                    if (route.component.length === 1) {
                        this.component = route.component[0];
                    } else if (route.component.length > 1) {
                        this.component = last(route.component);
                        this.layouts = initial(route.component);
                    }
                } else {
                    this.component = route.component;
                }
            }
        }
    };
}

export default StateRouteOptions;
