import React from "react";
import { UserContentListSummaryTemplate } from "themes/administration/analytics/components";

class UserContentListSummary extends React.Component {
    render() {
        return <UserContentListSummaryTemplate {...this.props} />;
    }
}

export default UserContentListSummary;
