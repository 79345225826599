import { BaseCacheManager } from "./";
import { localStorageProvider } from "../../common/storage";

class MrbCacheManager extends BaseCacheManager {
    constructor() {
        super(localStorageProvider);
    }
}

const mrbCacheManager = new MrbCacheManager();
export default mrbCacheManager;
