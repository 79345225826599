import { applicationDefaults } from "common/constants";
import moment from "moment";
import "moment-timezone";

function isProducerReportComplete(reportForMonth, periodEndDate) {
    const endOfMonth = moment.utc(reportForMonth).endOf("month");
    const endDate = moment.utc(periodEndDate).tz(applicationDefaults.defaultProductReportTimeZone);
    const isCompleted = endDate.isSame(endOfMonth, "day");

    return isCompleted;
}

export default isProducerReportComplete;
