import { action, computed, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseInfiniteScrollListViewStore } from "mrb/core";
import { MrbInfiniteScrollGridViewStore } from "mrb/components/infinite-scroll-grid";
import { ProducerPaymentReportFilter } from "administration/producer/filters";
import { applicationDefaults } from "common/constants";
import moment from "moment";

class BaseProducerRevenueReportViewStore extends MrbBaseInfiniteScrollListViewStore {
    _abortController = null;
    maskUserNames = false;
    @observable.ref containerRef = null;
    @observable.ref summaryData = null;

    @computed get selectedDate() {
        return moment.tz(this.queryUtility.filter.monthOfYear, this.queryUtility.filter.timeZone).toISOString();
    }

    constructor(rootStore, { maskUserNames = false }) {
        super(rootStore, {
            queryConfig: {
                ignoreQueryParams: ["page", "timeZone", "rpp", "orderBy", "producerId"],
                filter: new ProducerPaymentReportFilter(),
                pageSize: 30,
                embed: "user,subscriptionPlan",
                orderBy: "producerPayment",
                orderDirection: "desc",
            },
        });
        makeObservable(this);

        this.maskUserNames = maskUserNames;

        this.setGridStore(
            new MrbInfiniteScrollGridViewStore(this.rootStore, this.queryUtility, {
                generateKey: (item) => item.userId,
            })
        );

        this.applyFilter = this.applyFilter.bind(this);
    }

    async onInit() {
        await Promise.all([this.queryUtility.initialize(), this.fetchSummaryData(this.queryUtility.filter)]);
    }

    onFetchError(err) {
        this.rootStore.notificationStore.error(
            "An unexpected error occurred while trying to load data. Please contact the support team.",
            err
        );
    }

    @action.bound
    setFromDate(value) {
        const selectedDate = moment.utc(value).tz(this.queryUtility.filter.timeZone);
        this.queryUtility.filter.monthOfYear = selectedDate
            .startOf("month")
            .format(applicationDefaults.formats.isoDate);
    }

    async fetchSummaryData(filter) {
        this.rootStore.mainLoaderStore.suspend();
        this.loaderStore.suspend();
        try {
            const data = await this.getSumaryData(filter);
            data.filteredBy = {
                date: filter.monthOfYear,
                timeZone: filter.timeZone,
            };
            runInAction(() => {
                this.setSummaryData(data);
                this.loaderStore.resume();
                this.rootStore.mainLoaderStore.resume();
            });
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team.",
                err
            );
            runInAction(() => {
                this.loaderStore.resume();
                this.rootStore.mainLoaderStore.resume();
            });
        }
    }

    getSumaryData(params) {
        throw new Error("Method not implemented.");
    }

    @action.bound
    setSummaryData(data) {
        this.summaryData = data;
    }

    @action.bound
    setContainerRef(ref) {
        this.containerRef = ref;
    }

    async applyFilter() {
        await Promise.all([this.queryUtility.fetch(), this.fetchSummaryData(this.queryUtility.filter)]);
    }
}

export default BaseProducerRevenueReportViewStore;
