import React from "react";
import { PropTypes } from "prop-types";
import { ContentSyncWidget } from "administration/content-sync/components";
import { observer } from "mobx-react";

function AdministrationWidgetPanelTemplate({ rootStore, t }) {
    const { userStore } = rootStore;
    if (!userStore.isAdministrator) {
        return null;
    }

    return (
        <React.Fragment>
            <ContentSyncWidget contentEncoderSyncStore={rootStore.contentEncoderSyncStore} t={t} />
        </React.Fragment>
    );
}

AdministrationWidgetPanelTemplate.propTypes = {
    rootStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(AdministrationWidgetPanelTemplate);
