import { action, observable } from "mobx";

class NavigationOptions {
    @observable loading = false;
    @observable title = null;

    @action setTitle = (title) => {
        this.title = title;
    };

    @action suspend = () => {
        this.loading = true;
    };

    @action resume = () => {
        this.loading = false;
    };
}

export default NavigationOptions;
