import React from "react";
import { AdminProducerAccountStripeAccountEditTemplate } from "themes/administration/producer/components";
import { AdminProducerAccountStripeAccountEditViewStore } from "administration/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new AdminProducerAccountStripeAccountEditViewStore(rootStore, componentProps),
    "adminProducerAccountStripeAccountEditViewStore"
)
class AdminProducerAccountStripeAccountEdit extends React.Component {
    render() {
        return <AdminProducerAccountStripeAccountEditTemplate {...this.props} />;
    }
}

export default AdminProducerAccountStripeAccountEdit;
