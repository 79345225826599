import {
    createRouterState,
    HistoryAdapter,
    browserHistory,
} from "mobx-state-router";
import { action, computed, observable, makeObservable } from "mobx";
import { moduleBuilder } from "../infrastructure";
import { getDefaultRouteDataMap, MrbRouter } from "../infrastructure/router";
import { isObject, find, forOwn, merge, some } from "lodash";

class MrbRouterStore {
    initialState = new createRouterState("master.administration.dashboard");
    loginState = new createRouterState("master.public.membership.login");
    errorState = new createRouterState("master.error");
    unauthorizedState = new createRouterState("master.unauthorized");
    notFoundState = new createRouterState("master.not-found");

    router = null;
    historyAdapter = null;
    transitionData = {};

    // route data definition per route
    // map in format: <route-name> : <{ title, crumbs: [{ title, route }]}>
    @observable.ref routeDataMap = observable.map([]);
    @observable isRouterInitialized = false;

    get routes() {
        return this.router.routes;
    }

    @computed get routerState() {
        return this.router.routerState;
    }

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action.bound
    initialize(configuredRoutes) {
        if (!this.isRouterInitialized) {
            const { routes, routerMaps } = moduleBuilder.buildRoutes(
                configuredRoutes
            );
            this.router = new MrbRouter(routes, this.notFoundState, {
                rootStore: this.rootStore,
            });
            this.routeDataMap = getDefaultRouteDataMap(routes);
            this.routerMaps = routerMaps;

            this.historyAdapter = new HistoryAdapter(
                this.router,
                browserHistory
            );
            this.historyAdapter.observeRouterStateChanges();

            this.isRouterInitialized = true;
        }
    }

    async goTo(route, params, queryParams, options = null) {
        let newRoute;
        let newParams;
        let newQueryParams;
        if (isObject(route)) {
            newRoute = route.routeName;
            newParams = route.params;
            newQueryParams = route.queryParams;
        } else {
            newRoute = route;
            newParams = params;
            newQueryParams = queryParams;
        }

        newParams = newParams || {};
        newQueryParams = newQueryParams || {};
        if (options) {
            this.transitionData = {
                ...this.transitionData,
                ...options,
            };
        }

        // if new params don't have application identifier, old state had it and we are still on 'master.app' route, just
        // reuse old identifier to avoid whole application initialization logic
        // if (_.startsWith(newRoute, 'master.app') && !newParams.applicationIdentifier && this.routerState.params.applicationIdentifier) {
        //     newParams = {
        //         ...newParams,
        //         applicationIdentifier: this.routerState.params.applicationIdentifier
        //     }
        // }

        const newState = await this.router.goTo(newRoute, {
            params: newParams,
            queryParams: newQueryParams,
        });
        this.transitionData = {};
        return newState;
    }

    goToNotFound() {
        return this.router.goToNotFound();
    }

    goToUnauthorized() {
        return this.goTo(this.unauthorizedState);
    }

    goToLogin() {
        return this.goTo(this.loginState);
    }

    goToDefaultRoute() {
        return this.goTo(this.initialState);
    }

    getCurrentRoute() {
        return this.router.getCurrentRoute();
    }

    getRoute(routeName) {
        return this.router.getRoute(routeName);
    }

    goBack() {
        this.historyAdapter.goBack();
    }

    setQueryParams(params) {
        return this.router.setRouterState(
            createRouterState(this.routerState.routeName, {
                params: this.routerState.params,
                queryParams: params,
            })
        );
    }

    setTransitionData(data) {
        this.transitionData = {
            ...this.transitionData,
            ...(data || {}),
        };
    }

    findRoute(name) {
        return find(this.router.routes, (route) => route.name === name);
    }

    setRouteTitle(routeTitleMap) {
        this.setRouteData(routeTitleMap, (value) => ({
            title: value,
        }));
    }

    setRouteNavigateBack(routeBackMap) {
        this.setRouteData(routeBackMap, (value) => ({
            back: value,
        }));
    }

    setRouteData(routeDataMap, getMergeObject) {
        forOwn(routeDataMap, (value, key) => {
            const routeData = this.routeDataMap.get(key) || {};
            const newRouteData = merge({}, routeData, getMergeObject(value));
            this.routeDataMap.set(key, newRouteData);
        });
    }

    getCurrentData() {
        return this.routeDataMap.get(this.routerState.routeName);
    }

    async routeChange({ fromState, toState, options }) {
        const { authenticationStore, userStore } = this.rootStore;
        if (
            fromState &&
            fromState.routeName === this.loginState.routeName &&
            toState.routeName === this.errorState.routeName
        ) {
            return Promise.reject(this.initialState);
        }

        if (options.isPublic === false) {
            if (
                !authenticationStore.isAuthenticated &&
                toState.routeName !== this.loginState.routeName
            ) {
                authenticationStore.setSignInRedirect(toState);
                return Promise.reject(this.loginState);
            }

            if (
                (options.authorization.length > 0 &&
                    some(
                        options.authorization,
                        (a) => !userStore.hasPermission(a)
                    )) ||
                !userStore.hasPermission()
            ) {
                return Promise.reject(this.unauthorizedState);
            }
        }

        return Promise.resolve();
    }

    setSystemRoute(name, routeName) {
        this[name] = createRouterState(routeName);
    }
}

export default MrbRouterStore;
