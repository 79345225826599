import { BaseService } from "mrb/core";
import { EpisodeSortTypeApiRouteService } from "common/services";

class EpisodeSortTypeService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new EpisodeSortTypeApiRouteService());
    }

    get(abortSignal = null) {
        const url = this.routeService.get();
        return this.apiClient.get(url, null, null, abortSignal);
    }
}

export default EpisodeSortTypeService;
