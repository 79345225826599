import React from "react";
import { PropTypes } from "prop-types";
import { mergeOrOverrideCss } from "mrb/common/utils";

function StripeAccountLinkTemplate({ accountId, classNameOverride, classNameExtend, t }) {
    let url = `https://dashboard.stripe.com/connect/accounts/${accountId}/activity`;

    return (
        <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--flex-end">
            <p className={mergeOrOverrideCss("u-type--ellipsis", classNameOverride, classNameExtend)}>{accountId}</p>
            <a
                className="u-type--base u-type--link u-type--link--secondary u-mar--left--tny"
                href={url}
                target="_blank"
                rel="noreferrer"
                title="View Stripe Account"
                onClick={(event) => event.stopPropagation()}
            >
                <i className="u-icon u-icon--base u-icon--link--primary"></i>
            </a>
        </div>
    );
}

StripeAccountLinkTemplate.propTypes = {
    accountId: PropTypes.string.isRequired,
    t: PropTypes.func,
};

export default StripeAccountLinkTemplate;
