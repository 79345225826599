import React from "react";
import { PropTypes } from "prop-types";
import { CardElement } from "@stripe/react-stripe-js";
import { StripeFieldValidation } from "common/components";
import { observer } from "mobx-react";

const cardStyle = {
    style: {
        base: {
            iconColor: "#f5f5f5",
            color: "#F5F5F5",
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "14px",
            ":-webkit-autofill": {
                color: "#fce883",
            },
            "::placeholder": {
                color: "rgba(245, 245, 245, 0.4)",
            },
        },
    },
};

function StripeCreditCardFieldTemplate({ field, label, t = (i) => i }) {
    return (
        <React.Fragment>
            {label && (
                <label className="c-form__field__label">{t(label)}</label>
            )}
            <CardElement
                onChange={field.onStripeFieldChange}
                options={cardStyle}
            />
            <StripeFieldValidation field={field} />
        </React.Fragment>
    );
}

StripeCreditCardFieldTemplate.propTypes = {
    field: PropTypes.object.isRequired,
    label: PropTypes.string,
    t: PropTypes.func,
};

export default observer(StripeCreditCardFieldTemplate);
