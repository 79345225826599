import { mergeCss } from "./";

function mergeOrOverrideCss(defaultCss, forOverride, forMerge) {
    if (forOverride) {
        return forOverride;
    }
    return mergeCss(defaultCss, forMerge);
}

export default mergeOrOverrideCss;
