import { v4 as uuidv4, NIL, validate } from "uuid";

class UUID {
    static create() {
        return uuidv4();
    }

    static emptyUuid() {
        return NIL;
    }

    static isValid(uuid) {
        return validate(uuid);
    }
}

export default UUID;
