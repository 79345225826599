import { MrbBaseRouteStore } from "mrb/core";
import { ProducerUserAccountService, ProducerService, UserAccountService } from "common/services";

class AdminProducerAccountEditRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.producerUserAccountService = rootStore.createApplicationService(ProducerUserAccountService);
        this.producerService = rootStore.createApplicationService(ProducerService);
        this.userAccountService = rootStore.createApplicationService(UserAccountService);
    }

    async getProducer(producerId) {
        const response = await this.producerService.get(producerId);
        return response.data;
    }

    async findUser(filter) {
        const response = await this.userAccountService.find(filter);
        return response.data;
    }

    async getUser(id) {
        const response = await this.userAccountService.get(id);
        return response.data;
    }

    async getStripeConnectAccounts() {
        const response = await this.producerUserAccountService.getStripeConnectAccounts();
        return response.data;
    }

    async getProducerAccountsSummary(producerId, abortSignal) {
        const response = await this.producerUserAccountService.getProducerAccountsSummary(producerId, abortSignal);
        return response.data;
    }

    async getAccountTotalBalance(accountId, abortSignal) {
        const response = await this.producerUserAccountService.getAccountTotalBalance(accountId, abortSignal);
        return response.data;
    }

    async getProducerTotalBalance(producerId, abortSignal) {
        const response = await this.producerUserAccountService.getProducerTotalBalance(producerId, abortSignal);
        return response.data;
    }

    async updateAccounts(producerId, accounts) {
        const response = await this.producerUserAccountService.updateAccounts(producerId, accounts);
        return response.data;
    }
}

export default AdminProducerAccountEditRouteStore;
