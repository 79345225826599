import {
    StationFileStreamService,
    PodcastFileStreamService,
    NakiNigunArtistFileStreamService,
    NakiNigunAlbumFileStreamService,
    FeaturedSectionFileStreamService,
} from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";
import { isNil } from "lodash";

class CoverImageFactory {
    _podcastFileStreamService = null;
    _stationFileStreamService = null;
    _nakiNigunArtistFileStreamService = null;
    _nakiNigunAlbumFileStreamService = null;
    _featuredSectionFileStreamService = null;

    get podcastFileStreamService() {
        if (isNil(this._podcastFileStreamService)) {
            this._podcastFileStreamService = this.rootStore.createApplicationService(PodcastFileStreamService);
        }
        return this._podcastFileStreamService;
    }

    get stationFileStreamService() {
        if (isNil(this._stationFileStreamService)) {
            this._stationFileStreamService = this.rootStore.createApplicationService(StationFileStreamService);
        }
        return this._stationFileStreamService;
    }

    get nakiNigunArtistFileStreamService() {
        if (isNil(this._nakiNigunArtistFileStreamService)) {
            this._nakiNigunArtistFileStreamService = this.rootStore.createApplicationService(
                NakiNigunArtistFileStreamService
            );
        }
        return this._nakiNigunArtistFileStreamService;
    }

    get nakiNigunAlbumFileStreamService() {
        if (isNil(this._nakiNigunAlbumFileStreamService)) {
            this._nakiNigunAlbumFileStreamService = this.rootStore.createApplicationService(
                NakiNigunAlbumFileStreamService
            );
        }
        return this._nakiNigunAlbumFileStreamService;
    }

    get featuredSectionFileStreamService() {
        if (isNil(this._featuredSectionFileStreamService)) {
            this._featuredSectionFileStreamService = this.rootStore.createApplicationService(
                FeaturedSectionFileStreamService
            );
        }
        return this._featuredSectionFileStreamService;
    }

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    createStationCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.stationFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }

    createPodcastCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.podcastFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }

    createNakiNigunArtistCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.nakiNigunArtistFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }

    createNakiNigunAlbumCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.nakiNigunAlbumFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }

    createFeaturedSectionCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.featuredSectionFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }
}

export default CoverImageFactory;
