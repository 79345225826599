import { override } from "mobx";
import { MrbSimpleSelectStore } from "../";
import { isArray, arrayDifference, isEmpty, isFunction, isBoolean, merge } from "lodash";

const DefaultConfig = {
    fetchOnChange: true,
};

class MrbQuerySimpleSelectStore extends MrbSimpleSelectStore {
    queryUtility = null;
    key = null;

    @override get value() {
        return this.queryUtility.filter[this.key];
    }

    constructor(config, queryUtility, key, initialItems) {
        super(merge({}, DefaultConfig, config), initialItems);
        if (!queryUtility) {
            throw new Error("queryUtility must be provided.");
        }
        this.queryUtility = queryUtility;
        if (!key) {
            throw new Error("key must be provided.");
        }
        this.key = key;
    }

    @override
    setValue(value) {
        this.queryUtility.filter.set(this.key, value);
    }

    @override
    onChange(selectedOption) {
        const oldValue = this.value;
        super.onChange(selectedOption);

        if (
            (isFunction(this.config.fetchOnChange) && this.config.fetchOnChange(selectedOption)) ||
            (isBoolean(this.config.fetchOnChange) && this.config.fetchOnChange)
        ) {
            if (isArray(oldValue) && isArray(this.value)) {
                if (arrayDifference(oldValue, this.value).length > 0) {
                    this.queryUtility.fetch();
                }
            } else if (oldValue !== this.value && (!isEmpty(oldValue) || !isEmpty(this.value))) {
                this.queryUtility.fetch();
            }
        }
    }
}

export default MrbQuerySimpleSelectStore;
