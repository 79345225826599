import { BaseService } from "mrb/core";
import { TorahAnytimeApiRouteService } from "common/services";
import { findParams } from "mrb/core/utils";

class TorahAnytimeService extends BaseService {
    constructor(apiClient) {
        super(apiClient, new TorahAnytimeApiRouteService());
    }

    findSpeakers(filter, abortSignal = null) {
        const url = this.routeService.findSpeakers();
        const params = findParams(filter);
        const data = {
            embed: params.embed,
            topicIds: params.topicIds,
            fields: params.fields,
            page: params.page,
            rpp: params.rpp,
            searchQuery: params.searchQuery,
            sort: params.sort,
        };
        return this.apiClient.post(url, data, null, abortSignal);
    }

    findTopics(filter, abortSignal = null) {
        const url = this.routeService.findTopics(filter);
        return this.apiClient.get(url, null, abortSignal);
    }

    getSetting(abortSignal = null) {
        const url = this.routeService.getSetting();
        return this.apiClient.get(url, null, abortSignal);
    }

    findSpeakersByGender(filter) {
        const url = this.routeService.findSpeakersByGender();
        return this.apiClient.post(url, filter, null, null);
    }

    findSpeakerIdsByTopicIds(filter) {
        const url = this.routeService.findSpeakerIdsByTopicIds();
        return this.apiClient.post(url, filter, null, null);
    }
}

export default TorahAnytimeService;
