import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "mrb/components/button";
import { UpdateCustomerPaymentMethod } from "application/payment/components";
import { observer } from "mobx-react";
import { isNil } from "lodash";
import { mergeCss } from "mrb/common";

function CustomerPaymentMethodInfoTemplate({ customerPaymentMethodInfoViewStore, t }) {
    const {
        customerPaymentMethod,
        setIsCreditCardFormVisible,
        isCreditCardFormVisible,
        rootStore: { premiumSubscriptionStore },
    } = customerPaymentMethodInfoViewStore;

    if (isNil(customerPaymentMethod)) {
        return null;
    }
    if (premiumSubscriptionStore.isInternalSubscriptionActive) {
        return null;
    }

    return (
        <React.Fragment>
            <h1 className="u-type--base u-mar--bottom--med">Payment Method</h1>
            <div className="c-payment__card">
                <div className="c-credit-card">
                    <i
                        className={mergeCss(
                            "c-credit-card__logo u-icon",
                            customerPaymentMethod.brand
                                ? `u-icon--${customerPaymentMethod.brand}`
                                : " u-icon--custom-visa"
                        )}
                    ></i>
                    <div className="c-credit-card__nunmber">
                        <CreditCardPanel creditCard={customerPaymentMethod} t={t} />
                    </div>
                </div>

                {isCreditCardFormVisible && (
                    <React.Fragment>
                        <UpdateCustomerPaymentMethod store={customerPaymentMethodInfoViewStore} />
                        <MrbButton
                            classNameOverride={"c-btn c-btn--med c-btn--ghost c-btn--full u-mar--top--sml"}
                            onClick={() => setIsCreditCardFormVisible(!isCreditCardFormVisible)}
                            label="Cancel"
                        />
                    </React.Fragment>
                )}

                {!isCreditCardFormVisible && (
                    <React.Fragment>
                        <div className="u-separator--primary"></div>
                        <MrbButton
                            classNameOverride={"c-btn c-btn--med c-btn--ghost c-btn--full"}
                            onClick={() => setIsCreditCardFormVisible(!isCreditCardFormVisible)}
                            label="Update Card"
                        />
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}

CustomerPaymentMethodInfoTemplate.propTypes = {
    customerPaymentMethodInfoViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const CreditCardPanel = observer(function CreditCardPanel({ creditCard, t }) {
    if (!creditCard) {
        return null;
    }

    return (
        <div>
            <span className="u-mar--right--tny">#### #### #### {creditCard.last4Numbers} </span>
        </div>
    );
});

export default observer(CustomerPaymentMethodInfoTemplate);
