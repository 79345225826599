import React from "react";
import { UserAccountSummaryTemplate } from "themes/administration/analytics/components";

class UserAccountSummary extends React.Component {
    render() {
        return <UserAccountSummaryTemplate {...this.props} />;
    }
}

export default UserAccountSummary;
