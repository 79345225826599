import { BaseAnalyticsViewStore } from "administration/analytics/stores";
import {
    DeviceActivitySummaryViewStore,
    TopTrendingPodcastsViewStore,
    TopTrendingStationsViewStore,
    PortalViewStore,
} from "administration/analytics/stores";

class AnalyticsDashboardViewStore extends BaseAnalyticsViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore);
        this.routeStore = routeStore;

        this.deviceActivitySummaryViewStore = new DeviceActivitySummaryViewStore(this.rootStore, {
            routeStore: this.routeStore,
        });
        this.topTrendingPodcastsViewStore = new TopTrendingPodcastsViewStore(this.rootStore, {
            routeStore: this.routeStore,
        });
        this.topTrendingStationsViewStore = new TopTrendingStationsViewStore(this.rootStore, {
            routeStore: this.routeStore,
        });
        this.portalViewStore = new PortalViewStore(this.rootStore, {
            routeStore: this.routeStore,
        });
    }

    async fetch(params, abortController) {
        await Promise.all([
            this.deviceActivitySummaryViewStore.getResources(params, abortController),
            this.topTrendingPodcastsViewStore.getResources(params, abortController),
            this.topTrendingStationsViewStore.getResources(params, abortController),
            this.portalViewStore.getResources(params, abortController),
        ]);
    }

    onDestroy() {
        super.onDestroy();
        this.deviceActivitySummaryViewStore.destroy();
        this.topTrendingPodcastsViewStore.destroy();
        this.topTrendingStationsViewStore.destroy();
        this.portalViewStore.destroy();
    }
}

export default AnalyticsDashboardViewStore;
