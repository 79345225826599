import { action, computed, reaction, observable, makeObservable } from "mobx";
import { mrbCacheManager } from "../../cache";

class MrbBaseAuthenticationStore {
    /**
     * @protected
     * @description User authentication token instance.
     */
    @observable.ref token = null;

    @observable.ref signInRedirect = null;

    /**
     * @readonly
     * @public
     * @description Determine whether an user is authenticated.
     */
    @computed get isAuthenticated() {
        return this.isTokenValid(this.token);
    }

    /**
     * @constructor
     * @param {Core.Stores.RootStore} rootStore - The instance of the RootStore object.
     * @description Initializes a new instance of the MrbBaseAuthenticationStore.
     */
    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
        reaction(
            () => this.isAuthenticated,
            async (isAuthenticated) => {
                await this.onIsAuthenticatedChange(isAuthenticated);
            }
        );
    }

    @action.bound
    async onIsAuthenticatedChange(isAuthenticated) {
        const { userStore } = this.rootStore;
        if (isAuthenticated) {
            await userStore.resolveUser();
        } else {
            userStore.removeUser();
            await this.onUnauthenticated();
        }
    }

    onUnauthenticated() {
        this.rootStore.routerStore.goToLogin();
        mrbCacheManager.clear();
        return Promise.resolve();
    }

    /**
     * @function
     * @public
     * @description Initialize authentication store and underlying services.
     */
    @action.bound
    initialize() {
        this.syncToken();
    }

    syncToken() {
        throw new Error("Method not implemented.");
    }

    @action.bound
    setToken(token) {
        this.token = token;
    }

    @action.bound
    setSignInRedirect(state) {
        this.signInRedirect = state;
    }

    resetSignInRedirect() {
        this.setSignInRedirect(null);
    }

    getSignInRedirect() {
        if (this.signInRedirect) {
            return this.signInRedirect;
        }

        return this.rootStore.routerStore.initialState;
    }

    login(username, password, options) {
        throw new Error("Method not implemented.");
    }

    logout() {
        throw new Error("Method not implemented.");
    }

    isTokenValid(token) {
        return !!token;
    }
}

export default MrbBaseAuthenticationStore;
