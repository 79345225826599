import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { observer } from "mobx-react";
import { MrbContentLoader } from "mrb/components/loader";
import { TopTrendingListLoader } from "administration/analytics/components";
import { numberFormatter } from "common/utils";
import { map } from "lodash";

function UserContentPlaybackSummaryTemplate({ userDeviceActivityViewStore, t }) {
    const { userDeviceActivitySummaryViewStore } = userDeviceActivityViewStore;
    const { loaderStore } = userDeviceActivitySummaryViewStore;
    return (
        <div>
            <h1 className="c-analytics__title">Top Stations & Podcasts (Overall)</h1>
            <div className="c-card--primary c-card--med">
                <MrbContentLoader
                    loaderStore={loaderStore}
                    loaderRender={() => <TopTrendingListLoader row={5} noButton userActivity />}
                >
                    {() => <PlaybacksList userDeviceActivitySummaryViewStore={userDeviceActivitySummaryViewStore} />}
                </MrbContentLoader>
            </div>
        </div>
    );
}

UserContentPlaybackSummaryTemplate.propTypes = {
    userDeviceActivityViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

const PlaybacksList = observer(function PlaybacksList({ userDeviceActivitySummaryViewStore }) {
    const { contentPlaybacks } = userDeviceActivitySummaryViewStore;
    return (
        <div>
            {contentPlaybacks.length > 0 ? (
                <React.Fragment>
                    {map(contentPlaybacks, (item, index) => (
                        <PlaybackItem key={item.stationId || item.podcastId} item={item} index={index} />
                    ))}
                </React.Fragment>
            ) : (
                <EmptyState />
            )}
        </div>
    );
});

function PlaybackItem({ index, item }) {
    let title, route, isPremium, params;
    if (item.station) {
        title = item.station.title;
        isPremium = item.station.isPremium;
        route = "master.administration.analytics.station-activity";
        params = { id: item.station.id };
    } else if (item.podcast) {
        title = item.podcast.title;
        isPremium = item.podcast.isPremium;
        route = "master.administration.analytics.podcast-activity";
        params = { id: item.podcast.id };
    }
    return (
        <div className="c-analytics__list__item">
            <div className="u-display--flex u-display--flex--ai--center">
                <span className="u-mar--right--tny">{index + 1}.</span>
                <i
                    className={`u-icon u-icon--med u-mar--right--tny u-icon--${
                        item.stationId ? "stations" : "podcasts"
                    }`}
                />
                <MrbRouterLink routeName={route} params={params} className={"c-analytics__list__title"}>
                    {title}
                    {isPremium && <i className="u-icon u-icon--base u-mar--left--tny u-icon--premium" />}
                </MrbRouterLink>
            </div>
            {numberFormatter.format(item.playbacks)}
        </div>
    );
}

function EmptyState() {
    return (
        <div className="c-empty-state--no-results c-empty-state--no-results--sml">
            <i className="u-icon--empty u-icon--empty--sml u-icon--empty--shuffle u-mar--bottom--sml" />
            <h3>No activity data</h3>
            <p>The user has no detected plays.</p>
        </div>
    );
}

export default observer(UserContentPlaybackSummaryTemplate);
