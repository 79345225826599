import React from "react";
import { PropTypes } from "prop-types";
import { numberFormatter } from "common/utils";
import { TrendingIndicator } from "administration/analytics/components";
import { observer } from "mobx-react";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { StatsWidgetLoader } from "administration/analytics/components";
import { MrbContentLoader } from "mrb/components/loader";
import { StatsWidgetEmptyState } from "common/components/empty-state";

function StatsWidgetTemplate({ store, t }) {
    const { item, currentPeriodLabel, previousPeriodLabel, filter, options, loaderStore } = store;
    return (
        <React.Fragment>
            <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <StatsWidgetLoader />}>
                {() => (
                    <React.Fragment>
                        {item ? (
                            <div className="row u-animation--fadeIn">
                                <div className="col col-sml-7">
                                    <div className="u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            Total # of playbacks in {currentPeriodLabel}
                                        </p>
                                        <p>{numberFormatter.format(item.currentCycleScore)}</p>
                                    </div>
                                    <div className="u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {" "}
                                            {currentPeriodLabel} chart position
                                        </p>
                                        <p>{item.currentCyclePosition ? item.currentCyclePosition : "Unlisted"}</p>
                                    </div>
                                    <div>
                                        <p className="u-type--sml u-type--color--opaque">
                                            {previousPeriodLabel} chart position
                                        </p>
                                        <p>{item.previousCyclePosition ? item.previousCyclePosition : "Unlisted"}</p>
                                    </div>
                                </div>
                                {item.currentCyclePosition && item.previousCyclePosition && (
                                    <div className="col col-sml-5 u-display--flex u-display--flex--jc--flex-end u-display--flex--ai--center">
                                        <TrendingIndicator
                                            classNameExtend="c-analytics__stats--lrg"
                                            indicatorClassNameExtend="u-icon--analytics--xlrg"
                                            currentCycleNumber={item.currentCyclePosition}
                                            previousCycleNumber={item.previousCyclePosition}
                                        />
                                    </div>
                                )}
                                <div className="col col-sml-12">
                                    <div className="u-separator--primary u-separator--primary--sml"></div>
                                    <MrbRouterLink
                                        routeName={options.routeName}
                                        className="c-btn c-btn--base c-btn--full c-btn--ghost"
                                        params={options.routeParams}
                                        queryParams={{ range: filter.range }}
                                    >
                                        View More
                                    </MrbRouterLink>
                                </div>
                            </div>
                        ) : (
                            <StatsWidgetEmptyState period={"month"} />
                        )}
                    </React.Fragment>
                )}
            </MrbContentLoader>
        </React.Fragment>
    );
}

StatsWidgetTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(StatsWidgetTemplate);
