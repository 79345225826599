import { MrbBaseRouteStore } from "mrb/core";
import { PortalAnalyticsService } from "common/services";

class RegisteredUserDevicesActivityRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PortalAnalyticsService);
    }

    async getRegisteredUserDevicesActivity(filter, abortController) {
        const response = await this.service.getRegisteredUserDevicesActivity(filter, abortController);
        return response.data;
    }

    async getRegisteredUserDevicesSummary(filter, abortController) {
        const response = await this.service.getRegisteredUserDevicesSummary(filter, abortController);
        return response.data;
    }
}

export default RegisteredUserDevicesActivityRouteStore;
