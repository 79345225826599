import { MrbBaseRouteStore } from "mrb/core";
import { PortalAnalyticsService } from "common/services";

class PremiumUsersActivityRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PortalAnalyticsService);
    }

    async getPremiumSubscriptionsActivity(filter, abortController) {
        const response = await this.service.getPremiumSubscriptionsActivity(filter, abortController);
        return response.data;
    }

    async getPremiumSubscriptionsSummary(filter, abortController) {
        const response = await this.service.getPremiumSubscriptionsSummary(filter, abortController);
        return response.data;
    }
}

export default PremiumUsersActivityRouteStore;
