import { action, observable, makeObservable } from "mobx";
import { MrbBaseListViewStore } from "mrb/core";
import { MrbTableViewStore } from "mrb/components";
import { mrbDefaultFormats } from "mrb/common/formatting";
import { dateFormatter } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";
import { LineChartStore } from "common/components/charts";
import { currencyFormatter } from "mrb/common/formatting";
import { httpStatusCodes } from "mrb/core/constants";
import { ProducerPreviewReportPeriodCell, ProducerPreviewReportPlaybackTimeCell } from "common/components/producer";
import { map, orderBy, findIndex } from "lodash";
import { numberFormatter } from "common/utils";
import moment from "moment";

class AdminProducerPreviewViewStore extends MrbBaseListViewStore {
    @observable.ref summaryData = null;
    @observable.ref mostPopularContent = [];

    _id = null;
    range = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            queryConfig: {
                orderBy: "monthOfYear",
                orderDirection: "desc",
            },
            navigateBack: "master.administration.producer.list",
        });
        makeObservable(this);
        this.routeStore = routeStore;
        this._id = this.rootStore.routerStore.routerState.params.id;

        const from = moment.utc().subtract(1, "year").startOf("month").format(applicationDefaults.formats.isoDate);
        const to = moment.utc().subtract(1, "month").startOf("month").format(applicationDefaults.formats.isoDate);
        this.range = {
            from: from,
            to: to,
        };

        this.setGridStore(
            new MrbTableViewStore(this.rootStore, this.queryUtility, {
                onRowClick: (node) => {
                    const monthOfYear = dateFormatter.format(node.data.monthOfYear, {
                        format: applicationDefaults.formats.isoDate,
                        timeZone: "utc",
                    });
                    this.rootStore.routerStore.goTo(
                        "master.administration.producer.preview.payment",
                        {
                            id: node.data.producerId,
                        },
                        {
                            monthOfYear: monthOfYear,
                        }
                    );
                },
                columns: [
                    {
                        key: "monthOfYear",
                        title: "Period",
                        sortable: true,
                        cell: ProducerPreviewReportPeriodCell,
                    },
                    {
                        key: "totalProducerPlaybacks",
                        title: "Playback Time",
                        cell: ProducerPreviewReportPlaybackTimeCell,
                    },
                    {
                        key: "totalProducerPlaybacks",
                        title: "Playback Points",
                        sortable: true,
                        format: (value) => numberFormatter.format(value),
                    },
                    {
                        key: "totalProducerPayment",
                        title: "Revenue",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.currency,
                            currency: "USD",
                        },
                    },
                ],
            })
        );

        this.chartStore = new LineChartStore(this.rootStore, {
            label: "Payout",
            actions: {
                preprocessData: (data) => {
                    return this.spanChartDataSetToFullRange(data);
                },
                mapLabels: (data) => {
                    return map(data, (item) =>
                        dateFormatter.format(item.monthOfYear, {
                            format: applicationDefaults.formats.monthShort,
                        })
                    );
                },
                mapDataset: (data) => {
                    return map(data, (item) => item.totalProducerPayment);
                },
                find: async (_, abortController) => {
                    let params = {
                        from: this.range.from,
                        to: this.range.to,
                        page: 1,
                        rpp: 12, // 12 months
                        producerIds: this._id,
                        orderBy: "monthOfYear",
                        orderDirection: "asc",
                    };
                    const response = await this.routeStore.findProducerReports(params);

                    return response.item;
                },
                onError: (error) => {
                    if (error && error.statusCode === httpStatusCodes.CanceledRequest) {
                        return;
                    }
                    this.rootStore.notificationStore.error(
                        "An unexpected error occurred while trying to load chart data.",
                        error
                    );
                },
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                const amount = currencyFormatter.format(context.formattedValue, {
                                    currency: "USD",
                                });
                                return `${context.dataset.label}: ${amount}`;
                            },
                        },
                    },
                },
                scales: {
                    y: {
                        ticks: {
                            callback: function (value) {
                                return currencyFormatter.format(value, {
                                    currency: "USD",
                                });
                            },
                        },
                    },
                },
            },
        });

        this.spanChartDataSetToFullRange = this.spanChartDataSetToFullRange.bind(this);
    }

    async onInit() {
        await Promise.all([
            super.onInit(),
            this.chartStore.generate(),
            this.fetchSummaryData(this._id),
            this.fetchMostPopularContent(this._id),
        ]);
    }

    find(params) {
        params.producerIds = this._id;
        return this.routeStore.findProducerReports(params);
    }

    onFetchError(err) {
        this.rootStore.notificationStore.error(
            "An unexpected error occurred while trying to load data. Please contact the support team.",
            err
        );
    }

    spanChartDataSetToFullRange(data) {
        const min = moment.utc(this.range.from);
        const max = moment.utc(this.range.to);
        const periods = max.diff(min, "month") + 1;
        let currentPeriod = min;
        let missingPeriods = [];
        for (let period = 1; period <= periods; period++) {
            /* eslint-disable no-loop-func */
            const index = findIndex(data, (item) => moment.utc(item.monthOfYear).isSame(currentPeriod));
            if (index === -1) {
                missingPeriods.push({
                    monthOfYear: currentPeriod.format(applicationDefaults.formats.isoDate),
                    totalProducerPayment: 0,
                });
            }
            currentPeriod = moment.utc(currentPeriod).add(1, "month");
        }

        const result = orderBy([...data, ...missingPeriods], (x) => x.monthOfYear);
        return result;
    }

    async fetchMostPopularContent(id) {
        try {
            const data = await this.routeStore.getProducerMostPopularContent(id, { page: 1, rpp: 5 });
            this.setMostPopularContent(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team.",
                err
            );
        }
    }

    @action.bound
    setMostPopularContent(data) {
        this.mostPopularContent = data;
    }

    async fetchSummaryData(id) {
        try {
            const data = await this.routeStore.getProducerSummary(id);
            this.setSummaryData(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load data. Please contact the support team.",
                err
            );
        }
    }

    @action.bound
    setSummaryData(data) {
        this.summaryData = data;
    }
}

export default AdminProducerPreviewViewStore;
