import { action, observable, runInAction, makeObservable } from "mobx";
import { MrbBaseViewStore } from "./";
import { NavigationOptions } from "../models";
import { isFunction } from "lodash";

class MrbMainViewStore extends MrbBaseViewStore {
    @observable.ref navigationOptions = null;
    @observable isInitialized = false;

    constructor(rootStore) {
        super(rootStore);
        makeObservable(this);
    }

    initialize = async () => {
        await this.rootStore.userStore.resolveUser();
        runInAction(() => {
            this.isInitialized = true;
        });
    };

    @action setNavigationOptions = (navigationOptions) => {
        const opts = isFunction(navigationOptions)
            ? navigationOptions(this.rootStore)
            : navigationOptions;

        let options = null;
        if (opts && opts.title) {
            options = new NavigationOptions();
            if (isFunction(opts.title)) {
                const result = opts.title(this.rootStore);
                if (result instanceof Promise) {
                    options.suspend();
                    result.then((res) => {
                        options.setTitle(res);
                        options.resume();
                    });
                } else {
                    options.setTitle(result);
                }
            } else {
                options.setTitle(opts.title);
            }
        }

        this.navigationOptions = options;
    };
}

export default MrbMainViewStore;
