import React from "react";
import PropTypes from "prop-types";
import { findIndex } from "lodash";

/*
    will NOT work with kendo UI components since they always render outside of your usual DOM tree
    if you're trying to make this work, set a ref directly on a kendo UI component instead of using a wrapper
    and handle it same as it's handled here
*/

class MrbNotifyOutsideClick extends React.Component {
    constructor(props) {
        super(props);

        this.ignoreElementsRef = props.ignoreElementsRef;
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.isInIgnoreList = this.isInIgnoreList.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            !this.isInIgnoreList(event) &&
            !this.wrapperRef.contains(event.target)
        ) {
            this.props.action(event);
        }
    }

    isInIgnoreList(event) {
        if (this.ignoreElementsRef && this.ignoreElementsRef.length > 0) {
            return (
                findIndex(
                    this.ignoreElementsRef,
                    (ref) => ref === event.target || ref.contains(event.target)
                ) !== -1
            );
        }
        return false;
    }

    render() {
        return <div ref={this.setWrapperRef}>{this.props.children}</div>;
    }
}

MrbNotifyOutsideClick.propTypes = {
    children: PropTypes.node.isRequired,
    ignoreElements: PropTypes.any,
    action: PropTypes.func,
};

export default MrbNotifyOutsideClick;
